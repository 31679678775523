import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { newAppointment, newFollowUp } from "./queryPartials";

export const patientDashboardForAdministratorSort = () => [
  {
    filter: {
      ...newAppointment,
    },
    script_score: {
      script: {
        source: `10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
  },
  {
    filter: {
      ...newFollowUp,
    },
    script_score: {
      script: {
        source: `10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
  },
  {
    filter: {
      bool: {
        must: {
          match: {
            "flags.pinnedByProvider": true,
          },
        },
      },
    },
    weight: 1,
  },
];
