import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { v4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isNull, isUndefined } from "lodash";
import { FollowUpSurvey, SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import { ReactComponent as MedicalBackgroundImage } from "../../../../Assets/NewIcons/medical-background.svg";
import "./style.scss";
import { Button } from "../../../NewComponents/Common/Button";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { sendSurveyEnded } from "../../../../shared/analytics";
import { TreatmentsSurveyQuestion } from "./survey-questions/treatment";
import { IsMedicalHistoryChangesSurveyQuestion } from "./survey-questions/is-med-history-changes";
import { MedicalHistoryChangesSurveyQuestion } from "./survey-questions/med-history-changes";
import { isIssuesWithCreamsSurveyQuestion } from "./survey-questions/is-issues-with-creams";
import { IssuesWithCreamsSurveyQuestion } from "./survey-questions/issues-with-creams";
import { IsOralMedsInUseSurveyQuestion } from "./survey-questions/is-oral-meds-in-use";
import { isRefillRequiredSurveyQuestion } from "./survey-questions/is-refill-required";
import { RefillProductsSurveyQuestion } from "./survey-questions/refill-products";
import { SleepAmountSurveyQuestion } from "./survey-questions/sleep-amount";
import { DairyConsumptionSurveyQuestion } from "./survey-questions/dairy-consumption";
import { StressLevelFollowUpSurveyQuestion } from "./survey-questions/stress-level";
import { SkinImagesFollowUpSurveyQuestion } from "./survey-questions/skin-images";
import { ProgressFeelingsSurveyQuestion } from "./survey-questions/progressFeelings";
import { AdditionalQuestionsSurveyQuestion } from "./survey-questions/additionalQuestions";
import { AccutaneContraindicationsSurveyQuestion } from "./survey-questions/accutane-contraindications";
import { PHQSurveyQuestion } from "./survey-questions/phq-questions";
import { DepressionHistorySurveyQuestion } from "./survey-questions/depression-history";
import { WasDepressionTreatedSurveyQuestion } from "./survey-questions/was-depression-treated";
import { isEmpty } from "../../../../utils/is-empty";
import getAmericanFormatDate from "../../../../utils/get-american-format-date";
import { getSessionState } from "../../../../features/session";
import {
  useGetFollowUpsByPatientIdQuery,
  useSubmitFollowUpMutation,
} from "../../../../features/api/follow-ups";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { generateImageFilename } from "../../../../utils/generate-image-filename";
import { FollowUpQuestionnaire } from "../../../../types/FollowUp";
import { followUpSurveyValueToDB } from "../../../../utils/survey/follow-up-survey-to-questionaire";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { Loader } from "../../../NewComponents/Common/Loader";
import { followupSkinIssues } from "./follow-up-questions";
import { SurveyQuestionTemplete } from "./survey-questions";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { SkinImagesSurveyQuestion } from "../SkinSurvey/survey-questions/skin-images";
import {
  getLastFolloUpAnswer,
  getGenericLifestyleQuestion,
} from "../../../../utils/survey/get-last-follow-up-answer";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { useGetAccutaneByPatientIdQuery } from "../../../../features/api/accutane";
import isValidDate from "../../../../utils/is-valid-date";

enum STATES {
  INIT,
  SURVEY,
  COMPLETE,
}

export function FollowUpSurveyPage() {
  const { showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(activePatientId);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const { data: accutane } = useGetAccutaneByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const [skinSurveyType, setSkinSurveyType] = useState<any>();
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const [submitFollowUp] = useSubmitFollowUpMutation();
  const history = useHistory();
  const [state, setState] = useState(STATES.INIT);
  const [followQuestions, setFollowQuestions] = useState();
  const isMedicalBackground =
    !!patientQuery?.data?.medicalBackground?.skinSurvey;
  const isChildAccount = !!patientQuery?.data?.parentInfo;
  const [followUpQuestionsBySkinIssue, setFollowUpQuestionsBySkinIssue] =
    useState();

  useEffect(() => {
    const isLoaded =
      patientQuery && followUpsQuery.isSuccess && appointmentsQuery.isSuccess;

    if (!isLoaded) return;
    if (!isMedicalBackground) {
      history.push("/");
      return;
    }
    if (
      followUpsQuery?.data?.length === 0 ||
      (followUpsQuery?.data?.length === 1 &&
        !followUpsQuery?.data[0]?.treatmentPlan?.nextFollowUpDate)
    ) {
      if (
        appointmentsQuery.data?.[0].endTime &&
        new Date(appointmentsQuery?.data[0].endTime).getTime() < Date.now()
      ) {
        history.push(`/reschedule/${activePatientId}`);
        return;
      }
    }

    const nextFollowUpDate =
      followUpsQuery?.data[0]?.treatmentPlan?.nextFollowUpDate;
    if (nextFollowUpDate) {
      history.push("/start-follow-up");
      return;
    }
    history.push("/");
  }, [followUpsQuery, appointmentsQuery, patientQuery, isMedicalBackground]);

  useEffect(() => {
    const medicalBackground: any = patientQuery.currentData?.medicalBackground;
    if (isMedicalBackground && medicalBackground) {
      const skinIssueTypeObject = medicalBackground?.skinSurvey?.find(
        (obj) => obj.id === "skinIssueType"
      );

      setSkinSurveyType(
        skinIssueTypeObject ? skinIssueTypeObject.value : "acne"
      );
    }
  }, [patientQuery, isMedicalBackground]);

  const lastTreatmentPlan = followUpsQuery?.data?.[0]?.treatmentPlan || null;

  const generateSurveyQuestions = (subQuestions: any[] | undefined) => {
    const componentMap: { [key: string]: React.ComponentType<any> } = {
      isRecommendedTreatment: TreatmentsSurveyQuestion,
      isPhqQuestion: PHQSurveyQuestion,
      isTextArea: ProgressFeelingsSurveyQuestion,
      isCheckboxesList: AccutaneContraindicationsSurveyQuestion,
    };

    const questions =
      subQuestions &&
      subQuestions.map((question: { value: string | number }) => {
        const previousAnswer =
          question.questionType === "lifestyle" &&
          patientQuery?.data &&
          followUpsQuery.data
            ? getLastFolloUpAnswer(
                patientQuery.data,
                followUpsQuery.data,
                question.value
              )
            : null;

        return {
          isFilled: (data: { [x: string]: any }) =>
            !isEmpty(data[question.value]),
          Component:
            componentMap[
              Object.keys(question).find((key) => key.startsWith("is"))
            ] || SurveyQuestionTemplete,
          question,
          isChildAccount,
          when: (data) =>
            (!question.isAccutane || // Check if it's not isAccutane
              (lastTreatmentPlan &&
                medicationsQuery.data && // Additional condition for isCheckboxesList
                lastTreatmentPlan.medications.some(
                  ({ medicineKey }) =>
                    medicationsQuery.data.find(({ id }) => id === medicineKey)
                      ?.isAccutane
                ))) &&
            (!question.parentQuestion ||
              (data[question?.parentQuestion] &&
                (data[question?.parentQuestion] === question.parentAnswer ||
                  (question?.isArrayOfAnswer &&
                    data[question?.parentQuestion].includes(
                      question.parentAnswer
                    )))) ||
              (question.parentTextAnwser &&
                data[question.parentQuestion] !== question.parentTextAnwser)),
          previousAnswer,
        };
      });

    questions?.push(
      {
        isFilled: (data) => !isEmpty(data.depressionHistory),
        when: (data) =>
          !isEmpty(data.phqAnswer1) &&
          !isEmpty(data.phqAnswer2) &&
          data.phqAnswer1 + data.phqAnswer2 >= 3,
        Component: DepressionHistorySurveyQuestion,
      },
      {
        isFilled: (data) => !isEmpty(data.wasDepressionTreated),
        when: (data) =>
          !isEmpty(data.phqAnswer1) &&
          !isEmpty(data.phqAnswer2) &&
          data.phqAnswer1 + data.phqAnswer2 >= 3,
        Component: WasDepressionTreatedSurveyQuestion,
      },
      {
        isFilled: () => false,
        Component: SkinImagesFollowUpSurveyQuestion,
      }
    );
    return questions;
  };

  useEffect(() => {
    if (skinSurveyType && followUpsQuery?.data) {
      const followQuestionsObject: any = followupSkinIssues.find(
        (obj) => obj.value === skinSurveyType
      );
      const pregnantCapability =
        !isNull(patientQuery.currentData?.flags?.isNotPregnantCapability) &&
        patientQuery.currentData?.flags?.isNotPregnantCapability === false;

      const isFirstVisit = !isValidDate(accutane?.lastConfirmationDate);
      const hasAccutane =
        !isNull(accutane) && !isEmpty(accutane) && !isUndefined(accutane);
      const filterQuestions = pregnantCapability && hasAccutane && isFirstVisit;
      if (filterQuestions)
        followQuestionsObject.subQuestions =
          followQuestionsObject?.subQuestions?.filter(
            (obj) => obj.isConditional !== true
          );
      const skinSurvey =
        patientQuery?.currentData?.medicalBackground.skinSurvey;
      const followUpsData = followUpsQuery?.data;
      let latestFollowUpQuestionnaire = null;
      if (followUpsData.length >= 2) {
        latestFollowUpQuestionnaire =
          followUpsData[followUpsData.length - 2]?.questionnaire;
      } else {
        latestFollowUpQuestionnaire = followUpsData[0]?.questionnaire;
      }
      if (!latestFollowUpQuestionnaire) {
        const matches = [];

        skinSurvey.forEach((skin) => {
          const followUp = followQuestionsObject?.subQuestions?.find(
            (question) => question.value === skin.id
          );

          if (followUp) {
            matches.push({ skin, followUp });
          }
        });

        if (matches.length > 0) {
          matches.forEach(({ skin, followUp }) => {
            followUp.label = followUp.label.replace(
              "part from survey",
              `${skin.value}`
            );
          });
        } else {
          followQuestionsObject.subQuestions =
            followQuestionsObject?.subQuestions?.map((question) => {
              if (question.questionType === "lifestyle") {
                const returnedQuestion = getGenericLifestyleQuestion(
                  question.value
                );
                return returnedQuestion; // Replace the original question with the returned question
              }
              return question; // Keep the original question if it's not a "lifestyle" question
            });
        }
      } else {
        followQuestionsObject?.subQuestions
          ?.filter((question: any) => question.questionType === "lifestyle")
          .forEach((question: any) => {
            const lastFollowUpAnswer = getLastFolloUpAnswer(
              patientQuery?.data,
              followUpsQuery?.data,
              question.value
            );

            question.label = question.label.replace(
              "part from survey",
              lastFollowUpAnswer
            );
          });
      }
      setFollowQuestions(
        followQuestionsObject ? followQuestionsObject.subQuestions : null
      );
      const result: any = generateSurveyQuestions(
        followQuestionsObject?.subQuestions
      );
      setFollowUpQuestionsBySkinIssue(result);
    }
  }, [skinSurveyType, followUpsQuery?.data]);

  const surveyQuestions: SurveyPage[] = [
    {
      isFilled: (data) => !isEmpty(data.isMedicalHistoryChanges),
      Component: IsMedicalHistoryChangesSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.medicalHistoryChanges),
      when: (data) => !!data.isMedicalHistoryChanges,
      Component: MedicalHistoryChangesSurveyQuestion,
    },
    {
      isFilled: (data) => data.treatmentSurvey?.length > 0,
      Component: TreatmentsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isIssuesWithCreams),
      Component: isIssuesWithCreamsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.issuesWithCreams),
      when: (data) => data.isIssuesWithCreams,
      Component: IssuesWithCreamsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isOralMedicationInUse),
      Component: IsOralMedsInUseSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isRefillRequired),
      Component: isRefillRequiredSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.refillProducts),
      when: (data) => data.isRefillRequired,
      Component: RefillProductsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.accutaneContraindications),
      when: () => {
        if (!lastTreatmentPlan || !medicationsQuery.data) return false;
        return lastTreatmentPlan.medications.some(
          ({ medicineKey }) =>
            medicationsQuery.data?.find(({ id }) => id === medicineKey)
              ?.isAccutane
        );
      },
      Component: AccutaneContraindicationsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.stressLevel),
      Component: StressLevelFollowUpSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.sleepAmount),
      Component: SleepAmountSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.dairyConsumption),
      Component: DairyConsumptionSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.progressFeelings),
      Component: ProgressFeelingsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.additionalQuestions),
      Component: AdditionalQuestionsSurveyQuestion,
    },
    {
      isFilled: (data) =>
        !isEmpty(data.phqAnswer1) && !isEmpty(data.phqAnswer2),
      when: (data) => data.accutaneContraindications,
      Component: PHQSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.depressionHistory),
      when: (data) =>
        !isEmpty(data.phqAnswer1) &&
        !isEmpty(data.phqAnswer2) &&
        data.phqAnswer1 + data.phqAnswer2 >= 3,
      Component: DepressionHistorySurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.wasDepressionTreated),
      when: (data) =>
        !isEmpty(data.phqAnswer1) &&
        !isEmpty(data.phqAnswer2) &&
        data.phqAnswer1 + data.phqAnswer2 >= 3,
      Component: WasDepressionTreatedSurveyQuestion,
    },
    {
      isFilled: () => false,
      Component: SkinImagesFollowUpSurveyQuestion,
    },
  ];

  const submit = async (data: FollowUpSurvey) => {
    if (!activePatientId) return;

    const { skinImages: skinImagesBlobs } = data;

    const imagesPayload = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const skinImage of skinImagesBlobs) {
      const fileName = generateImageFilename(
        skinImage.image,
        activePatientId as string
      );
      const presignedUrl = await HoneydewAPI.followUps.getPresignedUrl(
        fileName
      );

      await axios.put(presignedUrl, skinImage.image, {
        headers: {
          "Content-Type": skinImage.image.type as string,
        },
      });
      imagesPayload.push({
        id: v4(),
        fileName,
        side: skinImage.side,
      });
    }

    const questionnaire: FollowUpQuestionnaire[] = Object.entries(data)
      .filter(([key]) => !["id", "skinImages"].includes(key))
      .map(([key, _value]) => {
        const { type, value } = followUpSurveyValueToDB(_value);
        return {
          id: key,
          type,
          value,
        };
      });

    await submitFollowUp({
      patientId: activePatientId,
      images: imagesPayload,
      questionnaire,
    });
  };

  function render() {
    switch (state) {
      case STATES.INIT:
        return (
          <div className="survey-page">
            <MedicalBackgroundImage className="survey-page__image" />
            <p className="survey-page__title">Start your follow-up visit</p>
            <p className="survey-page__initial-text paragraph-font--color">
              Follow up visits are important after certain intervals of time to
              evaluate the progress of your treatment and adjust your
              prescriptions as necessary.
            </p>
            <p className="survey-page__text paragraph-font--color">
              Your provider recommended you to follow up on or shortly after{" "}
              <strong>
                {lastTreatmentPlan ? (
                  getAmericanFormatDate(lastTreatmentPlan.nextFollowUpDate)
                ) : (
                  <Skeleton />
                )}
                .
              </strong>
              <br />
              <br />
              You will be asked to answer a few questions and upload up-to-date
              photos of your skin.
              <br />
              If you have general questions about your care or want to check if
              you should follow-up before your suggested date, you can contact
              your care team via chat.
              <br />
            </p>
            <Button
              text="Okay, Let's go"
              onClick={() => {
                setState(STATES.SURVEY);
              }}
            />
          </div>
        );
      case STATES.SURVEY:
        return activePatientId && lastTreatmentPlan ? (
          <CustomSurvey
            questions={followUpQuestionsBySkinIssue}
            data={{
              id: activePatientId,
            }}
            onComplete={async (_data) => {
              try {
                await submit(_data as FollowUpSurvey);
                setState(STATES.COMPLETE);
                return true;
              } catch (e) {
                showError({
                  title: "Something went wrong",
                  description:
                    "Could not submit your survey for some reason. Please, try again later.",
                });
                return false;
              }
            }}
            localStorage={`follow-up-survey-data:${activePatientId}`}
          />
        ) : (
          <Loader />
        );

      case STATES.COMPLETE:
        return (
          <div className="survey-page">
            <MedicalBackgroundImage className="survey-page__image" />
            <p className="survey-page__title">All done!</p>
            <p className="survey-page__text paragraph-font--color">
              Your provider will be in touch shortly with a response.
            </p>
            <Button text="Great!" onClick={() => history.push("/")} />
          </div>
        );
      default:
        return null;
    }
  }

  return render();
}
