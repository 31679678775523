import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import {
  Medicine,
  MedicineContent,
} from "../../../../../types/Entities/Medicine";
import { AuthenticationService } from "../../../../cognito";

export const create = async (data: MedicineContent) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Medicine>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/medicine/create`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot create medicine`);
    throw error;
  }
};
