import { Patient } from "../types/Entities/Patient";
import boolToString from "./bool-to-string";
import { skinIssues } from "../app/NewPages/Patient/SkinSurvey/skin-issues";
import { replaceText } from "./replaceText";

interface MapUserInfoToDashboardItem {
  article: string;
  description: string;
  key: string;
}

export interface MapUserInfoToDashboard {
  title: string;
  keyword: string;
  items: MapUserInfoToDashboardItem[];
}

function createDashboardItems(
  medBackgroundListToMap: Map<
    string,
    { value: string | null; questionType: string | null }
  >,
  filterQuestionType: string,
  targetQuestionType: string,
  optionalText: string
): MapUserInfoToDashboardItem[] {
  return Array.from(medBackgroundListToMap.entries())
    .filter(([key, entry]) => entry.questionType === filterQuestionType)
    .map(([key, entry]) => {
      const questionLabel = skinIssues
        .find((issue) => issue?.value === targetQuestionType)
        ?.subQuestions.find((question) => question?.value === key);

      const label = questionLabel?.label || key;

      return {
        article: replaceText(label, "your/your child’s", optionalText),
        description: entry.value || "N/A",
        key,
      };
    });
}

export function mapPatientInfoToDashboard(
  patient: Patient
): MapUserInfoToDashboard[] {
  const optionalText = patient?.parentInfo ? "your child’s" : "your";

  const medBackgroundListToMap = patient?.medicalBackground?.skinSurvey?.reduce(
    (map, { id, type, value, questionType }) => {
      let entry = map.get(id);
      if (!entry) {
        entry = { value: null, questionType: null };
      }

      switch (type) {
        case "text":
          entry.value = value;
          break;
        case "list":
          try {
            entry.value = JSON.parse(value).join(", ");
          } catch (error) {
            if (Array.isArray(value)) {
              entry.value = value.join(", ");
            } else {
              entry.value = value;
            }
          }
          break;
        case "bool":
          entry.value = boolToString(value);
          break;
        case "number":
          entry.value = value;
          break;
        default:
          throw new Error(
            `Unknown skin survey answer type "${type}" for id "${id}"`
          );
      }

      // Set the questionType in the entry
      entry.questionType = questionType;

      // Update the entry in the map
      map.set(id, entry);

      return map;
    },
    new Map<string, { value: string | null; questionType: string | null }>()
  );
  let resultArray: MapUserInfoToDashboard[];
  const targetQuestionType =
    medBackgroundListToMap?.get("skinIssueType")?.value;
  if (targetQuestionType) {
    const skinIssuesTypeHistory: MapUserInfoToDashboard = {
      title: `${targetQuestionType} History`,
      keyword: targetQuestionType,
      items: createDashboardItems(
        medBackgroundListToMap,
        targetQuestionType,
        targetQuestionType,
        optionalText
      ),
    };

    const medicalHistory: MapUserInfoToDashboard = {
      title: "Other Medical History",
      keyword: "otherConditions",
      items: createDashboardItems(
        medBackgroundListToMap,
        "otherMedical",
        targetQuestionType,
        optionalText
      ),
    };

    const lifeStyle: MapUserInfoToDashboard = {
      title: "Life Style",
      keyword: "lifestyle",
      items: createDashboardItems(
        medBackgroundListToMap,
        "lifestyle",
        targetQuestionType,
        optionalText
      ),
    };

    const femaleCycle: MapUserInfoToDashboard = {
      title: "Cycle & Menstruation",
      keyword: "cycleAndMenstruation",
      items: createDashboardItems(
        medBackgroundListToMap,
        "cycleAndMenstruation",
        targetQuestionType
      ),
    };

    resultArray = [
      skinIssuesTypeHistory,
      ...(patient?.medicalBackground?.sex === "Female" ? [femaleCycle] : []),
      medicalHistory,
      lifeStyle,
    ];
  } else {
    const skinInfo: MapUserInfoToDashboard = {
      title: "Skin type",
      keyword: "skin",
      items: [
        {
          article: "Dryness",
          description:
            medBackgroundListToMap?.get("skinDryness")?.value || "N/A",
          key: "dryness",
        },
        {
          article: "Sensitivity",
          description:
            medBackgroundListToMap?.get("skinSensitivity")?.value || "N/A",
          key: "sensitivity",
        },
        {
          article: "Had acne for",
          key: "hadAcneFor",
          description:
            medBackgroundListToMap?.get("acnePeriod")?.value || "N/A",
        },
      ],
    };
    const acneHistory: MapUserInfoToDashboard = {
      title: "Acne history",
      keyword: "acne",
      items: [
        {
          article: "Products tried",
          description:
            medBackgroundListToMap?.get("previousAcneProductsTaken")?.value ||
            "No",
          key: "productsTried",
        },
        {
          article: "Prescription topicals",
          description:
            medBackgroundListToMap?.get("prescriptionCreams")?.value || "No",
          key: "prescriptionTopicals",
        },
        {
          article: "Prescription pills",
          description:
            medBackgroundListToMap?.get("prescriptionPills")?.value || "No",
          key: "prescriptionPills",
        },
        {
          article: "Non-prescriptions in use",
          description:
            medBackgroundListToMap?.get("currentNonPrescriptions")?.value ||
            "No",
          key: "nonPrescriptionsInUse",
        },
        {
          article: "Current use",
          description:
            medBackgroundListToMap?.get("currentPrescriptions")?.value || "No",
          key: "currentUse",
        },
        {
          article: "Interested in",
          description:
            medBackgroundListToMap?.get("interestingMedications")?.value ||
            "No",
          key: "interestedIn",
        },
      ],
    };
    const medicalHistory: MapUserInfoToDashboard = {
      title: "Other Medical History",
      keyword: "otherConditions",
      items: [
        {
          article: "Allergies",
          description:
            (medBackgroundListToMap?.get("isAnyPrescriptionAllergies")
              ?.value === "Yes"
              ? medBackgroundListToMap?.get("prescriptionAllergies")?.value
              : medBackgroundListToMap?.get("isAnyPrescriptionAllergies")
                  ?.value) || "N/A",
          key: "allergies",
        },
        {
          article: "Other medications",
          description:
            (medBackgroundListToMap?.get("isAnyMedicalConditionPillsInUse")
              ?.value === "Yes"
              ? medBackgroundListToMap?.get("medications")?.value
              : medBackgroundListToMap?.get("isAnyMedicalConditionPillsInUse")
                  ?.value) || "N/A",
          key: "otherMedications",
        },
      ],
    };
    const lifestyle: MapUserInfoToDashboard = {
      title: "Lifestyle",
      keyword: "lifestyle",
      items: [
        {
          article: "Sleep amount",
          description:
            medBackgroundListToMap?.get("sleepAmount")?.value || "N/A",
          key: "sleepAmount",
        },
        {
          article: "Stress level",
          description:
            medBackgroundListToMap?.get("stressLevel")?.value || "N/A",
          key: "stressLevel",
        },
        {
          article: "Dairy consumption",
          description:
            medBackgroundListToMap?.get("dairyConsumption")?.value || "N/A",
          key: "dairyConsumption",
        },
      ],
    };
    const femaleCycle: MapUserInfoToDashboard = {
      title: "Cycle & Menstruation",
      keyword: "cycleAndMenstruation",
      items: [
        {
          article: "Pregnancy",
          description: medBackgroundListToMap?.get("pregnancy")?.value || "N/A",
          key: "pregnancy",
        },
        {
          article: "Breastfeeding",
          description:
            medBackgroundListToMap?.get("breastfeeding")?.value || "N/A",
          key: "breastfeeding",
        },
        {
          article: "Menstruation",
          description:
            medBackgroundListToMap?.get("menstruationBreakout")?.value || "N/A",
          key: "menstruation",
        },
        {
          article: "Regular Cycle",
          description:
            medBackgroundListToMap?.get("isRegularMenstruationCycle")?.value ||
            "N/A",
          key: "regularCycle",
        },
        {
          article: "Birth Control",
          description:
            medBackgroundListToMap?.get("birthControlMeds")?.value || "N/A",
          key: "birthControl",
        },
        {
          article: "History of PCOS",
          description: medBackgroundListToMap?.get("isPcos")?.value || "N/A",
          key: "historyOfPcos",
        },
      ],
    };
    resultArray = [
      skinInfo,
      acneHistory,
      ...(patient?.medicalBackground?.sex === "Female" ? [femaleCycle] : []),
      medicalHistory,
      lifestyle,
    ];
  }
  const filteredResult = resultArray.filter((obj) => obj.items.length > 0);

  return filteredResult;
}
