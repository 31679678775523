import React, { useContext, useEffect, useState } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import moment from "moment";
import {
  BasicPopupTemplate,
  BasicPopupTemplateContext,
} from "../../../NewComponents/Common/PopupLayer/basic";
import StyledSelect from "../../../NewComponents/Common/StyledSelect/StyledSelect";
import { TextInput } from "../../../NewComponents/Common/TextInput";
import { Button, SubmitButton } from "../../../NewComponents/Common/Button";
import { DateInput } from "../../../NewComponents/Common/DateInput";
import DateSelect from "../../../NewComponents/Common/DateSelect";
import DateTimeSelect from "../../../NewComponents/Common/DateTimeSelect";
import { ENV_CONFIG } from "../../../../config";
import "./styles.scss";
import {
  useCreateEmployeeTimeOffEventMutation,
  useGetEmployeesByRoleQuery,
} from "../../../../features/api/employees";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { convertToMilliseconds } from "../../../../utils/employee/convert-to-milliseconds";
import { StyledSelectCreatable } from "../../../NewComponents/Common/StyledSelect";

const poolData = {
  UserPoolId: ENV_CONFIG.USER_POOL_ID,
  ClientId: ENV_CONFIG.CLIENT_ID,
};

const cognitoService = new CognitoUserPool(poolData);

function TimeOffForm({ onClose, type, role }: any) {
  const [employees, setEmployees] = useState([]);
  const [addTimeOffPayload, setAddTimeOffPayload] = useState({
    employeedId: "",
    startDate: "",
    endDate: "",
    startTime: "08:00 PM",
    endTime: "08:00 PM",
  });

  const [errors, setErrors] = useState({
    employeedId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  });

  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const employeesQuery = useGetEmployeesByRoleQuery(role);
  const [createEmployeeTimeOffEvent] = useCreateEmployeeTimeOffEventMutation();
  const currentUser = cognitoService.getCurrentUser();

  useEffect(() => {
    if (employeesQuery.data) {
      const filteredEmployees = employeesQuery.data.filter(
        (obj) => obj.archived !== true
      );
      const transformedEmployees: any = filteredEmployees.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      }));
      const sortedListOfEmployees = transformedEmployees.sort(
        (a: any, b: any) => a.label.localeCompare(b.label)
      );
      setEmployees(sortedListOfEmployees);
    }
  }, [employeesQuery]);

  const handleSelectChange = (selectedOption: any) => {
    setAddTimeOffPayload({
      ...addTimeOffPayload,
      employeedId: selectedOption.value,
    });
    setErrors({ ...errors, employeedId: "" }); // Reset the error for the employeedId field
  };

  const handleDateChange = (name: string, value: any) => {
    setAddTimeOffPayload({ ...addTimeOffPayload, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Reset the error for the specific field
  };

  const handleTimeChange = (name: string, value: any) => {
    setAddTimeOffPayload({ ...addTimeOffPayload, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Reset the error for the specific field
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = { ...errors };

    if (!addTimeOffPayload.employeedId) {
      newErrors.employeedId = "Employee is required";
      formIsValid = false;
    }

    if (!addTimeOffPayload.startDate) {
      newErrors.startDate = "Start date is required";
      formIsValid = false;
    }

    if (!addTimeOffPayload.endDate) {
      newErrors.endDate = "End date is required";
      formIsValid = false;
    }

    if (!addTimeOffPayload.startTime) {
      newErrors.startTime = "Start time is required";
      formIsValid = false;
    }

    if (!addTimeOffPayload.endTime) {
      newErrors.endTime = "End time is required";
      formIsValid = false;
    }

    if (
      addTimeOffPayload.startDate &&
      addTimeOffPayload.endDate &&
      new Date(addTimeOffPayload.startDate) >
        new Date(addTimeOffPayload.endDate)
    ) {
      newErrors.startDate = "Start date should be before end date";
      formIsValid = false;
    }

    if (
      addTimeOffPayload.startDate &&
      addTimeOffPayload.endDate &&
      addTimeOffPayload.startDate === addTimeOffPayload.endDate
    ) {
      const startTime = moment(addTimeOffPayload.startTime, "hh:mm A");
      const endTime = moment(addTimeOffPayload.endTime, "hh:mm A");

      if (
        startTime.isValid() &&
        endTime.isValid() &&
        startTime.isAfter(endTime)
      ) {
        newErrors.startTime = "Start time should be before end time";
        formIsValid = false;
      }
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleFormSubmission = async () => {
    if (validateForm()) {
      const startDateTimeInMs = convertToMilliseconds(
        addTimeOffPayload.startDate,
        addTimeOffPayload.startTime
      );
      const endDateTimeInMs = convertToMilliseconds(
        addTimeOffPayload.endDate,
        addTimeOffPayload.endTime
      );

      try {
        const response = await createEmployeeTimeOffEvent({
          employeeId: addTimeOffPayload.employeedId,
          start: startDateTimeInMs,
          end: endDateTimeInMs,
          employeeRole: role,
          createdBy: currentUser?.username,
          lastModifiedBy: currentUser?.username,
        });
        if (response?.error)
          showError({
            title: "Error Occured",
            description: "Cannot create time off event",
          });
        else
          showSuccess({
            title: "Success",
            description: "Time Off event added sucessfully",
          });
        onClose();
      } catch (error) {
        showError({
          title: "Error Occured",
          description: "Cannot create time off event",
        });
        console.log("response in catch", error);
      }
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        className="timeoff-form"
        style={{
          height: "75%",
          display: "flex",
          flexDirection: "column",
          gap: "2%",
        }}
      >
        <div className="insurance-form__form-container sr-combo-inputs">
          <div className="timeoff-form-fields sr-combo-inputs-row">
            <span className="timeoff-popup-form-labels">EMPLOYEE'S NAME</span>
            <div>
              <StyledSelectCreatable
                id="employee-select"
                name="employeedId"
                onChange={handleSelectChange}
                options={employees}
                placeholder="Select an employee"
                filterOption={(option: any, searchText: any) => {
                  if (!searchText) {
                    // setShowCreation(false);
                    return true;
                  }
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return true;
                  }
                  // setShowCreation(true);
                  return false;
                }}
                isSearchable
                isValidNewOption={() => false}
              />

              {errors.employeedId && (
                <span className="error-message">{errors.employeedId}</span>
              )}
            </div>
          </div>
        </div>
        <div className="timeoff-form-fields sr-combo-inputs-row">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="timeoff-fields-size">
              <span className="timeoff-popup-form-labels">FROM</span>
              <DateSelect
                placeholder="mm-dd-yyyy"
                onChange={(date) => handleDateChange("startDate", date)}
                disablePastDates
              />
              {errors.startDate && (
                <span className="error-message">{errors.startDate}</span>
              )}
            </div>
            <div className="timeoff-fields-size">
              <span className="timeoff-popup-form-labels">TIME</span>
              <DateTimeSelect
                placeholder="Select time"
                value={addTimeOffPayload.startTime || ""}
                onChange={(time) => handleTimeChange("startTime", time)}
              />
              {errors.startTime && (
                <span className="error-message">{errors.startTime}</span>
              )}
            </div>
          </div>
        </div>
        <div className="timeoff-form-fields sr-combo-inputs-row">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="timeoff-fields-size">
              <span className="timeoff-popup-form-labels">TO</span>
              <DateSelect
                placeholder="mm-dd-yyyy"
                onChange={(date) => handleDateChange("endDate", date)}
                disablePastDates
              />
              {errors.endDate && (
                <span className="error-message">{errors.endDate}</span>
              )}
            </div>
            <div className="timeoff-fields-size">
              <span className="timeoff-popup-form-labels">TIME</span>
              <DateTimeSelect
                placeholder="Select time"
                value={addTimeOffPayload.endTime || ""}
                onChange={(time) => handleTimeChange("endTime", time)}
              />
              {errors.endTime && (
                <span className="error-message">{errors.endTime}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          height: "25%",
          justifyContent: "end",
          alignItems: "end",
          gap: "15px",
        }}
      >
        <div>
          <Button
            size="small"
            className="employees-dashboard__button--cancel"
            text="CANCEL"
            onClick={onClose}
          />
        </div>
        <div>
          <Button
            size="small"
            className="employees-dashboard__button--submit"
            text="SUBMIT & ADD TIME OFF"
            onClick={handleFormSubmission}
          />
        </div>
      </div>
    </div>
  );
}

export default TimeOffForm;

export function TimeOffPopupGenerator(role, type) {
  return function render({ onClose }) {
    return (
      <BasicPopupTemplate
        title={type === "edit" ? "Edit time off" : "Add time off"}
        onClose={onClose}
      >
        <TimeOffForm onClose={onClose} type={type} role={role} />
      </BasicPopupTemplate>
    );
  };
}
