import { createSlice } from "@reduxjs/toolkit";
import { ReduxStore } from "../types/Stores";

const initialState = {
  isError: false,
  initialized: false,
};

export const twilioSlice = createSlice({
  name: "twilio",
  initialState,
  reducers: {
    activate(state) {
      state.isError = false;
      state.initialized = true;
    },
    deactivate(state) {
      state.isError = false;
      state.initialized = false;
    },
    setError(state) {
      state.initialized = true;
      state.isError = true;
    },
  },
});

export const { activate, deactivate, setError } = twilioSlice.actions;

export const getTwilioState = ({ twilioStore }: ReduxStore) => twilioStore;

export default twilioSlice.reducer;
