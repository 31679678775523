import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { AuthenticationService } from "../../../../cognito";

interface Body {
  dateFrom: number;
  dateTo: number;
}

export const generateReport = async (
  providerId: string,
  { dateFrom, dateTo }: Body
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<{
      billingReport: string;
    }>(
      `${ENV_CONFIG.ROUTE_53_BASE_URL}/providers/billing-reports?id=${providerId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get provider report");
    throw error;
  }
};
