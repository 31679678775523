import { createSlice } from "@reduxjs/toolkit";
import { ReduxAction, ReduxStore } from "../types/Stores";
import { EditPatientInfoStore } from "../types/Stores/EditPatientInfoStore";

const initialState: EditPatientInfoStore = {
  isShown: false,
  type: null,
};

export const editPatientInfoSlice = createSlice({
  name: "editPatientInfo",
  initialState,
  reducers: {
    showEditPatientInfoPopup: (_state, action: ReduxAction<string>) => {
      const state = _state;
      state.type = action.payload;
      state.isShown = true;
    },
    hideEditPatientInfoPopup: (_state) => {
      const state = _state;
      state.type = null;
      state.isShown = false;
    },
  },
});

export const { showEditPatientInfoPopup, hideEditPatientInfoPopup } =
  editPatientInfoSlice.actions;
export const isShownSelector = ({ editPatientInfoStore }: ReduxStore) =>
  editPatientInfoStore.isShown;
export const typeSelector = ({ editPatientInfoStore }: ReduxStore) =>
  editPatientInfoStore.type;

export default editPatientInfoSlice.reducer;
