import React, { useState } from "react";
import feelingsList from "../../../data/patientAcneFeelings";
import "./style.css";

interface Props {
  onChange: (value: string) => void;
}

export function AcneFeelingsPicker({ onChange }: Props) {
  const [choosenVariant, setVariant] = useState<string | null>(null);

  return (
    <div className="acne-feelings-block">
      {feelingsList.map((el) => (
        <div
          className={`acne-feelings-block__element ${
            el.value === choosenVariant
              ? "acne-feelings-block__element--active"
              : ""
          }`}
          onClick={() => {
            setVariant(el.value);
            onChange(el.value);
          }}
        >
          <div className="acne-feelings-block__content">
            <el.img className="acne-feelings-block__image" />
            <p className="acne-feelings-block__text">{el.value}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
