import store from "../../store";
import { appointmentsApiSlice } from "../api/appointments";
import { employeesApiSlice } from "../api/employees";
import { followUpsApiSlice } from "../api/follow-ups";
import { patientsApiSlice } from "../api/patients";
import { paymentApiSlice } from "../api/payment";
import { accutaneApiSlice } from "../api/accutane";
import { medicationsApiSlice } from "../api/medications";

export function clearAllStores() {
  store.dispatch(patientsApiSlice.util.resetApiState());
  store.dispatch(appointmentsApiSlice.util.resetApiState());
  store.dispatch(followUpsApiSlice.util.resetApiState());
  store.dispatch(employeesApiSlice.util.resetApiState());
  store.dispatch(paymentApiSlice.util.resetApiState());
  store.dispatch(accutaneApiSlice.util.resetApiState());
  store.dispatch(medicationsApiSlice.util.resetApiState());
}
