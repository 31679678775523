import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientDashboardPageForEnrollmentCoordinator } from "../../NewPages/EnrollmentCoordinator/PatientDashboard";
import { PatientsPageForEnrollmentCoordinator } from "../../NewPages/EnrollmentCoordinator/PatientsPage";
import { Menu } from "../../NewComponents/Common/Menu";
import { RescheduleAppointmentPage } from "../../NewPages/Common/RescheduleAppointmentPage";
import { Footer } from "../../NewComponents/Common/Footer";
import { getSessionState } from "../../../features/session";
import { ChatLayer } from "../../NewComponents/Common/ChatLayer";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";
import EnrollmentRescheduleComponent from "./EnrollmentRescheduleComponent";
import PatientDashboardForEnrollmentComponent from "./PatientDashboardForEnrollmentComponent";
import EnrollmentProtectedRoutes from "./EnrollmentProtectedRoutes";
// import { PatientDashboardPageForEnrollmentCoordinator } from "../../Pages/EnrollmentCoordinator/PatientDashboardPage";

export function EnrollmentCoordinatorView() {
  const location = useLocation();
  const { userId, userRole } = useSelector(getSessionState);

  if (!userId || !userRole) return null;

  return (
    <ChatLayer identity={userId} userRole={userRole}>
      <Menu />
      <EnrollmentProtectedRoutes />
    </ChatLayer>
  );
}

export default EnrollmentCoordinatorView;
