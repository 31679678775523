import React, { FormEvent, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import { show } from "../../../../features/errorNotification";
import { logError } from "../../../../shared/logger";
import {
  BasicPopupTemplate,
  BasicPopupTemplateContext,
} from "../../Common/PopupLayer/basic";
import { TextInput } from "../../Common/TextInput";
import { SubmitButton } from "../../Common/Button";
import { Patient } from "../../../../types/Entities/Patient";
import { useUpdateInsuranceInfoMutation } from "../../../../features/api/patients";
import { NotificationLayerContext } from "../../Common/NotificationLayer";

interface Props {
  onClose: () => void;
}

function InsuranceForm({ patient }: { patient: Patient }) {
  const { onClose } = useContext(BasicPopupTemplateContext);
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [updateInsuranceInfo, { isSuccess, isError, isLoading }] =
    useUpdateInsuranceInfoMutation();

  const [insuranceName, setInsuranceName] = useState(
    patient.insurance?.insuranceName || ""
  );
  const [policyHolderName, setPolicyHolderName] = useState(
    patient.insurance?.policyHolderName || ""
  );
  const [groupNumber, setGroupNumber] = useState(
    patient.insurance?.groupNumber || ""
  );
  const [rxBinNumber, setRxBinNumber] = useState(
    patient.insurance?.rxBinNumber || ""
  );
  const [memberId, setMemberId] = useState(patient.insurance?.memberId || "");

  useEffect(() => {
    if (isError) {
      showError({
        title: "Something went wrong...",
        description: "Cannot update insurance info",
      });
      return;
    }
    if (isSuccess) {
      showSuccess({
        title: "Success!",
        description: "Insurance info have been successfully updated",
      });
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showError({
        title: "Something went wrong...",
        description: "Cannot update insurance info",
      });
    }
  }, [isError]);

  async function submit(event: FormEvent) {
    event.preventDefault();
    const payload = {
      memberId,
      insuranceName,
      policyHolderName,
      groupNumber,
      rxBinNumber,
    };
    // TODO: insurance info mutation
    await updateInsuranceInfo({
      patientId: patient.patientId,
      insuranceInfo: payload,
    });
  }

  return (
    <form className="insurance-form" onSubmit={submit}>
      <div className="insurance-form__form-container sr-combo-inputs">
        <div className="insurance-form__input-wrapper sr-combo-inputs-row">
          <TextInput
            id="insuranceName"
            value={insuranceName}
            onChange={(value) => setInsuranceName(value)}
            name="insuranceName"
            label="Health Plan Name"
          />
        </div>
        <div className="insurance-form__input-wrapper sr-combo-inputs-row">
          <TextInput
            id="memberId"
            value={memberId}
            onChange={(value) => setMemberId(value)}
            name="memberId"
            label="Member ID"
          />
        </div>
      </div>
      <div className="insurance-form__input-wrapper sr-combo-inputs-row">
        <TextInput
          id="groupNumber"
          value={groupNumber}
          onChange={(value) => setGroupNumber(value)}
          name="groupNumber"
          label="Group Number"
        />
      </div>
      <div className="insurance-form__input-wrapper sr-combo-inputs-row">
        <TextInput
          id="rxBinNumber"
          value={rxBinNumber}
          onChange={(value) => setRxBinNumber(value)}
          name="rxBinNumber"
          label="RxBin Number"
        />
      </div>
      <div className="insurance-form__input-wrapper sr-combo-inputs-row">
        <TextInput
          id="policyHolderName"
          value={policyHolderName}
          onChange={(value) => setPolicyHolderName(value)}
          name="policyHolderName"
          label="Policy Holder Name"
        />
      </div>
      <SubmitButton
        text="save"
        status={isLoading ? "loading" : undefined}
        className="insurance-form__submit-btn"
      />
    </form>
  );
}

export function InsurancePopupGenerator({ patient }: { patient: Patient }) {
  return function render({ onClose }: Props) {
    return (
      <BasicPopupTemplate title="Insurance form" onClose={onClose}>
        <InsuranceForm patient={patient} />
      </BasicPopupTemplate>
    );
  };
}
