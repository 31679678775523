const initialFollowUp = {
  bool: {
    should: [
      {
        bool: {
          must_not: {
            exists: {
              field: "followUp",
            },
          },
        },
      },
      {
        bool: {
          must: [
            {
              match: {
                "followUp.isInitialFollowUp": true,
              },
            },
            {
              match: {
                "followUp.isTreatmentPlanSet": false,
              },
            },
          ],
        },
      },
    ],
  },
};

const notArchived = {
  bool: {
    must_not: {
      match: {
        archived: true,
      },
    },
  },
};

export function getPatientDashboardForEnrollmentCoordinatorFiltersUpcomingVisits() {
  return [
    {
      text: "All",
      filter: {
        must: [
          {
            match_all: {},
          },
          initialFollowUp,
          notArchived,
          {
            bool: {
              should: [
                {
                  match: {
                    appointmentStatus: "PENDING",
                  },
                },
                {
                  match: {
                    appointmentStatus: "CONFIRMED",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      text: "Unconfirmed",
      filter: {
        must: [
          { match_all: {} },
          initialFollowUp,
          notArchived,
          {
            match: {
              appointmentStatus: "PENDING",
            },
          },
        ],
      },
    },
    {
      text: "Confirmed",
      filter: {
        must: [
          { match_all: {} },
          initialFollowUp,
          notArchived,
          {
            match: {
              appointmentStatus: "CONFIRMED",
            },
          },
        ],
      },
    },
    {
      text: "Pending medical background",
      filter: {
        must: [
          { match_all: {} },
          initialFollowUp,
          notArchived,
          {
            bool: {
              should: [
                {
                  match: {
                    appointmentStatus: "PENDING",
                  },
                },
                {
                  match: {
                    appointmentStatus: "CONFIRMED",
                  },
                },
              ],
            },
          },
          {
            match: {
              isMedicalBackgroundExists: "false",
            },
          },
        ],
      },
    },
  ];
}

const noMembership = {
  bool: {
    must_not: [
      {
        exists: {
          field: "subscription.membership",
        },
      },
    ],
  },
};

export function getPatientDashboardForEnrollmentCoordinatorFiltersPostEnrollment() {
  return [
    {
      text: "All",
      filter: {
        must: [
          {
            match_all: {},
          },
          noMembership,
          notArchived,
        ],
        must_not: initialFollowUp,
      },
    },
    {
      text: "Uncontacted",
      filter: {
        must: [
          { match_all: {} },
          {
            bool: {
              must_not: {
                match: {
                  "flags.contacted": true,
                },
              },
            },
          },
          {
            bool: {
              must_not: initialFollowUp,
            },
          },
          noMembership,
          notArchived,
        ],
      },
    },
    {
      text: "Contacted",
      filter: {
        must: [
          { match_all: {} },
          {
            bool: {
              must: {
                match: {
                  "flags.contacted": true,
                },
              },
            },
          },
          {
            bool: {
              must_not: initialFollowUp,
            },
          },
          noMembership,
          notArchived,
        ],
      },
    },
    {
      text: "Pending medical background",
      filter: {
        must: [
          { match_all: {} },
          {
            bool: {
              must: {
                match: {
                  isMedicalBackgroundExists: "false",
                },
              },
            },
          },
          {
            bool: {
              must_not: initialFollowUp,
            },
          },
          noMembership,
          notArchived,
        ],
      },
    },
  ];
}

export function getPatientDashboardForEnrollmentCoordinatorFiltersDeclinedVisits() {
  return [
    {
      text: "All",
      filter: {
        must: [
          {
            match_all: {},
          },
          initialFollowUp,
          {
            bool: {
              should: [
                {
                  match: {
                    appointmentStatus: "CANCELLED",
                  },
                },
                {
                  match: {
                    appointmentStatus: "NO_SHOW",
                  },
                },
              ],
            },
          },
          noMembership,
          notArchived,
        ],
      },
    },
    {
      text: "Cancelled",
      filter: {
        must: [
          { match_all: {} },
          initialFollowUp,
          {
            match: {
              appointmentStatus: "CANCELLED",
            },
          },
          noMembership,
          notArchived,
        ],
      },
    },
    {
      text: "No show",
      filter: {
        must: [
          { match_all: {} },
          initialFollowUp,
          {
            match: {
              appointmentStatus: "NO_SHOW",
            },
          },
          noMembership,
          notArchived,
        ],
      },
    },

    {
      text: "Pending medical background",
      filter: {
        must: [
          { match_all: {} },
          initialFollowUp,
          {
            bool: {
              should: [
                {
                  match: {
                    appointmentStatus: "CANCELLED",
                  },
                },
                {
                  match: {
                    appointmentStatus: "NO_SHOW",
                  },
                },
              ],
              must_not: [
                {
                  match: {
                    isMedicalBackgroundExists: "true",
                  },
                },
              ],
            },
          },
          noMembership,
          notArchived,
        ],
      },
    },
  ];
}
