import React, { useState } from "react";
import Calendar from "react-calendar";
import { ReactComponent as CalendarIcon } from "../../../../Assets/NewIcons/calendar-icon.svg";
import { getLocalDateNormal } from "../../../../utils/get-date-pretty";
import { TextInput } from "../TextInput";
import "./style.scss";
import OutsideClickHandler from "../../../Components/Common/OutsideClickHandler/OutsideClickHandler";

interface DateSelectProps {
  placeholder: string;
  value?: string;
  onChange: (v: string) => void;
  disableFutureDates?: boolean;
  disablePastDates?: boolean;
}

const parseDateString = (dateString: any) => {
  let parts;
  if (dateString.includes("-")) {
    parts = dateString.split("-");
  } else if (dateString.includes("/")) {
    parts = dateString.split("/");
  } else {
    return new Date(dateString); // Fallback for unexpected formats
  }

  const [month, day, year] = parts;
  return new Date(year, month - 1, day);
};

function DateSelect({
  placeholder,
  value,
  onChange,
  disableFutureDates = false,
  disablePastDates = false,
}: DateSelectProps) {
  const [isOpened, setOpened] = useState(false);
  const [currentValue, setValue] = useState(value || "");

  console.log("currentValue", currentValue);
  const toggleCalendarVisibility = () => setOpened(!isOpened);
  const toggleCalendarClose = () => setOpened(false);

  return (
    <div className="date-select-wrapper">
      <OutsideClickHandler onClickOutside={toggleCalendarClose}>
        <>
          <TextInput
            id="date_select"
            name="date-select"
            readOnly
            type="text"
            value={currentValue}
            onChange={() => null}
            placeholder={placeholder}
            onBlur={toggleCalendarVisibility}
          />
          <CalendarIcon
            className="date-select-wrapper__calendar-icon"
            onClick={toggleCalendarVisibility}
          />
          {isOpened && (
            <Calendar
              calendarType="US"
              minDetail="month"
              onChange={(_date: any) => {
                const formattedDate = getLocalDateNormal(_date as Date).format(
                  "MM/DD/YYYY"
                );

                setOpened(false);
                setValue(formattedDate);
                onChange(formattedDate);
              }}
              value={currentValue ? parseDateString(currentValue) : null}
              className="date-select-wrapper__calendar"
              minDate={disablePastDates ? new Date() : undefined}
              maxDate={disableFutureDates ? new Date() : undefined}
            />
          )}
        </>
      </OutsideClickHandler>
    </div>
  );
}

export default DateSelect;
