import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { ProviderTask } from "../../../../../types/Provider";

interface GetTasksProps {
  userId: string;
  employeeId?: string;
  documentId?: string;
  taskId?: string;
}

const buildQuery = (
  employeeId?: string,
  documentId?: string,
  taskId?: string
) => {
  let query = "";

  if (employeeId) {
    query += `&employeeId=${employeeId}`;
  }
  if (documentId) {
    query += `&documentId=${documentId}`;
  }
  if (taskId) {
    query += `&taskId=${taskId}`;
  }

  return query;
};

export const getTasks = async (
  tasksProps: GetTasksProps
): Promise<ProviderTask[]> => {
  try {
    const query = buildQuery(
      tasksProps.employeeId,
      tasksProps.documentId,
      tasksProps.taskId
    );
    const result = await axios.get(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/get-tasks/${tasksProps.userId}${
        query ? `?${query}` : ""
      }`
    );

    return result.data.tasks;
  } catch (error) {
    logError("Error occurred when trying to receive tasks");
    throw error;
  }
};
