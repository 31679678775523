import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const getMultiAccGroup = async (patientId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const { data: patients } = await axios.get<Patient[]>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/get-patients-multi-acc-group`,
      {
        params: {
          id: patientId,
        },
        headers: {
          Authorization: token,
        },
      }
    );
    return patients;
  } catch (error) {
    logError("Error occured. Cannot get patients multi account group");
    throw error;
  }
};
