import React from "react";
import { HelpPopupTemplate } from "../PopupLayer/help";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import { Button } from "../Button";
import "./style.scss";

interface OrderedSignaturesFormProps {
  onClose: () => void;
  currentStep?: number;
}

function OrderedSignaturesFormPopup({
  onClose,
  currentStep,
}: OrderedSignaturesFormProps) {
  const onSubmitHandler = () => {
    onClose();
  };

  return (
    <div className="signatures-form-popup">
      <div className="signatures-form-popup">
        <div className="signatures-form-popup__content">
          <div className="signatures-form-popup__warning">
            <p className="signatures-form-popup__current-page">
              This document requires signatures from:
            </p>
            <ul className="signatures-form-popup__list">
              <li className="signatures-form-popup__list-element">
                <div className="signatures-form-popup__check">
                  <p>Patient</p>
                  {currentStep === 2 && (
                    <CheckIcon className="signatures-form-popup__check-icon" />
                  )}
                </div>
              </li>
              <li className="signatures-form-popup__list-element">
                Parent/Guardian
              </li>
            </ul>
            {currentStep === 1 ? (
              <p className="signatures-form-popup__warning__description">
                The Patient signatures are required first
              </p>
            ) : (
              <p className="signatures-form-popup__warning__description">
                Great! Now we just need Parent/Guardian signatures
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="signatures-form-popup__button-wrapper">
        <Button
          text="Start Signing"
          onClick={onSubmitHandler}
          size="small"
          className="signatures-form-popup__button-wrapper__button"
        />
      </div>
    </div>
  );
}

export function OrderedSignaturesPopupGenerator(step?: number) {
  return function render({ onClose }: OrderedSignaturesFormProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <OrderedSignaturesFormPopup onClose={onClose} currentStep={step} />
      </HelpPopupTemplate>
    );
  };
}
