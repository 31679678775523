import React, { useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { CheckboxesList } from "../../../../NewComponents/Common/CheckboxesList";

const ANSWERS = {
  VITAMIN_A: "Vitamin A supplements",
  TETRACYCLINE: "Tetracycline antibiotics",
  PROGESTIN: "Progestin only birth control (mini pill)",
  DILANTIN: "Dilantin (phenytoin)",
  PREDNISONE: "Oral steroids (such as Prednisone)",
  ST_JOHNS_WORT: "St. John's Wort",
  NOT_TAKING: "I am not taking any of these",
};

const list = [
  {
    value: ANSWERS.VITAMIN_A,
    label: ANSWERS.VITAMIN_A,
  },
  {
    value: ANSWERS.TETRACYCLINE,
    label: ANSWERS.TETRACYCLINE,
  },
  {
    value: ANSWERS.PROGESTIN,
    label: ANSWERS.PROGESTIN,
  },
  {
    value: ANSWERS.DILANTIN,
    label: ANSWERS.DILANTIN,
  },
  {
    value: ANSWERS.PREDNISONE,
    label: ANSWERS.PREDNISONE,
  },
  {
    value: ANSWERS.ST_JOHNS_WORT,
    label: ANSWERS.ST_JOHNS_WORT,
  },
  {
    value: ANSWERS.NOT_TAKING,
    label: ANSWERS.NOT_TAKING,
    onlySelectable: true,
  },
];

function surveyToComponent(data: SurveyData) {
  return data.accutaneContraindications;
}

function componentToSurvey(value: string) {
  return {
    accutaneContraindications: value,
  };
}

export function AccutaneContraindicationsSurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));

  function submit() {
    if (isEmpty(currentData)) return;

    onDone(componentToSurvey(currentData));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        Are you taking any of the following medications? Please check all that
        apply.
      </p>
      <p className="survey-question__descr paragraph-font--color">
        These medications are contraindications and will need to be stopped and
        cannot be taken during treatment.
      </p>
      <CheckboxesList
        list={list}
        currentValues={currentData}
        onChange={(value) => setData(value)}
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={isEmpty(currentData) || currentData.length === 0}
      />
    </div>
  );
}
