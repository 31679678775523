import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Accutane } from "../../../../../types/Entities/Accutane";

interface EnrollAccutaneResponse {
  accutane: Accutane;
  message: string;
}

export const enrollAccutane = async (
  userId: string,
  taskId: string
): Promise<EnrollAccutaneResponse> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/enroll/${userId}`,
      {
        taskId,
      }
    );

    return result.data;
  } catch (error) {
    logError("Error received when trying to enroll accutane");
    throw error;
  }
};
