import React, { useContext, useEffect, useState } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
// import { PatientDashboardPageForProvider } from "../../Pages/Provider/PatientDashboardPage";
import { useSelector } from "react-redux";
import { PatientsPageForProvider } from "../../NewPages/Provider/PatientsPage";
import { PatientFollowUpVisit } from "../../Pages/Provider/PatientFollowUpVisit";
import { Menu } from "../../NewComponents/Common/Menu";
import { PatientDashboardPageForProvider } from "../../NewPages/Provider/PatientDashboard";
import { Footer } from "../../NewComponents/Common/Footer";
import { getSessionState } from "../../../features/session";
import { ChatLayer } from "../../NewComponents/Common/ChatLayer";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";
import { NotificationLayerContext } from "../../NewComponents/Common/NotificationLayer";
import { useGetAssignedPatientsQuery } from "../../../features/api/patients";
import PatientFollowUpProviderComponent from "./PatientFollowUpProviderComponent";
import PatientDashboardForProviderComponent from "./PatientDashboardForProviderComponent";
import ProviderProtectedRoutes from "./ProviderProtectedRoutes";

export function ProviderView() {
  const { userId, userRole } = useSelector(getSessionState);
  const { showInfo } = useContext(NotificationLayerContext);

  if (!userId || !userRole) return null;

  return (
    <ChatLayer identity={userId} userRole={userRole}>
      <>
        <Menu />
        <ProviderProtectedRoutes />
      </>
    </ChatLayer>
  );
}

export default ProviderView;
