import React from "react";
import "./style.scss";

interface Props {
  className?: string;
  loadingText?: string;
}

export function Loader({ className, loadingText }: Props) {
  return (
    <div className={`loader-icon__wrap ${className || ""}`}>
      <div className="loader-icon">
        <div className="loader-icon__half-circle">
          <div className="loader-icon__left" />
          <div className="loader-icon__right" />
        </div>
        <div className="loader-icon__empty-part" />
      </div>
      {loadingText && <p className="loader-icon__wrap__text">{loadingText}</p>}
    </div>
  );
}
