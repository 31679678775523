import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "../../Common/Button";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import generateHash from "../../../../utils/generate-hash";
import { ImagePayload } from "../../../../types/Entities/Image";
import { ImageBlob } from "../../../../types/Base/ImageBlob";
import { ImageUploadTile } from "../../Patient/ImageUploadTile";
import { ReactComponent as DocumentIcon } from "../../../../Assets/NewIcons/document.svg";
import "./style.scss";
import { Input } from "../../Common/Input";
import { StyledSelect, StyledSelectCreatable } from "../../Common/StyledSelect";
import { getSessionState } from "../../../../features/session";
import { Provider } from "../../../../types/Entities/Provider";
import { CareCoordinator } from "../../../../types/Entities/CareCoordinator";

import {
  useGetCareTeamQuery,
  useGetPatientByIdQuery,
} from "../../../../features/api/patients";
import { useGetAllEmployeesQuery } from "../../../../features/api/employees";
import { USER_ROLES } from "../../../../types/Main";
import { assigneeOptions } from "../AssignTaskModalPopup/utils";
import { Skeleton } from "../../Common/Skeleton";
import {
  useUploadDocumentMutation,
  useGetPresignedUrlMutation,
} from "../../../../features/api/accutane";
import { getEmployeeFullName } from "../../../../utils/get-employee-full-name";
import { ENV_CONFIG } from "../../../../config";
import { NotificationLayerContext } from "../../Common/NotificationLayer";

interface Props {
  onClose: () => void;
}

interface UploadDocumentsForm {
  document: Blob | ImageBlob | null;
  title: string | null;
  task?: {
    label: string;
    value: string;
  } | null;
  assignee?: {
    id: string;
    name: string;
  } | null;
}

enum Role {
  TeamCareCoordinator = "Care coordinator",
  TeamProvider = "Provider",
  TeamEnrollmentCoordinator = "Enrollment coordinator",
  TeamAdmin = "Admin",
}

const mapStringToType = {
  [Role.TeamCareCoordinator]: "care-coordinator",
  [Role.TeamProvider]: "provider",
  [Role.TeamEnrollmentCoordinator]: "enrollment-coordinator",
  [Role.TeamAdmin]: "admin",
};

function UploadImagePreview() {
  return (
    <div className="upload-document-form__uploader--preview">
      <DocumentIcon />
      <p className="upload-document-form__uploader--preview-description">
        Document uploaded
      </p>
    </div>
  );
}

function DiscardButton({ discardImage }: { discardImage?: () => void }) {
  const handleDiscardImage = () => {
    if (discardImage) {
      discardImage();
    }
  };

  return (
    <p
      onClick={handleDiscardImage}
      className="upload-document-form__uploader__discard-button"
    >
      Replace
    </p>
  );
}

enum AssigneeType {
  TeamCoordinator = "CareCoordinator",
  TeamProvider = "Provider",
  TeamEnrollmentCoordinator = "EnrollmentCoordinator",
  TeamAdmin = "Admin",
}

interface IAssigneeOptions {
  id: string;
  firstName: string;
  lastName: string;
  type: AssigneeType;
}

const getPatientTeamMembers = (
  provider: Provider | undefined,
  coordinator: CareCoordinator | undefined
) => {
  const teamMembers = [];
  if (provider) {
    teamMembers.push({
      id: provider.id,
      firstName: provider.firstName,
      lastName: provider.lastName,
      type: AssigneeType.TeamProvider,
    });
  }
  if (coordinator) {
    teamMembers.push({
      id: coordinator.id,
      firstName: coordinator.firstName,
      lastName: coordinator.lastName,
      type: AssigneeType.TeamCoordinator,
    });
  }

  return teamMembers;
};

const preselectedTasks = [
  {
    label: "all tasks",
    options: [
      {
        label: "Review blood work",
        value: "bloodWork",
      },
    ],
  },
];

const taskSelectGroup = (data: any) => (
  <div>
    <span>{data.label.toUpperCase()}</span>
  </div>
);

// todo add type
function UploadDocumentsPopup({
  onClose,
  userId,
}: {
  onClose: () => void;
  userId: string;
}) {
  const { userId: employeeId, userRole } = useSelector(getSessionState);
  const [tasksOptions, updateOptions] = useState(preselectedTasks);
  const [taskOptionsLoading, setLoadingOptions] = useState(false);
  const [shouldShowCreation, setShowCreation] = useState(false);
  const [uploadForm, setFormField] = useState<UploadDocumentsForm>({
    document: null,
    title: null,
    task: null,
    assignee: null,
  });
  const [uploadDocument] = useUploadDocumentMutation();
  const allEmployeesQuery = useGetAllEmployeesQuery(null);
  const careTeamQuery = useGetCareTeamQuery(userId);
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const { showError } = useContext(NotificationLayerContext);

  const employeesOptions = allEmployeesQuery.data
    ?.filter(
      ({ role }) =>
        role === USER_ROLES.PROVIDER ||
        role === USER_ROLES.CARE_COORDINATOR ||
        role === USER_ROLES.ADMINISTRATOR
    )
    .map(({ id, firstName, lastName, role }) => ({
      id,
      firstName,
      lastName,
      type: role,
      inCareTeam: !!careTeamQuery.data?.find(
        ({ employeeId: _employeeId }) => _employeeId === id
      ),
    }));
  const shouldShowDocumentTitle = !!uploadForm.document;
  const shouldShowAssignee = !!uploadForm.task;

  const generateImagesFilename = () => {
    const extension = `${uploadForm.document?.type.split("/").pop()}`;

    return `${uploadForm.title}.${generateHash(10)}.${extension}`;
  };

  const onSubmit = async () => {
    const filename = generateImagesFilename();

    if (!uploadForm.document || !uploadForm.title || !employeeId || !userRole) {
      // Handle missing data, maybe show an error message
      return;
    }

    let roleExists = "";

    if (uploadForm.assignee) {
      roleExists =
        mapStringToType[
          uploadForm.assignee.name.split(" ").pop()?.slice(-1, 1) as Role
        ] || "";
    }

    const submitterEmployee = allEmployeesQuery.data?.find(
      ({ id }) => id === employeeId
    );

    const response = await getPresignedUrl({
      patientId: userId,
      document: {
        contentType: uploadForm.document.type || "",
        filename,
      },
    });

    if (response?.data?.preSignedUrl) {
      try {
        await axios.put(response.data.preSignedUrl, uploadForm.document, {
          headers: {
            "Content-Type": uploadForm.document.type as string,
          },
        });

        const result = await uploadDocument({
          patientId: userId,
          document: {
            title: uploadForm.title,
            url: `${ENV_CONFIG.AMAZON_S3_PATIENT_DOCUMENT_DOMAIN}/${filename}`,
            comment: "",
            uploadedBy: {
              id: employeeId,
              name: submitterEmployee
                ? getEmployeeFullName(submitterEmployee)
                : "N/A",
            },
            assignedTo: {
              id: uploadForm.assignee?.id || "",
              name: uploadForm.assignee?.name || "",
              role: roleExists,
            },
            assignedBy: {
              id: employeeId,
              role: userRole,
            },
            signedBy: {
              id: "",
              name: "",
              role: "",
              createdAt: "",
            },
            taskName: uploadForm.task?.label || null,
            type: uploadForm.task?.value || "",
          },
        });

        if (!result.error) {
          onClose();
        }
      } catch (error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to perform this action",
        });
      }
    } else {
      showError({
        title: "Something went wrong...",
        description: "Unable to perform get preSignedUrl",
      });
    }
    onClose();
  };

  const handleImageUpload = (image: Blob | ImageBlob | null) => {
    setFormField({ ...uploadForm, document: image });
  };

  const handleDocumentTitleChange = (title: string) => {
    setFormField({ ...uploadForm, title });
  };

  const handleAssigneeChange = (assignee: { label: string; value: string }) => {
    const name = assignee?.label.split(" ").slice(0, 2).join(" ");

    setFormField({
      ...uploadForm,
      assignee: assignee
        ? {
            id: assignee.value,
            name,
          }
        : null,
    });
  };

  const handleTaskChange = (task: { label: string; value: string }) => {
    setFormField({ ...uploadForm, task });
  };

  return (
    <div className="upload-document-form">
      <p className="upload-document-form__title">Upload a document</p>
      <div className="upload-document-form__uploader">
        <ImageUploadTile
          side="center"
          setImage={handleImageUpload}
          customPreview={<UploadImagePreview />}
          customDiscardButton={<DiscardButton />}
          placeholderClassName="upload-document-form__uploader-placeholder"
        />
      </div>
      {!!uploadForm.document && (
        <div
          className={`upload-document-form__fields-list ${
            shouldShowAssignee ? "borderless" : ""
          }`}
        >
          <div>
            {shouldShowDocumentTitle && (
              <div className="upload-document-form__fields-list__field">
                <p className="upload-document-form__fields-list__field_title">
                  Document title
                </p>
                <Input
                  id="document-title"
                  name="document-title"
                  onChange={handleDocumentTitleChange}
                  placeholder="Enter document title"
                />
              </div>
            )}
            {!!uploadForm.document && (
              <div className="upload-document-form__fields-list__field">
                <p className="upload-document-form__fields-list__field_title">
                  Task (Optional)
                </p>
                <StyledSelectCreatable
                  formatCreateLabel={(inputValue: string) =>
                    `${inputValue} (custom task)`
                  }
                  id="uploadDocumentTask"
                  name="uploadDocumentTask"
                  onChange={handleTaskChange}
                  value={uploadForm.task}
                  options={tasksOptions}
                  createOptionPosition="first"
                  filterOption={(option: any, searchText: any) => {
                    if (!searchText) {
                      setShowCreation(false);
                      return true;
                    }
                    if (
                      option.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    ) {
                      return true;
                    }

                    setShowCreation(true);
                    return false;
                  }}
                  onCreateOption={(option) => {
                    setLoadingOptions(true);
                    const newTask = { label: option, value: option };
                    setTimeout(() => {
                      handleTaskChange(newTask);
                      setLoadingOptions(false);
                    }, 0);
                    setShowCreation(true);
                  }}
                  isValidNewOption={() => shouldShowCreation}
                  formatGroupLabel={taskSelectGroup}
                  placeholder="Select a task"
                  isSearchable
                  disabled={taskOptionsLoading}
                />
                {/* <Input */}
                {/*  id="document-task" */}
                {/*  name="document-task" */}
                {/*  onChange={handleDocumentTaskChange} */}
                {/*  placeholder="Enter task" */}
                {/*  disabled={isLoading} */}
                {/* /> */}
              </div>
            )}
          </div>
        </div>
      )}
      {shouldShowAssignee && (
        <div className="upload-document-form__assignee-field">
          <p className="upload-document-form__assignee-field__title">
            Assignee
          </p>
          {employeesOptions ? (
            <StyledSelect
              id="document-assignee"
              name="document-assignee"
              value={
                uploadForm.assignee
                  ? {
                      label: uploadForm.assignee.name,
                      value: uploadForm.assignee.id,
                    }
                  : null
              }
              onChange={handleAssigneeChange}
              options={assigneeOptions(employeesOptions)}
              placeholder="Select assignee"
              disabled={!employeesOptions}
            />
          ) : (
            <Skeleton fullWidth />
          )}
        </div>
      )}
      <div className="upload-document-form__control-buttons">
        <Button text="Cancel" size="small" view="secondary" onClick={onClose} />
        <Button
          text="NEXT"
          onClick={onSubmit}
          size="big"
          className="upload-document-form__control-buttons__submit"
        />
      </div>
    </div>
  );
}

// todo add type
export function UploadDocumentsPopupGenerator(userId: string) {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <UploadDocumentsPopup onClose={onClose} userId={userId} />
      </HelpPopupTemplate>
    );
  };
}
