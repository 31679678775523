import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getMedicineInfoState } from "../../../../features/medicine";
import { getTreatmentPlansInfoState } from "../../../../features/treatmentPlans";
import { MedicineTable } from "../../../Components/Administrator/MedicineTable";
import { TreatmentPlansTable } from "../../../Components/Administrator/TreatmentPlansList";

const views: {
  [p: string]: {
    value: string;
    friendly: string;
  };
} = {
  medicine: {
    value: "medicine",
    friendly: "Medicine",
  },
  treatments: {
    value: "treatments",
    friendly: "Treatment plans",
  },
};

export function MedicationsDashboard() {
  const [view, setView] = useState(views.medicine.value);

  function getActualTable() {
    switch (view) {
      case views.medicine.value:
        return <MedicineTable />;
      case views.treatments.value:
        return <TreatmentPlansTable />;
      default:
        return null;
    }
  }

  return (
    <div className="greeting-container">
      <div className="tables-block">
        <ul className="tables-tabs">
          {Object.keys(views).map((key) => {
            const isActive = view === key;
            return (
              <li
                className={`tables-tabs__tab${
                  isActive ? " tables-tabs__tab--active" : ""
                }`}
                onClick={() => setView(key)}
              >
                {views[key].friendly}
              </li>
            );
          })}
        </ul>
        {getActualTable()}
      </div>
    </div>
  );
}
