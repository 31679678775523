import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import "./style.scss";
import { PopupLayerContext } from "../PopupLayer";
import { IncompleteFormPopupGenerator } from "../IncompleteFormPopup";
import { Button } from "../Button";
import { NotificationLayerContext } from "../NotificationLayer";
import { Patient } from "../../../../types/Entities/Patient";
import { Accutane } from "../../../../types/Entities/Accutane";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { useSignBloodSlipMutation } from "../../../../features/api/accutane";

interface Props {
  onClose: () => void;
}

interface SignBloodSlipData {
  documentGroupId: string | undefined;
  inviteId: string | undefined;
  role: string;
  patient: Patient;
  taskId: string;
  compositeKey: string;
  employeeId: string;
}

interface PopupProps extends Props {
  // eslint-disable-next-line react/no-unused-prop-types
  accutane?: Accutane;
  // eslint-disable-next-line react/no-unused-prop-types
  userId?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  provider?: string;
  employeeId: string;
  documentGroupId: string | undefined;
  disablePopup: (value: boolean) => void;
  inviteId: string | undefined;
  role: string;
  patient: Patient;
  taskId: string;
  compositeKey: string;
}

function SignBloodSlipPopup({
  onClose,
  role,
  documentGroupId,
  disablePopup,
  inviteId,
  patient,
  taskId,
  compositeKey,
  employeeId,
}: PopupProps) {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const { showPopup } = useContext(PopupLayerContext);
  const [signBloodSlip] = useSignBloodSlipMutation();
  const [link, setLink] = useState("");

  useEffect(() => {
    (async () => {
      if (documentGroupId && inviteId) {
        const response = await HoneydewAPI.accutane.bloodSlipEmbeddedLink(
          documentGroupId,
          inviteId,
          patient.patientId
        );

        if (response) {
          setLink(response);
        }
      }
    })();
  }, []);

  const onComplete = async () => {
    disablePopup(true);
    if (documentGroupId && inviteId) {
      const response = await HoneydewAPI.accutane.checkDocumentStatus(
        patient.patientId,
        role,
        documentGroupId,
        inviteId,
        true
      );

      if (response.fulfilled) {
        const result: any = await signBloodSlip({
          taskId,
          compositeKey,
          documentGroupId,
          inviteId,
          patientId: patient.patientId,
        });

        if (result.error) {
          showError({
            title: "Something went wrong...",
            description: "Unable to sign blood slip",
          });
          return;
        }

        showSuccess({
          title: "Success",
          description: "Successfully signed",
        });
        onClose();
        disablePopup(false);
      } else {
        disablePopup(false);
        showPopup(IncompleteFormPopupGenerator());
      }
    }
  };

  return (
    <div className="sign-ipledge-consent-popup">
      {/* <p className="sign-ipledge-consent-popup__current-page">
        Sign blood slip
      </p> */}
      <div className="sign-ipledge-consent-popup__document">
        {link && <iframe src={link} scrolling="no" />}
      </div>
      <Button
        text="Complete"
        size="small"
        className="sign-ipledge-consent-popup__extra-button"
        onClick={onComplete}
      />
    </div>
  );
}

export function SignBloodSlipPopupGenerator(
  signBloodSleepData: SignBloodSlipData
) {
  return function render({ onClose }: Props) {
    const [closeDisabled, setDisabled] = useState(false);

    return (
      <ActionPopupTemplate
        title="Sign blood slip forms"
        onClose={onClose}
        closeDisabled={closeDisabled}
      >
        <SignBloodSlipPopup
          documentGroupId={signBloodSleepData?.documentGroupId}
          inviteId={signBloodSleepData.inviteId}
          onClose={onClose}
          role={signBloodSleepData.role}
          patient={signBloodSleepData.patient}
          taskId={signBloodSleepData.taskId}
          compositeKey={signBloodSleepData.compositeKey}
          employeeId={signBloodSleepData.employeeId}
          disablePopup={setDisabled}
        />
      </ActionPopupTemplate>
    );
  };
}

export default SignBloodSlipPopupGenerator;
