import React, { useEffect, useState } from "react";
import {
  SurveyPage,
  SurveyData,
  SkinSurvey,
} from "../../../../types/CustomSurvey";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import "./style.scss";

interface Props {
  questions: SurveyPage[];
  onComplete: (data: SurveyData) => Promise<boolean> | boolean;
  data: SurveyData;
  offsetStart?: number;
  offsetEnd?: number;
  localStorage?: string;
  className?: string;
  setState?: SurveyData;
  isFromAccutaneSource?: boolean;
  isMedicalBackground?: boolean;
  miminumPage?: number;
}

export function CustomSurvey({
  questions,
  onComplete,
  data = {},
  offsetStart = 0,
  offsetEnd = 0,
  localStorage,
  className,
  setState,
  isFromAccutaneSource,
  isMedicalBackground,
  miminumPage = 1,
}: Props) {
  const savedData = localStorage
    ? window.localStorage.getItem(localStorage)
    : null;
  const [currentData, setData] = useState(
    savedData ? JSON.parse(savedData) : data
  );
  const filteredQuestions = questions?.filter(
    ({ when }) => !when || !!when(currentData)
  );
  const [currentPage, setPage] = useState(
    filteredQuestions?.findIndex(({ isFilled }) => !isFilled(currentData))
  );

  useEffect(() => {
    if (localStorage) {
      window.localStorage.setItem(localStorage, JSON.stringify(currentData));
    }
  }, [currentData]);

  async function submit(_data: SkinSurvey) {
    const isDone = await onComplete(_data);
    if (isDone && localStorage) window.localStorage.removeItem(localStorage);
  }

  const currentQuestion = filteredQuestions[currentPage];
  if (!currentQuestion) return null;
  return (
    <div className={`custom-survey ${className || ""}`}>
      <div className="custom-survey__heading">
        <p className="custom-survey__counter">
          {currentPage + 1 + offsetStart} of{" "}
          {filteredQuestions.length + offsetStart + offsetEnd}
        </p>
        {(!currentPage > 0 && isFromAccutaneSource) ||
        (!isFromAccutaneSource &&
          currentPage >= miminumPage &&
          !isMedicalBackground) ? (
          <p
            className="custom-survey__back-link"
            onClick={() =>
              currentPage > 0 ? setPage(currentPage - 1) : setState(1)
            }
          >
            <ChevronIcon /> Back
          </p>
        ) : null}
      </div>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <currentQuestion.Component
        onDone={async (_data) => {
          if (currentPage === filteredQuestions.length - 1) {
            await submit({
              ...currentData,
              ..._data,
            });
            window.scroll(0, 0);
            return;
          }

          const updatedData = {
            ...currentData,
            ..._data,
          };
          if (currentQuestion.onComplete) {
            try {
              await currentQuestion.onComplete(updatedData);
            } catch (e) {
              return;
            }
          }

          setData(updatedData);
          setPage(currentPage + 1);
          window.scroll(0, 0);
        }}
        data={currentData}
        stepper={{
          current: currentPage + offsetStart,
          max: filteredQuestions.length + offsetStart + offsetEnd - 1,
        }}
        question={currentQuestion?.question}
        isChildAccount={currentQuestion?.isChildAccount}
        previousAnswer={currentQuestion.previousAnswer}
      />
    </div>
  );
}
