import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientFollowUpVisit } from "../../Pages/Provider/PatientFollowUpVisit";
import { Footer } from "../../NewComponents/Common/Footer";
import { useGetAssignedPatientsQuery } from "../../../features/api/patients";
import { getSessionState } from "../../../features/session";
import { PatientDashboardPageForProvider } from "../../NewPages/Provider/PatientDashboard";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";

interface Props {
  isAuthorised: boolean;
  setParamsId: (id: any) => void;
}

function PatientDashboardForProviderComponent({
  isAuthorised,
  setParamsId,
}: Props) {
  const params = useParams();
  const paramsId = params.userId;

  useEffect(() => {
    setParamsId(paramsId);
  }, [paramsId]);

  return isAuthorised ? (
    <>
      <PatientDashboardPageForProvider />
      <ChatWidget />
      <Footer />
    </>
  ) : (
    <Redirect to="/" />
  );
}

export default PatientDashboardForProviderComponent;
