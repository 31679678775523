export default function getAmericanTypeDate(
  _date: Date | string | number,
  addTime = false
) {
  const date = new Date(_date);
  let formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;

  if (addTime) {
    const partOfTheDay = date.getHours() >= 12 ? "pm" : "am";
    const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    formattedDate = `${formattedDate} ${hours}:${minutes}${partOfTheDay}`;
  }

  return formattedDate;
}
