import stringToBool from "../../../../utils/string-to-bool";
import boolToString from "../../../../utils/bool-to-string";
import {
  MedicalBackgroundItem,
  Patient,
} from "../../../../types/Entities/Patient";
import { isEmpty } from "../../../../utils/is-empty";

function getMedicalBackgroundSurveyAnswer(key: string, patient: Patient) {
  const question = patient.medicalBackground?.skinSurvey?.find(
    (it) => it.id === key
  );
  const value =
    question?.type === "bool" ? boolToString(question?.value) : question?.value;

  // Attempt to parse the value as JSON
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error(
      "Error parsing JSON in getMedicalBackgroundSurveyAnswer:",
      error
    );
    return value; // Return the original value if parsing fails
  }
}

export const skinDataAdapter = {
  dataToSurvey: (patient: Patient) => ({
    acnePeriod: getMedicalBackgroundSurveyAnswer("acnePeriod", patient),
    skinDryness: getMedicalBackgroundSurveyAnswer("skinDryness", patient),
    skin: getMedicalBackgroundSurveyAnswer("skinSensitivity", patient),
  }),
  surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] => [
    {
      id: "acnePeriod",
      type: "text",
      value: survey.acnePeriod,
      questionType: "acne",
    },
    {
      id: "skinDryness",
      type: "text",
      value: survey.skinDryness,
      questionType: "acne",
    },
    {
      id: "skinSensitivity",
      type: "text",
      value: survey.skin,
      questionType: "acne",
    },
  ],
};

export const otherConditionsDataAdapter = {
  dataToSurvey: (patient: Patient) => ({
    isAnyPrescriptionAllergies: getMedicalBackgroundSurveyAnswer(
      "isAnyPrescriptionAllergies",
      patient
    ),
    isAnyMedicalConditionPillsInUse: getMedicalBackgroundSurveyAnswer(
      "isAnyMedicalConditionPillsInUse",
      patient
    ),
    medications: getMedicalBackgroundSurveyAnswer("medications", patient),
    prescriptionAllergies: getMedicalBackgroundSurveyAnswer(
      "prescriptionAllergies",
      patient
    ),
    otherMedications: getMedicalBackgroundSurveyAnswer(
      "otherMedications",
      patient
    ),
    allergiesToPrescriptionMedications: getMedicalBackgroundSurveyAnswer(
      "allergiesToPrescriptionMedications",
      patient
    ),
    anythingElse: getMedicalBackgroundSurveyAnswer("anythingElse", patient),
    obesityOrHeartDisease: getMedicalBackgroundSurveyAnswer(
      "obesityOrHeartDisease",
      patient
    ),
    arthritisOrJointPain: getMedicalBackgroundSurveyAnswer(
      "arthritisOrJointPain",
      patient
    ),
    strepThroatInfections: getMedicalBackgroundSurveyAnswer(
      "strepThroatInfections",
      patient
    ),
    diabetesHistory: getMedicalBackgroundSurveyAnswer(
      "diabetesHistory",
      patient
    ),
    otherInformation: getMedicalBackgroundSurveyAnswer(
      "otherInformation",
      patient
    ),
  }),
  surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] =>
    [
      {
        id: "isAnyPrescriptionAllergies",
        type: "bool",
        value: stringToBool(survey.isAnyPrescriptionAllergies) as string,
        questionType: "otherMedical",
      },
      {
        id: "isAnyMedicalConditionPillsInUse",
        type: "bool",
        value: stringToBool(survey.skin) as string,
        questionType: "otherMedical",
      },
      {
        id: "medications",
        type: "text",
        value: survey.medications,
        questionType: "otherMedical",
      },
      {
        id: "prescriptionAllergies",
        type: "text",
        value: survey.prescriptionAllergies,
        questionType: "otherMedical",
      },
      {
        id: "otherMedications",
        type: "text",
        value: survey.otherMedications,
        questionType: "otherMedical",
      },
      {
        id: "allergiesToPrescriptionMedications",
        type: "text",
        value: survey.allergiesToPrescriptionMedications,
        questionType: "otherMedical",
      },
      {
        id: "anythingElse",
        type: "text",
        value: survey.anythingElse,
        questionType: "otherMedical",
      },
      {
        id: "obesityOrHeartDisease",
        type: "text",
        value: survey.obesityOrHeartDisease,
        questionType: "otherMedical",
      },
      {
        id: "arthritisOrJointPain",
        type: "text",
        value: survey.arthritisOrJointPain,
        questionType: "otherMedical",
      },
      {
        id: "strepThroatInfections",
        type: "text",
        value: survey.strepThroatInfections,
        questionType: "otherMedical",
      },
      {
        id: "diabetesHistory",
        type: "text",
        value: survey.diabetesHistory,
        questionType: "otherMedical",
      },
      {
        id: "otherInformation",
        type: "text",
        value: survey.otherInformation,
        questionType: "otherMedical",
      },
    ].filter(({ value }) => !isEmpty(value)),
};

// export const otherMedicalDataAdapter = {
//   dataToSurvey: (patient: Patient) => ({
//     otherMedications: getMedicalBackgroundSurveyAnswer(
//       "otherMedications",
//       patient
//     ),
//     allergiesToPrescriptionMedications: getMedicalBackgroundSurveyAnswer(
//       "allergiesToPrescriptionMedications",
//       patient
//     ),
//   }),
//   surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] =>
//     [
//       {
//         id: "otherMedications",
//         type: "text",
//         value: survey.otherMedications,
//         questionType: "otherMedical",
//       },
//       {
//         id: "allergiesToPrescriptionMedications",
//         type: "text",
//         value: survey.allergiesToPrescriptionMedications,
//         questionType: "otherMedical",
//       },
//     ].filter(({ value }) => !isEmpty(value)),
// };

export const acneDataAdapter = {
  dataToSurvey: (patient: Patient) => ({
    acnePeriod: getMedicalBackgroundSurveyAnswer("acnePeriod", patient),
    skinDryness: getMedicalBackgroundSurveyAnswer("skinDryness", patient),
    skinSensitivity: getMedicalBackgroundSurveyAnswer(
      "skinSensitivity",
      patient
    ),
    previousAcneProductsTaken: getMedicalBackgroundSurveyAnswer(
      "previousAcneProductsTaken",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "previousAcneProductsTaken",
          patient
        ) as string)
      : undefined,
    specificMedicationsInterest: getMedicalBackgroundSurveyAnswer(
      "specificMedicationsInterest",
      patient
    ),
    prescriptionTopicalsUsed: getMedicalBackgroundSurveyAnswer(
      "prescriptionTopicalsUsed",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "prescriptionTopicalsUsed",
          patient
        ) as string)
      : undefined,
    acnePillsTaken: getMedicalBackgroundSurveyAnswer("acnePillsTaken", patient)
      ? (getMedicalBackgroundSurveyAnswer("acnePillsTaken", patient) as string)
      : undefined,
    prescriptionPills: getMedicalBackgroundSurveyAnswer(
      "prescriptionPills",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "prescriptionPills",
          patient
        ) as string)
      : undefined,
    prescriptionCreams: getMedicalBackgroundSurveyAnswer(
      "prescriptionCreams",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "prescriptionCreams",
          patient
        ) as string)
      : undefined,
    isAnyPrescriptionsInUse: getMedicalBackgroundSurveyAnswer(
      "isAnyPrescriptionsInUse",
      patient
    ),
    currentNonPrescriptions: getMedicalBackgroundSurveyAnswer(
      "currentNonPrescriptions",
      patient
    ),
    currentPrescriptions: getMedicalBackgroundSurveyAnswer(
      "currentPrescriptions",
      patient
    ),
    interestingMedications: getMedicalBackgroundSurveyAnswer(
      "interestingMedications",
      patient
    ),
    periodEczma: getMedicalBackgroundSurveyAnswer("periodEczma", patient),
    familyHistory: getMedicalBackgroundSurveyAnswer("familyHistory", patient),
    haveAsthma: getMedicalBackgroundSurveyAnswer("haveAsthma", patient),
    seasonalAllergies: getMedicalBackgroundSurveyAnswer(
      "seasonalAllergies",
      patient
    ),
    nonEczemaPrescriptionProducts: getMedicalBackgroundSurveyAnswer(
      "nonEczemaPrescriptionProducts",
      patient
    ),
    locationofEczma: getMedicalBackgroundSurveyAnswer(
      "locationofEczma",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer("locationofEczma", patient) as string)
      : undefined,
    eczemaLocatedText: getMedicalBackgroundSurveyAnswer(
      "eczemaLocatedText",
      patient
    ),
    crackorBleed: getMedicalBackgroundSurveyAnswer("crackorBleed", patient),
    itchyChildSkin: getMedicalBackgroundSurveyAnswer("itchyChildSkin", patient),
    worseduringSeason: getMedicalBackgroundSurveyAnswer(
      "worseduringSeason",
      patient
    ),
    otcCreams: getMedicalBackgroundSurveyAnswer("otcCreams", patient)
      ? (getMedicalBackgroundSurveyAnswer("otcCreams", patient) as string)
      : undefined,
    locationofMoisturizers: getMedicalBackgroundSurveyAnswer(
      "locationofMoisturizers",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "locationofMoisturizers",
          patient
        ) as string)
      : undefined,
    moisturizersLocationText: getMedicalBackgroundSurveyAnswer(
      "moisturizersLocationText",
      patient
    ),
    locationofCleansers: getMedicalBackgroundSurveyAnswer(
      "locationofCleansers",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "locationofMoisturizers",
          patient
        ) as string)
      : undefined,
    cleansersLocationText: getMedicalBackgroundSurveyAnswer(
      "cleansersLocationText",
      patient
    ),
    octPills: getMedicalBackgroundSurveyAnswer("octPills", patient)
      ? (getMedicalBackgroundSurveyAnswer("octPills", patient) as string)
      : undefined,
    antihistaminesPills: getMedicalBackgroundSurveyAnswer(
      "antihistaminesPills",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "antihistaminesPills",
          patient
        ) as string)
      : undefined,
    // antihistaminesPills: getMedicalBackgroundSurveyAnswer(
    //   "antihistaminesPills",
    //   patient
    // )
    //   ? JSON.parse(
    //       getMedicalBackgroundSurveyAnswer(
    //         "antihistaminesPills",
    //         patient
    //       ) as string
    //     )
    //   : undefined,
    // cleansersLocationText: getMedicalBackgroundSurveyAnswer(
    //   "cleansersLocationText",
    //   patient
    // ),
    creamPrecriptions: getMedicalBackgroundSurveyAnswer(
      "creamPrecriptions",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "creamPrecriptions",
          patient
        ) as string)
      : undefined,
    // prescriptionPills: getMedicalBackgroundSurveyAnswer(
    //   "prescriptionPills",
    //   patient
    // )
    //   ? JSON.parse(
    //       getMedicalBackgroundSurveyAnswer(
    //         "prescriptionPills",
    //         patient
    //       ) as string
    //     )
    //   : undefined,
    injectables: getMedicalBackgroundSurveyAnswer("injectables", patient)
      ? (getMedicalBackgroundSurveyAnswer("injectables", patient) as string)
      : undefined,
    currentMedication: getMedicalBackgroundSurveyAnswer(
      "currentMedication",
      patient
    ),
    specificInterestedMedics: getMedicalBackgroundSurveyAnswer(
      "specificInterestedMedics",
      patient
    ),
    hairLossNoticeTimeframe: getMedicalBackgroundSurveyAnswer(
      "hairLossNoticeTimeframe",
      patient
    ),
    firsthairLoss: getMedicalBackgroundSurveyAnswer("firsthairLoss", patient),
    hairLossPattern: getMedicalBackgroundSurveyAnswer(
      "hairLossPattern",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer("hairLossPattern", patient) as string)
      : undefined,
    flakiness: getMedicalBackgroundSurveyAnswer("flakiness", patient),
    hairLossTry: getMedicalBackgroundSurveyAnswer("hairLossTry", patient)
      ? (getMedicalBackgroundSurveyAnswer("hairLossTry", patient) as string)
      : undefined,
    hairLossSpecificMedicationsInterest: getMedicalBackgroundSurveyAnswer(
      "hairLossSpecificMedicationsInterest",
      patient
    ),
    overTheCounterTreatments: getMedicalBackgroundSurveyAnswer(
      "overTheCounterTreatments",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "overTheCounterTreatments",
          patient
        ) as string)
      : undefined,
    overTheCounterTreatmentsfollow: getMedicalBackgroundSurveyAnswer(
      "overTheCounterTreatmentsfollow",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "overTheCounterTreatmentsfollow",
          patient
        ) as string)
      : undefined,
    overTheCounterTreatmentsfollowup: getMedicalBackgroundSurveyAnswer(
      "overTheCounterTreatmentsfollowup",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "overTheCounterTreatmentsfollowup",
          patient
        ) as string)
      : undefined,
    minoxidilPercentage: getMedicalBackgroundSurveyAnswer(
      "minoxidilPercentage",
      patient
    ),
    otherTopicals: getMedicalBackgroundSurveyAnswer("otherTopicals", patient),
    prescriptionMedications: getMedicalBackgroundSurveyAnswer(
      "prescriptionMedications",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "prescriptionMedications",
          patient
        ) as string)
      : undefined,
    prescriptionMedicationsfollow: getMedicalBackgroundSurveyAnswer(
      "prescriptionMedicationsfollow",
      patient
    ),
    officeProcedures: getMedicalBackgroundSurveyAnswer(
      "officeProcedures",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "officeProcedures",
          patient
        ) as string)
      : undefined,
    hsDiagnosed: getMedicalBackgroundSurveyAnswer("hsDiagnosed", patient),
    hsSymptomsDuration: getMedicalBackgroundSurveyAnswer(
      "hsSymptomsDuration",
      patient
    ),
    hsTreatments: getMedicalBackgroundSurveyAnswer("hsTreatments", patient)
      ? (getMedicalBackgroundSurveyAnswer("hsTreatments", patient) as string)
      : undefined,
    hsOtcMedications: getMedicalBackgroundSurveyAnswer(
      "hsOtcMedications",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "hsOtcMedications",
          patient
        ) as string)
      : undefined,
    hsPrescriptionMedications: getMedicalBackgroundSurveyAnswer(
      "hsPrescriptionMedications",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "hsPrescriptionMedications",
          patient
        ) as string)
      : undefined,
    hsSurgicalInterventions: getMedicalBackgroundSurveyAnswer(
      "hsSurgicalInterventions",
      patient
    ),
    currentMedication: getMedicalBackgroundSurveyAnswer(
      "currentMedication",
      patient
    ),
    specificInterestedMedics: getMedicalBackgroundSurveyAnswer(
      "specificInterestedMedics",
      patient
    ),
    symptomsSeverity: getMedicalBackgroundSurveyAnswer(
      "symptomsSeverity",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "symptomsSeverity",
          patient
        ) as string)
      : undefined,
    hsTriggers: getMedicalBackgroundSurveyAnswer("hsTriggers", patient),
    hsImpactOnLifestyle: getMedicalBackgroundSurveyAnswer(
      "hsImpactOnLifestyle",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "hsImpactOnLifestyle",
          patient
        ) as string)
      : undefined,
    hsFamilyHistory: getMedicalBackgroundSurveyAnswer(
      "hsFamilyHistory",
      patient
    ),
    affectedNails: getMedicalBackgroundSurveyAnswer("affectedNails", patient),
    knownHistory: getMedicalBackgroundSurveyAnswer("knownHistory", patient)
      ? (getMedicalBackgroundSurveyAnswer("knownHistory", patient) as string)
      : undefined,
    frequency: getMedicalBackgroundSurveyAnswer("frequency", patient),
    previousProductsforNailsTaken: getMedicalBackgroundSurveyAnswer(
      "previousProductsforNailsTaken",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "previousProductsforNailsTaken",
          patient
        ) as string)
      : undefined,
    nailsSpecificMedicationsInterest: getMedicalBackgroundSurveyAnswer(
      "nailsSpecificMedicationsInterest",
      patient
    ),
    overTheCounterMeds: getMedicalBackgroundSurveyAnswer(
      "overTheCounterMeds",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "overTheCounterMeds",
          patient
        ) as string)
      : undefined,
    prescriptionMeds: getMedicalBackgroundSurveyAnswer(
      "prescriptionMeds",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "prescriptionMeds",
          patient
        ) as string)
      : undefined,
    officeTreatments: getMedicalBackgroundSurveyAnswer(
      "officeTreatments",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "officeTreatments",
          patient
        ) as string)
      : undefined,
    currentMedication: getMedicalBackgroundSurveyAnswer(
      "currentMedication",
      patient
    ),
    nailsSpecificMedicationsInterest: getMedicalBackgroundSurveyAnswer(
      "nailsSpecificMedicationsInterest",
      patient
    ),
    psoriasisPeriod: getMedicalBackgroundSurveyAnswer(
      "psoriasisPeriod",
      patient
    ),
    skinBiopsy: getMedicalBackgroundSurveyAnswer("skinBiopsy", patient),
    psoriasisAreas: getMedicalBackgroundSurveyAnswer("psoriasisAreas", patient)
      ? (getMedicalBackgroundSurveyAnswer("psoriasisAreas", patient) as string)
      : undefined,
    psoriasisHands: getMedicalBackgroundSurveyAnswer("psoriasisHands", patient),
    psoriasisFeet: getMedicalBackgroundSurveyAnswer("psoriasisFeet", patient),
    itching: getMedicalBackgroundSurveyAnswer("itching", patient),
    psoriasisFamilyHistory: getMedicalBackgroundSurveyAnswer(
      "psoriasisFamilyHistory",
      patient
    ),
    previousPsoriasisProductsTaken: getMedicalBackgroundSurveyAnswer(
      "previousPsoriasisProductsTaken",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "previousPsoriasisProductsTaken",
          patient
        ) as string)
      : undefined,
    psoriasisCurrentlyUsingPrescriptionProducts:
      getMedicalBackgroundSurveyAnswer(
        "psoriasisCurrentlyUsingPrescriptionProducts",
        patient
      ),
    psoriasisSpecificMedicationsInterest: getMedicalBackgroundSurveyAnswer(
      "psoriasisSpecificMedicationsInterest",
      patient
    ),
    otcTopicalMedications: getMedicalBackgroundSurveyAnswer(
      "otcTopicalMedications",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "otcTopicalMedications",
          patient
        ) as string)
      : undefined,
    psoriasisPrescriptionCreams: getMedicalBackgroundSurveyAnswer(
      "psoriasisPrescriptionCreams",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "psoriasisPrescriptionCreams",
          patient
        ) as string)
      : undefined,
    psoriasisPrescriptionOralMedications: getMedicalBackgroundSurveyAnswer(
      "psoriasisPrescriptionOralMedications",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "psoriasisPrescriptionOralMedications",
          patient
        ) as string)
      : undefined,
    psoriasisPrescriptionInjectables: getMedicalBackgroundSurveyAnswer(
      "psoriasisPrescriptionInjectables",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "psoriasisPrescriptionInjectables",
          patient
        ) as string)
      : undefined,
    rosaceaPeriod: getMedicalBackgroundSurveyAnswer("rosaceaPeriod", patient),
    familyHistoryRosacea: getMedicalBackgroundSurveyAnswer(
      "familyHistoryRosacea",
      patient
    ),
    rosaceaSymptoms: getMedicalBackgroundSurveyAnswer(
      "rosaceaSymptoms",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer("rosaceaSymptoms", patient) as string)
      : undefined,
    rosaceaTriggers: getMedicalBackgroundSurveyAnswer(
      "rosaceaTriggers",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer("rosaceaTriggers", patient) as string)
      : undefined,
    previousRosaceaProductsTaken: getMedicalBackgroundSurveyAnswer(
      "previousRosaceaProductsTaken",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "previousRosaceaProductsTaken",
          patient
        ) as string)
      : undefined,
    rosaceaNonPrescriptionProducts: getMedicalBackgroundSurveyAnswer(
      "rosaceaNonPrescriptionProducts",
      patient
    ),
    rosaceaPrescriptionCreams: getMedicalBackgroundSurveyAnswer(
      "rosaceaPrescriptionCreams",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "rosaceaPrescriptionCreams",
          patient
        ) as string)
      : undefined,
    rosaceaPrescriptionPills: getMedicalBackgroundSurveyAnswer(
      "rosaceaPrescriptionPills",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "rosaceaPrescriptionPills",
          patient
        ) as string)
      : undefined,
    rosaceaCurrentlyUsingPrescriptionProducts: getMedicalBackgroundSurveyAnswer(
      "rosaceaCurrentlyUsingPrescriptionProducts",
      patient
    ),
    rosaceaSpecificMedicationsInterest: getMedicalBackgroundSurveyAnswer(
      "rosaceaSpecificMedicationsInterest",
      patient
    ),
    tineaVersicolorPeriod: getMedicalBackgroundSurveyAnswer(
      "tineaVersicolorPeriod",
      patient
    ),
    tineaVersicolorAreas: getMedicalBackgroundSurveyAnswer(
      "tineaVersicolorAreas",
      patient
    ),
    tineaVersicolorSeasonal: getMedicalBackgroundSurveyAnswer(
      "tineaVersicolorSeasonal",
      patient
    ),
    previousTineaVersicolorProductsTaken: getMedicalBackgroundSurveyAnswer(
      "previousTineaVersicolorProductsTaken",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "previousTineaVersicolorProductsTaken",
          patient
        ) as string)
      : undefined,
    tineaVersicolorOtcCreams: getMedicalBackgroundSurveyAnswer(
      "tineaVersicolorOtcCreams",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "tineaVersicolorOtcCreams",
          patient
        ) as string)
      : undefined,
    tineaVersicolorPrescriptionCreams: getMedicalBackgroundSurveyAnswer(
      "tineaVersicolorPrescriptionCreams",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "tineaVersicolorPrescriptionCreams",
          patient
        ) as string)
      : undefined,
    tineaVersicolorPrescriptionOrals: getMedicalBackgroundSurveyAnswer(
      "tineaVersicolorPrescriptionOrals",
      patient
    )
      ? (getMedicalBackgroundSurveyAnswer(
          "tineaVersicolorPrescriptionOrals",
          patient
        ) as string)
      : undefined,
    tineaVersicolorCurrentlyUsingPrescriptionProducts:
      getMedicalBackgroundSurveyAnswer(
        "tineaVersicolorCurrentlyUsingPrescriptionProducts",
        patient
      ),
    tineaVersicolorSpecificMedicationsInterest:
      getMedicalBackgroundSurveyAnswer(
        "tineaVersicolorSpecificMedicationsInterest",
        patient
      ),
  }),
  surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] =>
    [
      {
        id: "acnePeriod",
        type: "text",
        value: survey.acnePeriod,
        questionType: "acne",
      },
      {
        id: "skinDryness",
        type: "text",
        value: survey.skinDryness,
        questionType: "acne",
      },
      {
        id: "skinSensitivity",
        type: "text",
        value: survey.skin,
        questionType: "acne",
      },
      {
        id: "previousAcneProductsTaken",
        type: "list",
        value: JSON.stringify(survey.previousAcneProductsTaken),
        questionType: "acne",
      },
      {
        id: "prescriptionTopicalsUsed",
        type: "list",
        value: JSON.stringify(survey.prescriptionTopicalsUsed),
        questionType: "acne",
      },
      {
        id: "acnePillsTaken",
        type: "list",
        value: JSON.stringify(survey.acnePillsTaken),
        questionType: "acne",
      },
      {
        id: "specificMedicationsInterest",
        type: "text",
        value: survey.specificMedicationsInterest,
        questionType: "acne",
      },
      {
        id: "prescriptionPills",
        type: "list",
        value: JSON.stringify(survey.prescriptionPills),
        questionType: "acne",
      },
      {
        id: "prescriptionCreams",
        type: "list",
        value: JSON.stringify(survey.prescriptionCreams),
        questionType: "acne",
      },
      {
        id: "isAnyPrescriptionsInUse",
        type: "bool",
        value: stringToBool(survey.isAnyPrescriptionsInUse) as string,
        questionType: "acne",
      },
      {
        id: "currentNonPrescriptions",
        type: "text",
        value: survey.currentNonPrescriptions,
        questionType: "acne",
      },
      {
        id: "currentPrescriptions",
        type: "text",
        value: survey.currentPrescriptions,
        questionType: "acne",
      },
      {
        id: "interestingMedications",
        type: "text",
        value: survey.interestingMedications,
        questionType: "acne",
      },
      {
        id: "periodEczma",
        type: "text",
        value: survey.periodEczma,
        questionType: "eczema",
      },
      {
        id: "familyHistory",
        type: "text",
        value: survey.familyHistory,
        questionType: "eczema",
      },
      {
        id: "haveAsthma",
        type: "text",
        value: survey.haveAsthma,
        questionType: "eczema",
      },
      {
        id: "seasonalAllergies",
        type: "text",
        value: survey.seasonalAllergies,
        questionType: "eczema",
      },
      {
        id: "nonEczemaPrescriptionProducts",
        type: "text",
        value: survey.nonEczemaPrescriptionProducts,
        questionType: "eczema",
      },
      {
        id: "locationofEczma",
        type: "text",
        value: survey.locationofEczma,
        questionType: "eczema",
      },
      {
        id: "eczemaLocatedText",
        type: "text",
        value: survey.eczemaLocatedText,
        questionType: "eczema",
      },
      {
        id: "crackorBleed",
        type: "text",
        value: survey.crackorBleed,
        questionType: "eczema",
      },
      {
        id: "itchyChildSkin",
        type: "text",
        value: survey.itchyChildSkin,
        questionType: "eczema",
      },
      {
        id: "worseduringSeason",
        type: "text",
        value: survey.worseduringSeason,
        questionType: "eczema",
      },
      {
        id: "otcCreams",
        type: "text",
        value: survey.otcCreams,
        questionType: "eczema",
      },
      {
        id: "locationofMoisturizers",
        type: "text",
        value: survey.locationofMoisturizers,
        questionType: "eczema",
      },
      {
        id: "moisturizersLocationText",
        type: "text",
        value: survey.moisturizersLocationText,
        questionType: "eczema",
      },
      {
        id: "locationofCleansers",
        type: "text",
        value: survey.locationofCleansers,
        questionType: "eczema",
      },
      {
        id: "cleansersLocationText",
        type: "text",
        value: survey.cleansersLocationText,
        questionType: "eczema",
      },
      {
        id: "octPills",
        type: "text",
        value: survey.octPills,
        questionType: "eczema",
      },
      {
        id: "antihistaminesPills",
        type: "text",
        value: survey.antihistaminesPills,
        questionType: "eczema",
      },
      {
        id: "cleansersLocationText",
        type: "text",
        value: survey.cleansersLocationText,
        questionType: "eczema",
      },
      {
        id: "creamPrecriptions",
        type: "text",
        value: survey.creamPrecriptions,
        questionType: "eczema",
      },
      {
        id: "prescriptionPills",
        type: "text",
        value: survey.prescriptionPills,
        questionType: "eczema",
      },
      {
        id: "injectables",
        type: "text",
        value: survey.injectables,
        questionType: "eczema",
      },
      {
        id: "currentMedication",
        type: "text",
        value: survey.currentMedication,
        questionType: "eczema",
      },
      {
        id: "specificInterestedMedics",
        type: "text",
        value: survey.specificInterestedMedics,
        questionType: "eczema",
      },
      {
        id: "hairLossNoticeTimeframe",
        type: "text",
        value: survey.hairLossNoticeTimeframe,
        questionType: "hairLoss",
      },
      {
        id: "firsthairLoss",
        type: "text",
        value: survey.firsthairLoss,
        questionType: "hairLoss",
      },
      {
        id: "firsthairLoss",
        type: "text",
        value: survey.firsthairLoss,
        questionType: "hairLoss",
      },
      {
        id: "hairLossPattern",
        type: "text",
        value: survey.hairLossPattern,
        questionType: "hairLoss",
      },
      {
        id: "flakiness",
        type: "text",
        value: survey.flakiness,
        questionType: "hairLoss",
      },
      {
        id: "hairLossTry",
        type: "text",
        value: survey.hairLossTry,
        questionType: "hairLoss",
      },
      {
        id: "overTheCounterTreatments",
        type: "text",
        value: survey.overTheCounterTreatments,
        questionType: "hairLoss",
      },
      {
        id: "hairLossSpecificMedicationsInterest",
        type: "text",
        value: survey.hairLossSpecificMedicationsInterest,
        questionType: "hairLoss",
      },
      {
        id: "overTheCounterTreatmentsfollow",
        type: "text",
        value: survey.overTheCounterTreatmentsfollow,
        questionType: "hairLoss",
      },
      {
        id: "overTheCounterTreatmentsfollowup",
        type: "text",
        value: survey.overTheCounterTreatmentsfollowup,
        questionType: "hairLoss",
      },

      {
        id: "minoxidilPercentage",
        type: "text",
        value: survey.minoxidilPercentage,
        questionType: "hairLoss",
      },
      {
        id: "otherTopicals",
        type: "text",
        value: survey.otherTopicals,
        questionType: "hairLoss",
      },
      {
        id: "prescriptionMedications",
        type: "text",
        value: survey.prescriptionMedications,
        questionType: "hairLoss",
      },
      {
        id: "prescriptionMedicationsfollow",
        type: "text",
        value: survey.prescriptionMedicationsfollow,
        questionType: "hairLoss",
      },
      {
        id: "officeProcedures",
        type: "text",
        value: survey.officeProcedures,
        questionType: "hairLoss",
      },
      {
        id: "hsDiagnosed",
        type: "text",
        value: survey.hsDiagnosed,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsSymptomsDuration",
        type: "text",
        value: survey.hsSymptomsDuration,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsTreatments",
        type: "text",
        value: survey.hsTreatments,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsOtcMedications",
        type: "text",
        value: survey.hsOtcMedications,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsPrescriptionMedications",
        type: "text",
        value: survey.hsPrescriptionMedications,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsSurgicalInterventions",
        type: "text",
        value: survey.hsSurgicalInterventions,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "currentMedication",
        type: "text",
        value: survey.currentMedication,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "specificInterestedMedics",
        type: "text",
        value: survey.specificInterestedMedics,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "symptomsSeverity",
        type: "text",
        value: survey.symptomsSeverity,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsTriggers",
        type: "text",
        value: survey.hsTriggers,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsImpactOnLifestyle",
        type: "text",
        value: survey.hsImpactOnLifestyle,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "hsFamilyHistory",
        type: "text",
        value: survey.hsFamilyHistory,
        questionType: "hidradenitisSuppurativa",
      },
      {
        id: "affectedNails",
        type: "text",
        value: survey.affectedNails,
        questionType: "nailFungus",
      },
      {
        id: "knownHistory",
        type: "text",
        value: survey.knownHistory,
        questionType: "nailFungus",
      },
      {
        id: "frequency",
        type: "text",
        value: survey.frequency,
        questionType: "nailFungus",
      },
      {
        id: "previousProductsforNailsTaken",
        type: "text",
        value: survey.previousProductsforNailsTaken,
        questionType: "nailFungus",
      },
      {
        id: "overTheCounterMeds",
        type: "text",
        value: survey.overTheCounterMeds,
        questionType: "nailFungus",
      },
      {
        id: "prescriptionMeds",
        type: "text",
        value: survey.prescriptionMeds,
        questionType: "nailFungus",
      },
      {
        id: "officeTreatments",
        type: "text",
        value: survey.officeTreatments,
        questionType: "nailFungus",
      },
      {
        id: "currentMedication",
        type: "text",
        value: survey.currentMedication,
        questionType: "nailFungus",
      },
      {
        id: "nailsSpecificMedicationsInterest",
        type: "text",
        value: survey.nailsSpecificMedicationsInterest,
        questionType: "nailFungus",
      },
      {
        id: "nailsSpecificMedicationsInterest",
        type: "text",
        value: survey.nailsSpecificMedicationsInterest,
        questionType: "nailFungus",
      },
      {
        id: "psoriasisPeriod",
        type: "text",
        value: survey.psoriasisPeriod,
        questionType: "psoriasis",
      },
      {
        id: "skinBiopsy",
        type: "text",
        value: survey.skinBiopsy,
        questionType: "psoriasis",
      },
      {
        id: "psoriasisAreas",
        type: "text",
        value: survey.psoriasisAreas,
        questionType: "psoriasis",
      },
      {
        id: "psoriasisHands",
        type: "text",
        value: survey.psoriasisHands,
        questionType: "psoriasis",
      },
      {
        id: "psoriasisFeet",
        type: "text",
        value: survey.psoriasisFeet,
        questionType: "psoriasis",
      },
      {
        id: "itching",
        type: "text",
        value: survey.itching,
        questionType: "psoriasis",
      },
      {
        id: "psoriasisFamilyHistory",
        type: "text",
        value: survey.psoriasisFamilyHistory,
        questionType: "psoriasis",
      },
      {
        id: "psoriasisPrescriptionInjectables",
        type: "text",
        value: survey.psoriasisPrescriptionInjectables,
        questionType: "psoriasis",
      },
      {
        id: "psoriasisCurrentlyUsingPrescriptionProducts",
        type: "text",
        value: survey.psoriasisCurrentlyUsingPrescriptionProducts,
        questionType: "psoriasis",
      },
      {
        id: "psoriasisSpecificMedicationsInterest",
        type: "text",
        value: survey.psoriasisSpecificMedicationsInterest,
        questionType: "psoriasis",
      },
      {
        id: "rosaceaPeriod",
        type: "text",
        value: survey.rosaceaPeriod,
        questionType: "rosacea",
      },
      {
        id: "familyHistoryRosacea",
        type: "text",
        value: survey.familyHistoryRosacea,
        questionType: "rosacea",
      },
      {
        id: "rosaceaSymptoms",
        type: "text",
        value: survey.rosaceaSymptoms,
        questionType: "rosacea",
      },
      {
        id: "rosaceaTriggers",
        type: "text",
        value: survey.rosaceaTriggers,
        questionType: "rosacea",
      },
      {
        id: "previousRosaceaProductsTaken",
        type: "text",
        value: survey.previousRosaceaProductsTaken,
        questionType: "rosacea",
      },
      {
        id: "rosaceaNonPrescriptionProducts",
        type: "text",
        value: survey.rosaceaNonPrescriptionProducts,
        questionType: "rosacea",
      },
      {
        id: "rosaceaPrescriptionCreams",
        type: "text",
        value: survey.rosaceaPrescriptionCreams,
        questionType: "rosacea",
      },
      {
        id: "rosaceaPrescriptionPills",
        type: "text",
        value: survey.rosaceaPrescriptionPills,
        questionType: "rosacea",
      },
      {
        id: "rosaceaCurrentlyUsingPrescriptionProducts",
        type: "text",
        value: survey.rosaceaCurrentlyUsingPrescriptionProducts,
        questionType: "rosacea",
      },
      {
        id: "rosaceaSpecificMedicationsInterest",
        type: "text",
        value: survey.rosaceaSpecificMedicationsInterest,
        questionType: "rosacea",
      },
      {
        id: "tineaVersicolorPeriod",
        type: "text",
        value: survey.tineaVersicolorPeriod,
        questionType: "tineaVersicolor",
      },
      {
        id: "tineaVersicolorAreas",
        type: "text",
        value: survey.tineaVersicolorAreas,
        questionType: "tineaVersicolor",
      },
      {
        id: "tineaVersicolorSeasonal",
        type: "text",
        value: survey.tineaVersicolorSeasonal,
        questionType: "tineaVersicolor",
      },
      {
        id: "previousTineaVersicolorProductsTaken",
        type: "text",
        value: survey.previousTineaVersicolorProductsTaken,
        questionType: "tineaVersicolor",
      },
      {
        id: "tineaVersicolorOtcCreams",
        type: "text",
        value: survey.tineaVersicolorOtcCreams,
        questionType: "tineaVersicolor",
      },
      {
        id: "tineaVersicolorPrescriptionCreams",
        type: "text",
        value: survey.tineaVersicolorPrescriptionCreams,
        questionType: "tineaVersicolor",
      },
      {
        id: "tineaVersicolorPrescriptionOrals",
        type: "text",
        value: survey.tineaVersicolorPrescriptionOrals,
        questionType: "tineaVersicolor",
      },
      {
        id: "tineaVersicolorCurrentlyUsingPrescriptionProducts",
        type: "text",
        value: survey.tineaVersicolorCurrentlyUsingPrescriptionProducts,
        questionType: "tineaVersicolor",
      },
      {
        id: "tineaVersicolorSpecificMedicationsInterest",
        type: "text",
        value: survey.tineaVersicolorSpecificMedicationsInterest,
        questionType: "tineaVersicolor",
      },
    ].filter(({ value }) => !isEmpty(value)),
};

export const cycleAndMenstruationDataAdapter = {
  dataToSurvey: (patient: Patient) => ({
    pregnancy: getMedicalBackgroundSurveyAnswer("pregnancy", patient),
    breastfeeding: getMedicalBackgroundSurveyAnswer("breastfeeding", patient),
    menstruationBreakout: getMedicalBackgroundSurveyAnswer(
      "menstruationBreakout",
      patient
    ),
    isRegularMenstruationCycle: getMedicalBackgroundSurveyAnswer(
      "isRegularMenstruationCycle",
      patient
    ),
    isBirthControlTaken: getMedicalBackgroundSurveyAnswer(
      "isBirthControlTaken",
      patient
    ),
    birthControlMeds: getMedicalBackgroundSurveyAnswer(
      "birthControlMeds",
      patient
    ),
    isPcos: getMedicalBackgroundSurveyAnswer("isPcos", patient),
  }),
  surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] =>
    [
      {
        id: "pregnancy",
        type: "bool",
        value: stringToBool(survey.pregnancy) as string,
        questionType: "cycleAndMenstruation",
      },
      {
        id: "breastfeeding",
        type: "bool",
        value: stringToBool(survey.breastfeeding) as string,
        questionType: "cycleAndMenstruation",
      },
      {
        id: "menstruationBreakout",
        type: "text",
        value: survey.menstruationBreakout,
        questionType: "cycleAndMenstruation",
      },
      {
        id: "isRegularMenstruationCycle",
        type: "bool",
        value: stringToBool(survey.isRegularMenstruationCycle) as string,
        questionType: "cycleAndMenstruation",
      },
      {
        id: "isBirthControlTaken",
        type: "bool",
        value: stringToBool(survey.isBirthControlTaken) as string,
        questionType: "cycleAndMenstruation",
      },
      {
        id: "birthControlMeds",
        type: "text",
        value: survey.birthControlMeds,
        questionType: "cycleAndMenstruation",
      },
      {
        id: "isPcos",
        type: "bool",
        value: stringToBool(survey.isPcos) as string,
        questionType: "cycleAndMenstruation",
      },
    ].filter(({ value }) => !isEmpty(value)),
};

export const lifestyleDataAdapter = {
  dataToSurvey: (patient: Patient) => ({
    longShowers: getMedicalBackgroundSurveyAnswer("longShowers", patient),
    stressLevel: getMedicalBackgroundSurveyAnswer("stressLevel", patient),
    sleepAmount: getMedicalBackgroundSurveyAnswer("sleepAmount", patient),
    dairyConsumption: getMedicalBackgroundSurveyAnswer(
      "dairyConsumption",
      patient
    ),
    eczemaSleepImpact: getMedicalBackgroundSurveyAnswer(
      "eczemaSleepImpact",
      patient
    ),
    eczemaSleepPeriod: getMedicalBackgroundSurveyAnswer(
      "eczemaSleepPeriod",
      patient
    ),
    smokeCigarettes: getMedicalBackgroundSurveyAnswer(
      "smokeCigarettes",
      patient
    ),
  }),
  surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] =>
    [
      {
        id: "longShowers",
        type: "text",
        value: survey.longShowers,
        questionType: "lifestyle",
      },
      {
        id: "stressLevel",
        type: "text",
        value: survey.stressLevel,
        questionType: "lifestyle",
      },
      {
        id: "sleepAmount",
        type: "text",
        value: survey.sleepAmount,
        questionType: "lifestyle",
      },
      {
        id: "dairyConsumption",
        type: "text",
        value: survey.dairyConsumption,
        questionType: "lifestyle",
      },
      {
        id: "eczemaSleepImpact",
        type: "text",
        value: survey.eczemaSleepImpact,
        questionType: "lifestyle",
      },
      {
        id: "eczemaSleepPeriod",
        type: "text",
        value: survey.eczemaSleepPeriod,
        questionType: "lifestyle",
      },
      {
        id: "smokeCigarettes",
        type: "text",
        value: survey.smokeCigarettes,
        questionType: "lifestyle",
      },
    ].filter(({ value }) => !isEmpty(value)),
};

export const acneTypeDataAdapter = {
  dataToSurvey: (patient: Patient) => ({
    acnePeriod: getMedicalBackgroundSurveyAnswer("acnePeriod", patient),
    skinDryness: getMedicalBackgroundSurveyAnswer("skinDryness", patient),
    skinSensitivity: getMedicalBackgroundSurveyAnswer(
      "skinSensitivity",
      patient
    ),
  }),
  surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] =>
    [
      {
        id: "acnePeriod",
        type: "text",
        value: survey.acnePeriod,
        questionType: "acne",
      },
      {
        id: "skinDryness",
        type: "text",
        value: survey.skinDryness,
        questionType: "acne",
      },
      {
        id: "skinSensitivity",
        type: "text",
        value: survey.skinSensitivity,
        questionType: "acne",
      },
    ].filter(({ value }) => !isEmpty(value)),
};

// export const eczemaTypeDataAdapter = {
//   dataToSurvey: (patient: Patient) => ({
//     periodEczma: getMedicalBackgroundSurveyAnswer("periodEczma", patient),
//     familyHistory: boolToString(
//       getMedicalBackgroundSurveyAnswer("familyHistory", patient)
//     ),
//     haveAsthma: boolToString(
//       getMedicalBackgroundSurveyAnswer("haveAsthma", patient)
//     ),
//     seasonalAllergies: getMedicalBackgroundSurveyAnswer(
//       "seasonalAllergies",
//       patient
//     ),
//     nonPrescriptionProducts: getMedicalBackgroundSurveyAnswer(
//       "nonPrescriptionProducts",
//       patient
//     ),
//     locationofEczma: getMedicalBackgroundSurveyAnswer(
//       "locationofEczma",
//       patient
//     )
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer("locationofEczma", patient) as string
//         )
//       : undefined,
//     crackorBleed: getMedicalBackgroundSurveyAnswer("crackorBleed", patient),
//     itchyChildSkin: getMedicalBackgroundSurveyAnswer("itchyChildSkin", patient),
//     worseduringSeason: getMedicalBackgroundSurveyAnswer(
//       "worseduringSeason",
//       patient
//     ),
//     otcCreams: getMedicalBackgroundSurveyAnswer("otcCreams", patient)
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer("otcCreams", patient) as string
//         )
//       : undefined,
//     locationofMoisturizers: getMedicalBackgroundSurveyAnswer(
//       "locationofMoisturizers",
//       patient
//     )
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer(
//             "locationofMoisturizers",
//             patient
//           ) as string
//         )
//       : undefined,
//     moisturizersLocationText: getMedicalBackgroundSurveyAnswer(
//       "moisturizersLocationText",
//       patient
//     ),
//     locationofCleansers: getMedicalBackgroundSurveyAnswer(
//       "locationofCleansers",
//       patient
//     ),
//     cleansersLocationText: getMedicalBackgroundSurveyAnswer(
//       "cleansersLocationText",
//       patient
//     ),
//     octPills: getMedicalBackgroundSurveyAnswer("octPills", patient)
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer("octPills", patient) as string
//         )
//       : undefined,
//     antihistaminesPills: getMedicalBackgroundSurveyAnswer(
//       "antihistaminesPills",
//       patient
//     )
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer(
//             "antihistaminesPills",
//             patient
//           ) as string
//         )
//       : undefined,
//     antihistaminesPills: getMedicalBackgroundSurveyAnswer(
//       "antihistaminesPills",
//       patient
//     )
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer(
//             "antihistaminesPills",
//             patient
//           ) as string
//         )
//       : undefined,
//     cleansersLocationText: getMedicalBackgroundSurveyAnswer(
//       "cleansersLocationText",
//       patient
//     ),
//     creamPrecriptions: getMedicalBackgroundSurveyAnswer(
//       "creamPrecriptions",
//       patient
//     )
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer(
//             "creamPrecriptions",
//             patient
//           ) as string
//         )
//       : undefined,
//     prescriptionPills: getMedicalBackgroundSurveyAnswer(
//       "prescriptionPills",
//       patient
//     )
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer(
//             "prescriptionPills",
//             patient
//           ) as string
//         )
//       : undefined,
//     injectables: getMedicalBackgroundSurveyAnswer("injectables", patient)
//       ? JSON.parse(
//           getMedicalBackgroundSurveyAnswer("injectables", patient) as string
//         )
//       : undefined,
//     currentMedication: getMedicalBackgroundSurveyAnswer(
//       "currentMedication",
//       patient
//     ),
//     specificInterestedMedics: getMedicalBackgroundSurveyAnswer(
//       "specificInterestedMedics",
//       patient
//     ),
//   }),
//   surveyToData: (survey: { [p: string]: string }): MedicalBackgroundItem[] =>
//     [
//       {
//         id: "periodEczma",
//         type: "text",
//         value: survey.periodEczma,
//         questionType: "eczema",
//       },
//       {
//         id: "familyHistory",
//         type: "text",
//         value: survey.familyHistory,
//         questionType: "eczema",
//       },
//       {
//         id: "haveAsthma",
//         type: "text",
//         value: survey.haveAsthma,
//         questionType: "eczema",
//       },
//       {
//         id: "seasonalAllergies",
//         type: "text",
//         value: survey.seasonalAllergies,
//         questionType: "eczema",
//       },
//       {
//         id: "nonPrescriptionProducts",
//         type: "text",
//         value: survey.nonPrescriptionProducts,
//         questionType: "eczema",
//       },
//       {
//         id: "locationofEczma",
//         type: "text",
//         value: survey.locationofEczma,
//         questionType: "eczema",
//       },
//       {
//         id: "crackorBleed",
//         type: "text",
//         value: survey.crackorBleed,
//         questionType: "eczema",
//       },
//       {
//         id: "itchyChildSkin",
//         type: "text",
//         value: survey.itchyChildSkin,
//         questionType: "eczema",
//       },
//       {
//         id: "worseduringSeason",
//         type: "text",
//         value: survey.worseduringSeason,
//         questionType: "eczema",
//       },
//       {
//         id: "otcCreams",
//         type: "text",
//         value: survey.otcCreams,
//         questionType: "eczema",
//       },
//       {
//         id: "locationofMoisturizers",
//         type: "text",
//         value: survey.locationofMoisturizers,
//         questionType: "eczema",
//       },
//       {
//         id: "moisturizersLocationText",
//         type: "text",
//         value: survey.moisturizersLocationText,
//         questionType: "eczema",
//       },
//       {
//         id: "locationofCleansers",
//         type: "text",
//         value: survey.locationofCleansers,
//         questionType: "eczema",
//       },
//       {
//         id: "cleansersLocationText",
//         type: "text",
//         value: survey.cleansersLocationText,
//         questionType: "eczema",
//       },
//       {
//         id: "octPills",
//         type: "text",
//         value: survey.octPills,
//         questionType: "eczema",
//       },
//       {
//         id: "antihistaminesPills",
//         type: "text",
//         value: survey.antihistaminesPills,
//         questionType: "eczema",
//       },
//       {
//         id: "cleansersLocationText",
//         type: "text",
//         value: survey.cleansersLocationText,
//         questionType: "eczema",
//       },
//       {
//         id: "creamPrecriptions",
//         type: "text",
//         value: survey.creamPrecriptions,
//         questionType: "eczema",
//       },
//       {
//         id: "prescriptionPills",
//         type: "text",
//         value: survey.prescriptionPills,
//         questionType: "eczema",
//       },
//       {
//         id: "injectables",
//         type: "text",
//         value: survey.injectables,
//         questionType: "eczema",
//       },
//       {
//         id: "currentMedication",
//         type: "text",
//         value: survey.currentMedication,
//         questionType: "eczema",
//       },
//       {
//         id: "specificInterestedMedics",
//         type: "text",
//         value: survey.specificInterestedMedics,
//         questionType: "eczema",
//       },
//     ].filter(({ value }) => !isEmpty(value)),
// };
