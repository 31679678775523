import React, { createContext, useState } from "react";
import { ReactComponent as CrossIcon } from "../../../../../Assets/NewIcons/close-cross.svg";
import { logInfo } from "../../../../../shared/logger";
import "./style.scss";

interface Props {
  onClose: () => void;
  children: JSX.Element[] | JSX.Element;
  showCross?: boolean;
}

enum ANIMATIONS {
  SHOW = "show",
  HIDE = "fade",
}

export const HelpPopupTemplateContext = createContext({
  onClose: () => logInfo(""),
});

export function HelpPopupTemplate({
  children,
  onClose,
  showCross = true,
}: Props) {
  const [animation, setAnimation] = useState(ANIMATIONS.SHOW);

  function close() {
    setAnimation(ANIMATIONS.HIDE);
    setTimeout(onClose, 500);
  }

  return (
    <HelpPopupTemplateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        onClose: close,
      }}
    >
      <div className={`help-popup-template__wrap ${animation}`}>
        <div className="help-popup-template">
          {showCross && (
            <CrossIcon className="help-popup-template__cross" onClick={close} />
          )}
          {children}
        </div>
      </div>
    </HelpPopupTemplateContext.Provider>
  );
}
