import React, {
  KeyboardEventHandler,
  useContext,
  useEffect,
  useState,
} from "react";
import CreatableSelect from "react-select/creatable";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { Button } from "../Button";
import "./style.scss";
import { StyledSelectCreatable } from "../StyledSelect";
import { Input } from "../Input";
import { NotificationLayerContext } from "../NotificationLayer";
import DateSelect from "../DateSelect";
import { styledSelectMultipleStyles } from "../StyledSelect/constants";
import { Patient } from "../../../../types/Entities/Patient";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { Loader } from "../Loader";
import { getSessionState } from "../../../../features/session";
import { Provider } from "../../../../types/Employee";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { usePopulateBloodSlipPdfMutation } from "../../../../features/api/accutane";
import { ENV_CONFIG } from "../../../../config";

interface Props {
  onClose: () => void;
}

interface SendBloodSlipData {
  patient: Patient;
  provider: Provider;
}

interface SendBloodSlipProps extends Props {
  patient: Patient;
  provider: Provider;
}

interface FieldStructure {
  field_name: string;
  prefilled_text: string;
}

interface BloodSlipPopupSectionProps {
  title: string;
  description: string;
  fields: any[];
}

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const dateFormat = "MM/DD/YYYY";

function CreatableSelectIndicatorless({
  onChange,
  initialValue,
}: {
  onChange: any;
  initialValue?: Option[];
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<readonly Option[]>(
    initialValue || []
  );

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const inputChangeHandler = (newValue: any) => {
    setInputValue(newValue);
  };

  useEffect(() => {
    onChange(value);
  }, [value.length]);

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      styles={styledSelectMultipleStyles}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={(newValue) => setValue(newValue)}
      onInputChange={inputChangeHandler}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter"
      value={value}
      backspaceRemovesValue
    />
  );
}

// TODO extract to separate component for popups
function BloodSlipPopupSection({
  title,
  description,
  fields,
}: BloodSlipPopupSectionProps) {
  return (
    <div className="blood-slip-popup__content__section">
      <div className="blood-slip-popup__content__section__head">
        <p className="blood-slip-popup__content__section__head__title">
          {title}
        </p>
        <p className="blood-slip-popup__content__section__head__description">
          {description}
        </p>
      </div>
      <div className="blood-slip-popup__content__section__fields">
        {fields.map((field) => (
          <div
            className="blood-slip-popup__content__section__fields__field"
            key={`${title}__${description}`}
          >
            {field}
          </div>
        ))}
      </div>
    </div>
  );
}

/**
 * Lipid Profile, CMP - if can not get pregnant
 * Lipid Profile, CMP, HCG - if can get pregnant
 */
enum TestsOrderValues {
  LIPID_PROFILE = "Lipid Profile",
  HEPATIC_PANEL = "Hepatic Panel",
  HCG = "Qualitative (Urine) HCG test. If patient declines, Quantitative (blood) HCG is acceptable",
  CBC = "CBC",
  CMP = "CMP",
  PSA = "PSA",
}

const maleTestsOptions = [
  TestsOrderValues.LIPID_PROFILE,
  TestsOrderValues.HEPATIC_PANEL,
];
const femaleTestsOptions = [
  TestsOrderValues.LIPID_PROFILE,
  TestsOrderValues.HEPATIC_PANEL,
  TestsOrderValues.HCG,
];

function convertTestsIntoOptions(options: TestsOrderValues[]) {
  return options.map((option) => ({
    label: option,
    value: option,
  }));
}

function testsOrdersInitialValuesByGender(
  gender: string | null,
  isPregnantCapability: boolean
) {
  if (!gender) {
    return [];
  }
  const options =
    gender === "Male" || !isPregnantCapability
      ? maleTestsOptions
      : femaleTestsOptions;

  return convertTestsIntoOptions(options);
}

enum TemplatesList {
  ACCUTANE_BLOOD_SLIP = "accutaneBloodSlipTemplate",
  MALE_PATTERN_HAIR_LOSS = "malePatternHairLoss",
  PREGNANCY_TEST = "pregnancyTest",
}

const bloodSlipTemplateOptions = [
  {
    label: "Accutane Blood slip",
    value: TemplatesList.ACCUTANE_BLOOD_SLIP,
  },
  {
    label: "Male pattern hair loss",
    value: TemplatesList.MALE_PATTERN_HAIR_LOSS,
  },
  {
    label: "Pregnancy test",
    value: TemplatesList.PREGNANCY_TEST,
  },
];

function getFormFieldsByTemplateOption(
  option: TemplatesList | string,
  patient: Patient,
  provider: Provider,
  isPregnantCapability: boolean
) {
  switch (option) {
    case TemplatesList.ACCUTANE_BLOOD_SLIP: {
      return {
        "bloodSlipDetails.template": bloodSlipTemplateOptions[0],
        "bloodSlipDetails.diagnosisCode": "L70.0",
        "bloodSlipDetails.levelsToBeMeasured": testsOrdersInitialValuesByGender(
          patient.medicalBackground?.sex || null,
          isPregnantCapability === undefined
            ? !patient?.flags?.isNotPregnantCapability
            : isPregnantCapability
        ),
        "bloodSlipDetails.validFrom": getMomentDate(new Date())
          .add(patient.medicalBackground.sex === "Female" ? 30 : 0, "days")
          .format(dateFormat),
        "bloodSlipDetails.duration":
          "Valid every 4 weeks for 6 months as needed",
        "patientDetails.name": patient.fullName,
        "patientDetails.dateOfBirth": patient.dateOfBirth,
        "providerDetails.name": `${provider.firstName} ${provider.lastName}`,
        "providerDetails.npi": provider.npiNumber,
        "bloodSlipDetails.alertText": TestsOrderValues.LIPID_PROFILE
          ? "Important: Please fast for 12 hours before getting your bloodwork done. You are able to drink water while fasting."
          : null,
      };
    }
    case TemplatesList.MALE_PATTERN_HAIR_LOSS: {
      return {
        "bloodSlipDetails.template": bloodSlipTemplateOptions[1],
        "bloodSlipDetails.diagnosisCode": "L63.0",
        "bloodSlipDetails.levelsToBeMeasured": convertTestsIntoOptions([
          TestsOrderValues.CBC,
          TestsOrderValues.CMP,
          TestsOrderValues.PSA,
        ]),
        "bloodSlipDetails.validFrom": getMomentDate(new Date()).format(
          dateFormat
        ),
        "bloodSlipDetails.duration": "Does not repeat",
        "patientDetails.name": patient.fullName,
        "patientDetails.dateOfBirth": patient.dateOfBirth,
        "providerDetails.name": `${provider.firstName} ${provider.lastName}`,
        "providerDetails.npi": provider.npiNumber,
      };
    }
    case TemplatesList.PREGNANCY_TEST: {
      return {
        "bloodSlipDetails.template": bloodSlipTemplateOptions[2],
        "bloodSlipDetails.diagnosisCode": "L70.0",
        "bloodSlipDetails.levelsToBeMeasured": convertTestsIntoOptions([
          TestsOrderValues.HCG,
        ]),
        "bloodSlipDetails.validFrom": getMomentDate(new Date()).format(
          dateFormat
        ),
        "bloodSlipDetails.duration":
          "Valid every 4 weeks for 6 months as needed",
        "patientDetails.name": patient.fullName,
        "patientDetails.dateOfBirth": patient.dateOfBirth,
        "providerDetails.name": `${provider.firstName} ${provider.lastName}`,
        "providerDetails.npi": provider.npiNumber,
      };
    }
    default: {
      return {
        "bloodSlipDetails.template": {
          label: option,
          value: option,
        },
        "bloodSlipDetails.diagnosisCode": "",
        "bloodSlipDetails.levelsToBeMeasured": [],
        "bloodSlipDetails.validFrom": getMomentDate(new Date()).format(
          dateFormat
        ),
        "bloodSlipDetails.duration": "",
        "patientDetails.name": patient.fullName,
        "patientDetails.dateOfBirth": patient.dateOfBirth,
        "providerDetails.name": `${provider.firstName} ${provider.lastName}`,
        "providerDetails.npi": provider.npiNumber,
      };
    }
  }
}

function SendBloodSlipPopup({
  onClose,
  patient,
  provider,
}: SendBloodSlipProps) {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const { userId: employeeId } = useSelector(getSessionState);
  const [populatePdf] = usePopulateBloodSlipPdfMutation();
  const [fields, setFields] = useState(
    getFormFieldsByTemplateOption(
      TemplatesList.ACCUTANE_BLOOD_SLIP,
      patient,
      provider
    )
  );
  const [shouldShowCreation, setShowCreation] = useState(false);
  const [updating, setUpdating] = useState(false);
  const documentId = "3458bc2fdc4f4a0787baa4fb829a0482876b53e1";

  useEffect(() => {
    (async () => {
      const response = await HoneydewAPI.accutane.getSignNowDocument(
        documentId
      );

      if (response.fields) {
        const initialFields = response.fields.reduce(
          (acc, { json_attributes }) => {
            acc[json_attributes.name] = null;

            return acc;
          },
          {} as any
        );
        setFields({ ...initialFields, ...fields });
      }
    })();
  }, []);

  const getFieldChangeHandler =
    (fieldId: string) => (value: string | Option[]) => {
      let updatedFields: any = { ...fields };
      // Check if Lipid Profile is included in the selected tests
      const isLipidProfilePresent =
        fieldId === "bloodSlipDetails.levelsToBeMeasured" &&
        value &&
        value.some(
          (test: Option) => test.value === TestsOrderValues.LIPID_PROFILE
        );
      // If Lipid Profile is included, set the alert text; otherwise, set it to null
      if (fieldId === "bloodSlipDetails.levelsToBeMeasured") {
        updatedFields = {
          ...updatedFields,
          [fieldId]: value,
          "bloodSlipDetails.alertText": isLipidProfilePresent
            ? "Important: Please fast for 12 hours before getting your bloodwork done. You are able to drink water while fasting."
            : null,
        };
      } else {
        updatedFields[fieldId] = value;
      }

      // Update the state with the new values
      setFields(updatedFields);
    };

  const onTemplateChangeHandler = (selectedTemplate: {
    label: string;
    value: TemplatesList | string;
  }) => {
    const newFieldsValues = getFormFieldsByTemplateOption(
      selectedTemplate.value as TemplatesList,
      patient,
      provider
    );

    setUpdating(true);
    setTimeout(() => {
      setFields({ ...fields, ...newFieldsValues });
      setUpdating(false);
    }, 500);
  };

  const populatePdfHandler = async () => {
    const fieldsToSend: FieldStructure[] = Object.keys(fields)
      .filter((field) => field !== "bloodSlipDetails.template")
      .filter(
        (field) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !!fields[field]
      )
      .map((field) => ({
        field_name: field,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        prefilled_text: Array.isArray(fields[field])
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fields[field].map(({ label }: { label: string }) => label).join(";")
          : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fields[field],
      }));
    const currentDate = getMomentDate(new Date()).format(dateFormat);
    const result: any = await populatePdf({
      patientId: patient.patientId,
      documentName: `Blood slip for ${patient.fullName} ${currentDate}`,
      documentId: ENV_CONFIG.BLOOD_SLIP_TEMPLATE_ID,
      fields: fieldsToSend,
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to populate a blood slip",
      });
      return;
    }

    showSuccess({
      title: "Success",
      description: "Blood slip was successfully populated",
    });
    onClose();
  };

  return updating ? (
    <Loader className="updating-form-loader" />
  ) : (
    <div className="blood-slip-popup">
      <Button
        text="Populate pdf"
        size="small"
        onClick={populatePdfHandler}
        className="blood-slip-popup__submit-mobile"
      />
      {/* <p className="blood-slip-popup__description"> */}
      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur mi */}
      {/*  tellus. */}
      {/* </p> */}
      <div className="blood-slip-popup__content">
        <BloodSlipPopupSection
          title="Select blood slip template"
          description=""
          fields={[
            <>
              <p className="select-label">Blood slip template</p>
              <StyledSelectCreatable
                id="blood-slip-template"
                name="bloodSlipTemplate"
                value={fields["bloodSlipDetails.template"]}
                onChange={onTemplateChangeHandler}
                placeholder="Select template"
                options={bloodSlipTemplateOptions}
                formatCreateLabel={(inputValue: string) =>
                  `${inputValue} (custom)`
                }
                createOptionPosition="first"
                filterOption={(option: any, searchText: any) => {
                  if (!searchText) {
                    setShowCreation(false);
                    return true;
                  }
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return true;
                  }

                  setShowCreation(true);
                  return false;
                }}
                onCreateOption={(option) => {
                  const newFieldsValues = getFormFieldsByTemplateOption(
                    option,
                    patient,
                    provider
                  );

                  setUpdating(true);
                  setTimeout(() => {
                    setFields({ ...fields, ...newFieldsValues });
                    setUpdating(false);
                  }, 0);
                }}
                isValidNewOption={() => shouldShowCreation}
                isSearchable
              />
            </>,
          ]}
        />
        <BloodSlipPopupSection
          title="Blood slip details"
          description=""
          fields={[
            <>
              <p className="select-label">Diagnosis code</p>
              <Input
                id="diagnosis-code"
                name="diagnosisCode"
                placeholder="Enter diagnosis code"
                value={fields["bloodSlipDetails.diagnosisCode"]}
                onChange={getFieldChangeHandler(
                  "bloodSlipDetails.diagnosisCode"
                )}
              />
            </>,
            <>
              <p className="select-label">Tests ordered</p>
              <CreatableSelectIndicatorless
                initialValue={fields["bloodSlipDetails.levelsToBeMeasured"]}
                onChange={getFieldChangeHandler(
                  "bloodSlipDetails.levelsToBeMeasured"
                )}
              />
            </>,
            <>
              <p className="select-label">Valid from</p>
              <DateSelect
                placeholder="Select date"
                value={fields["bloodSlipDetails.validFrom"]}
                onChange={getFieldChangeHandler("bloodSlipDetails.validFrom")}
              />
            </>,
            <>
              <p className="select-label">Frequency / duration</p>
              <Input
                id="frequencyDuration"
                name="frequencyDuration"
                placeholder="Enter duration"
                value={fields["bloodSlipDetails.duration"]}
                onChange={getFieldChangeHandler("bloodSlipDetails.duration")}
              />
            </>,
          ]}
        />
        <BloodSlipPopupSection
          title="Patient details"
          description=""
          fields={[
            <>
              <p className="select-label">Patient's name</p>
              <Input
                id="patientName"
                name="patientName"
                placeholder="Enter patient name"
                value={fields["patientDetails.name"]}
                onChange={getFieldChangeHandler("patientDetails.name")}
              />
            </>,
            <>
              <p className="select-label">Date of birth</p>
              <DateSelect
                disableFutureDates
                placeholder="Select date"
                value={fields["patientDetails.dateOfBirth"]}
                onChange={getFieldChangeHandler("patientDetails.dateOfBirth")}
              />
            </>,
          ]}
        />
        <BloodSlipPopupSection
          title="Provider's details"
          description=""
          fields={[
            <>
              <p className="select-label">Provider's name</p>
              <Input
                id="providerName"
                name="providerName"
                placeholder="Enter provider's name"
                value={fields["providerDetails.name"]}
                onChange={getFieldChangeHandler("providerDetails.name")}
              />
            </>,
            <>
              <p className="select-label">Npi number</p>
              <Input
                id="npiNumber"
                name="npiNumber"
                placeholder="Enter npi number"
                value={fields["providerDetails.npi"]}
                onChange={getFieldChangeHandler("providerDetails.npi")}
              />
            </>,
          ]}
        />
      </div>
      <div className="blood-slip-popup__submit-desktop">
        <Button text="Populate pdf" size="small" onClick={populatePdfHandler} />
      </div>
    </div>
  );
}

function SendBloodSlipPopupGenerator(sendBloodSlipData: SendBloodSlipData) {
  return function render({ onClose }: Props) {
    return (
      <ActionPopupTemplate title="Send blood slip form" onClose={onClose}>
        <SendBloodSlipPopup
          onClose={onClose}
          patient={sendBloodSlipData.patient}
          provider={sendBloodSlipData.provider}
        />
      </ActionPopupTemplate>
    );
  };
}

export default SendBloodSlipPopupGenerator;

export const getBloodSlipTemplate = (
  patient: Patient,
  provider: Provider,
  isPregnantCapability: boolean
) =>
  getFormFieldsByTemplateOption(
    TemplatesList.ACCUTANE_BLOOD_SLIP,
    patient,
    provider,
    isPregnantCapability
  );

export const getPregnancyTestTemplate = (
  patient: Patient,
  provider: Provider
) =>
  getFormFieldsByTemplateOption(
    TemplatesList.PREGNANCY_TEST,
    patient,
    provider
  );
