import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const signBloodSlip = async (
  taskId: string,
  compositeKey: string,
  documentGroupId: string,
  inviteId: string,
  patientId: string
): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/sign-blood-slip/${patientId}`,
      {
        taskId,
        recordId: compositeKey,
        documentGroupId,
        inviteId,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to sign blood slip");
    throw error;
  }
};
