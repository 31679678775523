import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCareCoordinatorsInfoState } from "../../../../features/careCoordinators";
import { CareCoordinatorsTableBody } from "./tableBody";
import "./style.css";
import { CareCoordinatorsTableHead } from "./tableHead";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { TableItemValue } from "../../../../types/Table/TableItemValue";
import { SortingPayload } from "../../../../types/Table/SortingPayload";
import { SortingFunction } from "../../../../types/Table/SortingFunction";
import { useGetEmployeesByRoleQuery } from "../../../../features/api/employees";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { Button } from "../../../NewComponents/Common/Button";
import { USER_ROLES } from "../../../../types/Main";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { CareCoordinator } from "../../../../types/Employee";
import { PatientsTablePagination } from "../../../NewComponents/Common/PatientsTableElastic/tablePagination";

function alphabetical(a: TableItemValue, b: TableItemValue) {
  const c = typeof a === "string" ? a.toLowerCase() : a;
  const d = typeof b === "string" ? b.toLowerCase() : b;
  if (c > d) return 1;
  if (c < d) return -1;
  return 0;
}

const columnsList: TableHeadPayload<TableBodyPayload<CareCoordinator>>[] = [
  {
    title: "Full Name",
    get: ({ entity }) => `${entity.lastName}`,
    sort: alphabetical,
  },
  {
    title: "Email",
    get: ({ entity }) => entity.email,
    sort: alphabetical,
  },
  {
    title: "Phone",
    get: ({ entity }) => entity.phone,
    sort: alphabetical,
  },
  {
    title: "Actions",
  },
];

function setOrder(func: SortingFunction, order: "asc" | "desc") {
  return (a: TableItemValue, b: TableItemValue) =>
    order === "asc" ? func(a, b) : func(b, a);
}

function sortByColumn(
  careCoordinatorsList: TableBodyPayload<CareCoordinator>[],
  { column: columnName, state }: SortingPayload
) {
  const column = columnsList.find(({ title }) => title === columnName);
  if (!column) return careCoordinatorsList;
  if (!column.sort && !column.get) return careCoordinatorsList;
  return careCoordinatorsList.sort((a, b) =>
    setOrder(column.sort as SortingFunction, state)(
      (
        column.get as (
          item: TableBodyPayload<CareCoordinator>
        ) => string | number | Date
      )(a),
      (
        column.get as (
          item: TableBodyPayload<CareCoordinator>
        ) => string | number | Date
      )(b)
    )
  );
}

function defaultSort(
  careCoordinatorsList: TableBodyPayload<CareCoordinator>[],
  sorting: SortingPayload | null
) {
  const result = Array.of(careCoordinatorsList);

  if (sorting) {
    result.forEach((list) => sortByColumn(list, sorting));
  }

  return result.reduce((acc, el) => [...acc, ...el], []);
}

const processCareCoordinatorsList = (
  careCoordinatorsList: CareCoordinator[],
  sorting: SortingPayload | null
) => {
  const filteredCareCoordinatorsList = careCoordinatorsList.filter(
    (obj) => obj.archived !== true
  );
  const processedCareCoordinatorsList: TableBodyPayload<CareCoordinator>[] =
    filteredCareCoordinatorsList.map((careCoordinator) => ({
      isNewMessage: false,
      entity: careCoordinator,
      nextFollowUpDate: null,
      rowClass: null,
      type: null,
    }));

  return defaultSort(processedCareCoordinatorsList, sorting);
};

export function CareCoordinatorsTable() {
  const role = USER_ROLES.CARE_COORDINATOR;
  const { showPopup } = useContext(PopupLayerContext);
  const careCoordinatorsQuery = useGetEmployeesByRoleQuery(role);
  const [sort, setSort] = useState<SortingPayload | null>(null);
  const [
    defaultSortedCareCoordinatorsData,
    setDefaultSortedCareCoordinatorsData,
  ] = useState();

  useEffect(() => {
    if (
      careCoordinatorsQuery.data &&
      Array.isArray(careCoordinatorsQuery.data)
    ) {
      const sortedData = [...careCoordinatorsQuery.data].sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
      setDefaultSortedCareCoordinatorsData(sortedData);
    }
  }, [careCoordinatorsQuery.data]);

  return defaultSortedCareCoordinatorsData ? (
    <>
      {/* <div className="medicine-button-wrapper">
        <Button
          text="Create new care coordinator"
          onClick={() => showPopup(EmployeePopupGenerator(undefined, role))}
        />
      </div> */}
      <div className="patientsTable-wrapper">
        <table className="patientsTable">
          <CareCoordinatorsTableHead
            columns={columnsList}
            onSortChange={(value) => setSort(value)}
          />
          <CareCoordinatorsTableBody
            careCoordinatorsList={processCareCoordinatorsList(
              defaultSortedCareCoordinatorsData as CareCoordinator[],
              sort
            )}
          />
        </table>
        <PatientsTablePagination
          max={Math.ceil(100 / 10)}
          current={0}
          onChange={(it) => console.log("Paginations added")}
          simple
        />
      </div>
    </>
  ) : (
    <Skeleton flex count={5} fullWidth column style={{ height: 60 }} />
  );
}
