import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const getReferralLink = async (patientId: string) => {
  try {
    const result = await axios.get<string>(
      // TODO: change after domain full implementation
      `${ENV_CONFIG.ROUTE_53_BASE_URL}/referrals/referral-link/${patientId}`
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get user data");
    throw error;
  }
};
