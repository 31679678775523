import React, { useState, useEffect } from "react";
import { isNull } from "util";

interface TabsProps {
  onChange: (state: any) => void;
  tabs: string[];
}

export function PatientsTableTabs({ tabs, onChange }: TabsProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const currentTabIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentTabIndex);
    if (!isNull(currentIndexFormatted)) {
      setCurrentIndex(currentIndexFormatted.currentTab);
    }
  }, []);

  const handleTabClick = (index) => {
    const myState = { currentTab: index, currentSubTab: 0 };
    localStorage.setItem("currentIndex", JSON.stringify(myState));
    localStorage.setItem("currentPage", JSON.stringify(0));
    setCurrentIndex(index);
    onChange(index);
  };

  return (
    <>
      {tabs.map((tab, index) => (
        <div
          className={`patients-table__tab ${
            index === currentIndex ? "selected" : ""
          }`}
          onClick={() => {
            handleTabClick(index);
          }}
        >
          <p className="patients-table__tab-text paragraph-font--color">
            {tab}
          </p>
        </div>
      ))}
    </>
  );
}
