import { newFollowUp, newPatient } from "./queryPartials";

export const patientDashboardForAdministratorOrder = (
  administratorId: string
) => [
  {
    filter: {
      bool: {
        must: {
          match: {
            tasks: administratorId,
          },
        },
        must_not: newFollowUp,
      },
    },
    weight: 6,
  },
  {
    filter: {
      ...newPatient,
    },
    weight: 4,
  },
  {
    filter: {
      ...newFollowUp,
    },
    weight: 2,
  },
  {
    filter: {
      bool: {
        should: [
          {
            match: {
              [`flags.pinnedByAdministrator_${administratorId}`]: true,
            },
          },
          {
            match: {
              "flags.pinnedByAdministrator": true,
            },
          },
        ],
      },
    },
    weight: 1,
  },
];
