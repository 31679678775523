import { createSlice } from "@reduxjs/toolkit";
import { AuthenticationService } from "../services/cognito";
import { SessionPayload } from "../types/Payloads/SessionPayload";
import { ReduxAction, ReduxStore } from "../types/Stores";
import { SessionStore } from "../types/Stores/SessionStore";
import { clearAllStores } from "./utils/clear-all-stores";

const initialState: SessionStore = {
  isSessionChecked: false,
  isSessionExists: false,
  userRole: null,
  userId: null,
  session: null,
  activePatientId: null,
  newMsgsCount: null,
};

const updateStateWithSession = (
  state: SessionStore,
  sessionPayload: SessionPayload | null
) => {
  state.isSessionExists = !!sessionPayload;
  state.userRole = sessionPayload?.["cognito:groups"]?.[0] || null;
  state.userId = sessionPayload?.sub || null;
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    signOut: (state) => {
      AuthenticationService.signOut();

      state.isSessionExists = false;
      state.session = null;
      state.userRole = null;
      state.userId = null;
      state.activePatientId = null;
      state.newMsgsCount = null;
    },
    setActivePatient: (state, { payload }: ReduxAction<string | null>) => {
      state.activePatientId = payload;
    },
    signIn: (state, { payload }: ReduxAction<SessionPayload | null>) => {
      state.isSessionChecked = true;
      updateStateWithSession(state, payload);
    },
    checkSession: (state, { payload }: ReduxAction<SessionPayload | null>) => {
      state.isSessionChecked = true;
      updateStateWithSession(state, payload);
    },
    setNewMsgsCount: (state, { payload }: ReduxAction<number>) => {
      state.newMsgsCount = payload;
    },
  },
});

export const {
  signOut,
  signIn,
  checkSession,
  setActivePatient,
  setNewMsgsCount,
} = sessionSlice.actions;

export const getSessionState = ({ sessionStore }: ReduxStore) => sessionStore;

export default sessionSlice.reducer;
