import React, { useContext, useState } from "react";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import "./style.scss";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { ReactComponent as SunIcon } from "../../../../Assets/NewIcons/sun.svg";
import { ReactComponent as MoonIcon } from "../../../../Assets/NewIcons/moon.svg";
import { Button } from "../../Common/Button";
import { TextInput } from "../../Common/TextInput";
import { NumberInput } from "../../Common/NumberInput";
import { Checkbox } from "../../Common/Checkbox";
import { Medication } from "../../../../types/Medication";
import { TextArea } from "../../Common/TextArea";
import {
  useCreateMedicationMutation,
  useUpdateMedicationMutation,
} from "../../../../features/api/medications";

interface GeneratorProps {
  onClose: () => void;
}

interface MedicationPopupProps {
  medication?: Medication;
  onClose: () => void;
}

interface MedicationPopupSectionProps {
  title: string;
  description: string;
  fields: any[];
}

// todo extract to separate component, find same name in code
function MedicationPopupSection({
  title,
  description,
  fields,
}: MedicationPopupSectionProps) {
  return (
    <div className="edit-patient-details-popup__content__section">
      <div className="edit-patient-details-popup__content__section__head">
        <p className="edit-patient-details-popup__content__section__head__title">
          {title}
        </p>
        <p className="edit-patient-details-popup__content__section__head__description">
          {description}
        </p>
      </div>
      <div className="edit-patient-details-popup__content__section__fields">
        {fields.map((field, index) => (
          <div
            className="edit-patient-details-popup__content__section__fields__field"
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${title}__${description}__${index}`}
          >
            {field}
          </div>
        ))}
      </div>
    </div>
  );
}

function MedicationPopup({ medication, onClose }: MedicationPopupProps) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const isCreation = !medication;
  const [formValues, setFormField] = useState({
    name: medication?.name || "",
    strength: medication?.strength || "",
    size: medication?.size || "",
    specialInstructions: medication?.specialInstructions || "",
    refillCount: medication?.refillCount || 0,
    refillExpiration: medication?.refillExpiration || 0,
    instructions: new Set(medication?.instructions || []),
  });
  const [createMedication] = useCreateMedicationMutation();
  const [updateMedication] = useUpdateMedicationMutation();
  const [formErrors, setError] = useState<Record<string, string>>({});
  const MIN_REFILL_COUNT = 0;
  const MAX_REFILL_COUNT = 99;
  const handleFormError = (fieldName: string, errorMessage: string) => {
    setError((prevState) => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = () => {
    if (formValues.name.trim().length === 0) {
      handleFormError("name", "Required field");
      return false;
    }

    if (formValues.refillCount > MAX_REFILL_COUNT) {
      handleFormError(
        "refillCount",
        `Refill count must not exceed ${MAX_REFILL_COUNT}`
      );
      return false;
    }

    if (formValues.refillCount < MIN_REFILL_COUNT) {
      handleFormError(
        "refillCount",
        `Refill count can not be less than ${MIN_REFILL_COUNT}`
      );
      return false;
    }

    return true;
  };

  const onSaveChangesHandler = async () => {
    const validationResult = validateForm();
    if (!validationResult) {
      return;
    }

    const payload = {
      ...formValues,
      instructions: Array.from(formValues.instructions),
    };

    const response: any = isCreation
      ? await createMedication(payload)
      : await updateMedication({
          medicationId: medication.id,
          payload,
        });

    if (response?.data?.errors || response.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to submit medication form",
      });
      return;
    }

    showSuccess({
      title: "Success!",
      description: "Medication form has been submitted successfully",
    });
    onClose();
  };

  return (
    <div className="edit-patient-details-popup">
      <Button
        text="submit"
        className="edit-patient-details-popup__save-button"
        size="small"
        onClick={onSaveChangesHandler}
      />
      <p className="edit-patient-details-popup__info">
        After you save the applied changes the details will be changed for
        patient as well.
      </p>
      <div className="edit-patient-details-popup__content">
        <MedicationPopupSection
          title="Medication details"
          description=""
          fields={[
            <>
              {/* <p className="edit-patient-details-popup__input-title">
                    Name
                  </p> */}
              <TextInput
                id="medication-name-input"
                error={formErrors?.name ?? null}
                name="medicationName"
                label="Name"
                value={formValues?.name}
                onChange={(value) =>
                  setFormField({
                    ...formValues,
                    name: value,
                  })
                }
              />
            </>,
            <>
              {/* <p className="edit-patient-details-popup__input-title">
                    Strength
                  </p> */}
              <TextInput
                id="medication-strength-input"
                error={formErrors?.strength ?? null}
                name="medicationStrength"
                label="Strength"
                value={formValues.strength}
                onChange={(value) =>
                  setFormField({
                    ...formValues,
                    strength: value,
                  })
                }
              />
            </>,
            <>
              {/* <p className="edit-patient-details-popup__input-title">
                    Size
                  </p> */}
              <TextInput
                id="medication-size-input"
                error={formErrors?.size ?? null}
                name="medicationSize"
                label="Size"
                value={formValues.size}
                onChange={(value) =>
                  setFormField({
                    ...formValues,
                    size: value,
                  })
                }
              />
            </>,
            <>
              <p className="edit-patient-details-popup__input-title">
                Instructions
              </p>
              <div className="medication-popup__instructions-block">
                <Checkbox
                  checked={formValues.instructions.has("morning")}
                  onChange={(checked) => {
                    if (checked) formValues.instructions.add("morning");
                    else formValues.instructions.delete("morning");
                    setFormField({
                      ...formValues,
                      instructions: formValues.instructions,
                    });
                  }}
                >
                  <div className="when-to-use when-to-use__morning">
                    <SunIcon />
                    <span>Morning</span>
                  </div>
                </Checkbox>
                <Checkbox
                  checked={formValues.instructions.has("evening")}
                  onChange={(checked) => {
                    if (checked) formValues.instructions.add("evening");
                    else formValues.instructions.delete("evening");
                    setFormField({
                      ...formValues,
                      instructions: formValues.instructions,
                    });
                  }}
                >
                  <div className="when-to-use when-to-use__evening">
                    <MoonIcon />
                    <span>Evening</span>
                  </div>
                </Checkbox>
              </div>
            </>,
            <>
              <p className="edit-patient-details-popup__input-title">
                Special instructions
              </p>
              <TextArea
                value={formValues.specialInstructions}
                onChange={(value) =>
                  setFormField({
                    ...formValues,
                    specialInstructions: value,
                  })
                }
              />
            </>,
            <NumberInput
              id="refill-count"
              name="refill-count"
              min={MIN_REFILL_COUNT}
              max={MAX_REFILL_COUNT}
              step={1}
              error={formErrors?.refillCount ?? null}
              onChange={(value) => {
                if (/^[0-9]+$/.test(value)) {
                  setFormField({
                    ...formValues,
                    refillCount: Number(value),
                  });
                }
              }}
              value={String(formValues.refillCount)}
              label="Refill Count"
            />,
            <NumberInput
              id="refill-expiration"
              name="refill-expiration"
              min={0}
              max={999}
              step={1}
              onChange={(value) => {
                if (/^[0-9]+$/.test(value)) {
                  setFormField({
                    ...formValues,
                    refillExpiration: Number(value),
                  });
                }
              }}
              value={String(formValues.refillExpiration)}
              label="Refill Expiration"
            />,
          ]}
        />
      </div>
    </div>
  );
}

export function MedicationPopupGenerator(medication?: Medication) {
  return function render({ onClose }: GeneratorProps) {
    return (
      <ActionPopupTemplate
        title={medication ? "Edit Medication" : "Create Medication"}
        onClose={onClose}
      >
        <MedicationPopup medication={medication} onClose={onClose} />
      </ActionPopupTemplate>
    );
  };
}
