import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { PaymentConfiguration } from "../../../../../types/Payment/PaymentConfiguration";

export const getConfiguration = async () => {
  try {
    const result = await axios.get<PaymentConfiguration>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/payments/get-config`
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get Payment Configuration", error);
    throw error;
  }
};
