import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

export const getPresignedUrl = async (imageName: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<
      GraphQLResponse<{ getPresignedUrl: string }>
    >(`${ENV_CONFIG.FOLLOW_UP_SERVICE_GRAPHQL_URL}`, {
      headers: {
        Authorization: token,
      },
      params: {
        query: `query { getPresignedUrl(imageName: "${imageName}") }`,
      },
    });

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.getPresignedUrl;
  } catch (error: any) {
    logError("Error occured. Cannot get presigned URL", error);
    throw error;
  }
};
