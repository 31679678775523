export const rosaceaSubQuestions = [
  {
    label: "How long have you been suffering from rosacea?",
    value: "rosaceaPeriod",
    questionType: "rosacea",
    options: [
      {
        value: "Last 6 months or less",
        label: "Last 6 months or less",
      },
      {
        value: "1-2 years",
        label: "1-2 years",
      },
      {
        value: "3+ years",
        label: "3+ years",
      },
    ],
  },
  {
    label: "Do you have a family history of rosacea?",
    value: "familyHistoryRosacea",
    questionType: "rosacea",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you suffer from:",
    value: "rosaceaSymptoms",
    questionType: "rosacea",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Red patches",
        label: "Red patches",
      },
      {
        value: "Red pimples",
        label: "Red pimples",
      },
      {
        value: "Pustules (pimples with pus)",
        label: "Pustules (pimples with pus)",
      },
      {
        value: "Cysts",
        label: "Cysts",
      },
      {
        value: "Thickened skin of nose",
        label: "Thickened skin of nose",
      },
      {
        value: "Dilated blood vessels",
        label: "Dilated blood vessels",
      },
    ],
  },
  {
    label: "What triggers your rosacea?",
    value: "rosaceaTriggers",
    questionType: "rosacea",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Alcohol",
        label: "Alcohol",
      },
      {
        value: "Stress",
        label: "Stress",
      },
      {
        value: "Spicy foods",
        label: "Spicy foods",
      },
      {
        value: "Hot drinks (hot temperature)",
        label: "Hot drinks (hot temperature)",
      },
      {
        value: "Caffeine",
        label: "Caffeine",
      },
      {
        value: "Exercise",
        label: "Exercise",
      },
      {
        value: "Sun",
        label: "Sun",
      },
      {
        value: "Wind",
        label: "Wind",
      },
      {
        value: "Hot showers/baths",
        label: "Hot showers/baths",
      },
    ],
  },
  {
    label: "What kind of products have you tried for rosacea in the past?",
    value: "previousRosaceaProductsTaken",
    questionType: "rosacea",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Over the counter products (e.g. cleansers or moisturizers)",
        label: "Over the counter products (e.g. cleansers or moisturizers)",
      },
      {
        value: "Prescription creams",
        label: "Prescription creams",
      },
      {
        value: "Prescription pills",
        label: "Prescription pills",
      },
      {
        value: "I haven’t tried any products",
        label: "I haven’t tried any products",
        onlySelectable: true,
      },
    ],
  },
  {
    label:
      "Which non-prescription products do you use on your skin (e.g. washes, cleansers, masks, creams, etc.)? Please include the brand and product name if you can.",
    value: "rosaceaNonPrescriptionProducts",
    questionType: "rosacea",
    placeholder: "Which products?",
    textArea: true,
    parentQuestion: "previousRosaceaProductsTaken",
    parentAnswer: "Over the counter products (e.g. cleansers or moisturizers)",
    isArrayOfAnswer: true,
    options: [
      {
        value:
          "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
        label:
          "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
      },
    ],
  },
  {
    label: "What prescription creams have you tried?",
    value: "rosaceaPrescriptionCreams",
    questionType: "rosacea",
    parentQuestion: "previousRosaceaProductsTaken",
    parentAnswer: "Prescription creams",
    isArrayOfAnswer: true,
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Metronidazole",
        label: "Metronidazole",
      },
      {
        value: "Clindamycin",
        label: "Clindamycin",
      },
      {
        value: "Ivermectin (Soolantra)",
        label: "Ivermectin (Soolantra)",
      },
      {
        value: "Sulfur",
        label: "Sulfur",
      },
      {
        value: "Mirvaso",
        label: "Mirvaso",
      },
      {
        value: "Rhofade",
        label: "Rhofade",
      },
    ],
  },
  {
    label: "What prescription pills have you tried?",
    value: "rosaceaPrescriptionPills",
    questionType: "rosacea",
    parentQuestion: "previousRosaceaProductsTaken",
    parentAnswer: "Prescription pills",
    isArrayOfAnswer: true,
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Doxycycline",
        label: "Doxycycline",
      },
      {
        value: "Minocycline",
        label: "Minocycline",
      },
      {
        value: "Ivermectin",
        label: "Ivermectin",
      },
      {
        value: "Isotretinoin",
        label: "Isotretinoin",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Are you currently using any prescription products for rosacea?",
    value: "rosaceaCurrentlyUsingPrescriptionProducts",
    questionType: "rosacea",
    parentQuestion: "previousRosaceaProductsTaken",
    parentAnswer: "I haven’t tried any products",
    radioButtonsForm: true,
    placeholder: "Which products?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are there specific medications you are interested in?",
    value: "rosaceaSpecificMedicationsInterest",
    questionType: "rosacea",
    radioButtonsForm: true,
    placeholder: "Which products?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Please list your medications:",
    value: "medications",
    questionType: "otherMedical",
    parentQuestion: "otherMedications",
    placeholder: "Which products?",
    parentAnswer: true,
    textArea: true,
    options: [
      {
        value:
          "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
        label:
          "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
      },
    ],
  },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "Which allergies?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Is there anything else we should know? (e.g. other medical conditions)",
    value: "otherInformation",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "e.g. other medical conditions",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
];
