import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Survey from "survey-react";
import {
  hideEditPatientInfoPopup,
  isShownSelector,
  typeSelector,
} from "../../../../features/editPatientInfo";
import "survey-react/survey.css";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close-icon.svg";
import {
  skinQuestions,
  otherConditionsQuestions,
  cycleAndMenstruationQuestions,
  acneQuestions,
  lifestyleQuestions,
  insuranceQuestions,
  acneTypeQuestions,
  eczemaTypeQuestions,
  // otherMedicalQuestions,
} from "./surveyModels";
import {
  skinDataAdapter,
  otherConditionsDataAdapter,
  cycleAndMenstruationDataAdapter,
  acneDataAdapter,
  lifestyleDataAdapter,
  acneTypeDataAdapter,
  eczemaTypeDataAdapter,
  // otherMedicalDataAdapter,
} from "./adapter";
import "./style.css";
import { logError } from "../../../../shared/logger";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import {
  useGetPatientByIdQuery,
  useUpdateSkinSurveyMutation,
} from "../../../../features/api/patients";
import { Loader } from "../../../NewComponents/Common/Loader";
import { MedicalBackgroundItem } from "../../../../types/Entities/Patient";
import { isEmpty } from "../../../../utils/is-empty";
import { NotificationLayerContext } from "../NotificationsLayout";

export function EditPatientInfoPopup() {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const isShown = useSelector(isShownSelector);
  const type = useSelector(typeSelector);
  const { data: patient, isSuccess } = useGetPatientByIdQuery(userId as string);
  const [updateSkinSurvey] = useUpdateSkinSurveyMutation();
  let surveyModel: Survey.SurveyModel | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let adapter: any;
  const skinSurveyQuestions = patient?.medicalBackground?.skinSurvey;
  const isSkinTypeExist =
    skinSurveyQuestions && skinSurveyQuestions[0]?.id === "skinIssueType";
  const skinType = isSkinTypeExist && skinSurveyQuestions[0]?.value;

  switch (type) {
    case !isSkinTypeExist && "skin":
      surveyModel = new Survey.Model(skinQuestions);
      adapter = skinDataAdapter;
      break;
    case "cycleAndMenstruation":
      surveyModel = new Survey.Model(
        cycleAndMenstruationQuestions(skinSurveyQuestions)
      );
      adapter = cycleAndMenstruationDataAdapter;
      break;
    case "otherConditions":
      surveyModel = new Survey.Model(
        otherConditionsQuestions(skinSurveyQuestions)
      );
      adapter = otherConditionsDataAdapter;
      break;
    // case isSkinTypeExist && "otherConditions":
    //   surveyModel = new Survey.Model(otherMedicalQuestions);
    //   adapter = otherMedicalDataAdapter;
    //   break;
    case skinType || "acne":
      surveyModel = new Survey.Model(acneQuestions(skinSurveyQuestions));
      adapter = acneDataAdapter;
      break;
    // case isSkinTypeExist && "acne":
    //   surveyModel = new Survey.Model(acneTypeQuestions);
    //   adapter = acneTypeDataAdapter;
    //   break;
    // case isSkinTypeExist && "eczema":
    //   surveyModel = new Survey.Model(eczemaTypeQuestions);
    //   adapter = eczemaTypeDataAdapter;
    //   break;
    case "lifestyle":
      surveyModel = new Survey.Model(lifestyleQuestions(skinSurveyQuestions));
      adapter = lifestyleDataAdapter;
      break;
    default:
      return null;
  }

  const handlePopupClose = () => {
    dispatch(hideEditPatientInfoPopup());
  };

  const submit = async (answers: Survey.SurveyModel) => {
    if (!patient) return;

    const userData: MedicalBackgroundItem[] = adapter.surveyToData(
      answers.data
    );

    const existingSurveyAnswers: MedicalBackgroundItem[] =
      patient.medicalBackground?.skinSurvey || [];
    const resultSkinSurvey: MedicalBackgroundItem[] = [];
    existingSurveyAnswers.forEach((it) => {
      const newAnswer = userData.find((data) => data.id === it.id);
      if (newAnswer) {
        if (!isEmpty(newAnswer.value)) {
          // Find the corresponding item in resultSkinSurvey
          const correspondingItem = existingSurveyAnswers.find(
            (item) => item.id === newAnswer.id
          );
          if (correspondingItem) {
            // Update the type property of newAnswer
            newAnswer.type = correspondingItem.type;
          }
          resultSkinSurvey.push(newAnswer);
        }
        return;
      }

      resultSkinSurvey.push(it);
    });

    userData.forEach((it) => {
      if (!existingSurveyAnswers.find(({ id }) => id === it.id))
        resultSkinSurvey.push(it);
    });
    const result: any = await updateSkinSurvey({
      patientId: patient.patientId,
      skinSurvey: resultSkinSurvey,
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Cannot update patient's medical background",
      });
      return;
    }

    showSuccess({
      title: "Success!",
      description: "Patient's medical background has been updated successfully",
    });
    dispatch(hideEditPatientInfoPopup());
  };

  const onPreviousAcneProductsTakenChanged = (sender, options) => {
    const { name, value } = options;
    const question = sender.getQuestionByName(name);

    if (value) {
      if (value.includes("I haven’t tried anything")) {
        // If "I haven’t tried anything" is selected, remove other values from the array
        const newValue = ["I haven’t tried anything"];
        question.value = newValue;
      } else if (value.includes("I haven’t tried any products")) {
        // If "I haven’t tried anything" is selected, remove other values from the array
        const newValue = ["I haven’t tried any products"];
        question.value = newValue;
      }
    }
  };

  return isShown ? (
    <div className="edit-patient-info-popup">
      <div className="edit-patient-info-popup__wrapper">
        {!isSuccess ? (
          <Loader />
        ) : (
          <>
            {" "}
            <div
              onClick={handlePopupClose}
              className="edit-patient-info-popup__close-container"
            >
              <CloseIcon className="edit-patient-info-popup__close-icon" />
            </div>
            <div className="edit-patient-info-popup__container">
              <Survey.Survey
                model={surveyModel}
                onComplete={submit}
                data={adapter.dataToSurvey(patient)}
                onValueChanged={onPreviousAcneProductsTakenChanged}
              />
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
}
