import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { EnrollmentCoordinator } from "../../../../../types/Entities/EnrollmentCoordinator";
import { AuthenticationService } from "../../../../cognito";

interface Response {
  employee: EnrollmentCoordinator;
  temporaryPassword: string;
}

export const create = async (data: EnrollmentCoordinator) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Response>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/enrollment-coordinators/create`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot create enrollment coordinator`);
    throw error;
  }
};
