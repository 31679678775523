import { TableItemValue } from "../types/Table/TableItemValue";

export function alphabetical(a: TableItemValue, b: TableItemValue) {
  const c = typeof a === "string" ? a.toLowerCase() : a;
  const d = typeof b === "string" ? b.toLowerCase() : b;
  if (c > d) return 1;
  if (c < d) return -1;
  return 0;
}

export function byDate(a: TableItemValue, b: TableItemValue) {
  return new Date(a).getTime() - new Date(b).getTime();
}
