/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";
import "./style.scss";

interface ButtonProps {
  text: string | JSX.Element;
  view?: "primary" | "secondary";
  size?: "big" | "small" | "extra-small";
  disabled?: boolean;
  className?: string;
  onClick?: () => Promise<void> | void;
  tooltip?: string;
}

enum states {
  IDLE,
  IN_PROGRESS,
  DISABLED,
}

export function Button({
  text,
  view = "primary",
  size = "big",
  onClick,
  disabled,
  className,
  tooltip,
}: ButtonProps) {
  const [state, setState] = useState<states>(
    disabled ? states.DISABLED : states.IDLE
  );

  useEffect(() => {
    setState(disabled ? states.DISABLED : states.IDLE);
  }, [disabled]);

  const click = async () => {
    if (!onClick) return;

    const promise = onClick();

    if (promise?.then) {
      setState(states.IN_PROGRESS);
      promise
        .then(
          () => setState(states.IDLE),
          () => setState(states.IDLE)
        )
        .catch(() => setState(states.IDLE));
    }
  };

  function getClassName() {
    const classes = [
      "custom-button",
      `custom-button--${view}`,
      `custom-button--${size}`,
      className || "",
    ];

    return classes.join(" ");
  }

  function renderLoader() {
    if (size === "big" && state === states.IN_PROGRESS) {
      return (
        <div className="loader-wrap">
          <Loader />
        </div>
      );
    }

    return null;
  }

  return (
    <button
      className={getClassName()}
      type="button"
      onClick={click}
      disabled={state === states.DISABLED || state === states.IN_PROGRESS}
      data-tooltip={tooltip}
    >
      {text}
      {renderLoader()}
    </button>
  );
}

interface SubmitButtonProps {
  text: string;
  view?: "primary" | "secondary";
  size?: "big" | "small";
  status?: "disabled" | "loading";
  className?: string;
  tooltip?: string;
}

export function SubmitButton({
  text,
  view = "primary",
  size = "big",
  status,
  className,
  tooltip,
}: SubmitButtonProps) {
  let state: states;

  switch (status) {
    case "disabled":
      state = states.DISABLED;
      break;
    case "loading":
      state = states.IN_PROGRESS;
      break;
    default:
      state = states.IDLE;
      break;
  }

  function getClassName() {
    const classes = [
      "custom-button",
      `custom-button--${view}`,
      `custom-button--${size}`,
      className || "",
    ];

    return classes.join(" ");
  }

  function renderLoader() {
    if (size === "big" && state === states.IN_PROGRESS) {
      return (
        <div className="loader-wrap">
          <Loader />
        </div>
      );
    }

    return null;
  }

  return (
    <button
      className={getClassName()}
      type="submit"
      disabled={state === states.DISABLED || state === states.IN_PROGRESS}
      data-tooltip={tooltip}
    >
      {text}
      {renderLoader()}
    </button>
  );
}
