import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient, PatientNote } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const addNote = async (userId: string, note: PatientNote) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/add-notes`,
      {
        id: userId,
        note,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get user data");
    throw error;
  }
};
