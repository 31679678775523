import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

interface EditIpledge {
  patientId: string;
  enrollmentDate: string;
  lastConfirmationDate: string;
  nextConfirmationDate: string;
  remsNumber: string;
}

export const editIpledge = async ({
  patientId,
  enrollmentDate,
  lastConfirmationDate,
  nextConfirmationDate,
  remsNumber,
}: EditIpledge): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/update-ipledge-details/${patientId}`,
      {
        enrollmentDate,
        lastConfirmationDate,
        nextConfirmationDate,
        remsNumber,
      }
    );

    return result.data.accutane;
  } catch (error) {
    logError("Error received when trying to update iPledge");
    throw error;
  }
};
