import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";

export const patientDashboardForEnrollmentCoordinatorOrderUpcomingVisits = [
  {
    filter: {
      bool: {
        must: [
          {
            match: {
              appointmentStatus: "PENDING",
            },
          },
        ],
      },
    },
    weight: 2,
  },
];

export const patientDashboardForEnrollmentCoordinatorOrderPostEnrollment = [
  {
    filter: {
      bool: {
        should: [
          {
            bool: {
              must_not: {
                exists: {
                  field: "flags.contacted",
                },
              },
            },
          },
          {
            match: {
              "flags.contacted": false,
            },
          },
        ],
      },
    },
    weight: 1,
  },
  // {
  //   filter: {
  //     bool: {
  //       must: {
  //         exists: {
  //           field: "dateOfService",
  //         },
  //       },
  //     },
  //   },
  //   script_score: {
  //     script: {
  //       source: "1 / (Math.log(doc['dateOfService'].value - ${APPLICATION_START_POINT_TIMESTAMP}L))",
  //     },
  //   },
  // },
];

export const patientDashboardForEnrollmentCoordinatorOrderDeclinedVisits = [
  {
    filter: {
      bool: {
        must: {
          exists: {
            field: "appointmentDate",
          },
        },
      },
    },
    weight: 2,
  },
];
