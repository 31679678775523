import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { v4 } from "uuid";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { Button } from "../../Common/Button";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import {
  CustomPopupTemplate,
  CustomPopupTemplateContext,
} from "../../Common/PopupLayer/custom";
import "./style.scss";
import { USER_ROLES } from "../../../../types/Main";
import { useGetEmployeesByRoleQuery } from "../../../../features/api/employees";
import {
  CareCoordinator,
  Employee,
  Provider,
} from "../../../../types/Employee";
import { getEmployeeFullName } from "../../../../utils/get-employee-full-name";
import { Skeleton } from "../../Common/Skeleton";
import {
  useGetCareTeamQuery,
  useUpdateCareTeamMutation,
} from "../../../../features/api/patients";
import { getFullType } from "../../../../utils/get-full-type";

interface PopupProps {
  type: USER_ROLES.PROVIDER | USER_ROLES.CARE_COORDINATOR;
  patientId: string;
}

interface Props {
  onClose: () => void;
}

function AssignEmployeePopup({ type, patientId }: PopupProps) {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const { onClose } = useContext(CustomPopupTemplateContext);
  const [updateCareTeam] = useUpdateCareTeamMutation();
  const careTeamQuery = useGetCareTeamQuery(patientId);
  const providersQuery = useGetEmployeesByRoleQuery(USER_ROLES.PROVIDER);
  const careCoordinatorsQuery = useGetEmployeesByRoleQuery(
    USER_ROLES.CARE_COORDINATOR
  );
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[] | null>(
    null
  );
  const employees = new Map<USER_ROLES, Employee[] | null>([
    [USER_ROLES.PROVIDER, providersQuery.data || null],
    [USER_ROLES.CARE_COORDINATOR, careCoordinatorsQuery.data || null],
  ]);

  useEffect(() => {
    if (
      !careTeamQuery.data ||
      !providersQuery.data ||
      !careCoordinatorsQuery.data
    )
      return;

    const _employees =
      type === USER_ROLES.PROVIDER
        ? providersQuery.data
        : careCoordinatorsQuery.data;
    setSelectedEmployees(
      _employees.filter(
        ({ id, role, archived }) =>
          !!careTeamQuery.data?.find(
            ({ employeeId, role: _role }) => id === employeeId && role === _role
          ) && !archived
      )
    );
  }, [careTeamQuery, providersQuery, careCoordinatorsQuery]);

  async function submit() {
    if (!selectedEmployees?.length) return;
    const result: any = await updateCareTeam({
      patientId,
      employees: selectedEmployees.map(({ id }) => id),
      role: type,
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to update care team",
      });
      return;
    }

    showSuccess({
      title: "Success!",
      description: `Care team has been updated successfully`,
    });
    onClose();
  }

  function generateSelectItems() {
    const employeesList = employees.get(type);
    if (!employeesList) return [];

    return employeesList
      ?.filter(({ archived }) => !archived)
      .map((_employee) => ({
        value: _employee,
        label: getEmployeeFullName(_employee),
      }))
      .sort((a, b) => a.value.firstName.localeCompare(b.value.firstName));
  }

  return (
    <div className="providers-report-picker">
      <div className="providers-report-picker__message-block">
        <p className="providers-report-picker__title">
          Assign a {getFullType(type)}
        </p>
        {providersQuery.data && careCoordinatorsQuery.data ? (
          <Select
            className="providers-report-picker__select"
            options={generateSelectItems()}
            isMulti
            value={selectedEmployees?.map((employee) => ({
              label: getEmployeeFullName(employee),
              value: employee,
            }))}
            onChange={(data) =>
              setSelectedEmployees(data.map(({ value }) => value))
            }
            id={v4()}
          />
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="providers-report-picker__actions">
        <Button
          text="cancel"
          view="secondary"
          onClick={onClose}
          className="providers-report-picker__action"
        />
        <Button
          text="assign"
          className="providers-report-picker__action"
          onClick={submit}
          disabled={!selectedEmployees?.length}
        />
      </div>
    </div>
  );
}

export function AssignEmployeePopupGenerator(props: PopupProps) {
  return function render({ onClose }: Props) {
    return (
      <CustomPopupTemplate onClose={onClose}>
        <AssignEmployeePopup {...props} />
      </CustomPopupTemplate>
    );
  };
}
