import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { CareCoordinator } from "../../../../../types/Entities/CareCoordinator";
import { AuthenticationService } from "../../../../cognito";

export const get = async (careCoordinatorId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<CareCoordinator>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/care-coordinators/get`,
      {
        params: {
          id: careCoordinatorId,
        },
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get care coordinator data");
    throw error;
  }
};
