import React, { useState } from "react";
import { ReactComponent as CaretIcon } from "../../../../Assets/NewIcons/caret-icon.svg";
import "./style.scss";

interface CollapseProps {
  title: string;
  openedByDefault?: boolean;
  children: JSX.Element;
  extra?: JSX.Element;
  showCollapseIcon?: boolean;
  isCollapsible: boolean;
}

function Collapse({
  title,
  openedByDefault = false,
  children,
  extra,
  showCollapseIcon,
  isCollapsible = true,
}: CollapseProps) {
  const [collapsed, setCollapsed] = useState(openedByDefault);

  const toggleCollapse = () => {
    if (isCollapsible) {
      setCollapsed(!collapsed);
    }
  };

  return (
    <div className="collapse">
      <div className="collapse__header">
        <p
          className={`collapse__header__label${collapsed ? "--opened" : ""}`}
          onClick={toggleCollapse}
        >
          <span className="collapse__header__title-wrapper">
            {showCollapseIcon && <CaretIcon />}{" "}
            <span className="collapse__header__title-wrapper__title">
              {title}
            </span>
          </span>
          {extra && extra}
        </p>
      </div>
      {collapsed && children}
    </div>
  );
}

export default Collapse;
