export const skinQuestions = {
  questions: [
    {
      type: "radiogroup",
      isRequired: true,
      name: "acnePeriod",
      title: "How long have you or your child had acne?",
      choices: [
        "I'm new to acne",
        "I've had acne for months",
        "I've had acne for years",
        "My child is new to acne",
        "My child has had acne for months",
        "My child has had acne for years",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "skinDryness",
      title: "Do you have dry skin?",
      choices: [
        "Very dry",
        "Often dry",
        "Combination",
        "Often oily",
        "Very oily",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "skin",
      title: "Do you have sensitive skin?",
      choices: ["Very sensitive", "Somewhat sensitive", "Not really"],
    },
  ],
};

export const otherConditionsQuestions = (existingQuestions) => {
  const baseQuestions = [
    {
      type: "radiogroup",
      name: "obesityOrHeartDisease",
      title: "Do you have a personal history of obesity or heart disease?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      name: "arthritisOrJointPain",
      title: "Do you have a personal history of arthritis/ joint pain?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      name: "strepThroatInfections",
      title:
        "Do you have a recent or chronic history of strep throat infections?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "isAnyPrescriptionAllergies",
      title: "Do you have any allergies to prescription medications?",
      choices: ["Yes", "No"],
    },
    {
      type: "text",
      isRequired: true,
      visibleIf: "{isAnyPrescriptionAllergies}='Yes'",
      name: "prescriptionAllergies",
      title: "Please describe your allergies:",
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "isAnyMedicalConditionPillsInUse",
      title:
        "Are you taking any other medications/pills for any other medical condition?",
      choices: ["Yes", "No"],
    },
    {
      type: "text",
      isRequired: true,
      visibleIf: "{isAnyMedicalConditionPillsInUse}='Yes'",
      name: "medications",
      title: "Please describe your medications:",
    },
    {
      type: "text",
      // isRequired: true,
      name: "otherMedications",
      title:
        "Are you taking any other medications/pills for any other medical condition?",
    },
    {
      type: "text",
      // isRequired: true,
      name: "allergiesToPrescriptionMedications",
      title: "Do you have any allergies to prescription medications?",
    },
    {
      type: "text",
      name: "anythingElse",
      title: "Is there anything else we should know",
    },
    {
      type: "radiogroup",
      name: "diabetesHistory",
      title: "Do you have a personal history of obesity or heart disease?",
      choices: ["Yes", "No"],
    },
    {
      type: "text",
      name: "otherInformation",
      title:
        "Is there anything else we should know? (e.g. other medical conditions)",
    },
  ];
  const filteredQuestions = baseQuestions.filter((baseQuestion) => {
    const matchingQuestion =
      existingQuestions &&
      existingQuestions.find((question) => question.id === baseQuestion.name);

    return matchingQuestion !== undefined;
  });
  return { questions: filteredQuestions };
};
// export const otherConditionsQuestions = {
//   questions: [
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "isAnyPrescriptionAllergies",
//       title: "Do you have any allergies to prescription medications?",
//       choices: ["Yes", "No"],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       visibleIf: "{isAnyPrescriptionAllergies}='Yes'",
//       name: "prescriptionAllergies",
//       title: "Please describe your allergies:",
//     },
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "isAnyMedicalConditionPillsInUse",
//       title:
//         "Are you taking any other medications/pills for any other medical condition?",
//       choices: ["Yes", "No"],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       visibleIf: "{isAnyMedicalConditionPillsInUse}='Yes'",
//       name: "medications",
//       title: "Please describe your medications:",
//     },
//   ],
// };

// export const otherMedicalQuestions = {
//   questions: [
//     {
//       type: "text",
//       // isRequired: true,
//       name: "otherMedications",
//       title: "Are you taking any other medications/pills for any other medical condition?",
//     },
//     {
//       type: "text",
//       // isRequired: true,
//       name: "allergiesToPrescriptionMedications",
//       title: "Do you have any allergies to prescription medications?",
//     },
//   ],
// };
export const acneQuestions = (existingQuestions) => {
  const baseQuestions = [
    {
      type: "radiogroup",
      isRequired: true,
      name: "acnePeriod",
      title: "How long have you had acne?",
      choices: [
        "I’m new to acne",
        "I’ve had acne for months",
        "I’ve had acne for years",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "skinDryness",
      title: "Do you have dry skin?",
      choices: [
        "Very dry",
        "Often dry",
        "Combination",
        "Often oily",
        "Very oily",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "skinSensitivity",
      title: "Do you have sensitive skin?",
      choices: ["Very sensitive", "Somewhat sensitive", "Not really"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "previousAcneProductsTaken",
      title: "What kind of products have you tried for acne in the past?",
      choices: [
        "Cleansers or moisturizers",
        "Over the counter creams",
        "Vitamins for acne",
        "Prescription creams",
        "Prescription pills",
        "I haven’t tried any products",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "specificMedicationsInterest",
      title: "Are there specific medications you are interested in?",
      visibleIf:
        "{previousAcneProductsTaken} contains 'I haven’t tried any products'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "prescriptionTopicalsUsed",
      visibleIf: "{previousAcneProductsTaken} contains 'Prescription creams'",
      title: "Which prescription creams and cleansers have you used?",
      choices: [
        "Benzoyl Peroxide cleanser (Benzac)",
        "Dapsone (Aczone)",
        "Azelaic Acid (Finacea, Azelex)",
        "Sodium Sulfacetamide / Sulfur Cleanser / Creams (Avar, Sulfacet R, Sumaxan)",
        "Clindamycin (Cleocin T, Clindagel)",
        "Erythromycin",
        "Tretinoin (Retin-A)",
        "Clindamycin / Benzoyl Peroxide combo (Benzaclin, Acanya, Duac, Onexton)",
        "Benzamycin (Benzoyl Peroxide and Erythromycin combo)",
        "Tazarotene (Tazorac)",
        "Clindamycin/Tretinoin combo (Ziana)",
        "Amzeeq (topical Minocycline)",
        "Adapalene (Differin, Epiduo, Epiduo Forte)",
        "Sulfacetamide (Klaron, Ovace)",
        "Aklief (Trifarotene)",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "acnePillsTaken",
      visibleIf: "{previousAcneProductsTaken} contains 'Prescription pills'",
      title: "Please select all the pills you have ever taken for acne.",
      choices: [
        "Doxycycline (Doryx, Oracea, Monodox, Acticlate)",
        "Minocycline (Solodyn, Minocin, Dynacin, Ximino)",
        "Tetracycline",
        "Trimethoprim / Sulfamethoxazole (Bactrim)",
        "Isotretinoin (Accutane, Claravis, Amnesteem, Absorica)",
        "Spironolactone",
        "Amoxicillin (Augmentin)",
        "Cefadroxil (Duricef)",
        "Cefuroxime (Ceftin)",
        "Erythromycin",
        "Cefuroxime (Ceftin)",
        "Erythromycin",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      visibleIf:
        "{previousAcneProductsTaken} contains 'Prescription creams' || {previousAcneProductsTaken} contains 'Prescription pills'",
      name: "isAnyPrescriptionsInUse",
      title: "Are you currently using any prescriptions for acne?",
      choices: ["Yes", "No"],
    },
    {
      type: "text",
      isRequired: true,
      visibleIf: "{isAnyPrescriptionsInUse}='Yes'",
      name: "currentPrescriptions",
      title: "Which prescriptions?",
    },
    {
      type: "text",
      isRequired: true,
      visibleIf:
        "{previousAcneProductsTaken} contains 'Over the counter creams'",
      name: "currentNonPrescriptions",
      title:
        "Which non-prescription products do you use on your skin (e.g. washes, cleansers, masks, creams, etc.)? Please include the brand and product name if you can.",
    },
    {
      type: "text",
      isRequired: true,
      name: "interestingMedications",
      title: "Are there any specific medications you are interested in?",
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "periodEczma",
      title: "How long have you had eczema?",
      choices: [
        "I’m new to eczema",
        "I’ve had eczema for months",
        "I’ve had eczema for years",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "familyHistory",
      title: "Do you have a family history of eczema?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "haveAsthma",
      title: "Do you have asthma?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "seasonalAllergies",
      title: "Do you have seasonal allergies?",
      choices: ["Yes", "No", "Unknown"],
    },
    {
      type: "text",
      isRequired: true,
      name: "nonEczemaPrescriptionProducts",
      title:
        "How would you describe the amount of eczema on your skin from 1 - 10",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "locationofEczma",
      title: "Where is your eczema typically located?",
      choices: [
        "Inner elbows",
        "Behind the knees",
        "Back of neck",
        "Eyelids",
        "Palms",
        "All over the body",
        "Other",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "eczemaLocatedText",
      visibleIf: "{locationofEczma} contains 'Other'",
      title: "Where is your eczema typically located?",
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "crackorBleed",
      visibleIf: "{locationofEczma} contains 'Palms'",
      title: "Do your hands crack or bleed?",
      choices: ["Yes", "No"],
    },
    {
      type: "text",
      isRequired: true,
      name: "itchyChildSkin",
      title: "On a scale of 1-10, how itchy is your skin?",
    },
    {
      type: "text",
      isRequired: true,
      name: "worseduringSeason",
      title: "Is your eczema worse during particular seasons?",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "otcCreams",
      title: "What over-the-counter (non-prescription) creams have you used?",
      choices: [
        "Moisturizers",
        "Cleansers",
        "Cortisone Creams",
        "Oatmeal Baths",
        "None",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "locationofMoisturizers",
      visibleIf: "{otcCreams} contains 'Moisturizers'",
      title: "What over-the-counter moisturizers have you used?",
      choices: [
        "Cetaphil",
        "Cerave",
        "Aquaphor",
        "Eucerin",
        "Vaseline",
        "Vanicream",
        "Other",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "moisturizersLocationText",
      title: "Please enter moisturizers location",
      visibleIf: "{locationofMoisturizers} contains 'Other'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "locationofCleansers",
      title: "What over-the-counter cleansers have you used?",
      visibleIf: "{otcCreams} contains 'Cleansers'",
      choices: ["Cetaphil", "Cerave", "Dove", "Other"],
    },
    {
      type: "text",
      isRequired: true,
      name: "cleansersLocationText",
      title: "Please enter cleansers location",
      visibleIf: "{locationofCleansers} contains 'Other'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "octPills",
      title: "What over-the-counter (non-prescription) pills have you used?",
      choices: ["Vitamins", "Antihistamines", "None"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "antihistaminesPills",
      title: "What over-the-counter antihistamines have you used?",
      visibleIf: "{otcCreams} contains 'Antihistamines'",
      choices: ["Benadryl", "Zyrtec", "Claritin", "Allegra", "Xyzal", "Other"],
    },
    {
      type: "text",
      isRequired: true,
      name: "cleansersLocationText",
      title: "Please enter antihistamines have you used?",
      visibleIf: "{antihistaminesPills} contains 'Other'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "creamPrecriptions",
      title: "What prescription creams have you used ?",
      choices: [
        "Hydrocortisone",
        "Triamcinolone",
        "Alclometasone",
        "Desonide",
        "Tacrolimus ( Protopic)",
        "Pimecrolimus ( Elidel)",
        "Mometasone",
        "Fluocinonide",
        "Halobetasol",
        "Clobetasol",
        "Opzelura",
        "None",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "prescriptionPills",
      title: "What prescription pills have you used?",
      visibleIf: "{otcCreams} contains 'Cortisone Creams'",
      choices: [
        "Hydroxyzine",
        "Prednisone",
        "Rinvoq",
        "Cibinqo",
        "Antibiotics",
        "None",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "injectables",
      title: "What prescription injectables have you used?",
      visibleIf: "{otcCreams} contains 'Oatmeal baths, creams'",
      choices: ["Dupixent", "Adbry", "None"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "hairLossNoticeTimeframe",
      title: "When did you first notice your hair loss?",
      choices: [
        "Within the last few months",
        "Within the last few years",
        "Over 5 years ago",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "firsthairLoss",
      title:
        "If so, did you experience a major physical or emotional stressor within the last 6 months ?",
      choices: ["Yes", "No"],
      visibleIf: "{hairLossNoticeTimeframe}='Within the last few months'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "hairLossPattern",
      title: "What pattern of hair loss best describes your condition?",
      choices: [
        "Top of scalp (the crown or vertex)",
        "Frontal hairline receding",
        "Frontal scalp (from hairline to top of scalp)",
        "Patches of hair loss in circles",
        "Generalized hair loss all over the scalp",
        "Beard area",
        "Eyebrows",
        "Eyelashes",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "flakiness",
      title: "Is your hair loss associated with itch or dry flakiness?",
      choices: ["Yes", "No"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "hairLossTry",
      title: "What have you tried for hair loss in the past?",
      choices: [
        "Over-the-counter products (e.g. biotin, rogaine, at-home devices etc.)",
        "Prescription medications",
        "Procedures at a doctor’s office",
        "I haven’t tried anything",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "hairLossSpecificMedicationsInterest",
      title: "Are there specific medications you are interested in?",
      visibleIf: "{hairLossTry} contains 'I haven’t tried any products'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "overTheCounterTreatments",
      title: "What over-the-counter treatments have you tried?",
      choices: [
        "Oral Supplements",
        "Topicals (Creams/Shampoos/Lotions)",
        "LED light therapy/LASER device",
        "Other",
      ],
      visibleIf:
        "{hairLossTry} contains 'Over-the-counter products (e.g. biotin, rogaine, at-home devices etc.)'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "overTheCounterTreatmentsfollow",
      title: "Which oral supplements have you tried?",
      choices: ["Biotin", "Nutrafol", "Other"],
      visibleIf: "{overTheCounterTreatments} contains 'Oral Supplements'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "overTheCounterTreatmentsfollow",
      title: "Please select topicals",
      choices: ["Minoxidil ( Rogaine)", "Other"],
      visibleIf:
        "{overTheCounterTreatments} contains 'Topicals (Creams/Shampoos/Lotions)'",
    },
    {
      type: "text",
      isRequired: true,
      name: "minoxidilPercentage",
      title: "what percentage concentration?",
      visibleIf:
        "{overTheCounterTreatmentsfollowup} contains 'Minoxidil ( Rogaine)'",
      placeHolder: "Percentage concentration",
    },
    {
      type: "text",
      isRequired: true,
      name: "otherTopicals",
      title: "Please select Other topicals",
      visibleIf: "{overTheCounterTreatmentsfollowup} contains 'Other'",
      placeHolder: "Other topicals",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "prescriptionMedications",
      title: "What prescription medications have you tried?",
      choices: [
        "Finasteride (Propecia)",
        "Low dose oral Minoxidil",
        "Opzelura cream - topical JAK inhibitor",
        "Olumiant - Oral JAK inhibitor",
        "Topical steroids",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "prescriptionMedicationsfollow",
      title: "Please describe dose of Minoxidil",
      visibleIf: "{prescriptionMedications} contains 'Low dose oral Minoxidil'",
      placeHolder: "If so, what dose ?",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "officeProcedures",
      title: "What procedures have you tried at a provider’s office?",
      choices: [
        "PRP (Platelet-rich Plasma) injections",
        "Steroid injections",
        "Hair Transplant",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "hsDiagnosed",
      title:
        "Have you been diagnosed with Hidradenitis Suppurativa (HS) previously?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "hsSymptomsDuration",
      title:
        "How long have you been experiencing symptoms of Hidradenitis Suppurativa?",
      choices: [
        "I just started experiencing symptoms",
        "For months",
        "For years",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "hsTreatments",
      title: "What have you tried for Hidradenitis Suppurativa in the past?",
      choices: [
        "Over-the-counter products (e.g. warm compresses, hydrocortisone, tylenol etc.)",
        "Prescription medications",
        "Procedures / surgical interventions",
        "I haven’t tried anything",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "hsOtcMedications",
      title:
        "Which over-the-counter products have you taken for Hidradenitis Suppurativa?",
      visibleIf:
        "{hsTreatments} contains 'Over-the-counter products (e.g. warm compresses, hydrocortisone, tylenol etc.)'",
      choices: [
        "Warm compresses",
        "Cleansers (benzoyl peroxide / antibacterials)",
        "Topical antibacterial cream/ointment (neosporin, polysporin, hydrogen peroxide)",
        "Steroid Cream (hydrocortisone)",
        "Wound care products",
        "Pain relievers (Motrin or Tylenol)",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "hsPrescriptionMedications",
      title:
        "Which prescription medications have you taken for Hidradenitis Suppurativa?",
      visibleIf: "{hsTreatments} contains 'Prescription medications'",
      choices: [
        "Topical antibiotics (e.g. clindamycin)",
        "Oral antibiotics (e.g. doxycycline)",
        "Biologics (e.g. humira)",
        "Isotretinoin (Accutane)",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "hsSurgicalInterventions",
      visibleIf:
        "{hsTreatments} contains 'Procedures / surgical interventions'",
      title:
        "Have you had any surgical interventions for Hidradenitis Suppurativa?",
      choices: ["Yes", "No"],
    },
    {
      type: "text",
      isRequired: true,
      name: "specificInterestedMedics",
      title: "Are there specific medications you are interested in?",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "symptomsSeverity",
      title: "Please check if you experience any of the following symptoms",
      choices: [
        "Pain or discomfort",
        "Inflammation or swelling",
        "Presence of nodules or abscesses",
        "Drainage of pus or foul-smelling fluid",
        "Scarring or skin discoloration",
        "Itching or burning sensation",
        "Restricted movement or difficulty performing daily activities",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "hsTriggers",
      title:
        "Are there any specific triggers or factors that worsen your Hidradenitis Suppurativa symptoms?",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "hsImpactOnLifestyle",
      title: "Please check if you experience any of the following",
      choices: [
        "My skin condition affects my personal relationships",
        "My skin condition affects my ability to work effectively",
        "I give up certain activities because of my skin condition",
        "My condition affects my sleep",
        "I feel alone or isolated because of my skin conditions",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "hsFamilyHistory",
      title: "Do you have a family history of Hidradenitis Suppurativa",
      choices: ["Yes", "No", "Not Sure"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "affectedNails",
      title: "Which nails are affected?",
      choices: ["Toenails", "Fingernails", "Both"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "knownHistory",
      title: "Do you have a known history of",
      choices: [
        "Trauma to the nail",
        "Psoriasis",
        "Lichen Planus",
        "Inflamed skin around the nail (Paronychia)",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "frequency",
      title: "Do you get frequent pedicures/manicures?",
      choices: ["Yes", "No"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "previousProductsforNailsTaken",
      title:
        "What kind of products have you tried for nail fungus in the past?",
      choices: [
        "Over-the-counter products",
        "Prescription medications",
        "Office-based treatments",
        "I haven’t tried any products",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "overTheCounterMeds",
      title: "What over the counter medications have you tried in the past?",
      visibleIf:
        "{previousProductsforNailsTaken} contains 'Prescription medications'",
      choices: ["Biotin", "Other Supplements", "Topical nail Solutions"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "prescriptionMeds",
      title: "What prescription medications have you tried in the past?",
      visibleIf:
        "{previousProductsforNailsTaken} contains 'Prescription medications'",
      choices: [
        "Penlac (Ciclopirox) Nail Solution",
        "Jublia (Efinaconazole) Nail Solution",
        "Kerydin (Tavaborole) Nail Solution",
        "Oral Lamisil (Terbinafine)",
        "Oral Sporanox (Itraconazole)",
        "Other",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "officeTreatments",
      title: "What office-based treatments have you tried?",
      visibleIf:
        "{previousProductsforNailsTaken} contains 'Office-based treatments'",
      choices: ["Nail Surgery", "LASER", "Steroid Injections"],
    },
    {
      type: "text",
      isRequired: true,
      name: "nailsSpecificMedicationsInterest",
      title: "Are there specific medications you are interested in?",
      visibleIf:
        "{previousProductsforNailsTaken} contains 'I haven’t tried any products'",
      // choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "psoriasisPeriod",
      title: "How long have you suffered from psoriasis?",
      choices: ["Less than a year", "1-5 years", "5+ years"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "skinBiopsy",
      title: "Did you ever have a skin biopsy of the psoriasis done?",
      choices: ["Yes", "No"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "psoriasisAreas",
      title: "What areas of your body are covered with psoriasis?",
      choices: [
        "Trunk",
        "Arms",
        "Legs",
        "Scalp",
        "Ears",
        "Hands",
        "Feet",
        "Nails",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "psoriasisHands",
      title: "What areas of your hands are covered with Psoriasis?",
      visibleIf: "{psoriasisAreas}='Hands'",
      choices: ["Palms", "Back of hands"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "psoriasisFeet",
      title: "What areas of your feet are covered with Psoriasis?",
      visibleIf: "{psoriasisAreas}='Feet'",
      choices: ["Soles", "Top of feet"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "itching",
      title: "Does your skin itch?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "psoriasisFamilyHistory",
      title: "Do you have a family history of Psoriasis?",
      choices: ["Yes", "No"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "previousPsoriasisProductsTaken",
      title: "What kind of products have you tried for psoriasis in the past?",
      choices: [
        "Over-the-counter products (e.g. cleansers or moisturizers)",
        "Prescription creams",
        "Prescription pills",
        "Prescription injectables",
        "I haven’t tried any products",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "psoriasisSpecificMedicationsInterest",
      title: "Are there specific medications you are interested in?",
      visibleIf:
        "{previousPsoriasisProductsTaken} contains 'I haven’t tried any products'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "otcTopicalMedications",
      title: "What over the counter topical medications have you tried?",
      visibleIf:
        "{previousPsoriasisProductsTaken} contains 'I haven’t tried any products'",
      choices: [
        "Moisturizers",
        "Hydrocortisone creams",
        "Vitamins",
        "Supplements",
        "Antihistamines (for itch)",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "psoriasisPrescriptionCreams",
      title: "What prescription creams have you tried?",
      visibleIf:
        "{previousPsoriasisProductsTaken} contains 'Prescription creams'",
      choices: [
        "Steroids (Halobetasol, Clobetasol, Triamcinolone, Mometasone, Desonide, Aclometasone, Hydrocortisone)",
        "Other steroid",
        "Tacrolimus, Pimecrolimus, Zoryve, Vtama, Duobrii, Tazorac, Taclonex, Vit D analogues/ Calcipotriene ( Dovonex), anthralin, coal tar",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "psoriasisPrescriptionOralMedications",
      title: "What prescription oral medications have you tried?",
      visibleIf:
        "{previousPsoriasisProductsTaken} contains 'Prescription pills'",
      choices: [
        "Methotrexate",
        "Cyclosporine",
        "Otezla",
        "Sotyktu",
        "Acitretin",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "psoriasisPrescriptionInjectables",
      title: "What prescription injectables have you tried?",
      visibleIf:
        "{previousPsoriasisProductsTaken} contains 'Prescription injectables'",
      choices: [
        "Enbrel",
        "Humira",
        "Stelara",
        "Tremfya",
        "Cosentyx",
        "Skyrizi",
        "Ilumya",
        "Cimzia",
        "Siliq",
        "Taltz",
        "Simponi",
        "Remicade",
        "Orencia",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "psoriasisCurrentlyUsingPrescriptionProducts",
      title: "Are you currently using any prescription products for psoriasis?",
      visibleIf:
        "{previousPsoriasisProductsTaken} contains 'I haven’t tried any products'",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "rosaceaPeriod",
      title: "How long have you been suffering from rosacea?",
      choices: ["Last 6 months or less", "1-2 years", "3+ years"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "familyHistoryRosacea",
      title: "Do you have a family history of rosacea?",
      choices: ["Yes", "No"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "rosaceaSymptoms",
      title: "Do you suffer from:",
      choices: [
        "Red patches",
        "Red pimples",
        "Pustules (pimples with pus)",
        "Cysts",
        "Thickened skin of nose",
        "Dilated blood vessels",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "rosaceaTriggers",
      title: "What triggers your rosacea?",
      choices: [
        "Alcohol",
        "Stress",
        "Spicy foods",
        "Hot drinks (hot temperature)",
        "Caffeine",
        "Exercise",
        "Sun",
        "Wind",
        "Hot showers/baths",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "previousRosaceaProductsTaken",
      title: "What kind of products have you tried for rosacea in the past?",
      visibleIf:
        "{previousRosaceaProductsTaken} contains 'I haven’t tried any products'",
      choices: [
        "Over-the-counter products (e.g. cleansers or moisturizers)",
        "Prescription creams",
        "Prescription pills",
        "I haven’t tried any products",
      ],
    },
    {
      type: "text",
      isRequired: true,
      name: "rosaceaNonPrescriptionProducts",
      title:
        "Which non-prescription products do you use on your skin (e.g. washes, cleansers, masks, creams, etc.)?",
      placeholder: "Which products?",
      visibleIf:
        "{previousRosaceaProductsTaken} contains 'Over-the-counter products (e.g. cleansers or moisturizers)'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "rosaceaPrescriptionCreams",
      title: "What prescription creams have you tried?",
      visibleIf:
        "{previousRosaceaProductsTaken} contains 'Prescription creams'",
      choices: [
        "Metronidazole",
        "Clindamycin",
        "Ivermectin (Soolantra)",
        "Sulfur",
        "Mirvaso",
        "Rhofade",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "rosaceaPrescriptionPills",
      title: "What prescription pills have you tried?",
      visibleIf: "{previousRosaceaProductsTaken} contains 'Prescription pills'",
      choices: [
        "Doxycycline",
        "Minocycline",
        "Ivermectin",
        "Isotretinoin",
        "Other",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "currentlyUsingPrescriptionProducts",
      title: "Are you currently using any prescription products for rosacea?",
      visibleIf:
        "{previousRosaceaProductsTaken} contains 'I haven’t tried any products'",
      choices: ["Yes", "No"],
    },
    {
      type: "text",
      isRequired: true,
      name: "rosaceaCurrentlyUsingPrescriptionProducts",
      title: "Are you currently using any prescription products for rosacea?",
      visibleIf:
        "{previousRosaceaProductsTaken} contains 'I haven’t tried any products'",
    },
    {
      type: "text",
      isRequired: true,
      name: "rosaceaSpecificMedicationsInterest",
      title: "Are there specific medications you are interested in?",
      visibleIf:
        "{previousRosaceaProductsTaken} contains 'I haven’t tried any products'",
    },

    {
      type: "text",
      isRequired: true,
      name: "currentMedication",
      title:
        "Are you currently using any medications or treatment to treat your condition?",
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "tineaVersicolorPeriod",
      title: "How long have you suffered from Tinea Versicolor?",
      choices: ["A few months", "1-2 years", "3+ years"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "tineaVersicolorAreas",
      title: "What areas of your body are covered with spots?",
      choices: ["Chest", "Abdomen", "Back", "Neck", "Face", "Arms"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "tineaVersicolorSeasonal",
      title:
        "Do your spots come out seasonally (Spring/Summer) or do they appear year-round?",
      choices: ["Seasonally", "Year-round"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "previousTineaVersicolorProductsTaken",
      title:
        "What kind of products have you tried for Tinea Versicolor in the past?",
      choices: [
        "Over the counter products (e.g. cleansers or moisturizers)",
        "Prescription creams",
        "Prescription pills",
        "I haven’t tried any products",
      ],
    },
    {
      type: "text",
      name: "tineaVersicolorCurrentlyUsingPrescriptionProducts",
      title:
        "Are you currently using any prescription products for Tinea Versicolor?",
      visibleIf:
        "{previousTineaVersicolorProductsTaken} contains 'I haven’t tried any products'",
    },
    {
      type: "text",
      name: "tineaVersicolorSpecificMedicationsInterest",
      title: "Are there specific medications you are interested in?",
      visibleIf:
        "{previousTineaVersicolorProductsTaken} contains 'I haven’t tried any products'",
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "tineaVersicolorOtcCreams",
      title: "What over the counter creams have you tried?",
      visibleIf:
        "{previousTineaVersicolorProductsTaken} contains 'Over the counter products (e.g. cleansers or moisturizers)'",
      choices: ["Selsun blue", "Head and Shoulders", "Lamisil cream", "Other"],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "tineaVersicolorPrescriptionCreams",
      title: "What prescription creams have you tried?",
      visibleIf:
        "{previousTineaVersicolorProductsTaken} contains 'Prescription creams'",
      choices: [
        "Ketaconazole cream",
        "Oxiconazole cream",
        "Econazole cream",
        "Ketaconazole Shampoo",
        "Ciclopirox Shampoo",
        "Other",
      ],
    },
    {
      type: "checkbox",
      isRequired: true,
      name: "tineaVersicolorPrescriptionOrals",
      title: "What prescription orals have you tried?",
      visibleIf:
        "{previousTineaVersicolorProductsTaken} contains 'Prescription pills'",
      choices: ["Itraconazole (Sporanox)", "Lamisil", "Diflucan", "Other"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "currentlyUsingPrescriptionProducts",
      title:
        "Are you currently using any prescription products for Tinea Versicolor?",
      visibleIf:
        "{previousTineaVersicolorProductsTaken} contains 'I haven’t tried any products'",
      choices: ["Yes", "No"],
    },
  ];
  const filteredQuestions = baseQuestions.filter((baseQuestion) => {
    const matchingQuestion =
      existingQuestions &&
      existingQuestions.find((question) => question.id === baseQuestion.name);

    return matchingQuestion !== undefined;
  });
  return { questions: filteredQuestions };
};

export const cycleAndMenstruationQuestions = (existingQuestions) => {
  const baseQuestions = [
    {
      type: "radiogroup",
      isRequired: true,
      name: "pregnancy",
      title: "Are you pregnant?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "breastfeeding",
      title: "Are you currently breastfeeding?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "menstruationBreakout",
      title:
        "If you menstruate, do you break out throughout the month or specifically during/leading up to your menstrual cycle?",
      choices: [
        "Yes",
        "No, I breakout throughout the month",
        "I don’t menstruate",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "isRegularMenstruationCycle",
      visibleIf:
        "{menstruationBreakout}='Throughout the month' || {menstruationBreakout}='Premenstrual/during menstruation'",
      title: "Is your menstrual cycle regular",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "isBirthControlTaken",
      title: "Do you take birth control?",
      choices: ["Yes", "No"],
    },
    {
      isRequired: true,
      type: "radiogroup",
      name: "birthControlMeds",
      visibleIf: "{isBirthControlTaken}='Yes'",
      title: "Which birth control/hormonal meds are you taking?",
      choices: [
        "Yaz",
        "Ortho Tri-cyclen",
        "Beyaz",
        "Estrostep",
        "Loryna",
        "Yasmin",
        "Alesse",
        "Other",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "isPcos",
      title: "Do you have a history of PCOS (Polycystic Ovarian Syndrome)?",
      choices: ["Yes", "No"],
    },
  ];

  const filteredQuestions = baseQuestions.filter((baseQuestion) => {
    const matchingQuestion =
      existingQuestions &&
      existingQuestions.find((question) => question.id === baseQuestion.name);

    return matchingQuestion !== undefined;
  });
  return { questions: filteredQuestions };
};

export const lifestyleQuestions = (existingQuestions) => {
  const baseQuestions = [
    {
      type: "radiogroup",
      isRequired: true,
      name: "longShowers",
      title: "Do you take long hot showers?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "stressLevel",
      title: "In the last month, how would you describe your stress level?",
      choices: [
        "Never stressed",
        "Almost never stressed",
        "Sometimes stressed",
        "Fairly stressed",
        "Very stressed",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "sleepAmount",
      title: "In the last month, how would you describe your sleep?",
      choices: ["5 hours or less", "Between 6-7 hours", "8+ hours"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "dairyConsumption",
      title:
        "How often do you consume dairy (e.g. cheeses, yogurts, milk, butter)?",
      choices: [
        "Never",
        "A few times a month",
        "A few times a week",
        "A few times a day",
      ],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "eczemaSleepImpact",
      title: "Has your eczema affected how much sleep you are getting?",
      choices: ["Yes", "No"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "eczemaSleepPeriod",
      title: "Has your eczema affected how much sleep you are getting?",
      visibleIf: "{eczemaSleepImpact}='Yes'",
      choices: ["Only 3-4 hours or less", "Only 5-7 hours", "8-9 hours"],
    },
    {
      type: "radiogroup",
      isRequired: true,
      name: "smokeCigarettes",
      title: "Do you smoke tobacco cigarettes?",
      choices: ["Yes", "No"],
    },
  ];

  const filteredQuestions = baseQuestions.filter((baseQuestion) => {
    const matchingQuestion =
      existingQuestions &&
      existingQuestions.find((question) => question.id === baseQuestion.name);

    return matchingQuestion !== undefined;
  });
  return { questions: filteredQuestions };
};

export const insuranceQuestions = {
  questions: [
    {
      type: "text",
      isRequired: true,
      name: "memberId",
      title: "Member ID",
    },
    {
      type: "text",
      isRequired: true,
      name: "insuranceName",
      title: "Insurance Name",
    },
    {
      type: "text",
      isRequired: true,
      name: "policyHolderName",
      title: "Policy Holder Name",
    },
    {
      type: "text",
      isRequired: true,
      name: "groupNumber",
      title: "Group Number",
    },
    {
      type: "text",
      isRequired: true,
      name: "rxBinNumber",
      title: "RxBin number",
    },
  ],
};

// export const acneTypeQuestions = {
//   questions: [
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "acnePeriod",
//       title: "How long have you had acne?",
//       choices: [
//         "I’m new to acne",
//         "I’ve had acne for months",
//         "I’ve had acne for years",
//       ],
//     },
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "skinDryness",
//       title: "Do you have dry skin?",
//       choices: [
//         "Very dry",
//         "Often dry",
//         "Combination",
//         "Often oily",
//         "Very oily",
//       ],
//     },
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "skinSensitivity",
//       title: "Do you have sensitive skin?",
//       choices: ["Very sensitive", "Somewhat sensitive", "Not really"],
//     },
// {
//   type: "checkbox",
//   isRequired: true,
//   name: "previousAcneProductsTaken",
//   title: "What kind of products have you tried for acne in the past?",
//   choices: [
//     "Cleansers or moisturizers",
//     "Over the counter creams",
//     "Vitamins for acne",
//     "Prescription creams",
//     "Prescription pills",
//     "I haven't tried any products",
//   ],
// },
// {
//   type: "checkbox",
//   isRequired: true,
//   name: "prescriptionCreams",
//   visibleIf: "{previousAcneProductsTaken} contains 'Prescription creams'",
//   title: "Which prescription creams and cleansers have you used?",
//   choices: [
//     "Benzoyl Peroxide cleanser (Benzac)",
//     "Dapsone (Aczone)",
//     "Azelaic Acid (Finacea, Azelex)",
//     "Sodium Sulfacetamide / Sulfur Cleanser / Creams (Avar, Sulfacet R, Sumaxan)",
//     "Clindamycin (Cleocin T, Clindagel)",
//     "Erythromycin",
//     "Tretinoin (Retin-A)",
//     "Clindamycin / Benzoyl Peroxide combo (Benzaclin, Acanya, Duac, Onexton)",
//     "Benzamycin (Benzoyl Peroxide and Erythromycin combo)",
//     "Tazarotene (Tazorac)",
//     "Clindamycin/Tretinoin combo (Ziana)",
//     "Amzeeq (topical Minocycline)",
//     "Adapalene (Differin, Epiduo, Epiduo Forte)",
//     "Sulfacetamide (Klaron, Ovace)",
//     "Aklief (Trifarotene)",
//   ],
// },
// {
//   type: "checkbox",
//   isRequired: true,
//   name: "prescriptionPills",
//   visibleIf: "{previousAcneProductsTaken} contains 'Prescription pills'",
//   title: "Please select all the pills you have ever taken for acne.",
//   choices: [
//     "Doxycycline (Doryx, Oracea, Monodox, Acticlate)",
//     "Minocycline (Solodyn, Minocin, Dynacin, Ximino)",
//     "Tetracycline",
//     "Trimethoprim / Sulfamethoxazole (Bactrim)",
//     "Isotretinoin (Accutane, Claravis, Amnesteem, Absorica)",
//     "Spironolactone",
//     "Amoxicillin (Augmentin)",
//     "Cefadroxil (Duricef)",
//     "Cefuroxime (Ceftin)",
//     "Erythromycin",
//     "Cefuroxime (Ceftin)",
//     "Erythromycin",
//   ],
// },
// {
//   type: "radiogroup",
//   isRequired: true,
//   visibleIf:
//     "{previousAcneProductsTaken} contains 'Prescription creams' || {previousAcneProductsTaken} contains 'Prescription pills'",
//   name: "isAnyPrescriptionsInUse",
//   title: "Are you currently using any prescriptions for acne?",
//   choices: ["Yes", "No"],
// },
// {
//   type: "text",
//   isRequired: true,
//   visibleIf: "{isAnyPrescriptionsInUse}='Yes'",
//   name: "currentPrescriptions",
//   title: "Which prescriptions?",
// },
// {
//   type: "text",
//   isRequired: true,
//   visibleIf:
//     "{previousAcneProductsTaken} contains 'Over the counter creams'",
//   name: "currentNonPrescriptions",
//   title:
//     "Which non-prescription products do you use on your skin (e.g. washes, cleansers, masks, creams, etc.)? Please include the brand and product name if you can.",
// },
// {
//   type: "text",
//   isRequired: true,
//   name: "interestingMedications",
//   title: "Are there any specific medications you are interested in?",
// },
//   ],
// };

// export const eczemaTypeQuestions = {
//   questions: [
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "periodEczma",
//       title: "How long have you had eczema?",
//       choices: [
//         "I’m new to eczema",
//         "I’ve had eczema for months",
//         "I’ve had eczema for years",
//       ],
//     },
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "familyHistory",
//       title: "Do you have a family history of eczema?",
//       choices: ["Yes", "No"],
//     },
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "haveAsthma",
//       title: "Do you have asthma?",
//       choices: ["Yes", "No"],
//     },
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "seasonalAllergies",
//       title: "Do you have seasonal allergies?",
//       choices: ["Yes", "No", "Unknown"],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "nonPrescriptionProducts",
//       title:
//         "How would you describe the amount of eczema on your skin from 1 - 10",
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "locationofEczma",
//       title: "Where is your eczema typically located?",
//       choices: [
//         "Inner elbows",
//         "Behind the knees",
//         "Back of neck",
//         "Eyelids",
//         "Palms",
//         "All over the body",
//         "Other",
//       ],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "eczemaLocatedText",
//       visibleIf: "{locationofEczma} contains 'Other'",
//       title: "Where is your eczema typically located?",
//     },
//     {
//       type: "radiogroup",
//       isRequired: true,
//       name: "crackorBleed",
//       visibleIf: "{locationofEczma} contains 'Palms'",
//       title: "Do your hands crack or bleed?",
//       choices: ["Yes", "No"],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "itchyChildSkin",
//       title: "On a scale of 1-10, how itchy is your skin?",
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "worseduringSeason",
//       title: "Is your eczema worse during particular seasons?",
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "otcCreams",
//       title: "What over-the-counter (non-prescription) creams have you used?",
//       choices: [
//         "Moisturizers",
//         "Cleansers",
//         "Cortisone Creams",
//         "Oatmeal Baths",
//         "None",
//       ],
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "locationofMoisturizers",
//       visibleIf: "{otcCreams} contains 'Moisturizers'",
//       title: "What over-the-counter moisturizers have you used?",
//       choices: [
//         "Cetaphil",
//         "Cerave",
//         "Aquaphor",
//         "Eucerin",
//         "Vaseline",
//         "Vanicream",
//         "Other",
//       ],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "moisturizersLocationText",
//       title: "Please enter moisturizers location",
//       visibleIf: "{locationofMoisturizers} contains 'Other'",
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "locationofCleansers",
//       title: "What over-the-counter cleansers have you used?",
//       visibleIf: "{otcCreams} contains 'Cleansers'",
//       choices: ["Cetaphil", "Cerave", "Dove", "Other"],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "cleansersLocationText",
//       title: "Please enter cleansers location",
//       visibleIf: "{locationofCleansers} contains 'Other'",
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "octPills",
//       title: "What over-the-counter (non-prescription) pills have you used?",
//       choices: ["Vitamins", "Antihistamines", "None"],
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "antihistaminesPills",
//       title: "What over-the-counter antihistamines have you used?",
//       visibleIf: "{otcCreams} contains 'Antihistamines'",
//       choices: ["Benadryl", "Zyrtec", "Claritin", "Allegra", "Xyzal", "Other"],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "cleansersLocationText",
//       title: "Please enter antihistamines have you used?",
//       visibleIf: "{antihistaminesPills} contains 'Other'",
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "creamPrecriptions",
//       title: "What prescription creams have you used ?",
//       choices: [
//         "Hydrocortisone",
//         "Triamcinolone",
//         "Alclometasone",
//         "Desonide",
//         "Tacrolimus ( Protopic)",
//         "Pimecrolimus ( Elidel)",
//         "Mometasone",
//         "Fluocinonide",
//         "Halobetasol",
//         "Clobetasol",
//         "Opzelura",
//         "None",
//       ],
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "prescriptionPills",
//       title: "What prescription pills have you used?",
//       visibleIf: "{otcCreams} contains 'Cortisone Creams'",
//       choices: [
//         "Hydroxyzine",
//         "Prednisone",
//         "Rinvoq",
//         "Cibinqo",
//         "Antibiotics",
//         "None",
//       ],
//     },
//     {
//       type: "checkbox",
//       isRequired: true,
//       name: "injectables",
//       title: "What prescription injectables have you used?",
//       visibleIf: "{otcCreams} contains 'Oatmeal baths, creams'",
//       choices: ["Dupixent", "Adbry", "None"],
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "currentMedication",
//       title:
//         "Are you currently using any medications or treatment to treat your condition?",
//     },
//     {
//       type: "text",
//       isRequired: true,
//       name: "specificInterestedMedics",
//       title: "Are there specific medications you are interested in?",
//     },
//   ],
// };
