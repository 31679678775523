import React, { useEffect, useState } from "react";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { SortingPayload } from "../../../../types/Table/SortingPayload";
import { Provider } from "../../../../types/Employee";

const ASCENDING_ORDER_KEYWORD = "asc";
const DESCENDING_ORDER_KEYWORD = "desc";

function switchSortingOrder(currentOrder: "asc" | "desc" | null) {
  switch (currentOrder) {
    case null:
      return ASCENDING_ORDER_KEYWORD;
    case ASCENDING_ORDER_KEYWORD:
      return DESCENDING_ORDER_KEYWORD;
    case DESCENDING_ORDER_KEYWORD:
      return null;
    default:
      return null;
  }
}

interface Props {
  columns: TableHeadPayload<TableBodyPayload<Provider>>[];
  onSortChange: (sort: SortingPayload | null) => void;
}

export function ProvidersTableHead({ columns, onSortChange }: Props) {
  const [sort, setSorting] = useState<SortingPayload | null>(null);

  useEffect(() => {
    onSortChange(sort);
  }, [sort]);

  function sortChangeHandlerFactory(column: string) {
    return () => {
      const isNewColumn = !sort || sort.column !== column;

      if (isNewColumn) {
        setSorting({
          column,
          state: ASCENDING_ORDER_KEYWORD,
        });
      } else if (sort) {
        const newState = switchSortingOrder(sort.state);

        if (!newState) {
          setSorting(null);
        } else {
          setSorting({
            column,
            state: newState,
          });
        }
      }
    };
  }

  return (
    <tr>
      {columns.map(({ title, sort: sortFunc }) => (
        <th
          className={!sort || sort.column !== title ? undefined : sort.state}
          onClick={sortFunc ? sortChangeHandlerFactory(title) : undefined}
        >
          <span>{title}</span>
        </th>
      ))}
    </tr>
  );
}
