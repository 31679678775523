import React, { useContext, useEffect } from "react";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import zipState from "zip-state";
import { RegistrationSurvey, SurveyPage } from "../../../../types/CustomSurvey";
import { getAge } from "../../../../utils/get-age";
import { isEmpty } from "../../../../utils/is-empty";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import { SimplePageLayout } from "../../../NewComponents/Common/SimplePageLayout";
import { BirthDateSurveyQuestion } from "./survey-questions/birth-date";
import { FullNameSurveyQuestion } from "./survey-questions/full-name";
import { IsChildSurveyQuestion } from "./survey-questions/is-child";
import { ParentsDataSurveyQuestion } from "./survey-questions/parents-data";
import { SignUpSurveyQuestion } from "./survey-questions/sign-up";
import { ZipCodeSurveyQuestion } from "./survey-questions/zip-code";
import "./style.scss";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { AuthenticationService } from "../../../../services/cognito";
import { ActionPopupGenerator } from "../../../NewComponents/Common/ActionPopup";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { signIn } from "../../../../features/session";
import { sendSurveyStarted } from "../../../../shared/analytics";
import { HONEYDEW_ELIGIBLE_STATES } from "../../../../data/states";
import { logError } from "../../../../shared/logger";

const surveyQuestions: SurveyPage[] = [
  {
    isFilled: (data) => !isEmpty(data.isChild),
    Component: IsChildSurveyQuestion,
  },
  {
    isFilled: (data) => !isEmpty(data.zipCode),
    Component: ZipCodeSurveyQuestion,
  },
  {
    isFilled: (data) => !isEmpty(data.fullName),
    Component: FullNameSurveyQuestion,
  },
  {
    isFilled: (data) => !isEmpty(data.birthDate),
    Component: BirthDateSurveyQuestion,
  },
  {
    isFilled: (data) => !isEmpty(data.parents),
    when: (data) => data.isChild || getAge(data.birthDate) < 18,
    Component: ParentsDataSurveyQuestion,
  },
  {
    isFilled: (data) => !isEmpty(data.creds),
    Component: SignUpSurveyQuestion,
  },
];

export function RegistrationPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get("code");
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError, showInfo } = useContext(
    NotificationLayerContext
  );

  useEffect(() => {
    sendSurveyStarted();
  }, []);

  async function autoSignIn(email: string, password: string) {
    const session = await AuthenticationService.signIn({
      email,
      password,
    });
    dispatch(signIn(session));
    history.push("/submit-appointment");
  }

  async function doSignUp(data: RegistrationSurvey) {
    const state = zipState(data.zipCode);
    const isEligibleState =
      state && Object.keys(HONEYDEW_ELIGIBLE_STATES).includes(state);

    if (!isEligibleState) {
      logError(`Cannot find state for zipCode ${data.zipCode}`);
      showError({
        title: "Oops... Something went wrong",
        description: "Cannot find state for provided zip code",
      });
      return;
    }

    const result = await AuthenticationService.signUp(
      {
        email: data.creds.email,
        password: data.creds.pwd,
      },
      {
        fullName: data.fullName,
        state,
        dateOfBirth: data.birthDate,
        referralCode: data.referral,
        parentInfo: data.parents,
      }
    );
    if (result?.name === "UsernameExistsException") {
      showPopup(
        ActionPopupGenerator({
          title: "This account already exists",
          text: `An account under ${data.creds.email} email has already been created. To log in, press “cancel” and then “log in” at the button of the page, or add a new patient to your account`,
          action: async () => {
            window.sessionStorage.setItem(
              "patientInfoMultiAcc",
              JSON.stringify({
                fullName: data.fullName,
                state,
                email: data.creds.email,
                dateOfBirth: data.birthDate,
                parentInfo: data.parents,
              })
            );
            history.push("/login");
            showInfo({
              title: "Last step",
              description:
                "Log in to continue adding new patient to your new account",
            });
          },
          actionText: "Add another patient",
        })
      );
      return;
    }

    await autoSignIn(data.creds.email, data.creds.pwd);
  }

  // function render() {
  //   switch (state) {
  //     case STATES.REGISTRATION:
  //       return (
  //         <CustomSurvey
  //           questions={surveyQuestions}
  //           data={{ id: v4(), referral: referralCode }}
  //           onComplete={async (_data) => {
  //             await doSignUp(_data as RegistrationSurvey);
  //             return true;
  //           }}
  //           offsetEnd={2}
  //         />
  //       );
  //     case STATES.VERIFICATION:
  //       if (finalData?.creds?.email) {
  //         return (
  //           <VerifyEmail
  //             survey={finalData as RegistrationSurvey}
  //             onSuccess={() => setState(STATES.COMPLETE)}
  //           />
  //         );
  //       }
  //       return <p>"Cannot find email"</p>;
  //     case STATES.COMPLETE:
  //       return <VerifiedEmail />;
  //     default:
  //       return null;
  //   }
  // }

  return (
    <SimplePageLayout onBack={() => history.push("/login")}>
      <CustomSurvey
        questions={surveyQuestions}
        data={referralCode ? { referral: referralCode } : {}}
        onComplete={async (_data) => {
          await doSignUp(_data as RegistrationSurvey);
          return true;
        }}
        offsetEnd={2}
      />
    </SimplePageLayout>
  );
}
