import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { Stepper } from "../../../../NewComponents/Common/Stepper";
import { ShippingInfo } from "../../../../NewComponents/Patient/ShippingInfo";
import { getSessionState } from "../../../../../features/session";
import { useUpdateShippingInfoMutation } from "../../../../../features/api/patients";
import { NotificationLayerContext } from "../../../../NewComponents/Common/NotificationLayer";

export function ShipppingInfoSurveyPage({
  onDone,
  stepper: { max, current },
}: SurveyComponentProps) {
  const { activePatientId } = useSelector(getSessionState);
  const { showError } = useContext(NotificationLayerContext);
  const [updateShippingInfo, { isLoading, isSuccess, isError, error }] =
    useUpdateShippingInfoMutation();

  useEffect(() => {
    if (isError) {
      showError({
        title: "Something went wrong...",
        description: error?.toString() || "Unknown error appeared",
      });
      return;
    }

    if (isSuccess) {
      onDone({});
    }
  }, [isLoading, isSuccess, isError]);

  if (!activePatientId) {
    return null;
  }

  return (
    <div className="survey-question initiate-checkout-page__page">
      <Stepper max={max} current={current} />
      <p className="survey-question__title">What is your home address?</p>
      <ShippingInfo
        onSubmit={async (data) => {
          await updateShippingInfo({
            patientId: activePatientId,
            shippingInfo: data,
          });
        }}
      />
    </div>
  );
}
