import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Provider, ProviderEdit } from "../../../../../types/Entities/Provider";
import { AuthenticationService } from "../../../../cognito";

export const update = async (provider: ProviderEdit) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.put<Provider>(
      `${ENV_CONFIG.ROUTE_53_BASE_URL}/providers/update`,
      provider,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot update provider`);
    throw error;
  }
};
