/* eslint-disable react/no-unstable-nested-components, react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { BasicPopupTemplate } from "../../../NewComponents/Common/PopupLayer/basic";
import { Button, SubmitButton } from "../../../NewComponents/Common/Button";
import DateSelect from "../../../NewComponents/Common/DateSelect";
import "./styles.scss";
import Table from "../../../NewComponents/Administrator/TableComponent";
import { ReactComponent as DeleteIcon } from "../../../../Assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/pencil.svg";
import DateTimeSelect from "../../../NewComponents/Common/DateTimeSelect";
import {
  useDeleteEmployeeTimeOffEventMutation,
  useUpdateEmployeeTimeOffEventMutation,
  useGetTimeOffHistoryByEmployeeIdQuery,
  useGetActiveTimeOffByEmployeeIdQuery,
  useGetFutureTimeOffByEmployeeIdQuery,
} from "../../../../features/api/employees";
import { convertToMilliseconds } from "../../../../utils/employee/convert-to-milliseconds";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { formatNewTimeOffData } from "../../../../utils/employee/format-history-time-off-record";
import { AuthenticationService } from "../../../../services/cognito";
import { Loader } from "../../../NewComponents/Common/Loader";

function TimeOffHistoryForm({ onClose, empId, emp }: any) {
  const [isEditForm, setIsEditForm] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [employeeCurrentTimeOffs, setEmployeeCurrentTimeOffs] =
    useState<any>(null);
  const [employeeUpcomingTimeOffs, setEmployeeUpcomingTimeOffs] =
    useState(null);
  const [employeeHistoryTimeOffs, setEmployeeHistoryTimeOffs] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [isCurrentTimeOffEdit, setIsCurrentTimeOffEdit] = useState(false);

  const [editTimeOffPayload, setEditTimeOffPayload] = useState({
    createdBy: "",
    lastModifiedBy: "",
    leaveId: "",
    employeeId: "",
    employeeRole: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  });

  const employeeFullname = `${emp?.firstName} ${emp?.lastName}`;

  const [updateEmployeeTimeOffEvent] = useUpdateEmployeeTimeOffEventMutation();
  const [deleteEmployeeTimeOffEvent] = useDeleteEmployeeTimeOffEventMutation();

  const { showSuccess, showError } = useContext(NotificationLayerContext);

  const [errors, setErrors] = useState({
    employeeId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  });

  const timeOffHistoryData = useGetTimeOffHistoryByEmployeeIdQuery(empId);
  const allActiveTimeOff = useGetActiveTimeOffByEmployeeIdQuery(empId);
  const futureTimeOffQuery = useGetFutureTimeOffByEmployeeIdQuery(empId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await allActiveTimeOff.refetch();
        await futureTimeOffQuery.refetch();
        await timeOffHistoryData.refetch();
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [editSuccess, deleteSuccess]);

  useEffect(() => {
    if (timeOffHistoryData?.data?.data.getTimeOffHistoryByEmployeeId) {
      const formattedTimeOffs = formatNewTimeOffData(
        timeOffHistoryData.data.data.getTimeOffHistoryByEmployeeId
      );
      setEmployeeHistoryTimeOffs(formattedTimeOffs);
    }
  }, [timeOffHistoryData]);

  useEffect(() => {
    if (futureTimeOffQuery.data?.data?.getFutureTimeOffByEmployeeId) {
      const formattedTimeOffs = formatNewTimeOffData(
        futureTimeOffQuery.data?.data?.getFutureTimeOffByEmployeeId
      );

      setEmployeeUpcomingTimeOffs(formattedTimeOffs);
    }
  }, [futureTimeOffQuery]);

  useEffect(() => {
    if (allActiveTimeOff.data?.data?.getActiveTimeOffEventByEmployeeId) {
      const formattedTimeOffs = formatNewTimeOffData(
        allActiveTimeOff.data?.data?.getActiveTimeOffEventByEmployeeId
      ).map((timeOff) => ({
        ...timeOff,
        belongsToCurrentTimeOffs: true,
      }));
      setEmployeeCurrentTimeOffs(formattedTimeOffs);
    }
  }, [allActiveTimeOff]);

  useEffect(() => {
    if (
      timeOffHistoryData?.data?.data?.getTimeOffHistoryByEmployeeId &&
      futureTimeOffQuery.data?.data?.getFutureTimeOffByEmployeeId &&
      allActiveTimeOff.data?.data?.getActiveTimeOffEventByEmployeeId
    ) {
      setIsLoaded(true);
    }
  }, [timeOffHistoryData, futureTimeOffQuery, allActiveTimeOff]);

  const formatDateTime = (dateTime: string) => {
    const date = moment(dateTime, "M/D/YYYY, h:mm:ss A").format("MM-DD-YYYY");
    const time = moment(dateTime, "M/D/YYYY, h:mm:ss A").format("hh:mm a");
    return { date, time };
  };

  const updateTimeOffApiCall = async (leaveId: any, payload: any) => {
    try {
      const updateResponse = await updateEmployeeTimeOffEvent({
        leaveId,
        payload,
      }).unwrap();
      if (updateResponse?.error) {
        showError({
          title: "Error Updating Time Off",
          description: "Time off event cannot be updated",
        });
        onClose();
      }
      showSuccess({
        title: "Successfully Updated Time Off",
        description: "Time off event has been updated",
      });
      setEditSuccess(!editSuccess);
      onClose();
    } catch (error) {
      // Handle any error that occurs during the API calls
      console.error("One or more updates failed", error);
      showError({
        title: "Error Updating Time Off",
        description: "Time off event cannot be updated",
      });
      onClose();
    }
  };

  const handleTimeOffEditClick = (timeOffData: any) => {
    const startDateTime = formatDateTime(timeOffData.start);
    const endDateTime = formatDateTime(timeOffData.end);
    if (timeOffData.belongsToCurrentTimeOffs) {
      setIsCurrentTimeOffEdit(true);
    } else {
      setIsCurrentTimeOffEdit(false);
    }
    setEditTimeOffPayload({
      ...editTimeOffPayload,
      startDate: startDateTime.date,
      startTime: startDateTime.time,
      endDate: endDateTime.date,
      endTime: endDateTime.time,
      employeeId: timeOffData.employeeId,
      employeeRole: timeOffData.employeeRole,
      leaveId: timeOffData.leaveId,
    });
    setIsEditForm(true);
  };

  const handleCurrentTimeOffDeletion = async (currentTimeOffData: any) => {
    const currentDateTimeLocal = new Date().toLocaleString("en-US", {
      hour12: true,
    });

    currentTimeOffData.end = currentDateTimeLocal;

    const startDateTime = formatDateTime(currentTimeOffData.start);
    const endDateTime = formatDateTime(currentTimeOffData.end);
    const startDateTimeInMs = convertToMilliseconds(
      startDateTime.date,
      startDateTime.time
    );
    const endDateTimeInMs = convertToMilliseconds(
      endDateTime.date,
      endDateTime.time
    );

    const { leaveId } = currentTimeOffData;
    const currentUser = await AuthenticationService.currentLoggedInUser();
    const lastModifiedBy = currentUser?.username || "";

    const payload = {
      leaveId,
      lastModifiedBy,
      employeeId: currentTimeOffData.employeeId,
      employeeRole: currentTimeOffData.employeeRole,
      start: startDateTimeInMs,
      end: endDateTimeInMs,
    };

    await updateTimeOffApiCall(leaveId, payload);
  };

  const handleTimeOffDeleteClick = async (timeOffData) => {
    const { leaveId } = timeOffData;
    if (timeOffData.belongsToCurrentTimeOffs) {
      const deletionSuccess = await handleCurrentTimeOffDeletion(timeOffData);
      return true;
    }
    try {
      const result = await deleteEmployeeTimeOffEvent({ leaveId }).unwrap();
      showSuccess({
        title: "Deleted Successfully",
        description: "Time off event deleted succcessfully",
      });
      setDeleteSuccess(!deleteSuccess);
      return { leaveId, result };
    } catch (err) {
      console.error(`Failed to delete events with leaveId ${leaveId}:`, err);
      showError({
        title: "Error Deleting TimeOff",
        description: "Time off event cannot be delete",
      });
      return { leaveId, error: err.message };
    }
  };

  const validateForm = (payload: typeof editTimeOffPayload) => {
    let formIsValid = true;
    const newErrors = {};

    // Validate startDate and endDate
    if (
      payload.startDate &&
      payload.endDate &&
      new Date(payload.startDate) > new Date(payload.endDate)
    ) {
      newErrors.startDate = "Start date should be before end date";
      formIsValid = false;
    }

    if (
      payload.startDate &&
      payload.endDate &&
      payload.startDate === payload.endDate
    ) {
      const startTime = moment(payload.startTime, "hh:mm A");
      const endTime = moment(payload.endTime, "hh:mm A");

      if (
        startTime.isValid() &&
        endTime.isValid() &&
        startTime.isAfter(endTime)
      ) {
        newErrors.startTime = "Start time should be before end time";
        formIsValid = false;
      }
    }

    setErrors(newErrors); // Update errors state
    return formIsValid;
  };

  const handleDateChange = (name: string, value: any) => {
    setEditTimeOffPayload((prevState) => {
      const newState = { ...prevState, [name]: value };
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Reset the error for the specific field
      validateForm(newState); // Pass the updated state to the validateForm function
      return newState;
    });
  };

  const handleTimeChange = (name: string, value: any) => {
    setEditTimeOffPayload((prevState) => {
      const newState = { ...prevState, [name]: value };
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Reset the error for the specific field
      validateForm(newState); // Pass the updated state to the validateForm function
      return newState;
    });
  };

  const handleEditTimeOffFormSubmission = async () => {
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {
      console.log("Form is invalid. Errors:", errors);
      showError({
        title: "Error",
        description: "Please resolve errors in the form",
      });
      return;
    }
    const startDateTimeInMs = convertToMilliseconds(
      editTimeOffPayload.startDate,
      editTimeOffPayload.startTime
    );
    const endDateTimeInMs = convertToMilliseconds(
      editTimeOffPayload.endDate,
      editTimeOffPayload.endTime
    );

    const { leaveId, employeeId, employeeRole } = editTimeOffPayload;

    const currentUser = await AuthenticationService.currentLoggedInUser();
    const lastModifiedBy = currentUser?.username || "";

    const payload = {
      leaveId,
      lastModifiedBy,
      employeeId,
      employeeRole,
      start: startDateTimeInMs,
      end: endDateTimeInMs,
    };
    await updateTimeOffApiCall(leaveId, payload);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Start Time",
        accessor: "start",
      },
      {
        Header: "End Time",
        accessor: "end",
      },
      {
        Header: "Duration (days)",
        accessor: "duration",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ display: "flex" }}>
            <span
              className="patients-table__action-button"
              onClick={() => handleTimeOffDeleteClick(row.original)}
              data-tooltip="Delete"
            >
              <DeleteIcon
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  width: "13px",
                  height: "16px",
                }}
              />
            </span>
            <span
              className="patients-table__action-button"
              onClick={() => handleTimeOffEditClick(row.original)}
              data-tooltip="Edit"
            >
              <EditIcon
                style={{ cursor: "pointer", width: "10px", height: "10px" }}
              />
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const historyColumns = React.useMemo(
    () => [
      {
        Header: "Start Time",
        accessor: "start",
      },
      {
        Header: "End Time",
        accessor: "end",
      },
      {
        Header: "Duration (days)",
        accessor: "duration",
      },
    ],
    []
  );

  return (
    <div className="timeoff-modal-container">
      {isEditForm ? (
        <div>
          {isCurrentTimeOffEdit ? null : (
            <div className="timeoff-form-fields sr-combo-inputs-row">
              <div className="timeoff-fields-container">
                <div className="timeoff-fields-size">
                  <span className="timeoff-popup-form-labels">Start Date</span>
                  <DateSelect
                    placeholder="mm-dd-yyyy"
                    value={editTimeOffPayload.startDate || ""}
                    onChange={(date) => handleDateChange("startDate", date)}
                    disablePastDates
                  />
                  {errors.startDate && (
                    <span className="error-message">{errors.startDate}</span>
                  )}
                </div>
                <div className="timeoff-fields-size">
                  <span className="timeoff-popup-form-labels">Start Time</span>
                  <DateTimeSelect
                    placeholder="Select time"
                    value={editTimeOffPayload.startTime || ""}
                    onChange={(time) => handleTimeChange("startTime", time)}
                  />
                  {errors.startTime && (
                    <span className="error-message">{errors.startTime}</span>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="timeoff-form-fields sr-combo-inputs-row">
            <div className="timeoff-fields-container">
              <div className="timeoff-fields-size">
                <span className="timeoff-popup-form-labels">End Date</span>
                <DateSelect
                  value={editTimeOffPayload.endDate || ""}
                  placeholder="mm-dd-yyyy"
                  onChange={(date) => handleDateChange("endDate", date)}
                  disablePastDates
                />
                {errors.endDate && (
                  <span className="error-message">{errors.endDate}</span>
                )}
              </div>
              <div className="timeoff-fields-size">
                <span className="timeoff-popup-form-labels">End Time</span>
                <DateTimeSelect
                  placeholder="Select time"
                  value={editTimeOffPayload.endTime || ""}
                  onChange={(time) => handleTimeChange("endTime", time)}
                />
                {errors.endTime && (
                  <span className="error-message">{errors.endTime}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="timeoff-data-tables">
          {!isLoaded ? (
            <div>
              <Loader className="chat-popup__loader" />
            </div>
          ) : (
            <>
              {employeeCurrentTimeOffs &&
                employeeCurrentTimeOffs.length > 0 && (
                  <div className="timeoff-table-container">
                    <span className="timeoff-table-heading">
                      Current Time Off
                    </span>
                    <div className="table-wrapper">
                      <Table columns={columns} data={employeeCurrentTimeOffs} />
                    </div>
                  </div>
                )}
              {employeeUpcomingTimeOffs &&
                employeeUpcomingTimeOffs.length > 0 && (
                  <div className="timeoff-table-container">
                    <span className="timeoff-table-heading">
                      Upcoming Time-off
                    </span>
                    <div className="table-wrapper">
                      <Table
                        columns={columns}
                        data={employeeUpcomingTimeOffs}
                      />
                    </div>
                  </div>
                )}
              {employeeHistoryTimeOffs &&
                employeeHistoryTimeOffs.length > 0 && (
                  <div className="timeoff-table-container">
                    <span className="timeoff-table-heading">
                      Time-off history
                    </span>
                    <div className="table-wrapper">
                      <Table
                        columns={historyColumns}
                        data={employeeHistoryTimeOffs}
                      />
                    </div>
                  </div>
                )}
              {isEmpty(employeeUpcomingTimeOffs) &&
                isEmpty(employeeHistoryTimeOffs) &&
                isEmpty(employeeCurrentTimeOffs) && (
                  <div
                    className="timeoff-table-container"
                    style={{ textAlign: "center" }}
                  >
                    <span>No time off events found for {employeeFullname}</span>
                  </div>
                )}
            </>
          )}
        </div>
      )}
      {isEditForm && (
        <div className="timeoff-buttons">
          <div>
            <Button
              size="small"
              className="employees-dashboard__button--cancel"
              text="CANCEL"
              onClick={onClose}
            />
          </div>
          <div>
            <Button
              size="small"
              className="employees-dashboard__button--submit"
              text="SUBMIT TIME OFF"
              onClick={handleEditTimeOffFormSubmission}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TimeOffHistoryForm;

export function TimeOffHistoryPopup({ employee }: any) {
  return function Render({ onClose }: any) {
    return (
      <BasicPopupTemplate title="Time off Details" onClose={onClose}>
        <TimeOffHistoryForm
          onClose={onClose}
          empId={employee?.id}
          emp={employee}
        />
      </BasicPopupTemplate>
    );
  };
}
