import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import {
  BasicInfo,
  MedicalBackground,
  Patient,
} from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

interface Props {
  userId: string;
  medicalBackground: Partial<MedicalBackground>;
  basicInfo: Partial<BasicInfo>;
}

export const updateMedicalBackground = async ({
  userId,
  medicalBackground,
  basicInfo,
}: Props) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/update-medical-background`,
      { userId, medicalBackground, basicInfo },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot update medical background");
    throw error;
  }
};
