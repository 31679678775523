import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

export const highlightMessageForProvider = async (
  patientId: string,
  read?: boolean
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post(
      `${ENV_CONFIG.CHATS_SERVICE_GRAPHQL_URL}`,
      {
        query: `mutation { highlightMessageForProvider(patientId: "${patientId}", read: ${!!read}) { id } }`,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return null;
  } catch (error: any) {
    logError("Error occured. Cannot highlight message for provider", error);
    throw error;
  }
};
