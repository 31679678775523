import { Client, Conversation, JSONObject } from "@twilio/conversations";
import { getAllTwilioElements } from "../../utils/get-all-twilio-elements";
import { HoneydewAPI } from "../honeydew-api";

function controller() {
  let conversations: { [accountId: string]: Conversation } | null = null;
  let client: Client | null = null;
  let newMessages: { [accountId: string]: boolean } | null = null;

  function checkConversationForNewMessage(
    conversation: Conversation,
    userRole: string
  ) {
    if (!conversation.uniqueName) return;
    if (!newMessages) newMessages = {};
    const isNewMessage =
      userRole === "providers"
        ? !!conversation.attributes &&
          !!(conversation.attributes as JSONObject).newMessageForProvider
        : (conversation.lastMessage?.index ?? -1) >
          (conversation.lastReadMessageIndex ?? -1);
    newMessages[conversation.uniqueName] = isNewMessage;
  }

  function clear() {
    client?.removeAllListeners();
    client?.shutdown();
    conversations = null;
    client = null;
    newMessages = null;
  }

  return {
    loadConversations: async (identity: string, userRole: string) => {
      const token = await HoneydewAPI.chats.getAccessToken(identity);

      if (client) {
        clear();
      }

      const chatClient = new Client(token);
      client = chatClient;
      const _conversations = await getAllTwilioElements(
        chatClient.getSubscribedConversations()
      );
      conversations = Object.fromEntries(
        _conversations.map((conversation) => [
          conversation.uniqueName,
          conversation,
        ])
      );

      _conversations.forEach((it) =>
        checkConversationForNewMessage(it, userRole)
      );

      chatClient.on("conversationJoined", (data) => {
        if (!data.uniqueName || !conversations) return;
        conversations[data.uniqueName] = data;
        checkConversationForNewMessage(data, userRole);
      });
      chatClient.on(
        "conversationUpdated",
        ({ conversation, updateReasons }) => {
          if (!conversations || !conversation.uniqueName) return;
          conversations[conversation.uniqueName] = conversation;
          checkConversationForNewMessage(conversation, userRole);
        }
      );
    },
    clear,
    getConversations: () => conversations,
    getClient: () => client,
    getNewMessages: () => newMessages,
  };
}

export const TwilioController = controller();
