import React from "react";
import { useSelector } from "react-redux";
import { getAccutaneOfPatient } from "../../../../features/enrollmentCoordinators";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import "./style.scss";
import { useGetAccutaneByPatientIdQuery } from "../../../../features/api/accutane";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { InfoBlock } from "../../Common/InfoBlock";

const formatDate = (dateString: string | undefined): string => {
  if (dateString === "--" || dateString === "N/A") {
    return "--";
  }
  if (dateString && dateString !== "N/A") {
    return getMomentDate(dateString).format("MM/DD/YY");
  }
  return "N/A";
};

function IpledgeDetails({
  patientId,
  isNotPregnantCapability,
}: {
  patientId?: string;
  isNotPregnantCapability: boolean;
}) {
  const { data: accutane, isSuccess } = useGetAccutaneByPatientIdQuery(
    patientId as string,
    {
      skip: !patientId,
    }
  );
  const patientData = useGetPatientByIdQuery(patientId as string, {
    skip: !patientId,
  })?.data;

  if (!accutane) {
    return null;
  }

  const enrollmentDate = formatDate(accutane?.enrollmentDate);
  const lastConfirmationDate = formatDate(accutane?.lastConfirmationDate);
  const nextConfirmationDate = formatDate(accutane?.nextConfirmationDate);
  const accutaneStatus = accutane?.status;
  const accutaneBloodwork = patientData?.flags?.isStopBloodWorkTask;

  function accutaneDuration() {
    const duration = accutane?.confirmationPeriod;

    if (duration.months === 0 && duration.days === 0)
      return (
        <>
          <span className="ipledge-details__title">Status: </span>
          <span>Active</span>
        </>
      );
    if (!duration.past)
      return (
        <>
          <span className="ipledge-details__title">Status: </span>
          <span>
            Active (Month {duration.months}, Day {duration.days})
          </span>
        </>
      );
    if (duration.past)
      return (
        <InfoBlock
          text={
            <>
              <span className="ipledge-details__title">Status: </span> Active
              (Month {duration.months}, {duration.days} days past expected next
              confirmation)
            </>
          }
          view="warn"
        />
      );
    if (duration.months > 0)
      return (
        <>
          <span className="ipledge-details__title">Status: </span>
          <span>
            Active (Month {duration.months}, Day {duration.days})
          </span>
        </>
      );
    return (
      <>
        <span className="ipledge-details__title">Status: </span>
        <span>Active</span>
      </>
    );
  }

  // const showStatusIndicator = () => {
  //   switch (accutaneStatus) {
  //     case "renew":
  //       return accutaneDuration();
  //     case "stop":
  //       return (
  //         <>
  //           <span className="ipledge-details__title">Status:</span>
  //           <span>Course concluded</span>
  //         </>
  //       );
  //     case "pause":
  //       return (
  //         <>
  //           <span className="ipledge-details__title">Status:</span>
  //           <span>
  //             {" "}
  //             Month {accutane?.confirmationPeriod?.months}, Day{" "}
  //             {accutane?.confirmationPeriod?.days} (Paused <br /> until{" "}
  //             {nextConfirmationDate})
  //           </span>
  //         </>
  //       );
  //     default:
  //       return accutaneDuration();
  //   }
  // };

  const showStatusIndicator = () => {
    switch (accutaneStatus) {
      case "renew":
        return "Active";
      case "stop":
        return "Course concluded";
      case "pause":
        return `Pause Accutane untill ${nextConfirmationDate}`;
      default:
        return "Active";
    }
  };

  return (
    <div className="ipledge-details">
      <p className="ipledge-details__title">iPledge details</p>
      <div>
        <br />
        <span className="ipledge-details__title">Status: </span>
        <span>{showStatusIndicator()}</span>
        <br />
        <br />
        <span className="ipledge-details__title">Bloodwork: </span>
        <span>{` ${
          accutaneBloodwork ? "Not required" : "Required"
        } for next confirmation `}</span>

        <p className="ipledge-details__item">
          <span className="ipledge-details__item-description">
            {`Patient ${
              isNotPregnantCapability ? "cannot" : "can"
            } get pregnant`}
          </span>
        </p>
      </div>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">REMS number</span>
        <span className="ipledge-details__item-content">
          {accutane.remsNumber}
        </span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Date of enrollment
        </span>
        <span className="ipledge-details__item-content">{enrollmentDate}</span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Last confirmation date
        </span>
        <span className="ipledge-details__item-content">
          {lastConfirmationDate}
        </span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Next confirmation date
        </span>
        <span className="ipledge-details__item-content">
          {nextConfirmationDate}
        </span>
      </p>
    </div>
  );
}

export default IpledgeDetails;
