import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { ProviderTask } from "../../../../../types/Provider";

const buildQuery = (documentId?: string, taskId?: string) => {
  let query = "";

  if (documentId) {
    query += `&documentId=${documentId}`;
  }
  if (taskId) {
    query += `&taskId=${taskId}`;
  }

  return query;
};

export const getMiscellaneousTasks = async (
  employeeId: string,
  documentId?: string,
  taskId?: string
): Promise<ProviderTask[]> => {
  try {
    const query = buildQuery(documentId, taskId);

    const result = await axios.get(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/get-miscellaneous-tasks/${employeeId}${
        query.length ? `?${query}` : ""
      }`
    );

    return result.data.tasks;
  } catch (error) {
    logError("Error received when trying to receive tasks");
    throw error;
  }
};
