import React, { useState } from "react";
import { Patient } from "../../../../types/Entities/Patient";
import { Skeleton } from "../Skeleton";

interface Props {
  patient: Patient | null;
}

export function HeightAndWeightLine({ patient }: Props) {
  return (
    <div className="followUp-card__patient-info__height-weight">
      {!patient ? (
        <Skeleton />
      ) : (
        <span className="followUp-card__patient-info__height-weight__item">
          H:{" "}
          {patient.medicalBackground
            ? (patient.medicalBackground.height -
                (patient.medicalBackground.height % 12)) /
              12
            : "-"}
          '
          {patient.medicalBackground
            ? patient.medicalBackground.height % 12
            : "-"}
          "
        </span>
      )}

      <span className="followUp-card__patient-info__splitter" />
      {!patient ? (
        <Skeleton />
      ) : (
        <span className="followUp-card__patient-info__height-weight__item">
          W: {patient.medicalBackground?.weight ?? "—"} lbs
        </span>
      )}
    </div>
  );
}
