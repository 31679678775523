import { createSlice } from "@reduxjs/toolkit";
import { EnrollmentCoordinator } from "../types/Entities/EnrollmentCoordinator";
import { ReduxAction, ReduxStore } from "../types/Stores";
import { EnrollmentCoordinatorsStore } from "../types/Stores/EnrollmentCoordinatorsStore";
import { SortingPayload } from "../types/Table/SortingPayload";
import { Accutane } from "../types/Entities/Accutane";
import { ProviderTask } from "../types/Provider";

const initialState: EnrollmentCoordinatorsStore = {
  loadingStatus: "idle",
  wasInitialized: false,
  list: [],
  sort: null,
  accutane: null,
  tasks: [],
  documentTasks: [],
};

export const enrollmentCoordinatorsSlice = createSlice({
  name: "enrollmentCoordinators",
  initialState,
  reducers: {
    setEnrollmentCoordinators(
      _state,
      { payload }: ReduxAction<EnrollmentCoordinator[]>
    ) {
      const state = _state;
      state.list = payload;
      state.wasInitialized = true;
    },
    clearEnrollmentCoordinatorsStore(_state) {
      const state = _state;
      state.list = [];
      state.wasInitialized = false;
      state.accutane = null;
      state.tasks = [];
      state.documentTasks = [];
    },
    setSorting(_state, { payload }: ReduxAction<SortingPayload | null>) {
      const state = _state;
      state.sort = payload;
    },
    addEnrollmentCoordinator(
      _state,
      { payload }: ReduxAction<EnrollmentCoordinator>
    ) {
      const state = _state;
      state.list.push(payload);
      state.list = Array.from(state.list);
    },
    deleteEnrollmentCoordinator(_state, { payload }: ReduxAction<string>) {
      const state = _state;
      const index = state.list.findIndex(({ id }) => id === payload);
      state.list.splice(index, 1);
      state.list = Array.from(state.list);
    },
    updateEnrollmentCoordinator(
      _state,
      { payload }: ReduxAction<EnrollmentCoordinator>
    ) {
      const state = _state;
      const index = state.list.findIndex(({ id }) => id === payload.id);
      state.list[index] = {
        ...payload,
      };
      state.list = Array.from(state.list);
    },
    setEnrollmentCoordinatorAccutaneInfo(
      state,
      { payload }: ReduxAction<Accutane>
    ) {
      state.accutane = payload;
    },
    setEnrollmentCoordinatorTasks(
      state,
      { payload }: ReduxAction<ProviderTask[]>
    ) {
      state.tasks = payload;
    },
    setDocumentTasks(state, { payload }: ReduxAction<ProviderTask[]>) {
      state.documentTasks = payload;
    },
    clearEnrollmentCoordinatorAccutaneInfo(state) {
      state.accutane = null;
    },
  },
});

export const {
  setEnrollmentCoordinators,
  clearEnrollmentCoordinatorsStore,
  setSorting,
  addEnrollmentCoordinator,
  deleteEnrollmentCoordinator,
  updateEnrollmentCoordinator,
  setEnrollmentCoordinatorAccutaneInfo,
  setEnrollmentCoordinatorTasks,
  setDocumentTasks,
  clearEnrollmentCoordinatorAccutaneInfo,
} = enrollmentCoordinatorsSlice.actions;

export const getEnrollmentCoordinatorsInfoState = ({
  enrollmentCoordinatorsStore,
}: ReduxStore) => enrollmentCoordinatorsStore;

export const getAccutaneOfPatient = ({
  enrollmentCoordinatorsStore,
}: ReduxStore) => enrollmentCoordinatorsStore.accutane;

export const getEnrollmentCoordinatorById =
  (coordinatorId: string | undefined | null) =>
  ({ enrollmentCoordinatorsStore }: ReduxStore) =>
    enrollmentCoordinatorsStore.list.find(({ id }) => id === coordinatorId);

export const getEnrollmentCoordinatorTasks = ({
  enrollmentCoordinatorsStore,
}: ReduxStore) => enrollmentCoordinatorsStore.tasks;

export const getDocumentTasks = ({ enrollmentCoordinatorsStore }: ReduxStore) =>
  enrollmentCoordinatorsStore.documentTasks;

export default enrollmentCoordinatorsSlice.reducer;
