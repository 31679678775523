/* eslint-disable react/prop-types */
import React from "react";
import { Provider } from "react-redux";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import store from "../../../store"; // Adjust the path based on your project structure
import providerIcon from "../../../Assets/NewIcons/single-doctor.png";
import { useGetEmployeeQuery } from "../../../features/api/employees";
import { getFullType } from "../../../utils/get-full-type";
import "./style.scss";

function EmployeeInfoComponent({ followupId }) {
  const submittedBy = followupId?.treatmentPlan?.submittedBy;
  const {
    data: employeeData,
    isLoading,
    error,
  } = useGetEmployeeQuery(submittedBy, {
    skip: !submittedBy,
  });

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error loading employee data</Text>;
  }

  if (!employeeData) {
    return null;
  }

  const styles = StyleSheet.create({
    iconImage: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
  });

  return (
    <View style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
      {employeeData.image ? (
        <Image style={styles.iconImage} src={employeeData.image} />
      ) : (
        <Image style={styles.iconImage} src={providerIcon} />
      )}
      <Text style={{ fontSize: 16 }}>
        {employeeData.firstName} {employeeData.lastName}
        {employeeData.role && <> ({getFullType(employeeData.role)})</>}
      </Text>
    </View>
  );
}

function EmployeeInfo(props) {
  return (
    <Provider store={store}>
      <EmployeeInfoComponent {...props} />
    </Provider>
  );
}

export default EmployeeInfo;
/* eslint-enable react/prop-types */
