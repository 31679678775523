import Joi from "joi";
import JoiPhoneNumber from "joi-phone-number";
import React, { useContext, useState } from "react";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { Button } from "../../Common/Button";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import {
  CustomPopupTemplate,
  CustomPopupTemplateContext,
} from "../../Common/PopupLayer/custom";
import { TextInput } from "../../Common/TextInput";
import "./style.scss";
import { HONEYDEW_ELIGIBLE_STATES } from "../../../../data/states";

interface Props {
  onClose: () => void;
}

interface PopupProps {
  zipCode: string;
}

function UnsupportedAreaPopup({ zipCode }: PopupProps) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailInvalid, setEmailInvalid] = useState("");
  const [phoneInvalid, setPhoneInvalid] = useState("");
  const { onClose } = useContext(CustomPopupTemplateContext);

  // Assumes at least 3 eligible states
  const honeydewEligibleStatesArray = Object.keys(HONEYDEW_ELIGIBLE_STATES);
  let honeydewEligibleStatesString = honeydewEligibleStatesArray[0];
  for (let i = 1; i < honeydewEligibleStatesArray.length; i++) {
    if (i === honeydewEligibleStatesArray.length - 1) {
      honeydewEligibleStatesString = honeydewEligibleStatesString.concat(
        `, and ${honeydewEligibleStatesArray[i]}`
      );
    } else {
      honeydewEligibleStatesString = honeydewEligibleStatesString.concat(
        `, ${honeydewEligibleStatesArray[i]}`
      );
    }
  }

  function validateEmail() {
    const { error } = Joi.string()
      .email({ tlds: { allow: false } })
      .validate(email);
    if (error) {
      setEmailInvalid("Invalid email");
      return false;
    }

    return true;
  }

  function validatePhone() {
    const _phone = phone.replaceAll(/[^+0-9]/g, "");
    if (
      Joi.extend(JoiPhoneNumber)
        .string()
        .phoneNumber({
          strict: true,
          format: "e164",
        })
        .validate(_phone).error
    ) {
      setPhoneInvalid("Invalid phone number");
      return false;
    }

    return true;
  }

  return (
    <div className="unsupported-area-popup">
      <div className="unsupported-area-popup__message-block">
        <p className="unsupported-area-popup__title">
          We are launching in your area soon
        </p>
        <p className="unsupported-area-popup__descr paragraph-font--color">
          We are not available in your state yet, but we are working hard on
          getting there! Please leave your email and phone number to be the
          first to know when we're available in your area.
        </p>
        <TextInput
          id="email"
          name="email"
          value={email}
          placeholder="Email address"
          onChange={(value) => {
            setEmail(value);
            setEmailInvalid("");
          }}
          error={emailInvalid}
          className="unsupported-area-popup__input"
        />
        <TextInput
          id="phone"
          name="phone"
          value={phone}
          placeholder="Phone number"
          onChange={(value) => {
            setPhone(value);
            setPhoneInvalid("");
          }}
          error={phoneInvalid}
          className="unsupported-area-popup__input"
        />
      </div>
      <div className="unsupported-area-popup__actions">
        <Button
          text="cancel"
          view="secondary"
          onClick={onClose}
          className="unsupported-area-popup__action"
        />
        <Button
          text="submit"
          className="unsupported-area-popup__action"
          disabled={!email || !phone}
          onClick={async () => {
            const isEmailValid = validateEmail();
            const isPhoneValid = validatePhone();

            if (!isEmailValid || !isPhoneValid) return;

            try {
              await AcneAwayAPI.unsupportedVisitors.createUnsupportedVisitor({
                email,
                phone,
                zipCode,
              });
              showSuccess({
                title: "Success!",
                description:
                  "You've successfully submited your data. You can close this page now. Stay tuned!",
              });
              onClose();
            } catch {
              showError({
                title: "Something went wrong",
                description:
                  "Couldn't submit your data. Please, try again later",
              });
            }
          }}
        />
      </div>
    </div>
  );
}

export function UnsupportedAreaPopupGenerator(zipCode: string) {
  return function render({ onClose }: Props) {
    return (
      <CustomPopupTemplate onClose={onClose}>
        <UnsupportedAreaPopup zipCode={zipCode} />
      </CustomPopupTemplate>
    );
  };
}
