import React, { useContext, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientsPageForProvider } from "../../NewPages/Provider/PatientsPage";
import { Footer } from "../../NewComponents/Common/Footer";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";
import PatientFollowUpProviderComponent from "./PatientFollowUpProviderComponent";
import PatientDashboardForProviderComponent from "./PatientDashboardForProviderComponent";
import { getSessionState } from "../../../features/session";
import { useGetAssignedPatientsQuery } from "../../../features/api/patients";

function ProviderProtectedRoutes() {
  const [isAuthorised, setIsAuthorised] = useState(true);
  const [paramsId, setParamsId] = useState(null);

  const { userId } = useSelector(getSessionState);
  const careTeam = useGetAssignedPatientsQuery(userId);

  useEffect(() => {
    if (careTeam.status === "fulfilled" && paramsId) {
      const isExist = careTeam?.data.some((obj) => obj.patientId === paramsId);
      setIsAuthorised(isExist);
    }
  }, [careTeam, paramsId]);

  return (
    <Switch>
      <Route path="/:userId/visit">
        <div className="main-area">
          <PatientFollowUpProviderComponent
            isAuthorised={isAuthorised}
            setParamsId={setParamsId}
          />
        </div>
      </Route>
      <Route path="/:userId">
        <div className="main-area">
          <PatientDashboardForProviderComponent
            isAuthorised={isAuthorised}
            setParamsId={setParamsId}
          />
        </div>
      </Route>
      <Route path="/">
        <div className="main-area">
          <PatientsPageForProvider />
          <ChatWidget />
          <Footer />
        </div>
      </Route>
    </Switch>
  );
}

export default ProviderProtectedRoutes;
