import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { AccutaneNotification } from "../../../../../types/Accutane";

export const getNotification = async (
  patientId: string
): Promise<AccutaneNotification> => {
  try {
    const result = await axios.get<{ body: AccutaneNotification }>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/get-notification/${patientId}`
    );

    return result.data?.body ?? {};
  } catch (error) {
    logError("Error received when trying to get notification");
    throw error;
  }
};
