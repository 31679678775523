export function convertToMilliseconds(dateString: string, timeString: string) {
  const [month, day, year] = dateString.includes("/")
    ? dateString.split("/")
    : dateString.split("-");
  const [time, modifier] = timeString.split(" ");
  const [h, m] = time.split(":").map(Number);
  let hours = h;
  const minutes = m;

  if ((modifier === "pm" || modifier === "PM") && hours !== 12) {
    hours += 12;
  }
  if ((modifier === "am" || modifier === "AM") && hours === 12) {
    hours = 0;
  }

  const date = new Date(year, month - 1, day, hours, minutes, 0);
  return date.getTime();
}
