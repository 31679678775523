import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteEnrollmentCoordinator } from "../../../../features/enrollmentCoordinators";
import { show } from "../../../../features/errorNotification";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { DeleteConfirmainPopupContext } from "../../Common/DeleteConfirmationPopup";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { EnrollmentCoordinator } from "../../../../types/Employee";
import { NotificationLayerContext } from "../../Common/NotificationsLayout";
import { useArchiveEmployeeMutation } from "../../../../features/api/employees";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/pencil.svg";
import { ReactComponent as ArchiveIcon } from "../../../../Assets/icons/delete.svg";
import { ReactComponent as TimeOff } from "../../../../Assets/NewIcons/time-off.svg";
import { TimeOffHistoryPopup } from "../../../Pages/Administrator/TimeOffHistoryPopup";

interface Props {
  enrollmentCoordinatorsList: TableBodyPayload<EnrollmentCoordinator>[];
}

export function EnrollmentCoordinatorsTableBody({
  enrollmentCoordinatorsList,
}: Props) {
  const { showPopup } = useContext(PopupLayerContext);
  const { ask } = useContext(DeleteConfirmainPopupContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [archiveEmployee] = useArchiveEmployeeMutation();

  async function archiveEnrollmentCoordinator(id: string) {
    const result: any = await archiveEmployee({
      employeeId: id,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (result.error || result.data?.errors) {
      showError({
        title: "Something went wrong...",
        description: "Unable to perform this action",
      });
      throw new Error("Unable to archive an enrollment coordinator");
    }

    showSuccess({
      title: "Success!",
      description: `Enrollment coordinator has been archived successfully`,
    });
  }

  function askUserForConfirmation(id: string) {
    ask(archiveEnrollmentCoordinator.bind(null, id), "archive");
  }

  return (
    <>
      {enrollmentCoordinatorsList.map(({ entity: enrollmentCoordinator }) => (
        <tr>
          <td>
            {enrollmentCoordinator.firstName} {enrollmentCoordinator.lastName}
          </td>
          <td>
            {enrollmentCoordinator.email && (
              <a
                className="row-text-color"
                href={`mailto:${enrollmentCoordinator.email}`}
              >
                {enrollmentCoordinator.email}
              </a>
            )}
          </td>
          <td>
            {enrollmentCoordinator.phone && (
              <a
                className="row-text-color"
                href={`tel:${enrollmentCoordinator.phone}`}
              >
                {enrollmentCoordinator.phone}
              </a>
            )}
          </td>
          <td className="patientsTable__actionsList">
            <span
              onClick={async () => {
                showPopup(TimeOffHistoryPopup(enrollmentCoordinator));
              }}
            >
              <TimeOff />
            </span>
            <span
              onClick={async () => {
                showPopup(EmployeePopupGenerator(enrollmentCoordinator));
              }}
            >
              <EditIcon />
            </span>
            <span
              onClick={() => {
                askUserForConfirmation(enrollmentCoordinator.id);
              }}
            >
              <ArchiveIcon />
            </span>
            {/* <button
              type="button"
              title="Get report"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(
                  ProvidersReportPickerPopupGenerator({
                    providerId: provider.id,
                  })
                );
              }}
            >
              <div className="patientsTable__actionButton--schedule" />
            </button>
            <button
              type="button"
              title="Edit"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(EmployeePopupGenerator(provider));
              }}
            >
              <div className="patientsTable__actionButton--edit" />
            </button>
            {provider.archived ? null : (
              <button
                type="button"
                title="Archive"
                className="patientsTable__actionButtonWithIcon"
                onClick={() => {
                  askUserForConfirmation(provider.id);
                }}
              >
                <div className="patientsTable__actionButton--delete" />
              </button>
            )} */}
          </td>
          {/* <td className="patientsTable__actionsList">
            <button
              type="button"
              title="Edit"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(EmployeePopupGenerator(enrollmentCoordinator));
              }}
            >
              <div className="patientsTable__actionButton--edit" />
            </button>
            {enrollmentCoordinator.archived ? null : (
              <button
                type="button"
                title="Archive"
                className="patientsTable__actionButtonWithIcon"
                onClick={() => {
                  askUserForConfirmation(enrollmentCoordinator.id);
                }}
              >
                <div className="patientsTable__actionButton--delete" />
              </button>
            )}
          </td> */}
        </tr>
      ))}
    </>
  );
}
