export default function boolToString(value?: boolean | string) {
  let result = null;

  switch (value) {
    case true:
    case "true":
      result = "Yes";
      break;
    case false:
    case "false":
      result = "No";
      break;
    case "Yes":
    case "No":
      result = value;
      break;
    default:
      if (typeof value === "string" && value.trim() !== "") {
        result = value;
      }
      break;
  }

  return result;
}
