import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useContext } from "react";
import { ReactComponent as HomeIcon } from "../../../../../Assets/NewIcons/home.svg";
import { ReactComponent as LogOutIcon } from "../../../../../Assets/NewIcons/log-out.svg";
import { ReactComponent as EmployeesIcon } from "../../../../../Assets/NewIcons/employees.svg";
import { ReactComponent as MedicineIcon } from "../../../../../Assets/NewIcons/medicine.svg";
import { MenuItem } from "../types";
import { signOut } from "../../../../../features/session";
import { ActiveWrap } from "../active-wrap";
import { clearAllStores } from "../../../../../features/utils/clear-all-stores";
import { PopupLayerContext } from "../../PopupLayer";

interface Props {
  onClose: () => void;
}

export function MenuItemsForAdministrator({ onClose }: Props) {
  const { closeAllPopups } = useContext(PopupLayerContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const itemsList: MenuItem[] = [
    {
      icon: HomeIcon,
      text: "Patients",
      path: "/",
    },
    {
      icon: EmployeesIcon,
      text: "Employees",
      path: "/employees",
    },
    {
      icon: MedicineIcon,
      text: "Medications",
      path: "/medications",
    },
    {
      icon: LogOutIcon,
      text: "Log out",
      onClick: () => {
        dispatch(signOut());
        clearAllStores();
        closeAllPopups();
        localStorage.removeItem("currentIndex");
        localStorage.removeItem("patientsTableSorting");
        history.push("/login");
      },
    },
  ];

  return (
    <>
      {itemsList
        .filter((item) => !item.condition || item.condition())
        .map((item) => (
          <li className="menu__list-item">
            <ActiveWrap item={item} onClick={onClose}>
              <div className="menu__circle">
                <item.icon className="menu__item-icon" />
              </div>
              <p>{item.text}</p>
            </ActiveWrap>
          </li>
        ))}
    </>
  );
}
