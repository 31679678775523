export function followUpSurveyValueToDB(value: any): {
  type: string;
  value: string;
} {
  switch (typeof value) {
    case "string":
      return {
        type: "text",
        value,
      };
    case "number":
      return {
        type: "number",
        value: String(value),
      };
    case "boolean":
      return {
        type: "bool",
        value: String(value),
      };
    case "object":
      if (value instanceof Array) {
        return {
          type: "list",
          value: JSON.stringify(value),
        };
      }
      return {
        type: "object",
        value: JSON.stringify(value),
      };
    default:
      throw new Error("Survey mapping error: unknown value type received");
  }
}
