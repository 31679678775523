/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Select from "react-select";
import { alphabetical } from "../../../../utils/sortings";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { Button } from "../../../NewComponents/Common/Button";
import generateHash from "../../../../utils/generate-hash";

interface Props {
  onChange: (data: Partial<TreatmentPlanTableDataItem>) => void;
}

function TreatmentForm({
  data,
  setIsOpen,
  onChange,
  setTableRows,
  tablesRowData,
  tableRows,
  setDate,
  date,
}: Props) {
  const [formData, setFormData] = useState({
    medicineKey: data?.medicineKey || "",
    instructions: data?.instructions || [],
    specialInstructions: data?.specialInstructions || "",
    refillsCount: data?.refillsCount || 0,
    refillExpirationDate: data?.refillExpirationDate || null,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const idHash = generateHash(6);

  const preparedMedicineMap =
    medicationsQuery.data?.reduce(
      (acc: { [p: string]: { value: string; label: string } }, item) => {
        acc[item.id] = {
          value: item.id,
          label: `${item.name}${item.strength ? ` ${item.strength}` : ""}${
            item.size ? `, ${item.size}` : ""
          }`,
        };

        return acc;
      },
      {}
    ) || {};

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (item) => {
    const updatedInstructions = formData.instructions.includes(item)
      ? formData.instructions.filter((instruction) => instruction !== item)
      : formData.instructions.concat([item]);

    setFormData({ ...formData, instructions: updatedInstructions });
  };

  const handleDecrement = () => {
    setFormData({ ...formData, refillsCount: formData.refillsCount - 1 });
  };

  const handleIncrement = () => {
    setFormData({ ...formData, refillsCount: formData.refillsCount + 1 });
  };

  const handleAddEditItem = () => {
    if (data) {
      const updatedRows = tableRows.map((row) => {
        if (row.medicineKey === data.medicineKey) {
          const updatedRow = {
            ...row,
            medicineKey: selectedOption?.value || row?.medicineKey,
            instructions: formData.instructions || row.instructions,
            specialInstructions:
              formData.specialInstructions || row.specialInstructions,
            refillsCount: formData.refillsCount || row.refillsCount,
            refillExpirationDate: date || row.refillExpirationDate,
          };
          return updatedRow;
        }
        return row;
      });
      setTableRows(updatedRows);
    } else {
      const newItem = {
        medicineKey: selectedOption?.value || "",
        instructions: formData.instructions || [],
        specialInstructions: formData.specialInstructions || "",
        refillsCount: formData.refillsCount || 0,
        refillExpirationDate: date || null,
      };
      const newTableRows = [...tablesRowData, newItem];
      setTableRows(newTableRows);
    }
    setIsOpen(false);
  };

  return (
    <div>
      <div
        style={{
          height: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <span
          style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            color: "#583AD6",
          }}
          onClick={() => setIsOpen(false)}
        >
          {"< Close"}
        </span>
        <h2
          style={{
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "24px",
            color: "#403E48",
          }}
        >
          {data ? "Edit Treatment" : "Add Treatment"}
        </h2>
      </div>

      <div>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "24px",
            color: "#403E48",
            display: "inline-block",
            marginBottom: "10px",
          }}
        >
          Name of the Treatment
        </span>
        {medicationsQuery.data ? (
          <Select
            options={(
              data?.itemsList || medicationsQuery.data.map(({ id }) => id)
            )
              .map((item) => preparedMedicineMap[item])
              .sort((a, b) => alphabetical(a.label, b.label))}
            /* eslint-disable no-nested-ternary */
            value={
              selectedOption !== null
                ? selectedOption
                : data && data.medicineKey
                ? preparedMedicineMap[data.medicineKey] || data.medicineKey
                : ""
            }
            /* eslint-enable no-nested-ternary */
            className="treatment-plan-table__cell-select treatment-plan-table__cell-select-form"
            onChange={(item) => {
              let expirationDate = null;
              if (!item) return;
              const medicine = medicationsQuery.data?.find(
                ({ id }) => item.value === id
              ) as Medicine;

              if (medicine.refillExpiration) {
                const refillDate = new Date();
                refillDate.setDate(
                  refillDate.getDate() + medicine.refillExpiration
                );
                expirationDate = refillDate;
              }
              setSelectedOption(item);
              setFormData({
                ...formData,
                medicineKey: item?.value || "",
                instructions: medicine.instructions || [],
                specialInstructions: medicine.specialInstructions || "",
                refillsCount: Number(medicine.refillCount) || 0,
                refillExpirationDate: Number(expirationDate),
              });
            }}
          />
        ) : (
          <Skeleton fullHeight />
        )}

        <div style={{ padding: "10px" }}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              color: "#403E48",
              marginBottom: "10px",
            }}
          >
            Instructions
          </label>
          <div
            style={{
              border: "1px solid #F2F4F7",
              borderRadius: "5px",
              overflow: "hidden",
              padding: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "5px 0px 10px 0px",
              }}
            >
              <input
                type="checkbox"
                name="morning"
                id="morning"
                style={{ marginRight: "5px" }}
                onChange={() => handleCheckboxChange("morning")}
                checked={
                  (formData &&
                    formData.instructions &&
                    formData.instructions.includes("morning")) ||
                  (!formData?.instructions &&
                    data &&
                    data.instructions &&
                    data.instructions.includes("morning"))
                }
              />

              <label htmlFor="morning">Morning</label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="evening"
                id="evening"
                style={{ marginRight: "5px" }}
                onChange={() => handleCheckboxChange("evening")}
                checked={
                  (formData &&
                    formData.instructions &&
                    formData.instructions.includes("evening")) ||
                  (!formData?.instructions &&
                    data &&
                    data.instructions &&
                    data.instructions.includes("evening"))
                }
              />
              <label htmlFor="evening">Evening</label>
            </div>
          </div>
        </div>

        <div>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              color: "#403E48",
              marginBottom: "15px",
            }}
          >
            Special Instructions
          </label>
          <div style={{ marginBottom: "10px" }}>
            <textarea
              name="specialInstructions"
              value={formData.specialInstructions || ""}
              style={{
                height: "128px",
                width: "90%",
                border: "1px solid #E1E0E6",
                borderRadius: "20px",
                padding: "10px 14px",
              }}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              color: "#403E48",
              marginBottom: "10px",
            }}
          >
            Refills
          </label>
          <div>
            <button
              type="button"
              style={{
                border: "1px solid #F2F4F7",
                outline: "none",
                backgroundColor: "#fff",
                borderRadius: "8px",
                fontSize: "18px",
              }}
              onClick={handleDecrement}
            >
              -
            </button>
            <span style={{ margin: "0px 10px" }}>{formData?.refillsCount}</span>
            <button
              type="button"
              style={{
                border: "1px solid #F2F4F7",
                outline: "none",
                backgroundColor: "#fff",
                borderRadius: "8px",
                fontSize: "18px",
              }}
              onClick={handleIncrement}
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
        }}
        className="treatment-plan-table__calendar treatment-plan-table__mobile-calendar"
      >
        <p
          className="follow-up-visit__heading-text"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "16px",
            color: "#403E48",
            marginLeft: "-3px",
          }}
        >
          Refill Expiration Date
        </p>
        <input
          type="date"
          defaultValue={
            data && data.refillExpirationDate
              ? new Date(data.refillExpirationDate).toISOString().split("T")[0]
              : ""
          }
          min={new Date().toISOString().split("T")[0]}
          onChange={(event) => {
            const selectedDate = new Date(event.target.value);
            setDate(selectedDate.getTime());
          }}
        />
      </div>

      <div
        className="follow-up-visit__submit-area"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          size="big"
          text="Save Updates"
          onClick={() => handleAddEditItem()}
          // disabled={!isChangeDetected}
        />
        <Button
          size="big"
          text="Cancel"
          onClick={() => setIsOpen(false)}
          className="follow-up-visit__update-button"
          // onClick={submit}
          // disabled={!isChangeDetected}
        />
      </div>
    </div>
  );
}

export default TreatmentForm;
