/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import generateHash from "../../../../utils/generate-hash";
import { InstructionsList } from "../../../data/medicineData";
import { getMedicineInfoState } from "../../../../features/medicine";
import { TreatmentPlanTableDataItem } from "./types";
import { Medicine } from "../../../../types/Entities/Medicine";
import { alphabetical } from "../../../../utils/sortings";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { ReactComponent as RemoveButtonIcon } from "../../../../Assets/icons/delete.svg";
import "./style.scss";

interface Props {
  data: TreatmentPlanTableDataItem;
  onChange: (data: Partial<TreatmentPlanTableDataItem>) => void;
}

export function TreatmentTableRow({
  data,
  onChange,
  setTableRows,
  tableRows,
}: Props) {
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const idHash = generateHash(6);

  const preparedMedicineMap =
    medicationsQuery.data?.reduce(
      (acc: { [p: string]: { value: string; label: string } }, item) => {
        acc[item.id] = {
          value: item.id,
          label: `${item.name}${item.strength ? ` ${item.strength}` : ""}${
            item.size ? `, ${item.size}` : ""
          }`,
        };

        return acc;
      },
      {}
    ) || {};

  const handleTableRowsDeleting = (medicineKey) => {
    const rowToDelete = tableRows.find(
      (row) => row.medicineKey === medicineKey
    );
    if (!rowToDelete) {
      return;
    }

    if (rowToDelete.rowChecked) {
      const updatedRowData = { ...rowToDelete, rowChecked: false };
      onChange(updatedRowData);
      return;
    }
    const newState = tableRows.filter((row) => row.medicineKey !== medicineKey);
    setTableRows(newState);
  };

  return (
    <tr>
      <td>
        {medicationsQuery.data ? (
          <Select
            options={(
              data.itemsList || medicationsQuery.data.map(({ id }) => id)
            )
              .map((item) => preparedMedicineMap[item])
              .sort((a, b) => alphabetical(a.label, b.label))}
            value={preparedMedicineMap[data.medicineKey]}
            className="treatment-plan-table__cell-select"
            onChange={(item) => {
              let expirationDate = null;
              if (!item) return;
              const medicine = medicationsQuery.data?.find(
                ({ id }) => item.value === id
              ) as Medicine;

              if (medicine.refillExpiration) {
                const date = new Date();
                date.setDate(date.getDate() + medicine.refillExpiration);
                expirationDate = date;
              }

              onChange({
                medicineKey: item.value,
                instructions: medicine.instructions,
                specialInstructions: medicine.specialInstructions || "",
                refillsCount: Number(medicine.refillCount),
                refillExpirationDate: Number(expirationDate),
              });
            }}
          />
        ) : (
          <Skeleton fullHeight />
        )}
      </td>
      <td>
        <div className="treatment-plan-table__checkbox-block treatment-plan-table__table-cell--centered">
          {Object.entries(InstructionsList).map(([item, name]) => (
            <div className="treatment-plan-table__checkbox-container">
              <input
                type="checkbox"
                id={`${item}${idHash}`}
                className="treatment-plan-table__checkbox"
                {...{ checked: data.instructions.includes(item) }}
                onClick={() =>
                  onChange({
                    instructions: data.instructions.includes(item)
                      ? data.instructions.filter(
                          (instruction) => instruction !== item
                        )
                      : data.instructions.concat([item]),
                  })
                }
              />
              <label htmlFor={`${item}${idHash}`}>{name}</label>
            </div>
          ))}
        </div>
      </td>
      <td>
        <textarea
          className="treatment-plan-table__textarea"
          defaultValue={data.specialInstructions || ""}
          value={data.specialInstructions || ""}
          placeholder="Put special instructions here..."
          rows={2}
          onChange={(event) =>
            onChange({
              specialInstructions: event.target.value,
            })
          }
        />
      </td>
      <td>
        <div className="treatment-plan-table__table-cell--centered">
          <input
            style={{ minWidth: "30px", border: "none" }}
            type="number" // Set input type to number
            min={0} // Minimum allowed value
            max={9} // Maximum allowed value
            step={1} // Incremental steps
            value={data.refillsCount} // Current value
            onChange={(event) => {
              let value = parseInt(event.target.value, 10); // Parse value as integer with base 10
              // Check if the value is within the defined range
              /* eslint-disable no-restricted-globals */
              if (isNaN(value)) {
                // If the entered value is not a number, set it to the minimum value
                value = 0;
              } else if (value < 0) {
                // If the entered value is less than the minimum, set it to the minimum value
                value = 0;
              } else if (value > 9) {
                // If the entered value is greater than the maximum, set it to the maximum value
                value = 9;
              }
              onChange({
                refillsCount: value,
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="treatment-plan-table__table-cell--centered">
          <DatePicker
            locale="en-US"
            value={
              (data.refillExpirationDate &&
                new Date(data.refillExpirationDate)) ||
              null
            }
            minDate={new Date()}
            onChange={(value: any) => {
              const date = value as Date;
              onChange({
                refillExpirationDate: date ? date.getTime() : null,
              });
            }}
          />
        </div>
      </td>

      <td>
        <button
          type="button"
          onClick={() => handleTableRowsDeleting(data?.medicineKey)}
          style={{ border: "none", cursor: "pointer" }}
        >
          <RemoveButtonIcon />
        </button>
      </td>
    </tr>
  );
}
