import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button } from "../../Common/Button";
import { TextInput } from "../../Common/TextInput";

function CustomPharmacyDetails({
  onNext,
  onBack,
  setLocalPharmacyDetails,
}: any) {
  const [formData, setFormData] = useState({
    pharmacyName: "",
    pharmacyAddress: "",
    zipCode: "",
    state: "",
    city: "",
  });

  const handleInput = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    setLocalPharmacyDetails(formData);
  }, [formData, setLocalPharmacyDetails]);

  const isFormValid = Object.values(formData).every(
    (value) => value.trim() !== ""
  );

  return (
    <div className="pharmacy-details-form">
      <div className="pharmacy-details-form__headings">
        <h2 className="pharmacy-details-form__question-heading">
          Local pharmacy details
        </h2>
        <h4 className="pharmacy-details-form__sub-heading">
          We will send your prescriptions to this pharmacy once ready.
        </h4>
      </div>
      <div className="pharmacy-details-form__pharmacy-details">
        <TextInput
          className="pharmacy-details-input"
          id="pharmacy-name"
          name="pharmacyName"
          value={formData.pharmacyName}
          onChange={(value) => handleInput("pharmacyName", value)}
          type="text"
          placeholder="Pharmacy name"
        />
        <TextInput
          className="pharmacy-details-input"
          id="pharmacy-address"
          name="pharmacyAddress"
          value={formData.pharmacyAddress}
          onChange={(value) => handleInput("pharmacyAddress", value)}
          type="text"
          placeholder="Pharmacy address"
        />
        <TextInput
          className="pharmacy-details-input"
          id="city"
          name="city"
          value={formData.city}
          onChange={(value) => handleInput("city", value)}
          type="text"
          placeholder="City"
        />
        <TextInput
          className="pharmacy-details-input"
          id="state"
          name="state"
          value={formData.state}
          onChange={(value) => handleInput("state", value)}
          type="text"
          placeholder="State"
        />
        <TextInput
          className="pharmacy-details-input"
          id="zip-code"
          name="zipCode"
          value={formData.zipCode}
          onChange={(value) => handleInput("zipCode", value)}
          type="text"
          placeholder="ZIP code"
        />
      </div>
      <div className="pharmacy-details-form__button">
        <Button
          text="CHANGE PHARMACY TYPE"
          size="small"
          view="secondary"
          onClick={onBack}
        />
        <Button
          text="SUBMIT"
          size="small"
          onClick={onNext}
          disabled={!isFormValid}
        />
      </div>
    </div>
  );
}

export default CustomPharmacyDetails;
