import React, { useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { RadioButtonsList } from "../../../../NewComponents/Common/RadioButtonsList";

const ANSWERS = {
  YES: true,
  NO: false,
};

const list = [
  {
    value: ANSWERS.YES,
    label: "Yes",
  },
  {
    value: ANSWERS.NO,
    label: "No",
  },
];

function surveyToComponent(data: SurveyData) {
  return data.depressionHistory;
}

function componentToSurvey(value: boolean) {
  return {
    depressionHistory: value,
  };
}

export function DepressionHistorySurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));

  function submit() {
    if (isEmpty(currentData)) return;

    onDone(componentToSurvey(currentData));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        Does anyone in your family have a history of depression?
      </p>
      <p className="survey-question__descr paragraph-font--color" />
      <RadioButtonsList
        list={list}
        currentValue={isEmpty(currentData) ? undefined : currentData}
        onChange={(value) => setData(value)}
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={isEmpty(currentData)}
      />
    </div>
  );
}
