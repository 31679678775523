import React, { useState, InputHTMLAttributes } from "react";
import { ReactComponent as CloseSearchIcon } from "../../../../Assets/NewIcons/close-search.svg";
import "./style.scss";

type InputType = React.DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props extends Omit<InputType, "disabled" | "onChange"> {
  disabled?: boolean;
  value?: string;
  id: string;
  name: string;
  hasError?: {
    error: boolean;
    errorMessage: string;
  };
  placeholder?: string;
  onChange: (value: string) => void;
  clearable?: boolean;
  ref?: any;
}

export function Input({
  disabled,
  value,
  onChange,
  id,
  name,
  placeholder,
  hasError,
  clearable = false,
  defaultValue,
  ...restProps
}: Props) {
  const [text, setText] = useState(value || defaultValue || "");
  const inputClassName = disabled
    ? "input-wrapper-disabled"
    : "input-wrapper-active";

  return (
    <div className="input-wrapper">
      <input
        className={`input-wrapper__field input-font ${inputClassName}`}
        type="text"
        onChange={(event) => {
          onChange(event.target.value.trim());
          setText(event.target.value);
        }}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        value={text}
        {...restProps}
      />
      {clearable ? (
        <CloseSearchIcon
          className="input-wrapper__close-icon"
          onClick={() => {
            onChange("");
            setText("");
          }}
        />
      ) : null}
      {hasError && hasError.error && (
        <div className="input-wrapper__error-message">
          {hasError.errorMessage}
        </div>
      )}
    </div>
  );
}
