import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const pauseIsotretinoin = async (
  userId: string,
  daysAmount: number
): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/pause/${userId}`,
      {
        daysAmount,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to pause isotretinoin");
    throw error;
  }
};
