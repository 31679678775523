import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { SkinSurvey } from "../../../../../types/CustomSurvey";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const submitSkinSurvey = async (
  data: SkinSurvey,
  skinImages: { timestamp: number; list: string[] }[]
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.put<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/update`,
      {
        ...data,
        skinImages,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot submit follow up");
    throw error;
  }
};
