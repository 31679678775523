import React, { useEffect, useState } from "react";
import "./style.scss";

interface TabsProps {
  items: Array<{
    label: string;
    key: string;
    children?: JSX.Element | string;
    counter?: number;
  }>;
  onChange?: (tab: string) => void;
  initialTab?: string;
  className?: string;
}

function getTabChildren(currentTab: any, items: any) {
  const tabChildren = items.find(
    (tab: any) => tab?.label === currentTab
  )?.children;

  if (tabChildren) {
    return <div className="tabs-wrapper__tab-content">{tabChildren}</div>;
  }

  return null;
}

export function Tabs({ initialTab, onChange, items, className }: TabsProps) {
  const [currentTab, setTab] = useState(initialTab || items[0].label);

  useEffect(() => {
    setTab(initialTab || items[0].label);
  }, [initialTab]);

  return (
    <div className={`tabs-wrapper ${className || ""}`}>
      <div className="tabs">
        {items.map((tab) =>
          tab.counter ? (
            <div
              className={`tabs__tab ${
                tab.label === currentTab ? "selected" : ""
              } hasCounter`}
              onClick={() => {
                setTab(tab.label);
                if (onChange) {
                  onChange(tab.label);
                }
              }}
              key={tab.key}
            >
              <p
                className={`tabs__tab-label ${
                  tab.label === currentTab ? "selected" : ""
                }`}
              >
                {tab.label}
              </p>
              <div className="tabs__tab-counter">{tab.counter}</div>
            </div>
          ) : (
            <div
              className={`tabs__tab ${
                tab.label === currentTab ? "selected" : ""
              }`}
              onClick={() => {
                setTab(tab.label);
                if (onChange) {
                  onChange(tab.label);
                }
              }}
              key={tab.key}
            >
              <p
                className={`tabs__tab-label ${
                  tab.label === currentTab ? "selected" : ""
                }`}
              >
                {tab.label}
              </p>
            </div>
          )
        )}
      </div>
      {getTabChildren(currentTab, items)}
    </div>
  );
}
