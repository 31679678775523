import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionState } from "../../../../features/session";
import {
  activate,
  deactivate,
  getTwilioState,
  setError,
} from "../../../../features/twilio";
import { TwilioController } from "../../../../services/twilio";

interface Props {
  children: (JSX.Element | null)[] | JSX.Element | null;
  identity: string;
  userRole: string;
}

// async function paginator<T>(payload: Promise<Paginator<T>>): Promise<T[]> {
//   const step = await payload;

//   if (step.hasNextPage) {
//     return [...step.items, ...(await paginator(step.nextPage()))];
//   }

//   return step.items;
// }

export function ChatLayer({ children, identity, userRole }: Props) {
  const dispatch = useDispatch();
  const { initialized } = useSelector(getTwilioState);

  useEffect(() => {
    (async () => {
      try {
        dispatch(deactivate());
        await TwilioController.loadConversations(identity, userRole);
        dispatch(activate());
      } catch {
        dispatch(setError());
      }
    })();
  }, [identity]);

  useEffect(() => {
    if (!initialized) {
      (async () => {
        try {
          await TwilioController.loadConversations(identity, userRole);
          dispatch(activate());
        } catch {
          dispatch(setError());
        }
      })();
    }

    return function clean() {
      TwilioController.clear();
      dispatch(deactivate());
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
