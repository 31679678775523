import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { ImagePayload } from "../../../../../types/Entities/Image";

const BASE_URL = `${ENV_CONFIG.AMAZON_API_DOMAIN}/images`;
const UPLOAD_IMAGES_ENDPOINT = `${BASE_URL}/upload`;

export const upload = async (payload: ImagePayload[]) => {
  try {
    await Promise.all(
      payload.map((image) => axios.post(UPLOAD_IMAGES_ENDPOINT, { image }))
    );
  } catch (error) {
    logError("Error occured. Cannot upload images", error);
    throw error;
  }
};
