import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import {
  BasicPopupTemplate,
  BasicPopupTemplateContext,
} from "../../Common/PopupLayer/basic";
import { Button } from "../../Common/Button";

function SubscriptionExpiredPopup() {
  const history = useHistory();
  const { onClose } = useContext(BasicPopupTemplateContext);

  const renderPopupContent = () => (
    <div className="subscription-expired-popup__container">
      <h2 className="subscription-expired-popup__title">
        Your subscription has expired
      </h2>
      <p className="subscription-expired-popup__description">
        To regain access to your care team, please renew your subscription
      </p>
      <div className="start-follow-up__btn-wrapper">
        <Button
          text="Continue"
          onClick={() => {
            onClose();
            history.push("/buy-membership");
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="subscription-expired-popup">{renderPopupContent()}</div>
  );
}

interface Props {
  onClose: () => void;
}

export function SubscriptionExpiredPopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <BasicPopupTemplate title="Buy subscription" onClose={onClose}>
        <SubscriptionExpiredPopup />
      </BasicPopupTemplate>
    );
  };
}
