import React, { useState } from "react";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { InfoBlock } from "../../../../NewComponents/Common/InfoBlock";
import { RadioButtonsList } from "../../../../NewComponents/Common/RadioButtonsList";
import { Stepper } from "../../../../NewComponents/Common/Stepper";
import { ProductsPicker } from "../../../../NewComponents/Patient/ProductsList";
import { LineItem, ProductData } from "../../../../../types/Base/Products";

export function ProductsSurveyPage({
  onDone,
  data,
  stepper: { max, current },
}: SurveyComponentProps) {
  const [lineItems, setLineItems] = useState<LineItem[] | null>(
    data.products || null
  );
  const [orderPurchaseChecked, setOrderPurchaseChecked] = useState(true);

  return (
    <div className="survey-question initiate-checkout-page__page">
      <Stepper max={max} current={current} />
      <p className="survey-question__title">Your non-prescription treatments</p>
      <p className="survey-question__descr paragraph-font--color">
        You can order your non-prescription treatments right from the Honeydew.
        Your prescription products will be sent to a pharmacy after you sign up.
      </p>
      <RadioButtonsList
        list={[
          {
            value: true,
            label: "Order from Honeydew",
          },
          {
            value: false,
            label: "Order separately",
          },
        ]}
        onChange={(value) => setOrderPurchaseChecked(value as boolean)}
        currentValue={orderPurchaseChecked}
        size="small"
        className="initiate-checkout-page__options"
      />
      {orderPurchaseChecked ? (
        <>
          {" "}
          <ProductsPicker
            onChange={(value) => setLineItems(value)}
            initialQuantityData={lineItems || undefined}
            className="initiate-checkout-page__products"
            disableSuggestions={false}
          />
          <InfoBlock
            text="Your prescription products will be sent to a pharmacy after you sign up."
            view="warn"
            className="initiate-checkout-page__warn"
          />
        </>
      ) : null}

      <div className="initiate-checkout-page__actions">
        <Button
          text="order"
          onClick={() => {
            if (!orderPurchaseChecked)
              onDone({
                products: undefined,
              });
            else
              onDone({
                products: lineItems,
              });
          }}
          className="initiate-checkout-page__submit-button"
          disabled={
            orderPurchaseChecked &&
            (!lineItems || lineItems.every(({ quantity }) => quantity < 0))
          }
        />
      </div>
    </div>
  );
}
