export const psoriasisSubQuestions = [
  {
    label: "How long have you suffered from psoriasis?",
    value: "psoriasisPeriod",
    questionType: "psoriasis",
    options: [
      {
        value: "Less than a year",
        label: "Less than a year",
      },
      {
        value: "1-5 years",
        label: "1-5 years",
      },
      {
        value: "5+ years",
        label: "5+ years",
      },
    ],
  },
  {
    label: "Did you ever have a skin biopsy of the psoriasis done?",
    value: "skinBiopsy",
    questionType: "psoriasis",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What areas of your body are covered with psoriasis?",
    value: "psoriasisAreas",
    questionType: "psoriasis",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Trunk",
        label: "Trunk",
      },
      {
        value: "Arms",
        label: "Arms",
      },
      {
        value: "Legs",
        label: "Legs",
      },
      {
        value: "Scalp",
        label: "Scalp",
      },
      {
        value: "Ears",
        label: "Ears",
      },
      {
        value: "Hands",
        label: "Hands",
      },
      {
        value: "Feet",
        label: "Feet",
      },
      {
        value: "Nails",
        label: "Nails",
      },
    ],
  },
  {
    label: "What areas of your hands are covered with Psoriasis?",
    value: "psoriasisHands",
    questionType: "psoriasis",
    parentQuestion: "psoriasisAreas",
    parentAnwser: "Hands",
    options: [
      {
        value: "Palms",
        label: "Palms",
      },
      {
        value: "Back of hands",
        label: "Back of hands",
      },
    ],
  },
  {
    label: "What areas of your feet are covered with Psoriasis?",
    value: "psoriasisFeet",
    questionType: "psoriasis",
    parentQuestion: "psoriasisAreas",
    parentAnwser: "Feet",
    options: [
      {
        value: "Soles",
        label: "Soles",
      },
      {
        value: "Top of feet",
        label: "Top of feet",
      },
    ],
  },
  {
    label: "Does your skin itch?",
    value: "itching",
    questionType: "psoriasis",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have a family history of Psoriasis?",
    value: "psoriasisFamilyHistory",
    questionType: "psoriasis",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have a personal history of obesity or heart disease?",
    value: "obesityOrHeartDisease",
    questionType: "otherMedical",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have a personal history of arthritis/ joint pain?",
    value: "arthritisOrJointPain",
    questionType: "otherMedical",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Do you have a recent or chronic history of strep throat infections?",
    value: "strepThroatInfections",
    questionType: "otherMedical",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What kind of products have you tried for psoriasis in the past?",
    value: "previousPsoriasisProductsTaken",
    questionType: "psoriasis",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Over-the-counter products (e.g. cleansers or moisturizers)",
        label: "Over-the-counter products (e.g. cleansers or moisturizers)",
      },
      {
        value: "Prescription creams",
        label: "Prescription creams",
      },
      {
        value: "Prescription pills",
        label: "Prescription pills",
      },
      {
        value: "Prescription injectables",
        label: "Prescription injectables",
      },
      {
        value: "I haven’t tried any products",
        label: "I haven’t tried any products",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What over the counter topical medications have you tried?",
    value: "otcTopicalMedications",
    questionType: "psoriasis",
    parentQuestion: "previousPsoriasisProductsTaken",
    parentAnswer: "Over-the-counter products (e.g. cleansers or moisturizers)",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Moisturizers",
        label: "Moisturizers",
      },
      {
        value: "Hydrocortisone creams",
        label: "Hydrocortisone creams",
      },
      {
        value: "Vitamins",
        label: "Vitamins",
      },
      {
        value: "Supplements",
        label: "Supplements",
      },
      {
        value: "Antihistamines (for itch)",
        label: "Antihistamines (for itch)",
      },
    ],
  },
  {
    label: "What prescription creams have you tried?",
    value: "psoriasisPrescriptionCreams",
    questionType: "psoriasis",
    parentQuestion: "previousPsoriasisProductsTaken",
    parentAnswer: "Prescription creams",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value:
          "Steroids (Halobetasol, Clobetasol, Triamcinolone, Mometasone, Desonide, Aclometasone, Hydrocortisone)",
        label:
          "Steroids (Halobetasol, Clobetasol, Triamcinolone, Mometasone, Desonide, Aclometasone, Hydrocortisone)",
      },
      {
        value: "Other steroid",
        label: "Other steroid",
      },
      {
        value:
          "Tacrolimus, Pimecrolimus, Zoryve, Vtama, Duobrii, Tazorac, Taclonex, Vit D analogues/ Calcipotriene ( Dovonex), anthralin, coal tar",
        label:
          "Tacrolimus, Pimecrolimus, Zoryve, Vtama, Duobrii, Tazorac, Taclonex, Vit D analogues/ Calcipotriene ( Dovonex), anthralin, coal tar",
      },
    ],
  },
  {
    label: "What prescription oral medications have you tried?",
    value: "psoriasisPrescriptionOralMedications",
    questionType: "psoriasis",
    parentQuestion: "previousPsoriasisProductsTaken",
    parentAnswer: "Prescription pills",
    isArrayOfAnswer: true,
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Methotrexate",
        label: "Methotrexate",
      },
      {
        value: "Cyclosporine",
        label: "Cyclosporine",
      },
      {
        value: "Otezla",
        label: "Otezla",
      },
      {
        value: "Sotyktu",
        label: "Sotyktu",
      },
      {
        value: "Acitretin",
        label: "Acitretin",
      },
    ],
  },
  {
    label: "What prescription injectables have you tried?",
    value: "psoriasisPrescriptionInjectables",
    questionType: "psoriasis",
    parentQuestion: "previousPsoriasisProductsTaken",
    parentAnswer: "Prescription injectables",
    isArrayOfAnswer: true,
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Enbrel",
        label: "Enbrel",
      },
      {
        value: "Humira",
        label: "Humira",
      },
      {
        value: "Stelara",
        label: "Stelara",
      },
      {
        value: "Tremfya",
        label: "Tremfya",
      },
      {
        value: "Cosentyx",
        label: "Cosentyx",
      },
      {
        value: "Skyrizi",
        label: "Skyrizi",
      },
      {
        value: "Ilumya",
        label: "Ilumya",
      },
      {
        value: "Cimzia",
        label: "Cimzia",
      },
      {
        value: "Siliq",
        label: "Siliq",
      },
      {
        value: "Taltz",
        label: "Taltz",
      },
      {
        value: "Simponi",
        label: "Simponi",
      },
      {
        value: "Remicade",
        label: "Remicade",
      },
      {
        value: "Orencia",
        label: "Orencia",
      },
    ],
  },
  {
    label: "Are you currently using any prescription products for psoriasis?",
    value: "psoriasisCurrentlyUsingPrescriptionProducts",
    questionType: "psoriasis",
    parentQuestion: "previousPsoriasisProductsTaken",
    parentTextAnwser: "I haven’t tried any products",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are there specific medications you are interested in?",
    value: "psoriasisSpecificMedicationsInterest",
    questionType: "psoriasis",
    parentQuestion: "previousPsoriasisProductsTaken",
    parentTextAnwser: "I haven’t tried any products",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Please list your medications:",
  //   value: "medications",
  //   questionType: "otherMedical",
  //   parentQuestion: "otherMedications",
  //   parentAnwser: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value:
  //         "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
  //       label:
  //         "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
  //     },
  //   ],
  // },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    placeholder: "Which allergies",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Is there anything else we should know? (e.g. other medical conditions)",
    value: "otherInformation",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "e.g. other medical conditions",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
];
