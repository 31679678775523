/* eslint-disable @typescript-eslint/no-explicit-any */

import { MODE } from "../constants";

/* eslint-disable no-console */

const isEnabled =
  MODE !== "PROD" || window.localStorage.getItem("acneaway_logs_enabled");

const createMessage = (msg: string, additionalInfo?: any): string =>
  `${msg} ${additionalInfo ? JSON.stringify(additionalInfo, null, 2) : ""}`;

export const logInfo = (msg: string, additionalInfo?: any): void => {
  if (!isEnabled) return;
  console.log(createMessage(msg, additionalInfo));
};

export const logWarn = (msg: string, additionalInfo?: any): void => {
  if (!isEnabled) return;
  console.warn(createMessage(msg, additionalInfo));
};

export const logError = (msg: string, additionalInfo?: any): void => {
  if (!isEnabled) return;
  console.error(msg, additionalInfo);
};
