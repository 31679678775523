import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import "./style.scss";
import { Loader } from "../../Common/Loader";
import { InfoBlock } from "../../Common/InfoBlock";
import { TextInput } from "../../Common/TextInput";
import { useGetReferralLinkQuery } from "../../../../features/api/referral";

interface Props {
  onClose: () => void;
}

function ReferralLinkPopup({ patientId }: { patientId: string }) {
  const [isCopied, setCopied] = useState(false);
  const { data, isLoading, isSuccess, isError } =
    useGetReferralLinkQuery(patientId);

  function renderState() {
    if (isLoading) return <Loader className="referral-link-popup__loader" />;
    if (isSuccess)
      return (
        <CopyToClipboard text={data} onCopy={() => setCopied(true)}>
          <div
            className="referral-link-popup__tooltip"
            data-tooltip={isCopied ? "Copied!" : undefined}
          >
            <TextInput
              id="referral-link"
              name="referral-link"
              value={data}
              onChange={() => false}
            />
          </div>
        </CopyToClipboard>
      );
    if (isError)
      return (
        <InfoBlock
          text="Something went wrong. Please, try again later"
          view="error"
        />
      );
    return null;
  }

  return (
    <div className="referral-link-popup">
      <div className="referral-link-popup__body">
        <p className="referral-link-popup__title">Here's your referral link</p>
        <p className="paragraph-font paragraph-font--color">
          Click to copy and share with your friends!
        </p>
        <div className="referral-link-popup__content">{renderState()}</div>
      </div>
    </div>
  );
}

export function ReferralLinkPopupGenerator({
  patientId,
}: {
  patientId: string;
}) {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <ReferralLinkPopup patientId={patientId} />
      </HelpPopupTemplate>
    );
  };
}
