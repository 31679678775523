import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../../Assets/NewIcons/search.svg";
import { ReactComponent as CloseSearchIcon } from "../../../../Assets/NewIcons/close-search.svg";
import "./style.scss";
import { debounce } from "../../../../utils/debounce";

interface Props {
  className?: string;
  disabled?: boolean;
  value?: string;
  id: string;
  name: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

const useDebounce = (callback: () => void) => {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);

  return debouncedCallback;
};

export function SearchInput({
  className,
  disabled,
  value,
  onChange,
  id,
  name,
  placeholder,
}: Props) {
  const [text, setText] = useState(value || "");
  const onChangeDebounced = useDebounce(() => {
    onChange(text.trim());
  });

  return (
    <div className={`search-input ${className}`}>
      <SearchIcon className="search-input__icon" />
      <input
        className="search-input__field input-font input-font--color"
        type="text"
        onChange={(event) => {
          setText(event.target.value);
          onChangeDebounced();
        }}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        value={text}
      />
      {text ? (
        <CloseSearchIcon
          className="search-input__close-icon"
          onClick={() => {
            setText("");
            onChange("");
          }}
        />
      ) : null}
    </div>
  );
}
