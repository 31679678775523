import React, { useContext, useEffect, useState } from "react";
import JoiPhoneNumber from "joi-phone-number";
import Joi from "joi";
import Select from "react-select";
import { Column } from "react-table";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import "./style.scss";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { Button } from "../../Common/Button";
import { TextInput } from "../../Common/TextInput";
import { EMPLOYEE_ROLES, Employee, Provider } from "../../../../types/Employee";
import { USER_ROLES } from "../../../../types/Main";
import {
  useCreateEmployeeMutation,
  useUpdateEmployeeCognitoEmailMutation,
  useUpdateEmployeeMutation,
} from "../../../../features/api/employees";
import { getFullType } from "../../../../utils/get-full-type";
import { isEmpty } from "../../../../utils/is-empty";
import { ReactComponent as DeleteIcon } from "../../../../Assets/icons/trash.svg";
// import { ReactComponent as DeleteIcon } from "../../../../Assets/icons/delete-svgrepo-com.svg";
// import { ReactComponent as EditIcon } from "../../../../Assets/icons/edit.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/pencil.svg";
import { RadioButtonsForm } from "../../Common/RadioButtonsForm";
import Table from "../TableComponent";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { TimeOffPopupGenerator } from "../../../Pages/Administrator/TimeOffPopupGenerator/index";

interface GeneratorProps {
  onClose: () => void;
}

interface EmployeePopupProps {
  employee?: Employee;
  role?: EMPLOYEE_ROLES;
  onClose: () => void;
}

interface EmployeePopupSectionProps {
  title: string;
  description: string;
  fields: any[];
}

// todo extract to separate component, find same name in code
export function EmployeePopupSection({
  title,
  description,
  fields,
}: EmployeePopupSectionProps) {
  return (
    <div className="edit-patient-details-popup__content__section">
      <div className="edit-patient-details-popup__content__section__head">
        <p className="edit-patient-details-popup__content__section__head__title">
          {title}
        </p>
        <p className="edit-patient-details-popup__content__section__head__description">
          {description}
        </p>
      </div>
      <div className="edit-patient-details-popup__content__section__fields">
        {fields.map((field, index) => (
          <div
            className="edit-patient-details-popup__content__section__fields__field"
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${title}__${description}__${index}`}
          >
            {field}
          </div>
          /* eslint-enable no-restricted-syntax */
        ))}
      </div>
    </div>
  );
}

function EmployeePopup({ employee, role, onClose }: EmployeePopupProps) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const { showPopup } = useContext(PopupLayerContext);

  const isCreation = !employee;
  const isProvider =
    employee?.role === USER_ROLES.PROVIDER || role === USER_ROLES.PROVIDER;
  const [formValues, setFormField] = useState({
    firstName: employee?.firstName || "",
    lastName: employee?.lastName || "",
    phone: employee?.phone || "+1",
    email: employee?.email || undefined,
    stateLicenses: (employee as Provider)?.stateLicenses || [],
    npiNumber: (employee as Provider)?.npiNumber || "",
    isTaggable: (employee as Provider)?.isTaggable,
    canCoverTemporaryPatients:
      (employee as Provider)?.canCoverTemporaryPatients || false,
    detachedFromNewPatients:
      (employee as Provider)?.detachedFromNewPatients || false,
    title: (employee as Provider)?.title || undefined,
    presignedBloodSlipId:
      (employee as Provider)?.presignedBloodSlipId || undefined,
  });
  const [isFirstNameModified, setFirstNameModified] = useState(false);
  const [isLastNameModified, setLastNameModified] = useState(false);
  const [isTitleModified, setTitleModified] = useState(false);
  const [isPhoneModified, setPhoneModified] = useState(false);
  const [isEmailModified, setEmailModified] = useState(false);
  const [isLabslipIdModified, setLabslipModified] = useState(false);
  const [isNpiNumberModified, setIsNpiNumberModified] = useState(false);
  const [isTaggableModified, setIsTaggableModified] = useState(false);
  const [
    isDetachedFromNewPatientsModified,
    setIsDetachedFromNewPatientsModified,
  ] = useState(false);
  const [
    isCoverTemporaryPatientsModified,
    setIsCoverTemporaryPatientsModified,
  ] = useState(false);

  const [isFieldAdded, setIsFieldAdded] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    npiNumber: !isProvider,
  });
  const [licenseNotAdded, setLicenseNotAdded] = useState(true);

  const [isLicensesModified, setIsLicensesModified] = useState(false);

  const [lastFirstName, setlastFistName] = useState(
    formValues?.firstName ?? ""
  );
  const [lastLastName, setLastLastName] = useState(formValues?.lastName ?? "");
  const [lastTitle, setLastTitle] = useState(formValues?.title ?? "");
  const [lastPhone, setlastPhone] = useState(formValues?.phone ?? "");
  const [lastEmail, setLastEmail] = useState(formValues?.email ?? "");
  const [lastNpiNumber, setLastNpiNumber] = useState(
    formValues?.npiNumber ?? ""
  );
  const [lastLabslipId, setLastLabslipId] = useState(
    formValues?.presignedBloodSlipId ?? ""
  );
  const [lastLicenses, setLastLicenses] = useState(
    formValues?.stateLicenses ?? ""
  );

  const [createEmployee] = useCreateEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [formErrors, setError] = useState<Record<string, string>>({});
  const [isShowLicenseError, setIsShowLicenseError] = useState(false);

  const [updateEmployeeCognitoEmail] = useUpdateEmployeeCognitoEmailMutation();

  useEffect(() => {
    if (!isCreation) {
      if (employee.isTaggable == null) {
        setFormField({
          ...formValues,
          isTaggable: true,
        });
      } else {
        setFormField({
          ...formValues,
          isTaggable: (employee as Provider).isTaggable,
        });
      }
    }
  }, [employee]);

  useEffect(() => {
    setError({});
  }, [formValues]);

  const handleFormError = (fieldName: string, errorMessage: string) => {
    setError((prevState) => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    if (formValues.firstName.trim().length === 0) {
      handleFormError("firstName", "Required field");
      isValid = false;
    } else if (/^[A-Za-z'-]{1,}$/.test(formValues.firstName.trim()) === false) {
      handleFormError("firstName", "Only alphabetical characters are allowed");
      isValid = false;
    }
    // else if (formValues.firstName.trim().length > 40) {
    //   handleFormError("firstName", "Should not exceed 40 characters");
    //   isValid = false;
    // }

    if (formValues.lastName.trim().length === 0) {
      handleFormError("lastName", "Required field");
      isValid = false;
    } else if (/^[A-Za-z'-]{1,}$/.test(formValues.lastName.trim()) === false) {
      handleFormError("lastName", "Only alphabetical characters are allowed");
      isValid = false;
    }
    // else if (formValues.lastName.trim().length > 40) {
    //   handleFormError("lastName", "Should not exceed 40 characters");
    //   isValid = false;
    // }

    const phone = formValues.phone.replaceAll(/[^+0-9]/g, "");
    // License Title Validation

    if (
      Joi.extend(JoiPhoneNumber)
        .string()
        .phoneNumber({
          strict: true,
          format: "e164",
        })
        .validate(phone).error
    ) {
      handleFormError("phone", "Invalid phone number");
      isValid = false;
    }
    if (isCreation) {
      const { error } = Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .validate(formValues.email);

      if (error) {
        handleFormError("email", "Invalid email");
        isValid = false;
      }
    }

    if (isProvider) {
      if (!formValues.title || formValues.title?.trim().length === 0) {
        handleFormError("title", "Required field");
        isValid = false;
      } else if (!/^[A-Z]{1,}$/.test(formValues.title?.trim())) {
        handleFormError("title", "Only alphabetical characters are allowed");
        isValid = false;
      } else if ((formValues.title?.trim().length || 0) < 2) {
        handleFormError("title", "Should be atleast two characters");
        isValid = false;
      } else if ((formValues.title?.trim().length || 0) > 2) {
        handleFormError("title", "Should not exceed two characters");
        isValid = false;
      } else if (!/^MD|DO|NP|PA$/.test(formValues.title?.trim())) {
        handleFormError("title", "Title must be either MD | DO | NP | PA");
        isValid = false;
      }

      if (
        formValues.presignedBloodSlipId &&
        !/^[A-Za-z0-9]+$/.test(formValues.presignedBloodSlipId)
      ) {
        handleFormError(
          "labslipId",
          "ID should contain only alphanumeric symbols"
        );
        isValid = false;
      }
    }

    return isValid;
  };
  const options = [
    { label: "DO (Doctor of Osteopathic Medicine)", value: "DO" },
    { label: "MD (Doctor of Medicine)", value: "MD" },
    { label: "PA (Physician Assistant)", value: "PA" },
    { label: "NP (Nurse Practitioner)", value: "NP" },
  ];
  function arraysOfObjectsAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort arrays based on a unique property to ensure consistent ordering
    const sortedArr1 = arr1.slice().sort((a, b) => a.id - b.id);
    const sortedArr2 = arr2.slice().sort((a, b) => a.id - b.id);

    // Compare each object in the arrays
    for (let i = 0; i < sortedArr1.length; i++) {
      const obj1 = sortedArr1[i];
      const obj2 = sortedArr2[i];
      /* eslint-disable no-restricted-syntax */
      // Compare each property of the objects
      for (const key in obj1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
      /* eslint-enable no-restricted-syntax */
    }

    return true;
  }

  const handleChange = (selectedOption) => {
    const value = selectedOption?.value?.toUpperCase();

    setTitleModified(value !== lastTitle);
    setLicenseNotAdded(false);
    setFormField({
      ...formValues,
      title: value,
    });
  };

  const onSaveChangesHandler = async () => {
    const validationResult = validateForm();
    if (!validationResult) {
      return;
    }

    const payload = {
      ...formValues,
    };
    if (!isProvider) {
      delete payload.title;
      delete payload.npiNumber;
    }
    if (!isCreation && isEmailModified) {
      const cognitoResponse = await updateEmployeeCognitoEmail({
        userId: employee?.id,
        userName: employee?.id,
        newEmail: payload.email,
      });
      if (!cognitoResponse?.data?.success) {
        showError({
          title: "Something went wrong...",
          description: `Email cannot be updated on cognito`,
        });
        return;
      }
    }
    const response: any = isCreation
      ? await createEmployee({
          ...payload,
          role,
        })
      : await updateEmployee({
          employeeId: employee.id,
          payload,
        });
    if (response.error) {
      showError({
        title: "Something went wrong...",
        description: `${response.error.message}`,
      });
      return;
    }

    showSuccess({
      title: "Success!",
      description: "Employee form has been submitted successfully",
    });
    onClose();
  };
  const handleAddStateLicense = () => {
    const { stateLicenses } = formValues;

    // Check if stateLicenses array is empty or the last license is incomplete
    if (
      stateLicenses.length === 0 ||
      stateLicenses[stateLicenses.length - 1].license ||
      stateLicenses[stateLicenses.length - 1].expiry ||
      stateLicenses[stateLicenses.length - 1].state
    ) {
      const newLicense = { license: "", expiry: "", state: "" };
      setFormField((prevValues) => ({
        ...prevValues,
        stateLicenses: [...prevValues.stateLicenses, newLicense],
      }));
      setIsShowLicenseError(false);
    } else {
      setIsShowLicenseError(true);
    }
    setIsLicensesModified(
      arraysOfObjectsAreEqual(lastLicenses, formValues.stateLicenses)
    );
  };

  const handleStateChange = (index, field, value) => {
    // Make a copy of the stateLicenses array
    const updatedLicenses = [...formValues.stateLicenses];

    // Update the specified field value for the license at the specified index
    updatedLicenses[index] = {
      ...updatedLicenses[index],
      [field]: value,
    };

    // Update the form field with the modified stateLicenses array
    setFormField((prevValues) => ({
      ...prevValues,
      stateLicenses: updatedLicenses,
    }));

    // Check if both license and expiry are set for the license at the specified index
    const isLicenseEmpty = !updatedLicenses[index].license;
    const isExpiryEmpty = !updatedLicenses[index].expiry;
    const isStateEmpty = !updatedLicenses[index].state;
    setIsShowLicenseError(isLicenseEmpty || isExpiryEmpty || isStateEmpty);

    // Check if the licenses have been modified
    setIsLicensesModified(
      !arraysOfObjectsAreEqual(lastLicenses, formValues.stateLicenses)
    );
  };
  const handleStateLicenseStateChange = (index, value) => {
    handleStateChange(index, "state", value);
  };

  const handleStateLicenseChange = (index, value) => {
    handleStateChange(index, "license", value);
  };

  const handleStateLicenseExpiryChange = (index, value) => {
    handleStateChange(index, "expiry", value);
  };

  const handleRemoveStateLicense = (index) => {
    const updatedLicenses = [...formValues.stateLicenses];
    updatedLicenses.splice(index, 1);
    setFormField((prevValues) => ({
      ...prevValues,
      stateLicenses: updatedLicenses,
    }));

    // Check if all licenses have both expiry and license
    const allLicensesValid = updatedLicenses.every(
      (license) => license.expiry && license.license
    );
    setIsShowLicenseError(!allLicensesValid);
    setIsLicensesModified(
      arraysOfObjectsAreEqual(lastLicenses, formValues.stateLicenses)
    );
  };

  const handleButtonDisable = () => {
    const areAllFieldsAdded = Object.values(isFieldAdded).every(
      (value) => value
    );

    if (isCreation) {
      if (isProvider) {
        return licenseNotAdded || !areAllFieldsAdded;
      }
      return !areAllFieldsAdded;
    }
    return !(
      isFirstNameModified ||
      isLastNameModified ||
      isTitleModified ||
      isPhoneModified ||
      isEmailModified ||
      isLabslipIdModified ||
      isNpiNumberModified ||
      isTaggableModified ||
      isCoverTemporaryPatientsModified ||
      isDetachedFromNewPatientsModified ||
      isLicensesModified
    );
  };

  return (
    <div className="edit-patient-details-popup">
      <Button
        text="submit"
        className="edit-patient-details-popup__save-button"
        size="small"
        disabled={handleButtonDisable()}
        onClick={onSaveChangesHandler}
      />
      <div className="edit-patient-details-popup__content">
        <EmployeePopupSection
          title={`${employee?.role || role} details`}
          description=""
          fields={[
            <TextInput
              id="first-name"
              error={formErrors?.firstName ?? null}
              name="firstName"
              label="First name"
              value={formValues?.firstName}
              onChange={(value) => {
                setIsFieldAdded({
                  ...isFieldAdded,
                  firstName: value !== lastFirstName,
                });
                setFirstNameModified(value !== lastFirstName);
                setFormField({
                  ...formValues,
                  firstName: value,
                });
              }}
            />,
            <TextInput
              id="last-name"
              error={formErrors?.lastName ?? null}
              name="lastName"
              label="Last name"
              value={formValues.lastName}
              onChange={(value) => {
                setIsFieldAdded({
                  ...isFieldAdded,
                  lastName: value !== lastLastName,
                });

                setLastNameModified(value !== lastLastName);
                setFormField({
                  ...formValues,
                  lastName: value,
                });
              }}
            />,
            <TextInput
              id="phone"
              error={formErrors?.phone ?? null}
              name="phone"
              label="phone"
              value={formValues.phone}
              onChange={(value) => {
                setIsFieldAdded({
                  ...isFieldAdded,
                  phone: value !== lastPhone,
                });

                setPhoneModified(value !== "" && value !== lastPhone);
                setFormField({
                  ...formValues,
                  phone: value,
                });
              }}
            />,
            // ...(isCreation
            //   ? [
            <TextInput
              id="email"
              error={formErrors?.email ?? null}
              name="email"
              label="email"
              value={formValues.email}
              onChange={(value) => {
                setIsFieldAdded({
                  ...isFieldAdded,
                  email: value !== lastEmail,
                });
                setEmailModified(value !== lastEmail);
                setFormField({
                  ...formValues,
                  email: value,
                });
              }}
            />,
            //   ]
            // : []),
            ...(isProvider
              ? [
                  <Select
                    placeholder="Select License Initials"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: "50px",
                        height: "45px",
                        paddingLeft: "10px",
                      }),
                    }}
                    id="title"
                    name="title"
                    value={options.find(
                      (option) => option.value === formValues.title
                    )}
                    onChange={handleChange}
                    options={options}
                  />,
                  <TextInput
                    id="labslipId"
                    error={formErrors?.labslipId ?? null}
                    name="labslipId"
                    label="Lab Slip Template ID"
                    value={formValues.presignedBloodSlipId}
                    onChange={(value) => {
                      setLabslipModified(value !== lastLabslipId);
                      setFormField({
                        ...formValues,
                        presignedBloodSlipId: value,
                      });
                    }}
                  />,
                ]
              : []),
            ...(isProvider
              ? [
                  <TextInput
                    id="npiNumber"
                    error={formErrors?.npiNumber ?? null}
                    name="npiNumber"
                    label="NPI Number"
                    value={formValues.npiNumber}
                    onChange={(value) => {
                      setIsFieldAdded({
                        ...isFieldAdded,
                        npiNumber: value !== lastNpiNumber,
                      });
                      setIsNpiNumberModified(value !== lastNpiNumber);
                      setFormField({
                        ...formValues,
                        npiNumber: value,
                      });
                    }}
                  />,
                ]
              : []),
            <RadioButtonsForm
              heading="Taggable"
              customStyle
              list={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              onChange={(value) => {
                setIsTaggableModified(true);
                setFormField({ ...formValues, isTaggable: value });
              }}
              currentValue={formValues.isTaggable}
            />,
            <RadioButtonsForm
              heading="PREVENT NEW PATIENT ASSIGNMENTS"
              customStyle
              list={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              onChange={(value) => {
                setIsDetachedFromNewPatientsModified(true);
                setFormField({ ...formValues, detachedFromNewPatients: value });
              }}
              currentValue={formValues.detachedFromNewPatients}
            />,
            <RadioButtonsForm
              heading="Can cover temporary patients?"
              customStyle
              list={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              onChange={(value) => {
                setIsCoverTemporaryPatientsModified(true);
                setFormField({
                  ...formValues,
                  canCoverTemporaryPatients: value,
                });
              }}
              currentValue={formValues.canCoverTemporaryPatients}
            />,
            ...(isProvider
              ? [
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={isShowLicenseError}
                      view="secondary"
                      text="Add State License"
                      className="edit-patient-details-popup__add-license-button"
                      size="small"
                      onClick={() => handleAddStateLicense()}
                    />
                    {isShowLicenseError && (
                      <p style={{ color: "red" }}>
                        *Please fill out all fields.
                      </p>
                    )}
                  </div>,
                ]
              : []),
            !isEmpty(formValues?.stateLicenses) &&
              formValues?.stateLicenses.map((license, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                    alignItems: "center",
                    width: "100%",
                  }}
                  className="edit-patient-details-popup__state-license-row"
                >
                  <span style={{ marginRight: "10px", width: "100%" }}>
                    <TextInput
                      id={`state_${license.state}`}
                      // error={formErrors?.licenseExpiry ?? null}
                      label="State"
                      placeholder="Enter state here"
                      value={license.state}
                      onChange={(value) =>
                        handleStateLicenseStateChange(index, value)
                      }
                    />
                  </span>
                  <span style={{ marginRight: "10px", width: "100%" }}>
                    <TextInput
                      placeholder="License title here"
                      id={`license_${license.license}_1`}
                      label={`License# (${index + 1})`}
                      // error={formErrors?.licenseTitle ?? null}
                      value={license.license}
                      onChange={(value) =>
                        handleStateLicenseChange(index, value)
                      }
                    />
                  </span>
                  <TextInput
                    id={`expiry_${license.expiry}`}
                    // error={formErrors?.licenseExpiry ?? null}
                    label="Expiration Date"
                    placeholder="MM/DD/YYYY"
                    value={license.expiry}
                    onChange={(value) =>
                      handleStateLicenseExpiryChange(index, value)
                    }
                  />
                  <div
                    style={{
                      height: "3px",
                      width: "50px",
                      cursor: "pointer",
                      marginInline: "10px",
                    }}
                    className="edit-patient-details-popup__delete-license-icon"
                    onClick={() => handleRemoveStateLicense(index)}
                  >
                    <DeleteIcon
                      style={{
                        fill: "#f75959",
                      }}
                    />
                  </div>
                </div>
              )),
          ]}
        />
      </div>
    </div>
  );
}

export function EmployeePopupGenerator(
  employee?: Employee,
  role?: EMPLOYEE_ROLES
) {
  return function render({ onClose }: GeneratorProps) {
    return (
      <ActionPopupTemplate
        title={
          employee
            ? `Edit ${getFullType(employee.role)}`
            : `Create ${role && getFullType(role)}`
        }
        onClose={onClose}
      >
        <EmployeePopup employee={employee} role={role} onClose={onClose} />
      </ActionPopupTemplate>
    );
  };
}
