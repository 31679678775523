import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export interface DocumentImageFileProps {
  filename: string;
  contentType: string;
  source: string;
}

interface Document extends DocumentImageFileProps {
  title: string | null; // todo should not be nullable
  uploadedBy: {
    id: string | null;
    name: string | null;
  };
  assignedTo?: {
    id: string;
    name: string;
    role: string;
  } | null;
  assignedBy?: {
    id: string;
    role: string;
  };
  signedBy?: {
    id: string;
    name: string;
    role: string;
    createdAt: string;
  };
  taskName: string | null;
  comment: string;
  type: string;
}

export const uploadDocument = async (patientId: string, document: Document) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/upload-document/${patientId}`,
      {
        document,
      }
    );

    return result.data;
  } catch (error) {
    logError("Error received when trying to upload document");
    throw error;
  }
};
