import { MedicalBackground, Patient } from "../../types/Entities/Patient";
import { FollowUp } from "../../types/FollowUp";

export function getLastFolloUpAnswer(
  patient: Patient,
  followUps: FollowUp[],
  questionName: string
) {
  const lastFollowUp = followUps[0];
  const skinSurvey = patient.medicalBackground?.skinSurvey;

  if (!skinSurvey) {
    throw new Error(
      `Unable to get previous answer for follow-up question "${questionName}": no medical background found`
    );
  }

  if (lastFollowUp.questionnaire) {
    return lastFollowUp.questionnaire?.find(({ id }) => id === questionName)
      ?.value;
  }
  return skinSurvey.find(({ id }) => id === questionName)?.value;
}

export function getGenericLifestyleQuestion(question) {
  const lifestyleGenericQuestions = [
    {
      label: "In the last month, how would you describe your stress level?",
      value: "stressLevel",
      questionType: "lifestyle",
      options: [
        {
          value: "Never stressed",
          label: "Never stressed",
        },
        {
          value: "Almost never stressed",
          label: "Almost never stressed",
        },
        {
          value: "Sometimes stressed",
          label: "Sometimes stressed",
        },
        {
          value: "Fairly stressed",
          label: "Fairly stressed",
        },
        {
          value: "Very stressed",
          label: "Very stressed",
        },
      ],
    },
    {
      label: "In the last month, how would you describe your sleep?",
      value: "sleepAmount",
      questionType: "lifestyle",
      options: [
        {
          value: "5 hours or less",
          label: "5 hours or less",
        },
        {
          value: "Between 6-7 hours",
          label: "Between 6-7 hours",
        },
        {
          value: "8+ hours",
          label: "8+ hours",
        },
      ],
    },
    {
      label:
        "How often do you consume dairy (e.g. cheeses, yogurts, milk, butter)?",
      value: "dairyConsumption",
      questionType: "lifestyle",
      options: [
        {
          value: "Never",
          label: "Never",
        },
        {
          value: "A few times a day",
          label: "A few times a day",
        },
        {
          value: "A few times a week",
          label: "A few times a week",
        },
        {
          value: "A few times a month",
          label: "A few times a month",
        },
      ],
    },
  ];

  // Find and return the matching question object
  const matchingQuestion = lifestyleGenericQuestions.find(
    (q) => q.value === question
  );

  return matchingQuestion;
}
