import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  show as showErrorNotification,
  hide as hideErrorNotifcation,
} from "../../../../features/errorNotification";
import "./style.css";
import { AuthenticationService } from "../../../../services/cognito";
import { logError } from "../../../../shared/logger";
import { TextInput } from "../../../NewComponents/Common/TextInput";
import { SubmitButton } from "../../../NewComponents/Common/Button";

interface Props {
  verificationCode: string;
  username: string;
}

export function PasswordReset({ verificationCode, username }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatInvalid, setPasswordRepeatInvalid] = useState("");
  const [submitInProgress, setSubmitState] = useState(false);
  const [success, setSuccess] = useState(false);
  const email = username;

  const submitButtonStatus =
    // eslint-disable-next-line no-nested-ternary
    !password.length || !passwordRepeat.length
      ? "disabled"
      : submitInProgress
      ? "loading"
      : undefined;

  function validatePassword() {
    const lowercaseLettersRegexp = /[a-z]/;
    const numbersRegexp = /\d/;
    if (
      !lowercaseLettersRegexp.test(password) ||
      !numbersRegexp.test(password) ||
      password.length < 8
    ) {
      setPasswordInvalid(
        "Password should be at least 8 characters long, contain at least one lower case letter and one number"
      );
      return false;
    }

    return true;
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(hideErrorNotifcation());
    if (!validatePassword()) return;
    if (password !== passwordRepeat) {
      setPasswordRepeatInvalid("Passwords doesn't match");
      return;
    }

    setSubmitState(true);

    try {
      await AuthenticationService.resetPassword({
        email,
        password,
        verificationCode,
      });
      setSubmitState(false);
      setSuccess(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(showErrorNotification(e));
      setSubmitState(false);
    }
  };

  return success ? (
    <>
      <p className="logInContainer__title">Success!</p>
      <button
        type="button"
        className="logInContainer__submit"
        onClick={() => history.push("/")}
      >
        Back to log in
      </button>
    </>
  ) : (
    <>
      <p className="logInContainer__title">Password reset</p>
      <p className="logInContainer__description">
        Please create your new password. Your password must be at least 8
        characters long.
      </p>
      <form className="logInContainer__form" onSubmit={submit}>
        <div className="logInContainer__field-block">
          <TextInput
            disabled={submitInProgress}
            type="password"
            id="password"
            name="password"
            placeholder="Create password"
            required
            onChange={(value) => {
              setPassword(value);
              setPasswordInvalid("");
            }}
            error={passwordInvalid}
          />
        </div>
        <div className="logInContainer__field-block">
          <TextInput
            disabled={submitInProgress}
            type="password"
            id="repeatPassword"
            name="repeatPassword"
            placeholder="Re-enter password"
            required
            onChange={(value) => {
              setPasswordRepeat(value);
              setPasswordRepeatInvalid("");
            }}
            error={passwordRepeatInvalid}
          />
        </div>
        <SubmitButton text="Reset password" status={submitButtonStatus} />
      </form>
    </>
  );
}
