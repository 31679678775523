import React, { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { addCareCoordinator } from "../../../../features/careCoordinators";
import { addEnrollmentCoordinator } from "../../../../features/enrollmentCoordinators";
import { show } from "../../../../features/errorNotification";
import { addProvider } from "../../../../features/providers";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { CareCoordinator } from "../../../../types/Entities/CareCoordinator";
import { EnrollmentCoordinator } from "../../../../types/Entities/EnrollmentCoordinator";
import { Provider } from "../../../../types/Entities/Provider";
import "./style.css";

const types: { [p: string]: { value: string; friendly: string } } = {
  provider: {
    value: "provider",
    friendly: "Provider",
  },
  careCoordinator: {
    value: "careCoordinator",
    friendly: "Care Coordinator",
  },
  enrollmentCoordinator: {
    value: "enrollmentCoordinator",
    friendly: "Enrollment Coordinator",
  },
};

export function CreateEmployee() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState<string | null>(null);
  const [successPopup, setSuccess] = useState<{
    email: string;
    pwd: string;
  } | null>(null);
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  function getFormData() {
    switch (type) {
      case types.provider.value:
        return {
          title,
          firstName,
          lastName,
          phone,
          email,
        } as Provider;
      case types.careCoordinator.value:
        return {
          firstName,
          lastName,
          phone,
          email,
        } as CareCoordinator;
      case types.enrollmentCoordinator.value:
        return {
          firstName,
          lastName,
          phone,
          email,
        } as EnrollmentCoordinator;
      default:
        logError(`Unrecognized employee type "${type}"`);
        throw new Error(`Unrecognized employee type "${type}"`);
    }
  }

  async function create(
    data: Provider | CareCoordinator | EnrollmentCoordinator
  ) {
    switch (type) {
      case types.provider.value:
        return await AcneAwayAPI.providers.create(data as Provider);
      case types.careCoordinator.value:
        return await AcneAwayAPI.careCordinators.create(
          data as CareCoordinator
        );
      case types.enrollmentCoordinator.value:
        return await AcneAwayAPI.enrollmentCoordinators.create(
          data as EnrollmentCoordinator
        );
      default:
        throw new Error(`Unrecognized employee type "${type}:`);
    }
  }

  function addEmployeeToState(
    employee: Provider | CareCoordinator | EnrollmentCoordinator
  ) {
    switch (type) {
      case types.provider.value:
        return addProvider(employee as Provider);
      case types.careCoordinator.value:
        return addCareCoordinator(employee as CareCoordinator);
      case types.enrollmentCoordinator.value:
        return addEnrollmentCoordinator(employee as EnrollmentCoordinator);
      default:
        logError("Unrecognized employee type ", type);
        return null;
    }
  }

  async function submit(event: FormEvent) {
    try {
      event.preventDefault();
      const data: Provider | CareCoordinator | EnrollmentCoordinator =
        getFormData();
      const result = await create(data);
      setSuccess({
        email: result.employee.email,
        pwd: result.temporaryPassword,
      });
      dispatch(addEmployeeToState(result.employee));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let message;
      if ("response" in error) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }

      logError(message);
      dispatch(show(message));
    }
  }

  function drawFields() {
    switch (type) {
      case types.provider.value:
        return (
          <>
            <div className="create-employee__field-block">
              <label htmlFor="email" className="create-employee__label">
                E-mail
              </label>
              <input
                className="create-employee__field"
                id="email"
                onChange={(event) => setEmail(event.target.value)}
                name="email"
                value={email}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="phone" className="create-employee__label">
                Phone Number
              </label>
              <input
                className="create-employee__field"
                id="phone"
                onChange={(event) => setPhone(event.target.value)}
                name="phone"
                value={phone}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="title" className="create-employee__label">
                License Initials
              </label>
              <input
                className="create-employee__field"
                id="title"
                onChange={(event) => setTitle(event.target.value)}
                name="title"
                value={title}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="firstName" className="create-employee__label">
                First Name
              </label>
              <input
                className="create-employee__field"
                id="firstName"
                onChange={(event) => setFirstName(event.target.value)}
                name="firstName"
                value={firstName}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="lastName" className="create-employee__label">
                Last Name
              </label>
              <input
                className="create-employee__field"
                id="lastName"
                onChange={(event) => setLastName(event.target.value)}
                name="lastName"
                value={lastName}
              />
            </div>
          </>
        );
      case types.careCoordinator.value:
      case types.enrollmentCoordinator.value:
        return (
          <>
            <div className="create-employee__field-block">
              <label htmlFor="email" className="create-employee__label">
                E-mail
              </label>
              <input
                className="create-employee__field"
                id="email"
                onChange={(event) => setEmail(event.target.value)}
                name="email"
                value={email}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="phone" className="create-employee__label">
                Phone Number
              </label>
              <input
                className="create-employee__field"
                id="phone"
                onChange={(event) => setPhone(event.target.value)}
                name="phone"
                value={phone}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="firstName" className="create-employee__label">
                First Name
              </label>
              <input
                className="create-employee__field"
                id="firstName"
                onChange={(event) => setFirstName(event.target.value)}
                name="firstName"
                value={firstName}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="lastName" className="create-employee__label">
                Last Name
              </label>
              <input
                className="create-employee__field"
                id="lastName"
                onChange={(event) => setLastName(event.target.value)}
                name="lastName"
                value={lastName}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  }

  return successPopup && type ? (
    <div className="greeting-container">
      <div className="create-employee">
        <div className="create-employee__head">
          <p className="create-employee__title create-employee__title--center">
            {types[type].friendly} created successfully!
          </p>
        </div>
        <div className="create-employee__body create-employee__body--full-width">
          <p>
            E-mail:{" "}
            <span className="create-employee__text--bold">
              {successPopup.email}
            </span>
          </p>
          <p>
            Temporary Password:{" "}
            <span className="create-employee__text--bold">
              {successPopup.pwd}
            </span>
          </p>
        </div>
        <button
          type="button"
          className="create-employee__submit"
          onClick={() => history.push("/employees")}
        >
          Close
        </button>
      </div>
    </div>
  ) : (
    <div className="greeting-container">
      <div className="create-employee">
        <form className="create-employee__form" onSubmit={submit}>
          <div className="create-employee__head">
            <p className="create-employee__title">Create an Employee</p>
          </div>
          <div className="create-employee__body">
            <div className="create-employee__field-block">
              <label htmlFor="employeeType" className="create-employee__label">
                Type
              </label>
              <Select
                id="employeeType"
                options={Object.keys(types).map((key) => ({
                  value: types[key].value,
                  label: types[key].friendly,
                }))}
                onChange={(data) => setType(data?.value || null)}
              />
            </div>
            {drawFields()}
          </div>
          <input
            type="submit"
            name="submit"
            className="create-employee__submit"
          />
        </form>
      </div>
    </div>
  );
}
