import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import {
  TreatmentPlan,
  TreatmentPlanContent,
} from "../../../../../types/Entities/TreatmentPlan";
import { AuthenticationService } from "../../../../cognito";

export const create = async (data: TreatmentPlanContent) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<TreatmentPlan>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/treatment-plans/create`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot create treatment plan`);
    throw error;
  }
};
