import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hide,
  isShownSelector,
  textSelector,
} from "../../../../features/errorNotification";
import "./style.css";

export function ErrorNotificationPopup() {
  const dispatch = useDispatch();
  const isShown = useSelector(isShownSelector);
  const text = useSelector(textSelector);

  return isShown ? (
    <div className="error-notification-popup">
      <div className="error-notification-popup__heading">
        <p className="error-notification-popup__title">Error!</p>
        <button
          type="button"
          className="error-notification-popup__close-button"
          onClick={() => dispatch(hide())}
        />
      </div>
      <p className="error-notification-popup__description">{text}</p>
    </div>
  ) : null;
}
