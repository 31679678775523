export const newFollowUp = {
  bool: {
    must: [
      {
        exists: {
          field: "followUp",
        },
      },
      {
        match: {
          "followUp.isTreatmentPlanSet": false,
        },
      },
      {
        match: {
          "followUp.isInitialFollowUp": false,
        },
      },
    ],
  },
};

export const newAppointment = {
  bool: {
    must: [
      {
        exists: {
          field: "appointmentId",
        },
      },
      {
        bool: {
          should: [
            {
              match: {
                appointmentStatus: "PENDING",
              },
            },
            {
              match: {
                appointmentStatus: "CONFIRMED",
              },
            },
          ],
        },
      },
      {
        bool: {
          should: [
            {
              bool: {
                must_not: {
                  exists: {
                    field: "followUp",
                  },
                },
              },
            },
            {
              bool: {
                must: [
                  {
                    match: {
                      "followUp.isTreatmentPlanSet": false,
                    },
                  },
                  {
                    match: {
                      "followUp.isInitialFollowUp": true,
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
