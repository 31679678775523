import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "reset-css";
import "./index.css";
import "./styles/index.scss";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";

const container = document.getElementById("root");

const root = ReactDOMClient.createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
