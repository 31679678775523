import React, { useMemo, useRef } from "react";
import Creatable, { CreatableProps } from "react-select/creatable";
import { ClearIndicatorProps, OptionProps } from "react-select";
import { selectStyles } from "./constants";
import { ReactComponent as CloseCross } from "../../../../Assets/NewIcons/close-cross.svg";
import "./style.scss";

interface StyledSelectCreatableProps extends CreatableProps<any, any, any> {
  id: string;
  name: string;
  options: Partial<OptionProps>[];
  placeholder?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  onChange?: any;
}

function ClearIndicatorComp(props: ClearIndicatorProps<any, true>) {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div className="clear-indicator" ref={ref} {...restInnerProps}>
      <CloseCross />
    </div>
  );
}

function StyledSelectCreatable({
  id,
  name,
  options,
  placeholder = "Please, select one option",
  disabled = false,
  isSearchable = false,
  isClearable = true,
  onChange,
  ...otherProps
}: StyledSelectCreatableProps) {
  const selectRef = useRef(null);

  return (
    <div className="styled-select-wrapper">
      <Creatable
        ref={selectRef}
        placeholder={placeholder}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: ClearIndicatorComp,
        }}
        styles={selectStyles}
        options={options}
        id={`${id}__styledSelectCreatable`}
        name={`${name}__styledSelectCreatable`}
        defaultMenuIsOpen={false}
        isSearchable={isSearchable}
        isDisabled={disabled}
        isClearable={isClearable}
        {...otherProps}
      />
    </div>
  );
}

export default StyledSelectCreatable;
