export const downloadDocument =
  (documentUrl: string, documentTitle: string) => async () => {
    const image = await fetch(documentUrl);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = documentTitle;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
