import React from "react";
import SKELETON from "react-loading-skeleton";
import "./style.scss";

type Props = {
  count?: number;
  flex?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  column?: boolean;
  style?: React.CSSProperties;
};

export function Skeleton({
  flex,
  fullHeight,
  fullWidth,
  column,
  count,
  style,
}: Props) {
  const classNameList = [
    flex ? "skeleton--flex" : "",
    fullWidth ? "skeleton--fullWidth" : "",
    fullHeight ? "skeleton--fullHeight" : "",
    column ? "skeleton--column" : "",
  ].filter((it) => !!it);
  return (
    <SKELETON
      count={count}
      containerClassName={classNameList.join(" ")}
      style={style}
    />
  );
}
