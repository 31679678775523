import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const initiateAccutane = async (userId: string) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/initiate/${userId}`
    );

    return result.data.body;
  } catch (error) {
    logError("Error received when trying to initiate accutane");
    throw error;
  }
};
