import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import { ReactComponent as HappyGuy } from "../../../../Assets/NewIcons/happy-guy.svg";
import { Button } from "../../../NewComponents/Common/Button";
import { PaymentFormSurveyPage } from "../InitiateMembership/pages/payment-form";
import { MembershipPlanSurveyPage } from "../InitiateMembership/pages/membership-plan";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { getSessionState } from "../../../../features/session";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { SubscriptionItem } from "../../../../types/Subscription";

export function BuySubscriptionPage() {
  const history = useHistory();
  const { showWarn } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const [isDone, setDone] = useState(false);
  const [initialSubscriptionsData, setInitialSubscriptionsData] =
    useState<SubscriptionItem[]>();

  useEffect(() => {
    if (!initialSubscriptionsData && subscriptionsQuery.data) {
      setInitialSubscriptionsData(subscriptionsQuery.data);
    }
  }, [subscriptionsQuery]);

  const pages: SurveyPage[] = [
    {
      isFilled: () => false,
      Component: MembershipPlanSurveyPage,
    },
    {
      isFilled: () => false,
      Component: PaymentFormSurveyPage,
    },
  ];

  if (
    initialSubscriptionsData?.find(
      ({ status, type }) => type === "membership" && status !== "cancelled"
    )
  ) {
    showWarn({
      title: "Something went wrong",
      description:
        "You already have membership, there is no need to buy another one",
    });
    return <Redirect to="/" />;
  }

  return isDone ? (
    <div className="initiate-checkout-page__success-page">
      <HappyGuy className="initiate-checkout-page__success-image" />
      <p className="initiate-checkout-page__success-title">
        You have successfully paid for your subscription.
      </p>
      <p className="initiate-checkout-page__success-description paragraph-font--color">
        After a minute you will obtain full access to our features.{" "}
      </p>
      <Button onClick={() => history.push("/")} text="finish" />
    </div>
  ) : (
    <CustomSurvey
      questions={pages}
      data={{}}
      onComplete={() => {
        setDone(true);
        return true;
      }}
      className="initiate-checkout-page__survey"
    />
  );
}
