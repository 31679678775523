import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PatientsTableColumn } from "../../../../types/Table";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";

const ASCENDING_ORDER_KEYWORD = "asc";
const DESCENDING_ORDER_KEYWORD = "desc";

function switchSortingOrder(currentOrder: "asc" | "desc" | null) {
  switch (currentOrder) {
    case null:
      return ASCENDING_ORDER_KEYWORD;
    case ASCENDING_ORDER_KEYWORD:
      return DESCENDING_ORDER_KEYWORD;
    case DESCENDING_ORDER_KEYWORD:
      return null;
    default:
      return null;
  }
}

interface Props<T> {
  columns: TableHeadPayload<T>[];
  onSortChange: (sort: Record<any, any>[] | null) => void;
}

export function PatientsTableHead<T>({ columns, onSortChange }: Props<T>) {
  const [columnSort, setColumnSort] = useState<{
    title: string;
    dir: "asc" | "desc";
  } | null>(null);

  return (
    <tr>
      {columns.map(({ title, sort: sortFunc }) => (
        <th
          className={
            !columnSort || columnSort.title !== title
              ? undefined
              : columnSort.dir
          }
          onClick={() => {
            if (!sortFunc) return;
            switch (true) {
              case columnSort?.title !== title:
                setColumnSort({ title, dir: "asc" });
                onSortChange(sortFunc("asc"));
                break;
              case columnSort?.title === title && columnSort.dir === "asc":
                setColumnSort({ title, dir: "desc" });
                onSortChange(sortFunc("desc"));
                break;
              case columnSort?.title === title && columnSort.dir === "desc":
                setColumnSort(null);
                onSortChange(null);
                break;
              default:
                break;
            }
          }}
        >
          <span>{title}</span>
        </th>
      ))}
    </tr>
  );
}
