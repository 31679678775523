import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";

export const patientDashboardForEnrollmentCoordinatorSortUpcomingVisits = [
  {
    filter: {
      bool: {
        must: {
          exists: {
            field: "appointmentDate",
          },
        },
      },
    },
    script_score: {
      script: {
        source: `10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
  },
];

export const patientDashboardForEnrollmentCoordinatorSortPostEnrollment = [
  {
    filter: {
      bool: {
        should: [
          {
            bool: {
              must_not: {
                exists: {
                  field: "flags.contacted",
                },
              },
            },
          },
          {
            match: {
              "flags.contacted": false,
            },
          },
        ],
      },
    },
    script_score: {
      script: {
        source: `10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
  },
  // {
  //   filter: {
  //     bool: {
  //       must: {
  //         exists: {
  //           field: "dateOfService",
  //         },
  //       },
  //     },
  //   },
  //   script_score: {
  //     script: {
  //       source: "1 / (Math.log(doc['dateOfService'].value - ${APPLICATION_START_POINT_TIMESTAMP}L))",
  //     },
  //   },
  // },
];

export const patientDashboardForEnrollmentCoordinatorSortDeclinedVisits = [
  {
    filter: {
      bool: {
        must: {
          exists: {
            field: "appointmentDate",
          },
        },
      },
    },
    script_score: {
      script: {
        source: `1 - 10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
  },
];
