import React, { createContext, useState } from "react";
import { logInfo } from "../../../../../shared/logger";
import "./style.scss";

interface Props {
  onClose: () => void;
  children: JSX.Element[] | JSX.Element;
}

enum ANIMATIONS {
  SHOW = "show",
  HIDE = "fade",
}

export const CustomPopupTemplateContext = createContext({
  onClose: () => logInfo(""),
});

export function CustomPopupTemplate({ onClose, children }: Props) {
  const [animation, setAnimation] = useState(ANIMATIONS.SHOW);

  function close() {
    setAnimation(ANIMATIONS.HIDE);
    setTimeout(onClose, 500);
  }

  return (
    <CustomPopupTemplateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        onClose: close,
      }}
    >
      <div className={`custom-popup-template__wrap ${animation}`}>
        <div className="custom-popup-template">{children}</div>
      </div>
    </CustomPopupTemplateContext.Provider>
  );
}
