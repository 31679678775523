import React from "react";
import "./style.scss";

interface CollapseSectionProps {
  article: string;
  description: string | string[] | null;
}

function CollapseSection({ article, description }: CollapseSectionProps) {
  const cleanDescription = (desc) => {
    if (typeof desc !== "string") return desc;
    let cleanedDesc = desc.trim();
    if (cleanedDesc.startsWith("[") && cleanedDesc.endsWith("]")) {
      cleanedDesc = cleanedDesc.slice(1, -1).trim();
    } else if (cleanedDesc.startsWith('"') && cleanedDesc.endsWith('"')) {
      cleanedDesc = cleanedDesc.slice(1, -1).trim();
    }

    return cleanedDesc;
  };

  const cleanedDescription = cleanDescription(description);
  return (
    <div className="collapse-section">
      <p className="collapse-section__article">{article}</p>
      <p className="collapse-section__description">
        {cleanedDescription || "No"}
      </p>
    </div>
  );
}

export default CollapseSection;
