import React, { useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { TextInput } from "../../../../NewComponents/Common/TextInput";
import camelizeText from "../../../../../utils/camelize-text";

function surveyToComponent(data: SurveyData) {
  return data.fullName || "";
}

function componentToSurvey(value: string) {
  return {
    fullName: value,
  };
}

export function FullNameSurveyQuestion({ data, onDone }: SurveyComponentProps) {
  const [currentData, setData] = useState<string>(surveyToComponent(data));
  const [fullNameInvalid, setFullNameInvalid] = useState("");
  const { isChild } = data;

  function validate() {
    if (!currentData.trim()) {
      setFullNameInvalid("Please, enter your full name");
      return false;
    }

    if (currentData.length > 255) {
      setFullNameInvalid("Full name is too long");
      return false;
    }

    if (currentData.match(/[^a-zA-Z' -]/)) {
      setFullNameInvalid(
        "You can use only latin letters, space, - and ' symbols"
      );
      return false;
    }

    const words = currentData.split(" ").filter((it) => !!it);
    if (words.length < 2 || !words.every((it) => /[a-zA-Z]/.test(it))) {
      setFullNameInvalid("Please, enter your full name");
      return false;
    }

    return true;
  }

  function submit() {
    if (!validate()) {
      return;
    }

    sendSurveyQuestion("fullName");
    onDone(componentToSurvey(currentData.trim()));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        {isChild
          ? "Great, what is your child's name?"
          : "Great, what is your name?"}
      </p>
      <p className="survey-question__descr paragraph-font--color">
        {isChild
          ? "Please enter your child's full name"
          : "Please enter your full name"}
      </p>
      <TextInput
        id="full-name"
        name="full-name"
        onChange={(value) => setData(camelizeText(value))}
        required
        value={currentData || ""}
        placeholder="e.g. Jane Smith"
        error={fullNameInvalid}
        className="survey-question__input"
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={!currentData}
      />
    </div>
  );
}
