import React, { useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { CheckboxesList } from "../../../../NewComponents/Common/CheckboxesList";

export const PREVIOUS_ACNE_PRODUCTS_ANSWERS = {
  CLEANSERS_OR_MOISTURIZERS: "Cleansers or moisturizers",
  OTC_CREAMS: "Over the counter creams",
  VITAMINS: "Vitamins for acne",
  PRESCRIPTION_CREAMS: "Prescription creams",
  PRESCRIPTION_PILLS: "Prescription pills",
  NO: "I haven't tried any products",
};

const list = [
  {
    value: PREVIOUS_ACNE_PRODUCTS_ANSWERS.CLEANSERS_OR_MOISTURIZERS,
    label: "Cleansers or moisturizers",
  },
  {
    value: PREVIOUS_ACNE_PRODUCTS_ANSWERS.OTC_CREAMS,
    label: "Over the counter creams",
  },
  {
    value: PREVIOUS_ACNE_PRODUCTS_ANSWERS.VITAMINS,
    label: "Vitamins for acne",
  },
  {
    value: PREVIOUS_ACNE_PRODUCTS_ANSWERS.PRESCRIPTION_CREAMS,
    label: "Prescription creams",
  },
  {
    value: PREVIOUS_ACNE_PRODUCTS_ANSWERS.PRESCRIPTION_PILLS,
    label: "Prescription pills",
  },
  {
    value: PREVIOUS_ACNE_PRODUCTS_ANSWERS.NO,
    label: "I haven't tried any products",
  },
];

function surveyToComponent(data: SurveyData) {
  return (data.previousAcneProductsTaken as string[]) || [];
}

function componentToSurvey(value: string[]) {
  return {
    previousAcneProductsTaken: value,
  };
}

export function PreviousAcneProductsSurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));

  function submit() {
    if (isEmpty(currentData)) return;

    sendSurveyQuestion("previousAcneProductsTaken");
    onDone(componentToSurvey(currentData));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        What kind of products have you tried for acne in the past?
      </p>
      <p className="survey-question__descr paragraph-font--color">
        You can select multiple options from the list
      </p>
      <CheckboxesList
        list={list}
        currentValues={currentData}
        onChange={(value) => setData(value)}
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={currentData.length === 0}
      />
    </div>
  );
}
