import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";

export const marketingApiSlice = createApi({
  reducerPath: "api/marketing",
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.MARKETING_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    registerPageView: builder.mutation<null, { fbp: string }>({
      query: ({ fbp }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($fbp: String!) { registerPageView(fbp: String!) { nothing } }",
          variables: JSON.stringify({
            fbp,
          }),
        }),
      }),
    }),
    registerCheckoutInitiation: builder.mutation<null, { fbp: string }>({
      query: ({ fbp }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($fbp: String!) { registerCheckoutInitiation(fbp: String!) { nothing } }",
          variables: JSON.stringify({
            fbp,
          }),
        }),
      }),
    }),
  }),
});

export const {
  useRegisterCheckoutInitiationMutation,
  useRegisterPageViewMutation,
} = marketingApiSlice;
