import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientFollowUpVisit } from "../../Pages/Provider/PatientFollowUpVisit";
import { Footer } from "../../NewComponents/Common/Footer";
import { useGetAssignedPatientsQuery } from "../../../features/api/patients";
import { getSessionState } from "../../../features/session";
import { PatientFollowUpVisitMobileScreen } from "../../Pages/Provider/PatientFollowUpVisit/PatientFollowUpVisitMobileScreen";

interface Props {
  isAuthorised: boolean;
  setParamsId: (id: any) => void;
}

function PatientFollowUpProviderComponent({
  isAuthorised,
  setParamsId,
}: Props) {
  const params = useParams();
  const paramsId = params.userId;

  useEffect(() => {
    setParamsId(paramsId);
  }, [paramsId]);

  return isAuthorised ? (
    <>
      <div className="main-area follow-up-visit__mobile">
        <PatientFollowUpVisitMobileScreen />
      </div>

      <div className="main-area follow-up-visit__web">
        <PatientFollowUpVisit />
      </div>
      <Footer />
    </>
  ) : (
    <Redirect to="/" />
  );
}

export default PatientFollowUpProviderComponent;
