import React, { useEffect, useRef } from "react";

interface OutsideClickHandlerProps {
  onClickOutside: () => void;
  children: JSX.Element | Element;
}

function OutsideClickHandler({
  onClickOutside,
  children,
}: OutsideClickHandlerProps) {
  const ref = useRef<any>(null);

  const handleClickOutside = (e: React.MouseEvent) => {
    if (!ref.current || ref.current.contains(e.target)) {
      return;
    }

    onClickOutside();
  };

  useEffect(() => {
    document.addEventListener("mousedown", (e) =>
      handleClickOutside(e as unknown as React.MouseEvent)
    );
    return () => {
      document.removeEventListener("mousedown", (e) =>
        handleClickOutside(e as unknown as React.MouseEvent)
      );
    };
  }, []);

  return (
    <div
      ref={ref}
      onClick={handleClickOutside}
      onMouseOver={handleClickOutside}
    >
      {children}
    </div>
  );
}

export default OutsideClickHandler;
