import { createSlice } from "@reduxjs/toolkit";
import { ReduxAction, ReduxStore } from "../types/Stores";
import { EditPatientInfoStore } from "../types/Stores/EditPatientInfoStore";

const initialState: EditPatientInfoStore = {
  isShown: false,
  type: null,
};

export const editSkinInfoSlice = createSlice({
  name: "editSkinInfo",
  initialState,
  reducers: {
    showEditSkinInfoPopup: (_state, action: ReduxAction<string>) => {
      const state = _state;
      state.type = action.payload;
      state.isShown = true;
    },
    hideEditSkinInfoPopup: (_state) => {
      const state = _state;
      state.type = null;
      state.isShown = false;
    },
  },
});

export const { showEditSkinInfoPopup, hideEditSkinInfoPopup } =
  editSkinInfoSlice.actions;
export const isShownSelector = ({ editSkinInfoStore }: ReduxStore) =>
  editSkinInfoStore.isShown;
export const typeSelector = ({ editSkinInfoStore }: ReduxStore) =>
  editSkinInfoStore.type;

export default editSkinInfoSlice.reducer;
