import React, { useContext, useEffect, useState } from "react";
import { Conversation } from "@twilio/conversations";
import { useSelector } from "react-redux";
import { getPatientStore } from "../../../../features/patientView";
import { getProvidersInfoState } from "../../../../features/providers";
import { Button } from "../../Common/Button";
import { ReactComponent as DoctorsImage } from "../../../../Assets/NewIcons/doctors.svg";
import { ReactComponent as ChatMessagesIcon } from "../../../../Assets/NewIcons/chat-messages.svg";
import "./style.scss";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { ChatPopupGenerator } from "../../Common/ChatPopup";
import { getNonEmptyValue } from "../../../../utils/get-non-empty-value";
import { getCareCoordinatorsInfoState } from "../../../../features/careCoordinators";
import { ENV_CONFIG } from "../../../../config";
import { getSessionState } from "../../../../features/session";
import {
  useGetCareTeamQuery,
  useGetPatientByIdQuery,
} from "../../../../features/api/patients";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { TwilioController } from "../../../../services/twilio";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { Skeleton } from "../../Common/Skeleton";
import { hasActiveMembership } from "../../../../utils/has-active-membership";
import { getTwilioState } from "../../../../features/twilio";

type ChatParticipantsProps = {
  patientId?: string;
  view: "mobile" | "desktop";
};

function ParticipantImage({
  employeeId,
  className,
}: {
  employeeId: string;
  className: string;
}) {
  const employeeQuery = useGetEmployeeQuery(employeeId);
  return employeeQuery.data ? (
    <img src={employeeQuery.data.image} className={className} />
  ) : (
    <div className={className} />
  );
}

function ChatParticipants({ patientId, view }: ChatParticipantsProps) {
  const careTeamQuery = useGetCareTeamQuery(patientId as string, {
    skip: !patientId,
  });
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {careTeamQuery.data ? (
        careTeamQuery.data
          .slice(0, 3)
          .map(({ employeeId }) => (
            <ParticipantImage
              employeeId={employeeId}
              className={
                view === "desktop"
                  ? "chat-card-desktop__participant-image"
                  : "chat-card-mobile__participant-image"
              }
            />
          ))
      ) : (
        <Skeleton />
      )}
    </>
  );
}

export function ChatCard() {
  const { showPopup } = useContext(PopupLayerContext);
  const { initialized } = useSelector(getTwilioState);
  const { activePatientId, userId } = useSelector(getSessionState);
  const [isNewMessage, setIsNewMessage] = useState<boolean>();
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  // useEffect(() => {
  //   (async () => {
  //     if (!initialized || !userId) {
  //       setIsNewMessage(undefined);
  //       return;
  //     }

  //     const conversations = TwilioController.getConversations();
  //     if (!conversations) {
  //       throw new Error("Unable to get conversations");
  //     }
  //     const _conversation = conversations?.[userId];
  //     if (!_conversation) return;
  //     const unreadMessagesCount = await _conversation.getUnreadMessagesCount();
  //     setIsNewMessage(!!unreadMessagesCount);
  //   })();
  // }, [initialized]);

  const showChatPopup = () => {
    showPopup(ChatPopupGenerator());
  };

  return (
    <div className="chat-card">
      <div className="chat-card-mobile">
        <p className="chat-card-mobile__title subheading-font subheading-font--color">
          Chat with care team
        </p>
        <div className="chat-card-mobile__img-layout">
          <ChatMessagesIcon />
        </div>
        <div className="chat-card-mobile__click" onClick={showChatPopup} />
        <div className="chat-card-mobile__participants">
          {activePatientId ? (
            <ChatParticipants patientId={activePatientId} view="mobile" />
          ) : null}
        </div>
        {isNewMessage ? (
          <div className="chat-card-mobile__new-message" />
        ) : null}
      </div>
      <div className="chat-card-desktop">
        <div className="chat-card-desktop__content">
          <div className="chat-card-desktop__image">
            <DoctorsImage />
          </div>
          <p className="subheading-font subheading-font--margin subheading-font--color">
            Chat with care team
          </p>
          <p className="paragraph-font paragraph-font--color paragraph-font--margin">
            Ongoing support within 24 hours. Refills, skin questions, etc.
          </p>
        </div>
        <div className="chat-card-desktop__actions">
          <div className="chat-card-desktop__actions-wrap">
            {subscriptionsQuery.data ? (
              <Button
                size="small"
                text="view"
                onClick={showChatPopup}
                disabled={!hasActiveMembership(subscriptionsQuery.data)}
                tooltip={
                  !hasActiveMembership(subscriptionsQuery.data)
                    ? "Start your membership to unlock"
                    : undefined
                }
              />
            ) : (
              <Skeleton />
            )}

            {isNewMessage ? (
              <div className="chat-card-desktop__new-message" />
            ) : null}
          </div>

          <div className="chat-card-desktop__participants">
            {activePatientId ? (
              <ChatParticipants patientId={activePatientId} view="desktop" />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
