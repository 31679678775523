import { createSlice } from "@reduxjs/toolkit";
import { ReduxAction, ReduxStore } from "../types/Stores";
import { ErrorNotificationStore } from "../types/Stores/ErrorNotificatonStore";

const initialState: ErrorNotificationStore = {
  isShown: false,
  text: null,
};

export const errorNotificationSlice = createSlice({
  name: "errorNotification",
  initialState,
  reducers: {
    show: (_state, action: ReduxAction<string>) => {
      const state = _state;
      state.text = action.payload;
      state.isShown = true;
    },
    hide: (_state) => {
      const state = _state;
      state.text = null;
      state.isShown = false;
    },
  },
});

export const { show, hide } = errorNotificationSlice.actions;
export const isShownSelector = ({ errorNotificationStore }: ReduxStore) =>
  errorNotificationStore.isShown;
export const textSelector = ({ errorNotificationStore }: ReduxStore) =>
  errorNotificationStore.text;

export default errorNotificationSlice.reducer;
