interface FormattedRecord {
  leaveId: string;
  start: string;
  end: string;
  duration: number;
  employeeId: string;
  employeeRole: string;
}

export function formatNewTimeOffData(
  response: TimeOffRecord[]
): FormattedRecord[] {
  const formattedData: FormattedRecord[] = response.map((record) => {
    const { leaveId, start, end, employeeId, employeeRole } = record;

    const startDate = new Date(start * 1000);
    const endDate = new Date(end * 1000);

    // Calculate duration in days
    const duration = Math.round(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    return {
      leaveId,
      start: startDate.toLocaleString(),
      end: endDate.toLocaleString(),
      duration,
      employeeId,
      employeeRole,
    };
  });

  return formattedData;
}
