import React, { useContext } from "react";
import { Button } from "../../Common/Button";
import { ReactComponent as CalendarImage } from "../../../../Assets/NewIcons/calendar.svg";
import { ReactComponent as CalendarMobileImage } from "../../../../Assets/NewIcons/calendar-mobile.svg";
import "./style.scss";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { MyProgressPopupGenerator } from "../MyProgressPopup";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { hasActiveMembership } from "../../../../utils/has-active-membership";

export function TrackProgressCard({ patientId }: { patientId: string }) {
  const { showPopup } = useContext(PopupLayerContext);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(patientId);

  const isActiveMembership =
    subscriptionsQuery.isSuccess &&
    hasActiveMembership(subscriptionsQuery.data);

  return (
    <div className="track-progress-card">
      <div className="track-progress-card-mobile">
        <p className="track-progress-card-mobile__title subheading-font subheading-font--color">
          View my progress
        </p>
        <div className="track-progress-card-mobile__img-layout">
          <CalendarMobileImage />
        </div>
        <div
          style={{ cursor: !isActiveMembership ? "not-allowed" : "pointer" }}
          className="track-progress-card-mobile__click"
          onClick={() =>
            isActiveMembership && showPopup(MyProgressPopupGenerator())
          }
        />
      </div>
      <div className="track-progress-card-desktop">
        <div className="track-progress-card-desktop__content">
          <div className="track-progress-card-desktop__image">
            <CalendarImage />
          </div>
          <p className="subheading-font subheading-font--margin subheading-font--color">
            View my progress
          </p>
          <p className="paragraph-font paragraph-font--color paragraph-font--margin">
            See the stacked photos from my visit submissions.
          </p>
        </div>
        <div className="track-progress-card-desktop__actions">
          <Button
            className={
              !isActiveMembership ? "not-allowed-cursor" : "pointer-cursor"
            }
            size="small"
            text="track"
            onClick={() => showPopup(MyProgressPopupGenerator())}
            disabled={!isActiveMembership}
            tooltip={
              !isActiveMembership
                ? "Start your membership to unlock"
                : undefined
            }
          />
        </div>
      </div>
    </div>
  );
}
