import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { AuthenticationService } from "../../../../cognito";
import { Patient } from "../../../../../types/Entities/Patient";

type FullNameItem = {
  key: "fullName";
  queryType: string;
  queryName: string;
  value: string;
};

type PhoneItem = {
  key: "phone";
  queryType: string;
  queryName: string;
  value: string;
};

type SexItem = {
  key: "sex";
  queryType: string;
  queryName: string;
  value: string;
};

type HeightItem = {
  key: "height";
  queryType: string;
  queryName: string;
  value: number;
};

type WeightItem = {
  key: "weight";
  queryType: string;
  queryName: string;
  value: number;
};

type DiagnosisCodesItem = {
  key: "diagnosisCodes";
  queryType: string;
  queryName: string;
  value: string[];
};

type ParentsInfoItem = {
  key: "parentInfo";
  queryType: string;
  queryName: string;
  value: {
    name: string;
    email: string;
    phone: string;
  };
};

type DateOfBirthItem = {
  key: "dateOfBirth";
  queryType: string;
  queryName: string;
  value: string;
};

type ShippingInfoItem = {
  key: "shippingInfo";
  queryType: string;
  queryName: string;
  value: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
  };
};

export type UpdateItem =
  | FullNameItem
  | PhoneItem
  | SexItem
  | HeightItem
  | WeightItem
  | DiagnosisCodesItem
  | ParentsInfoItem
  | DateOfBirthItem
  | ShippingInfoItem;

export const updatePatientInfo = async (
  patientId: string,
  payload: UpdateItem[]
): Promise<Patient> => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/update-info`,
      {
        id: patientId,
        payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error received when trying to update patient");
    throw error;
  }
};
