export const selectStyles: any = {
  clearIndicator: () => ({
    position: "absolute",
    top: 0,
    right: "24px",
  }),
  control: () => ({
    width: "100%",
    height: "48px",
    background: "#F9F9FA",
    border: "1px solid #E1E0E6",
    borderRadius: "80px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#403E48",
    position: "relative",
    "&:active": {
      background: "#F9F9FA",
      border: "1px solid #C0B1FB",
      boxShadow: "0 0 0 3px #EEEBFC",
      borderRadius: "80px",
    },
    "&:focus": {
      background: "#F9F9FA",
      border: "1px solid #C0B1FB",
      boxShadow: "0 0 0 3px #EEEBFC",
      borderRadius: "80px",
    },
    "&:hover": {
      background: "#F9F9FA",
      border: "1px solid #C0B1FB",
      boxShadow: "0 0 0 3px #EEEBFC",
      borderRadius: "80px",
    },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    transform: `rotate(${state.isFocused ? "180deg" : "0"})`,
  }),
  indicatorsContainer: () => ({
    position: "absolute",
    top: "15px",
    right: "12px",
  }),
  indicatorSeparator: () => ({}),
  input: () => ({
    position: "relative",
    input: {},
  }),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  menu: () => ({
    position: "relative",
    margin: "16px 0 0",
    background: "#fff",
  }),
  menuList: () => ({
    maxHeight: "200px",
    overflow: "scroll",
    position: "absolute",
    background: "#fff",
    width: "100%",
    border: "1px solid #E1E0E6",
    borderRadius: "8px",
    zIndex: 2,
  }),
  option: (
    provided: any,
    { isSelected, isFocused }: { isSelected: boolean; isFocused: boolean }
  ) => ({
    padding: "12px 20px",
    gap: "10px",
    boxShadow: "inset 0px -1px 0px #F2F4F7",
    background: isSelected && isFocused ? "#F0F5FE" : "#fff",
    "&:hover": {
      background: "#F0F5FE",
      boxShadow: "inset 0px -1px 0px #F2F4F7",
    },
  }),
  placeholder: () => ({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#A09DB1",
    position: "absolute",
  }),
  valueContainer: () => ({
    padding: "12px 20px",
    // "*:nth-child(2)": {
    //   bottom: "24px",
    // },
  }),
  singleValue: () => ({
    width: "calc(100% - 25px)",
    padding: "0 50px 0 0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    position: "absolute",
    boxSizing: "border-box",
  }),
};

export const styledSelectChats = {
  clearIndicator: () => ({
    position: "absolute",
    top: 0,
    right: "24px",
  }),
  control: () => ({
    width: "100%",
    height: "48px",
    background: "#F9F9FA",
    border: "1px solid #E1E0E6",
    borderRadius: "80px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#403E48",
    position: "relative",
    "&:active": {
      background: "#F9F9FA",
      border: "1px solid #C0B1FB",
      boxShadow: "0 0 0 3px #EEEBFC",
      borderRadius: "80px",
    },
    "&:focus": {
      background: "#F9F9FA",
      border: "1px solid #C0B1FB",
      boxShadow: "0 0 0 3px #EEEBFC",
      borderRadius: "80px",
    },
    "&:hover": {
      background: "#F9F9FA",
      border: "1px solid #C0B1FB",
      boxShadow: "0 0 0 3px #EEEBFC",
      borderRadius: "80px",
    },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    transform: `rotate(${state.isFocused ? "180deg" : "0"})`,
  }),
  indicatorsContainer: () => ({
    position: "absolute",
    top: "15px",
    right: "12px",
  }),
  indicatorSeparator: () => ({}),
  input: () => ({
    position: "relative",
    input: {},
  }),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  // menu: () => ({
  //   position: "relative",
  //   margin: "16px 0 0",
  //   background: "#fff",
  // }),
  menuList: () => ({
    maxHeight: "18vh",
    overflow: "scroll",
    // position: "absolute",
    background: "#fff",
    width: "100%",
    border: "1px solid #E1E0E6",
    borderRadius: "8px",
    zIndex: 2,
  }),
  option: (provided: any, { isSelected }: { isSelected: boolean }) => ({
    padding: "12px 20px",
    gap: "10px",
    boxShadow: "inset 0px -1px 0px #F2F4F7",
    background: isSelected ? "#F0F5FE" : "#fff",
    "&:hover": {
      background: "#F0F5FE",
      boxShadow: "inset 0px -1px 0px #F2F4F7",
    },
  }),
  placeholder: () => ({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#A09DB1",
    position: "absolute",
  }),
  valueContainer: () => ({
    padding: "12px 20px",
    // "*:nth-child(2)": {
    //   bottom: "24px",
    // },
  }),
  singleValue: () => ({
    width: "calc(100% - 25px)",
    padding: "0 50px 0 0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    position: "absolute",
    boxSizing: "border-box",
  }),
};

export const styledSelectMultipleStyles = {
  control: (baseStyles: any, state: any) => {
    const childrenCountMoreThanTwo = state.children.length > 2;

    return {
      ...baseStyles,
      background: "#F9F9FA",
      // height: childrenCountMoreThanTwo ? "auto" : baseStyles.height,
      height: "auto",
      border: "1px solid #E1E0E6",
      borderRadius: "25px",
      fontWeight: 400,
      fontSize: "16px",
      "&:active": {
        background: "#F9F9FA",
        border: "1px solid #C0B1FB",
        boxShadow: "0 0 0 3px #EEEBFC",
        borderRadius: "80px",
      },
      "&:focus": {
        background: "#F9F9FA",
        border: "1px solid #C0B1FB",
        boxShadow: "0 0 0 3px #EEEBFC",
        borderRadius: "80px",
      },
      "&:hover": {
        background: "#F9F9FA",
        border: "1px solid #C0B1FB",
        boxShadow: "0 0 0 3px #EEEBFC",
        borderRadius: "80px",
      },
    };
  },
  valueContainer: (styles: any) => ({
    ...styles,
    padding: "4px 4px 4px 8px",
  }),
  MultiValueContainer: (styles: any) => ({
    ...styles,
    padding: "2px",
    fontSize: "12px",
  }),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: "#403E48",
    borderRadius: "6px",
    padding: 0,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: "#FFFFFF",
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    cursor: "pointer",
    color: "white",
    paddingLeft: "2px",
    ":hover": {
      backgroundColor: "#403E48",
      color: "white",
    },
  }),
};
