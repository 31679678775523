import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { show as showErrorNotification } from "../../../../features/errorNotification";
import { deleteMedicine } from "../../../../features/medicine";
import { InstructionsList } from "../../../data/medicineData";
import { DeleteConfirmainPopupContext } from "../../Common/DeleteConfirmationPopup";
import "./style.css";
import { logError } from "../../../../shared/logger";
import { Medicine } from "../../../../types/Entities/Medicine";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { alphabetical } from "../../../../utils/sortings";
import {
  useArchiveMedicationMutation,
  useGetAllMedicationsQuery,
} from "../../../../features/api/medications";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { Medication } from "../../../../types/Medication";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { MedicationPopupGenerator } from "../../../NewComponents/Administrator/MedicationPopup";
import { NotificationLayerContext } from "../../Common/NotificationsLayout";

export function MedicineTable() {
  const { showPopup } = useContext(PopupLayerContext);
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const [archiveMedication] = useArchiveMedicationMutation();
  const { ask } = useContext(DeleteConfirmainPopupContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMedications, setFilteredMedications] = useState(
    medicationsQuery?.data || []
  );

  useEffect(() => {
    if (medicationsQuery?.data) {
      const filtered = medicationsQuery?.data.filter((medication) =>
        medication.name.toLowerCase().includes(searchQuery.toLowerCase().trim())
      );
      setFilteredMedications(filtered);
    }
  }, [searchQuery, medicationsQuery.data]);
  async function archiveMedicineHandler(id: string, state: boolean) {
    const result: any = await archiveMedication({
      medicationId: id,
      archived: state,
    });
    if (result.error || result.data?.errors) {
      showError({
        title: "Something went wrong...",
        description: "Unable to perform this action",
      });
      throw new Error("Unable to archive medication");
    }

    showSuccess({
      title: "Success!",
      description: `Medication has been ${
        state ? "archived" : "unarchived"
      } successfully`,
    });
  }

  function askUserForConfirmation(id: string, archived: boolean) {
    ask(
      archiveMedicineHandler.bind(null, id, !archived),
      archived ? "unarchive" : "archive"
    );
  }

  const renderTableBody = (medications: Medication[]) => (
    <tbody>
      {Array.from(medications)
        .filter((row) => !row.archived) // Filter out archived records
        .sort((a, b) =>
          alphabetical(
            `${a.name}${a.strength ? `, ${a.strength}` : ""}${
              a.size ? `, ${a.size}` : ""
            }`,
            `${b.name}${b.strength ? `, ${b.strength}` : ""}${
              b.size ? `, ${b.size}` : ""
            }`
          )
        )
        .map((row) => (
          <tr key={row.id}>
            {" "}
            {/* Add a unique key for each row */}
            <td className="medicine-table__body-cell--center">
              {`${row.name}${row.strength ? `, ${row.strength}` : ""}${
                row.size ? `, ${row.size}` : ""
              }`}
            </td>
            <td className="medicine-table__body-cell--center">
              {row.instructions
                ?.map((instruction) => InstructionsList[instruction])
                .join(", ")}
            </td>
            <td className="medicine-table__body-cell--center">
              {row.specialInstructions || "-"}
            </td>
            <td className="medicine-table__body-cell--center">
              {row.refillCount || "-"}
            </td>
            <td className="medicine-table__body-cell--center">
              {row.refillExpiration || "-"}
            </td>
            <td className="patientsTable__actionsList">
              <button
                type="button"
                title="Edit"
                className="patientsTable__actionButtonWithIcon"
                onClick={async () => {
                  showPopup(MedicationPopupGenerator(row));
                }}
              >
                <div className="patientsTable__actionButton--edit" />
              </button>
              <button
                type="button"
                title={row.archived ? "Unarchive" : "Archive"}
                className="patientsTable__actionButtonWithIcon"
                onClick={() => askUserForConfirmation(row.id, !!row.archived)}
              >
                <div className="patientsTable__actionButton--delete" />
              </button>
            </td>
          </tr>
        ))}
    </tbody>
  );
  return filteredMedications ? (
    <>
      <div className="medicine-button-wrapper">
        <input
          className="medicine-search-field"
          type="text"
          placeholder="Search by medicine name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          type="button"
          className="custom-button custom-button--secondary custom-button--small ipledge-details__confirm-button"
          onClick={() => {
            showPopup(MedicationPopupGenerator());
          }}
        >
          Create new medicine
        </button>
      </div>
      <div className="medicine-table__wrap">
        <table className="medicine-table__table">
          <thead>
            <tr>
              <th className="medicine-table__heading-cell--treatment">
                Treatment
              </th>
              <th className="medicine-table__heading-cell--instructions">
                Instructions
              </th>
              <th>Special Instructions</th>
              <th>Refills</th>
              <th>Refill Expiration Rate (days)</th>
              <th>Actions</th>
            </tr>
          </thead>
          {renderTableBody(filteredMedications)}
        </table>
      </div>
    </>
  ) : (
    <Skeleton flex column fullWidth count={8} style={{ height: 70 }} />
  );
}
