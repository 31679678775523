import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const deleteChatMessage = async (
  messageId: string,
  conversationId: string
): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/delete-chat-message`,
      {
        messageId,
        conversationId,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to delete message");
    throw error;
  }
};
