import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { CareCoordinator } from "../../../../../types/Entities/CareCoordinator";
import { AuthenticationService } from "../../../../cognito";

interface Response {
  employee: CareCoordinator;
  temporaryPassword: string;
}

export const create = async (data: CareCoordinator) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Response>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/care-coordinators/create`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot create care coordinator`);
    throw error;
  }
};
