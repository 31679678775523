import React from "react";
import { Patient } from "../../../../types/Entities/Patient";
import { ProposeMeetingButton } from "./buttons/proposeMeet";
import "./style.css";

export function PatientActionButtons() {
  return (
    <div className="patient-action-buttons">
      <ProposeMeetingButton />
    </div>
  );
}
