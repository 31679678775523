import axios from "axios";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";

type DocumentType = "text" | "esignature";

interface DocumentJsonAttributes {
  align: string;
  arrangement: string;
  bold: boolean;
  color: string;
  font: string;
  height: number;
  italic: boolean;
  max_chars: number;
  max_lines: number;
  name: string;
  page_number: number;
  required: boolean;
  size: number;
  underline: boolean;
  valign: string;
  width: number;
  x: number;
  y: number;
}

export interface DocumentField {
  element_id: any;
  field_id: any;
  field_request_canceled: any;
  field_request_id: any;
  fulfiller: any;
  id: string;
  json_attributes: DocumentJsonAttributes;
  originator: string;
  role: string;
  role_id: string;
  template_field_id: string;
  type: DocumentType;
}

interface SignNowDocument {
  attachments: any[];
  checks: any[];
  created: string;
  document_group_info: {
    doc_count_in_group: number;
    document_group_id: any;
    document_group_name: any;
    invite_id: any;
    invite_status: any;
    sign_as_merged: boolean;
  };
  document_group_template_info: any[];
  fields: DocumentField[];
}

interface Response {
  document: SignNowDocument;
}

export const getSignNowDocument = async (
  documentId: string
): Promise<SignNowDocument> => {
  try {
    const result = await axios.get<Response>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/signnow-document/${documentId}`
    );

    return result.data.document;
  } catch (error) {
    logError("Error received when trying to get info");
    throw error;
  }
};
