import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull } from "util";
import {
  hideEditSkinInfoPopup,
  isShownSelector,
} from "../../../../features/editSkinInfo";
import "survey-react/survey.css";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close-icon.svg";
import "./style.css";
import {
  useGetPatientByIdQuery,
  useUpdateSkinSurveyMutation,
} from "../../../../features/api/patients";
import { Loader } from "../../../NewComponents/Common/Loader";
import { NotificationLayerContext } from "../NotificationsLayout";
import { Button } from "../../../NewComponents/Common/Button";
import { skinIssues } from "../../../NewPages/Patient/SkinSurvey/skin-issues";
import { getSessionState } from "../../../../features/session";
import { RadioButtonsList } from "../../../NewComponents/Common/RadioButtonsList";

export function EditSkinInfoPopup() {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { activePatientId } = useSelector(getSessionState);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const isShown = useSelector(isShownSelector);
  const { data: patient, isSuccess } = useGetPatientByIdQuery(userId as string);
  const [updateSkinSurvey] = useUpdateSkinSurveyMutation();
  const [currentSkinType, setCurrentSkinType] = useState(null);

  const handlePopupClose = () => {
    dispatch(hideEditSkinInfoPopup());
  };

  const dropdownOptions = skinIssues.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  let skinTypeUpdatePayload = [
    {
      id: "skinIssueType",
      type: "text",
      value: currentSkinType,
      questionType: currentSkinType,
    },
  ];

  const handleSkinTypeSelection = (e) => {
    console.log("e", e);
    setCurrentSkinType(e);
  };

  const handleSkinTypeSubmit = async () => {
    console.log("handleSkinTypeSubmit");
    const questionsArray = skinIssues.filter(
      (issue) => issue.value === skinTypeUpdatePayload[0].value
    );
    const transformedArray = questionsArray[0].subQuestions.flatMap((obj) => {
      // Create a new object based on the current question
      const newObj = {
        id: obj.value,
        type: "text",
        value: "",
        questionType: obj.questionType,
      };
      return newObj;
    });
    skinTypeUpdatePayload = [...skinTypeUpdatePayload, ...transformedArray];
    const result: any = await updateSkinSurvey({
      patientId: activePatientId,
      skinSurvey: skinTypeUpdatePayload,
    });
    if (result)
      if (result.error || result.data?.errors) {
        showError({
          title: "Something went wrong...",
          description: "Unable to perform this action",
        });
        handlePopupClose();
      }

    showSuccess({
      title: "Success!",
      description: `Care coordinator has been archived successfully`,
    });
    handlePopupClose();
  };

  return isShown ? (
    <div className="edit-patient-info-popup">
      <div className="edit-patient-info-popup__wrapper">
        {!isSuccess ? (
          <Loader />
        ) : (
          <>
            {" "}
            <div
              onClick={handlePopupClose}
              className="edit-patient-info-popup__close-container"
            >
              <CloseIcon className="edit-patient-info-popup__close-icon" />
            </div>
            <div className="">
              <p className="survey-question__title" style={{ fontSize: "1em" }}>
                Which skin type would you like to edit?
              </p>
              {/* <p className="survey-question__descr paragraph-font--color" /> */}
              <div>
                {dropdownOptions.map((option) => (
                  <div
                    key={option.value}
                    style={{ padding: "5px", fontSize: "15px" }}
                  >
                    <input
                      type="radio"
                      name="option"
                      id={option.label}
                      value={option.value}
                      onChange={(e) => handleSkinTypeSelection(e.target.value)}
                      style={{ marginRight: "6px" }}
                    />
                    {option.label}
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={handleSkinTypeSubmit}
                className="survey-skin-question__button"
                disabled={isNull(currentSkinType)}
                style={{
                  backgroundColor: "#1ab394",
                  width: "160px",
                  height: "33px",
                  color: "#fff",
                  border: "none",
                  fontWeight: "900",
                  fontSize: "14px",
                  marginTop: "16px",
                }}
              >
                Continue{" "}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
}
