import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  show as showErrorNotification,
  hide as hideErrorNotifcation,
} from "../../../../features/errorNotification";
import "./style.css";
import { AuthenticationService } from "../../../../services/cognito";
import { logError } from "../../../../shared/logger";
import { TextInput } from "../../../NewComponents/Common/TextInput";
import { SubmitButton } from "../../../NewComponents/Common/Button";
import { NotificationLayerContext } from "../NotificationsLayout";

interface Props {
  userAttr: any;
  user: CognitoUser;
}

export function NewPassword({ userAttr, user }: Props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatInvalid, setPasswordRepeatInvalid] = useState("");
  const [submitInProgress, setSubmitState] = useState(false);

  const submitButtonStatus =
    // eslint-disable-next-line no-nested-ternary
    !password.length || !passwordRepeat.length
      ? "disabled"
      : submitInProgress
      ? "loading"
      : undefined;

  function validatePassword() {
    const lowercaseLettersRegexp = /[a-z]/;
    const numbersRegexp = /\d/;
    if (
      !lowercaseLettersRegexp.test(password) ||
      !numbersRegexp.test(password) ||
      password.length < 8
    ) {
      setPasswordInvalid(
        "Password should be at least 8 characters long, contain at least one lower case letter and one number"
      );
      return false;
    }

    return true;
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validatePassword()) return;
    if (password !== passwordRepeat) {
      setPasswordRepeatInvalid("Passwords doesn't match");
      return;
    }

    setSubmitState(true);

    try {
      delete userAttr.email;
      delete userAttr.email_verified;
      await new Promise<void>((resolve, reject) => {
        user.completeNewPasswordChallenge(password, userAttr, {
          onSuccess: () => resolve(),
          onFailure: reject,
        });
      });
      history.push((location.state as any)?.targetPath || "/");
      setSubmitState(false);
      showSuccess({
        title: "Success!",
        description: "You've successfully set you new password",
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setSubmitState(false);
      showError({
        title: "Something went wrong...",
        description: "Unable to set new password",
      });
    }
  };

  return (
    <>
      <p className="logInContainer__title">New password</p>
      <p className="logInContainer__description">
        Please create your new password. Your password must be at least 8
        characters long.
      </p>
      <form className="logInContainer__form" onSubmit={submit}>
        <div className="logInContainer__field-block">
          <TextInput
            disabled={submitInProgress}
            type="password"
            id="password"
            name="password"
            placeholder="Create password"
            required
            onChange={(value) => {
              setPassword(value);
              setPasswordInvalid("");
            }}
            error={passwordInvalid}
          />
        </div>
        <div className="logInContainer__field-block">
          <TextInput
            disabled={submitInProgress}
            type="password"
            id="repeatPassword"
            name="repeatPassword"
            placeholder="Re-enter password"
            required
            onChange={(value) => {
              setPasswordRepeat(value);
              setPasswordRepeatInvalid("");
            }}
            error={passwordRepeatInvalid}
          />
        </div>
        <SubmitButton text="Submit new password" status={submitButtonStatus} />
      </form>
    </>
  );
}
