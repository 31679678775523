import { MODE } from "../constants";
import sendGAEvent from "./send-GA-event";
import { logError, logInfo } from "./logger";
import { Patient } from "../types/Entities/Patient";

export function analyticsIdentify(patient?: Patient) {
  try {
    ttq.identify({
      external_id: patient?.accountId,
      email: patient?.email,
      phone_number: patient?.phone,
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSurveyStarted() {
  if (MODE !== "PROD") return;

  try {
    sendGAEvent("create", "UA-173037697-1", "auto");
    sendGAEvent("send", "event", "Survey", "Started");
    gtag("event", "conversion", {
      send_to: "AW-350977296/7RDsCMLUookDEJD6racB",
    });
    ttq.track("Survey Started", {
      contents: [
        {
          content_name: "Survey Start", // string. The name of the page or product. Example: "shirt".
        },
      ],
      value: "0", // number. Value of the order or items sold. Example: 100.
      currency: "USD", // string. The 4217 currency code. Example: "USD".
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendAppointmentScheduled() {
  if (MODE !== "PROD") return;

  try {
    sendGAEvent("send", "event", "Survey", "Appointment scheduled");
    gtag("event", "conversion", {
      send_to: "AW-350977296/E58ZCLCqpYkDEJD6racB",
    });
    fbq("track", "Schedule");
    ttq.track("Appointment Scheduled", {
      contents: [
        {
          content_name: "Appointment Schedule Calendar", // string. The name of the page or product. Example: "shirt".
        },
      ],
      value: "0", // number. Value of the order or items sold. Example: 100.
      currency: "USD", // string. The 4217 currency code. Example: "USD".
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSignUpEvent() {
  if (MODE !== "PROD") return;

  try {
    gtag("event", "conversion", {
      send_to: "AW-350977296/jX-sCPiM-c0DEJD6racB",
    });
    ttq.track("CompleteRegistration", {
      contents: [
        {
          content_name: "Sign Up Page", // string. The name of the page or product. Example: "shirt".
        },
      ],
      value: "0", // number. Value of the order or items sold. Example: 100.
      currency: "USD", // string. The 4217 currency code. Example: "USD".
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendCardSavedEvent() {
  if (MODE !== "PROD") return;

  try {
    gtag("event", "conversion", {
      send_to: "AW-350977296/HAUKCLXf-c0DEJD6racB",
      value: 0.0,
      currency: "USD",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSurveyEnded() {
  if (MODE !== "PROD") return;

  try {
    sendGAEvent("send", "event", "Survey", "Ended");
    gtag("event", "conversion", {
      send_to: "AW-350977296/TcEoCIfk-YgDEJD6racB",
    });
    fbq("track", "CompleteRegistration");
    ttq.track("Survey Complete", {
      contents: [
        {
          content_name: "Survey End", // string. The name of the page or product. Example: "shirt".
        },
      ],
      value: "0", // number. Value of the order or items sold. Example: 100.
      currency: "USD", // string. The 4217 currency code. Example: "USD".
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendPaymentSuccess(amountPaid: number) {
  const amount = (amountPaid || 0) / 100;

  if (MODE !== "PROD") return;

  try {
    sendGAEvent(
      "send",
      "event",
      "Survey",
      "Payment successful",
      "Custom label",
      Math.round(amount)
    );

    fbq("track", "Purchase", { value: amount, currency: "USD" });
    gtag("event", "conversion", {
      send_to: "AW-350977296/MHnhCNOVw48DEJD6racB",
      currency: "USD",
      value: Math.round(amount),
      transaction_id: "",
    });
    ttq.track("CompletePayment", {
      contents: [
        {
          content_name: "Checkout Page", // string. The name of the page or product. Example: "shirt".
          content_id: "honeydew-checkout-page",
        },
      ],
      value: `${amount}`, // number. Value of the order or items sold. Example: 100.
      currency: "USD", // string. The 4217 currency code. Example: "USD".
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSurveyQuestion(questionName: string) {
  if (MODE !== "PROD") return;
  logInfo("Sending survey question answered event", {
    question: questionName,
  });
  try {
    sendGAEvent("send", "event", "Survey", "Question answered", questionName);
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}
