import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient } from "../../../../../types/Entities/Patient";
import { SubmitFollowUpPayload } from "../../../../../types/Payloads/SubmitFollowUp";
import { AuthenticationService } from "../../../../cognito";

export const submitFollowUp = async (data: SubmitFollowUpPayload) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/submit-follow-up`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot submit follow up");
    throw error;
  }
};
