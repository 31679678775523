import React, { useContext, useState } from "react";
import { ReactComponent as VerifyIcon } from "../../../Assets/NewIcons/verify-email.svg";
import "./styles.scss";
import "../Patient/VerifyEmail/styles.scss";
import { TextInput } from "../../NewComponents/Common/TextInput";
import { AuthenticationService } from "../../../services/cognito";
import { Button } from "../../NewComponents/Common/Button";
import { NotificationLayerContext } from "../../NewComponents/Common/NotificationLayer";

interface Props {
  onSuccess: () => void;
  email: string;
}

export function VerifyYourEmail({ onSuccess, email }: Props) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatInvalid, setPasswordRepeatInvalid] = useState("");

  async function resendVerificationCode() {
    await AuthenticationService.resendVerificationCode(email);
  }

  function validatePassword() {
    const lowercaseLettersRegexp = /[a-z]/;
    const numbersRegexp = /\d/;
    if (
      !lowercaseLettersRegexp.test(password) ||
      !numbersRegexp.test(password) ||
      password.length < 8
    ) {
      setPasswordInvalid(
        "Password should be at least 8 characters long, contain at least one lower case letter and one number"
      );
      return false;
    }

    return true;
  }

  async function verify() {
    if (!validatePassword()) return;
    if (password !== passwordRepeat) {
      setPasswordRepeatInvalid("Passwords doesn't match");
      return;
    }

    try {
      await AuthenticationService.resetPassword({
        email,
        verificationCode: code,
        password,
      });
      onSuccess();
    } catch (e: any) {
      showError({
        title: "Error",
        description: e.message,
      });
    }
  }

  return (
    <div className="verify-email-page">
      <VerifyIcon className="verify-email-page__verify-icon" />
      <p className="verify-email-page__title">Password reset</p>
      <p className="verify-email-page__description">
        We've sent an email with verification code to {email}. Please, enter
        verification code and your new password below
      </p>
      <div className="verify-email-page__verification">
        <TextInput
          id="verification-code"
          name="verification-code"
          label="verification code"
          placeholder="123456"
          onChange={(value) => setCode(value)}
        />
        <TextInput
          type="password"
          id="password"
          name="password"
          placeholder="Enter new password"
          required
          onChange={(value) => {
            setPassword(value);
            setPasswordInvalid("");
          }}
          error={passwordInvalid}
        />
        <TextInput
          type="password"
          id="repeatPassword"
          name="repeatPassword"
          placeholder="Repeat password"
          required
          onChange={(value) => {
            setPasswordRepeat(value);
            setPasswordRepeatInvalid("");
          }}
          error={passwordRepeatInvalid}
        />
        <Button text="submit" onClick={verify} />
      </div>
      <p className="verify-email-page__resend-email">
        <span>Didn't get the email?</span>
        <span
          className="verify-email-page__resend-email__send-again"
          onClick={async () => {
            try {
              await resendVerificationCode();
              showSuccess({
                title: "Success!",
                description: "Verification code has been resent to your email",
              });
            } catch {
              showError({
                title: "Something went wrong",
                description:
                  "Could not resend verification code. Please, try again later",
              });
            }
          }}
        >
          Send it again
        </span>
      </p>
    </div>
  );
}
