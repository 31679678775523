export const hidradentitisSubQuestions = [
  {
    label:
      "Have there been any changes to your medical history since your last visit?",
    value: "isMedicalHistoryChanges",
    questionType: "hidradentitis",
    hasChild: true,
    childId: "isMedicalHistoryChanges",
    radioButtonsForm: true,
    placeholder: "Please elaborate",
    description:
      "(e.g. new medications, medical conditions, surgeries or procedures)",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Please let your provider know how you've been using your recommended treatments",
    value: "treatmentSurvey",
    isRecommendedTreatment: true,
    questionType: "hidradentitis",
    options: [
      {
        value: "NORMAL",
        label: "I use it exactly as directed",
      },
      {
        value: "MODIFIED",
        label: "I modified the amount or the frequency of use",
      },
      {
        value: "STOPPED",
        label: "I stopped using this",
      },
    ],
  },
  {
    label:
      "If you are on oral or injectable medications, do you have any eye dryness or eye irritation, body rash, stomach irritation?",
    value: "sideEffects",
    questionType: "eczema",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
      {
        value: "I am not on oral or injectable medication",
        label: "I am not on oral or injectable medication",
      },
    ],
  },
  {
    label: "Do you need a refill of any products?",
    value: "isRefillRequired",
    questionType: "acne",
    hasChild: true,
    childId: "isRefillRequired",
    radioButtonsForm: true,
    placeholder: "Which products would you like to refill?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "On a scale of 1-5, how would you describe the amount of cysts or boils present?",
    description:
      "1 (totally clear) → 5 (armpits or groin still have active lesions)",
    value: "cystAmount",
    questionType: "eczema",
    isInputRatingType: true,
    maxNumber: 5,
    options: [],
  },
  {
    label: "How would you describe your level of pain or inflammation?",
    description: "1 (none at all) → 10 (extremely tender to the touch)",
    value: "painLevel",
    questionType: "eczema",
    isInputTypeNumber: true,
    maxNumber: 10,
    options: [],
  },
  {
    label: "How do you feel about your progress?",
    value: "progressFeelings",
    questionType: "eczema",
    isTextArea: true,
    textArea: true,
    options: [
      {
        value: "How do you feel about your progress?",
        label: "How do you feel about your progress?",
      },
    ], // open text
  },
  {
    label: "Do you have any specific questions or comments for your provider?",
    value: "additionalQuestions",
    questionType: "eczema",
    textArea: true,
    options: [
      {
        value:
          "Do you have any specific questions or comments for your provider?",
        label:
          "Do you have any specific questions or comments for your provider?",
      },
    ], // open text
  },
  {
    label: 'Would you still describe your stress level as "part from survey"?',
    value: "stressLevel",
    questionType: "lifestyle",
    options: [
      { value: "Never stressed", label: "Never stressed" },
      { value: "Almost never stressed", label: "Almost never stressed" },
      { value: "Sometimes stressed", label: "Sometimes stressed" },
      { value: "Fairly stressed", label: "Fairly stressed" },
      { value: "Very stressed", label: "Very stressed" },
    ],
  },
  {
    label: "In the last month, how would you describe your sleep?",
    value: "sleepAmount",
    options: [
      { value: "5 hours or less", label: "5 hours or less" },
      { value: "Between 6-7 hours", label: "Between 6-7 hours" },
      { value: "8+ hours", label: "8+ hours" },
    ],
  },
];
