import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { show } from "../../../../features/errorNotification";
import { deleteProviderInStore } from "../../../../features/providers";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { ProvidersReportPickerPopupGenerator } from "../../../NewComponents/Administrator/ReportPickerPopup";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { DeleteConfirmainPopupContext } from "../../Common/DeleteConfirmationPopup";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { Provider } from "../../../../types/Employee";
import { useArchiveEmployeeMutation } from "../../../../features/api/employees";
import { NotificationLayerContext } from "../../Common/NotificationsLayout";
import { ReactComponent as OptionsIcon } from "../../../../Assets/icons/action-options.svg";
import { ReactComponent as TimeOffIcon } from "../../../../Assets/icons/Vector.svg";
import { ReactComponent as ReportIcon } from "../../../../Assets/icons/icon.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/pencil.svg";
import { ReactComponent as ArchiveIcon } from "../../../../Assets/icons/delete.svg";
import { ReactComponent as TimeOff } from "../../../../Assets/NewIcons/time-off.svg";
import { TimeOffHistoryPopup } from "../../../Pages/Administrator/TimeOffHistoryPopup";

interface Props {
  providersList: TableBodyPayload<Provider>[];
  showLicensesColumn: boolean;
}

export function ProvidersTableBody({
  providersList,
  showLicensesColumn,
}: Props) {
  const { showPopup } = useContext(PopupLayerContext);
  const { ask } = useContext(DeleteConfirmainPopupContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [archiveEmployee] = useArchiveEmployeeMutation();

  async function archiveProvider(id: string) {
    const result: any = await archiveEmployee({
      employeeId: id,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (result.error || result.data?.errors) {
      showError({
        title: "Something went wrong...",
        description: "Unable to perform this action",
      });
      throw new Error("Unable to archive a provider");
    }

    showSuccess({
      title: "Success!",
      description: `Provider has been archived successfully`,
    });
  }

  function askUserForConfirmation(id: string) {
    ask(archiveProvider.bind(null, id), "archive");
  }

  return (
    <>
      {providersList.map(({ entity: provider }) => (
        <tr>
          <td>
            {provider.firstName} {provider.lastName}
          </td>
          <td>{provider.title}</td>
          <td style={{ minWidth: "100px" }}>{provider.npiNumber}</td>
          {showLicensesColumn && (
            <td style={{ minWidth: "150px" }}>
              {provider.stateLicenses && provider.stateLicenses[0] ? (
                provider.stateLicenses.map((e, index) => (
                  <span key={e.license}>
                    <span style={{ fontWeight: "bold" }}>{`State: `}</span>
                    {e.state || ""}
                    <br />
                    <span style={{ fontWeight: "bold" }}>{`License# `}</span>
                    {e.license}
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Expiration Date: &nbsp;
                    </span>
                    {e.expiry}
                    <br />
                    ----------
                    <br />
                  </span>
                ))
              ) : (
                <span>-</span>
              )}
            </td>
          )}
          <td>
            {provider.email && (
              <a className="row-text-color" href={`mailto:${provider.email}`}>
                {provider.email}
              </a>
            )}
          </td>
          <td>
            {provider.phone && (
              <a className="row-text-color" href={`tel:${provider.phone}`}>
                {provider.phone}
              </a>
            )}
          </td>
          <td className="patientsTable__actionsList">
            <span
              onClick={async () => {
                showPopup(TimeOffHistoryPopup({ employee: provider }));
              }}
            >
              <TimeOff />
            </span>
            <span
              onClick={async () => {
                showPopup(EmployeePopupGenerator(provider));
              }}
            >
              <EditIcon />
            </span>
            <span
              onClick={async () => {
                showPopup(
                  ProvidersReportPickerPopupGenerator({
                    providerId: provider.id,
                  })
                );
              }}
            >
              <ReportIcon />
            </span>
            <span
              onClick={() => {
                askUserForConfirmation(provider.id);
              }}
            >
              <ArchiveIcon />
            </span>
            {/* <button
              type="button"
              title="Get report"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(
                  ProvidersReportPickerPopupGenerator({
                    providerId: provider.id,
                  })
                );
              }}
            >
              <div className="patientsTable__actionButton--schedule" />
            </button>
            <button
              type="button"
              title="Edit"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(EmployeePopupGenerator(provider));
              }}
            >
              <div className="patientsTable__actionButton--edit" />
            </button>
            {provider.archived ? null : (
              <button
                type="button"
                title="Archive"
                className="patientsTable__actionButtonWithIcon"
                onClick={() => {
                  askUserForConfirmation(provider.id);
                }}
              >
                <div className="patientsTable__actionButton--delete" />
              </button>
            )} */}
          </td>
        </tr>
      ))}
    </>
  );
}
