import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelpPopupTemplate } from "../PopupLayer/help";
import { TextInput } from "../TextInput";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";
import "./style.scss";
import { DocumentsForm, Task } from "../../Provider/DocumentOverviewPopup";
import { ProviderTask } from "../../../../types/Provider";
import { NotificationLayerContext } from "../NotificationLayer";
import { getSessionState } from "../../../../features/session";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { USER_ROLES } from "../../../../types/Main";
import {
  useAddDocumentCommentMutation,
  useGetTasksQuery,
  useUpdateTaskStatusMutation,
} from "../../../../features/api/accutane";
import { PopupLayerContext } from "../PopupLayer";

interface SignTheDocumentPopupProps {
  onClose: () => void;
  userId: string;
  documentId: string;
  employeeId: string;
  documentsForm: DocumentsForm;
}

interface SignTheDocumentPopupForm {
  eSignature: string;
  submitted: boolean;
}

// TODO eSignature value for now isn't send to backend because of no need
function SignTheDocumentPopup({
  onClose,
  userId,
  documentId,
  employeeId,
  documentsForm,
}: SignTheDocumentPopupProps) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const { closeAllPopups } = useContext(PopupLayerContext);
  const { userRole } = useSelector(getSessionState);
  const employeeQuery = useGetEmployeeQuery(employeeId);
  const getTasksQuery = useGetTasksQuery({
    patientId: userId,
    documentId,
    employeeId,
  });
  const [updateTaskStatus] = useUpdateTaskStatusMutation();

  const [addDocumentComment] = useAddDocumentCommentMutation();
  const [signDocumentForm, setFormField] = useState<SignTheDocumentPopupForm>({
    eSignature: "",
    submitted: false,
  });
  const submitDisabled =
    !signDocumentForm.submitted || !signDocumentForm.eSignature.length;

  const handleEsignatureChange = (eSignature: string) =>
    setFormField({ ...signDocumentForm, eSignature });
  const handleToggleSubmit = () =>
    setFormField({
      ...signDocumentForm,
      submitted: !signDocumentForm.submitted,
    });

  const onSubmitForm = async () => {
    if (documentsForm.currentTask) {
      const taskToCompleteKey = getTasksQuery.data?.find(
        (task: ProviderTask) => task.id === documentsForm.currentTask
      )?.compositeKey;
      if (taskToCompleteKey) {
        const updatedBy = {
          id: employeeId || "",
          role: employeeQuery.data?.role || "",
        };

        const result: any = await updateTaskStatus({
          taskId: documentsForm.currentTask,
          compositeKey: taskToCompleteKey,
          patientId: userId,
          updatedBy,
          status: "COMPLETED",
        });
        if (result.error) {
          showError({
            title: "Something went wrong...",
            description: "Unable to complete task",
          });
          return;
        }

        if (employeeQuery.data && userRole) {
          const signedBy = {
            id: employeeId,
            name: `${employeeQuery.data.firstName} ${
              employeeQuery.data.lastName
            }${
              employeeQuery.data.role === USER_ROLES.PROVIDER
                ? `, ${employeeQuery.data.title}`
                : ""
            }`,
            role: userRole,
            createdAt: new Date().toISOString(),
          };
          const documentCommentUpdate: any = await addDocumentComment({
            documentId,
            patientId: userId,
            signedBy,
            comment: (documentsForm.comment as Task).label,
          });
          if (documentCommentUpdate.error) {
            showError({
              title: "Something went wrong...",
              description: "Unable to add comment to the document",
            });
            return;
          }
          showSuccess({
            title: "Success",
            description: "Task successfully completed",
          });
        }
      }
    }
    onClose();
    closeAllPopups();
  };

  return (
    <div className="sign-up-document-popup">
      <p className="sign-up-document-popup__title">Sign the document</p>
      <div className="sign-up-document-popup__e-signature">
        <p className="sign-up-document-popup__e-signature__title">
          Type your name to provide an e-signature
        </p>
        <TextInput
          onChange={handleEsignatureChange}
          id="eSignatureInput"
          name="eSignatureInput"
          placeholder="Enter your full name to sign the document"
        />
        <div className="sign-up-document-popup__e-signature__submit">
          <Checkbox onChange={handleToggleSubmit}>
            I submit that this document was reviewed and signed by me, as named
            above, and consent to using an e-signature.
          </Checkbox>
        </div>
      </div>
      <div className="sign-up-document-popup__control-buttons">
        <Button text="cancel" view="secondary" onClick={onClose} size="small" />
        <Button
          text="submit & sign"
          size="small"
          disabled={submitDisabled}
          onClick={onSubmitForm}
        />
      </div>
    </div>
  );
}

function SignTheDocumentPopupGenerator(
  userId: string,
  documentId: string,
  employeeId: string,
  documentsForm: DocumentsForm
) {
  return function render({ onClose }: { onClose: () => void }) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <SignTheDocumentPopup
          onClose={onClose}
          userId={userId}
          documentId={documentId}
          employeeId={employeeId}
          documentsForm={documentsForm}
        />
      </HelpPopupTemplate>
    );
  };
}

export default SignTheDocumentPopupGenerator;
