import React, { useState } from "react";
import "./style.scss";
import showIcon from "../../../../Assets/icons/view.png";
import hideIcon from "../../../../Assets/icons/hide.png";

interface Props {
  type?: "text" | "password" | "number";
  required?: boolean;
  disabled?: boolean;
  value?: string;
  label?: string;
  error?: string | null;
  id: string;
  name: string;
  className?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  readOnly?: boolean;
  autocomplete?: string;
  closeIcon?: JSX.Element;
}

export function TextInput({
  type = "text",
  required,
  disabled,
  value,
  label,
  error,
  onChange,
  id,
  name,
  placeholder,
  className,
  onBlur,
  autocomplete,
  readOnly = false,
  closeIcon,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  function renderLabel() {
    if (!label) return null;
    return (
      <label
        className="text-input__label label-font label-font--color"
        htmlFor={id}
      >
        {label}
        {required ? " *" : null}
      </label>
    );
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`text-input ${className || ""}`}>
      {renderLabel()}
      <input
        className={`text-input__field input-font input-font--color${
          error ? " text-input__field--error" : ""
        }`}
        type={type === "password" && !showPassword ? "password" : "text"}
        onClick={onBlur}
        onChange={(event) => onChange(event.target.value)}
        maxLength={type === "password" ? 40 : undefined}
        required={required}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        autoComplete={autocomplete}
      />
      {type === "password" && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="password-toggle-icon"
        >
          {showPassword ? (
            <img height={20} width={20} src={showIcon} alt="Show Password" />
          ) : (
            <img height={20} width={20} src={hideIcon} alt="Hide Password" />
          )}
        </button>
      )}
      {closeIcon && closeIcon}
      {error ? (
        <p className="text-input__error-text error-font error-font--color">
          {error}
        </p>
      ) : null}
    </div>
  );
}
