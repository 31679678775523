import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EnrollmentCoordinatorsTableBody } from "./tableBody";
import "./style.css";
import { EnrollmentCoordinatorsTableHead } from "./tableHead";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { TableItemValue } from "../../../../types/Table/TableItemValue";
import { SortingPayload } from "../../../../types/Table/SortingPayload";
import { SortingFunction } from "../../../../types/Table/SortingFunction";
import { getEnrollmentCoordinatorsInfoState } from "../../../../features/enrollmentCoordinators";
import { useGetEmployeesByRoleQuery } from "../../../../features/api/employees";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { USER_ROLES } from "../../../../types/Main";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { Button } from "../../../NewComponents/Common/Button";
import { EnrollmentCoordinator } from "../../../../types/Employee";
import { PatientsTablePagination } from "../../../NewComponents/Common/PatientsTableElastic/tablePagination";

function alphabetical(a: TableItemValue, b: TableItemValue) {
  const c = typeof a === "string" ? a.toLowerCase() : a;
  const d = typeof b === "string" ? b.toLowerCase() : b;
  if (c > d) return 1;
  if (c < d) return -1;
  return 0;
}

const columnsList: TableHeadPayload<TableBodyPayload<EnrollmentCoordinator>>[] =
  [
    {
      title: "Full Name",
      get: ({ entity }) => `${entity.lastName}`,
      sort: alphabetical,
    },
    {
      title: "Email",
      get: ({ entity }) => entity.email,
      sort: alphabetical,
    },
    {
      title: "Phone",
      get: ({ entity }) => entity.phone,
      sort: alphabetical,
    },
    {
      title: "Actions",
    },
  ];

function setOrder(func: SortingFunction, order: "asc" | "desc") {
  return (a: TableItemValue, b: TableItemValue) =>
    order === "asc" ? func(a, b) : func(b, a);
}

function sortByColumn(
  enrollmentCoordinatorsList: TableBodyPayload<EnrollmentCoordinator>[],
  { column: columnName, state }: SortingPayload
) {
  const column = columnsList.find(({ title }) => title === columnName);
  if (!column) return enrollmentCoordinatorsList;
  if (!column.sort && !column.get) return enrollmentCoordinatorsList;
  return enrollmentCoordinatorsList.sort((a, b) =>
    setOrder(column.sort as SortingFunction, state)(
      (
        column.get as (
          item: TableBodyPayload<EnrollmentCoordinator>
        ) => string | number | Date
      )(a),
      (
        column.get as (
          item: TableBodyPayload<EnrollmentCoordinator>
        ) => string | number | Date
      )(b)
    )
  );
}

function defaultSort(
  enrollmentCoordinatorsList: TableBodyPayload<EnrollmentCoordinator>[],
  sorting: SortingPayload | null
) {
  const result = Array.of(enrollmentCoordinatorsList);

  if (sorting) {
    result.forEach((list) => sortByColumn(list, sorting));
  }

  return result.reduce((acc, el) => [...acc, ...el], []);
}

const processEnrollmentCoordinatorsList = (
  enrollmentCoordinatorsList: EnrollmentCoordinator[],
  sorting: SortingPayload | null
) => {
  const filteredEnrollmentCoordinatorsList = enrollmentCoordinatorsList.filter(
    (obj) => obj.archived !== true
  );

  const processedEnrollmentCoordinatorsList: TableBodyPayload<EnrollmentCoordinator>[] =
    filteredEnrollmentCoordinatorsList.map((enrollmentCoordinator) => ({
      isNewMessage: false,
      entity: enrollmentCoordinator,
      nextFollowUpDate: null,
      rowClass: null,
      type: null,
    }));

  return defaultSort(processedEnrollmentCoordinatorsList, sorting);
};

export function EnrollmentCoordinatorsTable() {
  const role = USER_ROLES.ENROLLMENT_COORDINATOR;
  const { showPopup } = useContext(PopupLayerContext);
  const enrollmentCoordinatorsQuery = useGetEmployeesByRoleQuery(role);
  const [sort, setSort] = useState<SortingPayload | null>(null);
  const [
    defaultSortedEnrollmentCoordinatorsData,
    setDefaultSortedEnrollmentCoordinatorsData,
  ] = useState();

  useEffect(() => {
    if (
      enrollmentCoordinatorsQuery.data &&
      Array.isArray(enrollmentCoordinatorsQuery.data)
    ) {
      const sortedData = [...enrollmentCoordinatorsQuery.data].sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
      setDefaultSortedEnrollmentCoordinatorsData(sortedData);
    }
  }, [enrollmentCoordinatorsQuery.data]);

  return defaultSortedEnrollmentCoordinatorsData ? (
    <>
      {/* <div className="medicine-button-wrapper">
        <Button
          text="Create new enrollment coordinator"
          onClick={() => showPopup(EmployeePopupGenerator(undefined, role))}
        />
      </div> */}
      <div className="patientsTable-wrapper">
        <table className="patientsTable">
          <EnrollmentCoordinatorsTableHead
            columns={columnsList}
            onSortChange={(value) => setSort(value)}
          />
          <EnrollmentCoordinatorsTableBody
            enrollmentCoordinatorsList={processEnrollmentCoordinatorsList(
              defaultSortedEnrollmentCoordinatorsData as EnrollmentCoordinator[],
              sort
            )}
          />
        </table>
        <PatientsTablePagination
          max={Math.ceil(100 / 10)}
          current={0}
          onChange={(it) => console.log("Paginations added")}
          simple
        />
      </div>
    </>
  ) : (
    <Skeleton flex count={5} fullWidth column style={{ height: 60 }} />
  );
}
