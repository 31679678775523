import { USER_ROLES } from "../types/Main";

export function getFullType(type: USER_ROLES) {
  switch (type) {
    case "provider":
      return "Provider";
    case "care-coordinator":
      return "Care Coordinator";
    case "enrollment-coordinator":
      return "Enrollment Coordinator";
    case "administrator":
      return "Administrator";
    case "patient":
      return "Patient";
    default:
      return "Unknown";
  }
}
