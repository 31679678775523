import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import moment from "moment";
import { Button } from "../../Common/Button";
import {
  getAccutaneOfPatient,
  setCareCoordinatorAccutaneInfo,
} from "../../../../features/careCoordinators";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { PopupLayerContext } from "../../Common/PopupLayer";
import ConfirmIpledgePopupGenerator from "../../Provider/ConfirmIpledgePopup";
import { setEnrollmentCoordinatorAccutaneInfo } from "../../../../features/enrollmentCoordinators";
import { ReactComponent as EditIcon } from "../../../../Assets/NewIcons/pencil.svg";
import { Accutane } from "../../../../types/Entities/Accutane";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { TextInput } from "../../Common/TextInput";
import "./style.scss";
import { ReactComponent as CrossIcon } from "../../../../Assets/NewIcons/close-cross.svg";
import {
  useGetAccutaneByPatientIdQuery,
  useUpdateIpledgeDetailsMutation,
  useSetRemNumberMutation,
  useEnrollAccutaneMutation,
} from "../../../../features/api/accutane";
import isValidDate from "../../../../utils/is-valid-date";
import { ConfirmChoicePopupGenerator } from "../../../Pages/Provider/ConfirmChoicePopup";
import {
  useGetPatientByIdQuery,
  useSetFlagMutation,
} from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import { InfoBlock } from "../../Common/InfoBlock";

const NEXT_CONFIRMATION_DATE_OFFSET = 30;

const formatDate = (dateString: string | undefined): string => {
  if (dateString === "--" || dateString === "N/A") {
    return "--";
  }
  if (dateString && dateString !== "N/A") {
    return getMomentDate(dateString).format("MM/DD/YY");
  }
  return "N/A";
};

enum IpledgeDetailsViews {
  info = "info",
  edit = "edit",
}

enum EditIpledgeFormFields {
  remsNumber = "remsNumber",
  enrollmentDate = "enrollmentDate",
  lastConfirmationDate = "lastConfirmationDate",
  nextConfirmationDate = "nextConfirmationDate",
}

interface IEditIpledgeFormState {
  [EditIpledgeFormFields.remsNumber]: string;
  [EditIpledgeFormFields.enrollmentDate]: string;
  [EditIpledgeFormFields.lastConfirmationDate]: string;
  [EditIpledgeFormFields.nextConfirmationDate]: string;
}

function getInitialDate(date: string | undefined, addition = 0) {
  if (date && date !== "N/A" && date !== "--") {
    return getMomentDate(date).add(addition, "days").format("MM/DD/YY");
  }
  return getMomentDate(Date.now()).add(addition, "days").format("MM/DD/YY");
}

function EditView({
  accutane,
  changeViewHandler,
  patientId,
  isNotPregnantCapability,
}: {
  accutane: Accutane;
  changeViewHandler: (view: IpledgeDetailsViews) => void;
  patientId: string;
  isNotPregnantCapability: boolean;
}) {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [updateIpledgeDetails] = useUpdateIpledgeDetailsMutation();
  const [isNonPregnantCapability, setIsNonPregnantCapability] = useState(
    isNotPregnantCapability
  );
  const [enrollAccutane] = useEnrollAccutaneMutation();
  const { showPopup } = useContext(PopupLayerContext);
  const { userId } = useSelector(getSessionState);
  const [shouldClose, setClose] = useState({
    enrollment: true,
    lastConfirmation: true,
    nextConfirmation: true,
  });

  const flag = "isNotPregnantCapability";
  const [setFlag] = useSetFlagMutation();
  const [setRemsNumber] = useSetRemNumberMutation();

  const [editForm, setFormField] = useState<IEditIpledgeFormState>({
    remsNumber: accutane.remsNumber.toString(),
    enrollmentDate: isValidDate(accutane.enrollmentDate)
      ? getInitialDate(accutane.enrollmentDate)
      : "",
    lastConfirmationDate:
      accutane.lastConfirmationDate?.length &&
      isValidDate(accutane.lastConfirmationDate)
        ? getInitialDate(accutane.lastConfirmationDate)
        : "",
    nextConfirmationDate:
      (!accutane.lastConfirmationDate ||
        !isValidDate(accutane.lastConfirmationDate)) &&
      accutane.gender === "Male"
        ? ""
        : getInitialDate(
            accutane.lastConfirmationDate || accutane.enrollmentDate,
            30
          ),
  });

  const onSubmit = async () => {
    let nextConfirmationDate = "";
    if (editForm[EditIpledgeFormFields.nextConfirmationDate].length) {
      nextConfirmationDate = new Date(
        editForm[EditIpledgeFormFields.nextConfirmationDate]
      ).toISOString();
    } else if (accutane.gender === "Male") {
      nextConfirmationDate = "--";
    }
    let result: any;
    if (
      !isValidDate(accutane.enrollmentDate) &&
      !isValidDate(editForm[EditIpledgeFormFields.enrollmentDate])
    ) {
      result = await setRemsNumber({
        patientId,
        remsNumber: editForm[EditIpledgeFormFields.remsNumber],
      });
      await enrollAccutane({
        patientId,
        taskId: accutane.taskId,
      });
    } else {
      result = await updateIpledgeDetails({
        patientId,
        enrollmentDate: new Date(
          editForm[EditIpledgeFormFields.enrollmentDate]
        ).toISOString(),
        lastConfirmationDate: editForm[
          EditIpledgeFormFields.lastConfirmationDate
        ].length
          ? new Date(
              editForm[EditIpledgeFormFields.lastConfirmationDate]
            ).toISOString()
          : "",
        nextConfirmationDate,
        remsNumber: editForm[EditIpledgeFormFields.remsNumber],
        employeeId: userId,
      });
    }
    const response: any = await setFlag({
      patientId,
      flag,
      state: isNonPregnantCapability,
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to update Ipledge details",
      });
      return;
    }
    showSuccess({
      title: "Success",
      description: "iPledge updated successfully",
    });
    changeViewHandler(IpledgeDetailsViews.info);
  };

  const onRemsChange = (value: string) => {
    if (Number.isNaN(parseInt(value, 10))) {
      setFormField({ ...editForm, [EditIpledgeFormFields.remsNumber]: "" });
    }

    setFormField({ ...editForm, [EditIpledgeFormFields.remsNumber]: value });
  };
  const onEnrollmentChange = (value: any) =>
    setFormField({
      ...editForm,
      [EditIpledgeFormFields.enrollmentDate]: value,
    });

  const onLastConfirmationChange = (
    lastConfirmationDate: string,
    nextConfirmationDate: string
  ) =>
    setFormField({
      ...editForm,
      [EditIpledgeFormFields.lastConfirmationDate]: lastConfirmationDate,
      [EditIpledgeFormFields.nextConfirmationDate]: nextConfirmationDate,
    });
  const onNextConfirmationChange = (value: any) =>
    setFormField({
      ...editForm,
      [EditIpledgeFormFields.nextConfirmationDate]: value,
    });

  return (
    <div className="ipledge-details">
      <p className="ipledge-details__title">iPledge details</p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Patient {isNonPregnantCapability ? "CANNOT" : "CAN"} get pregnant
        </span>
        <div>
          <label className="switch">
            <input
              type="checkbox"
              onChange={() =>
                showPopup(
                  ConfirmChoicePopupGenerator(
                    !isNonPregnantCapability,
                    setIsNonPregnantCapability,
                    "Are you sure you want to change patient’s child bearing potential?"
                  )
                )
              }
              checked={!isNonPregnantCapability}
            />
            <span className="slider round" />
          </label>
        </div>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">REMS number</span>
        <TextInput
          id="editRemsNumber"
          name="editRemsNumber"
          value={editForm[EditIpledgeFormFields.remsNumber]}
          onChange={onRemsChange}
        />
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Date of enrollment
        </span>
        <span className="ipledge-details__item-content">
          <TextInput
            id="enrollmentInput"
            name="enrollmentInput"
            value={editForm[EditIpledgeFormFields.enrollmentDate]}
            onChange={() => null}
            onBlur={() => {
              setClose({ ...shouldClose, enrollment: false });
            }}
          />
          <CrossIcon
            className={`ipledge-details__item-content__calendar__close ${
              shouldClose.enrollment ? "closed" : ""
            }`}
            onClick={() => setClose({ ...shouldClose, enrollment: true })}
          />
          <Calendar
            calendarType="US"
            minDetail="month"
            defaultValue={
              (accutane.enrollmentDate &&
                new Date(accutane.enrollmentDate || 0)) ||
              undefined
            }
            onChange={(_date: any) => {
              onEnrollmentChange(moment(_date as Date).format("MM/DD/YY"));
              setClose({ ...shouldClose, enrollment: true });
            }}
            prev2Label={null}
            next2Label={null}
            className={`ipledge-details__item-content__calendar ${
              shouldClose.enrollment ? "closed" : ""
            }`}
          />
        </span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Last confirmation date
        </span>
        <span className="ipledge-details__item-content">
          <TextInput
            id="lastConfirmationInput"
            name="lastConfirmationInput"
            value={editForm[EditIpledgeFormFields.lastConfirmationDate]}
            onChange={() => null}
            onBlur={() => {
              setClose({ ...shouldClose, lastConfirmation: false });
            }}
            closeIcon={
              <CrossIcon
                className="ipledge-details__item-content__clear-icon"
                onClick={() => {
                  setClose({ ...shouldClose, lastConfirmation: true });
                  onLastConfirmationChange("", editForm.nextConfirmationDate);
                }}
              />
            }
          />
          <CrossIcon
            className={`ipledge-details__item-content__calendar__close ${
              shouldClose.lastConfirmation ? "closed" : ""
            }`}
            onClick={() => setClose({ ...shouldClose, lastConfirmation: true })}
          />
          <Calendar
            calendarType="US"
            minDetail="month"
            defaultValue={
              (accutane.lastConfirmationDate &&
                accutane.lastConfirmationDate !== "--" &&
                accutane.lastConfirmationDate !== "N/A" &&
                new Date(accutane.lastConfirmationDate || 0)) ||
              undefined
            }
            onChange={(_date: any) => {
              const date = _date as Date;
              const calculatedNextConfirmationDate = moment(
                new Date(
                  date.getTime() +
                    24 * 60 * 60 * 1000 * NEXT_CONFIRMATION_DATE_OFFSET
                )
              ).format("MM/DD/YY");
              onLastConfirmationChange(
                moment(date).format("MM/DD/YY"),
                calculatedNextConfirmationDate
              );
              setClose({ ...shouldClose, lastConfirmation: true });
            }}
            prev2Label={null}
            next2Label={null}
            className={`ipledge-details__item-content__calendar ${
              shouldClose.lastConfirmation ? "closed" : ""
            }`}
          />
        </span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Next confirmation date
        </span>
        <span className="ipledge-details__item-content">
          <TextInput
            id="nextConfirmationInput"
            name="nextConfirmationInput"
            value={editForm[EditIpledgeFormFields.nextConfirmationDate]}
            onChange={() => null}
            onBlur={() => {
              setClose({ ...shouldClose, nextConfirmation: false });
            }}
          />
          <CrossIcon
            className={`ipledge-details__item-content__calendar__close ${
              shouldClose.nextConfirmation ? "closed" : ""
            }`}
            onClick={() => setClose({ ...shouldClose, nextConfirmation: true })}
          />
          <Calendar
            calendarType="US"
            minDetail="month"
            defaultValue={
              (accutane.nextConfirmationDate &&
                accutane.nextConfirmationDate !== "--" &&
                accutane.nextConfirmationDate !== "N/A" &&
                new Date(accutane.nextConfirmationDate || 0)) ||
              undefined
            }
            onChange={(_date: any) => {
              onNextConfirmationChange(
                moment(_date as Date).format("MM/DD/YY")
              );
              setClose({ ...shouldClose, nextConfirmation: true });
            }}
            prev2Label={null}
            next2Label={null}
            className={`ipledge-details__item-content__calendar ${
              shouldClose.nextConfirmation ? "closed" : ""
            }`}
          />
        </span>
      </p>
      <div className="ipledge-details__control-buttons">
        <Button
          text="Cancel"
          view="secondary"
          size="small"
          onClick={() => changeViewHandler(IpledgeDetailsViews.info)}
        />
        <Button text="Save" size="small" onClick={onSubmit} />
      </div>
    </div>
  );
}

function IpledgeDetails({
  patientId,
  isNotPregnantCapability,
}: {
  patientId?: string;
  isNotPregnantCapability: boolean;
}) {
  const [activeView, setView] = useState(IpledgeDetailsViews.info);
  const { data: accutane, isSuccess } = useGetAccutaneByPatientIdQuery(
    patientId as string,
    {
      skip: !patientId,
    }
  );
  const patientData = useGetPatientByIdQuery(patientId as string, {
    skip: !patientId,
  })?.data;
  const { showPopup } = useContext(PopupLayerContext);

  if (!accutane) {
    return <div />;
  }
  const changeViewHandler = (view: IpledgeDetailsViews) => setView(view);

  const enrollmentDate = formatDate(accutane?.enrollmentDate);
  const lastConfirmationDate = formatDate(accutane?.lastConfirmationDate);
  const nextConfirmationDate = formatDate(accutane?.nextConfirmationDate);
  const accutaneStatus = accutane?.status;
  const accutaneBloodwork = patientData?.flags?.isStopBloodWorkTask;

  const handleConfirmIpledge = () => {
    showPopup(
      ConfirmIpledgePopupGenerator(nextConfirmationDate, accutane.patientId)
    );
  };

  function accutaneDuration() {
    const duration = accutane?.confirmationPeriod;

    if (duration.months === 0 && duration.days === 0)
      return (
        <>
          <span className="ipledge-details__title">Status: </span>
          <span>Active</span>
        </>
      );
    if (!duration.past)
      return (
        <>
          <span className="ipledge-details__title">Status: </span>
          <span>
            Active (Month {duration.months}, Day {duration.days})
          </span>
        </>
      );
    if (duration.past)
      return (
        <InfoBlock
          text={
            <>
              <span className="ipledge-details__title">Status: </span> Active
              (Month {duration.months}, {duration.days} days past expected next
              confirmation)
            </>
          }
          view="warn"
        />
      );
    if (duration.months > 0)
      return (
        <>
          <span className="ipledge-details__title">Status: </span>
          <span>
            Active (Month {duration.months}, Day {duration.days})
          </span>
        </>
      );
    return (
      <>
        <span className="ipledge-details__title">Status: </span>
        <span>Active</span>
      </>
    );
  }

  // const showStatusIndicator = () => {
  //   switch (accutaneStatus) {
  //     case "renew":
  //       return accutaneDuration();
  //     case "stop":
  //       return (
  //         <>
  //           <span className="ipledge-details__title">Status:</span>
  //           <span>Course concluded</span>
  //         </>
  //       );
  //     case "pause":
  //       return (
  //         <>
  //           <span className="ipledge-details__title">Status:</span>
  //           <span>
  //             {" "}
  //             Month {accutane?.confirmationPeriod?.months}, Day{" "}
  //             {accutane?.confirmationPeriod?.days} (Paused <br /> until{" "}
  //             {nextConfirmationDate})
  //           </span>
  //         </>
  //       );
  //     default:
  //       return accutaneDuration();
  //   }
  // };

  const showStatusIndicator = () => {
    switch (accutaneStatus) {
      case "renew":
        return "Active";
      case "stop":
        return "Course concluded";
      case "pause":
        return `Pause Accutane untill ${nextConfirmationDate}`;
      default:
        return "Active";
    }
  };

  return activeView === IpledgeDetailsViews.info ? (
    <div className="ipledge-details">
      <EditIcon
        className="ipledge-details__edit-icon"
        onClick={() => changeViewHandler(IpledgeDetailsViews.edit)}
      />
      <p className="ipledge-details__title">iPledge details</p>
      <br />
      <span className="ipledge-details__title">Status: </span>
      <span>{showStatusIndicator()}</span>
      <br />
      <br />
      <span className="ipledge-details__title">Bloodwork: </span>
      <span>{` ${
        accutaneBloodwork ? "Not required" : "Required"
      } for next confirmation `}</span>
      <div>
        <p className="ipledge-details__item">
          <span className="ipledge-details__item-description">
            {`Patient ${
              isNotPregnantCapability ? "cannot" : "can"
            } get pregnant`}
          </span>
        </p>
      </div>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">REMS number</span>
        <span className="ipledge-details__item-content">
          {accutane.remsNumber}
        </span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Date of enrollment
        </span>
        <span className="ipledge-details__item-content">{enrollmentDate}</span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Last confirmation date
        </span>
        <span className="ipledge-details__item-content">
          {lastConfirmationDate}
        </span>
      </p>
      <p className="ipledge-details__item">
        <span className="ipledge-details__item-description">
          Next confirmation date
        </span>
        <span className="ipledge-details__item-content">
          {nextConfirmationDate}
        </span>
      </p>
      <Button
        text="confirm"
        size="small"
        view="secondary"
        className="ipledge-details__confirm-button"
        onClick={handleConfirmIpledge}
      />
    </div>
  ) : (
    <EditView
      accutane={accutane}
      changeViewHandler={changeViewHandler}
      patientId={patientId as string}
      isNotPregnantCapability={isNotPregnantCapability}
    />
  );
}

export default IpledgeDetails;
