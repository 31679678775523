import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import {
  getSessionState,
  setActivePatient,
} from "../../../../features/session";
import {
  ProviderShortMedicalBackgroundTabs,
  ProviderTabs,
} from "../../../../types/Provider";
import DocumentOverviewPopupGenerator from "../../../NewComponents/Provider/DocumentOverviewPopup";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import EmptyTaskView from "../../../Pages/Provider/EmptyTaskView";
import { ChatWidget } from "../../../NewComponents/Common/ChatWidget";
import { EditPatientInfoPopup } from "../../../Components/Common/EditPatientInfoPopup";
import { Tabs } from "../../../NewComponents/Common/Tabs";
import TreatmentHistory from "../../../Components/Common/TreatmentHistory";
import { PatientActionButtons } from "../../../Components/Common/PatientActionButtons";
import FollowUpCard from "../../../NewComponents/Common/FollowUpCard";
import VisitsHistory from "../../../NewComponents/Common/VisitsHistory";
import ExpandableInfo from "../../../NewComponents/ExpandableInfo";
import SendBloodSlipPopupGenerator from "../../../NewComponents/Common/SendBloodSlipPopup";
import CareCoordinatorsDocuments from "../../../NewComponents/CareCoordinator/CareCoordinatorDocuments";
import IpledgeDetails from "../../../NewComponents/CareCoordinator/IpledgeDetails";
import { CareTeamBlock } from "../../../NewComponents/Administrator/CareTeamBlock";
import { ReactComponent as EditIcon } from "../../../../Assets/NewIcons/pencil.svg";
import { getPatientActionsList } from "../../../NewComponents/Patient/WelcomeCard/actions";
import "./style.scss";
import { concatShippingInfo } from "../../../../utils/concat-shipping-info";
import {
  useGetAccutaneByPatientIdQuery,
  useGetDocumentsByPatientIdQuery,
  useGetTasksQuery,
} from "../../../../features/api/accutane";
import {
  useGetCareTeamQuery,
  useGetPatientByIdQuery,
} from "../../../../features/api/patients";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { InsurancePopupGenerator } from "../../../NewComponents/Patient/InsurancePopup";
import { hasActiveMembership } from "../../../../utils/has-active-membership";
import { USER_ROLES } from "../../../../types/Main";
import { Provider, CareCoordinator } from "../../../../types/Employee";
import { getPatientMonthlyTasks } from "../../../../utils/patient/patientMonthlyTasks";
import { getGovtIDDocuments } from "../../../../utils/patient/get-government-issued-id";
import EnrollIpledgePopupGenerator from "../../../Pages/Provider/EnrollIpledgePopup";
import { pregnancyCapabilityStatus } from "../../../../utils/patient/pregnancyCapabilityStatus";
import ReviewNotesSectionPopupGenerator from "../../../Pages/Common/ReviewNotesSectionPopup";

const SECONDS_IN_DAY = 60 * 60 * 24;

interface ActionItem {
  text: string;
  check: () => boolean;
  action: () => void;
  key: string;
}

const careCoordinatorTabsItems = [
  {
    label: ProviderTabs.TREATMENT_HISTORY,
    key: "treatment-history-tab",
  },
  {
    label: ProviderTabs.DOCUMENTS,
    key: "documents-tab",
  },
];

const mapTaskTypeToPopup = (type: string) => {
  switch (type) {
    case "enrollPatient": {
      return EnrollIpledgePopupGenerator;
    }
    case "reviewNotesSection": {
      return ReviewNotesSectionPopupGenerator;
    }
    default: {
      return false;
    }
  }
};

export function PatientDashboardPageForCareCoordinator() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showPopup } = useContext(PopupLayerContext);
  const { userId: employeeId, activePatientId } = useSelector(getSessionState);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [patientTimezone, setPatientTimezone] = useState(null);
  const accutane = useGetAccutaneByPatientIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const careCoordinatorQuery = useGetEmployeeQuery(employeeId as string, {
    skip: !employeeId,
  });
  const tasksQuery = useGetTasksQuery(
    {
      patientId: activePatientId as string,
      employeeId: employeeId as string,
    },
    {
      skip: !activePatientId || !employeeId,
    }
  );
  const careCordTasks = tasksQuery?.data?.filter(
    (task) => task.assignedTo.id === employeeId
  );
  const documentsQuery = useGetDocumentsByPatientIdQuery(
    activePatientId as string,
    { skip: !activePatientId }
  );
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const careTeamQuery = useGetCareTeamQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const providerQuery = useGetEmployeeQuery(
    careTeamQuery.data?.find(({ role }) => role === USER_ROLES.PROVIDER)
      ?.employeeId as string,
    {
      skip: !careTeamQuery.data?.find(
        ({ role }) => role === USER_ROLES.PROVIDER
      )?.employeeId,
      refetchOnMountOrArgChange: true,
    }
  );
  const { userId: patientId } = useParams<{ userId: string }>();

  useEffect(() => {
    dispatch(setActivePatient(patientId || null));
  }, [patientId]);
  useEffect(
    () => () => {
      dispatch(setActivePatient(null));
    },
    []
  );

  useEffect(() => {
    if (appointmentsQuery?.data && appointmentsQuery?.data.length > 0) {
      setAppointmentDate(appointmentsQuery.data[0].startTime);
      setPatientTimezone(appointmentsQuery.data[0]?.inviteeTimezone);
    }
  }, [appointmentsQuery.data]);

  const [currentTab, setTab] = useState(
    ProviderTabs.TREATMENT_HISTORY as string
  );
  const onTabChange = (tab: string) => {
    setTab(tab);
  };

  const careCoordinatorTodos: ActionItem[] = (careCordTasks || [])
    ?.map((task) => ({
      text: task.message,
      check: () =>
        !(
          task.status?.toUpperCase() === "COMPLETED" ||
          task.status?.toUpperCase() === "DELETED"
        ),
      action: () => {
        const linkedDocument = documentsQuery.data?.find(
          (document) => document.id === task.compositeKey.split("_")[2]
        );
        if (linkedDocument && activePatientId && employeeId) {
          showPopup(
            DocumentOverviewPopupGenerator(
              linkedDocument.title,
              activePatientId,
              task.compositeKey.split("_")[2],
              linkedDocument.url,
              employeeId
            )
          );
        } else {
          const mappedPopup = mapTaskTypeToPopup(task.type);
          if (
            mappedPopup &&
            (accutane.data || task.type === "reviewNotesSection") &&
            patientQuery.data &&
            employeeId
          ) {
            showPopup(
              mappedPopup({
                ...accutane.data,
                taskId: task.id,
                userId: patientQuery.data.patientId,
                employeeId,
                careCoordinator: careCoordinatorQuery.data as CareCoordinator,
                patient: patientQuery.data,
                documentGroupId: task.document?.documentGroupId,
                inviteId: task.document?.inviteId,
                role: "Care coordinator",
                compositeKey: task.compositeKey,
              })
            );
          }
        }
      },
      key: task.compositeKey,
    }))
    .concat([
      {
        text: "Send blood slip",
        key: "task__send-blood-slip",
        check: (): boolean => {
          if (accutane.data && subscriptionsQuery.data) {
            if (hasActiveMembership(subscriptionsQuery.data)) {
              const delta =
                Math.floor(
                  new Date(accutane.data?.nextConfirmationDate ?? 0).getTime() /
                    1000
                ) - Math.floor(Date.now() / 1000);

              if (
                accutane.data.gender === "Female" &&
                !patientQuery.data?.flags?.isNotPregnantCapabilit
              ) {
                if (delta < SECONDS_IN_DAY * 5) {
                  return !accutane.data.bloodWork.populated;
                }
                return false;
              }
              if (accutane.data.gender === "Male") {
                return !accutane.data.bloodWork.populated;
              }

              return false;
            }
            return false;
          }
          return false;
        },
        action: () => {
          if (patientQuery.data && providerQuery.data) {
            showPopup(
              SendBloodSlipPopupGenerator({
                patient: patientQuery.data,
                provider: providerQuery.data as Provider,
              })
            );
          }
        },
      },
    ]);

  const openInsuranceInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (patientQuery.data)
      showPopup(InsurancePopupGenerator({ patient: patientQuery.data }));
  };

  const openFollowUpVisitPage = () => {
    window.scrollTo(0, 0);
    history.push(`/${patientId}/visit`);
  };

  function renderActionItems() {
    return careCoordinatorTodos
      .filter(({ check }) => check())
      .map(({ text, check, action }) => (
        <div
          className={`action-items__item${!check() ? " checked" : ""}`}
          onClick={() => action()}
        >
          <div className="action-items__checkbox">
            <CheckIcon className="action-items__check-icon" />
          </div>
          <p className="action-items__text">{text}</p>
          <ChevronIcon className="action-items__chevron" />
        </div>
      ));
  }
  const actionItems = renderActionItems();
  const tabsItems = [
    {
      label: ProviderShortMedicalBackgroundTabs.TODO,
      key: "todos-tab",
      children: (
        <div>
          <div>{actionItems.length ? actionItems : <EmptyTaskView />}</div>
        </div>
      ),
      counter: actionItems.length ?? null,
    },
    {
      label: ProviderTabs.CARE_TEAM,
      key: "care-team",
      children: <CareTeamBlock />,
    },
  ];

  const patientMonthlyTasks = getPatientMonthlyTasks(accutane.data);

  const govtIDDocuments = getGovtIDDocuments(patientId);

  const patientTasks = patientQuery.data
    ? getPatientActionsList(
        accutane.data || null,
        patientQuery.data,
        medicationsQuery.data || [],
        followUpsQuery.data || [],
        appointmentsQuery.data || [],
        subscriptionsQuery.data || [],
        showPopup,
        history,
        govtIDDocuments,
        patientMonthlyTasks
      )
    : [];
  const visiblePatientTasks = patientTasks
    .filter((task) => task.check())
    .map((task) => ({
      label: task.text,
      key: task.key,
      showRemoveIcon: true,
    }));

  const activePatientShipping = {
    address1: patientQuery.data?.shippingInfo?.addressLine1,
    address2: patientQuery.data?.shippingInfo?.addressLine2,
    city: patientQuery.data?.shippingInfo?.city,
    state: patientQuery.data?.shippingInfo?.state,
    zipCode: patientQuery.data?.shippingInfo?.zipCode,
  };

  const shippingInfo = concatShippingInfo(activePatientShipping);
  const expandableInfo = [
    {
      title: "Parent's contact information",
      items: [
        {
          label: "Parent name",
          description: patientQuery.data?.parentInfo?.name ?? "N/A",
        },
        {
          label: "Parent phone",
          description: patientQuery.data?.parentInfo?.phone ?? "N/A",
        },
        {
          label: "Parent email",
          description: patientQuery.data?.parentInfo?.email ?? "N/A",
        },
      ],
      key: "contactInformation",
    },
    {
      title: "Shipping address",
      items: [
        {
          label: shippingInfo,
        },
      ],
      key: "shippingInfo",
    },
    {
      title: "Health insurance",
      items: [
        {
          label: "Member ID",
          description: patientQuery.data?.insurance?.memberId ?? "N/A",
        },
        {
          label: "Insurance name",
          description: patientQuery.data?.insurance?.insuranceName ?? "N/A",
        },
        {
          label: "Policy holder name",
          description: patientQuery.data?.insurance?.policyHolderName ?? "N/A",
        },
        {
          label: "Group number",
          description: patientQuery.data?.insurance?.groupNumber ?? "N/A",
        },
        {
          label: "RxBin number",
          description: patientQuery.data?.insurance?.rxBinNumber ?? "N/A",
        },
      ],
      key: "healthInsurance",
      extra: (
        <div className="edit-button">
          <EditIcon onClick={openInsuranceInfo} />
        </div>
      ),
    },
    {
      title: "Patient's current tasks",
      items: visiblePatientTasks,
      key: "patientCurrentTasks",
    },
  ];

  const patientInfo =
    patientQuery.data &&
    (moment().diff(patientQuery.data.dateOfBirth, "years") >= 18 ||
      !patientQuery.data.parentInfo)
      ? expandableInfo.slice(1)
      : expandableInfo;

  return (
    <>
      <EditPatientInfoPopup />
      <div className="patient-details-dashboard">
        <div className="dashboard-info-container">
          <div className="dashboard-info-wrapper">
            <div className="dashboard-short-info">
              <FollowUpCard />
              <IpledgeDetails
                patientId={activePatientId || undefined}
                isNotPregnantCapability={pregnancyCapabilityStatus(
                  patientQuery?.data
                )}
              />
              <div className="dashboard-short-info__patient-info">
                <div>
                  {patientInfo.map((it) => (
                    <ExpandableInfo
                      title={it.title}
                      items={it.items}
                      extra={it.extra}
                      key={it.key}
                    />
                  ))}
                </div>
              </div>
              <Tabs items={tabsItems} />
            </div>
            <div className="dashboard-detailed-info">
              <Tabs
                className="dashboard-detailed-info__tabs"
                items={careCoordinatorTabsItems}
                onChange={onTabChange}
              />
              {currentTab === ProviderTabs.TREATMENT_HISTORY && (
                <>
                  <TreatmentHistory />
                  <PatientActionButtons />
                  <VisitsHistory
                    appointmentDate={appointmentDate}
                    patientTimezone={patientTimezone}
                  />
                </>
              )}
              {currentTab === ProviderTabs.DOCUMENTS &&
                (careCoordinatorQuery.data && activePatientId ? (
                  <CareCoordinatorsDocuments
                    userId={activePatientId}
                    employeeId={careCoordinatorQuery.data?.id}
                  />
                ) : (
                  <Skeleton fullWidth flex style={{ height: 200 }} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
