import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import { Patient } from "../../types/Entities/Patient";

export const referralApiSlice = createApi({
  reducerPath: "api/referral",
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.REFERRAL_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getReferralLink: builder.query<string, string>({
      query: (patientId: string) => ({
        url: "",
        params: {
          query: `query {
            getReferralLink(patientId: "${patientId}")
          }`,
        },
      }),
      transformResponse: (response: any) => response.data.getReferralLink,
    }),
  }),
});

export const { useGetReferralLinkQuery } = referralApiSlice;
