import React from "react";
import { ReactComponent as SuccessSign } from "../../../../Assets/NewIcons/check-rounded.svg";
import { ReactComponent as ErrorSign } from "../../../../Assets/NewIcons/error-rounded.svg";
import { ReactComponent as InfoSign } from "../../../../Assets/NewIcons/info-rounded.svg";
import { ReactComponent as WarnRounded } from "../../../../Assets/NewIcons/warn-rounded.svg";
import "./style.scss";

type VIEWS = "info" | "warn" | "error" | "success";

interface Props {
  view: VIEWS;
  text: string | JSX.Element;
  className?: string;
  isPregnancyTest?: boolean;
}

export function InfoBlock({ view, text, className, isPregnancyTest }: Props) {
  function renderIcon() {
    switch (view) {
      case "info":
        return <InfoSign />;
      case "warn":
        return <WarnRounded />;
      case "success":
        return <SuccessSign />;
      case "error":
        return <ErrorSign />;
      default:
        return null;
    }
  }

  return (
    <div className={`info-block info-block--${view} ${className || ""}`}>
      <div className="info-block__sign">{renderIcon()}</div>
      <p className="info-block__text paragraph-font--color">
        {text}
        {isPregnancyTest && (
          <>
            <br />
            <ul style={{ color: "#403e48", fontWeight: 550 }}>
              <li>
                &bull;&nbsp;A post-it with your handwritten name, signature and
                today's date
              </li>
              <li>&bull;&nbsp;The pregnancy test</li>
              <li>
                &bull;&nbsp;The instructions legend (how to interpret the test)
              </li>
            </ul>
            <br />
            <a
              href="https://honeydew-accutane-service-documents-bucket-prod.s3.amazonaws.com/Pregnancy_Test_Instructions.png"
              style={{ fontWeight: "normal", textDecoration: "underline" }}
            >
              See example link
            </a>
          </>
        )}
      </p>
    </div>
  );
}
