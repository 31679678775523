import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../NewComponents/Common/Button";
import { HelpPopupTemplate } from "../../../NewComponents/Common/PopupLayer/help";
import "./style.scss";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import {
  useConfirmIpledgeMutation,
  useEndIsotretinoinMutation,
  usePauseIsotretinoinMutation,
} from "../../../../features/api/accutane";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { getSessionState } from "../../../../features/session";

interface Props {
  onClose: () => void;
}

type SelectedOption = "pause" | "end";

interface IsotretinoinFormValues {
  endPlan: boolean;
  pauseFor: {
    count: {
      label: string;
      value: string;
    };
    period: {
      label: string;
      value: string;
    };
  };
}

interface PauseIsotretinoinProps {
  formValues: IsotretinoinFormValues;
  onClose: () => void;
  optionSelected: SelectedOption;
  userId: string;
}

const mapOptionToTitle = {
  pause: "Pause",
  end: "End",
  unpause: "Unpause",
};

const mapPeriodToCounter: Record<string, number> = {
  m: 30,
  w: 7,
  d: 1,
};

const getDaysAmount = (formValues: IsotretinoinFormValues) =>
  Number(formValues.pauseFor.count.label) *
  mapPeriodToCounter[formValues.pauseFor.period.value];

function PauseIsotretinoinConfirmationPopup({
  formValues,
  onClose,
  optionSelected,
  userId,
}: PauseIsotretinoinProps) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const { userId: providerId } = useSelector(getSessionState);
  const [endIsotretinoin] = useEndIsotretinoinMutation();
  const [pauseIsotretinoin] = usePauseIsotretinoinMutation();
  const [confirmIpledge] = useConfirmIpledgeMutation();

  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setDate(currentDate.getDate() + 30);

  const mapOptionToDescription = {
    pause:
      "A notification will be sent to the chat, and the next iPledge\n" +
      " confirmation date will be moved accordingly.",
    end: "A notification will be sent to the chat and all tasks/reminders about isotretinoin for this patient will stop.",
    unpause: `A notification will be sent to the chat, and the next iPledge confirmation date on ${getMomentDate(
      futureDate
    ).format("MM/DD/YY")}.`,
  };

  const onSubmitHandler = async () => {
    if (optionSelected === "end") {
      const result: any = await endIsotretinoin(userId);

      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to end isotretinoin treatment",
        });
        return;
      }
      showSuccess({
        title: "Success",
        description: "isotretinoin successfully ended",
      });
    } else if (optionSelected === "unpause") {
      const result: any = await confirmIpledge({
        patientId: userId,
        employeeId: providerId,
      });
      if (result.error) {
        showError({
          title: "Error",
          description: "Error occurred when trying to confirm iPledge",
        });
      } else {
        showSuccess({
          title: "Success",
          description: "iPledge successfully confirmed",
        });
        onClose();
      }
    } else {
      const daysAmount = getDaysAmount(formValues);
      const result: any = await pauseIsotretinoin({
        patientId: userId,
        daysAmount,
      });
      if (result === 200) {
        showError({
          title: "Something went wrong...",
          description: "Unable to pause isotretinoin treatment",
        });
      }
      showSuccess({
        title: "Success",
        description: "Isotretinoin successfully paused",
      });
    }
    onClose();
  };

  return (
    <div className="pause-isotretinoin-confirmation">
      <p className="pause-isotretinoin-confirmation__title">
        {mapOptionToTitle[optionSelected]} Isotretinoin?
      </p>
      <p className="pause-isotretinoin-confirmation__description">
        {mapOptionToDescription[optionSelected]}
      </p>
      <div className="pause-isotretinoin-confirmation__control-buttons">
        <Button text="Cancel" view="secondary" size="small" onClick={onClose} />
        <Button
          text={mapOptionToTitle[optionSelected]}
          size="small"
          onClick={onSubmitHandler}
        />
      </div>
    </div>
  );
}

// todo add type
function PauseIsotretinoinConfirmationPopupGenerator(
  formValues: any,
  optionSelected: any,
  userId: string
) {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose} showCross={false}>
        <PauseIsotretinoinConfirmationPopup
          onClose={onClose}
          formValues={formValues}
          optionSelected={optionSelected}
          userId={userId}
        />
      </HelpPopupTemplate>
    );
  };
}

export default PauseIsotretinoinConfirmationPopupGenerator;
