import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { StyledSelect } from "../../../NewComponents/Common/StyledSelect";
import "./style.scss";
import { RadioButtonsForm } from "../../../NewComponents/Common/RadioButtonsForm";
import { Button } from "../../../NewComponents/Common/Button";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import PauseIsotretinoinConfirmationPopupGenerator from "../PauseIsotretinoinConfirmationPopup";
import { getSessionState } from "../../../../features/session";
import { useGetAccutaneByPatientIdQuery } from "../../../../features/api/accutane";
import { getMomentDate } from "../../../../utils/get-date-pretty";

interface Option {
  label: string;
  value: string;
}

interface PauseForm {
  pauseFor: {
    count: {
      label: string;
      value: string;
    };
    period: {
      label: string;
      value: string;
    };
  };
  endPlan: boolean;
}

const countOptions = [...Array(31).keys()].map((key) => ({
  label: String(++key),
  value: String(++key),
}));
const periodOptions = [
  { label: "day(s)", value: "d" },
  { label: "week(s)", value: "w" },
  { label: "month(s)", value: "m" },
];

function IsotretinoinTreatment({
  onSubmit,
  userId,
}: {
  onSubmit: () => void;
  userId: string;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const [radioButtonsForm, setOption] = useState("pause");
  const countDefaultOption = countOptions[1];
  const periodDefaultOption = periodOptions[1];
  const [pauseIsotretinoinForm, setFormField] = useState<PauseForm>({
    pauseFor: {
      count: countDefaultOption,
      period: periodDefaultOption,
    },
    endPlan: false,
  });

  const { data: accutane } = useGetAccutaneByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  const handleCountChange = (value: Option) => {
    setFormField({
      ...pauseIsotretinoinForm,
      pauseFor: {
        ...pauseIsotretinoinForm.pauseFor,
        count: value,
      },
    });
  };

  const handlePeriodChange = (value: Option) => {
    setFormField({
      ...pauseIsotretinoinForm,
      pauseFor: {
        ...pauseIsotretinoinForm.pauseFor,
        period: value,
      },
    });
  };

  const formatDate = (dateString: string | undefined): string => {
    if (dateString === "--" || dateString === "N/A") {
      return "--";
    }
    if (dateString && dateString !== "N/A") {
      return getMomentDate(dateString).format("MM/DD/YY");
    }
    return "N/A";
  };

  const nextConfirmationDate = formatDate(accutane?.nextConfirmationDate);

  const optionsList = [
    {
      label: "Pause Isotretinoin for",
      value: "pause",
      extra: (
        <div>
          <div className="pause-isotretinoin-period">
            <StyledSelect
              id="pauseCount"
              name="pauseCount"
              options={countOptions}
              defaultValue={countDefaultOption}
              onChange={handleCountChange}
              isClearable={false}
              placeholder=""
            />
            <StyledSelect
              id="pausePeriod"
              name="pausePeriod"
              options={periodOptions}
              defaultValue={periodDefaultOption}
              onChange={handlePeriodChange}
              isClearable={false}
              placeholder=""
            />
          </div>
          <p className="pause-isotretinoin-period__period-description">
            A notification will be sent to the chat, and the next iPledge
            confirmation date will be moved accordingly.
          </p>
        </div>
      ),
      when: "pause",
    },
    {
      label: "Unpause Isotretinoin",
      value: "unpause",
      extra: (
        <p className="option-description">
          {`Therapy is currently paused untill ${nextConfirmationDate}. This action will
          instruct the patient to resume the treatment`}
        </p>
      ),
      when: "renew",
    },
    {
      label: "End Isotretinoin treatment",
      value: "end",
      extra: (
        <p className="option-description">
          A notification will be sent to the chat and all tasks/reminders about
          isotretinoin for this patient will stop
        </p>
      ),
      when: "end",
    },
  ];

  const onChange = (value: string | number | boolean) => {
    setOption(value as string);
  };

  const onCancel = () => {
    onSubmit();
  };

  const onSubmitCancel = () => {
    onSubmit();
    showPopup(
      PauseIsotretinoinConfirmationPopupGenerator(
        pauseIsotretinoinForm,
        radioButtonsForm,
        userId,
        nextConfirmationDate
      )
    );
  };

  return (
    <div className="isotretinoin-section">
      <p className="isotretinoin-section__popup-title">
        Pause or End Isotretinoin Treatment
      </p>
      <p className="isotretinoin-section__info">
        Please, select one of the options
      </p>
      <div className="isotretinoin-section__content">
        <RadioButtonsForm
          list={optionsList}
          currentValue={radioButtonsForm}
          onChange={onChange}
          className="isotretinoin-section__content__options-form"
          accutaneStatus={accutane?.status}
        />
      </div>
      <div className="isotretinoin-section__control-buttons">
        <Button
          text="Cancel"
          view="secondary"
          size="small"
          onClick={onCancel}
        />
        <Button text="Confirm" size="small" onClick={onSubmitCancel} />
      </div>
    </div>
  );
}

export default IsotretinoinTreatment;
