import React, { createContext, useState } from "react";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import { logInfo } from "../../../../shared/logger";
import "./style.scss";

interface Props {
  title: string;
  onClose: () => void;
  children: JSX.Element[] | JSX.Element;
  extra?: JSX.Element | JSX.Element[];
}

enum ANIMATIONS {
  SHOW = "show",
  HIDE = "fade",
}

export const OverviewPopupTemplateContext = createContext({
  onClose: () => logInfo(""),
});

export function OverviewPopupTemplate({
  title,
  onClose,
  children,
  extra,
}: Props) {
  const [animation, setAnimation] = useState(ANIMATIONS.SHOW);

  function close() {
    setAnimation(ANIMATIONS.HIDE);
    setTimeout(onClose, 500);
  }

  return (
    <OverviewPopupTemplateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        onClose: close,
      }}
    >
      <div className={`overview-popup-template__wrap ${animation}`}>
        <div className="overview-popup-template">
          <div className="overview-popup-template__head">
            <div
              className="overview-popup-template__icon-wrapper"
              onClick={close}
            >
              <ChevronIcon className="overview-popup-template__close-button" />
            </div>
            <div className="overview-popup-template__head-content">
              <p className="overview-popup-template__title">{title}</p>
              {extra}
            </div>
          </div>
          <div className="popup-template__body">{children}</div>
        </div>
      </div>
    </OverviewPopupTemplateContext.Provider>
  );
}
