export interface Props {
  onClose: () => void;
}

export interface SelectOption {
  label: string;
  value: string;
}

export enum EPrimaryFormOptions {
  birthControlPill = "birthControlPill",
  hormonalPatch = "hormonalPatch",
  hormonalIUD = "hormonalIUD",
  hormonalImplantsUnderTheSkin = "hormonalImplantsUnderTheSkin",
  hormonalShot = "hormonalShot",
  nonHormonalIUD = "nonHormonalIUD",
  maleVasectomy = "maleVasectomy",
  vaginalRing = "vaginalRing",
  tubeSterilization = "tubeSterilization",
}

export enum ESecondaryFormOptions {
  birthControlPill = "birthControlPill",
  hormonalPatch = "hormonalPatch",
  hormonalIUD = "hormonalIUD",
  hormonalImplantsUnderTheSkin = "hormonalImplantsUnderTheSkin",
  hormonalShot = "hormonalShot",
  nonHormonalIUD = "nonHormonalIUD",
  maleVasectomy = "maleVasectomy",
  vaginalRing = "vaginalRing",
  tubeSterilization = "tubeSterilization",
  maleLatexCondoms = "maleLatexCondoms",
  cervicalCap = "cervicalCap",
  diaphragmWithSpermicide = "diaphragmWithSpermicide",
  vaginalSponge = "vaginalSponge",
}

export enum EFormsOfPregnancyConfirmOptions {
  abstinence = "Abstinence",
  birthControl = "BirthControl",
}

export enum EPreviousPregnancyConfirmResults {
  yes = "yes",
  no = "no",
}

export type PrimaryOptionsProps = {
  label: string;
  value: EPrimaryFormOptions;
};
export type SecondaryOptionsProps = {
  label: string;
  value: ESecondaryFormOptions;
};

export interface PregnancyPreventionPayload {
  body: string;
}
