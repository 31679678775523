import React from "react";
import { useTable, Column } from "react-table";

// Define the TableProps interface
interface TableProps {
  columns: Column<any>[];
  data: any[];
}

function Table({ columns, data }: TableProps) {
  // Ensure columns and data are not undefined
  if (!columns || !data) {
    return <div>Error: Columns or data are undefined</div>;
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div style={{ overflowX: "auto" }}>
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    flex: 1,
                    textAlign: "left",
                    padding: "10px",
                    fontFamily: "Cera Pro",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      textAlign: "left",
                      flex: 1,
                      padding: "10px",
                      fontSize: "12px",
                      fontFamily: "Cera Pro",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
