import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Accutane } from "../../../../../types/Entities/Accutane";

interface AccutaneResponse {
  body: Accutane;
}

export const getAccutane = async (userId: string): Promise<Accutane> => {
  try {
    const result = await axios.get<AccutaneResponse>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/${userId}`
    );

    return result.data?.body ?? null;
  } catch (error) {
    logError("Error received when trying to get accutane results");
    throw error;
  }
};
