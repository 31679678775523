import axios from "axios";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";

export const checkDocumentStatus = async (
  userId: string,
  role: string,
  documentGroupId: string,
  inviteId: string,
  isBloodSlipCase = false
): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/check-document-fulfillment-status/${userId}`,
      {
        role,
        documentGroupId,
        inviteId,
        isBloodSlipCase,
      }
    );

    return result.data;
  } catch (error) {
    logError("Error received when trying to check document status");
    throw error;
  }
};
