export enum ProviderShortMedicalBackgroundTabs {
  DETAILS = "Details",
  TODO = "To do",
}

export enum ProviderTabs {
  TREATMENT_HISTORY = "Treatment history",
  DOCUMENTS = "Documents",
  CARE_TEAM = "Care team",
}

export interface ProviderTask {
  id: string;
  compositeKey: string;
  message: string;
  type: string;
  createdAt: string;
  document?: {
    documentGroupId: string;
    inviteId: string;
  };
  status?: string;
  assignedTo: {
    id: string;
    role: string;
  };
  assignedBy?: {
    id: string;
    role: string;
  };
}
