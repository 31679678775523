import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { show as showErrorNotification } from "../../../../features/errorNotification";
import { DeleteConfirmainPopupContext } from "../../Common/DeleteConfirmationPopup";
import "./style.css";
import { deleteTreatmentPlan } from "../../../../features/treatmentPlans";
import { Medicine } from "../../../../types/Entities/Medicine";
import { TreatmentPlan } from "../../../../types/Entities/TreatmentPlan";
import { logError } from "../../../../shared/logger";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { alphabetical } from "../../../../utils/sortings";
import { COMMON_TREATMENT_PLANS_ORDER } from "../../../../data/common-treatment-plans";
import {
  useArchiveTreatmentPlanMutation,
  useGetAllMedicationsQuery,
  useGetAllTreatmentPlansQuery,
} from "../../../../features/api/medications";
import { TreatmentPlanPreset } from "../../../../types/Medication";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { TreatmentPlanPopupGenerator } from "../../../NewComponents/Administrator/TreatmenPlanPopup";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";

export function TreatmentPlansTable() {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const treatmentPlansQuery = useGetAllTreatmentPlansQuery(null);
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const { ask } = useContext(DeleteConfirmainPopupContext);
  const [archiveTreatmentPlan] = useArchiveTreatmentPlanMutation();

  function getMedicine(id: string) {
    if (!treatmentPlansQuery.data || !medicationsQuery.data) return undefined;
    return medicationsQuery.data.find((item) => item.id === id);
  }

  function renderTreatmentGroupsText(plan: TreatmentPlan) {
    return plan.groups
      .map((group) =>
        group
          .map((id) => {
            const medicineItem = getMedicine(id);
            if (!medicineItem) {
              const message = `Cannot find medicine with ID ${id}`;
              logError(message);
              throw new Error(message);
            }

            return `${medicineItem.name}${
              medicineItem.strength ? `, ${medicineItem.strength}` : ""
            }${medicineItem.size ? `, ${medicineItem.size}` : ""}`;
          })
          .sort((a, b) => alphabetical(a, b))
          .join(" | ")
      )
      .map((row) => <p className="treatment-plans-list__group-sep">{row}</p>);
  }

  async function archiveMedicineHandler(id: string, state: boolean) {
    const result: any = await archiveTreatmentPlan({
      treatmentPlanId: id,
      archived: state,
    });
    if (result.error || result.data?.errors) {
      showError({
        title: "Something went wrong...",
        description: "Unable to perform this action",
      });
      throw new Error("Unable to archive treatment plan");
    }

    showSuccess({
      title: "Success!",
      description: `Treatment plan has been ${
        state ? "archived" : "unarchived"
      } successfully`,
    });
  }

  function askUserForConfirmation(id: string, archived: boolean) {
    ask(
      archiveMedicineHandler.bind(null, id, !archived),
      archived ? "unarchive" : "archive"
    );
  }

  function sortTreatmentPresets(treatmentsPreset: TreatmentPlanPreset[]) {
    const start = COMMON_TREATMENT_PLANS_ORDER.map((treatment) =>
      treatmentsPreset.find(({ id }) => id === treatment)
    ).filter((value) => !!value) as TreatmentPlanPreset[];
    const end = treatmentsPreset.filter(
      ({ id }) => !COMMON_TREATMENT_PLANS_ORDER.includes(id)
    );

    return [...start, ...end.sort((a, b) => alphabetical(a.name, b.name))];
  }

  const renderTableBody = (list: TreatmentPlanPreset[]) => (
    <tbody>
      {sortTreatmentPresets(list).map((row) => (
        <tr>
          <td>{row.name}</td>
          <td>{renderTreatmentGroupsText(row)}</td>
          <td className="patientsTable__actionsList">
            <button
              title="Edit"
              type="button"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                if (!medicationsQuery.data) return;
                showPopup(
                  TreatmentPlanPopupGenerator(medicationsQuery.data, row)
                );
              }}
            >
              <div className="patientsTable__actionButton--edit" />
            </button>
            {row.archived ? null : (
              <button
                title="Archive"
                type="button"
                className="patientsTable__actionButtonWithIcon"
                onClick={() => askUserForConfirmation(row.id, !!row.archived)}
              >
                <div className="patientsTable__actionButton--delete" />
              </button>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return medicationsQuery.data && treatmentPlansQuery.data ? (
    <>
      <div className="medicine-button-wrapper">
        <button
          type="button"
          className="custom-button custom-button--secondary custom-button--small ipledge-details__confirm-button"
          onClick={() => {
            if (!medicationsQuery.data) return;
            showPopup(TreatmentPlanPopupGenerator(medicationsQuery.data));
          }}
        >
          Create new treatment plan
        </button>
      </div>
      <div className="treatment-plans-list__wrap">
        <table className="treatment-plans-list__table">
          <thead>
            <tr>
              <th className="treatment-plans-list__heading-cell--treatment">
                Name
              </th>
              <th className="treatment-plans-list__heading-cell--instructions">
                Medicine
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          {renderTableBody(treatmentPlansQuery.data)}
        </table>
      </div>
    </>
  ) : (
    <Skeleton flex column fullWidth count={8} style={{ height: 70 }} />
  );
}
