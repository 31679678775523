import { AdaptiveQuestionV2 } from "../../app/Pages/Patient/StartFollowUpPage/types";

export function generateAdaptiveQuestion(
  question: AdaptiveQuestionV2[],
  answer: string
) {
  const choices = question.reduce(
    (acc: AdaptiveQuestionV2[], currentAnswer) => {
      if (currentAnswer.value === answer) {
        return [
          {
            value: currentAnswer.value,
            label: "Yes",
          },
          ...acc,
        ];
      }
      return [
        ...acc,
        {
          value: currentAnswer.value,
          label: `No, ${currentAnswer.label}`,
        },
      ];
    },
    []
  );

  return choices;
}
