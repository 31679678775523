import React, { useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientsPageForAdministrator } from "../../NewPages/Administrator/PatientsPage";
import { EmployeesDashboard } from "../../Pages/Administrator/EmployeesDashboard";
import { EditEmployee } from "../../Pages/Administrator/EditEmployee";
import { MedicationsDashboard } from "../../Pages/Administrator/MedicationsDashboard";
import { CreateEmployee } from "../../Pages/Administrator/CreateEmployee";
import { Menu } from "../../NewComponents/Common/Menu";
import { PatientDashboardPageForAdmin } from "../../NewPages/Administrator/PatientDashboard";
import { RescheduleAppointmentPage } from "../../NewPages/Common/RescheduleAppointmentPage";
import { Footer } from "../../NewComponents/Common/Footer";
import PrintPatientInfoPage from "../../NewPages/PrintPatientInfo";
import { getSessionState } from "../../../features/session";
import { ChatLayer } from "../../NewComponents/Common/ChatLayer";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";
import { PatientFollowUpVisit } from "../../Pages/Provider/PatientFollowUpVisit";
import { PatientFollowUpVisitMobileScreen } from "../../Pages/Provider/PatientFollowUpVisit/PatientFollowUpVisitMobileScreen";

export function AdministratorView() {
  const [showMenu, setMenuVisibility] = useState(true);
  const location = useLocation();
  const { userId, userRole } = useSelector(getSessionState);

  if (!userId || !userRole) return null;

  return (
    <ChatLayer identity={userId} userRole={userRole}>
      {(showMenu && <Menu />) || null}
      <Switch location={location}>
        <Route path="/:userId/visit">
          <div className="main-area follow-up-visit__mobile">
            <PatientFollowUpVisitMobileScreen />
          </div>

          <div className="main-area follow-up-visit__web">
            <PatientFollowUpVisit />
          </div>
        </Route>

        <Route path="/employees/edit/careCoordinator/:id">
          <div className="main-area">
            <EditEmployee type="careCoordinator" />
            <Footer />
          </div>
        </Route>
        <Route path="/employees/edit/provider/:id">
          <div className="main-area">
            <EditEmployee type="provider" />
            <Footer />
          </div>
        </Route>
        <Route path="/employees/edit/enrollmentCoordinator/:id">
          <div className="main-area">
            <EditEmployee type="enrollmentCoordinator" />
            <Footer />
          </div>
        </Route>
        <Route path="/employees/create">
          <div className="main-area">
            <CreateEmployee />
            <Footer />
          </div>
        </Route>
        <Route path="/medications">
          <div className="main-area">
            <MedicationsDashboard />
            <Footer />
          </div>
        </Route>
        <Route path="/employees">
          <div className="main-area">
            <EmployeesDashboard />
            <Footer />
          </div>
        </Route>
        <Route path="/reschedule/:patientId">
          <div className="main-area">
            <RescheduleAppointmentPage />
            <Footer />
          </div>
        </Route>
        <Route path="/:userId">
          <div className="main-area">
            <PatientDashboardPageForAdmin />
            <ChatWidget />
            <Footer />
          </div>
        </Route>
        <Route path="/">
          <div className="main-area">
            <PatientsPageForAdministrator />
            <ChatWidget />
            <Footer />
          </div>
        </Route>
      </Switch>
    </ChatLayer>
  );
}
