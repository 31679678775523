import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGetMyProfileQuery } from "../../../../features/api/patients";
import { setActivePatient } from "../../../../features/session";
import { Button } from "../../Common/Button";
import {
  CustomPopupTemplate,
  CustomPopupTemplateContext,
} from "../../Common/PopupLayer/custom";
import { RadioButtonsList } from "../../Common/RadioButtonsList";
import "./style.scss";
import { Skeleton } from "../../Common/Skeleton";

interface Props {
  onClose: () => void;
}

function PatientPickerPopup() {
  const [patientId, setPatientId] = useState<string | null>(null);
  const { onClose } = useContext(CustomPopupTemplateContext);
  const dispatch = useDispatch();
  const { isLoading, data, isSuccess } = useGetMyProfileQuery(null);

  const select = async (_patientId: string) => {
    dispatch(setActivePatient(_patientId));
    onClose();
  };

  const renderList = () => {
    if (isLoading) return <Skeleton count={2} />;
    if (isSuccess)
      return (
        <RadioButtonsList
          list={data.map((patient) => ({
            value: patient.patientId,
            label: patient.fullName,
          }))}
          currentValue={patientId || undefined}
          onChange={(value) => setPatientId(value as string)}
          size="small"
        />
      );
    return null;
  };

  return (
    <div className="patient-picker">
      <div className="patient-picker__message-block">
        <p className="patient-picker__title">Please select the patient</p>
        <p className="patient-picker__descr paragraph-font--color">
          {isSuccess ? (
            <>
              You have {data.length} patients under the same account. Please
              select the patient you would like to continue with.
            </>
          ) : (
            <Skeleton count={2} />
          )}
        </p>
        {renderList()}
      </div>
      <div className="patient-picker__actions">
        <Button
          text="cancel"
          view="secondary"
          onClick={() => {
            onClose();
          }}
          className="patient-picker__action"
        />
        <Button
          text="continue"
          className="patient-picker__action"
          onClick={() => select(patientId as string)}
          disabled={!patientId}
        />
      </div>
    </div>
  );
}

export function PatientPickerPopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <CustomPopupTemplate onClose={onClose}>
        <PatientPickerPopup />
      </CustomPopupTemplate>
    );
  };
}
