import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { JSONObject } from "@twilio/conversations";
import { isNull } from "util";
import { v4 as uuidv4 } from "uuid";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import {
  getLocalDateNonMilitary,
  getMomentDate,
} from "../../../../utils/get-date-pretty";
import { getLastName } from "../../../../utils/patient/get-last-name";
import { alphabetical, byDate } from "../../../../utils/sortings";
import {
  DoctorGreetingBanner,
  TaskColors,
} from "../../../NewComponents/Common/DoctorGreetingBanner";
import { ReactComponent as NoShowIcon } from "../../../../Assets/NewIcons/eye.svg";
import { ReactComponent as UnpinIcon } from "../../../../Assets/NewIcons/pin.svg";
import { ReactComponent as PinIcon } from "../../../../Assets/NewIcons/unpin.svg";
import "./style.scss";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { ActionPopupGenerator } from "../../../NewComponents/Common/ActionPopup";
import {
  NotificationLayer,
  NotificationLayerContext,
} from "../../../NewComponents/Common/NotificationLayer";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { Administrator, Provider } from "../../../../types/Employee";
import { PatientsTableElastic } from "../../../NewComponents/Common/PatientsTableElastic";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { Stylings } from "../../../../types/Table/TableBodyPayload";
import { patientDashboardForAdministratorSort } from "./sortings";
import { PatientsTableTabs } from "../../../NewComponents/Common/PatientsTableElastic/tableTabs";
import {
  getPatientDashboardForProviderFilters,
  getPatientDashboardForProviderFiltersInactive,
} from "./filters";
import { useMarkAsNoShowMutation } from "../../../../features/api/appointments";
import { getTwilioState } from "../../../../features/twilio";
import { TwilioController } from "../../../../services/twilio";
import { wait } from "../../../../utils/wait";
import { patientDashboardForAdministratorOrder } from "./orders";
import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { pubnubClient } from "../../../../services/pubnub";
import { getAllChannelMetadata } from "../../../../utils/patient/pubnubChatCount";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { useSetFlagMutation } from "../../../../features/api/patients";

function getStyleForDashboard(employeeId: string) {
  return (payload: PatientDashboardItem, currentFilter?: string) => {
    switch (true) {
      case currentFilter === "New follow-ups":
        return Stylings.Blue;
      case currentFilter === "Miscellaneous tasks":
        return Stylings.Orange;
      case currentFilter === "New patients":
        return Stylings.Red;
      case payload.followUp &&
        !payload.followUp.isInitialFollowUp &&
        !payload.followUp.isTreatmentPlanSet:
        return Stylings.Blue;
      case payload.tasks?.includes(employeeId):
        return Stylings.Orange;
      case !payload.followUp?.isTreatmentPlanSet &&
        (payload.appointmentStatus === "PENDING" ||
          payload.appointmentStatus === "CONFIRMED"):
        return Stylings.Red;

      default:
        return Stylings.Base;
    }
  };
}
function PinButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [togglePin] = useSetFlagMutation();
  const { userId: providerId } = useSelector(getSessionState);

  const previousPinnedFlag = "pinnedByProvider";
  const pinnedFlag = `pinnedByProvider_${providerId}`;
  const pinned =
    !!payload.flags[pinnedFlag] || !!payload.flags[previousPinnedFlag];

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          ActionPopupGenerator({
            title: pinned ? "Unpin patient?" : "Pin patient to the top?",
            text: pinned
              ? `Are you sure you want to unpin this ${payload.fullName}?`
              : `Are you sure you want to pin ${payload.fullName} to the top?`,
            action: async () => {
              const response: any = await togglePin({
                patientId: payload.patientId,
                flag: pinnedFlag,
                state: !pinned,
              });
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: `Unable to ${pinned ? "un" : ""}pin patient`,
                });
                throw new Error(`Unable to ${pinned ? "un" : ""}pin patient`);
              }

              // If unpinning, also set the previousPinnedFlag to false
              if (pinned && payload.flags[previousPinnedFlag]) {
                const previousFlagResponse: any = await togglePin({
                  patientId: payload.patientId,
                  flag: previousPinnedFlag,
                  state: false,
                });
                if (previousFlagResponse.error) {
                  showError({
                    title: "Something went wrong...",
                    description: `Unable to unpin patient from the old flag`,
                  });
                  throw new Error(`Unable to unpin patient from the old flag`);
                }
              }

              await wait(3000);
              reload();
              showSuccess({
                title: "Success!",
                description: `You've successfully ${
                  pinned ? "un" : ""
                }pinned a patient!`,
              });
            },
            actionText: pinned ? "Unpin" : "Pin",
          })
        );
      }}
      data-tooltip={pinned ? "Unpin" : "Pin"}
    >
      {pinned ? <UnpinIcon /> : <PinIcon />}
    </div>
  );
}
function MarkAsNoShowButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [markAsNoShow] = useMarkAsNoShowMutation();
  const { userId: providerId } = useSelector(getSessionState);

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          ActionPopupGenerator({
            title: "Mark patient as a no show?",
            text: `Are you sure you want to mark ${payload.fullName} as a no show patient? Patient's data will be moved to the “Inactive patients” tab.`,
            action: async () => {
              const response: any = await markAsNoShow({
                patientId: payload.patientId,
                userId: providerId,
              });
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: "Unable to mark patient as no show",
                });
                throw new Error("Unable to mark patient as no show");
              }
              await wait(3000);
              reload();
              showSuccess({
                title: "You've successfuly marked patient as a no show!",
                description: "Patient was moved to the inactive patients tab.",
              });
            },
            actionText: "Mark as a no show",
          })
        );
      }}
      data-tooltip="Mark as No Show"
    >
      <NoShowIcon />
    </div>
  );
}

const tableColumns: TableHeadPayload<PatientDashboardItem>[] = [
  {
    title: "Full name",
    get: (payload) => getLastName(payload.fullName),
    getPretty: (payload) => (
      <p>
        {payload.fullName}{" "}
        {!payload.followUp ? (
          <span
            className="patients-table__attention-sign"
            data-tooltip="No medical background"
          >
            !
          </span>
        ) : null}
        {payload.subscription?.accutane ? (
          <span className="patients-table__accutane-sign">Accutane</span>
        ) : null}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "lastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['lastNameNumberValue'].value  / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Birth",
    get: (payload) => payload.dateOfBirth,
    getPretty: (payload) => (
      <p>
        {getMomentDate(payload.dateOfBirth)
          .add(12, "hours")
          .format("MMM Do, YYYY")}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "dateOfBirthDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } doc['dateOfBirthDate'].value / 100000.0`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Service",
    get: () => 0,
    getPretty: ({ followUp, appointmentDate }) => {
      const dateOfService =
        followUp?.isInitialFollowUp === false
          ? followUp.timestamp
          : appointmentDate;
      return (
        <p>{dateOfService ? getLocalDateNonMilitary(dateOfService) : "-"}</p>
      );
    },
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "followUp.timestamp",
                },
              },
              {
                match: {
                  "followUp.isInitialFollowUp": false,
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "appointmentDate",
                },
              },
              {
                bool: {
                  should: [
                    {
                      bool: {
                        must_not: {
                          exists: {
                            field: "followUp",
                          },
                        },
                      },
                    },
                    {
                      match: {
                        "followUp.isInitialFollowUp": true,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Next Follow-Up",
    get: () => 0,
    getPretty: (payload) => (
      <p>
        {payload.followUp?.nextFollowUpDate
          ? getLocalDateNonMilitary(payload.followUp.nextFollowUpDate)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "followUp.nextFollowUpDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.nextFollowUpDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Actions",
    get: () => "",
    getPretty: (payload, reload) => (
      <div className="patients-table__actions">
        {payload.subscription?.membership ||
        ((payload.appointmentStatus === "PENDING" ||
          payload.appointmentStatus === "CONFIRMED") &&
          (!payload.followUp || payload.followUp.isInitialFollowUp)) ? (
          <PinButton payload={payload} reload={reload} />
        ) : null}
        {(payload.appointmentStatus === "PENDING" ||
          payload.appointmentStatus === "CONFIRMED") &&
        (!payload.followUp ||
          (payload.followUp.isInitialFollowUp &&
            !payload.followUp.isTreatmentPlanSet)) &&
        payload.appointmentDate &&
        payload.appointmentDate < Date.now() ? (
          <MarkAsNoShowButton payload={payload} reload={reload} />
        ) : null}
      </div>
    ),
  },
];

const tabs = ["Active Patients", "Inactive Patients"];

export function PatientsPageForProvider() {
  const dispatch = useDispatch();
  const { initialized } = useSelector(getTwilioState);
  const [newMessagesCount, setNewMessagesCount] = useState<number | null>(null);
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [tableParams, setTableParams] = useState<
    { filters: any; sort: any; order: any }[]
  >([]);
  const {
    userId: providerId,
    userRole,
    newMsgsCount,
  } = useSelector(getSessionState);
  const providerQuery = useGetEmployeeQuery(providerId as string, {
    skip: !providerId,
  });
  const provider = providerQuery.data as Provider | undefined;
  const [pubnub, setPubNub] = useState<PubNub | null>(null);

  const [filterArray, setFilterArray] = useState([]);
  const [filteredChat, setFilteredChat] = useState<any>([]);
  const [patientIds, setPatientIds] = useState<any>([]);

  useEffect(() => {
    const role = providerQuery?.currentData?.role;
    if (filterArray.length === 0 && role) {
      let newFilterArray;
      switch (role) {
        case "provider":
          newFilterArray = getPatientDashboardForProviderFilters(
            providerId as string
          );
          break;
        default:
          break;
      }
      if (newFilterArray) {
        setFilterArray(newFilterArray);
      }
    }
  }, [filterArray, providerQuery, providerId]);

  useEffect(() => {
    if (filterArray.length > 0) {
      const fetchData = async () => {
        const { filter } = filterArray[0];
        let mustQuery = filter.must;
        if (mustQuery instanceof Array) {
          mustQuery = [...mustQuery];
        } else if (mustQuery instanceof Object) {
          mustQuery = [mustQuery];
        } else {
          mustQuery = [];
        }
        try {
          const query = {
            query: {
              from: 0,
              size: 10000,
              query: {
                // when you comment this query the code starts working with extra records
                function_score: {
                  query: {
                    bool: {
                      ...filter,
                    },
                  },
                },
              },
            },
          };
          const results = await HoneydewAPI.dashboard.queryDashboardItems(
            query
          );
          const filteredChats = results.hits.filter((result: any) => {
            if (!result._source.subscription) {
              return true;
            }

            return result._source.subscription.membership === true;
          });
          setFilteredChat(filteredChats);
          setPatientIds(filteredChats.map((chat) => chat._source.patientId));
        } catch (error) {
          console.log("error in fetching table data", error);
        }
      };
      fetchData();
    }
  }, [filterArray]);

  useEffect(() => {
    if (filteredChat.length && !newMsgsCount && patientIds.length > 0) {
      const fetchData = async () => {
        try {
          const metadataFlags = await getAllChannelMetadata(
            providerId,
            patientIds
          );
          const uniqueMetadataFlags = metadataFlags.reduce((acc, obj) => {
            acc[obj.id] = obj;
            return acc;
          }, {});
          const uniqueMetadataArray = Object.values(uniqueMetadataFlags);

          const newMsgs = uniqueMetadataArray.filter(
            (obj) => obj.custom[providerId] === false
          );
          const filteredResult = filteredChat.filter((chat) => {
            const chatMetaData = newMsgs.find(
              (metaData) => metaData.id === chat._source.patientId
            );
            if (chatMetaData) {
              return true;
            }
            return false;
          });
          dispatch(setNewMsgsCount(filteredResult.length));
          // setNewMessagesCount(newMsgs?.length);
          // dispatch(setNewMsgsCount(newMsgs?.length));
        } catch (error) {
          // Handle error here if needed
          console.error("Error fetching metadata flags:", error);
        }
      };

      fetchData();
    }
  }, [filteredChat, patientIds]);

  useEffect(() => {
    if (!providerId) return;
    setTableParams([
      {
        filters: getPatientDashboardForProviderFilters(providerId as string),
        sort: patientDashboardForAdministratorSort(),
        order: patientDashboardForAdministratorOrder(providerId as string),
      },
      {
        filters: getPatientDashboardForProviderFiltersInactive(
          providerId as string
        ),
        sort: [],
        order: [],
      },
    ]);
  }, [providerId]);

  useEffect(() => {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    if (!isNull(currentIndexFormatted)) {
      setTab(currentIndexFormatted.currentTab);
    }
  }, []);

  if (!provider) return null;

  const providerName = provider
    ? [provider.firstName, provider.lastName, provider.title]
    : null;

  function renderTable() {
    if (!tableParams[tab]) return null;
    switch (tab) {
      case 0:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            stylizeRow={getStyleForDashboard(provider?.id as string)}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      case 1:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="greeting-container">
      <DoctorGreetingBanner
        items={[
          {
            text: "New patients",
            filter: getPatientDashboardForProviderFilters(provider?.id).find(
              ({ text }) => text === "New patients"
            )?.filter,
            color: TaskColors.Pink,
          },
          {
            text: "Follow-ups",
            filter: getPatientDashboardForProviderFilters(provider?.id).find(
              ({ text }) => text === "New follow-ups"
            )?.filter,
            color: TaskColors.LightBlue,
          },
          {
            text: "New messages",
            count: newMsgsCount ?? undefined,
            color: TaskColors.Green,
          },
          {
            text: "Miscellaneous tasks",
            filter: getPatientDashboardForProviderFilters(provider?.id).find(
              ({ text }) => text === "Miscellaneous tasks"
            )?.filter,
            color: TaskColors.Orange,
          },
        ]}
        name={providerName ? providerName.join(" ") : null}
      />
      <div className="patients-table">
        <div className="patients-table__tabs">
          <PatientsTableTabs
            tabs={tabs}
            onChange={(state) => {
              setTab(state);
            }}
          />
        </div>
      </div>
      {renderTable()}
    </div>
  );
}
