import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const bloodSlipEmbeddedLink = async (
  documentGroupId: string,
  inviteId: string,
  patientId: string
): Promise<any> => {
  try {
    const result = await axios.post<any>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/blood-slip-embedded-link/${patientId}`,
      {
        documentGroupId,
        inviteId,
      }
    );

    return result.data.uniqueLink.data.link;
  } catch (error) {
    logError("Error received when trying to get info");
    throw error;
  }
};
