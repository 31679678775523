import React, { useEffect, useState } from "react";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import "./style.scss";

interface Props {
  list: {
    value: string;
    label: string;
    onlySelectable?: boolean;
  }[];
  currentValues?: string[];
  onChange: (values: string[]) => void;
  className?: string;
}

export function SurveyCheckboxes({
  list,
  currentValues,
  onChange,
  className,
}: Props) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [list]);

  useEffect(() => {
    if (Array.isArray(currentValues)) {
      setSelectedItems(currentValues);
    }
  }, [currentValues]);

  const handleItemClick = (value: string, onlySelectable?: boolean) => {
    let updatedItems;
    if (onlySelectable) {
      if (selectedItems.includes(value)) {
        updatedItems = [];
      } else {
        updatedItems = [value];
      }
    } else {
      if (selectedItems.includes(value)) {
        updatedItems = selectedItems.filter((item) => item !== value);
      } else {
        updatedItems = [...selectedItems, value];
      }
      // If any onlySelectable item is selected, deselect it
      const onlySelectableItem = list.find(
        (item) => item.onlySelectable && selectedItems.includes(item.value)
      );
      if (onlySelectableItem) {
        updatedItems = updatedItems.filter(
          (item) => item !== onlySelectableItem.value
        );
      }
    }
    setSelectedItems(updatedItems);
    onChange(updatedItems);
  };

  const renderElement = (
    label: string,
    value: string,
    onlySelectable?: boolean,
    disabled?: boolean
  ) => {
    const selected = selectedItems.includes(value);
    return (
      <div
        key={value}
        className={`checkboxes-list__item ${selected ? "selected" : ""}`}
        onClick={() => handleItemClick(value, onlySelectable)}
        tabIndex={0}
        style={{ pointerEvents: disabled ? "none" : "auto" }}
      >
        <div className="checkboxes-list__square">
          {selected && <CheckIcon />}
        </div>
        <p className={`checkboxes-list__text${disabled ? " disabled" : ""}`}>
          {label}
        </p>
      </div>
    );
  };

  return (
    <div className={`checkboxes-list ${className || ""}`}>
      {list.map(({ value, label, onlySelectable }) =>
        renderElement(label, value, onlySelectable, false)
      )}
    </div>
  );
}
