import React, { useState, useEffect } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { RadioButtonsList } from "../../../../NewComponents/Common/RadioButtonsList";
import { TextArea } from "../../../../NewComponents/Common/TextArea";
import { RadioButtonsForm } from "../../../../NewComponents/Common/RadioButtonsForm";
import { CheckboxesList } from "../../../../NewComponents/Common/CheckboxesList";
import { replaceText } from "../../../../../utils/replaceText";
import { generateAdaptiveQuestion } from "../../../../../utils/survey/generate-adaptive-question";

function surveyToComponent(data: SurveyData, variable: string) {
  return data[variable];
}

function componentToSurvey(value: string, questionType: string) {
  const updatedValue = {
    [questionType]: value,
  };
  return updatedValue;
}

export function SurveyQuestionTemplete({
  data,
  onDone,
  question,
  isChildAccount,
  previousAnswer,
}: SurveyComponentProps) {
  const [currentData, setData] = useState<string | boolean>(
    surveyToComponent(data, question?.value)
  );
  useEffect(() => {
    setData(surveyToComponent(data, question?.value));
  }, [question]);

  const [answer, setAnswer] = useState<null | boolean>(
    currentData === undefined ? undefined : Boolean(currentData)
  );

  const optionalText = isChildAccount ? "your child’s" : "your";

  useEffect(() => {
    if (currentData !== null) {
      setAnswer(currentData === undefined ? undefined : Boolean(currentData));
    }
  }, [question, currentData]);

  function updateData(value) {
    setAnswer(value);
    if (!value) {
      setData(value);
    } else {
      setData(null);
    }
  }

  function submit() {
    if (isEmpty(currentData)) return;

    sendSurveyQuestion(question?.value);
    onDone(componentToSurvey(currentData, question?.value));
    setData(null);
  }
  console.log("curr data", currentData);
  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        {replaceText(question.label, "your/your child’s", optionalText)}
      </p>
      {question?.description && (
        <p className="survey-question__descr paragraph-font--color">
          {question?.description}
        </p>
      )}
      {question?.textArea && (
        <TextArea
          onChange={(value) => setData(value)}
          placeholder={question?.placeholder}
          value={currentData}
          maxRows={5}
          minRows={3}
        />
      )}
      {question?.isTextInput && (
        <TextArea
          onChange={(value) => setData(value)}
          placeholder={question?.placeholder}
          value={currentData}
          maxRows={5}
          minRows={3}
        />
      )}

      {question?.isInputTypeNumber && (
        <input
          className="text-area text-input__field"
          type="number"
          min={1}
          value={currentData as string}
          onChange={(e) => {
            const inputValue = e.target.value;
            const parsedValue = parseInt(inputValue, 10);
            if (
              inputValue === "" ||
              (parsedValue >= 1 && parsedValue <= question?.maxNumber)
            ) {
              console.log("In if");
              setData(inputValue);
            }
          }}
          placeholder={question?.placeholder}
        />
      )}

      {question?.isInputRatingType && (
        <input
          className="text-area text-input__field"
          type="number"
          min={1}
          value={currentData as string}
          onChange={(e) => {
            const inputValue = e.target.value;
            const parsedValue = parseInt(inputValue, 10);
            if (
              inputValue === "" ||
              (parsedValue >= 1 && parsedValue <= question?.maxNumber)
            ) {
              console.log("In if curr", currentData);
              setData(inputValue);
            }
          }}
          placeholder={question?.placeholder}
        />
      )}

      {question?.radioButtonsForm && (
        <RadioButtonsForm
          list={[
            {
              value: question?.options[0].value,
              label: question?.options[0].label,
              extra:
                answer === true ? (
                  <TextArea
                    onChange={(value) => setData(value)}
                    placeholder={question?.placeholder}
                    maxRows={5}
                    value={currentData}
                  />
                ) : (
                  false
                ),
            },
            {
              value: question?.options[1].value,
              label: question?.options[1].label,
            },
          ]}
          onChange={(value) => updateData(value)}
          currentValue={answer !== null ? answer : undefined}
        />
      )}

      {question?.checkboxesList && (
        <CheckboxesList
          list={question.options}
          // currentValues={currentData}
          onChange={(value) => setData(value)}
        />
      )}

      {!question?.textArea &&
        !question?.radioButtonsForm &&
        !question?.checkboxesList &&
        !question?.isInputTypeNumber && (
          <RadioButtonsList
            list={
              previousAnswer
                ? generateAdaptiveQuestion(question.options, previousAnswer)
                : question.options
            }
            currentValue={
              currentData !== null ? (currentData as string) : undefined
            }
            onChange={(value) => setData(value)}
          />
        )}

      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={/^\s*$/.test(currentData) || isEmpty(currentData)}
      />
    </div>
  );
}
