import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError, logInfo } from "../../../../../shared/logger";

export const createUnsupported = async (email: string, phone: string) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/unsupported-patients/create`,
      {
        email,
        phone,
      }
    );

    return result;
  } catch (error) {
    logError("Error occured. Cannot create user", error);
    throw error;
  }
};
