import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const saveCard = async (
  patientId: string,
  customerId: string,
  paymentMethodId: string
) => {
  const token = await AuthenticationService.getAccessToken();
  const { data } = await axios.post<Patient>(
    `${ENV_CONFIG.AMAZON_API_DOMAIN}/payments/save-card`,
    {
      patientId,
      customerId,
      paymentMethodId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return data;
};
