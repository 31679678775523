import React, { useContext } from "react";
import { Button } from "../../Common/Button";
import { ReactComponent as TreatmentPlanImage } from "../../../../Assets/NewIcons/treatment-plan.svg";
import { ReactComponent as TreatmentPlanMobileImage } from "../../../../Assets/NewIcons/treatment-plan-mobile.svg";
import "./style.scss";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { MyTreatmentPlanPopupGenerator } from "../MyTreatmentPlanPopup";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { Skeleton } from "../../Common/Skeleton";

export function MyTreatmentPlanCard({ patientId }: { patientId: string }) {
  const { data, isLoading, isSuccess } =
    useGetFollowUpsByPatientIdQuery(patientId);
  const { showPopup } = useContext(PopupLayerContext);

  const isActive =
    isSuccess &&
    data.length >= 1 &&
    (!!data[0].treatmentPlan || !!data[1]?.treatmentPlan);

  return (
    <div className="my-treatment-plan-card ">
      <div
        className="my-treatment-plan-card-mobile"
        style={{ cursor: !isActive ? "not-allowed" : "pointer" }}
      >
        <p className="my-treatment-plan-card-mobile__title subheading-font subheading-font--color">
          View my treatment plan
        </p>
        <div className="my-treatment-plan-card-mobile__img-layout">
          <TreatmentPlanMobileImage />
        </div>
        {isActive && (
          <div
            className="my-treatment-plan-card-mobile__click"
            onClick={() => showPopup(MyTreatmentPlanPopupGenerator())}
          />
        )}
      </div>
      <div className="my-treatment-plan-card-desktop">
        <div className="my-treatment-plan-card-desktop__content">
          <div className="my-treatment-plan-card-desktop__image">
            <TreatmentPlanImage />
          </div>
          <p className="subheading-font subheading-font--margin subheading-font--color">
            My treatment plan
          </p>
          <p className="paragraph-font paragraph-font--color paragraph-font--margin">
            View the latest plan your provider recommended for you.
          </p>
        </div>
        <div className="my-treatment-plan-card-desktop__actions">
          {isLoading ? (
            <Skeleton />
          ) : (
            <Button
              className={!isActive ? "not-allowed-cursor" : "pointer-cursor"}
              size="small"
              text="view"
              onClick={() => showPopup(MyTreatmentPlanPopupGenerator())}
              disabled={!isActive}
              tooltip={
                !isActive ? "You don't have treatment plan yet" : undefined
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
