import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Medicine } from "../../../../../types/Entities/Medicine";

export const getAll = async () => {
  try {
    const result = await axios.get<Medicine[]>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/medicine/all`
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get medicine list", error);
    throw error;
  }
};
