import { Accutane } from "../../types/Entities/Accutane";
import { FormsOfPregnancyPopupGenerator } from "../../app/NewComponents/Patient/FormsOfPregnancyPopup";
import { PregnancyTestPopupGenerator } from "../../app/NewComponents/Patient/PregnancyTestPopup";
import isValidDate from "../is-valid-date";

export const getPatientMonthlyTasks = (accutane: Accutane | null) => [
  {
    name: "Confirm forms of birth control",
    isCompleted: accutane?.birthControl?.completed,
    key: "formOfBirthControlAction",
    action: FormsOfPregnancyPopupGenerator(),
  },
  {
    name: "Complete pregnancy test",
    isCompleted: accutane?.pregnancyTest?.completed,
    key: "completePregnancyTestAction",
    action: PregnancyTestPopupGenerator(
      !isValidDate(accutane?.lastConfirmationDate)
    ),
  },
];
