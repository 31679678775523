export const acneSubQuestions = [
  {
    label: "How long have you had acne?",
    value: "acnePeriod",
    questionType: "acne",
    options: [
      {
        value: "I’m new to acne",
        label: "I’m new to acne",
      },
      {
        value: "I’ve had acne for months",
        label: "I’ve had acne for months",
      },
      {
        value: "I’ve had acne for years",
        label: "I’ve had acne for years",
      },
    ],
  },
  {
    label: "Do you have dry skin?",
    value: "skinDryness",
    questionType: "acne",
    options: [
      {
        value: "Very dry",
        label: "Very dry",
      },
      {
        value: "Often dry",
        label: "Often dry",
      },
      {
        value: "Combination",
        label: "Combination",
      },
      {
        value: "Often oily",
        label: "Often oily",
      },
      {
        value: "Very oily",
        label: "Very oily",
      },
    ],
  },
  {
    label: "Do you have sensitive skin?",
    value: "skinSensitivity",
    questionType: "acne",
    options: [
      {
        value: "Very sensitive",
        label: "Very sensitive",
      },
      {
        value: "Somewhat sensitive",
        label: "Somewhat sensitive",
      },
      {
        value: "Not really",
        label: "Not really",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
  {
    label: "Are you currently pregnant or actively trying to become pregnant?",
    value: "pregnancy",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are you currently breastfeeding?",
    value: "breastfeeding",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Do you break out during/leading up to your menstrual cycle specifically?",
    value: "menstruationBreakout",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: "Yes",
        label: "Yes",
      },
      {
        value: "No, I breakout throughout the month",
        label: "No, I breakout throughout the month",
      },
      {
        value: "I don’t menstruate",
        label: "I don’t menstruate",
      },
    ],
  },
  {
    label: "Is your menstrual cycle regular?",
    value: "isRegularMenstruationCycle",
    questionType: "cycleAndMenstruation",
    parentQuestion: "menstruationBreakout",
    parentAnswer: "Yes", // handle multile options
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you take birth control?",
    value: "isBirthControlTaken",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Which birth control/hormonal meds are you taking?",
    value: "birthControlMeds",
    questionType: "cycleAndMenstruation",
    parentQuestion: "isBirthControlTaken",
    parentAnswer: true,
    options: [
      {
        value: "Yaz",
        label: "Yaz",
      },
      {
        value: "Ortho Tri-cyclen",
        label: "Ortho Tri-cyclen",
      },
      {
        value: "Beyaz",
        label: "Beyaz",
      },
      {
        value: "Estrostep",
        label: "Estrostep",
      },
      {
        value: "Loryna",
        label: "Loryna",
      },
      {
        value: "Yasmin",
        label: "Yasmin",
      },
      {
        value: "Alesse",
        label: "Alesse",
      },
      {
        value: "Ortho-Novum",
        label: "Ortho-Novum",
      },
      {
        value: "Apri",
        label: "Apri",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Do you have a history of PCOS (Polycystic Ovarian Syndrome)?",
    value: "isPcos",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What kind of products have you tried for acne in the past?",
    value: "previousAcneProductsTaken",
    questionType: "acne",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Cleansers or moisturizers",
        label: "Cleansers or moisturizers",
      },
      {
        value: "Over the counter creams",
        label: "Over the counter creams",
      },
      {
        value: "Vitamins for acne",
        label: "Vitamins for acne",
      },
      {
        value: "Prescription creams",
        label: "Prescription creams",
      },
      {
        value: "Prescription pills",
        label: "Prescription pills",
      },
      {
        value: "I haven’t tried any products",
        label: "I haven’t tried any products",
        onlySelectable: true,
      },
    ],
  },
  {
    label:
      "Which non-prescription products do you use on your skin (e.g. washes, cleansers, masks, creams, etc.)?",
    value: "nonPrescriptionProducts",
    questionType: "acne",
    placeholder: "Which products?",
    textArea: true,
    // isArrayOfAnswer: true,
    parentQuestion: "previousAcneProductsTaken",
    parentAnswer: "Over the counter creams",
    description: "Please include the brand and product name if you can.",
    options: [
      // {
      //   value: "Please list the medications you take and for what condition",
      //   label: "Please list the medications you take and for what condition",
      // },
    ],
  },
  {
    label: "Which prescription creams and cleansers have you used?",
    value: "prescriptionTopicalsUsed",
    questionType: "acne",
    parentQuestion: "previousAcneProductsTaken",
    parentAnswer: "Prescription creams",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Benzoyl Peroxide cleanser (Benzac)",
        label: "Benzoyl Peroxide cleanser (Benzac)",
      },
      {
        value:
          "Sodium Sulfacetamide/Sulfur Cleanser/Creams (Avar, Sulfacet R, Sumaxan,)",
        label:
          "Sodium Sulfacetamide/Sulfur Cleanser/Creams (Avar, Sulfacet R, Sumaxan,)",
      },
      {
        value: "Tretinoin (Retin-A)",
        label: "Tretinoin (Retin-A)",
      },
      {
        value: "Tazarotene (Tazorac)",
        label: "Tazarotene (Tazorac)",
      },
      {
        value: "Adapalene (Differin, Epiduo, Epiduo Forte)",
        label: "Adapalene (Differin, Epiduo, Epiduo Forte)",
      },
      {
        value: "Dapsone (Aczone)",
        label: "Dapsone (Aczone)",
      },
      {
        value: "Clindamycin (Cleocin T, Clindagel)",
        label: "Clindamycin (Cleocin T, Clindagel)",
      },
      {
        value:
          "Clindamycin/Benzoyl Peroxide combo (Benzaclin, Acanya, Duac, Onexton)",
        label:
          "Clindamycin/Benzoyl Peroxide combo (Benzaclin, Acanya, Duac, Onexton)",
      },
      {
        value: "Clindamycin/Tretinoin combo (Ziana)",
        label: "Clindamycin/Tretinoin combo (Ziana)",
      },
      {
        value: "Sulfacetamide (Klaron, Ovace)",
        label: "Sulfacetamide (Klaron, Ovace)",
      },
      {
        value: "Azelaic Acid (Finacea, Azelex)",
        label: "Azelaic Acid (Finacea, Azelex)",
      },
      {
        value: "Erythromycin",
        label: "Erythromycin",
      },
      {
        value: "Benzamycin (Benzoyl Peroxide and Erythromycin combo)",
        label: "Benzamycin (Benzoyl Peroxide and Erythromycin combo)",
      },
      {
        value: "Amzeeq (topical Minocycline)",
        label: "Amzeeq (topical Minocycline)",
      },
      {
        value: "Aklief (Trifarotene)",
        label: "Aklief (Trifarotene)",
      },
    ],
  },
  {
    label: "Please select all pills you have ever taken for acne.",
    value: "acnePillsTaken",
    questionType: "acne",
    parentQuestion: "previousAcneProductsTaken",
    parentAnswer: "Prescription pills",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Doxycycline (Doryx, Oracea, Monodox, Acticlate)",
        label: "Doxycycline (Doryx, Oracea, Monodox, Acticlate)",
      },
      {
        value: "Minocycline (Solodyn, Minocin, Dynacin, Ximino)",
        label: "Minocycline (Solodyn, Minocin, Dynacin, Ximino)",
      },
      {
        value: "Tetracycline",
        label: "Tetracycline",
      },
      {
        value: "Trimethoprim/Sulfamethoxazole (Bactrim)",
        label: "Trimethoprim/Sulfamethoxazole (Bactrim)",
      },
      {
        value: "Isotretinoin (Accutane, Claravis, Amnesteem, Absorica)",
        label: "Isotretinoin (Accutane, Claravis, Amnesteem, Absorica)",
      },
      {
        value: "Spironolactone",
        label: "Spironolactone",
      },
      {
        value: "Amoxicillin (Augmentin)",
        label: "Amoxicillin (Augmentin)",
      },
      {
        value: "Cefadroxil (Duricef)",
        label: "Cefadroxil (Duricef)",
      },
      {
        value: "Cefuroxime (Ceftin)",
        label: "Cefuroxime (Ceftin)",
      },
      {
        value: "Erythromycin",
        label: "Erythromycin",
      },
    ],
  },
  // {
  //   label: "Are you currently using any prescription products for acne?",
  //   value: "currentlyUsingPrescriptionProducts",
  //   questionType: "acne",
  //   parentQuestion: "previousAcneProductsTaken",
  //   parentTextAnwser: "I haven’t tried any products",
  //   radioButtonsForm: true,
  //   placeholder: "Which medications?",
  //   options: [
  //     {
  //       value: true,
  //       label: "Yes",
  //     },
  //     {
  //       value: false,
  //       label: "No",
  //     },
  //   ],
  // },
  {
    label: "Are there any specific medications you are interested in?",
    value: "specificMedicationsInterest",
    questionType: "acne",
    parentQuestion: "previousAcneProductsTaken",
    parentTextAnwser: "I haven’t tried any products",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Please list your medications:",
  //   value: "medications",
  //   questionType: "otherMedical",
  //   parentQuestion: "otherMedications",
  //   parentAnwser: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value:
  //         "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
  //       label:
  //         "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
  //     },
  //   ],
  // },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "Which allergies",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "In the last month, how would you describe your stress level?",
    value: "stressLevel",
    questionType: "lifestyle",
    options: [
      {
        value: "Never stressed",
        label: "Never stressed",
      },
      {
        value: "Almost never stressed",
        label: "Almost never stressed",
      },
      {
        value: "Sometimes stressed",
        label: "Sometimes stressed",
      },
      {
        value: "Fairly stressed",
        label: "Fairly stressed",
      },
      {
        value: "Very stressed",
        label: "Very stressed",
      },
    ],
  },
  {
    label: "In the last month, how would you describe your sleep?",
    value: "sleepAmount",
    questionType: "lifestyle",
    options: [
      {
        value: "5 hours or less",
        label: "5 hours or less",
      },
      {
        value: "Between 6-7 hours",
        label: "Between 6-7 hours",
      },
      {
        value: "8+ hours",
        label: "8+ hours",
      },
    ],
  },
  {
    label:
      "How often do you consume dairy (e.g. cheeses, yogurts, milk, butter)?",
    value: "dairyConsumption",
    questionType: "lifestyle",
    options: [
      {
        value: "Never",
        label: "Never",
      },
      {
        value: "A few times a day",
        label: "A few times a day",
      },
      {
        value: "A few times a week",
        label: "A few times a week",
      },
      {
        value: "A few times a month",
        label: "A few times a month",
      },
    ],
  },
];
