import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { AccutaneNotification } from "../../../../../types/Accutane";

export const createNotification = async (
  patientId: string,
  nextConfirmationDate: string
): Promise<number> => {
  try {
    const result = await axios.post<AccutaneNotification>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/create-notification/${patientId}`,
      {
        nextConfirmationDate,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to create notification");
    throw error;
  }
};
