import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as BellIcon } from "../../../../Assets/icons/notify.svg";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { Loader } from "../Loader";
import { NotificationLayerContext } from "../NotificationLayer";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { getSessionState } from "../../../../features/session";

enum STATUSES {
  IDLE = "idle",
  LOADING = "loading",
}

interface Props {
  patientId: string;
}

export function NotifyProviderButton({ patientId }: Props) {
  const [status, setStatus] = useState(STATUSES.IDLE);
  const { userRole } = useSelector(getSessionState);
  const { showError, showSuccess } = useContext(NotificationLayerContext);

  async function submit() {
    setStatus(STATUSES.LOADING);

    try {
      // await HoneydewAPI.chats.highlightMessageForProvider(patientId);
      await HoneydewAPI.chats.getNotifyMessageForProvider(patientId);

      showSuccess({
        title: "Success!",
        description: "Provider was notified about new message in chat",
      });
    } catch (e: any) {
      logError("Cannot notify provider", e);
      showError({
        title: "Something went wrong",
        description: "Cannot notify provider. Please, try again later",
      });
    } finally {
      setStatus(STATUSES.IDLE);
    }
  }

  if (userRole !== "care-coordinators" && userRole !== "admins") {
    return null;
  }

  return (
    <div
      className={`notify-provider notify-provider--${status}`}
      onClick={status === STATUSES.IDLE ? submit : undefined}
      data-tooltip="Notify provider"
    >
      {status === STATUSES.LOADING ? (
        <Loader className="notify-provider__icon" />
      ) : (
        <BellIcon className="notify-provider__icon" />
      )}
    </div>
  );
}
