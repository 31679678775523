import React from "react";
import { Loader } from "../Loader";
import "./style.scss";

export function LoadingBlock() {
  return (
    <div className="loading-block">
      <Loader className="loading-block__loader" />
      <p className="loading-block__title">Loading</p>
      <p className="loading-block__text paragraph-font--color">
        Please wait...
      </p>
    </div>
  );
}
