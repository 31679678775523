import React, { useState } from "react";
import { HelpPopupTemplate } from "../../../NewComponents/Common/PopupLayer/help";
import { Button } from "../../../NewComponents/Common/Button";
import "./style.scss";
import { setLoading } from "../../../../features/patientView";

interface PopupProps {
  onClose: () => void;
}

function ConfirmChoicePopup({
  value,
  onClose,
  onConfirm,
  text,
  buttonText,
}: {
  value: boolean;
  onClose: () => void;
  onConfirm: (value: boolean) => void;
  text: string;
  buttonText: string;
}) {
  const onSubmit = async () => {
    onClose();
    onConfirm(value);
  };

  return (
    <div className="child-bearing-potential-popup">
      <div className="child-bearing-potential-popup__content">
        <p className="child-bearing-potential-popup__content-text">{text}</p>
      </div>
      <div className="child-bearing-potential-popup__control-buttons">
        <Button
          text="NO, GO BACK"
          onClick={onClose}
          size="small"
          view="secondary"
        />
        <Button
          text={buttonText || "YES, CHANGE"}
          onClick={onSubmit}
          size="small"
        />
      </div>
    </div>
  );
}

export function ConfirmChoicePopupGenerator(
  value: boolean,
  setOptionFlag: (value: boolean) => void,
  text: string,
  buttonText: string
) {
  return function render({ onClose }: PopupProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <ConfirmChoicePopup
          value={value}
          onClose={onClose}
          onConfirm={setOptionFlag}
          text={text}
          buttonText={buttonText}
        />
      </HelpPopupTemplate>
    );
  };
}
