import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../Common/Button";
import { ReactComponent as TreatmentsImage } from "../../../../Assets/NewIcons/treatments.svg";
import { ReactComponent as TreatmentsMobileImage } from "../../../../Assets/NewIcons/treatments-mobile.svg";
import "./style.scss";

export function OrderTreatmentCard() {
  const history = useHistory();

  function showBuyProductsPopup() {
    history.push("/buy-products");
  }

  return (
    <div className="order-treatment-card">
      <div className="order-treatment-card-mobile">
        <p className="order-treatment-card-mobile__title subheading-font subheading-font--color">
          Buy products
        </p>
        <div className="order-treatment-card-mobile__img-layout">
          <TreatmentsMobileImage />
        </div>
        <div
          className="order-treatment-card-mobile__click"
          onClick={showBuyProductsPopup}
        />
      </div>
      <div className="order-treatment-card-desktop">
        <div className="order-treatment-card-desktop__content">
          <div className="order-treatment-card-desktop__image">
            <TreatmentsImage />
          </div>
          <p className="subheading-font subheading-font--margin subheading-font--color">
            Buy products
          </p>
          <p className="paragraph-font paragraph-font--color paragraph-font--margin">
            Honeydew-vetted products shipped directly to you.
          </p>
        </div>
        <div className="order-treatment-card-desktop__actions">
          <Button size="small" text="order" onClick={showBuyProductsPopup} />
        </div>
      </div>
    </div>
  );
}
