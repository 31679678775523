import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const endIsotretinoin = async (userId: string): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/end/${userId}`
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to end isotretinoin");
    throw error;
  }
};
