import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import getCookie from "../../../../utils/get-cookie";
import setCookie from "../../../../utils/set-cookie";
import { signOut, getSessionState } from "../../../../features/session";
import { clearAllStores } from "../../../../features/utils/clear-all-stores";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";

const expiry = 1800000; // twenty minutes

function recordActivity() {
  setCookie("acneawayActivityTime", `${Date.now()}`);
}

function subscribeActivityHandler() {
  window.addEventListener("click", recordActivity);
  window.addEventListener("touchstart", recordActivity);
}

function unsubscribeActivityHandler() {
  window.removeEventListener("click", recordActivity);
  window.removeEventListener("touchstart", recordActivity);
}

export function LogoutTimer() {
  const { closeAllPopups } = useContext(PopupLayerContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSessionExists } = useSelector(getSessionState);
  recordActivity();
  let activityTime = parseInt(getCookie("acneawayActivityTime") || "0", 10);
  let intervalIndex: number | undefined;

  function triggerSigningOut() {
    if (!intervalIndex) return;
    clearInterval(intervalIndex);
    dispatch(signOut());
    closeAllPopups();
    clearAllStores();
    history.push("/login");
  }

  function check() {
    if (
      !isSessionExists ||
      (activityTime && activityTime + expiry < Date.now())
    ) {
      unsubscribeActivityHandler();
      triggerSigningOut();
    }
  }

  intervalIndex = window.setInterval(() => {
    activityTime = parseInt(getCookie("acneawayActivityTime") || "0", 10);
    check();
  }, 1000);

  check();

  subscribeActivityHandler();

  return null;
}
