export const hidradenitisSuppurativaSubQuestions = [
  {
    label:
      "Have you been diagnosed with Hidradenitis Suppurativa (HS) previously?",
    value: "hsDiagnosed",
    questionType: "hidradenitisSuppurativa",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "How long have you been experiencing symptoms of Hidradenitis Suppurativa?",
    value: "hsSymptomsDuration",
    questionType: "hidradenitisSuppurativa",
    description:
      "(Symptoms include cysts or boils in the armpit or groin region, as well as pain, itching, inflammation, and/or drainage from cysts)",
    options: [
      {
        value: "I just started experiencing symptoms",
        label: "I just started experiencing symptoms",
      },
      {
        value: "For months",
        label: "For months",
      },
      {
        value: "For years",
        label: "For years",
      },
    ],
  },
  {
    label: "What have you tried for Hidradenitis Suppurativa in the past?",
    value: "hsTreatments",
    questionType: "hidradenitisSuppurativa",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value:
          "Over-the-counter products (e.g. warm compresses, hydrocortisone, tylenol etc.)",
        label:
          "Over-the-counter products (e.g. warm compresses, hydrocortisone, tylenol etc.)",
      },
      {
        value: "Prescription medications",
        label: "Prescription medications",
      },
      {
        value: "Procedures / surgical interventions",
        label: "Procedures / surgical interventions",
      },
      {
        value: "I haven’t tried anything",
        label: "I haven’t tried anything",
        onlySelectable: true,
      },
    ],
  },
  {
    label:
      "Which over-the-counter products have you taken for Hidradenitis Suppurativa?",
    value: "hsOtcMedications",
    questionType: "hidradenitisSuppurativa",
    parentQuestion: "hsTreatments",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    parentAnswer:
      "Over-the-counter products (e.g. warm compresses, hydrocortisone, tylenol etc.)",
    options: [
      {
        value: "Warm compresses",
        label: "Warm compresses",
      },
      {
        value: "Cleansers (benzoyl peroxide / antibacterials)",
        label: "Cleansers (benzoyl peroxide / antibacterials)",
      },
      {
        value:
          "Topical antibacterial cream/ointment (neosporin, polysporin, hydrogen peroxide)",
        label:
          "Topical antibacterial cream/ointment (neosporin, polysporin, hydrogen peroxide)",
      },
      {
        value: "Steroid Cream (hydrocortisone)",
        label: "Steroid Cream (hydrocortisone)",
      },
      {
        value: "Wound care products",
        label: "Wound care products",
      },
      {
        value: "Pain relievers (Motrin or Tylenol)",
        label: "Pain relievers (Motrin or Tylenol)",
      },
    ],
  },
  {
    label:
      "Which prescription medications have you taken for Hidradenitis Suppurativa?",
    value: "hsPrescriptionMedications",
    questionType: "hidradenitisSuppurativa",
    parentQuestion: "hsTreatments",
    parentAnswer: "Prescription medications",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Topical antibiotics (e.g. clindamycin)",
        label: "Topical antibiotics (e.g. clindamycin)",
      },
      {
        value: "Oral antibiotics (e.g. doxycycline)",
        label: "Oral antibiotics (e.g. doxycycline)",
      },
      {
        value: "Biologics (e.g. humira)",
        label: "Biologics (e.g. humira)",
      },
      {
        value: "Isotretinoin (Accutane)",
        label: "Isotretinoin (Accutane)",
      },
    ],
  },
  {
    label:
      "Have you had any surgical interventions for Hidradenitis Suppurativa?",
    value: "hsSurgicalInterventions",
    questionType: "hidradenitisSuppurativa",
    parentQuestion: "hsTreatments",
    parentAnswer: "Procedures / surgical interventions",
    radioButtonsForm: true,
    isArrayOfAnswer: true,
    placeholder: "Please provide details of the surgical interventions",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Are you currently using any medications or treatment to treat your condition?",
    value: "currentMedication",
    questionType: "hidradenitisSuppurativa",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are there specific medications you are interested in?",
    value: "specificInterestedMedics",
    questionType: "hidradenitisSuppurativa",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label: "Please check if you experience any of the following symptoms",
    value: "symptomsSeverity",
    questionType: "hidradenitisSuppurativa",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        label: "Pain or discomfort",
        value: "hsPainDiscomfort",
      },
      {
        label: "Inflammation or swelling",
        value: "Inflammation or swelling",
      },
      {
        label: "Presence of nodules or abscesses",
        value: "Presence of nodules or abscesses",
      },
      {
        label: "Drainage of pus or foul-smelling fluid",
        value: "Drainage of pus or foul-smelling fluid",
      },
      {
        label: "Scarring or skin discoloration",
        value: "Scarring or skin discoloration",
      },
      {
        label: "Itching or burning sensation",
        value: "Itching or burning sensation",
      },
      {
        label: "Restricted movement or difficulty performing daily activities",
        value: "Restricted movement or difficulty performing daily activities",
      },
    ],
  },
  {
    label:
      "Are there any specific triggers or factors that worsen your Hidradenitis Suppurativa symptoms?",
    value: "hsTriggers",
    questionType: "hidradenitisSuppurativa",
    radioButtonsForm: true,
    placeholder: "Please describe the triggers or factors",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Please check if you experience any of the following",
    value: "hsImpactOnLifestyle",
    questionType: "hidradenitisSuppurativa",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        label: "My skin condition affects my personal relationships",
        value: "My skin condition affects my personal relationships",
      },
      {
        label: "My skin condition affects my ability to work effectively",
        value: "My skin condition affects my ability to work effectively",
      },
      {
        label: "I give up certain activities because of my skin condition",
        value: "I give up certain activities because of my skin condition",
      },
      {
        label: "My condition affects my sleep",
        value: "My condition affects my sleep",
      },
      {
        label: "I feel alone or isolated because of my skin conditions",
        value: "I feel alone or isolated because of my skin conditions",
      },
    ],
  },
  {
    label: "Do you have a family history of Hidradenitis Suppurativa",
    value: "hsFamilyHistory",
    questionType: "hidradenitisSuppurativa",
    options: [
      {
        value: "Yes",
        label: "Yes",
      },
      {
        value: "No",
        label: "No",
      },
      {
        value: "Not Sure",
        label: "Not Sure",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
  {
    label: "Are you currently pregnant or trying to become pregnant?",
    value: "pregnancy",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are you currently breastfeeding?",
    value: "breastfeeding",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    placeholder:
      "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    placeholder: "Which allergies?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Is there anything else we should know?",
    value: "anythingElse",
    questionType: "otherMedical",
    placeholder: "e.g. other medical conditions",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "In the last month, how would you describe your stress level?",
    value: "stressLevel",
    questionType: "lifestyle",
    options: [
      {
        value: "Never stressed",
        label: "Never stressed",
      },

      {
        value: "Almost never stressed",
        label: "Almost never stressed",
      },
      {
        value: "Sometimes stressed",
        label: "Sometimes stressed",
      },
      {
        value: "Fairly stressed",
        label: "Fairly stressed",
      },

      {
        value: "Very stressed",
        label: "Very stressed",
      },
    ],
  },

  {
    label: "Do you smoke tobacco cigarettes?",
    value: "smokeCigarettes",
    questionType: "lifestyle",
    description:
      "(We’re only asking because smoking tobacco cigarettes can impact your Hidradenitis Suppurativa condition and treatment response)",
    options: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
];
