import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

export const getPubnubMessage = async (accountId: string, role: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<
      GraphQLResponse<{ newPubnubMessageForUser: string }>
    >(`${ENV_CONFIG.CHATS_SERVICE_GRAPHQL_URL}`, {
      headers: {
        Authorization: token,
      },
      params: {
        query: `query { newPubnubMessageForUser(accountId: "${accountId}", role: "${role}") }`,
      },
    });

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.newPubnubMessageForUser;
  } catch (error: any) {
    logError("Error occured. Cannot get chat generated token", error);
    throw error;
  }
};
