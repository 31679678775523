import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { AuthenticationService } from "../../../../cognito";

const BASE_URL = `${ENV_CONFIG.AMAZON_API_DOMAIN}/chat/get-admin-chat-token`;

export const getAdminChatToken = async (email: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const { data } = await axios.get<string>(
      `${BASE_URL}?identity=${encodeURIComponent(email)}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data;
  } catch (error) {
    logError("Error occured. Cannot get chat access token", error);
    throw error;
  }
};
