export const hairLossSubQuestions = [
  {
    label: "When did you first notice your hair loss?",
    value: "hairLossNoticeTimeframe",
    questionType: "hairLoss",
    options: [
      {
        value: "Within the last few months",
        label: "Within the last few months",
      },
      {
        value: "Within the last few years",
        label: "Within the last few years",
      },
      {
        value: "Over 5 years ago",
        label: "Over 5 years ago",
      },
    ],
  },
  {
    label:
      "If so, did you experience a major physical or emotional stressor within the last 6 months ?",
    value: "firsthairLoss",
    questionType: "hairLoss",
    parentQuestion: "hairLossNoticeTimeframe",
    parentAnswer: "Within the last few months",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
  {
    label: "What pattern of hair loss best describes your condition?",
    value: "hairLossPattern",
    questionType: "hairLoss",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Top of scalp (the crown or vertex)",
        label: "Top of scalp (the crown or vertex)",
      },
      {
        value: "Frontal hairline receding",
        label: "Frontal hairline receding",
      },
      {
        value: "Frontal scalp (from hairline to top of scalp)",
        label: "Frontal scalp (from hairline to top of scalp)",
      },
      {
        value: "Patches of hair loss in circles",
        label: "Patches of hair loss in circles",
      },
      {
        value: "Generalized hair loss all over the scalp",
        label: "Generalized hair loss all over the scalp",
      },
      {
        value: "Beard area",
        label: "Beard area",
      },
      {
        value: "Eyebrows",
        label: "Eyebrows",
      },
      {
        value: "Eyelashes",
        label: "Eyelashes",
      },
    ],
  },
  {
    label: "Is your hair loss associated with itch or dry flakiness?",
    value: "flakiness",
    questionType: "hairLoss",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What have you tried for hair loss in the past?",
    value: "hairLossTry",
    questionType: "hairLoss",
    checkboxesList: true,
    options: [
      {
        value:
          "Over-the-counter products (e.g. biotin, rogaine, at-home devices etc.)",
        label:
          "Over-the-counter products (e.g. biotin, rogaine, at-home devices etc.)",
      },
      {
        value: "Prescription medications",
        label: "Prescription medications",
      },
      {
        value: "Procedures at a doctor’s office",
        label: "Procedures at a doctor’s office",
      },
      {
        value: "I haven’t tried anything",
        label: "I haven’t tried anything",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What over-the-counter treatments have you tried?",
    value: "overTheCounterTreatments",
    questionType: "hairLoss",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    parentQuestion: "hairLossTry",
    parentAnswer:
      "Over-the-counter products (e.g. biotin, rogaine, at-home devices etc.)",
    options: [
      {
        value: "Oral Supplements",
        label: "Oral Supplements",
      },
      {
        value: "Topicals (Creams/Shampoos/Lotions)",
        label: "Topicals (Creams/Shampoos/Lotions)",
      },
      {
        value: "LED light therapy/LASER device",
        label: "LED light therapy/LASER device",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Which oral supplements have you tried?",
    value: "overTheCounterTreatmentsfollow",
    questionType: "hairLoss",
    parentQuestion: "overTheCounterTreatments",
    parentAnswer: "Oral Supplements",
    otherCheckboxesList: true,
    isArrayOfAnswer: true,
    options: [
      {
        value: "Biotin",
        label: "Biotin",
      },
      {
        value: "Nutrafol",
        label: "Nutrafol",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Please select topicals",
    value: "overTheCounterTreatmentsfollowup",
    questionType: "hairLoss",
    parentQuestion: "overTheCounterTreatments",
    parentAnswer: "Topicals (Creams/Shampoos/Lotions)",
    isArrayOfAnswer: true,
    checkboxesList: true,
    options: [
      {
        value: "Minoxidil ( Rogaine)",
        label: "Minoxidil ( Rogaine)",
      },
      {
        value: "other",
        label: "Other",
      },
    ],
  },
  {
    label: "what percentage concentration?",
    value: "minoxidilPercentage",
    questionType: "hairLoss",
    parentQuestion: "overTheCounterTreatmentsfollowup",
    placeholder: "Percentage concentration",
    parentAnswer: "Minoxidil ( Rogaine)",
    textArea: true,
    isArrayOfAnswer: true,
    options: [
      {
        value: "Percentage concentration",
        label: "Percentage concentration",
      },
    ],
  },
  {
    label: "Please select Other topicals",
    value: "otherTopicals",
    questionType: "hairLoss",
    parentQuestion: "overTheCounterTreatmentsfollowup",
    placeholder: "Other topicals",
    parentAnswer: "Other",
    textArea: true,
    isArrayOfAnswer: true,
    options: [
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  // {
  //   label: "Please select other products",
  //   value: "overTheCounterTreatmentsfollowup1",
  //   questionType: "hairLoss",
  //   parentQuestion: "overTheCounterTreatments",
  //   parentAnswer: "What other over-the-counter products have you tried?",
  //   isArrayOfAnswer: true,
  //   options: [
  //     {
  //       value: "LED light therapy/LASER device",
  //       label: "LED light therapy/LASER device",
  //     },
  //     {
  //       value: "Other",
  //       label: "Other",
  //     },
  //   ],
  // },
  {
    label: "What prescription medications have you tried?",
    value: "prescriptionMedications",
    questionType: "hairLoss",
    parentQuestion: "hairLossTry",
    parentAnswer: "Prescription medications",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Finasteride (Propecia)",
        label: "Finasteride (Propecia)",
      },
      {
        value: "Low dose oral Minoxidil",
        label: "Low dose oral Minoxidil",
      },
      {
        value: "Opzelura cream - topical JAK inhibitor",
        label: "Opzelura cream - topical JAK inhibitor",
      },
      {
        value: "Olumiant - Oral JAK inhibitor",
        label: "Olumiant - Oral JAK inhibitor",
      },
      {
        value: "Topical steroids",
        label: "Topical steroids",
      },
    ],
  },
  {
    label: "Please describe dose of Minoxidil",
    value: "prescriptionMedicationsfollow",
    questionType: "hairLoss",
    parentQuestion: "prescriptionMedications",
    parentAnswer: "Low dose oral Minoxidil",
    textArea: true,
    placeHolder: "If so, what dose ?",
    options: [
      {
        value: "If so what dose ?",
        label: "If so what dose ?",
      },
    ],
  },
  {
    label: "What procedures have you tried at a provider’s office?",
    value: "officeProcedures",
    questionType: "hairLoss",
    parentQuestion: "hairLossTry",
    parentAnswer: "Procedures at a doctor’s office",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "PRP (Platelet-rich Plasma) injections",
        label: "PRP (Platelet-rich Plasma) injections",
      },
      {
        value: "Steroid injections",
        label: "Steroid injections",
      },
      {
        value: "Hair Transplant",
        label: "Hair Transplant",
      },
    ],
  },
  {
    label:
      "Are you currently using any medications or treatment to treat your condition?",
    value: "currentMedics",
    questionType: "hairLoss",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are there specific medications you are interested in?",
    value: "hairLossSpecificMedicationsInterest",
    questionType: "hairLoss",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Please list your medications:",
  //   value: "medications",
  //   questionType: "otherMedical",
  //   parentQuestion: "otherMedications",
  //   placeholder: "Which products?",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value:
  //         "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
  //       label:
  //         "Please list the medications you take and for what condition (e.g. metformin (diabetes), claritin (seasonal allergies), etc.)",
  //     },
  //   ],
  // },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "Which allergies?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Is there anything else we should know? (e.g. other medical conditions)",
    value: "otherInformation",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "e.g. other medical conditions",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
];
