import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const markAsPinned = async (
  patientId: string,
  state: boolean,
  issuer: "care-coordinator" | "admin"
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/mark-as-pinned`,
      {
        id: patientId,
        state,
        issuer,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError("Error occured. Cannot update pin flag on patient");
    throw error;
  }
};
