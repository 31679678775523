import React from "react";
import { NavLink } from "react-router-dom";
import { MenuItem } from "./types";

interface ActiveWrapProps {
  children: JSX.Element[];
  item: MenuItem;
  onClick?: () => void;
}

export function ActiveWrap({ children, item, onClick }: ActiveWrapProps) {
  if (item.path) {
    return (
      <NavLink
        exact
        to={item.path}
        className="menu__link-active-wrap"
        activeClassName="selected"
        onClick={onClick}
      >
        {children}
      </NavLink>
    );
  }
  if (item.onClick) {
    return (
      <div className="menu__link-active-wrap" onClick={item.onClick}>
        {children}
      </div>
    );
  }

  return null;
}
