import { ImageBlob } from "../../../../../types/Base/ImageBlob";

type ImageItem = Blob | ImageBlob | null;

export type SkinImage = {
  image: ImageItem;
  side: string;
  title: string;
  description: string;
  group: string;
};

export type SkinImageType = {
  label: string;
  value: string;
  images: SkinImage[];
};

const skinImageTypes: SkinImageType[] = [
  {
    label: "Face",
    value: "face",
    images: [
      {
        image: null,
        side: "face_left",
        title: "Face",
        group: "face",
        description: "Left profile",
      },
      {
        image: null,
        side: "face_center",
        title: "Face",
        group: "face",
        description: "Center profile",
      },
      {
        image: null,
        side: "face_right",
        title: "Face",
        group: "face",
        description: "Right profile",
      },
    ],
  },
  {
    label: "Chest",
    value: "chest",
    images: [
      {
        image: null,
        side: "chest_front",
        title: "Chest",
        group: "chest",
        description: "The front of your chest",
      },
    ],
  },
  {
    label: "Back",
    value: "back",
    images: [
      {
        image: null,
        side: "back_1",
        title: "Back",
        group: "back",
        description: "Back",
      },
      {
        image: null,
        side: "back_2",
        title: "Back",
        group: "back",
        description: "Back",
      },
    ],
  },
  {
    label: "Hand",
    value: "hand",
    images: [
      {
        image: null,
        side: "left_hand",
        title: "Left Hand",
        group: "hand",
        description: "The left hand",
      },
      {
        image: null,
        side: "right_hand",
        title: "Right Hand",
        group: "hand",
        description: "The right hand",
      },
    ],
  },
  {
    label: "Arm",
    value: "arm",
    images: [
      {
        image: null,
        side: "left_arm",
        title: "Left Arm",
        group: "arm",
        description: "The left arm",
      },
      {
        image: null,
        side: "right_arm",
        title: "Right Arm",
        group: "arm",
        description: "The right arm",
      },
    ],
  },
  {
    label: "Neck",
    value: "neck",
    images: [
      {
        image: null,
        side: "neck",
        group: "neck",
        title: "Neck",
        description: "Neck",
      },
    ],
  },
  {
    label: "Hair",
    value: "hair",
    images: [
      {
        image: null,
        side: "frontal_hairline",
        title: "Frontal Hairline",
        group: "hair",
        description: "The frontal hairline",
      },
      {
        image: null,
        side: "mid_scalp",
        title: "Mid-scalp",
        group: "hair",
        description: "The mid-scalp area",
      },
      {
        image: null,
        side: "neckline",
        title: "Back/Neckline",
        group: "hair",
        description: "The neckline of your hairline",
      },
      {
        image: null,
        side: "left_hair_side",
        title: "Left side",
        group: "hair",
        description: "Hold the hair up",
      },
      {
        image: null,
        side: "right_hair_side",
        title: "Right side",
        group: "hair",
        description: "Hold the hair up",
      },
    ],
  },
  {
    label: "Abdomen",
    value: "abdomen",
    images: [
      {
        image: null,
        side: "abdomen",
        title: "Abdomen",
        description: "Abdomen",
        group: "abdomen",
      },
    ],
  },
  {
    label: "Groin",
    value: "groin",
    images: [
      {
        image: null,
        side: "groin_area",
        title: "Groin Area",
        group: "groin",
        description: "The Groin area",
      },
    ],
  },
  {
    label: "Butt",
    value: "butt",
    images: [
      {
        image: null,
        side: "butt",
        title: "Butt",
        group: "butt",
        description: "The butt",
      },
    ],
  },
  {
    label: "Leg",
    value: "leg",
    images: [
      {
        image: null,
        side: "left_leg",
        title: "Left Leg",
        group: "leg",
        description: "The left leg",
      },
      {
        image: null,
        side: "right_leg",
        title: "Right Leg",
        group: "leg",
        description: "The right leg",
      },
    ],
  },
  {
    label: "Foot",
    value: "foot",
    images: [
      {
        image: null,
        side: "left_foot",
        title: "Left Foot",
        group: "foot",
        description: "The left foot",
      },
      {
        image: null,
        side: "right_foot",
        title: "Right Foot",
        group: "foot",
        description: "The right foot",
      },
    ],
  },
];

export { skinImageTypes };
