import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as WomenWithGift } from "../../../../Assets/NewIcons/women-with-gift.svg";
import "./style.scss";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { ReferralLinkPopupGenerator } from "../ReferralLinkPopup";
import { getSessionState } from "../../../../features/session";
import { Skeleton } from "../../Common/Skeleton";

export function ReferralCard() {
  const { showPopup } = useContext(PopupLayerContext);
  const { activePatientId } = useSelector(getSessionState);

  return (
    <div className="referral-card">
      <div className="referral-card__info">
        <p className="subheading-font subheading-font--margin subheading-font--color">
          Give two months, get two months free!
        </p>
        <p className="paragraph-font paragraph-font--color paragraph-font--margin">
          For every friend who signs up for their first month, you each get two
          months free.
        </p>
        {activePatientId ? (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              showPopup(
                ReferralLinkPopupGenerator({ patientId: activePatientId })
              );
            }}
            className="link-font link-font--color"
          >
            get link
          </a>
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="referral-card__img">
        <WomenWithGift />
      </div>
    </div>
  );
}
