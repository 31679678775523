import React, { useContext, useState } from "react";
import Select from "react-select";
import { v4 } from "uuid";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import "./style.scss";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { ReactComponent as AddButtonIcon } from "../../../../Assets/icons/PlusIcon.svg";
import { ReactComponent as RemoveButtonIcon } from "../../../../Assets/icons/close-button-white.svg";
import { Button } from "../../Common/Button";
import { TextInput } from "../../Common/TextInput";
import {
  useCreateTreatmentPlanMutation,
  useUpdateTreatmentPlanMutation,
} from "../../../../features/api/medications";
import { Medication, TreatmentPlanPreset } from "../../../../types/Medication";

interface GeneratorProps {
  onClose: () => void;
}

interface TreatmentPlanPopupProps {
  treatmentPlan?: TreatmentPlanPreset;
  medications: Medication[];
  onClose: () => void;
}

interface TreatmentPlanPopupSectionProps {
  title: string;
  description: string;
  fields: any[];
}

// todo extract to separate component, find same name in code
function TreatmentPlanPopupSection({
  title,
  description,
  fields,
}: TreatmentPlanPopupSectionProps) {
  return (
    <div className="edit-patient-details-popup__content__section">
      <div className="edit-patient-details-popup__content__section__head">
        <p className="edit-patient-details-popup__content__section__head__title">
          {title}
        </p>
        <p className="edit-patient-details-popup__content__section__head__description">
          {description}
        </p>
      </div>
      <div className="edit-patient-details-popup__content__section__fields">
        {fields.map((field, index) => (
          <div
            className="edit-patient-details-popup__content__section__fields__field"
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${title}__${description}__${index}`}
          >
            {field}
          </div>
        ))}
      </div>
    </div>
  );
}

function TreatmentPlanPopup({
  treatmentPlan,
  medications,
  onClose,
}: TreatmentPlanPopupProps) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const isCreation = !treatmentPlan;
  const [formValues, setFormField] = useState({
    name: treatmentPlan?.name || "",
    groups: treatmentPlan?.groups || [],
  });
  const [createTreatmentPlan] = useCreateTreatmentPlanMutation();
  const [updateTreatmentPlan] = useUpdateTreatmentPlanMutation();
  const [formErrors, setError] = useState<Record<string, string>>({});

  const handleFormError = (fieldName: string, errorMessage: string) => {
    setError((prevState) => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = () => {
    if (formValues.name.trim().length === 0) {
      handleFormError("name", "Required field");
      return false;
    }

    return true;
  };

  const onSaveChangesHandler = async () => {
    const validationResult = validateForm();
    if (!validationResult) {
      return;
    }

    const payload = {
      ...formValues,
    };

    const response: any = isCreation
      ? await createTreatmentPlan(payload)
      : await updateTreatmentPlan({
          treatmentPlanId: treatmentPlan.id,
          payload,
        });
    if (response.data.errors || response.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to submit treatment plan form",
      });
      return;
    }

    showSuccess({
      title: "Success!",
      description: "treatment plan form has been submitted successfully",
    });
    onClose();
  };

  const selectOptions = medications
    .map((item) => ({
      value: item.id,
      label: `${item.name}${item.strength ? `, ${item.strength}` : ""}${
        item.size ? `, ${item.size}` : ""
      }`,
    }))
    .sort((a, b) => {
      if (a.value === b.value) return 0;
      if (a.value < b.value) return -1;
      return 1;
    });

  return (
    <div className="edit-patient-details-popup">
      <Button
        text="submit"
        className="edit-patient-details-popup__save-button"
        size="small"
        onClick={onSaveChangesHandler}
      />
      <div className="edit-patient-details-popup__content">
        <TreatmentPlanPopupSection
          title="Treatment plan details"
          description=""
          fields={[
            <>
              {/* <p className="edit-patient-details-popup__input-title">
                    Name
                  </p> */}
              <TextInput
                id="treatment-plan-name-input"
                error={formErrors?.name ?? null}
                name="treatmentPlanName"
                label="Name"
                value={formValues?.name}
                onChange={(value) =>
                  setFormField({
                    ...formValues,
                    name: value,
                  })
                }
              />
            </>,
            <>
              <div className="treatment-plan-form__field-row">
                <p className="edit-patient-details-popup__input-title">
                  Groups
                </p>
                <button
                  type="button"
                  onClick={(e) => {
                    setFormField({
                      ...formValues,
                      groups: [...formValues.groups, []],
                    });
                  }}
                  className="treatment-plan-form__button"
                >
                  <AddButtonIcon />
                </button>
              </div>
              {formValues.groups.map((group) => (
                <div className="treatment-plan-form__select-wrap">
                  <Select
                    className="treatment-plan-form__select"
                    isMulti
                    id={v4()}
                    options={selectOptions}
                    onChange={(data) => {
                      const newGroup: string[] = [];

                      if (data) {
                        data.forEach((item) =>
                          newGroup.push(item?.value || "")
                        );
                      }
                      setFormField({
                        ...formValues,
                        groups: formValues.groups.map((it) =>
                          it === group ? newGroup : it
                        ),
                      });
                    }}
                    value={group.map((id) =>
                      selectOptions.find(({ value }) => value === id)
                    )}
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setFormField({
                        ...formValues,
                        groups: formValues.groups.filter(
                          (item) => item !== group
                        ),
                      });
                    }}
                    className="treatment-plan-form__button treatment-plan-form__button--remove"
                  >
                    <RemoveButtonIcon />
                  </button>
                </div>
              ))}
            </>,
          ]}
        />
      </div>
    </div>
  );
}

export function TreatmentPlanPopupGenerator(
  medications: Medication[],
  treatmentPlan?: TreatmentPlanPreset
) {
  return function render({ onClose }: GeneratorProps) {
    return (
      <ActionPopupTemplate
        title={treatmentPlan ? "Edit Treatment Plan" : "Create Treatment Plan"}
        onClose={onClose}
      >
        <TreatmentPlanPopup
          treatmentPlan={treatmentPlan}
          medications={medications}
          onClose={onClose}
        />
      </ActionPopupTemplate>
    );
  };
}
