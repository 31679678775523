import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteCareCoordinator } from "../../../../features/careCoordinators";
import { show } from "../../../../features/errorNotification";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { DeleteConfirmainPopupContext } from "../../Common/DeleteConfirmationPopup";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { CareCoordinator } from "../../../../types/Employee";
import { NotificationLayerContext } from "../../Common/NotificationsLayout";
import { useArchiveEmployeeMutation } from "../../../../features/api/employees";
import { ReactComponent as OptionsIcon } from "../../../../Assets/icons/action-options.svg";
import { ReactComponent as TimeOffIcon } from "../../../../Assets/icons/Vector.svg";
import { ReactComponent as ReportIcon } from "../../../../Assets/icons/icon.svg";
import "./style.css";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/pencil.svg";
import { ReactComponent as TimeOff } from "../../../../Assets/NewIcons/time-off.svg";
import { ReactComponent as ArchiveIcon } from "../../../../Assets/icons/delete.svg";
import { TimeOffHistoryPopup } from "../../../Pages/Administrator/TimeOffHistoryPopup";

interface Props {
  careCoordinatorsList: TableBodyPayload<CareCoordinator>[];
}

export function CareCoordinatorsTableBody({ careCoordinatorsList }: Props) {
  const { showPopup } = useContext(PopupLayerContext);
  const { ask } = useContext(DeleteConfirmainPopupContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [archiveEmployee] = useArchiveEmployeeMutation();

  async function archiveCareCoordinator(id: string) {
    const result: any = await archiveEmployee({
      employeeId: id,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (result.error || result.data?.errors) {
      showError({
        title: "Something went wrong...",
        description: "Unable to perform this action",
      });
      throw new Error("Unable to archive a care coordinator");
    }

    showSuccess({
      title: "Success!",
      description: `Care coordinator has been archived successfully`,
    });
  }

  function askUserForConfirmation(id: string) {
    ask(archiveCareCoordinator.bind(null, id), "archive");
  }

  return (
    <>
      {careCoordinatorsList.map(({ entity: careCoordinator }) => (
        <tr>
          <td>
            {careCoordinator.firstName} {careCoordinator.lastName}
          </td>
          <td>
            {careCoordinator.email && (
              <a
                className="row-text-color"
                href={`mailto:${careCoordinator.email}`}
              >
                {careCoordinator.email}
              </a>
            )}
          </td>
          <td>
            {careCoordinator.phone && (
              <a
                className="row-text-color"
                href={`tel:${careCoordinator.phone}`}
              >
                {careCoordinator.phone}
              </a>
            )}
          </td>
          <td className="patientsTable__actionsList">
            <span
              onClick={async () => {
                showPopup(TimeOffHistoryPopup({ employee: careCoordinator }));
              }}
            >
              <TimeOff />
            </span>
            <span
              onClick={async () => {
                showPopup(EmployeePopupGenerator(careCoordinator));
              }}
            >
              <EditIcon />
            </span>
            <span
              onClick={() => {
                askUserForConfirmation(careCoordinator.id);
              }}
            >
              <ArchiveIcon />
            </span>
            {/* <button
              type="button"
              title="Get report"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(
                  ProvidersReportPickerPopupGenerator({
                    providerId: provider.id,
                  })
                );
              }}
            >
              <div className="patientsTable__actionButton--schedule" />
            </button>
            <button
              type="button"
              title="Edit"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(EmployeePopupGenerator(provider));
              }}
            >
              <div className="patientsTable__actionButton--edit" />
            </button>
            {provider.archived ? null : (
              <button
                type="button"
                title="Archive"
                className="patientsTable__actionButtonWithIcon"
                onClick={() => {
                  askUserForConfirmation(provider.id);
                }}
              >
                <div className="patientsTable__actionButton--delete" />
              </button>
            )} */}
          </td>
          {/* <td className="patientsTable__actionsList">
            <button
              type="button"
              title="Edit"
              className="patientsTable__actionButtonWithIcon"
              onClick={async () => {
                showPopup(EmployeePopupGenerator(careCoordinator));
              }}
            >
              <div className="patientsTable__actionButton--edit" />
            </button>
            {careCoordinator.archived ? null : (
              <button
                type="button"
                title="Archive"
                className="patientsTable__actionButtonWithIcon"
                onClick={() => {
                  askUserForConfirmation(careCoordinator.id);
                }}
              >
                <div className="patientsTable__actionButton--delete" />
              </button>
            )}
          </td> */}
        </tr>
      ))}
    </>
  );
}
