import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ShippingInfo as ShippingInfoData } from "../../../../types/Entities/Patient";
import { SubmitButton } from "../../Common/Button";
import "./style.scss";

interface Props {
  onSubmit: (data: ShippingInfoData) => Promise<void>;
}

export function ShippingInfo({ onSubmit }: Props) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  async function submit(shippingInfo: ShippingInfoData) {
    setLoading(true);

    try {
      await onSubmit(shippingInfo);
    } catch {
      setLoading(false);
    }
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <form className="shipping-info" onSubmit={handleSubmit(submit)}>
      {/* <div className="shipping-info__input-wrapper">
        <div className="shipping-info__input-wrapper-inner">
          <label className="shipping-info__label" htmlFor="firstName">
            First Name
          </label>
          <input
            id="firstName"
            className="text-input__field input-font"
            type="text"
            placeholder="First name"
            {...register("firstName", {
              required: true,
              minLength: 1,
              maxLength: 80,
            })}
          />
          <span className="shipping-info__input-error">
            {errors?.firstName?.type === "required" && "Required field"}
          </span>
        </div>
        <div className="shipping-info__input-wrapper-inner">
          <label className="shipping-info__label" htmlFor="lastName">
            Last Name
          </label>
          <input
            id="lastName"
            className="text-input__field input-font"
            type="text"
            placeholder="Last name"
            {...register("lastName", { required: true, maxLength: 100 })}
          />
          <span className="shipping-info__input-error">
            {errors?.lastName?.type === "required" && "Required field"}
          </span>
        </div>
      </div> */}
      <label className="shipping-info__label" htmlFor="addressLine1">
        Address Line 1
      </label>
      <input
        id="addressLine1"
        className="text-input__field input-font"
        type="text"
        placeholder="Address Line 1"
        {...register("addressLine1", { required: true })}
      />
      <span className="shipping-info__input-error">
        {errors?.addressLine1?.type === "required" && "Required field"}
      </span>
      <label className="shipping-info__label" htmlFor="addressLine2">
        Address Line 2
      </label>
      <input
        id="addressLine2"
        className="text-input__field input-font"
        type="text"
        placeholder="Address Line 2"
        {...register("addressLine2", { required: false })}
      />
      <div className="shipping-info__input-wrapper">
        <div className="shipping-info__input-wrapper-inner">
          <label className="shipping-info__label" htmlFor="city">
            City
          </label>
          <input
            id="city"
            className="text-input__field input-font shipping-info__input--small"
            type="text"
            placeholder="City"
            {...register("city", {
              required: true,
              pattern: /^(?=[A-Za-z .-]*[A-Za-z])[A-Za-z .-]+$/,
            })}
          />
          <span className="shipping-info__input-error">
            {errors?.city?.type === "required" && "Required field"}
            {errors?.city?.type === "pattern" && "Invalid city"}
          </span>
        </div>
        <div className="shipping-info__input-wrapper-inner">
          <label className="shipping-info__label" htmlFor="state">
            State
          </label>
          <input
            id="state"
            className="text-input__field input-font shipping-info__input--small"
            type="text"
            placeholder="State"
            {...register("state", {
              required: true,
              pattern: /^(?=[A-Za-z .-]*[A-Za-z])[A-Za-z .-]+$/,
            })}
          />
          <span className="shipping-info__input-error">
            {errors?.state?.type === "required" && "Required field"}
            {errors?.state?.type === "pattern" && "Invalid state"}
          </span>
        </div>
      </div>
      <label className="shipping-info__label" htmlFor="zipCode">
        Zip Code
      </label>
      <input
        id="zipCode"
        className="text-input__field input-font"
        type="text"
        placeholder="Zip Code"
        {...register("zipCode", { required: true, pattern: /^(?:\d|-)+$/i })}
      />
      <span className="shipping-info__input-error">
        {errors?.zipCode?.type === "required" && "Required field"}
        {errors?.zipCode?.type === "pattern" &&
          "Zip code should contain only digits"}
      </span>

      <SubmitButton
        status={loading ? "loading" : undefined}
        text="submit"
        className="shipping-info__btn"
      />
    </form>
  );
}
