import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import {
  InsuranceInfo,
  MedicalBackground,
  MedicalBackgroundItem,
  Patient,
  ShippingInfo,
  SkinSurveyAnswer,
} from "../../types/Entities/Patient";
import { CareTeamRecord } from "../../types/CareTeam";
import { USER_ROLES } from "../../types/Main";
import { PatientNote } from "../../types/PatientNote";
import { UpdateItem } from "../../services/acneaway-api/entities/patients/update-patient-info";

const patientFieldsQuery = `{
  patientId
  accountId
  fullName
  state
  dateOfBirth
  parentInfo {
    name
    email
    phone
  }
  phone
  email
  insurance {
    groupNumber
    insuranceName
    memberId
    policyHolderName
    rxBinNumber
  }
  shippingInfo {
    addressLine1
    addressLine2
    city
    state
    zipCode
  }
  medicalBackground {
    skinSurvey {
      id
      type
      value
      questionType
    }
    height
    weight
    sex
  }
  scheduleLink
  flags {
    isNotPregnantCapability
    isStopBloodWorkTask
    isRemovedGovernmentIssuedId
    isRemovedInsuranceInfo
    isRemovedInitialVisit
    isRemovedMedicalBackground
    isRemovedInitiateMembership
    isRemovedScheduleCallWithProvider
    isRemovedProviderResponseToFollowUp
    isRemovedNewFollowUpVisit
    isRemovedInitiateAccutane
    isRemovedRenewMembership
  }
}`;

const careTeamRecordFieldsQuery = `{
  id
  patientId
  employeeId
  role
}`;

const patientNoteFieldsQuery = `{
  noteId
  patientId
  creatorId
  creatorName
  timestamp
  text
}`;

export const patientsApiSlice = createApi({
  reducerPath: "api/patients",
  tagTypes: ["patient", "care-team", "note"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.PATIENTS_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPatientById: builder.query<Patient, string>({
      query: (patientId: string) => ({
        url: "",
        params: {
          query: `query { getPatient(patientId: "${patientId}") ${patientFieldsQuery} }`,
        },
      }),
      providesTags: (response: any) =>
        response ? [{ type: "patient", id: response.patientId }] : [],
      transformResponse: (response: any) => response.data.getPatient,
    }),

    getMyProfile: builder.query<
      { fullName: string; patientId: string }[],
      null
    >({
      query: () => ({
        url: "",
        params: {
          query: `query { getMyProfile { patientId fullName archived } }`,
        },
      }),
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.getMyProfile;
      },
    }),

    getPatientsByAccountId: builder.query<Patient[], string>({
      query: (accountId) => ({
        url: "",
        params: {
          query: `query { getPatientsByAccountId(accountId: "${accountId}") ${patientFieldsQuery} }`,
        },
      }),
      ttransformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.getPatientsByAccountId;
      },
    }),

    getAllPatients: builder.query<Patient[], null>({
      query: () => ({
        url: "",
        params: {
          query: `query { getPatients ${patientFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) => response.data.getPatients,
    }),

    getCareTeam: builder.query<CareTeamRecord[], string>({
      query: (patientId) => ({
        url: "",
        params: {
          query: `query { getCareTeam(patientId: "${patientId}") ${careTeamRecordFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) => response.data.getCareTeam,
      providesTags: (response) =>
        response?.length
          ? response.map(({ id }) => ({ type: "care-team", id }))
          : [{ type: "care-team" }],
    }),

    getAssignedPatients: builder.query<CareTeamRecord[], string>({
      query: (employeeId) => ({
        url: "",
        params: {
          query: `query { getAssignedPatients(employeeId: "${employeeId}") ${careTeamRecordFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) => response.data.getAssignedPatients,
    }),

    getPatientNotes: builder.query<PatientNote[], string>({
      query: (patientId) => ({
        url: "",
        params: {
          query: `query { getNotes(patientId: "${patientId}") ${patientNoteFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) => response.data.getNotes,
      providesTags: (response) =>
        response?.length
          ? response.map(({ noteId }) => ({ type: "note", id: noteId }))
          : [{ type: "note" }],
    }),

    updateInsuranceInfo: builder.mutation<
      {
        patientId: string;
        insuranceInfo: InsuranceInfo;
      },
      {
        patientId: string;
        insuranceInfo: InsuranceInfo;
      }
    >({
      query: ({ patientId, insuranceInfo }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($insuranceInfo: PatientInsuranceInfoInput!, $patientId: String!) { updatePatientInsuranceInfo(insuranceInfo: $insuranceInfo, patientId: $patientId) { patientId } }",
          variables: JSON.stringify({
            patientId,
            insuranceInfo,
          }),
        }),
      }),
      invalidatesTags: (result: any) =>
        result ? [{ type: "patient", id: result.patientId }] : [],
    }),

    updateShippingInfo: builder.mutation<
      {
        patientId: string;
      },
      {
        patientId: string;
        shippingInfo: ShippingInfo;
      }
    >({
      query: ({ patientId, shippingInfo }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($shippingInfo: ShippingInfoInput!, $patientId: String!) { updatePatientShippingInfo(shippingInfo: $shippingInfo, patientId: $patientId) { patientId } }",
          variables: JSON.stringify({
            patientId,
            shippingInfo,
          }),
        }),
      }),
      invalidatesTags: (result: any) =>
        result ? [{ type: "patient", id: result.patientId }] : [],
    }),

    addNewPatientToAccount: builder.mutation<
      null,
      {
        patient: Partial<Patient>;
      }
    >({
      query: ({ patient }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($patient: PatientInput!) { createPatientInAccount(patient: $patient) { nothing } }",
          variables: JSON.stringify({
            patient,
          }),
        }),
      }),
    }),

    updateCognitoUser: builder.mutation<{
      userId: string;
      userName: string;
      newEmail: string;
    }>({
      query: ({ userId, userName, newEmail }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query: `
            mutation ($userId: String!, $userName: String!, $newEmail: String!) {
              updateCognitoUser(userId: $userId,userName: $userName, newEmail: $newEmail) {
                success
                error
              }
            }
          `,
          variables: {
            userId,
            userName,
            newEmail,
          },
        }),
      }),
      invalidatesTags: (result) => [{ type: "cognito" }],
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.updateCognitoUser;
      },
    }),

    submitMedicalBackground: builder.mutation<
      null,
      {
        payload: MedicalBackground;
        patientId: string;
      }
    >({
      query: ({ payload, patientId }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($patientId: String!, $payload: SubmitMedicalBackgroundInput!) { submitMedicalBackground(patientId: $patientId, payload: $payload) { nothing } }",
          variables: JSON.stringify({
            patientId,
            payload,
          }),
        }),
      }),
      invalidatesTags: (result: any) => (result ? [{ type: "patient" }] : []),
    }),

    archivePatient: builder.mutation<{ patientId: string }, string>({
      query: (patientId) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($patientId: String!) { archivePatient(patientId: $patientId) { patientId } }",
          variables: JSON.stringify({
            patientId,
          }),
        }),
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "patient", id: result.patientId }] : [],
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.archivePatient;
      },
    }),

    setFlag: builder.mutation<
      { patientId: string },
      {
        patientId: string;
        flag: string;
        state: boolean;
      }
    >({
      query: ({ patientId, flag, state }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($patientId: String!, $flag: String!, $state: Boolean!) { setFlag(patientId: $patientId, flag: $flag, state: $state) { patientId } }",
          variables: JSON.stringify({
            patientId,
            flag,
            state,
          }),
        }),
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "patient", id: result.patientId }] : [],
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.setFlag;
      },
    }),
    updateCareTeam: builder.mutation<
      CareTeamRecord[],
      {
        patientId: string;
        employees: string[];
        role: USER_ROLES.PROVIDER | USER_ROLES.CARE_COORDINATOR;
      }
    >({
      query: ({ patientId, employees, role }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($patientId: String!, $employees: [String!]!, $role: String!) { updateCareTeam(patientId: $patientId, employees: $employees, role: $role) { nothing } }",
          variables: JSON.stringify({
            patientId,
            employees,
            role,
          }),
        }),
      }),
      invalidatesTags: (result) => [{ type: "care-team" }],
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.updateCareTeam;
      },
    }),
    addPatientNote: builder.mutation<
      PatientNote,
      {
        patientId: string;
        creatorId: string;
        text: string;
      }
    >({
      query: ({ patientId, creatorId, text }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($patientId: String!, $creatorId: String!, $text: String!) { addNote(patientId: $patientId, creatorId: $creatorId, text: $text) { noteId } }",
          variables: JSON.stringify({
            patientId,
            creatorId,
            text,
          }),
        }),
      }),
      transformResponse: (response: any) => response.data.addNote,
      invalidatesTags: (response) => (response ? [{ type: "note" }] : []),
    }),
    updatePatientInfo: builder.mutation<
      null,
      { patientId: string; items: UpdateItem[] }
    >({
      query: ({ patientId, items }) => {
        const queryParams = items.map(
          ({ key, value, queryName, queryType }) => ({
            variableDefinition: `$${key}: ${queryType}`,
            query: `${queryName}(patientId: $patientId, ${key}: $${key}) { patientId }`,
            variable: {
              [key]: value,
            },
          })
        );
        return {
          url: "",
          method: "POST",
          body: JSON.stringify({
            query: `mutation ($patientId: String!, ${queryParams
              .map(({ variableDefinition }) => variableDefinition)
              .join(", ")}) {
              ${queryParams.map(({ query }) => query).join(" ")}
            }`,
            variables: JSON.stringify({
              patientId,
              ...queryParams.reduce(
                (acc, { variable }) => ({ ...acc, ...variable }),
                {}
              ),
            }),
          }),
        };
      },
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data;
      },
      invalidatesTags: (response) => (response ? [{ type: "patient" }] : []),
    }),
    updateSkinSurvey: builder.mutation<
      Patient,
      {
        patientId: string;
        skinSurvey: MedicalBackgroundItem[];
      }
    >({
      query: ({ patientId, skinSurvey }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($patientId: String!, $skinSurvey: [PatientMedicalBackgroundSkinSurveyInput!]!) { updatePatientSkinSurvey(patientId: $patientId, skinSurvey: $skinSurvey) { patientId } }",
          variables: JSON.stringify({
            patientId,
            skinSurvey,
          }),
        }),
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "patient", id: result.patientId }] : [],
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.updatePatientSkinSurvey;
      },
    }),
  }),
});

export const {
  useGetPatientByIdQuery,
  useGetMyProfileQuery,
  useGetAllPatientsQuery,
  useGetPatientsByAccountIdQuery,
  useUpdateInsuranceInfoMutation,
  useUpdateShippingInfoMutation,
  useAddNewPatientToAccountMutation,
  useGetCareTeamQuery,
  useGetAssignedPatientsQuery,
  useSubmitMedicalBackgroundMutation,
  useArchivePatientMutation,
  useSetFlagMutation,
  useUpdateCareTeamMutation,
  useGetPatientNotesQuery,
  useAddPatientNoteMutation,
  useUpdatePatientInfoMutation,
  useUpdateSkinSurveyMutation,
  useUpdateCognitoUserMutation,
} = patientsApiSlice;
