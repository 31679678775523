import { useSelector } from "react-redux";
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { ImageBlob } from "../../../../types/Base/ImageBlob";
import { getPatientStore } from "../../../../features/patientView";
import generateHash from "../../../../utils/generate-hash";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { Button } from "../../Common/Button";
import { ImageUploadTile } from "../ImageUploadTile";
import { Checkbox } from "../../Common/Checkbox";
import { Input } from "../../Common/Input";
import { Props } from "../FormsOfPregnancyPopup/types";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { StyledSelect } from "../../Common/StyledSelect";
import "./style.scss";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { DocumentImageFileProps } from "../../../../services/acneaway-api/entities/providers/upload-document";
import { ReactComponent as DocumentIcon } from "../../../../Assets/NewIcons/document.svg";
import { getSessionState } from "../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import {
  useUploadDocumentMutation,
  useGetPresignedUrlMutation,
} from "../../../../features/api/accutane";
import { InfoBlock } from "../../Common/InfoBlock";
import { ENV_CONFIG } from "../../../../config";

interface DocumentTypeForm {
  image: Blob | ImageBlob | null;
  eSignature: string;
  confirmTest: boolean;
  documentType: {
    label: string;
    value: string;
    text: string;
    showWarnBlock: boolean;
    isPregnancyTest: boolean;
  } | null;
  documentTitle: string | null;
}

enum FormIds {
  eSignature = "eSignature",
  confirmTest = "confirmTest",
  image = "image",
  documentType = "documentType",
  documentTitle = "documentTitle",
}

const documentTypeOptions = [
  {
    label: "Document type",
    options: [
      {
        label: "Pregnancy urine test",
        value: "urinePregnancyTest",
        text: "2. Upload an image of your pregnancy test",
        showWarnBlock: true,
        isPregnancyTest: true,
      },
      {
        label: "Other",
        value: "other",
        text: "2. Upload your document here",
        showWarnBlock: false,
      },
    ],
  },
];

const taskSelectGroup = (data: any) => (
  <div>
    <span>{data.label.toUpperCase()}</span>
  </div>
);

function UploadImagePreview() {
  return (
    <div className="upload-documents-popup__uploader--preview">
      <DocumentIcon />
      <p className="upload-documents-popup__uploader--preview-description">
        Document uploaded
      </p>
    </div>
  );
}

function DiscardButton({ discardImage }: { discardImage?: () => void }) {
  const handleDiscardImage = () => {
    if (discardImage) {
      discardImage();
    }
  };

  return (
    <p
      onClick={handleDiscardImage}
      className="upload-documents-popup__uploader__discard-button"
    >
      Replace
    </p>
  );
}

function UploadDocumentsPopup({ onClose }: { onClose: () => void }) {
  const { showSuccess } = useContext(NotificationLayerContext);
  const {
    userRole,
    userId: employeeId,
    activePatientId,
  } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [uploadDocument] = useUploadDocumentMutation();
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [otherTypeSelected, setSelected] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [uploadDocumentForm, setFormField] = useState<DocumentTypeForm>({
    [FormIds.eSignature]: "",
    [FormIds.confirmTest]: false,
    [FormIds.image]: null,
    [FormIds.documentType]: null,
    [FormIds.documentTitle]: null,
  });

  if (!patientQuery.data) {
    return null;
  }
  useEffect(() => {
    const validateForm = () => {
      const { documentType, eSignature, confirmTest, image, documentTitle } =
        uploadDocumentForm;

      if (documentType?.value === "urinePregnancyTest") {
        setIsDisabled(!(eSignature && confirmTest && image && documentTitle));
      } else if (documentType?.value === "other") {
        setIsDisabled(!(eSignature && image && documentTitle));
      } else {
        // Handle other document types if needed
        setIsDisabled(true);
      }
    };

    validateForm();
  }, [uploadDocumentForm]);
  // todo extract to separate function
  const generateImagesFilename = () => {
    if (!activePatientId)
      throw new Error("Cannot generate file name without patient ID");
    const extension = `${uploadDocumentForm.image?.type.split("/").pop()}`;

    return `${activePatientId}.${generateHash(10)}.${extension}`;
  };

  const onSubmit = async () => {
    const filename = generateImagesFilename();
    if (!patientQuery.data) return;
    const uploadFile = await new Promise<DocumentImageFileProps>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        resolve({
          contentType: uploadDocumentForm.image?.type ?? "",
          filename,
        });
      };

      if (uploadDocumentForm.image) {
        reader.readAsDataURL(uploadDocumentForm.image);
      }
    });

    if (userRole && employeeId) {
      const response = await getPresignedUrl({
        patientId: patientQuery.data.patientId,
        document: {
          ...uploadFile,
        },
      });

      if (response?.data && response.data?.preSignedUrl) {
        const data = await axios.put(
          response.data.preSignedUrl,
          uploadDocumentForm.image,
          {
            headers: {
              "Content-Type": uploadDocumentForm.image?.type as string,
            },
          }
        );
        if (data.status === 200) {
          const result = await uploadDocument({
            patientId: patientQuery.data.patientId,
            document: {
              url: `${ENV_CONFIG.AMAZON_S3_PATIENT_DOCUMENT_DOMAIN}/${filename}`,
              comment: "",
              assignedTo: null,
              title: uploadDocumentForm.documentTitle,
              type: uploadDocumentForm?.documentType?.value ?? "",
              assignedBy: {
                id: employeeId,
                role: userRole,
              },
              uploadedBy: {
                id: patientQuery.data.patientId,
                name: patientQuery.data.fullName,
              },
              taskName: "",
            },
          });
          if (result) {
            showSuccess({
              title: "Success",
              description: "Document successfully uploaded",
            });
          }
        }
      }
    }
    onClose();
  };

  const togglePregnancyConfirmation = () =>
    setFormField({
      ...uploadDocumentForm,
      confirmTest: !uploadDocumentForm.confirmTest,
    });

  const handleDocumentTypeChange = (documentType: {
    label: string;
    value: string;
    text: string;
    showWarnBlock: boolean;
    isPregnancyTest: boolean;
  }) => {
    setFormField({ ...uploadDocumentForm, documentType });
    if (documentType.value === "other") {
      setSelected(true);
    } else {
      setSelected(false);
    }
  };

  const setImageHandler = (image: Blob | ImageBlob | null) => {
    setFormField({ ...uploadDocumentForm, image });
  };
  const setEsignatureHandler = (eSignature: string) =>
    setFormField({ ...uploadDocumentForm, eSignature });

  const setDocumentTitle = (documentTitle: string) =>
    setFormField({ ...uploadDocumentForm, documentTitle });

  const customPreview =
    uploadDocumentForm.image?.type !== "application/pdf" ? null : (
      <UploadImagePreview />
    );
  const customDiscardButton =
    uploadDocumentForm.image?.type !== "application/pdf" ? null : (
      <DiscardButton />
    );

  return (
    <div className="upload-documents-popup">
      <Button
        text="Upload"
        size="big"
        onClick={onSubmit}
        disabled={isDisabled}
        className="upload-documents-popup__extra-button"
      />
      <div className="upload-documents-popup-form">
        <div className="upload-documents-popup-form__item">
          <div className="upload-documents-popup-form__option-wrapper">
            <p className="upload-documents-popup-form__text">
              1. Please select a document
            </p>
          </div>
          <StyledSelect
            id="documentType"
            name="documentType"
            options={documentTypeOptions}
            formatGroupLabel={taskSelectGroup}
            onChange={handleDocumentTypeChange}
          />
        </div>
        <div className="upload-documents-popup-form__item">
          <div className="upload-documents-popup-form__option-wrapper">
            <p className="upload-documents-popup-form__text">
              {uploadDocumentForm.documentType && (
                <>
                  {uploadDocumentForm.documentType.text}
                  {uploadDocumentForm.documentType.showWarnBlock && (
                    <InfoBlock
                      view="warn"
                      text="In the picture be sure to include:"
                      className="pregnancy-test-popup__info-block"
                      isPregnancyTest={
                        uploadDocumentForm?.documentType?.isPregnancyTest
                      }
                    />
                  )}
                </>
              )}
            </p>
          </div>
          <div className="upload-documents-popup__uploader">
            <ImageUploadTile
              side="center"
              setImage={setImageHandler}
              customPreview={customPreview}
              customDiscardButton={customDiscardButton}
              placeholderClassName="upload-documents-popup__uploader-placeholder"
            />
          </div>
          {uploadDocumentForm &&
            uploadDocumentForm.documentType?.isPregnancyTest && (
              <Checkbox
                checked={uploadDocumentForm.confirmTest}
                onChange={togglePregnancyConfirmation}
                disabled={!uploadDocumentForm.image}
              >
                <span>
                  I confirm that this is my own pregnancy test and it was taken
                  today
                </span>
              </Checkbox>
            )}
        </div>
        {uploadDocumentForm.documentType && (
          <div className="upload-documents-popup-form__item">
            <div className="upload-documents-popup-form__option-wrapper">
              <p className="upload-documents-popup-form__text">
                3. Add a title for your document
              </p>
            </div>
            <Input
              id="fullName"
              name="fullName"
              onChange={setDocumentTitle}
              placeholder="e.g. Bloodwork_2022.04.08"
            />
          </div>
        )}
        <div className="upload-documents-popup-form__item">
          <div className="upload-documents-popup-form__option-wrapper">
            <p className="upload-documents-popup-form__text">
              {uploadDocumentForm.documentType ? "4. " : "3. "} Type your name
              to provide an e-signature
            </p>
          </div>
          <Input
            id="fullName"
            name="fullName"
            onChange={setEsignatureHandler}
            placeholder="Enter your full name to sign the provided result"
          />
        </div>
      </div>
      <div className="upload-documents-popup__submit-button">
        <Button
          text="Upload"
          size="big"
          onClick={onSubmit}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

function UploadDocumentsPopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <ActionPopupTemplate title="Upload a document" onClose={onClose}>
        <UploadDocumentsPopup onClose={onClose} />
      </ActionPopupTemplate>
    );
  };
}

export default UploadDocumentsPopupGenerator;
