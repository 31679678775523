import React from "react";
import { ReactComponent as DoctorInGray } from "../../../../Assets/NewIcons/doctor-in-gray.svg";
import "./style.scss";

export function ContactProviderCard() {
  return (
    <div className="contact-provider-card">
      <div className="contact-provider-card__info">
        <p className="subheading-font subheading-font--margin subheading-font--color">
          How do follow-ups work?
        </p>
        <p className="paragraph-font paragraph-font--color paragraph-font--margin">
          Update your provider by answering a few key questions and submitting
          new photos of your skin. Your provider will review your submission and
          outline next steps.
        </p>
      </div>
      <div className="contact-provider-card__img">
        <DoctorInGray />
      </div>
    </div>
  );
}
