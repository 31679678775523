import React, { useMemo, useState } from "react";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { RadioButtonsList } from "../../../../NewComponents/Common/RadioButtonsList";

const ANSWERS = {
  NO: "Not at all",
  SEVERAL_DAYS: "Several days",
  HALF_THE_DAYS: "More than half the days",
  EVERY_DAY: "Nearly every day",
};

function surveyToComponent(data: SurveyData) {
  return {
    answer1: data.phqAnswer1,
    answer2: data.phqAnswer2,
  };
}

function componentToSurvey(answer1: number, answer2: number) {
  return {
    phqAnswer1: answer1,
    phqAnswer2: answer2,
  };
}

export function PHQSurveyQuestion({ data, onDone }: SurveyComponentProps) {
  const [answer1, setAnswer1] = useState(surveyToComponent(data).answer1);
  const [answer2, setAnswer2] = useState(surveyToComponent(data).answer2);

  const list = [
    {
      value: 0,
      label: ANSWERS.NO,
    },
    {
      value: 1,
      label: ANSWERS.SEVERAL_DAYS,
    },
    {
      value: 2,
      label: ANSWERS.HALF_THE_DAYS,
    },
    {
      value: 3,
      label: ANSWERS.EVERY_DAY,
    },
  ];

  function submit() {
    if (isEmpty(answer1) || isEmpty(answer2)) return;

    onDone(componentToSurvey(answer1, answer2));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        Over the past 2 weeks, how often have you been bothered by any of the
        following problems?
      </p>
      <p className="survey-question__subtitle paragraph-font--color">
        a. Little interest or pleasure in doing things
      </p>
      <RadioButtonsList
        list={list}
        currentValue={answer1}
        onChange={(value) => setAnswer1(value)}
      />
      <p className="survey-question__subtitle paragraph-font--color">
        b. Feeling down, depressed, or hopeless
      </p>
      <RadioButtonsList
        list={list}
        currentValue={answer2}
        onChange={(value) => setAnswer2(value)}
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={isEmpty(answer1) || isEmpty(answer2)}
      />
    </div>
  );
}
