import React from "react";
import { ReactComponent as SearchIcon } from "../../../../Assets/NewIcons/search.svg";
import "./style.scss";

function EmptyTaskView() {
  return (
    <div className="empty-task-view">
      <div className="empty-task-view__first-icon-wrapper">
        <div className="empty-task-view__first-icon-wrapper__second-icon-wrapper">
          <SearchIcon />
        </div>
      </div>
      <p className="empty-task-view__task-free">No tasks</p>
      <p className="empty-task-view__congratulation">
        Great job! You have completed all tasks for this patient.
      </p>
    </div>
  );
}

export default EmptyTaskView;
