import React, { useState } from "react";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { Stepper } from "../../../../NewComponents/Common/Stepper";
import { ProductsPicker } from "../../../../NewComponents/Patient/ProductsList";
import { LineItem } from "../../../../../types/Base/Products";

export function ProductsSurveyPage({
  onDone,
  data,
  stepper: { max, current },
}: SurveyComponentProps) {
  const [lineItems, setLineItems] = useState<LineItem[] | null>(
    data.products || null
  );

  return (
    <div className="survey-question initiate-checkout-page__page">
      <p className="survey-question__title">Place an order</p>
      <Stepper max={max} current={current} />
      <p className="survey-question__descr paragraph-font--color">
        {/* You can order your non-prescription treatments right from the Honeydew.
        Your prescription products will be sent to a pharmacy after you sign up. */}
      </p>

      <ProductsPicker
        onChange={(value) => setLineItems(value)}
        initialQuantityData={lineItems || undefined}
        className="initiate-checkout-page__products"
        disableSuggestions={false}
      />

      <div className="initiate-checkout-page__actions">
        <Button
          text="order"
          onClick={() => {
            onDone({
              products: lineItems,
            });
          }}
          className="initiate-checkout-page__submit-button"
          disabled={
            !lineItems || lineItems?.every(({ quantity }) => quantity < 0)
          }
        />
      </div>
    </div>
  );
}
