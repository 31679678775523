/* eslint-disable react/prop-types */
import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { ReactComponent as AddButtonIcon } from "../../../../Assets/icons/PlusIcon.svg";
import { ReactComponent as RemoveButtonIcon } from "../../../../Assets/icons/close-button-dark.svg";
import { TreatmentTableRow } from "./treatmentTableRow";
import "./style.scss";
import { TreatmentPlanTableDataItem } from "./types";
import { logError } from "../../../../shared/logger";
import { COMMON_TREATMENT_PLANS_ORDER } from "../../../../data/common-treatment-plans";
import { alphabetical } from "../../../../utils/sortings";
import {
  useGetAllMedicationsQuery,
  useGetAllTreatmentPlansQuery,
} from "../../../../features/api/medications";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { TreatmentPlan } from "../../../../types/Entities/TreatmentPlan";
import { TreatmentPlanMedication } from "../../../../types/FollowUp";
import { ShowTreatmentPlanMobileDevice } from "./ShowTreatmentPlanMobileDevice";
import TreatmentForm from "../TreatmentForm";

export type TreatmentPlanTableMedication = {
  id: string;
  instructions: string[];
  specialInstructions: string;
  refillCount: number;
  refillExpiration: number | null;
};

interface Props {
  tableData: TreatmentPlanMedication[] | null;
  treatmentPlanPreset: string | null;
  onChange: (data: TreatmentPlanMedication[]) => void;
  onPresetChange: (preset: string) => void;
}

function mapTreatmentPlansToSelect(treatmentPlans: TreatmentPlan[]) {
  return treatmentPlans.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
}
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "auto",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    background: "white",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export function TreatmentPlanTable({
  tableData,
  treatmentPlanPreset,
  date,
  setDate,
  onChange,
  onPresetChange,
}: Props) {
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const treatmentPlansQuery = useGetAllTreatmentPlansQuery(null);
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [tableRows, setTableRows] = useState<TreatmentPlanTableDataItem[]>(
    tableData
      ? tableData.map((tableRow) => ({
          medicineKey: tableRow.medicineKey,
          itemsList: null,
          rowChecked: false,
          instructions: tableRow.instructions,
          specialInstructions: tableRow.specialInstructions || null,
          refillsCount: tableRow.refillsCount || 0,
          refillExpirationDate: tableRow.refillExpirationDate || null,
        }))
      : []
  );

  const [tablesRowData, setTablesRowData] = useState(tableRows);

  const getPrettifiedTableData = (): TreatmentPlanMedication[] =>
    tableRows.map((row) => ({
      medicineKey: row.medicineKey,
      instructions: row.instructions,
      specialInstructions: row.specialInstructions || "",
      refillsCount: row.refillsCount || null,
      refillExpirationDate: row.refillExpirationDate,
      isAccutane: row?.isAccutane,
    }));

  useEffect(() => {
    onChange(getPrettifiedTableData());
  }, [tableRows]);

  const handlePrescriptionPlanSelect = (value: string) => {
    if (!treatmentPlansQuery.data || !medicationsQuery.data) return;
    const prescriptiponPlan = treatmentPlansQuery.data.find(
      ({ id }) => id === value
    )?.groups;
    if (prescriptiponPlan) {
      logError(`Prescription plan with ID ${prescriptiponPlan} is found`);
    } else if (!prescriptiponPlan) {
      logError(`Prescription plan with ID ${value} is not found`);
      return;
    }

    const tableRowsData = prescriptiponPlan.reduce(
      (acc: TreatmentPlanTableDataItem[], group) => {
        const medicineKey = group[0];
        const medicine = medicationsQuery.data?.find(
          ({ id }) => id === medicineKey
        );
        if (!medicine) {
          logError(`Medicine with ID ${medicineKey} is not found`);
          return acc;
        }

        let expirationDate = null;

        if (medicine.refillExpiration) {
          const currentDate = new Date();
          currentDate.setDate(
            currentDate.getDate() + medicine.refillExpiration
          );
          expirationDate = currentDate.getTime();
        }

        acc.push({
          medicineKey,
          itemsList: group,
          rowChecked: false,
          instructions: medicine.instructions,
          specialInstructions: medicine.specialInstructions || "",
          refillsCount: Number(medicine.refillCount),
          refillExpirationDate: expirationDate,
          isAccutane: medicine?.isAccutane,
        });
        return acc;
      },
      []
    );
    setTableRows(tableRowsData);
    const treatmentPlan = treatmentPlansQuery.data.find(
      ({ id }) => id === value
    );
    if (!treatmentPlan) {
      logError(`Treatment plan with ID ${value}`);
      return;
    }

    onPresetChange(treatmentPlan.name);
    setSelectedTreatmentPlan(treatmentPlan?.name);
  };

  const renderTableBody = () => (
    <tbody>
      {tableRows.map((tableRowData, index) => (
        <TreatmentTableRow
          data={tableRowData}
          onChange={(data) => {
            const newState = Array.from(tableRows);
            newState[index] = { ...tableRowData, ...data };
            setTableRows(newState);
          }}
          setTableRows={setTableRows}
          tableRows={tableRows}
        />
      ))}
    </tbody>
  );

  const handleTableRowAdding = () => {
    const newState = JSON.parse(JSON.stringify(tableRows));
    newState.push({
      medicineKey: null,
      itemsList: null,
      rowChecked: false,
      instructions: [],
      specialInstructions: "",
      refillsCount: 0,
      refillExpirationDate: null,
    });

    setTableRows(newState);
  };

  function sortTreatmentPresets(
    treatmentsPreset: {
      value: string;
      label: string;
    }[]
  ) {
    const start = COMMON_TREATMENT_PLANS_ORDER.map((treatment) =>
      treatmentsPreset.find(({ value }) => value === treatment)
    ).filter((value) => !!value) as {
      value: string;
      label: string;
    }[];
    const end = treatmentsPreset.filter(
      ({ value }) => !COMMON_TREATMENT_PLANS_ORDER.includes(value)
    );

    return [...start, ...end.sort((a, b) => alphabetical(a.label, b.label))];
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleTableRowChange = (index, data) => {
    const newState = Array.from(tableRows);
    newState[index] = { ...tableRows[index], ...data };
    setTableRows(newState);
  };

  return (
    <>
      <div>
        <span className="treatment-plan-table__heading-text">
          Update treatment plan
        </span>
      </div>
      <div className="treatment-plan-table__heading">
        <div
          className="treatment-plan-table__heading-part treatment-plan-table__selectable-container"
          style={{ alignItems: "baseline" }}
        >
          <span className="treatment-plan-table__heading-text treatment-plan-table-selectable-text">
            Select Treatment plan
          </span>
          <div className="treatment-plan-table__presets-select">
            {treatmentPlansQuery.data ? (
              <Select
                className="treatment-plan-table__select-field"
                options={sortTreatmentPresets(
                  mapTreatmentPlansToSelect(treatmentPlansQuery.data)
                )}
                onChange={(selectedItem) =>
                  selectedItem &&
                  typeof selectedItem !== "string" &&
                  handlePrescriptionPlanSelect(selectedItem.value)
                }
                defaultValue={
                  treatmentPlanPreset
                    ? mapTreatmentPlansToSelect(treatmentPlansQuery.data).find(
                        (option) => option.label === treatmentPlanPreset
                      )
                    : ""
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
      </div>
      <div className="treatment-plan-table">
        <table className="treatment-plan-table__table">
          <thead>
            <tr>
              <th className="treatment-plan-table__heading-cell--treatment">
                Treatment
              </th>
              <th className="treatment-plan-table__heading-cell--instructions">
                Instructions
              </th>
              <th className="treatment-plan-table__heading-cell--instructions">
                Special Instructions
              </th>
              <th className="treatment-plan-table__heading-cell--refills">
                Refills
              </th>
              <th className="treatment-plan-table__heading-cell--refill-expiration">
                Refill Expiration
              </th>
              <th className="treatment-plan-table__heading-cell--action">
                Action
              </th>
            </tr>
          </thead>
          {renderTableBody()}
        </table>
      </div>
      <div className="treatment-plan-table__heading-part treatment-plan-table__control-buttons-wrap treatment-plan-table__add-treatment">
        <button type="button" onClick={handleTableRowAdding}>
          <AddButtonIcon />
          <span style={{ textTransform: "uppercase" }}>Add Treatment</span>
        </button>
      </div>
      <div className="treatment-plan-table__show-treatment-plan-on-mobile">
        <ShowTreatmentPlanMobileDevice
          data={tableRows}
          selectedTreatmentPlanHeading={selectedTreatmentPlan}
          setTableRows={setTableRows}
          tableRows={tableRows}
          onChange={handleTableRowChange}
          setTablesRowData={setTablesRowData}
          tablesRowData={tablesRowData}
          setDate={setDate}
          date={date}
        />
      </div>

      <div className="treatment-plan-table__add-treatment-button-on-mobile">
        <button type="button" onClick={() => setIsOpen(true)}>
          <span style={{ textTransform: "uppercase" }}>Add Treatment</span>
        </button>
      </div>

      <div
        style={{
          width: "100%",
        }}
        className="treatment-plan-table__calendar treatment-plan-table__mobile-calendar"
      >
        <p
          className="follow-up-visit__heading-text"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "16px",
            color: "#403E48",
            marginLeft: "-3px",
          }}
        >
          Choose the Follow-up date
        </p>
        <input
          type="date"
          defaultValue={
            (date && new Date(date).toISOString().split("T")[0]) || ""
          }
          min={new Date().toISOString().split("T")[0]}
          onChange={(event) => {
            const selectedDate = new Date(event.target.value);
            setDate(selectedDate.getTime());
          }}
        />
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Treatment Modal"
        style={customStyles}
      >
        <TreatmentForm
          onChange={handleTableRowChange}
          setTableRows={setTableRows}
          tablesRowData={tablesRowData}
          tableRows={tableRows}
          setDate={setDate}
          date={date}
          setIsOpen={setIsOpen}
        />
      </Modal>
    </>
  );
}
