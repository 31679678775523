import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { HelpPopupTemplate } from "../../../NewComponents/Common/PopupLayer/help";
import { Accutane, Gender } from "../../../../types/Entities/Accutane";
import { Button } from "../../../NewComponents/Common/Button";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import {
  setAccutaneInfo,
  setProviderTasks,
} from "../../../../features/providers";
import "./style.scss";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { setLoading } from "../../../../features/patientView";
import { Patient } from "../../../../types/Entities/Patient";
import { Provider } from "../../../../types/Employee";
import { useAcceptEnrollAccutaneMutation } from "../../../../features/api/accutane";

interface PopupProps {
  onClose: () => void;
}

interface AcceptIPledgePatientPopup extends Accutane {
  taskId: string;
  userId: string;
  employeeId: string;
  patient?: Patient;
  provider?: Provider;
  inviteId?: string | undefined;
  role?: string;
  compositeKey?: string;
}

function AcceptIpledgePopup({
  patientId,
  onClose,
  taskId,
  userId,
  employeeId,
  compositeKey,
}: {
  patientId: string;
  onClose: () => void;
  taskId: string;
  userId: string;
  employeeId: string;
  compositeKey: string;
}) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [acceptEnrollAccutane] = useAcceptEnrollAccutaneMutation();

  const onSubmit = async () => {
    const result: any = await acceptEnrollAccutane({
      patientId,
      taskId,
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to complete task",
      });
      return;
    }
    showSuccess({
      title: "Success!",
      description: "Successfully accepted patient to iPledge",
    });
    onClose();
  };

  return (
    <div className="enroll-ipledge-popup">
      <p className="enroll-ipledge-popup__title">Accept patient in iPledge</p>
      <div className="enroll-ipledge-popup__content">
        <p className="enroll-ipledge-popup__content-text">
          This patient has been enrolled in iPledge. Please accept the patient
          on the iPledge website and then confirm that this task was completed
          below.
        </p>
      </div>
      <div className="enroll-ipledge-popup__control-buttons">
        <Button text="Cancel" onClick={onClose} size="small" view="secondary" />
        <Button text="Confirm" onClick={onSubmit} size="small" />
      </div>
    </div>
  );
}

function AcceptIPledgePopupGenerator(accutane: AcceptIPledgePatientPopup) {
  return function render({ onClose }: PopupProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <AcceptIpledgePopup
          patientId={accutane.patientId}
          taskId={accutane.taskId}
          onClose={onClose}
          userId={accutane.userId}
          employeeId={accutane.employeeId}
          compositeKey={accutane.compositeKey}
        />
      </HelpPopupTemplate>
    );
  };
}

export default AcceptIPledgePopupGenerator;
