import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export interface AccutaneDocument {
  id: string;
  title: string;
  uploadedBy: {
    id: string;
    name: string;
  };
  assignedTo: {
    id: string;
    name: string;
  };
  assignedBy?: {
    id: string;
    role: string;
  };
  createdAt: string;
  compositeKey: string;
  signedBy: {
    id: string;
    name: string;
    role: string;
    createdAt: string;
  };
  comment: string;
  type: string;
  url: string;
}

export interface Response {
  body: AccutaneDocument[];
}

export const getDocuments = async (
  userId: string
): Promise<AccutaneDocument[]> => {
  try {
    const result = await axios.get<Response>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/get-documents/${userId}`
    );

    return result.data.body;
  } catch (error) {
    logError("Error received when trying to get documents");
    throw error;
  }
};
