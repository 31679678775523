import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const updateChatInitTimestamp = async (
  id: string,
  identity: string,
  initTimestamp: string
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient[]>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/update-chat-init-timestamp`,
      {
        id,
        identity,
        initTimestamp,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError("Error occured. Cannot update patient's chat init timestamp");
    throw error;
  }
};
