import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

interface ConfirmPregnancyPreventionProps {
  primaryForm: string;
  secondaryForm?: string;
}

export const confirmPregnancyPrevention = async (
  userId: string,
  formFields: ConfirmPregnancyPreventionProps
) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/add-birth-control/${userId}`,
      {
        primaryForm: formFields.primaryForm,
        secondaryForm: formFields.secondaryForm,
      }
    );

    return result.data;
  } catch (error) {
    logError("Error received when trying to submit blood work results");
    throw error;
  }
};
