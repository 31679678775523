import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const markAsNoShow = async (patientId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/mark-patient-as-no-show`,
      patientId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError("Error occured. Cannot mark user as no show");
    throw error;
  }
};
