import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { StripeCustomerInfo } from "../../../../../types/Payment/StripeCustomerInfo";
import { AuthenticationService } from "../../../../cognito";

export const getCustomerInfo = async (patientId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<StripeCustomerInfo>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/payments/get-customer-info`,
      patientId,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error: any) {
    if (error.statusCode === 404) {
      return null;
    }

    logError("Error occured. Cannot get Payment Configuration", error);
    throw error;
  }
};
