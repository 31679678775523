import React from "react";
import "./style.scss";

interface PaymentOptionCardProps {
  heading: string;
  subHeading: string;
  Icon: React.ElementType;
  onClick: () => void;
  isSelected: boolean;
}

function PaymentOptionCard({
  heading,
  subHeading,
  Icon,
  onClick,
  isSelected,
}: PaymentOptionCardProps) {
  return (
    <div
      className={`pharmacy-billing-form__option ${
        isSelected ? "selected" : ""
      }`}
      onClick={onClick}
    >
      <div className="pharmacy-billing-form__option-left">
        <div className="pharmacy-billing-form__icon-background">
          <Icon />
        </div>
      </div>
      <div className="pharmacy-billing-form__option-right">
        <h3 className="pharmacy-billing-form__option-heading">{heading}</h3>
        <p className="pharmacy-billing-form__option-subheading">{subHeading}</p>
      </div>
    </div>
  );
}

export default PaymentOptionCard;
