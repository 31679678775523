import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  CardElement,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { StripePaymentMethod } from "../../../../../types/Payment/StripeCustomerInfo";
import { Checkbox } from "../../../Common/Checkbox";
import { TextInput } from "../../../Common/TextInput";
import { ReactComponent as MastercardIcon } from "../../../../../Assets/NewIcons/mastercard-brand.svg";
import "./style.scss";
import { PaymentMethod } from "../../../../../types/CustomerInfo";

interface Props {
  items: PaymentMethod[];
  // saveCard: boolean;
  cardHolderName: string;
  onSelectChange: (value: string | null) => void;
  // onCardSaveChange: (value: boolean) => void;
  onCardHolderNameChange: (value: string) => void;
}

function getCardBrandIcon(brand: string) {
  switch (brand) {
    case "mastercard":
      return <MastercardIcon />;
    case "visa":
    default:
      return null;
  }
}

export function CheckoutFormPaymentMethods({
  onCardHolderNameChange,
  onSelectChange,
  // onCardSaveChange,
  cardHolderName,
  // saveCard,
  items,
}: Props) {
  const [value, setValue] = useState<string | null>(items?.[0]?.id || null);

  useEffect(() => {
    onSelectChange(value);
  }, [value]);

  useEffect(() => {
    setValue(items?.[0]?.id || null);
  }, [items]);

  function renderItems() {
    return items.map(({ billingName, cardNumber, brand, id }) => (
      <div
        className={`checkout-component-radio__item ${
          id === value ? "selected" : ""
        }`}
        onClick={() => setValue(id)}
      >
        <div className="checkout-component-radio__heading">
          <div className="checkout-component-radio__circle" />
          <p className="checkout-form__card-holder-name">
            {billingName || "No name"}
          </p>
          <p className="checkout-form__card-number paragraph-font--color">
            ...{cardNumber}
          </p>
          <div className="checkout-form__card-brand">
            {getCardBrandIcon(brand || "")}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className="checkout-component-radio">
      {renderItems()}
      <div
        className={`checkout-component-radio__item ${
          value === null ? "selected" : ""
        }`}
        onClick={() => setValue(null)}
      >
        <div className="checkout-component-radio__heading">
          <div className="checkout-component-radio__circle" />
          <p>Add New Credit or Debit Card</p>
        </div>
        <div className="checkout-component-radio__body">
          <div className="checkout-form">
            <TextInput
              onChange={onCardHolderNameChange}
              id="card-holder-name"
              name="card-holder-name"
              required
              placeholder="Card holder name"
              className="checkout-form__input--card-holder-name"
              value={cardHolderName}
            />
            <CardNumberElement className="text-input__field input-font checkout-form__input--number" />
            <CardExpiryElement className="text-input__field input-font checkout-form__input--expiry-date" />
            <CardCvcElement className="text-input__field input-font checkout-form__input--cvc" />
          </div>
        </div>
      </div>
    </div>
  );
}
