import React, { useContext, useState, useEffect } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import { ImageViewPopupContext } from "../../../Components/Common/ImageViewPopup";
import { PopupLayerContext } from "../PopupLayer";
import VisitDetailsPopupGenerator from "../VisitDetailsPopup";
import { ReactComponent as GoodIcon } from "../../../../Assets/icons/good-icon.svg";
import { ReactComponent as OkIcon } from "../../../../Assets/icons/OkIcon.svg";
import { ReactComponent as BadIcon } from "../../../../Assets/icons/bad-icon.svg";
import {
  getLocalDateLongPretty,
  getMomentDate,
  getGivenTimezoneLongPretty,
} from "../../../../utils/get-date-pretty";
import "./style.scss";
import { ENV_CONFIG } from "../../../../config";
import { FollowUp } from "../../../../types/FollowUp";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import {
  useGetFollowUpsByPatientIdQuery,
  useSubmitFollowUpMutation,
} from "../../../../features/api/follow-ups";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { Button } from "../Button";
import { Skeleton } from "../Skeleton";
import { usePdfToJpgConverter } from "../../../../utils/usePdfToJpgConverter";
import { transformString } from "../../../../utils/transformString";

interface VisitItemProps {
  followUp: FollowUp;
  appointmentDate: number;
  patientTimezone: string;
}

function getFeelingIcon(feeling: string) {
  switch (feeling) {
    case "Good Day":
      return <GoodIcon className="feeling-icon" />;
    case "Typical Day": {
      return <OkIcon className="feeling-icon" />;
    }
    case "Bad Day": {
      return <BadIcon className="feeling-icon" />;
    }
    default: {
      return <div />;
    }
  }
}

export function VisitImage({
  imageName,
  side,
}: {
  imageName: string;
  side?: string;
}) {
  const { showImagePopup } = useContext(ImageViewPopupContext);
  const src = `${ENV_CONFIG.AMAZON_S3_USER_IMAGES_DOMAIN}/${imageName}`;
  const [imageUrl, setImageUrl] = useState("");
  const { convertPdfToJpg, imageData } = usePdfToJpgConverter();

  const handleClickOnImage = () => {
    showImagePopup(imageUrl);
  };

  useEffect(() => {
    if (src) {
      if (src.split(".").pop().toLowerCase() === "pdf") {
        convertPdfToJpg(src);
        setImageUrl(imageData);
      } else setImageUrl(src);
    }
  }, [src, imageData]);

  return (
    <div className="visits-history__items-list__item__preview__images__item">
      {imageUrl && (
        <>
          <div className="visits-history__items-list__item__preview__images__item__side">
            {transformString(side)}
          </div>
          <img
            src={imageUrl}
            className="visits-history__items-list__item__preview__images__item"
            alt="visit-image"
            onClick={handleClickOnImage}
          />
        </>
      )}
    </div>
  );
}

function VisitItem({
  followUp,
  appointmentDate,
  patientTimezone,
}: VisitItemProps) {
  const { showPopup } = useContext(PopupLayerContext);
  const { timestamp } = followUp;
  const followUpDate = appointmentDate
    ? getGivenTimezoneLongPretty(appointmentDate, patientTimezone)
    : moment.utc(timestamp).local().format("MMMM Do, YYYY");
  const followUpVisitDate = appointmentDate || timestamp;

  const openVisitDetails = () => {
    showPopup(
      VisitDetailsPopupGenerator(followUp.followUpId, followUpVisitDate)
    );
  };

  const acneFeelings = followUp.questionnaire?.find(
    ({ id }) => id === "acneFeelings"
  )?.value;

  const renderImages = () => {
    const { images } = followUp;
    const rows = [];
    for (let i = 0; i < images.length; i += 3) {
      rows.push(images.slice(i, i + 3));
    }

    return rows.map((row, index) => (
      <div className="row">
        {row.map(({ fileName, side }, idx) => (
          <div className="visits-history__items-list__item__preview__images__item">
            <VisitImage imageName={fileName} side={side} />
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className="visits-history__items-list__item">
      <div className="visits-history__items-list__item__preview">
        <div className="visits-history__items-list__item__preview__date">
          <p className="visits-history__items-list__item__preview__date__title">
            {followUpDate}
            {acneFeelings ? getFeelingIcon(acneFeelings) : null}
          </p>
          <p
            className="visits-history__items-list__item__preview__date__expand-button"
            onClick={openVisitDetails}
          >
            <span className="visits-history__items-list__item__preview__date__expand-button__text">
              Show details
            </span>
            <ChevronIcon className="visits-history__items-list__item__preview__date__expand-button__icon" />
          </p>
        </div>
        <div className="visits-history__items-list__item__preview__content">
          {followUp?.images && renderImages()}
        </div>
      </div>
    </div>
  );
}

function VisitsHistory({
  disableFollowUpVisitButton,
  appointmentDate,
  patientTimezone,
}: {
  disableFollowUpVisitButton?: boolean;
  appointmentDate?: number;
  patientTimezone?: string;
}) {
  const history = useHistory();
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const [submitFollowUp] = useSubmitFollowUpMutation();

  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  const createFollowUp = async () => {
    const followUpData = await submitFollowUp({
      patientId: activePatientId,
    });

    return followUpData?.data?.followUpId;
  };

  const openFollowUpVisitPage = async () => {
    const hasFollowUps = followUpsQuery?.data && followUpsQuery.data.length > 0;

    if (
      hasFollowUps ||
      (patientQuery.data?.medicalBackground?.sex && (await createFollowUp()))
    ) {
      window.scrollTo(0, 0);
      history.push(`/${activePatientId}/visit`);
    }
  };

  if (!patientQuery.isSuccess || !followUpsQuery.isSuccess) {
    return (
      <div className="visits-history">
        <div className="visits-history__items-list">
          <Skeleton
            flex
            fullWidth
            style={{
              height: 200,
            }}
          />
        </div>
      </div>
    );
  }
  if (!followUpsQuery.data.length) {
    if (disableFollowUpVisitButton) return null;

    return (
      <>
        {appointmentsQuery?.data?.length > 0 && (
          <div className="visits-history">
            <p className="visits-history__title">Visits history</p>
            <p className="visits-history__items-list__item__preview__date__title">
              {getGivenTimezoneLongPretty(
                appointmentsQuery?.data[0]?.startTime,
                appointmentsQuery?.data[0]?.inviteeTimezone
              )}
            </p>
          </div>
        )}
        <div className="visits-history__items-list__item__start-btn">
          <Button text="start visit" onClick={openFollowUpVisitPage} />
        </div>
      </>
    );
  }

  return (
    <div className="visits-history">
      <p className="visits-history__title">Visits history</p>
      <div className="visits-history__items-list">
        {followUpsQuery.data.map((el, index, array) => (
          <VisitItem
            followUp={el}
            appointmentDate={
              index === array.length - 1 ? appointmentDate : undefined
            }
            patientTimezone={patientTimezone}
          />
        ))}
      </div>
    </div>
  );
}

export default VisitsHistory;
