import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Provider } from "../../../../../types/Entities/Provider";
import { AuthenticationService } from "../../../../cognito";

interface Response {
  employee: Provider;
  temporaryPassword: string;
}

export const create = async (provider: Provider) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Response>(
      `${ENV_CONFIG.ROUTE_53_BASE_URL}/providers/create`,
      provider,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot create provider`);
    throw error;
  }
};
