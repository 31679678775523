import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Accutane } from "../../../../../types/Entities/Accutane";

interface ConfirmPregnancyTestResponse {
  accutane: Accutane;
}

export const addPregnancyLabTest = async (
  userId: string
): Promise<ConfirmPregnancyTestResponse> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/add-pregnancy-lab-test/${userId}`
    );

    return result.data;
  } catch (error) {
    logError("Error received when trying to confirm pregnancy test");
    throw error;
  }
};
