import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const confirmIpledge = async (userId: string) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/confirm-ipledge/${userId}`
    );

    return result.data.accutane;
  } catch (error) {
    logError("Error received when trying to confirm iPledge");
    return null;
  }
};
