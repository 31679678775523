import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API, Amplify, graphqlOperation } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import { PriceItem } from "../../types/Price";
import { SubscriptionItem } from "../../types/Subscription";
import { CheckoutPayload } from "../../types/Checkout";
import { logError, logInfo } from "../../shared/logger";

const subscriptionFieldsQuery = `{
  patientId
  id
  status
  type
}`;

const priceFieldsQuery = `{
  id
  type
  title
  amount
  subscriptionType
  medicineId
  keywords
}`;

export const paymentApiSlice = createApi({
  reducerPath: "api/payment",
  tagTypes: ["subscription"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.PAYMENT_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPrices: builder.query<PriceItem[], null>({
      query: () => ({
        url: "",
        params: {
          query: `query { getPrices ${priceFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) => response.data.getPrices,
    }),
    getSubscriptionsByPatientId: builder.query<SubscriptionItem[], string>({
      query: (patientId) => ({
        url: "",
        params: {
          query: `query { getSubscriptionsByPatientId(patientId: "${patientId}") ${subscriptionFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) =>
        response.data.getSubscriptionsByPatientId,
      providesTags: (response) =>
        response?.length
          ? response.map(({ id }) => ({
              type: "subscription",
              id,
            }))
          : [{ type: "subscription" }],
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        await cacheDataLoaded;
        const token = await AuthenticationService.getAccessToken();
        Amplify.configure({
          Auth: {
            region: ENV_CONFIG.AWS_REGION,
            userPoolId: ENV_CONFIG.USER_POOL_ID,
            userPoolWebClientId: ENV_CONFIG.CLIENT_ID,
          },
          aws_appsync_graphqlEndpoint: ENV_CONFIG.PAYMENT_SERVICE_GRAPHQL_URL,
          aws_appsync_region: ENV_CONFIG.AWS_REGION,
          aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
        });
        const subscription = API.graphql<
          GraphQLSubscription<{ onSubscriptionsChange: SubscriptionItem }>
        >(
          graphqlOperation(
            `subscription ($patientId: String!) {
              onSubscriptionsChange(patientId: $patientId) ${subscriptionFieldsQuery}
            }`,
            { patientId: arg },
            token
          )
        ).subscribe({
          next: ({ value }) => {
            updateCachedData((draft) => {
              if (value.data?.onSubscriptionsChange) {
                const index = draft.findIndex(
                  (it) => it.id === value.data?.onSubscriptionsChange.id
                );
                if (index >= 0) {
                  draft[index] = value.data?.onSubscriptionsChange;
                } else {
                  draft.push(value.data?.onSubscriptionsChange);
                }
              }
            });
          },
          error: (error) => logError(error),
        });

        await cacheEntryRemoved;
        subscription.unsubscribe();
      },
    }),
    processCheckout: builder.mutation<null, CheckoutPayload>({
      query: (payload) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($payload: CheckoutInput!) { processCheckout(payload: $payload) { nothing } }",
          variables: JSON.stringify({
            payload,
          }),
        }),
      }),
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return null;
      },
    }),
  }),
});

export const {
  useGetPricesQuery,
  useGetSubscriptionsByPatientIdQuery,
  useProcessCheckoutMutation,
} = paymentApiSlice;
