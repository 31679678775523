export const hairLossSubQuestions = [
  {
    label: "How do you feel about your progress?",
    value: "progressFeelings",
    description: "1-10 ( 1 no improvement, 10 hair fully regrown )",
    questionType: "hairLoss",
    isInputTypeNumber: true,
    maxNumber: 10,
  },
  {
    label:
      "Have there been any changes to your medical history or allergies since your last visit?",
    value: "isMedicalHistoryChanges",
    questionType: "hairLoss",
    hasChild: true,
    childId: "isMedicalHistoryChanges",
    description:
      "(e.g. new medications, medical conditions, surgeries or procedures)",
    placeholder: "Please elaborate",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Please elaborate",
    value: "medicalHistoryChanges",
    questionType: "hairLoss",
    parentQuestion: "isMedicalHistoryChanges",
    parentAnswer: true,
    textArea: true,
    options: [
      {
        value: "medicalHistoryChanges",
        label: "Please elaborate",
      },
    ],
  },
  {
    label:
      "Please let your provider know how you've been using your recommended treatments",
    value: "treatmentSurvey",
    isRecommendedTreatment: true,
    questionType: "hairLoss",
    options: [
      {
        value: "NORMAL",
        label: "I use it exactly as directed",
      },
      {
        value: "MODIFIED",
        label: "I modified the amount or the frequency of use",
      },
      {
        value: "STOPPED",
        label: "I stopped using this",
      },
    ],
  },
  {
    label: "Do you need a refill of any products?",
    value: "isRefillRequired",
    questionType: "eczema",
    hasChild: true,
    childId: "isRefillRequired",
    radioButtonsForm: true,
    placeholder: "Which products would you like to refill?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Which products would you like to refill?",
  //   value: "refillProducts",
  //   questionType: "eczema",
  //   parentQuestion: "isRefillRequired",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "refillProducts",
  //       label: "Which products would you like to refill?",
  //     },
  //   ],
  // },
  {
    label: "Do you have any specific questions or comments for your provider?",
    value: "additionalQuestions",
    questionType: "eczema",
    isTextInput: true,
    // textArea: true,
    options: [],
  },
];
