import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import EnrollIpledgePopupGenerator from "../../../Pages/Provider/EnrollIpledgePopup";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import {
  getSessionState,
  setActivePatient,
} from "../../../../features/session";
import {
  getAccutaneOfPatient,
  getDocumentTasks,
  clearEnrollmentCoordinatorAccutaneInfo,
  getEnrollmentCoordinatorTasks,
  setDocumentTasks,
  setEnrollmentCoordinatorAccutaneInfo,
  setEnrollmentCoordinatorTasks,
} from "../../../../features/enrollmentCoordinators";
import {
  getPatientDocuments,
  storePatientDocuments,
} from "../../../../features/patientView";
import {
  ProviderShortMedicalBackgroundTabs,
  ProviderTabs,
} from "../../../../types/Provider";
import { logError } from "../../../../shared/logger";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { showEditPatientInfoPopup } from "../../../../features/editPatientInfo";
import DocumentOverviewPopupGenerator from "../../../NewComponents/Provider/DocumentOverviewPopup";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import { ReactComponent as ErrorRoundedIcon } from "../../../../Assets/NewIcons/error-rounded.svg";
import { ReactComponent as CheckRoundedIcon } from "../../../../Assets/NewIcons/check-rounded.svg";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/NewIcons/pencil.svg";
import EmptyTaskView from "../../../Pages/Provider/EmptyTaskView";
import { ChatWidget } from "../../../NewComponents/Common/ChatWidget";
import { EditPatientInfoPopup } from "../../../Components/Common/EditPatientInfoPopup";
import { Tabs } from "../../../NewComponents/Common/Tabs";
import TreatmentHistory from "../../../Components/Common/TreatmentHistory";
import { PatientActionButtons } from "../../../Components/Common/PatientActionButtons";
import FollowUpCard from "../../../NewComponents/Common/FollowUpCard";
import VisitsHistory from "../../../NewComponents/Common/VisitsHistory";
import PatientNotes from "../../../NewComponents/Common/PatientNotes";
import ExpandableInfo from "../../../NewComponents/ExpandableInfo";
import AdministratorDocuments from "../../../NewComponents/Administrator/AdministratorDocuments";
import { getProvidersInfoState } from "../../../../features/providers";
import SendBloodSlipPopupGenerator from "../../../NewComponents/Common/SendBloodSlipPopup";
import IpledgeDetails from "../../../NewComponents/Administrator/IpledgeDetails";
import { CareTeamBlock } from "../../../NewComponents/Administrator/CareTeamBlock";
import "./style.scss";
import { getPatientActionsList } from "../../../NewComponents/Patient/WelcomeCard/actions";
import { AccutaneNotification } from "../../../../types/Accutane";
import { concatShippingInfo } from "../../../../utils/concat-shipping-info";
import {
  useGetAccutaneByPatientIdQuery,
  useGetDocumentsByPatientIdQuery,
  useGetTasksQuery,
} from "../../../../features/api/accutane";
import {
  useGetCareTeamQuery,
  useGetPatientByIdQuery,
} from "../../../../features/api/patients";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { InsurancePopupGenerator } from "../../../NewComponents/Patient/InsurancePopup";
import { USER_ROLES } from "../../../../types/Main";
import { hasActiveMembership } from "../../../../utils/has-active-membership";
import { Provider } from "../../../../types/Employee";
import {
  getChatDatePretty,
  getMomentDate,
} from "../../../../utils/get-date-pretty";
import { Button } from "../../../NewComponents/Common/Button";
import { getPatientMonthlyTasks } from "../../../../utils/patient/patientMonthlyTasks";
import { getGovtIDDocuments } from "../../../../utils/patient/get-government-issued-id";
import { pregnancyCapabilityStatus } from "../../../../utils/patient/pregnancyCapabilityStatus";
import RemoveTaskPopupGenerator from "../../../Pages/Common/RemoveTaskPopup";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close-icon.svg";
import ReviewNotesSectionPopupGenerator from "../../../Pages/Common/ReviewNotesSectionPopup";

interface ActionItem {
  text: string;
  check: () => boolean;
  action: () => void;
  key?: string;
}

const mapTaskTypeToPopup = (type: string) => {
  switch (type) {
    // case "enrollPatient": {
    //   return EnrollIpledgePopupGenerator;
    // }
    case "reviewNotesSection": {
      return ReviewNotesSectionPopupGenerator;
    }
    default: {
      return false;
    }
  }
};

const SECONDS_IN_DAY = 60 * 60 * 24;

export function PatientDashboardPageForAdmin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showPopup } = useContext(PopupLayerContext);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [patientTimezone, setPatientTimezone] = useState(null);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [notification, setNotification] = useState<AccutaneNotification | null>(
    null
  );
  const { userId: employeeId, activePatientId } = useSelector(getSessionState);
  const accutane = useGetAccutaneByPatientIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const adminQuery = useGetEmployeeQuery(employeeId as string, {
    skip: !employeeId,
  });
  const careTeamQuery = useGetCareTeamQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  useEffect(() => {
    if (careTeamQuery && careTeamQuery.data) {
      const empData = careTeamQuery.data.map((data) => ({
        id: data.employeeId,
        role: data.role,
      }));
      setEmployeeIds(empData);
    }
  }, [careTeamQuery]);

  const medicationsQuery = useGetAllMedicationsQuery(null);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  const providerIds = employeeIds
    .filter((employee) => employee.role === "provider")
    .map((employee) => employee.id);

  const careCoordinatorIds = employeeIds
    .filter((employee) => employee.role === "care-coordinator")
    .map((employee) => employee.id);

  console.log("providerIds", providerIds);
  console.log("careCoordinatorIds", careCoordinatorIds);
  const providerTasks = useGetTasksQuery(
    {
      patientId: activePatientId as string,
      employeeIds: providerIds.length ? providerIds : undefined,
    },
    {
      skip: !activePatientId || !providerIds.length,
    }
  );
  const careCordtasks = useGetTasksQuery(
    {
      patientId: activePatientId as string,
      employeeIds: careCoordinatorIds.length ? careCoordinatorIds : undefined,
    },
    {
      skip: !activePatientId || !careCoordinatorIds.length,
    }
  );
  const adminTasks = useGetTasksQuery(
    {
      patientId: activePatientId as string,
      employeeIds: [employeeId],
    },
    {
      skip: !activePatientId || !employeeId,
    }
  );

  console.log("careCordtasks", careCordtasks);
  console.log("providerTasks", providerTasks);
  useEffect(() => {
    if (providerTasks?.data && careCordtasks?.data) {
      const providerData = providerTasks.data || [];
      const careCordData = careCordtasks.data || [];
      const adminData = adminTasks.data || [];
      setAllTasks([...providerData, ...careCordData, ...adminData]);
    }
  }, [providerTasks?.data, careCordtasks?.data, adminTasks.data]);

  function discard() {
    setCallback(null);
    setValidity(false);
  }

  const documentsQuery = useGetDocumentsByPatientIdQuery(
    activePatientId as string,
    { skip: !activePatientId }
  );
  const providerQuery = useGetEmployeeQuery(
    careTeamQuery.data?.find(({ role }) => role === USER_ROLES.PROVIDER)
      ?.employeeId as string,
    {
      skip: !careTeamQuery.data?.find(
        ({ role }) => role === USER_ROLES.PROVIDER
      )?.employeeId,
      refetchOnMountOrArgChange: true,
    }
  );
  const { userId: patientId } = useParams<{ userId: string }>();

  const [currentTab, setTab] = useState(
    ProviderTabs.TREATMENT_HISTORY as string
  );
  const onTabChange = (tab: string) => {
    setTab(tab);
  };

  useEffect(() => {
    dispatch(setActivePatient(patientId || null));
  }, [patientId]);
  useEffect(
    () => () => {
      dispatch(setActivePatient(null));
    },
    []
  );

  useEffect(() => {
    if (appointmentsQuery?.data && appointmentsQuery?.data.length > 0) {
      setAppointmentDate(appointmentsQuery.data[0].startTime);
      setPatientTimezone(appointmentsQuery.data[0].inviteeTimezone);
    }
  }, [appointmentsQuery.data]);

  const openFollowUpVisitPage = () => {
    window.scrollTo(0, 0);
    history.push(`/${patientId}/visit`);
  };

  const openInsuranceInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (patientQuery.data)
      showPopup(InsurancePopupGenerator({ patient: patientQuery.data }));
  };

  const adminTodos: ActionItem[] = (allTasks || [])
    ?.map((task) => ({
      text: task.message,
      check: () =>
        !(
          task?.status?.toUpperCase() === "COMPLETED" ||
          task?.status?.toUpperCase() === "DELETED"
        ),
      key: `task__${task.id}`,
      isDeleteable: !!task.id,
      action: () => {
        const linkedDocument = documentsQuery.data?.find(
          (doc) => doc.id === task.compositeKey.split("_")[2]
        );
        // if (linkedDocument && patientId && employeeId) {
        //   showPopup(
        //     DocumentOverviewPopupGenerator(
        //       linkedDocument.title,
        //       patientId,
        //       task.compositeKey.split("_")[2],
        //       linkedDocument.url,
        //       employeeId
        //     )
        //   );
        // } else {
        // const mappedPopup = mapTaskTypeToPopup(task.type);
        if (
          (accutane.data || task.type === "reviewNotesSection") &&
          patientQuery.data &&
          employeeId
        ) {
          showPopup(
            RemoveTaskPopupGenerator({
              ...accutane.data,
              taskId: task.id,
              userId: patientQuery.data.patientId,
              employeeId,
              provider: providerQuery.data as Provider,
              patient: patientQuery.data,
              documentGroupId: task.document?.documentGroupId,
              inviteId: task.document?.inviteId,
              role: "Administrator",
              compositeKey: task.compositeKey,
              taskName: task?.message,
            })
          );
          // }
        }
      },
      key: task.compositeKey,
    }))
    .concat([
      {
        text: "Send blood slip",
        key: "task__send-blood-slip",
        check: (): boolean => {
          if (accutane.data && subscriptionsQuery.data) {
            if (hasActiveMembership(subscriptionsQuery.data)) {
              const delta =
                Math.floor(
                  new Date(accutane.data?.nextConfirmationDate ?? 0).getTime() /
                    1000
                ) - Math.floor(Date.now() / 1000);

              if (
                accutane.data.gender === "Female" &&
                !patientQuery.data?.flags?.isNotPregnantCapability
              ) {
                if (delta < SECONDS_IN_DAY * 5) {
                  return !accutane.data.bloodWork.populated;
                }
                return false;
              }
              if (accutane.data.gender === "Male") {
                return !accutane.data.bloodWork.populated;
              }

              return false;
            }
            return false;
          }
          return false;
        },
        action: () => {
          if (patientQuery.data && providerQuery.data) {
            showPopup(
              SendBloodSlipPopupGenerator({
                patient: patientQuery.data,
                provider: providerQuery.data as Provider,
              })
            );
          }
        },
      },
    ]);

  function renderActionItems() {
    return adminTodos
      .filter(({ check }) => check())
      .map(({ text, check, action, key, isDeleteable }) => (
        <div
          className={`action-items__item${!check() ? " checked" : ""}`}
          key={key}
        >
          <div className="action-items__checkbox">
            <CheckIcon className="action-items__check-icon" />
          </div>
          <p className="action-items__text">{text}</p>
          {isDeleteable && (
            <CloseIcon
              onClick={() => action()}
              className="action-items__chevron"
            />
          )}
        </div>
      ));
  }

  const actionItems = renderActionItems();
  const tabsItems = [
    {
      label: ProviderShortMedicalBackgroundTabs.TODO,
      key: "todos-tab",
      children: (
        <div>
          <div>{actionItems.length ? actionItems : <EmptyTaskView />}</div>
        </div>
      ),
      counter: actionItems.length ?? null,
    },
    {
      label: ProviderTabs.CARE_TEAM,
      key: "care-team",
      children: <CareTeamBlock editable />,
    },
  ];

  const tabs = [
    {
      label: ProviderTabs.TREATMENT_HISTORY,
      key: "treatment-history-tab",
    },
    {
      label: ProviderTabs.DOCUMENTS,
      key: "documents-tab",
    },
  ];

  const createNotificationHandler = async () => {
    if (
      activePatientId &&
      accutane.data?.nextConfirmationDate &&
      new Date(accutane.data.nextConfirmationDate).getTime() > Date.now()
    ) {
      const result = await AcneAwayAPI.accutane.createNotification(
        activePatientId,
        accutane.data?.nextConfirmationDate
      );

      if (result === 200) {
        const notificationResult = await AcneAwayAPI.accutane.getNotification(
          activePatientId
        );

        setNotification(notificationResult);
      }
    } else {
      console.error("Next confirmation date is not exist or is in the past");
    }
  };

  const patientMonthlyTasks = getPatientMonthlyTasks(accutane.data);

  const govtIDDocuments = getGovtIDDocuments(patientId);

  const patientTasks = patientQuery.data
    ? getPatientActionsList(
        accutane.data || null,
        patientQuery.data,
        medicationsQuery.data || [],
        followUpsQuery.data || [],
        appointmentsQuery.data || [],
        subscriptionsQuery.data || [],
        showPopup,
        history,
        govtIDDocuments,
        patientMonthlyTasks
      )
    : [];
  const visiblePatientTasks = patientTasks
    .filter((task) => task.check())
    .map((task) => ({
      label: task.text,
      key: task.key,
      showRemoveIcon: true,
    }));
  const ipledgeConfirmationReminder = notification
    ? [
        {
          description: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "6px 0",
                lineHeight: "20px",
              }}
            >
              <CheckRoundedIcon
                style={{ width: "24px", height: "24px", margin: "0 8px 0 0" }}
              />
              <span>
                iPledge created at{" "}
                <b style={{ fontWeight: "bold" }}>
                  {getMomentDate(notification.createdAt).format("MM/DD/YY")}
                </b>
                <span className="hidden">
                  {" "}
                  and will be sent on{" "}
                  <b style={{ fontWeight: "bold" }}>
                    {getChatDatePretty(
                      new Date(notification.ttl * 1000).toISOString()
                    )}
                  </b>
                </span>
              </span>
            </div>
          ),
        },
      ]
    : [
        {
          description: (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                margin: "6px 0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ErrorRoundedIcon
                  style={{ width: "24px", height: "24px", margin: "0 8px 0 0" }}
                />
                <span>iPledge reminder doesn't exist</span>
              </div>
              <Button
                text="Create"
                onClick={createNotificationHandler}
                size="extra-small"
              />
            </div>
          ),
        },
      ];

  const activePatientShipping = {
    address1: patientQuery.data?.shippingInfo?.addressLine1,
    address2: patientQuery.data?.shippingInfo?.addressLine2,
    city: patientQuery.data?.shippingInfo?.city,
    state: patientQuery.data?.shippingInfo?.state,
    zipCode: patientQuery.data?.shippingInfo?.zipCode,
  };

  const shippingInfo = concatShippingInfo(activePatientShipping);
  const expandableInfo = [
    {
      title: "Parent's contact information",
      items: [
        {
          label: "Parent name",
          description: patientQuery.data?.parentInfo?.name ?? "N/A",
        },
        {
          label: "Parent phone",
          description: patientQuery.data?.parentInfo?.phone ?? "N/A",
        },
        {
          label: "Parent email",
          description: patientQuery.data?.parentInfo?.email ?? "N/A",
        },
      ],
      key: "contactInformation",
    },
    {
      title: "Shipping address",
      items: [
        {
          label: shippingInfo,
        },
      ],
      key: "shippingInfo",
    },
    {
      title: "Health insurance",
      items: [
        {
          label: "Member ID",
          description: patientQuery.data?.insurance?.memberId ?? "N/A",
        },
        {
          label: "Insurance name",
          description: patientQuery.data?.insurance?.insuranceName ?? "N/A",
        },
        {
          label: "Policy holder name",
          description: patientQuery.data?.insurance?.policyHolderName ?? "N/A",
        },
        {
          label: "Group number",
          description: patientQuery.data?.insurance?.groupNumber ?? "N/A",
        },
        {
          label: "RxBin number",
          description: patientQuery.data?.insurance?.rxBinNumber ?? "N/A",
        },
      ],
      key: "healthInsurance",
      extra: (
        <div className="edit-button">
          <EditIcon onClick={openInsuranceInfo} />
        </div>
      ),
    },
    {
      title: "Patient's current tasks",
      items: visiblePatientTasks,
      key: "patientCurrentTasks",
    },
    // {
    //   title: "Ipledge confirmation reminder",
    //   items: ipledgeConfirmationReminder,
    // },
  ];

  const patientInfo =
    patientQuery.data &&
    (moment().diff(patientQuery.data.dateOfBirth, "years") >= 18 ||
      !patientQuery.data.parentInfo)
      ? expandableInfo.slice(1)
      : expandableInfo;
  return (
    <>
      <EditPatientInfoPopup />
      <div className="patient-details-dashboard">
        <div className="dashboard-info-container">
          <div className="dashboard-info-wrapper">
            <div className="dashboard-short-info">
              <FollowUpCard />
              <IpledgeDetails
                patientId={patientId}
                isNotPregnantCapability={pregnancyCapabilityStatus(
                  patientQuery?.data
                )}
              />
              <div className="dashboard-short-info__patient-info">
                <div>
                  {patientInfo.map((it) => (
                    <ExpandableInfo
                      title={it.title}
                      items={it.items}
                      extra={it.extra}
                      key={it.key}
                    />
                  ))}
                </div>
              </div>
              <Tabs items={tabsItems} />
            </div>
            <div className="dashboard-detailed-info">
              <Tabs
                className="dashboard-detailed-info__tabs"
                items={tabs}
                onChange={onTabChange}
              />
              {currentTab === ProviderTabs.TREATMENT_HISTORY && (
                <>
                  <TreatmentHistory />
                  <PatientActionButtons />
                  <VisitsHistory
                    appointmentDate={appointmentDate}
                    patientTimezone={patientTimezone}
                  />
                </>
              )}
              {currentTab === ProviderTabs.DOCUMENTS &&
                (adminQuery.data && activePatientId ? (
                  <AdministratorDocuments
                    userId={activePatientId}
                    employeeId={adminQuery.data?.id}
                  />
                ) : (
                  <Skeleton fullWidth flex style={{ height: 200 }} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
