export const eczemaSubQuestions = [
  {
    label: "How long have you had eczema?",
    value: "periodEczma",
    questionType: "eczema",
    options: [
      {
        value: "I’m new to eczema",
        label: "I’m new to eczema",
      },
      {
        value: "I’ve had eczema for months",
        label: "I’ve had eczema for months",
      },
      {
        value: "I’ve had eczema for years",
        label: "I’ve had eczema for years",
      },
    ],
  },
  {
    label: "Do you have a family history of eczema?",
    value: "familyHistory",
    questionType: "eczema",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have asthma?",
    value: "haveAsthma",
    questionType: "eczema",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have seasonal allergies?",
    value: "seasonalAllergies",
    questionType: "eczema",
    options: [
      {
        value: "Yes",
        label: "Yes",
      },
      {
        value: "No",
        label: "No",
      },
      {
        value: "Unknown",
        label: "Unknown",
      },
    ],
  },
  {
    label: "Do you take long hot showers?",
    value: "longShowers",
    questionType: "lifestyle",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "How would you describe the amount of eczema on your skin from 1 - 10",
    value: "nonEczemaPrescriptionProducts",
    questionType: "eczema",
    placeholder: "Rate from 1-10",
    isInputTypeNumber: true,
    maxNumber: 10,
    options: [
      {
        value:
          "How would you describe the amount of eczema on your skin from 1 - 10",
        label:
          "How would you describe the amount of eczema on your skin from 1 - 10",
      },
    ],
  },
  {
    label: "Where is your eczema typically located?",
    value: "locationofEczma",
    questionType: "eczema",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Inner elbows",
        label: "Inner elbows",
      },
      {
        value: "Behind the knees",
        label: "Behind the knees",
      },
      {
        value: "Back of neck",
        label: "Back of neck",
      },
      {
        value: "Eyelids",
        label: "Eyelids",
      },
      {
        value: "Palms",
        label: "Palms",
      },
      {
        value: "All over the body",
        label: "All over the body",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Where is your eczema typically located?",
    value: "eczemaLocatedText",
    questionType: "eczema",
    parentQuestion: "locationofEczma",
    placeholder: "Where located?",
    parentAnswer: "Other",
    textArea: true,
    isArrayOfAnswer: true,
    options: [
      {
        value: "Where is your eczema typically located?",
        label: "Where is your eczema typically located?",
      },
    ],
  },
  {
    label: "Do your hands crack or bleed?",
    value: "crackorBleed",
    questionType: "eczema",
    parentQuestion: "locationofEczma",
    parentAnswer: "Palms",
    isArrayOfAnswer: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "On a scale of 1-10, how itchy is your skin?",
    value: "itchyChildSkin",
    questionType: "eczema",
    isInputTypeNumber: true,
    maxNumber: 10,
    description: "1 (not at all) --> 10 (can't sleep at night because of it)",
    options: [
      {
        value: "On a scale of 1-10, how itchy is your skin?",
        label: "On a scale of 1-10, how itchy is your skin?",
      },
    ],
  },
  {
    label: "Is your eczema worse during particular seasons?",
    value: "worseduringSeason",
    questionType: "eczema",
    placeholder: "Which Season?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What over-the-counter (non-prescription) creams have you used?",
    value: "otcCreams",
    questionType: "eczema",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Moisturizers",
        label: "Moisturizers",
      },
      {
        value: "Cleansers",
        label: "Cleansers",
      },
      {
        value: "Cortisone Creams",
        label: "Cortisone creams",
      },
      {
        value: "Oatmeal baths, creams",
        label: "Oatmeal baths, creams",
      },
      {
        value: "None",
        label: "None",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What over-the-counter moisturizers have you used?",
    value: "locationofMoisturizers",
    questionType: "eczema",
    parentQuestion: "otcCreams",
    parentAnswer: "Moisturizers",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Cetaphil",
        label: "Cetaphil",
      },
      {
        value: "Cerave",
        label: "Cerave",
      },
      {
        value: "Aquaphor",
        label: "Aquaphor",
      },
      {
        value: "Eucerin",
        label: "Eucerin",
      },
      {
        value: "Vaseline",
        label: "Vaseline",
      },
      {
        value: "Vanicream",
        label: "Vanicream",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Please enter moisturizers location",
    value: "moisturizersLocationText",
    questionType: "eczema",
    parentQuestion: "locationofMoisturizers",
    placeholder: "Location",
    parentAnswer: "Other",
    textArea: true,
    options: [
      {
        value: "Please enter moisturizers location",
        label: "Please enter moisturizers location",
      },
    ],
  },
  {
    label: "What over-the-counter cleansers have you used?",
    value: "locationofCleansers",
    questionType: "eczema",
    parentQuestion: "otcCreams",
    parentAnswer: "Cleansers",
    isArrayOfAnswer: true,
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Cetaphil",
        label: "Cetaphil",
      },
      {
        value: "Cerave",
        label: "Cerave",
      },
      {
        value: "Dove",
        label: "Dove",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Please enter cleansers location",
    value: "cleansersLocationText",
    questionType: "eczema",
    parentQuestion: "locationofCleansers",
    placeholder: "Location",
    parentAnswer: "Other",
    textArea: true,
    options: [
      {
        value: "Please enter cleansers location",
        label: "Please enter cleansers location",
      },
    ],
  },
  {
    label: "What over-the-counter (non-prescription) pills have you used?",
    value: "octPills",
    questionType: "eczema",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Vitamins",
        label: "Vitamins",
      },
      {
        value: "Antihistamines",
        label: "Antihistamines",
      },
      {
        value: "None",
        label: "None",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What over-the-counter antihistamines have you used?",
    value: "antihistaminesPills",
    questionType: "eczema",
    parentQuestion: "octPills",
    parentAnswer: "Antihistamines",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Benadryl",
        label: "Benadryl",
      },
      {
        value: "Zyrtec",
        label: "Zyrtec",
      },
      {
        value: "Claritin",
        label: "Claritin",
      },
      {
        value: "Allegra",
        label: "Allegra",
      },
      {
        value: "Xyzal",
        label: "Xyzal",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "Please enter antihistamines have you used?",
    value: "cleansersLocationText",
    questionType: "eczema",
    parentQuestion: "antihistaminesPills",
    placeholder: "Antihistamines",
    parentAnswer: "Other",
    textArea: true,
    options: [
      {
        value: "Please enter antihistamines have you used?",
        label: "Please enter antihistamines have you used?",
      },
    ],
  },
  {
    label: "What prescription creams have you used ?",
    value: "creamPrecriptions",
    questionType: "eczema",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Hydrocortisone",
        label: "Hydrocortisone",
      },
      {
        value: "Triamcinolone",
        label: "Triamcinolone",
      },
      {
        value: "Alclometasone",
        label: "Alclometasone",
      },
      {
        value: "Desonide",
        label: "Desonide",
      },
      {
        value: "Tacrolimus ( Protopic)",
        label: "Tacrolimus ( Protopic)",
      },
      {
        value: "Pimecrolimus ( Elidel)",
        label: "Pimecrolimus ( Elidel)",
      },
      {
        value: "Mometasone",
        label: "Mometasone",
      },
      {
        value: "Fluocinonide",
        label: "Fluocinonide",
      },
      {
        value: "Halobetasol",
        label: "Halobetasol",
      },
      {
        value: "Clobetasol",
        label: "Clobetasol",
      },
      {
        value: "Opzelura",
        label: "Opzelura",
      },
      {
        value: "None",
        label: "None",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What prescription pills have you used?",
    value: "prescriptionPills",
    questionType: "eczema",
    parentQuestion: "otcCreams",
    parentAnswer: "Cortisone Creams",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Hydroxyzine",
        label: "Hydroxyzine",
      },
      {
        value: "Prednisone",
        label: "Prednisone",
      },
      {
        value: "Rinvoq",
        label: "Rinvoq",
      },
      {
        value: "Cibinqo",
        label: "Cibinqo",
      },
      {
        value: "Antibiotics",
        label: "Antibiotics",
      },
      {
        value: "None",
        label: "None",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What prescription injectables have you used?",
    value: "injectables",
    questionType: "eczema",
    parentQuestion: "otcCreams",
    parentAnswer: "Oatmeal baths, creams",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Dupixent",
        label: "Dupixent",
      },
      {
        value: "Adbry",
        label: "Adbry",
      },
      {
        value: "None",
        label: "None",
        onlySelectable: true,
      },
    ],
  },
  {
    label:
      "Are you currently using any medications or treatment to treat your condition?",
    value: "currentMedication",
    questionType: "eczema",
    placeholder: "Which medications",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are there specific medications you are interested in?",
    value: "specificInterestedMedics",
    questionType: "eczema",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    placeholder: "Which allergies",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Is there anything else we should know",
    value: "anythingElse",
    questionType: "otherMedical",
    placeholder: "e.g. other medical conditions",
    textArea: true,
    options: [
      {
        value: "Is there anything else we should know",
        label: "Is there anything else we should know",
      },
    ],
  },
  {
    label: "Has your eczema affected how much sleep you are getting?",
    value: "eczemaSleepImpact",
    questionType: "lifestyle",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Has your eczema affected how much sleep you are getting?",
    value: "eczemaSleepPeriod",
    questionType: "lifestyle",
    parentQuestion: "eczemaSleepImpact",
    parentAnswer: true,
    options: [
      {
        value: "Only 3-4 hours or less",
        label: "Only 3-4 hours or less",
      },
      {
        value: "Only 5-7 hours",
        label: "Only 5-7 hours",
      },
      {
        value: "8-9 hours",
        label: "8-9 hours",
      },
    ],
  },
  {
    label: "In the last month, how would you describe your stress level?",
    value: "stressLevel",
    questionType: "lifestyle",
    options: [
      {
        value: "Never stressed",
        label: "Never stressed",
      },
      {
        value: "Almost never stressed",
        label: "Almost never stressed",
      },
      {
        value: "Sometimes stressed",
        label: "Sometimes stressed",
      },
      {
        value: "Fairly stressed",
        label: "Fairly stressed",
      },
      {
        value: "Very stressed",
        label: "Very stressed",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
  {
    label: "Are you currently pregnant or trying to become pregnant?",
    value: "pregnancy",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are you currently breastfeeding?",
    value: "breastfeeding",
    questionType: "cycleAndMenstruation",
    parentQuestion: "gender",
    parentAnswer: "Female",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
];
