import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PatientNotes } from "../../../Components/Common/PatientNotes";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import {
  getSessionState,
  setActivePatient,
} from "../../../../features/session";
import { ProviderTabs } from "../../../../types/Provider";
import { logError } from "../../../../shared/logger";
import IpledgeDetails from "../../../NewComponents/EnrollmentCoordinator/IpledgeDetails";
import { Tabs } from "../../../NewComponents/Common/Tabs";
import ExpandableInfo from "../../../NewComponents/ExpandableInfo";
import TreatmentHistory from "../../../Components/Common/TreatmentHistory";
import VisitsHistory from "../../../NewComponents/Common/VisitsHistory";
import "./style.scss";
import {
  clearEnrollmentCoordinatorAccutaneInfo,
  setEnrollmentCoordinatorAccutaneInfo,
  setEnrollmentCoordinatorTasks,
} from "../../../../features/enrollmentCoordinators";
import FollowUpCard from "../../../NewComponents/Common/FollowUpCard";
import { concatShippingInfo } from "../../../../utils/concat-shipping-info";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { pregnancyCapabilityStatus } from "../../../../utils/patient/pregnancyCapabilityStatus";

const enrollmentCoordinatorTabsItems = [
  {
    label: ProviderTabs.TREATMENT_HISTORY,
    key: "treatment-history-tab",
  },
];

export function PatientDashboardPageForEnrollmentCoordinator() {
  const dispatch = useDispatch();
  const { userId: employeeId, activePatientId } = useSelector(getSessionState);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [patientTimezone, setPatientTimezone] = useState(null);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [currentTab, setTab] = useState(
    ProviderTabs.TREATMENT_HISTORY as string
  );
  const { userId: patientId } = useParams<{ userId: string }>();

  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  useEffect(() => {
    dispatch(setActivePatient(patientId || null));
  }, [patientId]);
  useEffect(
    () => () => {
      dispatch(setActivePatient(null));
    },
    []
  );

  useEffect(() => {
    if (appointmentsQuery?.data && appointmentsQuery?.data.length > 0) {
      setAppointmentDate(appointmentsQuery.data[0].startTime);
      setPatientTimezone(appointmentsQuery.data[0]?.inviteeTimezone);
    }
  }, [appointmentsQuery.data]);

  const onTabChange = (tab: string) => {
    setTab(tab);
  };

  const activePatientShipping = {
    address1: patientQuery.data?.shippingInfo?.addressLine1,
    address2: patientQuery.data?.shippingInfo?.addressLine2,
    city: patientQuery.data?.shippingInfo?.city,
    state: patientQuery.data?.shippingInfo?.state,
    zipCode: patientQuery.data?.shippingInfo?.zipCode,
  };

  const shippingInfo = concatShippingInfo(activePatientShipping);

  const expandableInfo = [
    {
      title: "Parent's contact information",
      items: [
        {
          label: "Parent name",
          description: patientQuery.data?.parentInfo?.name ?? "N/A",
        },
        {
          label: "Parent phone",
          description: patientQuery.data?.parentInfo?.phone ?? "N/A",
        },
        {
          label: "Parent email",
          description: patientQuery.data?.parentInfo?.email ?? "N/A",
        },
      ],
      key: "contactInformation",
    },
    {
      title: "Shipping address",
      items: [
        {
          label: shippingInfo,
        },
      ],
      key: "shippingInfo",
    },
    {
      title: "Health insurance",
      items: [
        {
          label: "Member ID",
          description: patientQuery.data?.insurance?.memberId ?? "N/A",
        },
        {
          label: "Insurance name",
          description: patientQuery.data?.insurance?.insuranceName ?? "N/A",
        },
        {
          label: "Policy holder name",
          description: patientQuery.data?.insurance?.policyHolderName ?? "N/A",
        },
        {
          label: "Group number",
          description: patientQuery.data?.insurance?.groupNumber ?? "N/A",
        },
        {
          label: "RxBin number",
          description: patientQuery.data?.insurance?.rxBinNumber ?? "N/A",
        },
      ],
      key: "healthInsurance",
    },
  ];

  return (
    <div className="patient-details-dashboard">
      <div className="dashboard-info-container">
        <div className="dashboard-info-wrapper">
          <div className="dashboard-short-info">
            <FollowUpCard editable={false} />
            <IpledgeDetails
              patientId={patientId}
              isNotPregnantCapability={pregnancyCapabilityStatus(
                patientQuery?.data
              )}
            />
            <div className="dashboard-short-info__patient-info">
              <div>
                {expandableInfo.map((it) => (
                  <ExpandableInfo
                    title={it.title}
                    items={it.items}
                    key={it.key}
                  />
                ))}
              </div>
            </div>
            <div className="dashboard-short-info__patient-notes">
              {/* <PatientNotes patient={patient} /> */}
            </div>
          </div>
          <div className="dashboard-detailed-info">
            <Tabs
              className="dashboard-detailed-info__tabs"
              items={enrollmentCoordinatorTabsItems}
              onChange={onTabChange}
            />
            {currentTab === ProviderTabs.TREATMENT_HISTORY && (
              <>
                <TreatmentHistory editable={false} />
                <VisitsHistory
                  appointmentDate={appointmentDate}
                  patientTimezone={patientTimezone}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
