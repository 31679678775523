import { acneSubQuestions } from "./acne";
import { eczemaSubQuestions } from "./eczema";
import { rosaceaSubQuestions } from "./rosacea";
import { psoriasisSubQuestions } from "./psoriasis";
import { hairLossSubQuestions } from "./hairLoss";
import { nailFungusSubQuestions } from "./nailFungus";
import { hidradentitisSubQuestions } from "./hidradenitisSuppurativa";
import { tineaVersicolorSubQuestions } from "./tineaVersicolor";

export const followupSkinIssues = [
  { label: "Acne", value: "acne", subQuestions: acneSubQuestions },
  { label: "Eczema", value: "eczema", subQuestions: eczemaSubQuestions },
  { label: "Rosacea", value: "rosacea", subQuestions: rosaceaSubQuestions },
  {
    label: "Psoriasis",
    value: "psoriasis",
    subQuestions: psoriasisSubQuestions,
  },
  { label: "Hair loss", value: "hairLoss", subQuestions: hairLossSubQuestions },
  {
    label: "Nails fungus",
    value: "nailFungus",
    subQuestions: nailFungusSubQuestions,
  },
  {
    label: "Hidradenitis suppurativa",
    value: "hidradenitisSuppurativa",
    subQuestions: hidradentitisSubQuestions,
  },
  {
    label: "Tinea versicolor",
    value: "tineaVersicolor",
    subQuestions: tineaVersicolorSubQuestions,
  },
];
