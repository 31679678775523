import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { newFollowUp, newPatient } from "./queryPartials";

export const patientDashboardForAdministratorSort = () => [
  {
    filter: {
      ...newPatient,
    },
    script_score: {
      script: {
        source: `10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
  },
  {
    filter: {
      ...newFollowUp,
    },
    script_score: {
      script: {
        source: `10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
  },
  {
    filter: {
      match_all: {},
    },
    weight: 1,
  },
  // {
  //   filter: {
  //     bool: {
  //       must: {
  //         exists: { field: "fullName" },
  //       },
  //     },
  //   },
  //   field_value_factor: {
  //     field: "fullName.keyword",
  //     factor: 1,
  //     modifier: "sqrt",
  //     missing: 0,
  //   },
  // },
];
