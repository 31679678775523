import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import "./style.scss";
import { sendSurveyStarted } from "../../../../shared/analytics";
import { TreatmentPlanSurveyPage } from "./pages/treatment-plan";
import { MembershipPlanSurveyPage } from "./pages/membership-plan";
import { ProductsSurveyPage } from "./pages/products";
import { ShipppingInfoSurveyPage } from "./pages/shipping-info";
import { PaymentFormSurveyPage } from "./pages/payment-form";
import { ReactComponent as HappyGuy } from "../../../../Assets/NewIcons/happy-guy.svg";
import { Button } from "../../../NewComponents/Common/Button";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { Loader } from "../../../NewComponents/Common/Loader";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { Patient } from "../../../../types/Entities/Patient";
import { SubscriptionItem } from "../../../../types/Subscription";
import PharmacyBillingMethodPopupModal from "../../../NewComponents/Patient/PharmacyBillingMethodPopupModal";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";

export function InitiateMembershipPage({ patientId }: { patientId: string }) {
  const history = useHistory();
  const { showError } = useContext(NotificationLayerContext);
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(patientId);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(patientId);
  const patientQuery = useGetPatientByIdQuery(patientId);
  const [isDone, setDone] = useState(false);
  const [showFinishSection, setShowFinishSection] = useState(false);
  const [initialPatientData, setInitialPatientData] = useState<Patient>();
  const [initialSubscriptionsData, setInitialSubscriptionsData] =
    useState<SubscriptionItem[]>();

  const { showPopup } = useContext(PopupLayerContext);

  const isLoaded =
    subscriptionsQuery.isSuccess &&
    followUpsQuery.isSuccess &&
    patientQuery.isSuccess;

  // useEffect(() => {
  //   sendSurveyStarted();
  // }, []);

  useEffect(() => {
    if (!initialPatientData && patientQuery.data) {
      setInitialPatientData(patientQuery.data);
    }
  }, [patientQuery]);

  useEffect(() => {
    if (!initialSubscriptionsData && subscriptionsQuery.data) {
      setInitialSubscriptionsData(subscriptionsQuery.data);
    }
  }, [subscriptionsQuery]);

  useEffect(() => {
    if (isDone) {
      showPopup(PharmacyBillingMethodPopupModal());
      setShowFinishSection(true);
    }
  }, [isDone]);

  const fullSurveyQuestions: SurveyPage[] = [
    {
      isFilled: () => false,
      Component: TreatmentPlanSurveyPage,
    },
    {
      isFilled: () => false,
      Component: MembershipPlanSurveyPage,
    },
    {
      isFilled: () => false,
      Component: ProductsSurveyPage,
    },
    {
      isFilled: () => false,
      when: () => !initialPatientData?.shippingInfo,
      Component: ShipppingInfoSurveyPage,
    },
    {
      isFilled: () => false,
      Component: PaymentFormSurveyPage,
    },
  ];

  const shortSurveyQuestions: SurveyPage[] = [
    {
      isFilled: () => false,
      Component: MembershipPlanSurveyPage,
    },
    {
      isFilled: () => false,
      when: () => !initialPatientData?.shippingInfo,
      Component: ShipppingInfoSurveyPage,
    },
    {
      isFilled: () => false,
      Component: PaymentFormSurveyPage,
    },
  ];

  if (!isLoaded) {
    return (
      <div className="main-area__loader-wrap">
        <div className="main-area__loader">
          <Loader />
        </div>
      </div>
    );
  }

  if (
    initialSubscriptionsData?.find(
      ({ type, status }) => type === "membership" && status !== "cancelled"
    )
  ) {
    showError({
      title: "Something went wrong...",
      description:
        "You've already got membership, so there is no need to buy it again",
    });
    return <Redirect to="/" />;
  }

  const isTreatmentPlanExist = !!followUpsQuery.data.find(
    ({ treatmentPlan }) => !!treatmentPlan
  );

  return showFinishSection ? (
    <div className="initiate-checkout-page__success-page">
      <HappyGuy className="initiate-checkout-page__success-image" />
      <p className="initiate-checkout-page__success-title">
        You have successfully started your Honeydew membership. Welcome!
      </p>
      <p className="initiate-checkout-page__success-description paragraph-font--color">
        After a minute you will obtain full access to our features.{" "}
      </p>
      <Button onClick={() => history.push("/")} text="finish" />
    </div>
  ) : (
    <CustomSurvey
      questions={
        // shortSurveyQuestions
        isTreatmentPlanExist ? fullSurveyQuestions : shortSurveyQuestions
      }
      data={{}}
      onComplete={() => {
        setDone(true);
        return true;
      }}
      className="initiate-checkout-page__survey"
    />
  );
}
