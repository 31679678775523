import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API, Amplify, graphqlOperation } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import { Appointment } from "../../types/Appointment";
import { logError } from "../../shared/logger";

const appointmentFieldsQuery = `{ patientId appointmentId timestamp startTime endTime status meetingLink, inviteeTimezone }`;

export const appointmentsApiSlice = createApi({
  reducerPath: "api/appointments",
  tagTypes: ["appointment"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.APPOINTMENT_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAppointment: builder.query<Appointment, string>({
      query: (appointmentId: string) => ({
        url: "",
        params: {
          query: `query {
            getAppointment(appointmentId: "${appointmentId}") ${appointmentFieldsQuery}
          }`,
        },
      }),
      transformResponse: (response: any) => response.data.getAppointment,
      providesTags: (response) => [
        { type: "appointment", id: response?.appointmentId },
      ],
    }),
    getAppointmentsByPatientId: builder.query<Appointment[], string>({
      query: (patientId: string) => ({
        url: "",
        params: {
          query: `query { getAppointmentsByPatientId(patientId: "${patientId}") ${appointmentFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) =>
        response.data.getAppointmentsByPatientId,
      providesTags: (response) =>
        response?.length
          ? response.map(({ appointmentId }) => ({
              type: "appointment",
              appointmentId,
            }))
          : [{ type: "appointment" }],
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        await cacheDataLoaded;
        const token = await AuthenticationService.getAccessToken();
        Amplify.configure({
          Auth: {
            region: ENV_CONFIG.AWS_REGION,
            userPoolId: ENV_CONFIG.USER_POOL_ID,
            userPoolWebClientId: ENV_CONFIG.CLIENT_ID,
          },
          aws_appsync_graphqlEndpoint:
            ENV_CONFIG.APPOINTMENT_SERVICE_GRAPHQL_URL,
          aws_appsync_region: ENV_CONFIG.AWS_REGION,
          aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
        });
        const subscription = API.graphql<
          GraphQLSubscription<{ onAppointmentChange: Appointment }>
        >(
          graphqlOperation(
            `subscription ($patientId: String!) {
                onAppointmentChange(patientId: $patientId) ${appointmentFieldsQuery}
              }`,
            { patientId: arg },
            token
          )
        ).subscribe({
          next: ({ value }) => {
            updateCachedData((draft) => {
              if (value.data?.onAppointmentChange) {
                const index = draft.findIndex(
                  (it) =>
                    it.appointmentId ===
                    value.data?.onAppointmentChange.appointmentId
                );
                if (index >= 0) {
                  draft[index] = value.data?.onAppointmentChange;
                } else {
                  draft.unshift(value.data?.onAppointmentChange);
                }
              }
            });
          },
          error: (error) => logError(error),
        });

        await cacheEntryRemoved;
        subscription.unsubscribe();
      },
    }),
    proposeMeeting: builder.mutation<null, string>({
      query: (patientId: string) => ({
        url: "",
        params: {
          query: `mutation {
            proposeMeeting(patientId: "${patientId}") { nothing }
          }`,
        },
      }),
    }),

    markAsNoShow: builder.mutation<null, { patientId: string; userId: string }>(
      {
        query: ({ patientId, userId }) => ({
          url: "",
          params: {
            query: `mutation {
            markAsNoShow(patientId: "${patientId}", userId: "${userId}") { nothing }
          }`,
          },
        }),
        transformResponse: (response: any) => {
          if (response.errors?.[0]?.message) {
            throw new Error(response.errors?.[0]?.message);
          }
          return null;
        },
      }
    ),

    confirmAppointment: builder.mutation<null, string>({
      query: (patientId: string) => ({
        url: "",
        params: {
          query: `mutation {
            confirmAppointment(patientId: "${patientId}") { nothing }
          }`,
        },
      }),
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return null;
      },
    }),

    cancelAppointment: builder.mutation<null, string>({
      query: (patientId: string) => ({
        url: "",
        params: {
          query: `mutation {
            cancelAppointment(patientId: "${patientId}") { nothing }
          }`,
        },
      }),
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return null;
      },
    }),
  }),
});

export const {
  useGetAppointmentQuery,
  useGetAppointmentsByPatientIdQuery,
  useProposeMeetingMutation,
  useMarkAsNoShowMutation,
  useConfirmAppointmentMutation,
  useCancelAppointmentMutation,
} = appointmentsApiSlice;
