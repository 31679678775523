import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../NewComponents/Common/Button";
import "./style.scss";
import {
  useGetActiveTimeOffByEmployeeIdQuery,
  useGetEmployeeQuery,
  useReplaceCareTeamMemberIfOnLeaveMutation,
} from "../../../../features/api/employees";
import {
  BasicPopupTemplate,
  BasicPopupTemplateContext,
} from "../../../NewComponents/Common/PopupLayer/basic";
import { InfoBlock } from "../../../NewComponents/Common/InfoBlock";
import { getFormattedMomentDate } from "../../../../utils/get-date-pretty";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { Loader } from "../../../NewComponents/Common/Loader";

interface PopupProps {
  // type: USER_ROLES.PROVIDER | USER_ROLES.CARE_COORDINATOR;
  patientId: any;
  employee: any;
  onSubstituteReplace: any;
}

interface Props {
  onClose: () => void;
}

function WarnSubstituteEmployeePopup({
  patientId,
  employee,
  onSubstituteReplace,
}: PopupProps) {
  const { onClose } = useContext(BasicPopupTemplateContext);
  const { showError, showSuccess } = useContext(NotificationLayerContext);

  const [isLoaded, setIsLoaded] = useState(true);
  const [empData, setEmpData] = useState();
  const [endDate, setEndDate] = useState();

  const [replaceCareTeamMemberIfOnLeave] =
    useReplaceCareTeamMemberIfOnLeaveMutation();

  const employeeQuery = useGetEmployeeQuery(employee.employeeId);
  const allActiveTimeOff = useGetActiveTimeOffByEmployeeIdQuery(
    employee.employeeId
  );

  useEffect(() => {
    if (employeeQuery?.data && allActiveTimeOff?.data?.data) {
      const timeOffs =
        allActiveTimeOff?.data?.data.getActiveTimeOffEventByEmployeeId;
      const foundObject = timeOffs.find(
        (leave) => leave.leaveId === employee.leaveId
      );
      const endDateFormatted = getFormattedMomentDate(foundObject.end * 1000);

      setEndDate(endDateFormatted);
      setEmpData(employeeQuery?.data);
      setIsLoaded(false);
    }
  }, [employeeQuery, allActiveTimeOff]);

  const replaceEmpIdWithSubstitute = async () => {
    const replaceResult = await replaceCareTeamMemberIfOnLeave({
      employeeId: employee.employeeId,
      patientId,
    });
    if (!replaceResult?.error) {
      const idToReplaceWith = replaceResult?.data.id;
      onSubstituteReplace(idToReplaceWith);
      onClose();
    } else {
      showError({
        title: "Something went wrong...",
        description: "Unable to assign task",
      });
      onClose();
    }
  };

  const handleModalClose = () => {
    onSubstituteReplace(employee.employeeId);
    onClose();
  };

  return isLoaded ? (
    <div className="warn-popup">
      <Loader className="chat-popup__loader" />
    </div>
  ) : (
    <div className="warn-popup">
      <div className="info-container">
        <div className="info-block">
          <InfoBlock
            text={`${empData.firstName} ${empData.lastName} is out until ${endDate}`}
            view="warn"
          />
        </div>
        <p className="info-block-note">
          They will not be notified until they return.{" "}
          <span className="info-block-bold-note">
            Do you want to notify the person who is covering for them instead?
          </span>{" "}
        </p>
      </div>
      <div className="buttons-container">
        <Button
          text={`Assign it to ${empData?.firstName}`}
          view="secondary"
          size="small"
          onClick={handleModalClose}
        />
        <Button
          text="Assign it to their substitute"
          size="small"
          onClick={replaceEmpIdWithSubstitute}
        />
      </div>
    </div>
  );
}

export function WarnSubstituteEmployeePopupGenerator({
  patientId,
  employee,
  onSubstituteReplace,
}: any) {
  return function render({ onClose }: Props) {
    return (
      <div className="warn-popup-modal">
        <BasicPopupTemplate title="Out-of-Office notice" onClose={onClose}>
          <WarnSubstituteEmployeePopup
            patientId={patientId}
            onSubstituteReplace={onSubstituteReplace}
            employee={employee}
          />
        </BasicPopupTemplate>
      </div>
    );
  };
}
