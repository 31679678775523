export const rosaceaSubQuestions = [
  {
    label:
      "Have there been any changes to your medical history or new allergies since your last visit?",
    value: "isMedicalHistoryChanges",
    hasChild: true,
    childId: "isMedicalHistoryChanges",
    questionType: "rosacea",
    description:
      "(e.g. new medications, medical conditions,  surgeries or procedures)",
    radioButtonsForm: true,
    placeholder: "Please elaborate",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Please elaborate",
  //   value: "medicalHistoryChanges",
  //   questionType: "rosacea",
  //   parentQuestion: "isMedicalHistoryChanges",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "medicalHistoryChanges",
  //       label: "Please elaborate",
  //     },
  //   ],
  // },
  {
    label:
      "Please let your provider know how you've been using your recommended treatments",
    value: "treatmentSurvey",
    isRecommendedTreatment: true,
    questionType: "rosacea",
    options: [
      {
        value: "NORMAL",
        label: "I use it exactly as directed",
      },
      {
        value: "MODIFIED",
        label: "I modified the amount or the frequency of use",
      },
      {
        value: "STOPPED",
        label: "I stopped using this",
      },
    ],
  },
  {
    label: "Are you experiencing any dryness or itching from your creams?",
    value: "isDrynessExperience",
    questionType: "rosacea",
    placeholder: "Please elaborate",
    radioButtonsForm: true,
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
  {
    label:
      "Are you experiencing any stomach irritation, headache, lightheadedness, or body rash from your oral medications?",
    value: "isIrritationExperience",
    questionType: "rosacea",
    hasChild: true,
    childId: "isIrritationExperience",
    radioButtonsForm: true,
    placeholder: "Please elaborate",
    options: [
      { value: true, label: "Yes" }, // What medicines?
      { value: false, label: "No" },
      {
        value: "I don’t take oral medication",
        label: "I don’t take oral medication",
      },
    ],
  },

  // {
  //   label: "Please elaborate",
  //   value: "irritationExperience",
  //   questionType: "rosacea",
  //   parentQuestion: "isIrritationExperience",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "irritationExperience",
  //       label: "Please elaborate",
  //     },
  //   ],
  // },
  {
    label: "Do you need a refill of any products?",
    value: "isRefillRequired",
    questionType: "acne",
    hasChild: true,
    childId: "isRefillRequired",
    radioButtonsForm: true,
    placeholder: "Which products would you like to refill?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Which products would you like to refill?",
  //   value: "refillProducts",
  //   questionType: "eczema",
  //   parentQuestion: "isRefillRequired",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "refillProducts",
  //       label: "Which products would you like to refill?",
  //     },
  //   ],
  // },
  {
    label: "How do you feel about your progress?",
    value: "progressFeelings",
    questionType: "eczema",
    textArea: true,
    isTextArea: true,
    options: [
      {
        value: "How do you feel about your progress?",
        label: "How do you feel about your progress?",
      },
    ], // open text
  },
  {
    label: "Do you have any specific comments or concerns for your provider?",
    value: "additionalQuestions",
    questionType: "eczema",
    textArea: true,
    options: [
      {
        value:
          "Do you have any specific questions or comments for your provider?",
        label:
          "Do you have any specific questions or comments for your provider?",
      },
    ], // open text
  },
];
