import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

interface Props {
  children: JSX.Element[];
  session: boolean;
}

function PrivateRoute({ children, session, ...rest }: Props) {
  const location = useLocation();
  return (
    <Route {...rest}>
      {session ? (
        children
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { targetPath: location.pathname } }}
        />
      )}
    </Route>
  );
}

export default PrivateRoute;
