export const nailsFungusSubQuestions = [
  {
    label: "Which nails are affected?",
    value: "affectedNails",
    questionType: "nailFungus",
    options: [
      {
        value: "Toenails",
        label: "Toenails",
      },
      {
        value: "Fingernails",
        label: "Fingernails",
      },
      {
        value: "Both",
        label: "Both",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
  {
    label: "Do you have a known history of",
    value: "knownHistory",
    questionType: "nailFungus",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Trauma to the nail",
        label: "Trauma to the nail",
      },
      {
        value: "Psoriasis",
        label: "Psoriasis",
      },
      {
        value: "Lichen Planus",
        label: "Lichen Planus",
      },
      {
        value: "Inflamed Skin Around Nail",
        label: "Inflamed skin around the nail (Paronychia)",
      },
    ],
  },
  {
    label: "Do you have a history of diabetes?",
    value: "diabetesHistory",
    questionType: "otherMedical",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you get frequent pedicures/manicures?",
    value: "frequency",
    questionType: "nailFungus",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What kind of products have you tried for nail fungus in the past?",
    value: "previousProductsforNailsTaken",
    questionType: "nailFungus",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Over-the-counter products",
        label: "Over-the-counter products",
      },
      {
        value: "Prescription medications",
        label: "Prescription medications",
      },
      {
        value: "Office-based treatments",
        label: "Office-based treatments",
      },
      {
        value: "I haven’t tried any products",
        label: "I haven’t tried any products",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What over the counter medications have you tried in the past?",
    value: "overTheCounterMeds",
    questionType: "nailFungus",
    parentQuestion: "previousProductsforNailsTaken",
    parentAnswer: "Over-the-counter products",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Biotin",
        label: "Biotin",
      },
      {
        value: "Other Supplements",
        label: "Other supplements",
      },
      {
        value: "Topical nail Solutions",
        label: "Topical nail solutions",
      },
    ],
  },
  {
    label: "What prescription medications have you tried in the past?",
    value: "prescriptionMeds",
    questionType: "nailFungus",
    parentQuestion: "previousProductsforNailsTaken",
    parentAnswer: "Prescription medications",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Penlac",
        label: "Penlac (Ciclopirox) Nail Solution",
      },
      {
        value: "Jublia",
        label: "Jublia (Efinaconazole) Nail Solution",
      },
      {
        value: "Kerydin",
        label: "Kerydin (Tavaborole) Nail Solution",
      },
      {
        value: "Oral Lamisil",
        label: "Oral Lamisil (Terbinafine)",
      },
      {
        value: "Oral Sporanox",
        label: "Oral Sporanox (Itraconazole)",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "What office-based treatments have you tried?",
    value: "officeTreatments",
    questionType: "nailFungus",
    parentQuestion: "previousProductsforNailsTaken",
    parentAnswer: "Office-based treatments",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Nail Surgery",
        label: "Nail Surgery",
      },
      {
        value: "LASER",
        label: "LASER",
      },
      {
        value: "Steroid Injections",
        label: "Steroid Injections",
      },
    ],
  },
  {
    label:
      "Are you currently using any medications or treatment to treat your condition?",
    value: "currentMedication",
    questionType: "nailFungus",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are there specific medications you are interested in?",
    value: "nailsSpecificMedicationsInterest",
    questionType: "nailFungus",
    parentQuestion: "previousProductsforNailsTaken",
    parentTextAnwser: "I haven’t tried any products",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    placeholder: "Which allergies?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Is there anything else we should know? (e.g. other medical conditions)",
    value: "otherInformation",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "e.g. other medical conditions",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
];
