import React from "react";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import IsotretinoinTreatment from "../../../Pages/Provider/IsotretinoinTreatment";

interface Props {
  onClose: () => void;
}

function PauseOrStopIsotretinoinPopup({ onClose, userId }: any) {
  const onSubmit = () => {
    onClose();
  };

  return <IsotretinoinTreatment onSubmit={onSubmit} userId={userId} />;
}

export function PauseOrStopIsotretinoinPopupGenerator(userId: string) {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <PauseOrStopIsotretinoinPopup onClose={onClose} userId={userId} />
      </HelpPopupTemplate>
    );
  };
}

export default PauseOrStopIsotretinoinPopupGenerator;
