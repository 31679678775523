export const concatShippingInfo = (
  activePatientShipping: Record<string, string | undefined>
): string => {
  const hasNoShippingInfo = Object.values(activePatientShipping).every(
    (it) => !it
  );
  if (hasNoShippingInfo) {
    return "N/A";
  }
  const shippingRow: Array<string> = Object.values(
    activePatientShipping
  ).reduce((acc: string[], it) => {
    if (it && String(it).trim().length > 0) {
      acc.push(String(it).trim());
    }

    return acc;
  }, []);

  return shippingRow.join(", ");
};
