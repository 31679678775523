import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { ENV_CONFIG } from "../../../../../config";
import { sendAppointmentScheduled } from "../../../../../shared/analytics";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";

export function CalendlySurveyQuestion({ onDone, data }: SurveyComponentProps) {
  const { patient } = data;
  useCalendlyEventListener({
    onEventScheduled: () => {
      localStorage.setItem(
        `appointment-submitted-${patient.patientId}`,
        "true"
      );
      sendAppointmentScheduled();
      onDone({});
    },
  });

  const widgetUrl = ENV_CONFIG.CALENDLY_APPOINTMENT_WIGDET_URLS[patient.state];

  return (
    <div className="survey-question submit-appointment__calendly">
      <p className="survey-question__title">
        Schedule your initial visit below
      </p>
      <p className="survey-question__descr paragraph-font--color">
        Schedule a consultation with one of our licensed dermatology providers
      </p>
      <InlineWidget
        // url={widgetOptions.url}
        url={widgetUrl}
        utm={{
          utmCampaign: ENV_CONFIG.ENVIRONMENT,
          utmSource: patient.patientId,
        }}
        prefill={{
          email: patient.email,
          name: patient.fullName,
        }}
      />
    </div>
  );
}
