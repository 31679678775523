import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient, ShippingInfo } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const updateShippingInfo = async (
  patientId: string,
  shippingInfo: ShippingInfo
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/update-shipping-info`,
      {
        patientId,
        shippingInfo,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError("Error occured. Cannot update patient's shipping date");
    throw error;
  }
};
