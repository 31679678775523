import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import {
  EnrollmentCoordinator,
  EnrollmentCoordinatorEdit,
} from "../../../../../types/Entities/EnrollmentCoordinator";
import { AuthenticationService } from "../../../../cognito";

export const update = async (
  enrollmentCoordinator: EnrollmentCoordinatorEdit
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.put<EnrollmentCoordinator>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/enrollment-coordinators/update`,
      enrollmentCoordinator,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot update enrollment coordinator`);
    throw error;
  }
};
