import { ReactComponent as goodIcon } from "../../Assets/icons/good-icon.svg";
import { ReactComponent as okIcon } from "../../Assets/icons/OkIcon.svg";
import { ReactComponent as badIcon } from "../../Assets/icons/bad-icon.svg";

export default [
  {
    value: "Bad Day",
    img: badIcon,
  },
  {
    value: "Typical Day",
    img: okIcon,
  },
  {
    value: "Good Day",
    img: goodIcon,
  },
];
