export const checkIsAccutaneSource = (location, history) => {
  const locationSearch = new URLSearchParams(location.search);
  if (locationSearch.get("source") === "accutane") {
    locationSearch.delete("source");
    history.replace({
      ...location,
      search: locationSearch.toString(),
    });

    localStorage.setItem("isFromAccutaneSource", true);
  }
};
