import React, { useContext, useEffect } from "react";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { ActionsBlock } from "./actions";
import "./style.scss";
import { Skeleton } from "../../Common/Skeleton";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { NotificationLayerContext } from "../../Common/NotificationLayer";

type Props = {
  patientId: string;
};

export function WelcomeCard({ patientId }: Props) {
  const { data } = useGetPatientByIdQuery(patientId);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(patientId);
  const { showInfo } = useContext(NotificationLayerContext);

  useEffect(() => {
    if (subscriptionsQuery.data) {
      if (
        subscriptionsQuery.data.find(
          ({ status, type }) => type === "membership" && status === "incomplete"
        )
      ) {
        showInfo({
          title: "Processing...",
          description:
            "Thank you for signing up! Please give us a moment as we process your payment",
          duration: 30,
        });
      }
    }
  }, [subscriptionsQuery.data]);

  useEffect(() => {
    if (
      subscriptionsQuery.data &&
      !window.localStorage.getItem("referral-banner-shown")
    ) {
      window.localStorage.setItem("referral-banner-shown", "true");
      if (
        subscriptionsQuery.data.find(
          ({ status, type }) => type === "membership" && status === "active"
        )
      ) {
        showInfo({
          title: "NEW!",
          description: "Refer a friend, give two months, get two months free!",
          duration: 20,
        });
      }
    }
  }, [subscriptionsQuery.data]);

  return (
    <div className="welcome-card">
      <div className="welcome-card__text-block">
        <p className="welcome-card__title">
          {data ? <>Hi, {data.fullName.split(" ")[0]}!</> : <Skeleton />}
        </p>
        <p className="welcome-card__descr">
          {data ? <>Welcome to your skin care hub</> : <Skeleton />}
        </p>
      </div>
      <ActionsBlock patientId={patientId} />
    </div>
  );
}
