import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useState } from "react";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { setLoading } from "../../../../features/patientView";
import { InfoBlock } from "../../Common/InfoBlock";
import { StyledSelect, StyledSelectCreatable } from "../../Common/StyledSelect";
import { Button } from "../../Common/Button";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import {
  AdminsTypes,
  AssignTaskModalProps,
  IAssigneeOptions,
  IAssignTaskForm,
  PopupProps,
  Task,
} from "./types";
import {
  assigneeOptions,
  assignTaskModalOptions,
  mapStringToType,
} from "./utils";
import { getSessionState } from "../../../../features/session";
import {
  useGetCareTeamQuery,
  useGetPatientByIdQuery,
} from "../../../../features/api/patients";
import { useGetAllEmployeesQuery } from "../../../../features/api/employees";
import { USER_ROLES } from "../../../../types/Main";
import { Skeleton } from "../../Common/Skeleton";
import { useAssignTaskMutation } from "../../../../features/api/accutane";

function AssignTaskModalPopup({
  title,
  patientName,
  onClose,
  documentId,
  userId,
  employeeId,
}: AssignTaskModalProps) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const { userRole, userId: assignedById } = useSelector(getSessionState);
  const careTeamQuery = useGetCareTeamQuery(userId);
  const allEmployeesQuery = useGetAllEmployeesQuery(null);
  const [assignTask] = useAssignTaskMutation();
  const [shouldShowCreation, setShowCreation] = useState(false);
  const [tasksOptions, updateOptions] = useState(assignTaskModalOptions);
  const [assignTaskForm, setFormValue] = useState<IAssignTaskForm>({
    task: {
      label: "Review urine pregnancy test",
      value: "urinePregnancyTest",
    },
    assignee: null,
  });
  const [optionsLoading, setLoadingOptions] = useState(false);

  const taskSelectGroup = (data: any) => (
    <div>
      <span>{data.label.toUpperCase()}</span>
    </div>
  );

  const infoBlockText = (
    <span>
      You are about to assign a task for{" "}
      <span className="patient-documents__table-assign-task__popup-content__bold">
        {title}
      </span>{" "}
      document, connected to{" "}
      <span className="patient-documents__table-assign-task__popup-content__bold">
        {patientName}
      </span>{" "}
      (Patient)
    </span>
  );

  const handleTaskChange = (task: { label: string; value: string }) => {
    setFormValue({ ...assignTaskForm, task });
  };

  // todo add type
  const handleAssigneeChange = (assignee: any) => {
    setFormValue({ ...assignTaskForm, assignee });
  };

  const onAssign = async () => {
    if (assignTaskForm.assignee && assignedById && userRole) {
      const role: AdminsTypes = assignTaskForm.assignee.label
        .split(" ")
        .slice(-1)
        .join("")
        .slice(1, -1) as AdminsTypes;
      const result: any = await assignTask({
        message: (assignTaskForm.task as Task).label,
        type: (assignTaskForm.task as Task).value,
        createdAt: new Date().toISOString(),
        patientId: userId,
        employeeId: assignTaskForm.assignee?.value,
        documentId,
        assignedTo: {
          role: mapStringToType[role],
          name: assignTaskForm.assignee?.label.split(" ").slice(0, 2).join(" "),
          id: assignTaskForm.assignee?.value,
        },
        assignedBy: {
          id: assignedById,
          role: userRole,
        },
      });

      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to assign task",
        });
        return;
      }

      showSuccess({
        title: "Success",
        description: "Task successfully assigned",
      });
    }
    onClose();
  };

  const employeesOptions = allEmployeesQuery.data
    ?.filter(
      ({ role }) =>
        role === USER_ROLES.PROVIDER ||
        role === USER_ROLES.CARE_COORDINATOR ||
        role === USER_ROLES.ADMINISTRATOR
    )
    .filter(({ archived }) => !archived)
    .map(({ id, firstName, lastName, role }) => ({
      id,
      firstName,
      lastName,
      type: role,
      inCareTeam: !!careTeamQuery.data?.find(
        ({ employeeId: _employeeId }) => _employeeId === id
      ),
    }));

  return (
    <div className="patient-documents__table-assign-task__popup">
      <p className="patient-documents__table-assign-task__popup-title">
        Assign a task
      </p>
      <div className="patient-documents__table-assign-task__popup-content">
        <InfoBlock view="info" text={infoBlockText} />
        <div>
          <p className="patient-documents__table-assign-task__popup-content__item">
            Task
          </p>
          <StyledSelectCreatable
            formatCreateLabel={(inputValue: string) =>
              `${inputValue} (custom task)`
            }
            id="bloodworkSelect"
            name="bloodworkSelect"
            onChange={handleTaskChange}
            value={assignTaskForm.task}
            options={tasksOptions}
            createOptionPosition="first"
            filterOption={(option: any, searchText: any) => {
              if (!searchText) {
                setShowCreation(false);
                return true;
              }
              if (
                option.label.toLowerCase().includes(searchText.toLowerCase())
              ) {
                return true;
              }

              setShowCreation(true);
              return false;
            }}
            onCreateOption={(option) => {
              setLoadingOptions(true);
              const newTask = { label: option, value: option };
              setTimeout(() => {
                handleTaskChange(newTask);
                setLoadingOptions(false);
              }, 0);
              setShowCreation(true);
            }}
            isValidNewOption={() => shouldShowCreation}
            formatGroupLabel={taskSelectGroup}
            placeholder="Select a task"
            isSearchable
            disabled={optionsLoading}
          />
          <p className="patient-documents__table-assign-task__popup-content__item">
            Assignee
          </p>
          {employeesOptions ? (
            <StyledSelect
              id="assigneeSelect"
              name="assigneeSelect"
              options={assigneeOptions(employeesOptions)}
              onChange={handleAssigneeChange}
              placeholder="Select an assignee"
              isSearchable
            />
          ) : (
            <Skeleton count={3} fullWidth />
          )}
        </div>
      </div>
      <div className="patient-documents__table-assign-task__popup-control-buttons">
        <Button
          text="cancel"
          size="small"
          view="secondary"
          onClick={() => onClose()}
        />
        <Button text="assign" size="small" onClick={onAssign} />
      </div>
    </div>
  );
}

// todo refactor
export function AssignTaskModalPopupGenerator(
  title: string,
  patientName: string,
  documentId: string,
  userId: string,
  employeeId: string
) {
  return function render({ onClose }: PopupProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <AssignTaskModalPopup
          title={title}
          patientName={patientName}
          onClose={onClose}
          documentId={documentId}
          userId={userId}
          employeeId={employeeId}
        />
      </HelpPopupTemplate>
    );
  };
}
