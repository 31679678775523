import React, { useContext } from "react";
import OutsideClickHandler from "../../../Components/Common/OutsideClickHandler/OutsideClickHandler";
import "./style.scss";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { NotificationLayerContext } from "../NotificationLayer";

interface ChatMessagePopoverProps {
  messageId: string;
  conversationId: string;
  onClose: () => void;
  onMessageDelete: (messageId: string) => void;
}

function ChatMessagePopover({
  messageId,
  conversationId,
  onClose,
  onMessageDelete,
}: ChatMessagePopoverProps) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);

  const deleteDocument = async () => {
    try {
      onMessageDelete(messageId);
      onClose();
    } catch (e) {
      showError({
        title: "Error",
        description: "Error occurred when trying to delete message",
      });
    }
  };

  const options = [
    <p
      className="chat-message-popover__options-list__option"
      onClick={deleteDocument}
    >
      Delete document
    </p>,
  ];
  return (
    <div className="chat-message-popover">
      <OutsideClickHandler onClickOutside={onClose}>
        <div className="chat-message-popover__options-list">{options}</div>
      </OutsideClickHandler>
    </div>
  );
}

export default ChatMessagePopover;
