import React, { Fragment } from "react";
import "./style.scss";

interface RadioButtonProps {
  value: string | number | boolean;
  label: string;
  onClick: (value: string | number | boolean) => void;
  selected?: boolean;
  extra?: JSX.Element;
  centered?: boolean;
  when?: boolean;
}

interface OptionElement {
  value: string | number | boolean;
  label: string;
  extra?: JSX.Element;
  centered?: boolean;
}

interface Props {
  heading?: string;
  list: OptionElement[];
  currentValue?: string | number | boolean;
  customStyle?: boolean;
  onChange: (value: string | number | boolean) => void;
  size?: "small" | "big";
  className?: string;
  accutaneStatus?: string;
}

function RadioButtonItem({
  value,
  label,
  onClick,
  selected,
  extra,
  centered,
  when,
}: RadioButtonProps) {
  return (
    when && (
      <div
        className={`radio-buttons-form__item ${selected ? "selected" : ""} ${
          centered ? "centered" : ""
        }`}
        onClick={() => onClick(value)}
      >
        <div className="radio-buttons-form__option-wrapper">
          <div className="radio-buttons-form__circle" />
          <p className="radio-buttons-form__text">{label}</p>
        </div>
        {extra && (
          <div className="radio-buttons-form__item__extra-content">{extra}</div>
        )}
      </div>
    )
  );
}

export function RadioButtonsForm({
  heading,
  list,
  currentValue,
  onChange,
  className,
  customStyle = false,
  size = "big",
  accutaneStatus = "renew",
}: Props) {
  return (
    <>
      <span
        className="text-input__label label-font label-font--color"
        style={{ marginLeft: "20px" }}
      >
        {heading}
      </span>
      <div
        className={`${
          customStyle
            ? "custom-radio-buttons-form"
            : `radio-buttons-form radio-buttons-form--${size}`
        }  ${className || ""}`}
      >
        {list.map(({ value: _value, label, extra, centered, when }) => (
          <RadioButtonItem
            value={_value}
            label={label}
            onClick={(newValue: string | number | boolean) =>
              onChange(newValue)
            }
            selected={_value === currentValue}
            extra={extra}
            centered={centered}
            key={`radio-key-${_value}`}
            when={accutaneStatus !== when}
          />
        ))}
      </div>
    </>
  );
}
