import { useGetDocumentsByPatientIdQuery } from "../../features/api/accutane";

export const getGovtIDDocuments = (patientId) => {
  const documentsQuery = useGetDocumentsByPatientIdQuery(patientId as string);

  const governmentIdDocuments = documentsQuery.data?.filter(
    (document) => document.type === "governmentIssuedId"
  );

  return governmentIdDocuments || [];
};
