import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

export const getAccessToken = async (identity: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<
      GraphQLResponse<{ getChatAccessToken: string }>
    >(`${ENV_CONFIG.CHATS_SERVICE_GRAPHQL_URL}`, {
      headers: {
        Authorization: token,
      },
      params: {
        query: `query { getChatAccessToken(userId: "${identity}") }`,
      },
    });

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.getChatAccessToken;
  } catch (error: any) {
    logError("Error occured. Cannot get chat access token", error);
    throw error;
  }
};
