import React from "react";
import "./style.scss";

interface Props {
  max: number;
  current: number;
}

export function Stepper({ max, current }: Props) {
  function render() {
    const elements = [];
    for (let i = 0; i <= max; i++) {
      elements.push(
        <div
          className={`stepper__item ${
            i <= current ? "stepper__item--highlight" : ""
          }`}
        />
      );
    }
    return elements;
  }

  return <div className="stepper">{render()}</div>;
}
