import { Paginator } from "@twilio/conversations";

export async function getAllTwilioElements<T>(
  payload: Promise<Paginator<T>>
): Promise<T[]> {
  const step = await payload;

  if (step.hasNextPage) {
    return [...step.items, ...(await getAllTwilioElements(step.nextPage()))];
  }

  return step.items;
}
