import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import { ImageBlob } from "../../../../../types/Base/ImageBlob";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { AcneFeelingsPicker } from "../../../../Components/Patient/AcneFeelingsPicker";
import { Button } from "../../../../NewComponents/Common/Button";
import { ImageUploadTile } from "../../../../NewComponents/Patient/ImageUploadTile";
import { NotificationLayerContext } from "../../../../NewComponents/Common/NotificationLayer";
import { getSessionState } from "../../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../../features/api/patients";
import { useGetFollowUpsByPatientIdQuery } from "../../../../../features/api/follow-ups";
import { StyledSelect } from "../../../../NewComponents/Common/StyledSelect";
import { skinImageTypes } from "../../SkinSurvey/survey-questions/skin-image-types";

type ImageItem = Blob | ImageBlob | null;

type SkinImageSide = "left" | "center" | "right";

const predefinedImagesInputs: {
  image: ImageItem;
  side: SkinImageSide;
  title: string;
}[] = [
  {
    image: null,
    side: "left",
    title: "Left profile",
  },
  {
    image: null,
    side: "center",
    title: "Center profile",
  },
  {
    image: null,
    side: "right",
    title: "Right profile",
  },
];

export function SkinImagesFollowUpSurveyQuestion({
  onDone,
}: SurveyComponentProps) {
  const { showError } = useContext(NotificationLayerContext);
  const [skinImages, setSkinImages] = useState<typeof predefinedImagesInputs>();
  // JSON.parse(JSON.stringify(predefinedImagesInputs))
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const skinSurvey = patientQuery?.data?.medicalBackground.skinSurvey;

  const skinConditionTypeTextConversion = (type: string) => {
    switch (type) {
      case "hairLoss":
        return "Hair Loss";
        break;
      case "hidradenitisSuppurativa":
        return "Hidradenitis Supporativa";
        break;
      case "tineaVersicolor":
        return "Tinea Versicolor";
        break;
      case "nailFungus":
        return "Nails Fungus";
        break;
      default:
        return "Acne";
        break;
    }
  };

  const skinConditionType = skinSurvey?.find(
    (item) => item.id === "skinIssueType"
  )?.value
    ? skinSurvey?.find((item) => item.id === "skinIssueType")?.value
    : "acne";

  const patientFollowUps: any = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    { skip: !activePatientId }
  );

  const [acneFeelings, setAcneFeelings] = useState<string | null>(null);
  const [defaultSkinArea, setDefaultSkinArea] = useState([]);
  const [selectedSkinTypes, setSelectedSkinTypes] = useState<string[]>([]);
  const [hasImageNotSelected, setHasImageNotSelected] = useState(false);
  const [allImagesUploaded, setAllImagesUploaded] = useState(true);
  const [previousSelectedOptions, setPreviousSelectedOptions] =
    useState(selectedSkinTypes);

  useEffect(() => {
    const setDefaultSkinAreas = (images: any) => {
      const tempArray: any = [];
      if (images) {
        images.forEach((img) => {
          if (img.type !== null && !tempArray.includes(img.type)) {
            tempArray.push(img.type);
          }
        });
      }
      return tempArray;
    };
    const getTypes = setDefaultSkinAreas(patientFollowUps.data[0].images);
    setDefaultSkinArea(getTypes);
  }, []);

  useEffect(() => {
    if (skinConditionType !== "nailFungus")
      if (defaultSkinArea && defaultSkinArea.length > 0) {
        const filteredSkinImages = defaultSkinArea.map((area) => {
          const skinImageType = skinImageTypes.find(
            (obj) => obj.value === area
          );
          return skinImageType
            ? JSON.parse(JSON.stringify(skinImageType.images))
            : null;
        });
        const flattenedSkinImages = filteredSkinImages.flatMap(
          (innerArray) => innerArray
        );

        setSkinImages(flattenedSkinImages);
        setSelectedSkinTypes(defaultSkinArea);
      } else {
        setSkinImages(JSON.parse(JSON.stringify(skinImageTypes[0]?.images)));
        setSelectedSkinTypes(["face"]);
      }
  }, [defaultSkinArea]);

  useEffect(() => {
    if (skinImages) {
      const isAnyImageNull = skinImages.some(
        (obj) => obj.image === null && Object.keys(obj).length !== 0
      );
      setAllImagesUploaded(isAnyImageNull);
    }
  }, [skinImages]);

  async function submit() {
    if (skinImages.find(({ image }) => !image)) {
      showError({
        title: "Images uploading error",
        description:
          "Some images weren't loaded by you. Please, add remaining images of your skin and submit them again",
      });
      return;
    }
    if (!acneFeelings) {
      showError({
        title: "Images uploading error",
        description: `Please, set your feelings about your ${skinConditionType}`,
      });
      return;
    }

    await onDone({
      skinImages,
      acneFeelings,
    });
    sendSurveyQuestion("skinImages");
  }

  function handleSkinTypeChange(selectedOptions) {
    setSelectedSkinTypes(selectedOptions.map((option) => option.value));

    if (selectedOptions.length === 0) {
      setHasImageNotSelected(true);
    } else {
      setHasImageNotSelected(false);
    }

    // Create a map of existing images by their side
    const existingImagesMap = new Map(
      skinImages.map((image) => [image.side, image])
    );

    // Merge existing images with selected options
    const selectedImages = selectedOptions.flatMap((option) =>
      (option.images || []).map((img) => ({
        ...img,
        image: existingImagesMap.get(img.side)?.image || img.image,
      }))
    );

    console.log("selectedImages", selectedImages);

    setSkinImages(selectedImages);
    setPreviousSelectedOptions(selectedOptions);
  }

  function setImage(image: ImageItem, index: number, imgSide: string) {
    skinImages?.forEach((skin) => {
      if (skin.side === imgSide) {
        skin.image = image;
      }
    });
    setSkinImages(Array.from(skinImages));
  }

  const filterSkinTypesForNailFungus = () => {
    if (skinConditionType === "nailFungus") {
      return skinImageTypes.filter(
        (type) => type.value === "hand" || type.value === "foot"
      );
    }
    return skinImageTypes;
  };

  return (
    <div className="survey-question survey-page__skin-images-wrap">
      <p className="survey-question__title">Last step! Let's see your skin</p>
      <p className="survey-question__descr paragraph-font--color">
        Take or upload photos of your skin.
      </p>

      <p className="survey-page__input-title">AFFECTED skin area(s):</p>
      <StyledSelect
        isMulti
        id="skinImage"
        name="skinImage"
        options={filterSkinTypesForNailFungus()}
        onChange={handleSkinTypeChange}
        value={skinImageTypes.filter((option) =>
          selectedSkinTypes.includes(option.value)
        )}
        isClearable={false}
        styles={{
          multiValueLabel: (base) => ({
            ...base,
            maxWidth: "150px", // Set the maximum width for the tags
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "#FFFFFF",
            padding: "6px",
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: "#FFFFFF",
          }),
          control: (provided, state) => ({
            ...provided,
            outline: "none", // Remove the blue border on focus
            boxShadow: state.isFocused
              ? "0 0 0 2px lightgrey"
              : provided.boxShadow,
            borderColor: state.isFocused ? "lightgrey" : provided.borderColor,
            borderRadius: "80px",
            padding: "6px",
          }),
        }}
      />
      <p className="survey-page__input-text">
        Select the key area(s) you’d like your provider to review
      </p>

      <div className="survey-page__skin-images survey-page__skin-images--margin">
        {skinImages &&
          skinImages?.map(({ title, side }, index) => (
            <ImageUploadTile
              key={side}
              setImage={(imageBlob) => setImage(imageBlob, index, side)}
              side={side}
              sidePlaceholder={title}
            />
          ))}
      </div>
      <p className="survey-question__descr paragraph-font--color">
        Do the photos you submitted represent a good day, typical day, or bad
        day for your {skinConditionTypeTextConversion(skinConditionType)}?
      </p>
      <AcneFeelingsPicker onChange={(value) => setAcneFeelings(value)} />
      <Button
        text="submit images"
        onClick={submit}
        className="survey-question__button survey-question__button--short"
        disabled={allImagesUploaded || hasImageNotSelected || !acneFeelings}
      />
    </div>
  );
}
