import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getCareCoordinatorsInfoState,
  updateCareCoordinator,
} from "../../../../features/careCoordinators";
import {
  getEnrollmentCoordinatorsInfoState,
  updateEnrollmentCoordinator,
} from "../../../../features/enrollmentCoordinators";
import { show } from "../../../../features/errorNotification";
import {
  getProvidersInfoState,
  updateProvider,
} from "../../../../features/providers";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import {
  CareCoordinator,
  CareCoordinatorEdit,
} from "../../../../types/Entities/CareCoordinator";
import { Employee, EmployeeEdit } from "../../../../types/Employee";
import {
  EnrollmentCoordinator,
  EnrollmentCoordinatorEdit,
} from "../../../../types/Entities/EnrollmentCoordinator";
import { Provider, ProviderEdit } from "../../../../types/Entities/Provider";
import "./style.css";

const types: { [p: string]: { value: string; friendly: string } } = {
  provider: {
    value: "provider",
    friendly: "Provider",
  },
  careCoordinator: {
    value: "careCoordinator",
    friendly: "Care Coordinator",
  },
  enrollmentCoordinator: {
    value: "enrollmentCoordinator",
    friendly: "Enrollment Coordinator",
  },
};

function getStateSelector(type: string) {
  switch (type) {
    case "provider":
      return getProvidersInfoState;
    case "careCoordinator":
      return getCareCoordinatorsInfoState;
    case "enrollmentCoordinator":
      return getEnrollmentCoordinatorsInfoState;
    default:
      throw new Error(`Unrecognized employee type "${type}"`);
  }
}

interface Props {
  type: string;
}

export function EditEmployee({ type }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { list } = useSelector(getStateSelector(type));
  const { id: employeeId } = useParams();
  const employee = list.find(({ id }) => id === employeeId);
  if (!employee) {
    logError(`Cannot find employee with type "${type}" and ID "${employeeId}"`);
    return null;
  }
  const [title, setTitle] = useState((employee as Provider)?.title || "");
  const [firstName, setFirstName] = useState(employee?.firstName || "");
  const [lastName, setLastName] = useState(employee?.lastName || "");
  const [phone, setPhone] = useState(employee?.phone || "");

  function getFormData() {
    switch (type) {
      case types.provider.value:
        return {
          id: employeeId,
          title,
          firstName,
          lastName,
          phone,
          image: (employee as Provider).image,
        };
      case types.careCoordinator.value:
      case types.enrollmentCoordinator.value:
        return {
          id: employeeId,
          firstName,
          lastName,
          phone,
        };
      default:
        const message = `Unrecognized employee type "${type}:`;
        logError(message);
        throw new Error(message);
    }
  }

  async function update(data: EmployeeEdit) {
    switch (type) {
      case types.provider.value:
        return await AcneAwayAPI.providers.update(data as ProviderEdit);
      case types.careCoordinator.value:
        return await AcneAwayAPI.careCordinators.update(
          data as CareCoordinatorEdit
        );
      case types.enrollmentCoordinator.value:
        return await AcneAwayAPI.enrollmentCoordinators.update(
          data as EnrollmentCoordinatorEdit
        );
      default:
        const message = `Unrecognized employee type "${type}:`;
        logError(message);
        throw new Error(message);
    }
  }

  function updateEmployeeToState(_employee: Employee) {
    switch (type) {
      case types.provider.value:
        return updateProvider(_employee as Provider);
      case types.careCoordinator.value:
        return updateCareCoordinator(_employee as CareCoordinator);
      case types.enrollmentCoordinator.value:
        return updateEnrollmentCoordinator(_employee as EnrollmentCoordinator);
      default:
        const message = `Unrecognized employee type "${type}:`;
        logError(message);
        throw new Error(message);
    }
  }

  async function submit(event: FormEvent) {
    try {
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = getFormData();
      const result = await update(data);
      dispatch(updateEmployeeToState(result));
      history.push("/employees");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let message;
      if ("response" in error) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }

      logError(message);
      dispatch(show(message));
    }
  }

  function drawFields() {
    switch (type) {
      case types.provider.value:
        return (
          <>
            <div className="create-employee__field-block">
              <label htmlFor="phone" className="create-employee__label">
                Phone Number
              </label>
              <input
                className="create-employee__field"
                id="phone"
                onChange={(event) => setPhone(event.target.value)}
                name="phone"
                value={phone}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="title" className="create-employee__label">
                License Initials
              </label>
              <input
                className="create-employee__field"
                id="title"
                onChange={(event) => setTitle(event.target.value)}
                name="title"
                value={title}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="firstName" className="create-employee__label">
                First Name
              </label>
              <input
                className="create-employee__field"
                id="firstName"
                onChange={(event) => setFirstName(event.target.value)}
                name="firstName"
                value={firstName}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="lastName" className="create-employee__label">
                Last Name
              </label>
              <input
                className="create-employee__field"
                id="lastName"
                onChange={(event) => setLastName(event.target.value)}
                name="lastName"
                value={lastName}
              />
            </div>
          </>
        );
      case types.careCoordinator.value:
      case types.enrollmentCoordinator.value:
        return (
          <>
            <div className="create-employee__field-block">
              <label htmlFor="phone" className="create-employee__label">
                Phone Number
              </label>
              <input
                className="create-employee__field"
                id="phone"
                onChange={(event) => setPhone(event.target.value)}
                name="phone"
                value={phone}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="firstName" className="create-employee__label">
                First Name
              </label>
              <input
                className="create-employee__field"
                id="firstName"
                onChange={(event) => setFirstName(event.target.value)}
                name="firstName"
                value={firstName}
              />
            </div>
            <div className="create-employee__field-block">
              <label htmlFor="lastName" className="create-employee__label">
                Last Name
              </label>
              <input
                className="create-employee__field"
                id="lastName"
                onChange={(event) => setLastName(event.target.value)}
                name="lastName"
                value={lastName}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <div className="greeting-container">
      <div className="create-employee">
        <form className="create-employee__form" onSubmit={submit}>
          <div className="create-employee__head">
            <p className="create-employee__title">
              Edit {types[type].friendly}
            </p>
          </div>
          <div className="create-employee__body">{drawFields()}</div>
          <input
            type="submit"
            name="submit"
            className="create-employee__submit"
          />
        </form>
      </div>
    </div>
  );
}
