import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "../../../NewComponents/Common/Collapse";
import CollapseSection from "../../../NewComponents/Common/Collapse/CollapseSection";
import { Patient } from "../../../../types/Entities/Patient";
import {
  mapPatientInfoToDashboard,
  MapUserInfoToDashboard,
} from "../../../../utils/map-user-info-to-dashboard";
import { showEditPatientInfoPopup } from "../../../../features/editPatientInfo";
import PatientNotes from "../../../NewComponents/Common/PatientNotes";
import "./style.scss";
import { ReactComponent as EditIcon } from "../../../../Assets/NewIcons/pencil.svg";
import { getSessionState } from "../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import camelizeText from "../../../../utils/camelize-text";
import { skinIssues } from "../../../NewPages/Patient/SkinSurvey/skin-issues";
import { Select } from "../../../NewComponents/Common/Select";
import { EditSkinInfoPopup } from "../EditSkinInfoPopup";
import { showEditSkinInfoPopup } from "../../../../features/editSkinInfo";
import { EditPatientInfoPopup } from "../EditPatientInfoPopup";

function TreatmentHistory({
  editable = true,
  hideNotes = false,
  showCollapseIcon = true,
  extraItems = [],
  className,
  isCollapsible = true,
}: {
  editable?: boolean;
  hideNotes?: boolean;
  showCollapseIcon?: boolean;
  extraItems?: MapUserInfoToDashboard[];
  className?: string;
  isCollapsible?: boolean;
}) {
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const skinSurveyExists = patientQuery?.data?.medicalBackground?.skinSurvey;
  const [isSkinSurveyEmptyOrNull, setIsSkinSurveyEmptyOrNull] = useState(null);

  useEffect(() => {
    setIsSkinSurveyEmptyOrNull(
      !skinSurveyExists || skinSurveyExists.length === 0
    );
  }, [skinSurveyExists]);

  const defaultItems = [
    {
      keyword: "skinIssueType",
      title: "Skin Type",
      items: [
        {
          article: "Click edit icon to select a skin type",
          description: " ",
          key: "skinIssueType",
        },
      ],
    },
  ];

  const dispatch = useDispatch();

  return (
    <div className={`treatment-history ${className}`}>
      <EditSkinInfoPopup />
      {!patientQuery.isSuccess || patientQuery.isLoading ? (
        <Skeleton
          column
          flex
          count={4}
          fullHeight
          fullWidth
          style={{
            height: 200,
          }}
        />
      ) : (
        <div className="treatment-history__items">
          {isSkinSurveyEmptyOrNull
            ? defaultItems.map((el) => (
                <Collapse
                  title={camelizeText(el.title)}
                  key={el.keyword}
                  openedByDefault
                  showCollapseIcon={showCollapseIcon}
                  isCollapsible={isCollapsible}
                  extra={
                    editable ? (
                      <div className="edit-button">
                        <EditIcon
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            dispatch(showEditSkinInfoPopup(el.keyword));
                          }}
                        />
                      </div>
                    ) : undefined
                  }
                >
                  <div>
                    {el.items.map((it) => (
                      <CollapseSection
                        article={it.article}
                        description={it.description}
                        key={it.key}
                      />
                    ))}
                  </div>
                </Collapse>
              ))
            : extraItems
                .concat(mapPatientInfoToDashboard(patientQuery.data))
                .map((el) => (
                  <Collapse
                    title={camelizeText(el.title)}
                    key={el.keyword}
                    openedByDefault
                    showCollapseIcon={showCollapseIcon}
                    isCollapsible={isCollapsible}
                    extra={
                      editable ? (
                        <div className="edit-button">
                          <EditIcon
                            onClick={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              dispatch(showEditPatientInfoPopup(el.keyword));
                            }}
                          />
                        </div>
                      ) : undefined
                    }
                  >
                    <div>
                      {el.items.map((it) => (
                        <CollapseSection
                          article={it.article}
                          description={it.description}
                          key={it.key}
                        />
                      ))}
                    </div>
                  </Collapse>
                ))}
        </div>
      )}
      {!hideNotes && (
        <div className="patient-notes-desktop">
          <PatientNotes />
        </div>
      )}
    </div>
  );
}

export default TreatmentHistory;
