import { logInfo } from "../../../shared/logger";
import { ShowNotificationProps } from "../../NewComponents/Common/NotificationLayer";

interface HolidayRecord {
  key: string;
  title: string;
  day: number;
  month: number;
}

// Dates last updated 8/29/2024
// TODO: Improve this so that these don't need to be updated every year: HD-1176
const holidaysList: HolidayRecord[] = [
  {
    key: "new-year-day", // 2025
    title: "New Year's Day",
    day: 1,
    month: 1,
  },
  {
    key: "martin-luther-king-day", // 2025
    title: "Martin Luther King Day",
    day: 20,
    month: 1,
  },
  {
    key: "presidents-day", // 2025
    title: "Presidents' Day",
    day: 17,
    month: 2,
  },
  {
    key: "memorial-day", // 2025
    title: "Memorial Day",
    day: 26,
    month: 5,
  },
  {
    key: "juneteenth", // 2025
    title: "Juneteenth",
    day: 19,
    month: 6,
  },
  {
    key: "independence-day", // 2025
    title: "Independence Day",
    day: 4,
    month: 7,
  },
  {
    key: "labor-day", // 2024
    title: "Labor Day",
    day: 2,
    month: 9,
  },
  {
    key: "columbus-day", // 2024
    title: "Columbus Day",
    day: 14,
    month: 10,
  },
  {
    key: "veterans-day", // 2024
    title: "Veterans Day",
    day: 11,
    month: 11,
  },
  {
    key: "thanksgiving-day", // 2024
    title: "Thanksgiving Day",
    day: 28,
    month: 11,
  },
  {
    key: "christmas-day", // 2024
    title: "Christmas Day",
    day: 25,
    month: 12,
  },
];

function createDate(year: number, month: number, day: number) {
  return new Date(
    `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
  );
}

export function processHolidays(
  showInfo: (props: ShowNotificationProps) => void
) {
  holidaysList.forEach(({ key, title, day, month }) => {
    const now = new Date();
    const year = now.getFullYear();

    const holidayDate = createDate(year, month, day);

    const isNotified = !!window.localStorage.getItem(`${key}-${year}`);

    // Start showing banner 3 days before holiday
    const from = new Date(holidayDate);
    from.setDate(from.getDate() - 3);
    from.setHours(0, 0, 0, 0);

    // Show the banner through the entire holiday
    const to = new Date(holidayDate);
    to.setDate(to.getDate());
    to.setHours(23, 59, 59, 999);

    // TODO: extend the `to` to the following Monday if the holiday is a Friday (or also if it is Thanksgiving Thursday): HD-1177

    logInfo(key, {
      from,
      to,
    });

    if (!isNotified && now >= from && now <= to) {
      showInfo({
        title: "Information regarding holidays",
        description: `Please be advised that there might be delayed response times due to ${title}`,
        duration: 100,
      });

      window.localStorage.setItem(`${key}-${year}`, "true");
    }
  });
}
