import React, { useEffect, useRef, useState } from "react";
import { isNull } from "util";
import {
  PatientsTableChipsEntity,
  PatientTableEntity,
} from "../../../../types/Table";
import { Skeleton } from "../Skeleton";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { logError } from "../../../../shared/logger";

interface Props {
  chips: PatientsTableChipsEntity[];
  onChange: (value: number) => void;
  initial: number;
}

type TableChipProps = {
  chip: PatientsTableChipsEntity;
  count: number | null;
  onClick: () => void;
  checked: boolean;
};

function TableChip({ chip, count, onClick, checked }: TableChipProps) {
  return (
    <div
      className={`patients-table__chip-element ${checked ? "selected" : ""}`}
      onClick={onClick}
    >
      {chip.text} •{" "}
      {count === null ? <Skeleton style={{ width: 10 }} /> : count}
    </div>
  );
}

export function PatientsTableChips({ chips, onChange, initial }: Props) {
  const [index, setIndex] = useState(initial);
  const [chipsCounts, setChipsCounts] = useState<(number | null)[]>(
    chips.map(() => null)
  );
  useEffect(() => {
    (async () => {
      setChipsCounts(chips.map(() => null));
      const response = await HoneydewAPI.dashboard.countDashboardItems(
        chips.map(({ text, filter }) => ({
          label: text,
          query: {
            query: {
              bool: filter,
            },
          },
        }))
      );
      setChipsCounts(chips.map(({ text }) => response[text]?.count ?? null));
    })();
  }, [chips]);

  useEffect(() => {
    onChange(index);
  }, [index]);

  useEffect(() => {
    if (initial !== index) setIndex(initial);
  }, [initial]);

  useEffect(() => {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    if (!isNull(currentIndexFormatted)) {
      setIndex(currentIndexFormatted.currentSubTab);
    }
  });

  const handleChipClick = (i: number) => {
    setIndex(i);
    const myState = { currentSubTab: i };
    localStorage.setItem("currentIndex", JSON.stringify(myState));
    localStorage.setItem("currentPage", JSON.stringify(0));
  };

  return (
    <>
      {chips.map((chip, i) => (
        <TableChip
          chip={chip}
          count={chipsCounts[i]}
          onClick={() => handleChipClick(i)}
          checked={i === index}
        />
      ))}
    </>
  );
}
