export const tineaVersicolorSubQuestions = [
  {
    label: "How long have you suffered from Tinea Versicolor?",
    value: "tineaVersicolorPeriod",
    questionType: "tineaVersicolor",
    options: [
      {
        value: "A few months",
        label: "A few months",
      },
      {
        value: "1-2 years",
        label: "1-2 years",
      },
      {
        value: "3+ years",
        label: "3+ years",
      },
    ],
  },
  {
    label: "What areas of your body are covered with spots?",
    value: "tineaVersicolorAreas",
    questionType: "tineaVersicolor",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Chest",
        label: "Chest",
      },
      {
        value: "Abdomen",
        label: "Abdomen",
      },
      {
        value: "Back",
        label: "Back",
      },
      {
        value: "Neck",
        label: "Neck",
      },
      {
        value: "Face",
        label: "Face",
      },
      {
        value: "Arms",
        label: "Arms",
      },
    ],
  },
  {
    label:
      "Do your spots come out seasonally (Spring/Summer) or do they appear year-round?",
    value: "tineaVersicolorSeasonal",
    questionType: "tineaVersicolor",
    options: [
      {
        value: "Seasonally",
        label: "Seasonally",
      },
      {
        value: "Year-round",
        label: "Year-round",
      },
    ],
  },
  {
    label:
      "What kind of products have you tried for Tinea Versicolor in the past?",
    value: "previousTineaVersicolorProductsTaken",
    questionType: "tineaVersicolor",
    checkboxesList: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Over the counter products (e.g. cleansers or moisturizers)",
        label: "Over the counter products (e.g. cleansers or moisturizers)",
      },
      {
        value: "Prescription creams",
        label: "Prescription creams",
      },
      {
        value: "Prescription pills",
        label: "Prescription pills",
      },
      {
        value: "I haven’t tried any products",
        label: "I haven’t tried any products",
        onlySelectable: true,
      },
    ],
  },
  {
    label: "What over the counter creams have you tried?",
    value: "tineaVersicolorOtcCreams",
    questionType: "tineaVersicolor",
    parentQuestion: "previousTineaVersicolorProductsTaken",
    parentAnswer: "Over the counter products (e.g. cleansers or moisturizers)",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Selsun blue",
        label: "Selsun blue",
      },
      {
        value: "Head and Shoulders",
        label: "Head and Shoulders",
      },
      {
        value: "Lamisil cream",
        label: "Lamisil cream",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "What prescription creams have you tried?",
    value: "tineaVersicolorPrescriptionCreams",
    questionType: "tineaVersicolor",
    parentQuestion: "previousTineaVersicolorProductsTaken",
    parentAnswer: "Prescription creams",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Ketaconazole cream",
        label: "Ketaconazole cream",
      },
      {
        value: "Oxiconazole cream",
        label: "Oxiconazole cream",
      },
      {
        value: "Econazole cream",
        label: "Econazole cream",
      },
      {
        value: "Ketaconazole Shampoo",
        label: "Ketaconazole Shampoo",
      },
      {
        value: "Ciclopirox Shampoo",
        label: "Ciclopirox Shampoo",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label: "What prescription orals have you tried?",
    value: "tineaVersicolorPrescriptionOrals",
    questionType: "tineaVersicolor",
    parentQuestion: "previousTineaVersicolorProductsTaken",
    parentAnswer: "Prescription pills",
    checkboxesList: true,
    isArrayOfAnswer: true,
    description: "You can select multiple options from the list",
    options: [
      {
        value: "Itraconazole (Sporanox)",
        label: "Itraconazole (Sporanox)",
      },
      {
        value: "Lamisil",
        label: "Lamisil",
      },
      {
        value: "Diflucan",
        label: "Diflucan",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  {
    label:
      "Are you currently using any prescription products for Tinea Versicolor?",
    value: "tineaVersicolorCurrentlyUsingPrescriptionProducts",
    questionType: "tineaVersicolor",
    parentQuestion: "previousTineaVersicolorProductsTaken",
    parentTextAnwser: "I haven’t tried any products",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Are there specific medications you are interested in?",
    value: "tineaVersicolorSpecificMedicationsInterest",
    questionType: "tineaVersicolor",
    parentQuestion: "previousTineaVersicolorProductsTaken",
    parentTextAnwser: "I haven’t tried any products",
    radioButtonsForm: true,
    placeholder: "Which medications?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, let’s see what my provider recommends",
      },
    ],
  },
  {
    label:
      "Are you taking any other medications/pills for any other medical condition?",
    value: "otherMedications",
    questionType: "otherMedical",
    placeholder: "Which medications?",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "Do you have any allergies to prescription medications?",
    value: "allergiesToPrescriptionMedications",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "Which allergies?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label:
      "Is there anything else we should know? (e.g. other medical conditions)",
    value: "otherInformation",
    questionType: "otherMedical",
    radioButtonsForm: true,
    placeholder: "e.g. other medical conditions",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  {
    label: "What is your sex assigned at birth?",
    value: "gender",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ],
  },
];
