import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

interface Props {
  archived?: boolean;
}

export const getAll = async (props?: Props) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<Patient[]>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/all${
        props?.archived ? "?includeArchived=true" : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get users list", error);
    throw error;
  }
};
