import React from "react";
import { PatientsTablePagination } from "../../../NewComponents/Common/PatientsTable/tablePagination";
import { ColumnsConfig, TableConfig, TableProps } from "./types";
import "./style.scss";
import "../../../NewComponents/Common/PatientsTable/style.scss";

function TableHeader({ columns }: { columns: ColumnsConfig[] }) {
  const headerColumns = columns.map(({ header, maxWidth, width }) => ({
    header,
    maxWidth,
    width,
  }));

  return (
    <thead>
      <tr className="table-header">
        {headerColumns.map((cell) => (
          <th
            className="table-header__cell"
            key={cell.header || "actions"}
            style={{
              width: cell.width ? cell.width : "",
              maxWidth: cell.maxWidth ? cell.maxWidth : "",
            }}
          >
            {cell.header}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function TableBody({ data, columns, onRowClick }: TableConfig) {
  return (
    <tbody className="table-body">
      {data.map((cell) => (
        <tr className="table-body-row" key={cell.key} onClick={onRowClick}>
          {columns.map((column) => {
            if (column.render) {
              return (
                <td
                  className="table-body-row__cell"
                  key={column.key}
                  onClick={() => {
                    if (column.onClick) {
                      column.onClick(cell);
                    }
                  }}
                >
                  {column.render
                    ? column.render(cell[column.dataIndex], cell, cell.key)
                    : cell[column.dataIndex]}
                </td>
              );
            }
            return (
              <td
                className="table-body-row__cell"
                key={column.key}
                onClick={() => {
                  if (column.onClick) {
                    column.onClick(cell);
                  }
                }}
              >
                {cell[column.dataIndex]}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
}

function Table({
  data,
  columns,
  pagination,
  className,
  onRowClick,
}: TableProps) {
  return (
    <div className="custom-table">
      <div className="table-wrapper">
        <table className={`table ${className || ""}`}>
          <TableHeader columns={columns} />
          {data ? (
            <TableBody columns={columns} data={data} onRowClick={onRowClick} />
          ) : null}
        </table>
      </div>
      {pagination && (
        <PatientsTablePagination
          max={pagination.maxPage}
          current={pagination.currentPage}
          onChange={pagination.onPageChange}
        />
      )}
    </div>
  );
}

export default Table;
