// export const MedicineMap = {
//   "benzoylPeroxide": {
//     "name": "Benzoyl Peroxide",
//     "strength": "10%",
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply to face for 10 seconds and wash off before topical prescriptions",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "benzamycinGel": {
//     "name": "Benzamycin gel",
//     "strength": "3% erythromycin / 5% benzoyl peroxide ",
//     "size": "46.6 g",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply to affected areas in morning and before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "sulfurCleanser": {
//     "name": "Sulfur Cleanser",
//     "strength": "10/5",
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply to face for 10 seconds and wash off before topical prescriptions",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "adapalene": {
//     "name": "Adapalene gel",
//     "strength": "0.1",
//     "size": "45 g",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "adapaleneStrong": {
//     "name": "Adapalene gel",
//     "strength": "0.3",
//     "size": "45 g",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "tretinoin": {
//     "name": "Tretinoin cream",
//     "strength": "0.025%",
//     "size": "20 g",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "tretionoinBig": {
//     "name": "Tretinoin cream",
//     "strength": "0.025%",
//     "size": "45 g",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "tretinoinStrong": {
//     "name": "Tretinoin cream",
//     "strength": "0.05%",
//     "size": "20 g",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "moisturizer": {
//     "name": "Moisturizer",
//     "strength": null,
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply after topical prescription medication dries",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "clindamycinGel": {
//     "name": "Clindamycin Gel",
//     "strength": "1%",
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply to face in morning and before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "clindamycinPledglets": {
//     "name": "Clindamycin Pledglets",
//     "strength": "1%",
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply one wipe evenly to face",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "clindamycinLotion": {
//     "name": "Clindamycin Lotion",
//     "strength": "1%",
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply to face in morning and before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "benzaclin": {
//     "name": "Benzaclin",
//     "strength": "1% clinda/5% BP",
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply to face in morning and before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "dapsone": {
//     "name": "Dapsone",
//     "strength": "5%",
//     "size": null,
//     "instructions": [
//       "morning",
//     ],
//     "specialInstructions": "Apply a thin layer to face and rub in until disappears",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "dapsoneStrong": {
//     "name": "Dapsone",
//     "strength": "7.5%",
//     "size": null,
//     "instructions": [
//       "morning",
//     ],
//     "specialInstructions": "Apply a thin layer to face and rub in until disappears",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "erythromycin": {
//     "name": "Erythromycin pads",
//     "strength": "2%",
//     "size": null,
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply a thin layer to face and rub in until disappears",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "tazarotene": {
//     "name": "Tazarotene",
//     "strength": "0.1%",
//     "size": "30 g",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "tazaroteneWeak": {
//     "name": "Tazarotene",
//     "strength": "0.05%",
//     "size": "30 g",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "tazorac": {
//     "name": "Tazorac",
//     "strength": "0.05%",
//     "size": null,
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "tazoracStrong": {
//     "name": "Tazorac",
//     "strength": "0.1%",
//     "size": null,
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "epiduo": {
//     "name": "Epiduo gel",
//     "strength": "0.1%/2.5%",
//     "size": null,
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "epiduoForte": {
//     "name": "Epiduo forte gel",
//     "strength": "0.3%/2.5%",
//     "size": null,
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": null,
//     "refillExpiration": null,
//   },
//   "retinaMicro": {
//     "name": "Retin-A Micro",
//     "strength": "0.06% or 0.08%",
//     "size": null,
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "retinaMicroStrong": {
//     "name": "Retin-A Micro",
//     "strength": "0.1%",
//     "size": null,
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Apply to face before bed",
//     "refillCount": 1,
//     "refillExpiration": 56,
//   },
//   "doxycycline": {
//     "name": "Doxycycline once a day",
//     "strength": "100 mg",
//     "size": "30 pills",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Take one with food in the evening",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "doxycyclineStrongTwice": {
//     "name": "Doxycycline twice a day",
//     "strength": "100 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Take pills with food (1 in morning, 1 in evening)",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "doxycyclineTwice": {
//     "name": "Doxycycline twice a day",
//     "strength": "50 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Take pills with food (1 in morning, 1 in evening)",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "doxycyclineThrice": {
//     "name": "Doxycycline three times a day",
//     "strength": "50 mg",
//     "size": "90 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Take pills with food (1 in morning, 2 in evening)",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "minocycline": {
//     "name": "Minocycline once a day",
//     "strength": "100 mg",
//     "size": "30 pills",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Take one with food in the evening",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "minocyclineTwice": {
//     "name": "Minocycline twice a day",
//     "strength": "50 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Take pills with food (1 in morning, 1 in evening)",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "minocyclineStrongTwice": {
//     "name": "Minocycline twice a day",
//     "strength": "100 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Take pills with food (1 in morning, 1 in evening)",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "minocyclineThrice": {
//     "name": "Minocycline three times a day",
//     "strength": "50 mg",
//     "size": "90 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Take pills with food (1 in morning, 2 in evening)",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "minocyclineER": {
//     "name": "Minocycline ER",
//     "strength": "90 mg",
//     "size": "30 pills",
//     "instructions": [
//       "evening",
//     ],
//     "specialInstructions": "Take one with food in the evening",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "ceftin": {
//     "name": "Ceftin",
//     "strength": "250 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "duricef": {
//     "name": "Duricef",
//     "strength": "500 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "bactrim": {
//     "name": "Bactrim DS",
//     "strength": "160 mg/800 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "spironolactone": {
//     "name": "Spironolactone",
//     "strength": "25 mg",
//     "size": "60 pills",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
//   "hydroquinone": {
//     "name": "Hydroquinone cream",
//     "strength": "4%",
//     "size": "28 g",
//     "instructions": [
//       "morning",
//       "evening",
//     ],
//     "specialInstructions": "Apply in AM and PM to brown spots",
//     "refillCount": 1,
//     "refillExpiration": 42,
//   },
// }

// export const PrescriptionPackages = {
//   "mildAcne": {
//     "name": "Mild Acne",
//     "groups": [
//       [ "benzoylPeroxide", "sulfurCleanser" ],
//       ["clindamycinGel", "clindamycinPledglets", "clindamycinLotion", "benzaclin", "dapsone", "erythromycin", "benzamycinGel"],
//       [ "adapalene", "tretinoin" ],
//       [ "moisturizer" ],
//     ]
//   },
//   "mildModerateAcne": {
//     "name": "Mild-Moderate Acne",
//     "groups": [
//       [ "benzoylPeroxide", "sulfurCleanser" ],
//       [ "clindamycinGel", "clindamycinPledglets", "clindamycinLotion", "benzaclin", "dapsone", "dapsoneStrong", "erythromycin", "benzamycinGel" ],
//       [ "tretinoin", "tretionoinBig", "tretinoinStrong", "adapaleneStrong", "tazaroteneWeak", "tazarotene", "tazorac", "tazoracStrong", "epiduo", "epiduoForte", "retinaMicro", "retinaMicroStrong" ],
//       [ "moisturizer" ],
//     ],
//   },
//   "moderateAcne": {
//     "name": "Moderate Acne",
//     "groups": [
//       [ "benzoylPeroxide", "sulfurCleanser" ],
//       [ "clindamycinGel", "clindamycinPledglets", "clindamycinLotion", "benzaclin", "dapsone", "dapsoneStrong", "benzamycinGel" ],
//       [ "tretinoin", "tretionoinBig", "tretinoinStrong", "adapaleneStrong", "tazaroteneWeak", "tazarotene", "tazorac", "tazoracStrong", "epiduo", "epiduoForte", "retinaMicro", "retinaMicroStrong" ],
//       [ "doxycycline", "doxycyclineTwice", "doxycyclineThrice", "minocycline", "minocyclineTwice", "minocyclineThrice", "minocyclineER", "ceftin", "duricef", "bactrim", "spironolactone" ],
//       [ "moisturizer" ],
//     ],
//   },
//   "moderateSevereAcne": {
//     "name": "Moderate-Severe Acne",
//     "groups": [
//       [ "benzoylPeroxide", "sulfurCleanser" ],
//       [ "clindamycinGel", "clindamycinPledglets", "benzaclin", "dapsone", "dapsoneStrong", "benzamycinGel" ],
//       [ "tazaroteneWeak", "tazarotene", "tazorac", "tazoracStrong", "tretinoinStrong", "adapaleneStrong", "epiduo", "epiduoForte", "retinaMicro", "retinaMicroStrong" ],
//       [ "doxycycline", "doxycyclineTwice", "doxycyclineStrongTwice", "doxycyclineThrice", "minocycline", "minocyclineTwice", "minocyclineStrongTwice", "minocyclineThrice", "minocyclineER", "ceftin", "duricef", "bactrim", "spironolactone" ],
//       [ "moisturizer" ],
//     ],
//   }
// }

export const InstructionsList: { [p: string]: string } = {
  morning: "Morning",
  evening: "Evening",
};
