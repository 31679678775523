import React, { useEffect, useState } from "react";
import { logError } from "../../../../../shared/logger";
import { LineItem } from "../../../../../types/Base/Products";
import { PromotionCode } from "../../../../../types/Payment/PromotionCode";
import { Button, SubmitButton } from "../../../Common/Button";
import { InfoBlock } from "../../../Common/InfoBlock";
import { Loader } from "../../../Common/Loader";
import { TextInput } from "../../../Common/TextInput";
import "./style.scss";
import { useGetPricesQuery } from "../../../../../features/api/payment";
import { HoneydewAPI } from "../../../../../services/honeydew-api";
import {
  MembershipPaymentOption,
  PaymentOptions,
  ProductPaymentOption,
} from "../../../../../types/Payment/PaymentConfiguration";
import { Skeleton } from "../../../Common/Skeleton";

interface Props {
  internalCredit: number;
  onCouponChange: (value: { id: string; name: string } | null) => void;
  onSubmit: (paymentOptions: PaymentOptions) => Promise<void>;
  enableCheckout: boolean;
  products?: LineItem[];
  subscription?: string;
}

export function CheckoutFormCheckoutInfo({
  products,
  subscription,
  internalCredit,
  enableCheckout,
  onCouponChange,
  onSubmit,
}: Props) {
  const pricesQuery = useGetPricesQuery(null);
  const [isPaymentOptionsError, setIsPaymentOptionsError] = useState(false);
  const [discountInfo, setDiscount] = useState<PromotionCode | null>(null);
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptions | null>(
    null
  );

  useEffect(() => {
    if (pricesQuery.isError) {
      setIsPaymentOptionsError(true);
      return;
    }
    if (pricesQuery.isSuccess) {
      const _products = pricesQuery.data.reduce(
        (acc, { type, title, amount, id }) => {
          if (type !== "product") return acc;
          const correlatedProduct = products?.find(
            ({ priceId }) => priceId === id
          );
          if (!correlatedProduct) return acc;

          return [
            ...acc,
            {
              title,
              amount,
              priceId: id,
              quantity: correlatedProduct.quantity,
            },
          ];
        },
        [] as ProductPaymentOption[]
      );
      const membershipPrice = pricesQuery.data.find(
        ({ id }) => id === subscription
      );
      setPaymentOptions({
        products: _products.length ? _products : undefined,
        membership: membershipPrice
          ? {
              title: membershipPrice.title,
              amount: membershipPrice.amount,
              priceId: membershipPrice.id,
            }
          : undefined,
      });
    }
  }, [pricesQuery.isError, pricesQuery.isSuccess]);

  useEffect(() => {
    onCouponChange(
      discountInfo
        ? {
            id: discountInfo.id,
            name: coupon,
          }
        : null
    );
  }, [discountInfo]);

  function calculateAmounts(
    membership?: MembershipPaymentOption,
    _products?: ProductPaymentOption[]
  ) {
    if (discountInfo?.appliesToProducts) {
      const productsAmounts = _products
        ? _products.reduce(
            (acc, { amount, quantity, priceId }) => {
              const totalAmount = amount * quantity || 0;
              let discount = 0;

              if (discountInfo.percentOff) {
                const isCodeApplicable =
                  discountInfo.appliesToProducts?.includes(priceId);
                discount = isCodeApplicable
                  ? (discountInfo.percentOff * totalAmount) / 100
                  : 0;
              }

              return {
                totalAmount: totalAmount + acc.totalAmount,
                discount: discount + acc.discount,
              };
            },
            {
              totalAmount: 0,
              discount: 0,
            }
          )
        : {
            totalAmount: 0,
            discount: 0,
          };
      const membershipTotalAmount = membership?.amount || 0;
      const membershipDiscount = discountInfo.appliesToProducts.includes(
        membership?.priceId || ""
      )
        ? ((discountInfo.percentOff || 0) / 100) * membershipTotalAmount
        : 0;
      const totalAmount = productsAmounts.totalAmount + membershipTotalAmount;
      return {
        totalAmount,
        discount: productsAmounts.discount + membershipDiscount,
      };
    }

    const isDiscountApplicableToItems = discountInfo?.duration !== "repeating";
    const discountPercent = (discountInfo?.percentOff || 0) / 100;

    const membershipAmount = (membership && membership.amount) || 0;
    const productsAmount = _products
      ? _products.reduce(
          (acc: number, { amount, quantity }) => acc + amount * quantity,
          0
        )
      : 0;

    const membershipAmountDiscount = membershipAmount * discountPercent;

    const productsAmountDiscount = isDiscountApplicableToItems
      ? productsAmount * discountPercent
      : 0;
    const totalAmount = membershipAmount + productsAmount;
    return {
      totalAmount,
      discount: membershipAmountDiscount + productsAmountDiscount,
    };
  }

  const { totalAmount, discount } = calculateAmounts(
    subscription ? paymentOptions?.membership : undefined,
    paymentOptions?.products
  );
  let amountToPay = totalAmount - discount - internalCredit;
  if (amountToPay < 0) amountToPay = 0;

  async function verifyPromotionCode(_code: string) {
    setCouponError("");

    try {
      const stripeDiscount = await HoneydewAPI.payments.getPromotionCode(_code);
      setDiscount(stripeDiscount);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logError("Promotion code verification error", e);

      setCouponError("Invalid promotion code");
      setDiscount(null);
      throw e;
    }
  }

  function renderSummary() {
    return [
      subscription && (
        <div className="checkout-info__price-item paragraph-font paragraph-font--color">
          {paymentOptions?.membership ? (
            <>
              {" "}
              <p>{paymentOptions.membership.title}:</p>
              <p>
                ${((paymentOptions.membership.amount || 0) / 100).toFixed(2)}
              </p>
            </>
          ) : (
            <Skeleton />
          )}
        </div>
      ),
      ...(paymentOptions?.products || [])
        .filter(({ quantity }) => quantity > 0)
        .map(({ title, quantity, amount }) => (
          <div className="checkout-info__price-item paragraph-font paragraph-font--color">
            <p>
              {title} ({quantity}):
            </p>
            <p>${((amount * quantity) / 100).toFixed(2)}</p>
          </div>
        )),
      <div className="checkout-info__price-item paragraph-font paragraph-font--color">
        <p>Internal credit (${(internalCredit / 100).toFixed(2)}):</p>
        <p>
          {internalCredit !== 0 ? "-" : null} $
          {(amountToPay <= 0
            ? (totalAmount - discount) / 100
            : internalCredit / 100
          ).toFixed(2)}
        </p>
      </div>,
      <div className="checkout-info__price-item paragraph-font paragraph-font--color">
        <p>Discount:</p>
        <p>
          {discount === 0 ? null : "-"} ${(discount / 100).toFixed(2)}
        </p>
      </div>,
    ];
  }

  if (isPaymentOptionsError) {
    return (
      <div className="checkout-info">
        <InfoBlock
          view="error"
          text="Somthing went wrong. Please, try again later"
        />
      </div>
    );
  }

  if (!paymentOptions) {
    return (
      <div className="checkout-info">
        <Loader className="checkout-info__loader" />
      </div>
    );
  }

  return (
    <div className="checkout-info">
      {isPaymentOptionsError}
      <div className="checkout-info__section">
        <div className="checkout-info__title">
          <p>Order Summary</p>
        </div>
        <div className="checkout-info__body">{renderSummary()}</div>
      </div>
      <div className="checkout-info__section">
        <div className="checkout-info__title">
          <p className="checkout-info__title--main">Add coupon code</p>
          <p className="paragraph-font paragraph-font--color">Optional</p>
        </div>
        <div className="checkout-info__body">
          <TextInput
            id="coupon-code"
            name="Coupon code"
            onChange={(value) => {
              setCoupon(value);
              setCouponError("");
            }}
            error={couponError}
          />
          <Button
            text="apply"
            view="secondary"
            disabled={!coupon || !!couponError}
            className="checkout-info__coupon-button"
            onClick={async () => {
              await verifyPromotionCode(coupon);
            }}
          />
        </div>
      </div>

      <div className="checkout-info__section">
        <div className="checkout-info__title">
          <p>Order total:</p>
          <p className="checkout-info__amount">
            ${(amountToPay / 100).toFixed(2)}
          </p>
        </div>
        <div className="checkout-info__body">
          {enableCheckout ? (
            <Button
              text="checkout"
              className="checkout-info__coupon-button"
              onClick={() => onSubmit(paymentOptions)}
            />
          ) : (
            <Skeleton style={{ height: 48 }} />
          )}

          <p className="checkout-info__rules-text paragraph-font paragraph-font--color">
            By placing your order, you agree to our company{" "}
            <a
              href="https://honeydewcare.com/Honeydew_Privacy_Policy.pdf"
              target="_blank"
              rel="noreferrer"
              className="link-font--color"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://honeydewcare.com/Honeydew_Terms_of_Service.pdf"
              target="_blank"
              rel="noreferrer"
              className="link-font--color"
            >
              Terms of Service
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
