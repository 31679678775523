import { newAppointment, newFollowUp } from "./queryPartials";

export const patientDashboardForAdministratorOrder = (employeeId: string) => [
  {
    filter: {
      bool: {
        must: {
          match: {
            tasks: employeeId,
          },
        },
        must_not: newFollowUp,
      },
    },
    weight: 5,
  },
  {
    filter: {
      ...newAppointment,
    },
    weight: 4,
  },
  {
    filter: {
      ...newFollowUp,
    },
    weight: 2,
  },
  {
    filter: {
      bool: {
        should: [
          {
            match: {
              [`flags.pinnedByProvider_${employeeId}`]: true,
            },
          },
          {
            match: {
              "flags.pinnedByProvider": true,
            },
          },
        ],
      },
    },
    weight: 1,
  },
];
