import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

interface AccutaneTask {
  message: string;
  type: string;
  createdAt: string;
  patientId: string;
  onLeaveDelayed?: boolean;
  assignedTo: {
    id: string | undefined;
    role: string | undefined;
    name: string | undefined;
  };
  assignedBy: {
    id: string;
    role: string;
  };
  employeeId?: string | null;
  documentId?: string;
}

export const assignTask = async (data: AccutaneTask) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/assign-task`,
      data
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to assign task");
    throw error;
  }
};
