import React, { useState, createContext } from "react";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close-icon.svg";
import { logInfo } from "../../../../shared/logger";
import "./style.css";
import { Button } from "../../../NewComponents/Common/Button";

const defaultConfirmationWord = "delete";

interface Props {
  children: JSX.Element;
}

export const DeleteConfirmainPopupContext = createContext<{
  ask: (cb: () => void, word?: string) => void;
}>({
  ask: () => {
    logInfo("");
  },
});

export function DeleteConfirmationPopup({ children }: Props) {
  const [valid, setValidity] = useState(false);
  const [confirmationWord, setConfirmationWord] = useState(
    defaultConfirmationWord
  );
  const [callback, setCallback] = useState<(() => void) | null>(null);

  function discard() {
    setCallback(null);
    setValidity(false);
  }

  async function confirm() {
    if (valid && callback) {
      await callback();
      setCallback(null);
      setValidity(false);
    }
  }

  function ask(cb: () => void | Promise<void>, word?: string) {
    setCallback(() => () => cb());
    setConfirmationWord(word || defaultConfirmationWord);
  }

  return (
    <DeleteConfirmainPopupContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ask,
      }}
    >
      {callback ? (
        <div className="confirm-deletion-popup">
          <div className="confirm-deletion-popup__wrapper">
            <div
              onClick={discard}
              className="confirm-deletion-popup__close-container"
            >
              <CloseIcon className="confirm-deletion-popup__close-icon" />
            </div>
            <div className="confirm-deletion-popup__container">
              <h2 className="confirm-deletion-popup__title">Confirm action</h2>
              <p className="confirm-deletion-popup__description">
                Please type "{confirmationWord.toUpperCase()}" to confirm
              </p>
              <input
                className="confirm-deletion-popup__input"
                type="text"
                onChange={(event) =>
                  setValidity(
                    event.target.value === confirmationWord.toUpperCase()
                  )
                }
              />
              <Button text="confirm" onClick={confirm} disabled={!valid} />
            </div>
          </div>
        </div>
      ) : null}
      {children}
    </DeleteConfirmainPopupContext.Provider>
  );
}
