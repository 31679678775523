export const psoriasisSubQuestions = [
  {
    label:
      "Have there been any changes to your medical history or new allergies since your last visit?",
    value: "isMedicalHistoryChanges",
    questionType: "psoriasis",
    hasChild: true,
    childId: "isMedicalHistoryChanges",
    description:
      "(e.g. new medications, medical conditions, surgeries or procedures)",
    radioButtonsForm: true,
    placeholder: "Please elaborate",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Please elaborate",
  //   value: "medicalHistoryChanges",
  //   questionType: "psoriasis",
  //   parentQuestion: "isMedicalHistoryChanges",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "medicalHistoryChanges",
  //       label: "Please elaborate",
  //     },
  //   ],
  // },
  {
    label:
      "Please let your provider know how you've been using your recommended treatments",
    value: "treatmentSurvey",
    questionType: "psoriasis",
    isRecommendedTreatment: true,
    options: [
      {
        value: "NORMAL",
        label: "I use it exactly as directed",
      },
      {
        value: "MODIFIED",
        label: "I modified the amount or the frequency of use",
      },
      {
        value: "STOPPED",
        label: "I stopped using this",
      },
    ],
  },
  {
    label:
      "Do you have any side effects to your medication (both topical and systemic medications) that you wish to talk to your provider about?",
    value: "medicationSideEffects",
    questionType: "psoriasis",
    placeholder: "Please elaborate",
    radioButtonsForm: true,
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No, I am tolerating everything ok",
      },
    ], // open text
  },
  {
    label: "Do you need a refill of any products?",
    value: "isRefillRequired",
    questionType: "acne",
    hasChild: true,
    childId: "isRefillRequired",
    radioButtonsForm: true,
    placeholder: "Which products would you like to refill?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Which products would you like to refill?",
  //   value: "refillProducts",
  //   questionType: "eczema",
  //   parentQuestion: "isRefillRequired",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "refillProducts",
  //       label: "Which products would you like to refill?",
  //     },
  //   ],
  // },
  {
    label: "How do you feel about your progress?",
    value: "progressFeelings",
    questionType: "psoriasis",
    textArea: true,
    isTextArea: true,
    options: [
      {
        value: "How do you feel about your progress?",
        label: "How do you feel about your progress?",
      },
    ], // open text
  },
  {
    label: "How would you describe the amount of psoriasis?",
    description:
      "(1-10; 1 - totally clear to 10 - most of my body covered with eczema)",
    value: "psoriasisAmount",
    questionType: "psoriasis",
    isInputTypeNumber: true,
    maxNumber: 10,
    options: [],
  },
  {
    label: "How would you describe your level of itch",
    description:
      "( 1 not at all ; 10 itching and scratching all day and can’t sleep at night because of itch )",
    value: "itchDuration",
    questionType: "psoriasis",
    isInputRatingType: true,
    maxNumber: 10,
    options: [],
  },
  {
    label:
      "Do you have any specific questions or comments that you would like to talk to your provider about",
    value: "additionalQuestions",
    questionType: "psoriasis",
    textArea: true,
    options: [
      {
        value:
          "Do you have any specific questions or comments for your provider?",
        label:
          "Do you have any specific questions or comments for your provider?",
      },
    ], // open text
  },
];
