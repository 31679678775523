import React from "react";
import { HelpPopupTemplate } from "../PopupLayer/help";
import { Button } from "../Button";
import "./style.scss";

interface IncompleteFormPopupProps {
  onClose: () => void;
}

function IncompleteFormPopup({ onClose }: IncompleteFormPopupProps) {
  const onSubmitHandler = () => {
    onClose();
  };

  return (
    <div className="incomplete-form-popup">
      <div className="incomplete-form-popup__content">
        <p className="incomplete-form-popup__content__title">Oops!</p>
        <p className="incomplete-form-popup__content__description">
          It looks like the form is either incomplete or has not been submitted
          yet.
          <br />
          <br />
          If complete, please use the "Finish" button on the embedded form
          before marking this task as complete.
        </p>
      </div>
      <div className="incomplete-form-popup__button-wrapper">
        <Button
          text="Okay"
          onClick={onSubmitHandler}
          size="small"
          className="incomplete-form-popup__button-wrapper__button"
        />
      </div>
    </div>
  );
}

export function IncompleteFormPopupGenerator() {
  return function render({ onClose }: IncompleteFormPopupProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <IncompleteFormPopup onClose={onClose} />
      </HelpPopupTemplate>
    );
  };
}
