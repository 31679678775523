/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ReactComponent as RemoveButtonIcon } from "../../../../Assets/icons/Button-Delete.svg";
import { ReactComponent as EditButtonIcon } from "../../../../Assets/icons/Button-Edit.svg";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import TreatmentForm from "../TreatmentForm";
import { Button } from "../../../NewComponents/Common/Button";
import sunIcon from "../../../../Assets/NewIcons/sun.svg";
import moonIcon from "../../../../Assets/NewIcons/moon.svg";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "auto",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    background: "white",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

const deleteModalStyle = {
  content: {
    top: "20%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    maxWidth: "400px",
    width: "100%",
    backgroundColor: "#fff",
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
};

export function ShowTreatmentPlanMobileDevice({
  data,
  selectedTreatmentPlanHeading,
  onChange,
  setTableRows,
  setTablesRowData,
  tablesRowData,
  tableRows,
  setDate,
  date,
}) {
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const [isOpen, setIsOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const preparedMedicineMap =
    medicationsQuery.data?.reduce(
      (acc: { [p: string]: { value: string; label: string } }, item) => {
        acc[item.id] = {
          value: item.id,
          label: `${item.name}${item.strength ? ` ${item.strength}` : ""}${
            item.size ? `, ${item.size}` : ""
          }`,
        };

        return acc;
      },
      {}
    ) || {};

  const handleEditClick = (compData) => {
    setRowData(compData);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTablesRowData(tablesRowData);
  }, [tablesRowData]);

  useEffect(() => {
    setTablesRowData(data);
  }, [data]);

  const handleRowDelete = (item) => {
    setItemToDelete(item);
    setIsOpenDeleteModal(true);
  };

  const handleModalClose = () => {
    setIsOpenDeleteModal(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirmed = () => {
    if (itemToDelete) {
      const filteredArray = tablesRowData?.filter(
        (item) => item.medicineKey !== itemToDelete.medicineKey
      );

      setTablesRowData(filteredArray);
      setTableRows(filteredArray);
      setIsOpenDeleteModal(false);
      setItemToDelete(null);
    }
  };

  return (
    <div>
      {tablesRowData.map((item, index) => (
        <>
          <div
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              border: "2px solid #F2F4F7",
              borderRadius: "20px",
              backgroundColor: "#fff",
              padding: "16px",
              marginBottom: "10px",
              lineHeight: "24px",
            }}
          >
            <div key={item.medicineKey || index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <h2
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {preparedMedicineMap[item.medicineKey]?.label ||
                    item?.medicineKey?.label ||
                    (item?.medicineKey &&
                      item?.medicineKey.match(/label=([^,]+)/)?.[1]?.trim())}
                  {item.instructions.includes("morning") && (
                    <img
                      src={sunIcon}
                      alt="Morning"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "5px",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    />
                  )}
                  {item.instructions.includes("evening") && (
                    <img
                      src={moonIcon}
                      alt="Evening"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    />
                  )}
                </h2>
                <div style={{ display: "flex" }}>
                  <span
                    style={{ display: "inline-block", marginRight: "10px" }}
                    onClick={() => handleRowDelete(item)}
                  >
                    <RemoveButtonIcon />
                  </span>
                  <span onClick={() => handleEditClick(item)}>
                    <EditButtonIcon />
                  </span>
                </div>
              </div>
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  color: "#6C6A7C",
                }}
              >
                {item.specialInstructions}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#6C6A7C",
                    }}
                  >
                    Refills
                  </h3>
                </div>
                <div>{item.refillsCount || "-"}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#6C6A7C",
                    }}
                  >
                    Refills Expiration
                  </h3>
                </div>
                <div>
                  <span>
                    {item.refillExpirationDate
                      ? new Date(item.refillExpirationDate).toLocaleString()
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal
              isOpen={isOpenDeleteModal}
              onRequestClose={handleModalClose}
              contentLabel="Delete Treatment Plan"
              style={deleteModalStyle}
            >
              <h3
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#403E48",
                  marginBottom: "10px",
                }}
              >
                Are you sure?
              </h3>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  lineHeight: "24px",
                  fontSize: "16px",
                  color: "#6C6A7C",
                }}
              >
                You are about to remove{" "}
                <span style={{ fontWeight: "bold" }}>
                  {preparedMedicineMap[itemToDelete?.medicineKey]?.label}
                </span>{" "}
                from this treatment plan.
              </div>
              <div
                className="follow-up-visit__submit-area"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  size="big"
                  text="Yes, Remove"
                  onClick={handleDeleteConfirmed}

                  // onClick={submit}
                  // disabled={!isChangeDetected}
                />
                <Button
                  size="big"
                  text="No, Cancel"
                  className="follow-up-visit__update-button"
                  onClick={handleModalClose}
                  // onClick={submit}
                  // disabled={!isChangeDetected}
                />
              </div>
            </Modal>
          </div>
        </>
      ))}

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Treatment Modal"
        style={customStyles}
      >
        <TreatmentForm
          data={rowData}
          setIsOpen={setIsOpen}
          onChange={onChange}
          setTableRows={setTableRows}
          tableRows={tableRows}
          tablesRowData={tablesRowData}
          setDate={setDate}
          date={date}
        />
      </Modal>
    </div>
  );
}
