import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const deleteDocument = async (
  documentId: string,
  patientId: string
): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/delete-document/${documentId}`,
      {
        patientId,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to delete document");
    throw error;
  }
};
