import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const completeTask = async (
  taskId: string,
  compositeKey: string
): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/complete-task/${taskId}`,
      {
        compositeKey,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to complete task");
    throw error;
  }
};
