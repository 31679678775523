import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import {
  CareCoordinator,
  CareCoordinatorEdit,
} from "../../../../../types/Entities/CareCoordinator";
import { AuthenticationService } from "../../../../cognito";

export const update = async (careCoordinator: CareCoordinatorEdit) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.put<CareCoordinator>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/care-coordinators/update`,
      careCoordinator,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot update Care coordinator`);
    throw error;
  }
};
