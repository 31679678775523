import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getProvidersInfoState } from "../../../../features/providers";
import { ProvidersTableBody } from "./tableBody";
import "./style.css";
import { ProvidersTableHead } from "./tableHead";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { TableItemValue } from "../../../../types/Table/TableItemValue";
import { SortingFunction } from "../../../../types/Table/SortingFunction";
import { SortingPayload } from "../../../../types/Table/SortingPayload";
import { useGetEmployeesByRoleQuery } from "../../../../features/api/employees";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { Button } from "../../../NewComponents/Common/Button";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { USER_ROLES } from "../../../../types/Main";
import { Provider } from "../../../../types/Employee";
import { PatientsTablePagination } from "../../../NewComponents/Common/PatientsTableElastic/tablePagination";

function alphabetical(a: string | number | Date, b: string | number | Date) {
  const c = typeof a === "string" ? a.toLowerCase() : a;
  const d = typeof b === "string" ? b.toLowerCase() : b;
  if (c > d) return 1;
  if (c < d) return -1;
  return 0;
}

const columnsList: TableHeadPayload<TableBodyPayload<Provider>>[] = [
  {
    title: "Full Name",
    get: ({ entity }) => `${entity.lastName}`,
    sort: alphabetical,
  },
  {
    title: "License Initials",
    get: ({ entity }) => entity.title || "",
    sort: alphabetical,
  },
  {
    title: "NPI Number",
    get: ({ entity }) => entity.npiNumber || "",
  },
  {
    title: "State Licenses",
    get: ({ entity }) => entity.stateLicenses || "",
  },
  {
    title: "Email",
    get: ({ entity }) => entity.email,
    sort: alphabetical,
  },
  {
    title: "Phone",
    get: ({ entity }) => entity.phone,
    sort: alphabetical,
  },
  {
    title: "Actions",
  },
];

function setOrder(func: SortingFunction, order: "asc" | "desc") {
  return (a: TableItemValue, b: TableItemValue) =>
    order === "asc" ? func(a, b) : func(b, a);
}

function sortByColumn(
  providersList: TableBodyPayload<Provider>[],
  { column: columnName, state }: SortingPayload
) {
  const column = columnsList.find(({ title }) => title === columnName);
  if (!column) return providersList;
  if (column.sort && column.get) {
    return providersList.sort((a, b) =>
      setOrder(column.sort as SortingFunction, state)(
        (
          column.get as (
            item: TableBodyPayload<Provider>
          ) => string | number | Date
        )(a),
        (
          column.get as (
            item: TableBodyPayload<Provider>
          ) => string | number | Date
        )(b)
      )
    );
  }

  return providersList;
}

function defaultSort(
  providersList: TableBodyPayload<Provider>[],
  sorting: SortingPayload | null
) {
  const result = Array.of(providersList);

  if (sorting) {
    result.forEach((list) => sortByColumn(list, sorting));
  }

  return result.reduce((acc, el) => [...acc, ...el], []);
}

const processProvidersList = (
  providersList: Provider[],
  sorting: SortingPayload | null
) => {
  const filteredProvidersList = providersList.filter(
    (obj) => obj.archived !== true
  );
  const processedProvidersList: TableBodyPayload<Provider>[] =
    filteredProvidersList.map((provider) => {
      const type = null;
      const nextFollowUpDate = null;
      const rowClass = null;
      const isNewMessage = false;

      return {
        isNewMessage,
        entity: provider,
        nextFollowUpDate,
        rowClass,
        type,
      };
    });

  return defaultSort(processedProvidersList, sorting);
};

export function ProvidersTable() {
  const role = USER_ROLES.PROVIDER;
  const { showPopup } = useContext(PopupLayerContext);
  const providersQuery = useGetEmployeesByRoleQuery(role);
  const [defaultSortedProvidersData, setDefaultSortedProvidersData] =
    useState();
  const [sort, setSort] = useState<SortingPayload | null>(null);
  const [showLicensesColumn, setShowLicensesColumn] = useState(true);

  useEffect(() => {
    if (providersQuery.data && Array.isArray(providersQuery.data)) {
      const sortedData = [...providersQuery.data].sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
      setDefaultSortedProvidersData(sortedData);
    }
  }, [providersQuery.data]);

  return defaultSortedProvidersData ? (
    <>
      <div className="provider-medicine-button-wrapper">
        <div className="show-licenses-switch">
          <label>
            {showLicensesColumn
              ? "Hide Licenses Column"
              : "Show Licenses Column"}
            <input
              type="checkbox"
              checked={showLicensesColumn}
              onChange={() => setShowLicensesColumn(!showLicensesColumn)}
            />
          </label>
        </div>
      </div>

      <div className="patientsTable-wrapper">
        <table className="patientsTable">
          <ProvidersTableHead
            columns={
              showLicensesColumn
                ? columnsList
                : columnsList.filter((col) => col.title !== "State Licenses")
            }
            onSortChange={(payload) => setSort(payload)}
          />
          <ProvidersTableBody
            providersList={processProvidersList(
              defaultSortedProvidersData as Provider[],
              sort
            )}
            showLicensesColumn={showLicensesColumn}
          />
        </table>
        <div style={{ marginTop: "20px" }}>
          <PatientsTablePagination
            max={Math.ceil(100 / 10)}
            current={0}
            onChange={(it) => console.log("Paginations added")}
            simple
          />
        </div>
      </div>
    </>
  ) : (
    <Skeleton flex count={5} fullWidth column style={{ height: 60 }} />
  );
}
