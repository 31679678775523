import React, { createContext, ReactElement, useState } from "react";
import { logInfo } from "../../../../shared/logger";
import "./style.scss";

type PopupProps = ({ onClose }: { onClose: () => void }) => ReactElement;

export const PopupLayerContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showPopup: (component: PopupProps) => logInfo(""),
  closeAllPopups: () => logInfo(""),
});

interface Props {
  children: (JSX.Element | null)[] | JSX.Element | null;
}

interface LayerProps {
  component: PopupProps;
  onClose: () => void;
}

function Layer({ onClose, component }: LayerProps) {
  return component({
    onClose,
  });
}

export function PopupLayer({ children }: Props) {
  const [components, setComponents] = useState<Set<PopupProps>>(new Set());

  const lockBody = () => {
    document.body.style.overflowY = "hidden";
    document.body.style.height = "100vh";
  };

  const unlockBody = () => {
    document.body.style.overflowY = "visible";
    document.body.style.height = "100vh";
  };

  function showPopup(component: PopupProps) {
    setComponents((_components) => new Set(_components.add(component)));
    lockBody();
  }

  function closeAllPopups() {
    setComponents(new Set());
    unlockBody();
  }

  function renderPopupLayer() {
    return Array.from(components).map((Component) => (
      <Layer
        onClose={() => {
          setComponents((_components) => {
            _components.delete(Component);
            return new Set(_components);
          });
          unlockBody();
        }}
        component={Component}
      />
    ));
  }

  return (
    <PopupLayerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        showPopup,
        closeAllPopups,
      }}
    >
      {children}
      {renderPopupLayer()}
    </PopupLayerContext.Provider>
  );
}
