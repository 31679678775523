import axios from "axios";
import { ENV_CONFIG } from "../../../../config";
import { logError } from "../../../../shared/logger";

type Payload = {
  zipCode: string;
  email: string;
  phone: string;
};

export async function createUnsupportedVisitor(payload: Payload) {
  try {
    await axios.post(ENV_CONFIG.ADD_UNSUPPORTED_VISITOR_URL, payload);
  } catch (error) {
    logError("Error occured. Cannot get care coordinator data");
    throw error;
  }
}
