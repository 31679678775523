import moisturizer from "../Assets/NewImages/moisturizer_blue.png";
import benzoylPeroxide from "../Assets/NewImages/wash_blue.png";
import probiotics from "../Assets/NewImages/probiotic_blue.png";
import sunscreen from "../Assets/NewImages/sunscreen_blue.png";
import { ProductData } from "../types/Base/Products";

export const initialProductsData: ProductData[] = [
  {
    keyword: "moisturizer",
    image: moisturizer,
    description: "Non-prescription",
  },
  {
    keyword: "benzoylPeroxide",
    image: benzoylPeroxide,
    description: "Non-prescription",
  },
  {
    keyword: "probiotics",
    image: probiotics,
    description: "Non-prescription",
    soldout: true,
  },
  {
    keyword: "sunscreen",
    image: sunscreen,
    description: "Non-prescription",
  },
];
