export const pregnancyCapabilityStatus = (patient) => {
  if (!patient) {
    return false;
  }

  const isNotPregnantCapability = patient.flags?.isNotPregnantCapability;

  if (
    isNotPregnantCapability === null ||
    isNotPregnantCapability === undefined
  ) {
    return patient.medicalBackground?.sex !== "Female";
  }

  return isNotPregnantCapability;
};
