import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { useParams } from "react-router";
import { ReactComponent as ChatCloud } from "../../../../Assets/NewIcons/chat-cloud.svg";
import { ReactComponent as CrossIcon } from "../../../../Assets/NewIcons/close-cross.svg";
import { ChatComponent } from "../ChatPopup";
import { Patient } from "../../../../types/Entities/Patient";
import { NotifyProviderButton } from "./notify-provider";
import { getSessionState } from "../../../../features/session";
import { NewChatMessagesList } from "./new-messages-list";

interface Props {
  currentPatient: Patient;
}

enum STATES {
  VISIBLE = "visible",
  HIDDEN = "hidden",
}

export function ChatWidget() {
  const { activePatientId, userRole } = useSelector(getSessionState);
  const [opened, setOpened] = useState(false);
  const [state, setState] = useState(STATES.HIDDEN);

  return (
    <>
      {opened ? (
        <div className={`chat-widget__window ${state}`}>
          <div className="chat-widget__header">
            <div className="chat-widget__title">
              <p>Care team chat</p>
              {activePatientId ? (
                <NotifyProviderButton patientId={activePatientId} />
              ) : null}
            </div>
            <div
              className="chat-widget__close-button"
              onClick={() => {
                setState(STATES.HIDDEN);
                setTimeout(() => setOpened(false), 300);
              }}
            >
              <CrossIcon />
            </div>
          </div>
          <div className="chat-widget__body">
            {activePatientId ? <ChatComponent /> : <NewChatMessagesList />}
          </div>
        </div>
      ) : null}
      <div
        className="chat-widget__button"
        onClick={() => {
          setState(STATES.VISIBLE);
          setOpened(true);
        }}
      >
        <ChatCloud className="chat-widget__button-icon" />
      </div>
    </>
  );
}
