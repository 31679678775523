import React from "react";
import { ReactComponent as PeopleIcon } from "../../../../../Assets/NewIcons/people.svg";
import { ReactComponent as HeartIcon } from "../../../../../Assets/NewIcons/heart.svg";
import { ReactComponent as ImageIcon } from "../../../../../Assets/NewIcons/image.svg";
import { ReactComponent as CheckRounded } from "../../../../../Assets/NewIcons/check-rounded.svg";
import "./style.scss";

function HowItWorks() {
  return (
    <div className="how-it-works-wrapper">
      <p className="how-it-works-wrapper__title">How it works</p>
      <ul className="how-it-works-wrapper__list">
        <li className="how-it-works-wrapper__list-item">
          <div className="how-it-works-wrapper__list-item__icon">
            <PeopleIcon />
          </div>
          <p className="how-it-works-wrapper__list-item__content">
            <span className="how-it-works-wrapper__list-item__content--bold">
              1. Schedule an initial video visit (FREE)
            </span>{" "}
            to build your treatment plan
          </p>
        </li>
        <li className="how-it-works-wrapper__list-item">
          <div className="how-it-works-wrapper__list-item__icon">
            <HeartIcon />
          </div>
          <p className="how-it-works-wrapper__list-item__content">
            <span className="how-it-works-wrapper__list-item__content--bold">
              2. Sign up and get your treatment{" "}
            </span>
            delivered to your house or at your local pharmacy
          </p>
        </li>
        <li className="how-it-works-wrapper__list-item">
          <div className="how-it-works-wrapper__list-item__icon">
            <ImageIcon />
          </div>
          <p className="how-it-works-wrapper__list-item__content">
            <span className="how-it-works-wrapper__list-item__content--bold">
              3. Track your progress{" "}
            </span>
            with photos and share with your care team online
          </p>
        </li>
        <li className="how-it-works-wrapper__list-item">
          <div className="how-it-works-wrapper__list-item__icon">
            <CheckRounded />
          </div>
          <p className="how-it-works-wrapper__list-item__content">
            <span className="how-it-works-wrapper__list-item__content--bold">
              4. Access your care team{" "}
            </span>
            for ongoing support, refills, and skin tips
          </p>
        </li>
      </ul>
    </div>
  );
}

export default HowItWorks;
