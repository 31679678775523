import React, { useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { DateInput } from "../../../../NewComponents/Common/DateInput";
import { literal } from "../../../../../constants";

function surveyToComponent(data: SurveyData) {
  return data.birthDate?.replaceAll("/", " / ");
}

function componentToSurvey(value: string) {
  return {
    birthDate: value.replaceAll(" ", ""),
  };
}

export function BirthDateSurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));
  const [birthDateInvalid, setBirthDateInvalid] = useState("");
  const { isChild } = data;

  function isFutureDate(date: any) {
    const currentDateTimeStamp = new Date().getTime();
    let inputDate = date.split(literal.character.FORWARD_SLASH);
    inputDate = new Date(
      inputDate[2].trim(),
      inputDate[0].trim() - 1,
      inputDate[1].trim()
    ).getTime();
    return currentDateTimeStamp - inputDate < 0;
  }

  function validate() {
    const valid = new RegExp(/^\d{2} \/ \d{2} \/ \d{4}$/).test(currentData);
    if (!valid) {
      setBirthDateInvalid("Please, enter the date in MM / DD / YYYY format");
      return false;
    }

    if (isFutureDate(currentData)) {
      setBirthDateInvalid(
        "Date cannot be in the future. Please enter a valid date"
      );
      return false;
    }

    return true;
  }

  function submit() {
    if (!validate()) return;

    sendSurveyQuestion("birthDate");
    onDone(componentToSurvey(currentData));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        {isChild
          ? "Okay, and what is your child's date of birth?"
          : "Okay, and what is your date of birth?"}
      </p>
      <p className="survey-question__descr">
        {isChild
          ? "Please, enter your child's date of birth"
          : "Please, enter your date of birth"}
      </p>
      <DateInput
        id="full-name"
        name="full-name"
        onChange={(value) => {
          setData(value);
          setBirthDateInvalid("");
        }}
        required
        value={currentData || ""}
        placeholder="MM / DD / YYYY"
        error={birthDateInvalid}
        className="survey-question__input"
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={!currentData}
      />
    </div>
  );
}
