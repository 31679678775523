import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { PromotionCode } from "../../../../../types/Payment/PromotionCode";

export const validatePromotionCode = async (promotionCode: string) => {
  const { data } = await axios.get<PromotionCode>(
    `${ENV_CONFIG.AMAZON_API_DOMAIN}/payments/promotionCodeId/${promotionCode}`
  );

  return data;
};
