import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import store from "../../../../store";
import { ActionItem } from "./types";
import { Patient } from "../../../../types/Entities/Patient";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { StartFollowUpPopupGenerator } from "../StartFollowUpPopup";
import { MyTreatmentPlanPopupGenerator } from "../MyTreatmentPlanPopup";
import { InsurancePopupGenerator } from "../InsurancePopup";
import { AccutanePopupGenerator } from "../AccutanePopup";
import { BloodWorkPopupGenerator } from "../BloodWorkPopup";
import { Accutane } from "../../../../types/Entities/Accutane";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { useGetAccutaneByPatientIdQuery } from "../../../../features/api/accutane";
import { Medication } from "../../../../types/Medication";
import { FollowUp } from "../../../../types/FollowUp";
import { Appointment } from "../../../../types/Appointment";
import { hasActiveMembership } from "../../../../utils/has-active-membership";
import {
  SubscriptionItem,
  SUBSCRIPTION_TYPES,
} from "../../../../types/Subscription";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { hasActiveAccutane } from "../../../../utils/has-active-accutane";
import { Skeleton } from "../../Common/Skeleton";
import SignIpledgeConsentPopupGenerator from "../SignIpledgeConsentPopup";
import { SubscriptionExpiredPopupGenerator } from "../SubscriptionExpiredPopup";
import { getPatientMonthlyTasks } from "../../../../utils/patient/patientMonthlyTasks";
import { UploadDocumentPopupModal } from "../UploadDocumentPopupModal";
import { getPatientDocuments } from "../../../../features/patientView";
import { getGovtIDDocuments } from "../../../../utils/patient/get-government-issued-id";
import isAccutaneTasksVisible from "../../../../utils/is-accutane-tasks-visible";
import PharmacyBillingMethodPopupModal from "../PharmacyBillingMethodPopupModal";

const SECONDS_IN_DAY = 60 * 60 * 24;

export const getPatientActionsList = (
  accutane: Accutane | null,
  patient: Patient,
  medications: Medication[],
  followUps: FollowUp[],
  appointments: Appointment[],
  subscriptions: SubscriptionItem[],
  showPopup: any,
  history: any,
  govtIDDocuments: any,
  patientMonthlyTasks: any = []
): ActionItem[] => [
  {
    text: "Schedule initial visit (free)",
    check: () =>
      !appointments.length &&
      !window.localStorage.getItem(
        `appointment-submitted-${patient.patientId}`
      ) &&
      patient.flags?.isRemovedInitialVisit !== true,
    action: () => {
      history.push("/submit-appointment");
    },
    key: "initialVisitAction",
  },
  {
    text: "Submit medical background",
    check: () =>
      followUps.length === 0 &&
      patient.flags?.isRemovedMedicalBackground !== true,
    action: () => {
      history.push("/skin-survey");
    },
    key: "medicalBackgroundAction",
  },
  {
    text: "Initiate membership",
    check: () =>
      !hasActiveMembership(subscriptions) &&
      !subscriptions.find(({ type }) => type === "membership") &&
      patient.flags?.isRemovedInitiateMembership !== true,
    action: () => {
      history.push("/initiate-membership");
    },
    key: "initiateMembershipAction",
  },
  {
    text: "Upload government-issued ID",
    check: () =>
      govtIDDocuments &&
      govtIDDocuments.length === 0 &&
      patient.flags?.isRemovedGovernmentIssuedId !== true,
    action: () => {
      showPopup(UploadDocumentPopupModal());
    },
    key: "governmentIssuedIdAction",
  },
  {
    text: "Add insurance info",
    check: () =>
      !patient.insurance && patient.flags?.isRemovedInsuranceInfo !== true,
    action: () => {
      showPopup(InsurancePopupGenerator({ patient }));
    },
    key: "insuranceInfoAction",
  },
  {
    text: "Renew membership",
    check: () =>
      !!subscriptions.find(
        ({ type, status }) => type === "membership" && status !== "incomplete"
      ) &&
      !hasActiveMembership(subscriptions) &&
      patient.flags?.isRemovedRenewMembership !== true,
    action: () => {
      history.push("/buy-membership");
    },
    key: "renewMembershipAction",
  },
  {
    text: "Schedule call with provider",
    check: () =>
      !!patient.scheduleLink &&
      patient.flags?.isRemovedScheduleCallWithProvider !== true,
    action: () => window.location.assign(patient.scheduleLink as string),
    key: "scheduleCallWithProviderAction",
  },
  {
    text: "View provider's response to your follow up visit",
    check: () =>
      followUps.length > 1 &&
      !!followUps[0].treatmentPlan &&
      !followUps[0].treatmentPlan.isReadByPatient &&
      patient.flags?.isRemovedProviderResponseToFollowUp !== true,
    action: async () => {
      showPopup(MyTreatmentPlanPopupGenerator());
    },
    key: "providerResponseToFollowUpAction",
  },
  {
    text: "Start new follow-up visit",
    check: () => {
      const nextFollowUpDate =
        followUps.length > 0 && followUps[0].treatmentPlan?.nextFollowUpDate;
      if (!nextFollowUpDate) return false;
      return (
        nextFollowUpDate < Date.now() &&
        patient.flags?.isRemovedNewFollowUpVisit !== true
      );
    },
    action: () => {
      if (!hasActiveMembership(subscriptions)) {
        showPopup(SubscriptionExpiredPopupGenerator());
      } else {
        history.push("/start-follow-up");
      }
    },
    key: "newFollowUpVisitAction",
  },
  {
    text: "Initiate accutane",
    check: () =>
      (patient.medicalBackground?.sex &&
        !hasActiveAccutane(subscriptions) &&
        !accutane &&
        hasActiveMembership(subscriptions) &&
        followUps.length > 0 &&
        followUps[0].treatmentPlan?.medications.some(({ medicineKey }) =>
          medications.find((it) => it.id === medicineKey && it.isAccutane)
        ) &&
        patient.flags?.isRemovedInitiateAccutane !== true) ||
      false,
    action: () => {
      showPopup(AccutanePopupGenerator());
    },
    key: "initiateAccutaneAction",
  },
  {
    text: "Get blood work done",
    check: () => {
      // on first month for male this task should appear immediately, and after first month - 5 days before next confirmation date, for female - always 5 days before next confirmation date
      if (
        accutane &&
        isAccutaneTasksVisible(accutane) &&
        (!patient.flags.isStopBloodWorkTask || !accutane.bloodWork.createdAt)
      ) {
        if (
          hasActiveMembership(subscriptions) &&
          hasActiveAccutane(subscriptions)
        ) {
          if (patient.medicalBackground?.sex === "Male") {
            if (accutane.isOnboarding && !accutane.bloodWork.completed) {
              return true;
            }
          }
          const delta =
            new Date(accutane.nextConfirmationDate as string).getTime() / 1000 -
            Math.floor(Date.now() / 1000);
          // const delta = accutane.ttl - Math.floor(Date.now() / 1000);

          // TODO HERE WE CHECK THAT BLOODWORK SHOULD APPEAR ONLY 5 DAYS BEFORE RECONFIRMATION
          if (delta < SECONDS_IN_DAY * 5 && !accutane.bloodWork.completed) {
            return true;
          }
        }
      }

      return false;
    },
    action: () => {
      showPopup(BloodWorkPopupGenerator(patient.patientId, store.dispatch));
    },
    key: "getBloodworkDoneAction",
  },
  ...patientMonthlyTasks.map((task: any) => ({
    text: task.name,
    check: () => {
      if (accutane && isAccutaneTasksVisible(accutane)) {
        if (
          hasActiveMembership(subscriptions) &&
          hasActiveAccutane(subscriptions)
        ) {
          if (accutane.gender === "Male") {
            return false;
          }
          if (
            accutane.gender === "Female" &&
            !patient.flags?.isNotPregnantCapability
          ) {
            const delta =
              new Date(accutane.nextConfirmationDate as string).getTime() /
                1000 -
              Math.floor(Date.now() / 1000);

            if (!task.isCompleted) {
              return true;
            }
            if (!task.isCompleted && delta < SECONDS_IN_DAY * 5) {
              return true;
            }
          }
        }
      }

      return false;
    },
    action: () => {
      showPopup(task.action);
    },
    key: task.key,
  })),
  {
    text: "Sign iPledge consent",
    check: () => {
      if (accutane && isAccutaneTasksVisible(accutane)) {
        if (
          hasActiveMembership(subscriptions) &&
          hasActiveAccutane(subscriptions) &&
          !accutane.iPledgeConsent.signed
        ) {
          return true;
        }
      }

      return false;
    },
    action: () => {
      if (accutane) {
        showPopup(
          SignIpledgeConsentPopupGenerator(patient, "Patient", accutane)
        );
      }
    },
    key: "signIpledgeConsent",
  },
];

export function ActionsBlock({ patientId }: { patientId: string }) {
  const history = useHistory();
  const { showPopup } = useContext(PopupLayerContext);
  const patientQuery = useGetPatientByIdQuery(patientId);
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(patientId);
  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(patientId);
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const accutaneQuery = useGetAccutaneByPatientIdQuery(patientId);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(patientId);

  const isLoaded =
    patientQuery.isSuccess &&
    followUpsQuery.isSuccess &&
    appointmentsQuery.isSuccess &&
    medicationsQuery.isSuccess &&
    accutaneQuery.isSuccess &&
    subscriptionsQuery.isSuccess;

  const patientMonthlyTasks = getPatientMonthlyTasks(accutaneQuery.data);

  const govtIDDocuments = getGovtIDDocuments(patientId);

  const actionsList = isLoaded
    ? getPatientActionsList(
        accutaneQuery.data,
        patientQuery.data,
        medicationsQuery.data,
        followUpsQuery.data,
        appointmentsQuery.data,
        subscriptionsQuery.data,
        showPopup,
        history,
        govtIDDocuments,
        patientMonthlyTasks
      )
    : [];

  function renderActionItems() {
    return actionsList
      .filter(({ check }) => check())
      .map(({ text, check, action, key }) => (
        <div
          className={`action-items__item${!check() ? " checked" : ""}`}
          onClick={() => action()}
          key={key}
        >
          <div className="action-items__checkbox">
            <CheckIcon className="action-items__check-icon" />
          </div>
          <p className="action-items__text">{text}</p>
          <ChevronIcon className="action-items__chevron" />
        </div>
      ));
  }

  const actionItems = renderActionItems();

  return (
    <div className="welcome-card__actions-block">
      <div className="action-items__to-do">
        {isLoaded ? (
          <>
            <p>To do</p>
            <p className="action-items__counter">{actionItems?.length}</p>
          </>
        ) : (
          <Skeleton flex />
        )}
      </div>
      <div className="action-items">
        {isLoaded ? (
          <>
            {" "}
            {actionItems.length ? (
              actionItems
            ) : (
              <p className="action-items__no-tasks-text">
                You have no tasks to do now. Stay tuned!
              </p>
            )}
          </>
        ) : (
          <Skeleton count={3} flex column fullHeight fullWidth />
        )}
      </div>
    </div>
  );
}
