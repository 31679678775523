import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Footer } from "../../NewComponents/Common/Footer";
import { RescheduleAppointmentPage } from "../../NewPages/Common/RescheduleAppointmentPage";

interface Props {
  isAuthorised: boolean;
  setParamsId: (id: any) => void;
}

function EnrollmentRescheduleComponent({ isAuthorised, setParamsId }: Props) {
  const params = useParams();
  const paramsId = params.userId;

  useEffect(() => {
    setParamsId(paramsId);
  }, [paramsId]);

  return isAuthorised ? (
    <>
      <RescheduleAppointmentPage />
      <Footer />
    </>
  ) : (
    <Redirect to="/" />
  );
}

export default EnrollmentRescheduleComponent;
