import { createSlice } from "@reduxjs/toolkit";
import { Provider } from "../types/Entities/Provider";
import { ReduxAction, ReduxStore } from "../types/Stores";
import { ProvidersStore } from "../types/Stores/ProvidersStore";
import { SortingPayload } from "../types/Table/SortingPayload";
import { Accutane } from "../types/Entities/Accutane";
import { ProviderTask } from "../types/Provider";

const initialState: ProvidersStore = {
  loadingStatus: "idle",
  wasInitialized: false,
  list: [],
  sort: null,
  accutane: null,
  tasks: [],
  documentTasks: [],
};

export const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    setProviders(state, { payload }: ReduxAction<Provider[]>) {
      state.list = payload;
      state.wasInitialized = true;
    },
    clearProvidersStore(state) {
      state.list = [];
      state.wasInitialized = false;
      state.accutane = null;
      state.tasks = [];
      state.documentTasks = [];
    },
    setSorting(state, { payload }: ReduxAction<SortingPayload | null>) {
      state.sort = payload;
    },
    addProvider(state, { payload }: ReduxAction<Provider>) {
      state.list.push(payload);
      state.list = Array.from(state.list);
    },
    deleteProviderInStore(state, { payload }: ReduxAction<string>) {
      const index = state.list.findIndex(({ id }) => id === payload);
      state.list.splice(index, 1);
      state.list = Array.from(state.list);
    },
    updateProvider(state, { payload }: ReduxAction<Provider>) {
      const index = state.list.findIndex(({ id }) => id === payload.id);
      state.list[index] = {
        ...payload,
      };
      state.list = Array.from(state.list);
    },
    setAccutaneInfo(state, { payload }: ReduxAction<Accutane>) {
      state.accutane = payload;
    },
    setProviderTasks(state, { payload }: ReduxAction<ProviderTask[]>) {
      state.tasks = payload;
    },
    setDocumentsTasks(state, { payload }: ReduxAction<ProviderTask[]>) {
      state.documentTasks = payload;
    },
    updateProviderIpledge(state, { payload }: ReduxAction<any>) {
      state.accutane = { ...state.accutane, ...payload };
    },
    clearProviderStoreAccutane(state) {
      state.accutane = null;
    },
  },
});

export const {
  setProviders,
  clearProvidersStore,
  setSorting,
  addProvider,
  deleteProviderInStore,
  updateProvider,
  setAccutaneInfo,
  setProviderTasks,
  setDocumentsTasks,
  updateProviderIpledge,
  clearProviderStoreAccutane,
} = providersSlice.actions;

export const getProvidersInfoState = ({ providersStore }: ReduxStore) =>
  providersStore;

export const getProvidersList = ({ providersStore }: ReduxStore) =>
  providersStore?.list ?? [];

export const getAccutaneOfPatient = ({ providersStore }: ReduxStore) =>
  providersStore.accutane;

export const getProviderById =
  (providerId: string | undefined | null) =>
  ({ providersStore }: ReduxStore) =>
    providersStore.list.find(({ id }) => id === providerId);

export const getProviderTasks = ({ providersStore }: ReduxStore) =>
  providersStore.tasks;

export const getDocumentTasks = ({ providersStore }: ReduxStore) =>
  providersStore.documentTasks;

export default providersSlice.reducer;
