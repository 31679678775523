import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { isNull } from "util";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import {
  getLocalDateNonMilitary,
  getMomentDate,
} from "../../../../utils/get-date-pretty";
import { getLastName } from "../../../../utils/patient/get-last-name";
import { alphabetical, byDate } from "../../../../utils/sortings";
import {
  DoctorGreetingBanner,
  TaskColors,
} from "../../../NewComponents/Common/DoctorGreetingBanner";
import { PatientsTable } from "../../../NewComponents/Common/PatientsTable";
import "./style.scss";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { LoadingBlock } from "../../../NewComponents/Common/LoadingBlock";
// import {
//   mapToPatientsListForCareCoordinators,
//   PATIENT_STATUSES_FOR_CARE_COORDINATOR,
// } from "../../../../mappings/to-patients-list-for-care-coordinator";
import { ActionPopupGenerator } from "../../../NewComponents/Common/ActionPopup";
import { ReactComponent as UnpinIcon } from "../../../../Assets/NewIcons/pin.svg";
import { ReactComponent as PinIcon } from "../../../../Assets/NewIcons/unpin.svg";
import { ProviderTask } from "../../../../types/Provider";
import { patientsCustomSorting } from "../../../../features/utils/patientsCustomSorting";
import {
  useGetAssignedPatientsQuery,
  useGetPatientByIdQuery,
  useSetFlagMutation,
} from "../../../../features/api/patients";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { CareCoordinator } from "../../../../types/Employee";
import { PatientsTableTabs } from "../../../NewComponents/Common/PatientsTableElastic/tableTabs";
import {
  getPatientDashboardForCareCoordinatorFilters,
  getPatientDashboardForCareCoordinatorFiltersInactive,
} from "./filters";
import { PatientsTableElastic } from "../../../NewComponents/Common/PatientsTableElastic";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { patientDashboardForCareCoordinatorSort } from "./sortings";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { Stylings } from "../../../../types/Table/TableBodyPayload";
import { ChatWidget } from "../../../NewComponents/Common/ChatWidget";
import { getTwilioState } from "../../../../features/twilio";
import { TwilioController } from "../../../../services/twilio";
import { wait } from "../../../../utils/wait";
import { patientDashboardForCareCoordinatorOrder } from "./orders";
import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { pubnubClient } from "../../../../services/pubnub";
import { getAllChannelMetadata } from "../../../../utils/patient/pubnubChatCount";
import { getPatientDashboardForProviderFilters } from "../../Provider/PatientsPage/filters";
import { patientDashboardForAdministratorFilters } from "../../Administrator/PatientsPage/filters";
import { HoneydewAPI } from "../../../../services/honeydew-api";

function getStyleForDashboard(employeeId: string) {
  return (payload: PatientDashboardItem, currentFilter?: string) => {
    switch (true) {
      case currentFilter === "New treatment plans":
        return Stylings.Blue;
      case currentFilter === "Miscellaneous tasks":
        return Stylings.Orange;
      case payload.followUp &&
        payload.followUp.isTreatmentPlanSet &&
        !payload.followUp.isPrescriptionSubmitted:
        return Stylings.Blue;
      case payload.tasks?.includes(employeeId):
        return Stylings.Orange;
      default:
        return Stylings.Base;
    }
  };
}

function PinButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [togglePin] = useSetFlagMutation();
  const { userId: careCordId } = useSelector(getSessionState);

  const previousPinnedFlag = "pinnedByCareCoordinator";
  const pinnedFlag = `pinnedByCareCoordinator_${careCordId}`;
  const pinned =
    !!payload.flags[pinnedFlag] || !!payload.flags[previousPinnedFlag];

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          ActionPopupGenerator({
            title: pinned ? "Unpin patient?" : "Pin patient to the top?",
            text: pinned
              ? `Are you sure you want to unpin ${payload.fullName}?`
              : `Are you sure you want to pin ${payload.fullName} to the top?`,
            action: async () => {
              const response: any = await togglePin({
                patientId: payload.patientId,
                flag: pinnedFlag,
                state: !pinned,
              });
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: `Unable to ${pinned ? "un" : ""}pin patient`,
                });
                throw new Error(`Unable to ${pinned ? "un" : ""}pin patient`);
              }

              // If unpinning, also set the previousPinnedFlag to false
              if (pinned && payload.flags[previousPinnedFlag]) {
                const previousFlagResponse: any = await togglePin({
                  patientId: payload.patientId,
                  flag: previousPinnedFlag,
                  state: false,
                });
                if (previousFlagResponse.error) {
                  showError({
                    title: "Something went wrong...",
                    description: `Unable to unpin patient from the old flag`,
                  });
                  throw new Error(`Unable to unpin patient from the old flag`);
                }
              }

              await wait(3000);
              reload();
              showSuccess({
                title: "Success!",
                description: `You've successfully ${
                  pinned ? "un" : ""
                }pinned a patient!`,
              });
            },
            actionText: pinned ? "Unpin" : "Pin",
          })
        );
      }}
      data-tooltip={pinned ? "Unpin" : "Pin"}
    >
      {pinned ? <UnpinIcon /> : <PinIcon />}
    </div>
  );
}

const tableColumns: TableHeadPayload<PatientDashboardItem>[] = [
  {
    title: "Full name",
    get: (payload) => getLastName(payload.fullName),
    getPretty: (payload) => (
      <p>
        {payload.fullName}{" "}
        {!payload.followUp ? (
          <span
            className="patients-table__attention-sign"
            data-tooltip="No medical background"
          >
            !
          </span>
        ) : null}
        {payload.subscription?.accutane ? (
          <span className="patients-table__accutane-sign">Accutane</span>
        ) : null}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "lastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['lastNameNumberValue'].value  / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Birth",
    get: (payload) => payload.dateOfBirth,
    getPretty: (payload) => (
      <p>
        {getMomentDate(payload.dateOfBirth)
          .add(12, "hours")
          .format("MMM Do, YYYY")}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "dateOfBirthDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } doc['dateOfBirthDate'].value / 100000.0`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Service",
    get: () => 0,
    getPretty: ({ followUp }) => (
      <p>
        {followUp?.timestamp
          ? getLocalDateNonMilitary(followUp.timestamp)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "followUp.timestamp",
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Next Follow-Up",
    get: () => 0,
    getPretty: (payload) => (
      <p>
        {payload.followUp?.nextFollowUpDate
          ? getLocalDateNonMilitary(payload.followUp.nextFollowUpDate)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "followUp.nextFollowUpDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.nextFollowUpDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Actions",
    get: () => "",
    getPretty: (payload, reload) => (
      <div className="patients-table__actions">
        {payload.subscription?.membership ||
        ((payload.appointmentStatus === "PENDING" ||
          payload.appointmentStatus === "CONFIRMED") &&
          (!payload.followUp || payload.followUp.isInitialFollowUp)) ? (
          <PinButton payload={payload} reload={reload} />
        ) : null}
      </div>
    ),
  },
];

export function PatientsPageForCareCoordinator() {
  const dispatch = useDispatch();
  const { initialized } = useSelector(getTwilioState);
  const [newMessagesCount, setNewMessagesCount] = useState<number | null>(null);
  const history = useHistory();
  const { userId: careCoordinatorId, newMsgsCount } =
    useSelector(getSessionState);
  const [tab, setTab] = useState(0);
  const [tableParams, setTableParams] = useState<
    { filters: any; sort: any; order: any }[]
  >([]);
  const careCoordinatorQuery = useGetEmployeeQuery(
    careCoordinatorId as string,
    {
      skip: !careCoordinatorId,
    }
  );
  const careCoordinator = careCoordinatorQuery.data as
    | CareCoordinator
    | undefined;

  const [filterArray, setFilterArray] = useState([]);
  const [filteredChat, setFilteredChat] = useState<any>([]);
  const [patientIds, setPatientIds] = useState<any>([]);

  useEffect(() => {
    const role = careCoordinatorQuery?.currentData?.role;
    if (filterArray.length === 0 && role) {
      let newFilterArray;
      switch (role) {
        case "care-coordinator":
          newFilterArray = getPatientDashboardForCareCoordinatorFilters(
            careCoordinatorId as string
          );
          break;
        default:
          break;
      }
      if (newFilterArray) {
        setFilterArray(newFilterArray);
      }
    }
  }, [filterArray, careCoordinatorQuery, careCoordinatorId]);

  useEffect(() => {
    if (filterArray.length > 0) {
      const fetchData = async () => {
        const { filter } = filterArray[0];
        let mustQuery = filter.must;
        if (mustQuery instanceof Array) {
          mustQuery = [...mustQuery];
        } else if (mustQuery instanceof Object) {
          mustQuery = [mustQuery];
        } else {
          mustQuery = [];
        }
        try {
          const query = {
            query: {
              from: 0,
              size: 10000,
              query: {
                // when you comment this query the code starts working with extra records
                function_score: {
                  query: {
                    bool: {
                      ...filter,
                    },
                  },
                },
              },
            },
          };
          const results = await HoneydewAPI.dashboard.queryDashboardItems(
            query
          );
          const filteredChats = results.hits.filter((result: any) => {
            if (!result._source.subscription) {
              return true;
            }

            return result._source.subscription.membership === true;
          });
          setFilteredChat(filteredChats);
          setPatientIds(filteredChats.map((chat) => chat._source.patientId));
        } catch (error) {
          console.log("error in fetching table data", error);
        }
      };
      fetchData();
    }
  }, [filterArray]);

  useEffect(() => {
    // if (!newMsgsCount) {
    if (filteredChat.length && !newMsgsCount && patientIds.length > 0) {
      const fetchData = async () => {
        try {
          const metadataFlags = await getAllChannelMetadata(
            careCoordinatorId,
            patientIds
          );
          const uniqueMetadataFlags = metadataFlags.reduce((acc, obj) => {
            acc[obj.id] = obj;
            return acc;
          }, {});
          const uniqueMetadataArray = Object.values(uniqueMetadataFlags);

          const newMsgs = uniqueMetadataArray.filter(
            (obj) => obj.custom[careCoordinatorId] === false
          );
          const filteredResult = filteredChat.filter((chat) => {
            const chatMetaData = newMsgs.find(
              (metaData) => metaData.id === chat._source.patientId
            );
            if (chatMetaData) {
              return true;
            }
            return false;
          });
          dispatch(setNewMsgsCount(filteredResult.length));
          // setNewMessagesCount(newMsgs?.length);
          // dispatch(setNewMsgsCount(newMsgs?.length));
        } catch (error) {
          console.error("Error fetching metadata flags:", error);
        }
      };

      fetchData();
    }
    // }
  }, [filteredChat, patientIds]);

  useEffect(() => {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    if (!isNull(currentIndexFormatted)) {
      setTab(currentIndexFormatted.currentTab);
    }
  }, []);

  useEffect(() => {
    if (!careCoordinatorId) return;
    setTableParams([
      {
        filters:
          getPatientDashboardForCareCoordinatorFilters(careCoordinatorId),
        sort: patientDashboardForCareCoordinatorSort(),
        order: patientDashboardForCareCoordinatorOrder(careCoordinatorId),
      },
      {
        filters:
          getPatientDashboardForCareCoordinatorFiltersInactive(
            careCoordinatorId
          ),
        sort: [],
        order: [],
      },
    ]);
  }, [careCoordinatorId]);
  if (!careCoordinator) return null;

  function renderTable() {
    if (!tableParams[tab]) return null;
    switch (tab) {
      case 0:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            stylizeRow={getStyleForDashboard(careCoordinator?.id as string)}
            onClick={(item, state) => {
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              window.scrollTo(0, 0);
              history.push(`/${item.patientId}`);
            }}
          />
        );
      case 1:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      default:
        return null;
    }
  }

  const careCoordinatorName = careCoordinator
    ? [careCoordinator.firstName, careCoordinator.lastName]
    : null;

  const tabs = ["Your active patients", "Your inactive patients"];

  return (
    <div className="greeting-container">
      <DoctorGreetingBanner
        items={[
          {
            text: "New treatment plans",
            filter: getPatientDashboardForCareCoordinatorFilters(
              careCoordinator.id
            ).find(({ text }) => text === "New treatment plans")?.filter,
            color: TaskColors.LightBlue,
          },
          {
            text: "New messages",
            count: newMsgsCount ?? undefined,
            color: TaskColors.Green,
          },
          {
            text: "Miscellaneous tasks",
            filter: getPatientDashboardForCareCoordinatorFilters(
              careCoordinator.id
            ).find(({ text }) => text === "Miscellaneous tasks")?.filter,
            color: TaskColors.Orange,
          },
        ]}
        name={careCoordinatorName ? careCoordinatorName.join(" ") : null}
      />
      <div className="patients-table">
        <div className="patients-table__tabs">
          <PatientsTableTabs
            tabs={tabs}
            onChange={(state) => {
              setTab(state);
            }}
          />
        </div>
      </div>
      {renderTable()}
    </div>
  );
}
