import React, { useContext } from "react";
import "./style.scss";
import { Button } from "../Button";
import {
  ActionPopupTemplate,
  ActionPopupTemplateContext,
} from "../PopupLayer/action";

interface Props {
  onClose: () => void;
}

interface ComponentProps {
  title: string;
  text: string;
  action: () => void | Promise<void>;
  actionText: string;
}

function ActionPopup({ title, text, action, actionText }: ComponentProps) {
  const { onClose } = useContext(ActionPopupTemplateContext);

  return (
    <div className="action-popup">
      <div className="action-popup__message-block">
        <p className="action-popup__title">{title}</p>
        <p className="action-popup__descr paragraph-font--color">{text}</p>
      </div>
      <div className="action-popup__actions">
        <Button
          text="cancel"
          view="secondary"
          onClick={onClose}
          className="action-popup__action"
        />
        <Button
          text={actionText}
          className="action-popup__action"
          onClick={async () => {
            const result = action();

            if (result instanceof Promise) {
              await result;
            }

            onClose();
          }}
        />
      </div>
    </div>
  );
}

export function ActionPopupGenerator(props: ComponentProps) {
  return function render({ onClose }: Props) {
    return (
      <ActionPopupTemplate onClose={onClose}>
        <ActionPopup {...props} />
      </ActionPopupTemplate>
    );
  };
}
