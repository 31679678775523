import React, { useState } from "react";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { Stepper } from "../../../../NewComponents/Common/Stepper";
import { MembershipOptions } from "../../../../NewComponents/Patient/MembershipOptions";

export function MembershipPlanSurveyPage({
  onDone,
  stepper: { max, current },
}: SurveyComponentProps) {
  const [membership, setMembership] = useState<string | null>(null);

  return (
    <div className="survey-question initiate-checkout-page__page">
      <Stepper max={max} current={current} />
      <p className="survey-question__title">Choose your membership plan</p>

      <p className="survey-question__descr paragraph-font--color">
        Please, note that medications are not included in the price of
        membership. They are prescribed to outside pharmacies and may be covered
        by insurance.
      </p>
      <div className="initiate-checkout-page__treatment">
        <MembershipOptions onChoose={(value) => setMembership(value)} />
      </div>
      <div className="initiate-checkout-page__actions">
        <Button
          text="continue"
          onClick={() =>
            onDone({
              membershipPlan: membership,
            })
          }
          className="initiate-checkout-page__submit-button"
          disabled={!membership}
        />
      </div>
    </div>
  );
}
