export default function getCookie(name: string) {
  if (!name) {
    throw new Error("Cookie name should be specified");
  }

  const safeName = name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1");
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${safeName}=([^;]*)`)
  );

  return matches ? decodeURIComponent(matches[1]) : null;
}
