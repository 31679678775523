import axios from "axios";
import { Patient, ShippingInfo } from "../../../../../types/Entities/Patient";
import { ENV_CONFIG } from "../../../../../config";

export const createStripeCustomer = async (
  id: string,
  shippingInfo: ShippingInfo
) => {
  const { data } = await axios.post<Patient>(
    `${ENV_CONFIG.AMAZON_API_DOMAIN}/payments/create-customer`,
    {
      id,
      shippingInfo,
    }
  );

  return data;
};
