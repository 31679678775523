import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { logError } from "../../../../../../shared/logger";
import { NotificationLayerContext } from "../../../NotificationsLayout";
import "./style.css";
import { getSessionState } from "../../../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../../../features/api/patients";
import { useProposeMeetingMutation } from "../../../../../../features/api/appointments";

enum STATES {
  IDLE = "idle",
  PENDING = "pending",
  SUCCESS = "success",
  RESEND = "RESEND",
}

export function ProposeMeetingButton() {
  const { showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [proposeMeeting] = useProposeMeetingMutation();
  const [state, setState] = useState<STATES | null>(null);

  useEffect(() => {
    if (patientQuery.isSuccess) {
      setState(patientQuery.data.scheduleLink ? STATES.RESEND : STATES.IDLE);
    } else {
      setState(null);
    }
  }, [patientQuery.isSuccess]);

  const sendSchedulingLinkToPatient = async () => {
    if (!activePatientId) return;
    setState(STATES.PENDING);

    try {
      const result: any = await proposeMeeting(activePatientId);
      if (result.error || result.data.errors) {
        throw new Error("Error occured");
      }
      setState(STATES.SUCCESS);
    } catch (e) {
      setState(STATES.IDLE);
      logError("Error during sending meeting invitation to patient", e);
      showError({
        title: "Something went wrong",
        description: "Unable to send a schedule link to the patient",
      });
    }
  };

  switch (state) {
    case STATES.IDLE:
      return (
        <button
          type="button"
          className="propose-meeting-button"
          onClick={sendSchedulingLinkToPatient}
        >
          Send New Calendar Link
        </button>
      );
    case STATES.RESEND:
      return (
        <button
          type="button"
          className="propose-meeting-button"
          onClick={sendSchedulingLinkToPatient}
        >
          Resend Calendar Link
        </button>
      );
    case STATES.PENDING:
      return (
        <button type="button" className="propose-meeting-button" disabled>
          Sending...
        </button>
      );
    case STATES.SUCCESS:
      return (
        <button type="button" className="propose-meeting-button" disabled>
          Calendar Link Sent
        </button>
      );
    default:
      return null;
  }
}
