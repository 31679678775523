import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";

interface Body {
  dateFrom: number;
  dateTo: number;
}

export const generateReport = async (
  providerId: string,
  { dateFrom, dateTo }: Body
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<{
      billingReport: string;
    }>(
      `${ENV_CONFIG.EMPLOYEES_SERVICE_REST_URL}/get-billing-report?id=${providerId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get provider report");
    throw error;
  }
};
