import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";
import { DashboardQueryResult } from "../../../types/Dashboard";

export async function queryDashboardItems<T>(query: any) {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<any, { data: DashboardQueryResult<T> }>(
      `${ENV_CONFIG.QUERY_DASHBOARD_ITEMS_URL}/search`,
      query,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error: any) {
    logError("Error occured. Cannot get dashboard items", error);
    throw error;
  }
}
