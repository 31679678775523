import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as PricingCheckBox } from "../../../../Assets/NewIcons/pricing-checkmark.svg";
import { ReactComponent as PricingPlus } from "../../../../Assets/NewIcons/pricing-plus.svg";
import { ReactComponent as LineThrough } from "../../../../Assets/NewIcons/line-through.svg";
import "./style.scss";
import { useGetPricesQuery } from "../../../../features/api/payment";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { PRICES_KEYWORDS } from "../../../../data/prices-keywords";
import { Skeleton } from "../../Common/Skeleton";

interface Props {
  onChoose: (membershipType: string) => void;
}

export function MembershipOptions({ onChoose }: Props) {
  const { showError } = useContext(NotificationLayerContext);
  const [membershipType, setMembershipType] = useState<string | null>(null);
  const [memberships, setMemberships] = useState<{
    month: string;
    year: string;
  } | null>(null);
  const pricesQuery = useGetPricesQuery(null);

  useEffect(() => {
    if (pricesQuery.isError) {
      showError({
        title: "Something went wrong",
        description: "Unable to get membership plans. Please, try again later",
      });
      return;
    }
    if (pricesQuery.isSuccess) {
      const _memberships = pricesQuery.data.filter(
        ({ type, subscriptionType }) =>
          type === "subscription" && subscriptionType === "membership"
      );
      const yearMembership = _memberships.find(({ keywords }) =>
        keywords.includes(PRICES_KEYWORDS.yearMembership)
      );
      const monthMembership = _memberships.find(({ keywords }) =>
        keywords.includes(PRICES_KEYWORDS.monthMembership)
      );

      if (!yearMembership || !monthMembership) {
        showError({
          title: "Something went wrong",
          description: "Cannot find a membership by keyword",
        });
        return;
      }

      setMemberships({
        month: monthMembership.id,
        year: yearMembership.id,
      });
      setMembershipType(yearMembership.id);
    }
  }, [pricesQuery.isError, pricesQuery.isSuccess]);

  useEffect(() => {
    if (membershipType) onChoose(membershipType);
  }, [membershipType]);

  return (
    <div className="membership-options">
      <div className="membership-options__inner">
        <div className="membership-options__picker">
          <div className="membership-options__picker-items">
            <div
              className={`membership-options__picker-item ${
                memberships && membershipType === memberships?.year
                  ? "membership-options__picker-item--selected"
                  : ""
              }`}
              onClick={() => {
                if (memberships) setMembershipType(memberships.year);
              }}
            >
              {memberships?.year ? (
                <>
                  {" "}
                  <div className="membership-options__discount">Save $169</div>
                  <div className="membership-options__full-price paragraph-font--color">
                    <LineThrough className="membership-options__line-through" />
                    $468
                  </div>
                  <p className="membership-options__picker-cost">$299</p>
                  <p className="membership-options__picker-text paragraph-font--color paragraph-font--margin">
                    yearly
                  </p>
                </>
              ) : (
                <Skeleton flex fullHeight fullWidth />
              )}
            </div>
            <div
              className={`membership-options__picker-item ${
                memberships && membershipType === memberships?.month
                  ? "membership-options__picker-item--selected"
                  : ""
              }`}
              onClick={() => {
                if (memberships) setMembershipType(memberships.month);
              }}
            >
              {memberships?.month ? (
                <>
                  {" "}
                  <div className="membership-options__full-price" />
                  <p className="membership-options__picker-cost">$39</p>
                  <p className="membership-options__picker-text paragraph-font--color">
                    monthly
                  </p>
                  <p className="membership-options__picker-text membership-options__picker-text--lowercase paragraph-font--color">
                    (3-month minimum)
                  </p>
                </>
              ) : (
                <Skeleton flex fullHeight fullWidth />
              )}
            </div>
          </div>
        </div>
        <div className="membership-options__description">
          <div className="membership-options__description-group">
            <p className="membership-options__description-title">
              Membership includes*
            </p>
            <ul className="membership-options__description-list">
              <li className="membership-options__description-list-item">
                <PricingCheckBox className="membership-options__description-list-icon" />
                <p>All Honeydew clinician consultations</p>
              </li>
              <li className="membership-options__description-list-item">
                <PricingCheckBox className="membership-options__description-list-icon" />
                <p>Prescription management</p>
              </li>
              <li className="membership-options__description-list-item">
                <PricingCheckBox className="membership-options__description-list-icon" />
                <p>Progress tracking</p>
              </li>
              <li className="membership-options__description-list-item">
                <PricingCheckBox className="membership-options__description-list-icon" />
                <p>Ongoing support from your dedicated care team</p>
              </li>
            </ul>
          </div>
          <div className="membership-options__description-group">
            <p className="membership-options__description-title">Add-ons</p>
            <ul className="membership-options__description-list">
              <li className="membership-options__description-list-item">
                <PricingPlus className="membership-options__description-list-icon" />
                <p>$25/month extra for Accutane management, if prescribed</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="membership-options__actions">
          <p className="membership-options__actions-text">
            *Please, note that medications are not included in the price of
            membership. They are prescribed to outside pharmacies and may be
            covered by insurance.
          </p>
        </div>
      </div>
    </div>
  );
}
