import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import {
  Patient,
  TreatmentPlanData,
} from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const submitTreatmentPlan = async (
  userId: string,
  treatmentPlanData: TreatmentPlanData,
  params: { [p: string]: string | boolean }
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/submit-treatment-plan`,
      {
        userId,
        treatmentPlanData,
        ...params,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot submit treatment plan");
    throw error;
  }
};
