import { useSelector } from "react-redux";
import React, { useContext, useState } from "react";
import axios from "axios";
import { ImageBlob } from "../../../../types/Base/ImageBlob";
import generateHash from "../../../../utils/generate-hash";
import { Button } from "../../Common/Button";
import { ImageUploadTile } from "../ImageUploadTile";
import { Props } from "../FormsOfPregnancyPopup/types";
import { StyledSelect } from "../../Common/StyledSelect";
import "./style.scss";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { DocumentImageFileProps } from "../../../../services/acneaway-api/entities/providers/upload-document";
import { ReactComponent as DocumentIcon } from "../../../../Assets/NewIcons/document.svg";
import { getSessionState } from "../../../../features/session";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import { getCareCoordinatorByCoordinatorId } from "../../../../features/careCoordinators";
import {
  useGetPatientByIdQuery,
  useGetCareTeamQuery,
} from "../../../../features/api/patients";
import {
  useUploadDocumentMutation,
  useGetPresignedUrlMutation,
} from "../../../../features/api/accutane";
import { ENV_CONFIG } from "../../../../config";

interface DocumentTypeForm {
  image: Blob | ImageBlob | null;
  documentTitle: string | null;
}
enum FormIds {
  image = "image",
  documentTitle = "documentTitle",
}
function UploadImagePreview() {
  return (
    <div className="upload-documents-popup__uploader--preview">
      <DocumentIcon />
      <p className="upload-documents-popup__uploader--preview-description">
        Document uploaded
      </p>
    </div>
  );
}
function DiscardButton({ discardImage }: { discardImage?: () => void }) {
  const handleDiscardImage = () => {
    if (discardImage) {
      discardImage();
    }
  };
  return (
    <p
      onClick={handleDiscardImage}
      className="upload-documents-popup__uploader__discard-button"
    >
      Replace
    </p>
  );
}
function UploadDocumentsPopup({ onClose }: { onClose: () => void }) {
  const { showSuccess } = useContext(NotificationLayerContext);
  const {
    userRole,
    userId: employeeId,
    activePatientId,
  } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [uploadDocument] = useUploadDocumentMutation();
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [uploadDocumentForm, setFormField] = useState<DocumentTypeForm>({
    [FormIds.image]: null,
    [FormIds.documentTitle]: null,
  });

  if (!patientQuery.data) {
    return null;
  }

  const careTeamQuery = useGetCareTeamQuery(activePatientId);
  const patientCoordinators = careTeamQuery.data?.filter(
    (team) => team.role === "care-coordinator"
  );

  const generateImagesFilename = () => {
    if (!activePatientId)
      throw new Error("Cannot generate file name without patient ID");
    const extension = `${uploadDocumentForm.image?.type.split("/").pop()}`;

    return `${activePatientId}.${generateHash(10)}.${extension}`;
  };

  const onSubmit = async () => {
    const filename = generateImagesFilename();
    if (!patientQuery.data) return;
    const uploadFile = await new Promise<DocumentImageFileProps>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        resolve({
          contentType: uploadDocumentForm.image?.type ?? "",
          filename,
        });
      };
      if (uploadDocumentForm.image) {
        reader.readAsDataURL(uploadDocumentForm.image);
      }
    });

    if (userRole && employeeId) {
      const response = await getPresignedUrl({
        patientId: patientQuery.data.patientId,
        document: {
          ...uploadFile,
        },
      });
      if (response?.data && response.data?.preSignedUrl) {
        const data = await axios.put(
          response.data.preSignedUrl,
          uploadDocumentForm.image,
          {
            headers: {
              "Content-Type": uploadDocumentForm.image?.type as string,
            },
          }
        );
        if (data.status === 200) {
          const result = await uploadDocument({
            patientId: patientQuery.data.patientId,
            document: {
              url: `${ENV_CONFIG.AMAZON_S3_PATIENT_DOCUMENT_DOMAIN}/${filename}`,
              comment: "",
              assignedTo: null,
              assignedTo: {
                id: patientCoordinators[0]?.employeeId ?? "",
                role: "Care coordinator",
              },
              title: "Government-Issued ID",
              type: "governmentIssuedId",
              assignedBy: {
                id: employeeId,
                role: userRole,
              },
              uploadedBy: {
                id: patientQuery.data.patientId,
                name: patientQuery.data.fullName,
              },
              taskName: "Review uploaded government issued ID",
            },
          });
          if (result) {
            showSuccess({
              title: "Success",
              description: "Document successfully uploaded",
            });
          }
        }
      }
    }
    onClose();
  };

  const setImageHandler = (image: Blob | ImageBlob | null) =>
    setFormField({ ...uploadDocumentForm, image });

  const customPreview =
    uploadDocumentForm.image?.type !== "application/pdf" ? null : (
      <UploadImagePreview />
    );
  const customDiscardButton =
    uploadDocumentForm.image?.type !== "application/pdf" ? null : (
      <DiscardButton />
    );
  return (
    <div className="upload-document-form">
      <p className="upload-document-form__title">Upload</p>
      <p className="upload-document-form__title-body">
        Please upload a photo of your government-issued ID
      </p>
      <div className="upload-document-form__uploader">
        <ImageUploadTile
          side="center"
          setImage={setImageHandler}
          customPreview={<UploadImagePreview />}
          customDiscardButton={<DiscardButton />}
          placeholderClassName="upload-document-form__uploader-placeholder"
          chooseFileText="Choose a document"
        />
      </div>
      <div className="upload-document-form__control-buttons">
        <Button text="Cancel" size="small" view="secondary" onClick={onClose} />
        <Button
          text="UPLOAD"
          onClick={onSubmit}
          size="small"
          className="upload-document-form__control-buttons__submit"
          disabled={!uploadDocumentForm.image}
        />
      </div>
    </div>
  );
}
export function UploadDocumentPopupModal() {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <UploadDocumentsPopup onClose={onClose} />
      </HelpPopupTemplate>
    );
  };
}
export default UploadDocumentPopupModal;
