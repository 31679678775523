import React from "react";
import "./style.scss";

interface Props {
  required?: boolean;
  disabled?: boolean;
  value?: string;
  label?: string;
  error?: string;
  id: string;
  name: string;
  className?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  step?: number;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export function NumberInput({
  required,
  disabled,
  value,
  label,
  error,
  onChange,
  id,
  name,
  placeholder,
  min,
  max,
  step,
  className,
  onBlur,
}: Props) {
  function renderLabel() {
    if (!label) return null;
    return (
      <label
        className="text-input__label label-font label-font--color"
        htmlFor={id}
      >
        {label}
        {required ? " *" : null}
      </label>
    );
  }
  return (
    <div className={`text-input ${className || ""}`}>
      {renderLabel()}
      <input
        className={`text-input__field input-font input-font--color${
          error ? " text-input__field--error" : ""
        }`}
        type="number"
        onClick={onBlur}
        onChange={(event) => onChange(event.target.value)}
        required={required}
        disabled={disabled}
        id={id}
        name={name}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        value={value}
      />
      {error ? (
        <p className="text-input__error-text error-font error-font--color">
          {error}
        </p>
      ) : null}
    </div>
  );
}
