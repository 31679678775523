export const MONTH_NAMES = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const literal = {
  character: {
    FORWARD_SLASH: "/",
  },
};

const development = {
  ADMINS_LIST: [
    {
      cognitoUserId: "b5b5a9cf-3348-44f3-ae8f-6cf558125d6f",
      phone: "+48603467716",
      fullName: "Yahor Admin",
      email: "philipok4596@gmail.com",
      group: "admin",
      groupFriendly: "Administrator",
    },
  ],
};

const production = {
  ADMINS_LIST: [
    {
      cognitoUserId: "6e7f5a4f-865f-4013-b79a-93a5fcf0521d",
      phone: "+15165321199",
      fullName: "David Futoran",
      email: "david.futoran@honeydewcare.com",
      group: "admin",
      groupFriendly: "Administrator",
    },
    {
      cognitoUserId: "33145a4f-865f-4013-b79a-93a5fcf0521d",
      phone: "+18018343024", // TODO
      fullName: "Tamazine Davison",
      email: "tamazine.davison@honeydewcare.com",
      group: "admin",
      groupFriendly: "Administrator",
    },
  ],
};

export const diagnosisCodesList = [
  "Acne Vulgaris L70.0",
  "Rosacea L71.9",
  "Atopic Dermatitis L20.9",
  "Plaque Psoriasis L40.0",
  "Vitiligo L80",
  "Hydradenitis Suppurativa L73.2",
  "Alopecia L63.9",
];

export const MODE = process.env.REACT_APP_ENV as string;
export const { ADMINS_LIST } =
  process.env.ENVIRONMENT === "PROD" ? production : development;

// July 7th, 2020
export const APPLICATION_START_POINT_TIMESTAMP = 1599764800000;
