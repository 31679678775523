import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { Button } from "../../Common/Button";
import { RadioButtonsForm } from "../../Common/RadioButtonsForm";
import "./style.scss";
import {
  EFormsOfPregnancyConfirmOptions,
  EPreviousPregnancyConfirmResults,
  EPrimaryFormOptions,
  ESecondaryFormOptions,
  PrimaryOptionsProps,
  Props,
  SecondaryOptionsProps,
  SelectOption,
} from "./types";
import { StyledSelect } from "../../Common/StyledSelect";
import {
  getPatientAccutane,
  getPatientStore,
  storeAccutaneInfo,
} from "../../../../features/patientView";
import { Accutane } from "../../../../types/Entities/Accutane";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import {
  useConfirmPregnancyPreventionMutation,
  useGetAccutaneByPatientIdQuery,
} from "../../../../features/api/accutane";
import { NotificationLayerContext } from "../../Common/NotificationLayer";

interface FormState {
  currentOption: EFormsOfPregnancyConfirmOptions | string;
  previousResultsOption: EPreviousPregnancyConfirmResults | string;
}

interface FormFields {
  primaryForm: SelectOption | null;
  secondaryForm: SelectOption | null;
}

const primaryFormOptions: PrimaryOptionsProps[] = [
  {
    label: "Birth control pill (Combination Type)",
    value: EPrimaryFormOptions.birthControlPill,
  },
  {
    label: "Hormonal Patch",
    value: EPrimaryFormOptions.hormonalPatch,
  },
  {
    label: "Hormonal IUD",
    value: EPrimaryFormOptions.hormonalIUD,
  },
  {
    label: "Hormonal Implants under-the-skin",
    value: EPrimaryFormOptions.hormonalImplantsUnderTheSkin,
  },
  {
    label: "Hormonal Shot",
    value: EPrimaryFormOptions.hormonalShot,
  },
  {
    label: "Non-Hormonal IUD",
    value: EPrimaryFormOptions.nonHormonalIUD,
  },
  {
    label: "Male Vasectomy",
    value: EPrimaryFormOptions.maleVasectomy,
  },
  {
    label: "Vaginal Ring",
    value: EPrimaryFormOptions.vaginalRing,
  },
  {
    label: "Tube Sterilization (tying your tubes)",
    value: EPrimaryFormOptions.tubeSterilization,
  },
];

const secondaryFormOptions: SecondaryOptionsProps[] = [
  {
    label: "Birth control pill (Combination Type)",
    value: ESecondaryFormOptions.birthControlPill,
  },
  {
    label: "Hormonal Patch",
    value: ESecondaryFormOptions.hormonalPatch,
  },
  {
    label: "Hormonal IUD",
    value: ESecondaryFormOptions.hormonalIUD,
  },
  {
    label: "Hormonal Implants under-the-skin",
    value: ESecondaryFormOptions.hormonalImplantsUnderTheSkin,
  },
  {
    label: "Hormonal Shot",
    value: ESecondaryFormOptions.hormonalShot,
  },
  {
    label: "Non-Hormonal IUD",
    value: ESecondaryFormOptions.nonHormonalIUD,
  },
  {
    label: "Male Vasectomy",
    value: ESecondaryFormOptions.maleVasectomy,
  },
  {
    label: "Vaginal Ring",
    value: ESecondaryFormOptions.vaginalRing,
  },
  {
    label: "Tube Sterilization (tying your tubes)",
    value: ESecondaryFormOptions.tubeSterilization,
  },
  {
    label: "Male latex condoms",
    value: ESecondaryFormOptions.maleLatexCondoms,
  },
  {
    label: "Cervical Cap",
    value: ESecondaryFormOptions.cervicalCap,
  },
  {
    label: "Diaphragm (with spermicide)",
    value: ESecondaryFormOptions.diaphragmWithSpermicide,
  },
  {
    label: "Vaginal Sponge",
    value: ESecondaryFormOptions.vaginalSponge,
  },
];

function getDataToSend(
  formFields: FormFields,
  formState: FormState,
  previousAccutane: Accutane | null
): { primaryForm: any; secondaryForm: any } {
  if (
    formState.previousResultsOption === EPreviousPregnancyConfirmResults.yes
  ) {
    return {
      primaryForm: previousAccutane?.birthControl?.primaryForm,
      secondaryForm: previousAccutane?.birthControl?.secondaryForm,
    };
  }
  if (formState.currentOption === EFormsOfPregnancyConfirmOptions.abstinence) {
    return {
      primaryForm: EFormsOfPregnancyConfirmOptions.abstinence,
      secondaryForm: null,
    };
  }

  return {
    primaryForm: formFields.primaryForm?.label,
    secondaryForm: formFields.secondaryForm?.label,
  };
}

// todo add types
function SecondOptionPrimaryForm({
  onChange,
  options,
  value,
}: {
  onChange: any;
  options: PrimaryOptionsProps[];
  value: any;
}) {
  return (
    <div className="pregnancy-prevention-popup__secondary-form-select">
      <StyledSelect
        id="primaryForm"
        name="primaryForm"
        value={value}
        options={options}
        onChange={onChange}
        isClearable
      />
    </div>
  );
}

// todo add types
function SecondOptionSecondaryForm({
  onChange,
  options,
  value,
}: {
  onChange: any;
  options: SecondaryOptionsProps[];
  value: any;
}) {
  return (
    <div className="pregnancy-prevention-popup__secondary-form-select">
      <StyledSelect
        id="secondaryForm"
        name="secondaryForm"
        value={value}
        options={options}
        onChange={onChange}
      />
    </div>
  );
}

const optionsList = (
  onChange: (fieldId: string) => (option: { option: SelectOption }) => void,
  formState: {
    primaryForm: SelectOption | null;
    secondaryForm: SelectOption | null;
  },
  currentOption: EFormsOfPregnancyConfirmOptions
) => {
  const primaryOptions = formState.secondaryForm?.value
    ? primaryFormOptions.filter(
        (option) => option.value !== formState?.secondaryForm?.value
      )
    : primaryFormOptions;
  const secondaryOptions = formState.primaryForm?.value
    ? secondaryFormOptions.filter(
        (option) => option.value !== formState?.primaryForm?.value
      )
    : secondaryFormOptions;

  return [
    {
      label: "Option 1: Abstinence",
      value: EFormsOfPregnancyConfirmOptions.abstinence,
      extra: (
        <div className="pregnancy-prevention-popup__extra-content">
          <p className="pregnancy-prevention-popup__extra-content__description">
            I confirm that I will refrain from{" "}
            <span className="pregnancy-prevention-popup__extra-content__description-bold">
              any and all
            </span>{" "}
            sexual activity with individuals that were assigned male at birth
            while taking isotretinoin and for one month after.
          </p>
        </div>
      ),
    },
    {
      label: "Option 2: Select two forms of birth control",
      value: EFormsOfPregnancyConfirmOptions.birthControl,
      extra: (
        <div className="pregnancy-prevention-popup__extra-content">
          <p className="pregnancy-prevention-popup__extra-content__description">
            I confirm that I will use{" "}
            <span className="pregnancy-prevention-popup__extra-content__description-bold">
              both
            </span>{" "}
            forms of birth control while taking isotretinoin and for one month
            after.
          </p>
          {currentOption === EFormsOfPregnancyConfirmOptions.birthControl && (
            <>
              <p className="pregnancy-prevention-popup__extra-content__form-title">
                Primary form
              </p>
              <SecondOptionPrimaryForm
                onChange={onChange("primaryForm")}
                options={primaryOptions}
                value={formState.primaryForm}
              />
              <p className="pregnancy-prevention-popup__extra-content__form-title">
                Secondary form
              </p>
              <SecondOptionSecondaryForm
                onChange={onChange("secondaryForm")}
                options={secondaryOptions}
                value={formState.secondaryForm}
              />
            </>
          )}
        </div>
      ),
    },
  ];
};

function PreviousFormResultsCheck({
  onChangeOption,
  value,
  previousAccutane,
}: {
  onChangeOption: (value: string | number | boolean) => void;
  value: string | undefined;
  previousAccutane: Accutane;
}) {
  const isAbstinence =
    previousAccutane.birthControl?.primaryForm ===
    EFormsOfPregnancyConfirmOptions.abstinence;
  const yesOptionText = `I use ${previousAccutane.birthControl?.primaryForm} ${
    previousAccutane.birthControl?.secondaryForm
      ? `and ${previousAccutane.birthControl.secondaryForm}`
      : ""
  }`;
  const previousFormResultsOptions = [
    {
      label: "Yes",
      value: EPreviousPregnancyConfirmResults.yes,
      centered: true,
      extra: <span>{isAbstinence ? "Abstinence" : yesOptionText}</span>,
    },
    {
      label: "No",
      value: EPreviousPregnancyConfirmResults.no,
      centered: true,
      extra: (
        <span>I would like to select different forms of contraception</span>
      ),
    },
  ];

  return (
    <div className="pregnancy-prevention-popup__previous-results">
      <p className="pregnancy-prevention-popup__previous-results__title">
        Is your form of pregnancy prevention still the same?
      </p>
      <RadioButtonsForm
        list={previousFormResultsOptions}
        onChange={onChangeOption}
        currentValue={value}
      />
    </div>
  );
}

export function FormsOfPregnancyPopup({ onClose }: { onClose: () => void }) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const [confirmPregnancyPrevention] = useConfirmPregnancyPreventionMutation();
  const patientQuery = useGetPatientByIdQuery(activePatientId as string);
  const accutaneQuery = useGetAccutaneByPatientIdQuery(
    activePatientId as string
  );
  const [formState, setValue] = useState<FormState>({
    currentOption: "",
    previousResultsOption: "",
  });
  const [formFields, setFormFields] = useState<FormFields>({
    primaryForm: null,
    secondaryForm: null,
  });

  if (!patientQuery.data || !accutaneQuery.data) return null;

  const hasPreviousBirthControl = accutaneQuery.data.birthControl?.primaryForm;
  const shouldShowAdditionalForm =
    (hasPreviousBirthControl &&
      formState.previousResultsOption ===
        EPreviousPregnancyConfirmResults.no) ||
    !hasPreviousBirthControl;
  const isPreviousBirthControl =
    formState.previousResultsOption === EPreviousPregnancyConfirmResults.yes;
  const isAbstinence =
    formState.currentOption === EFormsOfPregnancyConfirmOptions.abstinence;
  const formFieldsValid =
    !!formFields.primaryForm?.value && !!formFields.secondaryForm?.value;
  const isValidFormControl = formFieldsValid && !isAbstinence;
  const submitDisabled: boolean =
    !isPreviousBirthControl && !isAbstinence && !isValidFormControl;

  const onPreviousResultsChangeHandler = (value: string | number | boolean) => {
    setValue({
      ...formState,
      previousResultsOption: value as EPreviousPregnancyConfirmResults,
    });
  };

  const onChange = (fieldId: string) => (option: { option: SelectOption }) => {
    setFormFields({ ...formFields, [fieldId]: option });
  };

  const onSubmit = async () => {
    if (!activePatientId || !accutaneQuery.data) return;
    const dataToSend = getDataToSend(formFields, formState, accutaneQuery.data);
    const result: any = await confirmPregnancyPrevention({
      patientId: activePatientId,
      primaryForm: dataToSend.primaryForm,
      secondaryForm: dataToSend.secondaryForm,
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to submit pregnancy prevention form",
      });
      return;
    }

    showSuccess({
      title: "Success!",
      description:
        "You've submitted your pregnancy prevention form successfully",
    });
    onClose();
  };

  const onChangeRadioButtonOption = (value: string | boolean | number) => {
    if (value === EFormsOfPregnancyConfirmOptions.abstinence) {
      setFormFields({ primaryForm: null, secondaryForm: null });
    }
    setValue({
      ...formState,
      currentOption: value as EFormsOfPregnancyConfirmOptions,
    });
  };

  return (
    <div className="pregnancy-prevention-popup">
      <Button
        text="Select & Submit"
        size="small"
        onClick={onSubmit}
        disabled={submitDisabled}
        className="pregnancy-prevention-popup__extra-button"
      />
      <p className="pregnancy-prevention-popup__description">
        It is important to take serious measures to prevent the risk of
        pregnancy while taking isotretinoin and for one month after. iPledge
        requires you to abide by one of the following two options:
      </p>
      {hasPreviousBirthControl && (
        <PreviousFormResultsCheck
          onChangeOption={onPreviousResultsChangeHandler}
          value={formState.previousResultsOption}
          previousAccutane={accutaneQuery.data}
        />
      )}
      {shouldShowAdditionalForm && (
        <div className="pregnancy-prevention-popup__additional-form">
          <p className="pregnancy-prevention-popup__additional-form__title">
            Confirm your new forms of contraception
          </p>
          <RadioButtonsForm
            list={optionsList(
              onChange,
              formFields,
              formState.currentOption as EFormsOfPregnancyConfirmOptions
            )}
            currentValue={formState.currentOption}
            onChange={onChangeRadioButtonOption}
          />
        </div>
      )}
      <div className="pregnancy-prevention-popup__control-buttons">
        <Button
          text="Select & Submit"
          size="small"
          onClick={onSubmit}
          className="pregnancy-prevention-popup__control-buttons__submit"
          disabled={submitDisabled}
        />
      </div>
    </div>
  );
}

export function FormsOfPregnancyPopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <ActionPopupTemplate
        title="Confirm forms of pregnancy prevention"
        onClose={onClose}
      >
        <FormsOfPregnancyPopup onClose={onClose} />
      </ActionPopupTemplate>
    );
  };
}
