import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isNull } from "util";
import { PatientsTableBody } from "./tableBody";
import "./style.scss";
import { PatientsTableHead } from "./tableHead";
import {
  PatientsTableBodyPayload,
  Stylings,
} from "../../../../types/Table/TableBodyPayload";
import { ReactComponent as SearchIcon } from "../../../../Assets/NewIcons/search.svg";
import { ReactComponent as ErrorIcon } from "../../../../Assets/NewIcons/error-rounded.svg";
import { SearchInput } from "../SearchInput";
import { useUpdatePatientInfoMutation } from "../../../../features/api/patients";
import {
  PatientsTableChipsEntity,
  PatientsTableColumn,
  PatientsTableTab,
  PatientTableEntity,
  PatientTableSortFunction,
} from "../../../../types/Table";
import { PatientsTableTabs } from "./tableTabs";
import { PatientsTableChips } from "./tableChips";
import { PatientsTablePagination } from "./tablePagination";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { NotificationLayerContext } from "../NotificationLayer";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { logError } from "../../../../shared/logger";
import { Loader } from "../Loader";

enum VIEWS {
  IDLE,
  LOADING,
  LIST,
  NOT_FOUND,
  ERROR,
}

// function setOrder(
//   func: PatientTableSortFunction,
//   order: "asc" | "desc" | null
// ) {
//   return (a: any, b: any) => {
//     if (order === "asc") return func(a, b);
//     return func(b, a);
//   };
// }

const ITEMS_PER_PAGE = 100;

interface Props<T> {
  filters: PatientsTableChipsEntity[];
  sort: any[];
  order: any[];
  columns: TableHeadPayload<T>[];
  stylizeRow?: (item: T, currentFilter?: string) => Stylings | null;
  search?: any;
  onClick?: (item: T, state: any) => void;
}

export function PatientsTableElastic<T>({
  columns,
  sort,
  order,
  filters,
  stylizeRow,
  search,
  onClick,
}: Props<T>) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [viewState, setViewState] = useState<VIEWS>(VIEWS.IDLE);
  const [items, setItems] = useState<T[]>([]);
  const [currentFilter, setFilter] = useState(0);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
  const [currentPage, setPage] = useState(
    parseInt(localStorage.getItem("currentPage") || "0", 10)
  );
  const [maxPages, setMaxPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selectedSort, setSelectedSort] = useState<Record<any, any>[] | null>(
    JSON.parse(localStorage.getItem("patientsTableSorting") || null)
  );
  const [searchKey, setSearchKey] = useState("fullName");
  const [updatePatientInfo] = useUpdatePatientInfoMutation();

  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    if (storedPage !== null) {
      setPage(parseInt(storedPage, 10));
    }

    const handleBeforeUnload = () => {
      // Reset all values in local storage to 0 before the page is unloaded
      localStorage.setItem("currentPage", "0");
      setPage(0);
      // Add other localStorage keys to reset if needed
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [localStorage]);

  async function getTableItems() {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    const { filter } = currentIndexFormatted
      ? filters[currentIndexFormatted.currentSubTab]
      : filters[currentFilter];
    let mustQuery = filter.must;
    if (mustQuery instanceof Array) {
      mustQuery = [...mustQuery];
    } else if (mustQuery instanceof Object) {
      mustQuery = [mustQuery];
    } else {
      mustQuery = [];
    }

    if (searchText) {
      // mustQuery.push({
      //   wildcard: {
      //     fullName: {
      //       value: `*${searchText.replaceAll(" ", "?")}*`,
      //       case_insensitive: true,
      //     },
      //   },
      // });

      mustQuery.push({
        query_string: {
          query: `${searchText
            .split(" ")
            .map((it) => `*${it}*`)
            .join(" AND ")}`,
          default_field: searchKey,
          analyze_wildcard: true,
        },
      });
    }

    const query = {
      query: {
        from: currentPage * ITEMS_PER_PAGE,
        size: ITEMS_PER_PAGE,
        query: {
          function_score: {
            query: {
              bool: {
                ...filter,
                must: mustQuery,
              },
            },
            functions: [...order, ...(selectedSort || sort)],
            score_mode: "sum",
            boost_mode: "replace",
          },
        },
      },
    };

    try {
      setViewState(VIEWS.LOADING);
      const result = await HoneydewAPI.dashboard.queryDashboardItems<T>(query);
      const pages = Math.ceil(result.total.value / ITEMS_PER_PAGE);
      setMaxPages(pages);
      setItems(result.hits.map(({ _source }) => _source));
      setViewState(result.total.value === 0 ? VIEWS.NOT_FOUND : VIEWS.LIST);
    } catch (e) {
      logError("Error during dashboard request", e);
      setViewState(VIEWS.ERROR);
    }
  }

  useEffect(() => {
    if (selectedSort)
      localStorage.setItem(
        "patientsTableSorting",
        JSON.stringify(selectedSort)
      );
  }, [selectedSort]);

  useEffect(() => {
    // const isSortingAvailable: any = localStorage.getItem(
    //   "patientsTableSorting"
    // );
    // console.log("availabl sorting", JSON.parse(isSortingAvailable));
    // if (isSortingAvailable) setSelectedSort(isSortingAvailable);
    (async () => {
      await getTableItems();
    })();
  }, [searchText, currentPage, currentFilter, filters, order, selectedSort]);

  const handleSelectedSortChange = (value: any) => {
    setSelectedSort(value);
    console.log("val", value);
  };

  const handleMedBackgroundReminderCount = async (payload: any) => {
    const updatedCount = payload.medBackgroundReminderCount
      ? payload.medBackgroundReminderCount + 1
      : 1;
    const medBackgroundReminderCountPayload = [
      {
        key: "medBackgroundReminderCount",
        value: updatedCount,
        queryType: "Int!",
        queryName: "updateMedBackgroundReminderCount",
      },
    ];
    try {
      const response = await updatePatientInfo({
        patientId: payload.patientId,
        items: medBackgroundReminderCountPayload,
      });
      if (response.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to update reminder.",
        });
        return;
      }

      const updatedItems = items.map((item) => {
        if (item.patientId === payload.patientId) {
          return {
            ...item,
            medBackgroundReminderCount: updatedCount,
          };
        }
        return item;
      });

      setItems(updatedItems);

      showSuccess({
        title: "Success",
        description: "You have marked the patient as being contacted.",
      });
    } catch (error) {
      console.error("Error setting reminder count:", error);
    }
  };

  const toRender = items.map((item) => {
    const payload: PatientsTableBodyPayload<T> = {
      data: item,
      row: columns.map(({ getPretty }) =>
        getPretty(item, getTableItems, handleMedBackgroundReminderCount)
      ),
      rowStyle: stylizeRow
        ? stylizeRow(item, filters[currentFilter]?.text) || Stylings.Base
        : Stylings.Base,
    };

    return payload;
  });

  const patientsToRender = toRender;

  function renderBody() {
    switch (viewState) {
      case VIEWS.IDLE:
        return null;
      case VIEWS.LOADING:
        return (
          <div className="patients-table__not-found-wrap">
            <div className="patients-table__not-found-sign">
              <div className="patients-table__not-found-sign-inner">
                <Loader />
              </div>
            </div>
            <p className="patients-table__not-found-title">Loading...</p>
          </div>
        );
      case VIEWS.ERROR:
        return (
          <div className="patients-table__not-found-wrap">
            <div className="patients-table__not-found-sign">
              <div className="patients-table__not-found-sign-inner">
                <ErrorIcon />
              </div>
            </div>
            <p className="patients-table__not-found-title">Oops...</p>
            <p className="patients-table__not-found-text paragraph-font--color">
              Unexpected error occured. Please, try again later
            </p>
          </div>
        );
      case VIEWS.NOT_FOUND:
        return (
          <div className="patients-table__not-found-wrap">
            <div className="patients-table__not-found-sign">
              <div className="patients-table__not-found-sign-inner">
                <SearchIcon />
              </div>
            </div>
            <p className="patients-table__not-found-title">
              Oops!... No results found
            </p>
            <p className="patients-table__not-found-text paragraph-font--color">
              Please try another search
            </p>
          </div>
        );
      case VIEWS.LIST:
        return null;
      default:
        return null;
    }
  }
  const setCurrentPage = (current: number) => {
    localStorage.setItem("currentPage", current.toString());
    setPage(current);
  };

  return (
    <div className="patients-table">
      {/* <div className="patients-table__tabs">
        <PatientsTableTabs
          tabs={content.map(({ title }) => title)}
          onChange={(state) => {
            setCurrentTab(state);
            setFilter(null);
            setPage(0);
          }}
        />
      </div> */}
      <div className="patients-table__main">
        <div className="patients-table__search patients-table__search--half">
          <SearchInput
            id="search"
            name="search"
            placeholder="Search by patient's name"
            className="patients-table__search--half-input"
            onChange={(text) => {
              setSearchText(text.trim());
              setCurrentPage(0);
              setSearchKey("fullName");
            }}
          />
          <SearchInput
            id="search"
            name="search"
            placeholder="Search by provider's name"
            onChange={(text) => {
              setSearchText(text.trim());
              setCurrentPage(0);
              setSearchKey("providerFullName");
            }}
          />
        </div>
        <div className="patients-table__chips">
          <PatientsTableChips
            chips={filters}
            onChange={(value) => {
              setFilter(value);
              setCurrentFilterIndex(value);
              setPage(parseInt(localStorage.getItem("currentPage") || "0", 10));
            }}
            initial={currentFilter}
          />
        </div>
        <div className="patients-table__wrap">
          <table className="patients-table__table">
            <PatientsTableHead<T>
              columns={[...columns]}
              onSortChange={(value) => {
                handleSelectedSortChange(value);
              }}
            />
            {viewState === VIEWS.LIST
              ? patientsToRender.map(({ row, rowStyle, data }) => (
                  <tr
                    className={`${rowStyle || ""}`}
                    onClick={() => onClick && onClick(data, currentFilterIndex)}
                  >
                    {row.map((elem) => (
                      <td>{elem}</td>
                    ))}
                  </tr>
                ))
              : null}
          </table>
        </div>
        {renderBody()}
        <PatientsTablePagination
          current={currentPage}
          max={maxPages}
          onChange={(current) => setCurrentPage(current)}
        />
      </div>
    </div>
  );
}
