import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as GroupIcon } from "../../../../Assets/icons/group.svg";
import { ReactComponent as SingleDoctor } from "../../../../Assets/NewIcons/single-doctor.svg";
import "./style.scss";
import { Skeleton } from "../Skeleton";
import { HoneydewAPI } from "../../../../services/honeydew-api";

export enum TaskColors {
  Pink = "pink",
  LightBlue = "light-blue",
  Green = "green",
  Orange = "orange",
}

interface TaskCounter {
  text: string;
  color: TaskColors;
  // eslint-disable-next-line react/no-unused-prop-types
  filter?: any;
  count?: number;
}

interface Props {
  items: TaskCounter[];
  name: string | null;
}

function TaskCounterElement({ text, count, color }: TaskCounter) {
  // const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout>();
  // const refreshTimeoutRef = useRef(refreshTimeout);
  // refreshTimeoutRef.current = refreshTimeout;

  // async function getCount(query: any) {
  //   const response = await HoneydewAPI.dashboard.countDashboardItems({
  //     queries: [
  //       {
  //         label: "",
  //         query: {
  //           query: {
  //             bool: query,
  //           },
  //         },
  //       },
  //     ],
  //   });
  //   setCount(response.count);
  // }

  // useEffect(() => {
  //   if (!filter) {
  //     setCount(count === undefined ? null : count);
  //   }
  // }, [count, filter]);

  // function scheduleRefresh(query: any) {
  //   const id = setTimeout(() => {
  //     getCount(query).then(scheduleRefresh);
  //   }, REFRESH_RATE);
  //   setRefreshTimeout(() => id);
  // }

  // useEffect(() => {
  //   (async () => {
  //     if (filter) {
  //       await getCount(filter);
  //       scheduleRefresh(filter);
  //     }
  //   })();
  //   return () => clearTimeout(refreshTimeoutRef.current);
  // }, []);

  return (
    <div className={`task-counter task-counter--${color}`}>
      <p className="task-counter__count">
        {count ?? <Skeleton style={{ width: 20 }} />}
      </p>
      <p className="task-counter__text">{text}</p>
    </div>
  );
}

export function DoctorGreetingBanner({ items, name }: Props) {
  const [tileCounts, setTileCounts] = useState(
    items.reduce<{ [p: string]: number | null }>((acc, { count, text }) => {
      acc[text] = count ?? null;
      return acc;
    }, {})
  );

  useEffect(() => {
    (async () => {
      const filters = items
        .filter((it) => it.filter)
        .map(({ filter, text }) => ({
          query: {
            query: {
              bool: filter,
            },
          },
          label: text,
        }));
      const result = await HoneydewAPI.dashboard.countDashboardItems(filters);
      const newCounts: { [p: string]: number } = {};
      Object.keys(result).forEach((key) => {
        newCounts[key] = result[key].count;
      });
      setTileCounts((old) =>
        JSON.parse(
          JSON.stringify({
            ...old,
            ...newCounts,
          })
        )
      );
    })();
  }, []);

  useEffect(() => {
    items.forEach(({ text, count }) => {
      if (count === undefined) return;
      tileCounts[text] = count;
    });
    setTileCounts(JSON.parse(JSON.stringify(tileCounts)));
  }, [items]);

  return (
    <div className="doctor-greeting-banner">
      <p className="doctor-greeting-banner__title">
        {name ? `Hi, ${name}!` : <Skeleton style={{ width: 200 }} />}
      </p>
      <div className="doctor-greeting-banner__main">
        <div className="doctor-greeting-banner__tasks">
          {items.map(({ text, color }) => (
            <TaskCounterElement
              text={text}
              count={tileCounts[text] ?? undefined}
              color={color}
            />
          ))}
          {items.length % 2 !== 0 ? (
            <div className="task-counter task-counter--placeholder">
              <SingleDoctor />
            </div>
          ) : null}
        </div>
        <GroupIcon className="doctor-greeting-banner__image" />
      </div>
    </div>
  );
}
