import Select, { ClearIndicatorProps, Props } from "react-select";
import React, { useMemo, useRef } from "react";
import { selectStyles, styledSelectChats } from "./constants";
import { ReactComponent as CloseCross } from "../../../../Assets/NewIcons/close-cross.svg";
import "./style.scss";

interface StyledSelectProps extends Props {
  id: string;
  name: string;
  options: any[];
  placeholder?: string;
  onChange?: any;
  disabled?: boolean;
  isSearchable?: boolean;
  isChatsSelect?: boolean;
  hideCrossIcon?: boolean;
  isMulti?: boolean;
  isTimeOffStyles?: boolean;
  isClearable?: boolean;
}

function ClearIndicatorComp(props: ClearIndicatorProps<any>) {
  const {
    innerProps: { ref, ...restInnerProps },
    hideCrossIcon = false,
  } = props;
  if (hideCrossIcon) {
    return null; // Return null if hideCrossIcon is true
  }

  return (
    <div className="clear-indicator" ref={ref} {...restInnerProps}>
      <CloseCross />
    </div>
  );
}

function StyledSelect({
  id,
  name,
  options,
  placeholder = "Please select one option",
  disabled = false,
  isSearchable = false,
  isMulti = false,
  isTimeOffStyles = false,
  onChange,
  isClearable = true,
  isChatsSelect,
  hideCrossIcon = false,
  ...otherProps
}: StyledSelectProps) {
  const selectRef = useRef(null);

  return (
    <div className="styled-select-wrapper">
      <Select
        ref={selectRef}
        placeholder={placeholder}
        isClearable={isClearable}
        components={{
          IndicatorSeparator: () => null,
          // eslint-disable-next-line react/no-unstable-nested-components
          ClearIndicator: (clearProps) => (
            <ClearIndicatorComp {...clearProps} hideCrossIcon={hideCrossIcon} />
          ),
        }}
        styles={isChatsSelect ? styledSelectChats : selectStyles}
        options={options}
        id={`${id}__styledSelect`}
        name={`${name}__styledSelect`}
        defaultMenuIsOpen={false}
        isSearchable={isSearchable}
        isDisabled={disabled}
        isMulti={isMulti}
        onChange={(newValue) => onChange(newValue)}
        menuPosition="absolute"
        /* eslint-disable no-nested-ternary */ className={
          isMulti && otherProps?.value?.length > 0
            ? "is-multi"
            : isTimeOffStyles
            ? "is-timeoff"
            : ""
        }
        /* eslint-enable no-nested-ternary */
        {...otherProps}
      />
    </div>
  );
}

export default StyledSelect;
