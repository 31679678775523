import React, { useState } from "react";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import "./style.scss";

interface Props {
  children: JSX.Element | JSX.Element[] | string;
  disabled?: boolean;
  className?: string;
  onChange?: (state: boolean) => void;
  checked?: boolean;
  hasError?: {
    errorMessage: string;
    error: boolean;
  } | null;
}

export function Checkbox({
  onChange,
  children,
  checked,
  className,
  disabled = false,
  hasError,
}: Props) {
  const [isChecked, check] = useState(!!checked);

  const onClickHandler = () => {
    if (!disabled) {
      check(!isChecked);
      onChange?.(!isChecked);
    }
  };

  return (
    <>
      <div
        className={`custom-checkbox ${isChecked ? "checked" : ""} ${
          className || ""
        }`}
        onClick={onClickHandler}
      >
        <div
          className={`custom-checkbox__box ${
            disabled ? "custom-checkbox__box__disabled" : ""
          }`}
        >
          <CheckIcon />
        </div>
        <div className="custom-checkbox__label">{children}</div>
      </div>
      {hasError && hasError.error && (
        <div className="custom-checkbox__error-field">
          {hasError.errorMessage}
        </div>
      )}
    </>
  );
}
