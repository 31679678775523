import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { TreatmentPlan } from "../../../../../types/Entities/TreatmentPlan";
import { AuthenticationService } from "../../../../cognito";

export const update = async (treatmentPlan: TreatmentPlan) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.put<TreatmentPlan>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/treatment-plans/update`,
      treatmentPlan,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot update treatment plan`);
    throw error;
  }
};
