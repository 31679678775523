import React from "react";
import { useSelector } from "react-redux";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { getMomentDate } from "../../../../../utils/get-date-pretty";
import { Button } from "../../../../NewComponents/Common/Button";
import { Stepper } from "../../../../NewComponents/Common/Stepper";
import { TreatmentPlanItemsList } from "../../../../NewComponents/Patient/TreatmentPlanItemsList";
import { TreatmentPlanProviderComment } from "../../../../NewComponents/Patient/TreatmentPlanProviderComment";
import { getSessionState } from "../../../../../features/session";
import { useGetFollowUpsByPatientIdQuery } from "../../../../../features/api/follow-ups";
import { useGetEmployeeQuery } from "../../../../../features/api/employees";
import { Skeleton } from "../../../../NewComponents/Common/Skeleton";
import { Provider } from "../../../../../types/Entities/Provider";

export function TreatmentPlanSurveyPage({
  onDone,
  stepper: { max, current },
}: SurveyComponentProps) {
  const { activePatientId } = useSelector(getSessionState);
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const providerQuery = useGetEmployeeQuery(
    followUpsQuery.data?.[0].treatmentPlan?.submittedBy as string,
    {
      skip: !followUpsQuery.isSuccess,
    }
  );

  return (
    <div className="survey-question initiate-checkout-page__page">
      <Stepper max={max} current={current} />
      <p className="survey-question__title">Check your treatment plan</p>
      <p className="survey-question__descr paragraph-font--color">
        {followUpsQuery.data?.[0].treatmentPlan && providerQuery.data ? (
          <>
            {" "}
            Your treatment plan was updated on{" "}
            {getMomentDate(
              followUpsQuery.data[0].treatmentPlan?.timestamp
            ).format("MMMM MM, YYYY")}{" "}
            by your provider {providerQuery.data.firstName}{" "}
            {providerQuery.data.lastName},{" "}
            {(providerQuery.data as Provider).title}. You can view this plan
            when needed in your personal dashboard.
          </>
        ) : (
          <Skeleton style={{ height: 100 }} />
        )}
      </p>
      <div className="initiate-checkout-page__treatment">
        <div className="initiate-checkout-page__treatment-comment">
          {followUpsQuery.data?.[0].treatmentPlan && providerQuery.data ? (
            <TreatmentPlanProviderComment
              employee={providerQuery.data}
              treatmentPlan={followUpsQuery.data[0].treatmentPlan}
            />
          ) : (
            <Skeleton style={{ height: 150 }} />
          )}
        </div>
        {followUpsQuery.data?.[0].treatmentPlan ? (
          <TreatmentPlanItemsList
            medications={followUpsQuery.data?.[0].treatmentPlan.medications}
            highlight
          />
        ) : (
          <Skeleton count={3} fullWidth flex />
        )}
      </div>
      <div className="initiate-checkout-page__actions">
        <Button
          text="continue"
          onClick={() => onDone({})}
          className="initiate-checkout-page__submit-button"
        />
      </div>
    </div>
  );
}
