import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../Common/Button";
import { Props } from "../FormsOfPregnancyPopup/types";
import "./style.scss";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import { ReactComponent as CashIcon } from "../../../../Assets/icons/cash.svg";
import { ReactComponent as InsuranceIcon } from "../../../../Assets/icons/insurance.svg";
import PaymentOptionCard from "./PaymentOptionCard";
import PharmacySelectPopup from "./PharmacySelectPopup";
import CustomPharmacyDetails from "./CustomPharmacyDetails";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { getSessionState } from "../../../../features/session";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { pubnubClient } from "../../../../services/pubnub";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import {
  useGetAllMedicationsQuery,
  useGetMedicationQuery,
} from "../../../../features/api/medications";

interface PaymentOption {
  key: string;
  heading: string;
  Icon: React.ElementType;
  subHeading: string;
}

const priceMapping: any = {
  "10 capsules": "$30",
  "30 capsules": "$50 (1 box at $50 / box)",
  "60 capsules": "$100 (2 boxes at $50 / box)",
};

const calculatePrice = (size: string) => priceMapping[size] || 0;

function PharmacyBillingPopup({
  onNext,
  setBillingOption,
  subHeadingCustom,
}: any) {
  const [selectedOption, setSelectedOption] = useState(null);

  const paymentOptions: PaymentOption[] = [
    {
      key: "Cash",
      heading: "Cash / Without insurance",
      Icon: CashIcon,
      subHeading: subHeadingCustom,
    },
    {
      key: "Insurance",
      heading: "With insurance",
      Icon: InsuranceIcon,
      subHeading: "Subject to your plan's coverage, co-pay and deductible",
    },
  ];

  const handleBillingOptionClick = (key) => {
    setBillingOption(key);
    setSelectedOption(key);
  };

  return (
    <div className="pharmacy-billing-form">
      <div className="pharmacy-billing-form__headings">
        <h2 className="pharmacy-billing-form__question-heading">
          How will you pay for your prescriptions?
        </h2>
        <h4 className="pharmacy-billing-form__sub-heading">
          Let us know how you’ll pay and we’ll help you find the best price.
        </h4>
      </div>
      <div className="pharmacy-billing-form__options-container">
        {paymentOptions.map((option, index) => (
          <PaymentOptionCard
            key={option.key}
            heading={option.heading}
            subHeading={option.subHeading}
            Icon={option.Icon}
            onClick={() => handleBillingOptionClick(option.key)}
            isSelected={selectedOption === option.key}
          />
        ))}
      </div>
      <div className="pharmacy-billing-form__button">
        <Button
          text="SUBMIT"
          size="small"
          disabled={selectedOption == null}
          onClick={onNext}
        />
      </div>
    </div>
  );
}

function PharmacyBilling({ onClose }: any) {
  const [step, setStep] = useState(0);
  const [billingOption, setBillingOption] = useState(null);
  const [pharmacyOption, setPharmacyOption] = useState(null);
  const [subHeading, setSubHeading] = useState("");
  const [localPharmacyDetails, setLocalPharmacyDetails] = useState(null);
  const [patientHasAccutane, setPatientHasAccutane] = useState(null);
  const [msgBody, setMsgBody] = useState({
    patient: "",
    pharmacy: "Specialty",
    paymentMethod: "",
    pharmacyDetails: {},
  });

  const [pubnubToken, setPubnubToken] = useState(null);
  const [pubnub, setPubNub] = useState<any | null>(null);

  const { showSuccess, showError } = useContext(NotificationLayerContext);

  const { activePatientId, userId } = useSelector(getSessionState);

  const medicationsQuery = useGetAllMedicationsQuery(null);

  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  useEffect(() => {
    if (followUpsQuery?.data && medicationsQuery?.data) {
      const lastTreatmentPlan =
        followUpsQuery?.data?.[0]?.treatmentPlan || null;
      const medicineKeys = lastTreatmentPlan?.medications.map(
        (item) => item.medicineKey
      );
      const matchingMedicines = medicationsQuery?.data.filter((obj) =>
        medicineKeys?.includes(obj.id)
      );
      const isotretinoinMedicine = matchingMedicines.find(
        (obj) => obj.name === "Isotretinoin"
      );
      console.log("isotretinoinMedicine", isotretinoinMedicine);
      if (isotretinoinMedicine) {
        const price = calculatePrice(isotretinoinMedicine.size);
        setSubHeading(`Isotretinoin (Accutane): ${price}`);
        setPatientHasAccutane(true);
      } else {
        setPatientHasAccutane(false);
        setSubHeading(
          "We’ll find you the lowest cash prices available on the market"
        );
      }
    }
  }, [followUpsQuery, medicationsQuery]);

  useEffect(() => {
    if (patientQuery.data?.patientId) {
      const fetchData = async () => {
        try {
          const token = await HoneydewAPI.chats.getTokenGenerated(
            patientQuery.data?.patientId,
            userId
          );
          setPubnubToken(token);
        } catch (error) {
          console.error("Error fetching PubNub token:", error);
        }
      };
      setMsgBody({ ...msgBody, patient: patientQuery.data?.fullName });
      fetchData();
    }
  }, [patientQuery.data?.patientId, userId]);

  useEffect(() => {
    if (pubnubToken && patientQuery.data?.patientId) {
      const pubnubInstance = pubnubClient({
        userId: patientQuery.data?.patientId,
        pubnubToken,
      });
      setPubNub(pubnubInstance);
    }
  }, [pubnubToken, patientQuery.data?.patientId]);

  useEffect(() => {
    console.log("billing", billingOption);
    console.log("pharma", pharmacyOption);
    if (billingOption) {
      switch (billingOption) {
        case "Cash":
          setMsgBody({ ...msgBody, paymentMethod: "Cash/Without Insurance" });
          break;
        case "Insurance":
          setMsgBody({ ...msgBody, paymentMethod: "With Insurance" });
          break;
        default:
          setMsgBody({ ...msgBody, paymentMethod: "Cash/Without Insurance" });
      }
    }
    if (pharmacyOption) {
      switch (pharmacyOption) {
        case "LOCAL":
          setMsgBody({ ...msgBody, pharmacy: "Local" });
          break;
        case "SPECIAL":
          setMsgBody({ ...msgBody, pharmacy: "Specialty" });
          break;
        default:
          setMsgBody({ ...msgBody, pharmacy: "Local" });
      }
    }
  }, [billingOption, pharmacyOption]);

  useEffect(() => {
    if (localPharmacyDetails) {
      setMsgBody({ ...msgBody, pharmacyDetails: localPharmacyDetails });
    }
  }, [localPharmacyDetails]);

  const handleFormSubmit = async () => {
    const timestamp = new Date().toISOString();
    let body;
    if (msgBody.pharmacy === "Local") {
      body = `${msgBody.patient}'s preferred pharmacy: ${msgBody.pharmacy}<br> <br>${msgBody.pharmacyDetails?.pharmacyName} <br>${msgBody.pharmacyDetails?.pharmacyAddress}<br>${msgBody.pharmacyDetails?.city} ${msgBody.pharmacyDetails?.state} ${msgBody.pharmacyDetails?.zipCode}<br><br>Payment method: ${msgBody.paymentMethod}`;
    } else if (msgBody.pharmacy === "Specialty") {
      body = `${msgBody.patient}'s preferred pharmacy: ${msgBody.pharmacy}<br> <br>Payment method: ${msgBody.paymentMethod}`;
    }

    try {
      const publishConfig = {
        channel: patientQuery.data?.patientId,
        message: {
          author: "bot",
          text: body,
          timestamp,
          attributes: {
            notificationType: "system",
            attachment: {
              type: "text",
              data: "",
            },
          },
        },
      };
      const pubnubResponse = await pubnub.publish(publishConfig);
      showSuccess({
        title: "Great! We let your Care Team know.",
        description: "",
      });
      onClose();
    } catch (err) {
      showError({
        title: "Error! Something went wrong",
        description: "",
      });
      console.log("error occured in form submission", err);
    }
  };

  const handleSubmit = () => {
    if (step < 2 && pharmacyOption !== "SPECIAL") {
      setStep(step + 1);
    } else if (step === 2 && pharmacyOption === "SPECIAL") {
      handleFormSubmit();
    } else {
      handleFormSubmit();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <div className="pharmacy-billing-popup">
      {step === 0 && (
        <PharmacyBillingPopup
          onNext={handleSubmit}
          setBillingOption={setBillingOption}
          subHeadingCustom={subHeading}
        />
      )}
      {step === 1 && (
        <PharmacySelectPopup
          onNext={handleSubmit}
          billingOption={billingOption}
          setPharmacyOption={setPharmacyOption}
          hasAccutane={patientHasAccutane}
        />
      )}
      {step === 2 && (
        <CustomPharmacyDetails
          onNext={handleSubmit}
          onBack={handleBack}
          setLocalPharmacyDetails={setLocalPharmacyDetails}
        />
      )}
    </div>
  );
}

export function PharmacyBillingMethodPopupModal() {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose} showCross={false}>
        <PharmacyBilling onClose={onClose} />
      </HelpPopupTemplate>
    );
  };
}

export default PharmacyBillingMethodPopupModal;
