import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./features/session";
import errorNotificationReducer from "./features/errorNotification";
import editPatientInfoReducer from "./features/editPatientInfo";
import editSkinInfoReducer from "./features/editSkinInfo";
import twilioReducer from "./features/twilio";
import { patientsApiSlice } from "./features/api/patients";
import { appointmentsApiSlice } from "./features/api/appointments";
import { medicationsApiSlice } from "./features/api/medications";
import { followUpsApiSlice } from "./features/api/follow-ups";
import { paymentApiSlice } from "./features/api/payment";
import { accutaneApiSlice } from "./features/api/accutane";
import { referralApiSlice } from "./features/api/referral";
import { marketingApiSlice } from "./features/api/marketing";
import { employeesApiSlice } from "./features/api/employees";

export default configureStore({
  reducer: {
    errorNotificationStore: errorNotificationReducer,
    editPatientInfoStore: editPatientInfoReducer,
    editSkinInfoStore: editSkinInfoReducer,
    sessionStore: sessionReducer,
    twilioStore: twilioReducer,
    [patientsApiSlice.reducerPath]: patientsApiSlice.reducer,
    [appointmentsApiSlice.reducerPath]: appointmentsApiSlice.reducer,
    [medicationsApiSlice.reducerPath]: medicationsApiSlice.reducer,
    [followUpsApiSlice.reducerPath]: followUpsApiSlice.reducer,
    [paymentApiSlice.reducerPath]: paymentApiSlice.reducer,
    [accutaneApiSlice.reducerPath]: accutaneApiSlice.reducer,
    [referralApiSlice.reducerPath]: referralApiSlice.reducer,
    [marketingApiSlice.reducerPath]: marketingApiSlice.reducer,
    [employeesApiSlice.reducerPath]: employeesApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(patientsApiSlice.middleware)
      .concat(appointmentsApiSlice.middleware)
      .concat(medicationsApiSlice.middleware)
      .concat(followUpsApiSlice.middleware)
      .concat(paymentApiSlice.middleware)
      .concat(accutaneApiSlice.middleware)
      .concat(referralApiSlice.middleware)
      .concat(marketingApiSlice.middleware)
      .concat(employeesApiSlice.middleware),
});
