import React, { createContext, useState } from "react";
import { ReactComponent as CrossIcon } from "../../../../../Assets/NewIcons/close-cross.svg";
import { logInfo } from "../../../../../shared/logger";
import "./style.scss";

interface Props {
  title: string;
  onClose: () => void;
  children: JSX.Element[] | JSX.Element;
}

enum ANIMATIONS {
  SHOW = "show",
  HIDE = "fade",
}

export const ProgressPopupTemplateContext = createContext({
  onClose: () => logInfo(""),
});

export function ProgressPopupTemplate({ title, onClose, children }: Props) {
  const [animation, setAnimation] = useState(ANIMATIONS.SHOW);

  function close() {
    setAnimation(ANIMATIONS.HIDE);
    setTimeout(onClose, 500);
  }

  return (
    <ProgressPopupTemplateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        onClose: close,
      }}
    >
      <div className={`progress-popup-template__wrap ${animation}`}>
        <div className="progress-popup-template">
          <div className="progress-popup-template__head">
            <p className="progress-popup-template__title">{title}</p>
            <CrossIcon
              onClick={close}
              className="progress-popup-template__close-button"
            />
          </div>
          <div className="progress-popup-template__body">{children}</div>
        </div>
      </div>
    </ProgressPopupTemplateContext.Provider>
  );
}
