import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SkinSurvey, SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import { SimplePageLayout } from "../../../NewComponents/Common/SimplePageLayout";
import { ReactComponent as MedicalBackgroundImage } from "../../../../Assets/NewIcons/medical-background.svg";
import "./style.scss";
import { AcnePeriodSurveyQuestion } from "./survey-questions/acne-period";
import { Button } from "../../../NewComponents/Common/Button";
import { BirthControlMedsSurveyQuestion } from "./survey-questions/birth-control-meds";
import { SkinDrynessSurveyQuestion } from "./survey-questions/skin-dryness";
import { SkinSensitivitySurveyQuestion } from "./survey-questions/skin-sensitivity";
import { PregnancySurveyQuestion } from "./survey-questions/pregnancy";
import {
  MenstrualBreakoutSurveyQuestion,
  MENSTRUAL_BREAKOUT_ANSWERS,
} from "./survey-questions/menstrual-breakout";
import { IsMenstruationRegularSurveyQuestion } from "./survey-questions/is-menstruation-regular";
import { isBirthControlSurveyQuestion } from "./survey-questions/is-birth-control";
import { isPCOSSurveyQuestion } from "./survey-questions/is-pcos";
import {
  PreviousAcneProductsSurveyQuestion,
  PREVIOUS_ACNE_PRODUCTS_ANSWERS,
} from "./survey-questions/previous-acne-products";
import { CurrentNonPrescriptionsSurveyQuestion } from "./survey-questions/current-non-prescriptions";
import { PrescriptionCreamsSurveyQuestion } from "./survey-questions/prescription-creams";
import { PrescriptionPillsSurveyQuestion } from "./survey-questions/prescription-pills";
import { isAnyPrescriptionsInUseSurveyQuestion } from "./survey-questions/is-any-prescriptions-in-use";
import { CurrentPrescriptionsSurveyQuestion } from "./survey-questions/current-prescriptions";
import { isMedicalConditionPillsInUseSurveyQuestion } from "./survey-questions/is-medical-condition-pills";
import { MedicationsSurveyQuestion } from "./survey-questions/medications";
import { isAnyPrescriptionAllergiesSurveyQuestion } from "./survey-questions/is-prescription-allergies";
import { PrescriptionAllergiesSurveyQuestion } from "./survey-questions/prescription-allergies";
import { StressLevelSurveyQuestion } from "./survey-questions/stress-level";
import { SleepAmountSurveyQuestion } from "./survey-questions/sleep-amount";
import { DairyConsumptionSurveyQuestion } from "./survey-questions/dairy-consumption";
import { SkinImagesSurveyQuestion } from "./survey-questions/skin-images";
import { isEmpty } from "../../../../utils/is-empty";
import {
  getPatientStore,
  updateActivePatient,
} from "../../../../features/patientView";
import { ImageBlob } from "../../../../types/Base/ImageBlob";
import generateHash from "../../../../utils/generate-hash";
import { ImagePayload } from "../../../../types/Entities/Image";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import {
  GenderSurveyQuestion,
  GENDER_ANSWERS,
} from "./survey-questions/gender";
import { BreastFeedingSurveyQuestion } from "./survey-questions/breastfeeding";
import {
  sendSurveyEnded,
  sendSurveyQuestion,
} from "../../../../shared/analytics";
import { HeightSurveyQuestion } from "./survey-questions/height";
import { WeightSurveyQuestion } from "./survey-questions/weight";
import { InterestingMedicationsSurveyQuestion } from "./survey-questions/interesting-medications";
import { getSessionState } from "../../../../features/session";
import { MedicalBackgroundItem } from "../../../../types/Entities/Patient";
import { surveyValueToDB } from "../../../../utils/survey/survey-value-to-db";
import {
  useGetPatientByIdQuery,
  useSubmitMedicalBackgroundMutation,
} from "../../../../features/api/patients";
import { StyledSelect } from "../../../NewComponents/Common/StyledSelect";
import { SurveyQuestionTemplete } from "./survey-questions/index";
import { skinIssues } from "./skin-issues";

enum STATES {
  INIT,
  SURVEY_TYPE,
  SURVEY,
  COMPLETE,
}

const excludedQuestions = ["id", "height", "weight", "gender"];

export function SkinSurveyPage({
  isMedicalBackground,
  isChildAccount,
}: {
  isMedicalBackground: boolean;
  isChildAccount: boolean;
}) {
  const { showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const [submitMedicalBackground, { isSuccess, isError }] =
    useSubmitMedicalBackgroundMutation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(STATES.INIT);
  const localStorageKey = `skin-survey-data:${activePatientId}`;
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const isFromAccutaneSource = window.localStorage.getItem(
    "isFromAccutaneSource"
  );

  // useEffect(() => {
  //   if (isMedicalBackground) setState(STATES.COMPLETE);
  // }, [isMedicalBackground]);

  useEffect(() => {
    if (state === STATES.COMPLETE) {
      sendSurveyEnded();
    }
  }, [state]);

  if (!activePatientId) return null;

  const acneSurveyQuestions: SurveyPage[] = [
    {
      isFilled: (data) => !isEmpty(data.acnePeriod),
      Component: AcnePeriodSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.skinDryness),
      Component: SkinDrynessSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.skinSensitivity),
      Component: SkinSensitivitySurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.gender),
      Component: GenderSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.height),
      Component: HeightSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.weight),
      Component: WeightSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.pregnancy),
      when: (data) => data.gender === GENDER_ANSWERS.FEMALE,
      Component: PregnancySurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.breastfeeding),
      when: (data) => data.gender === GENDER_ANSWERS.FEMALE,
      Component: BreastFeedingSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.menstruationBreakout),
      when: (data) => data.gender === GENDER_ANSWERS.FEMALE,
      Component: MenstrualBreakoutSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isRegularMenstruationCycle),
      when: (data) =>
        data.gender === GENDER_ANSWERS.FEMALE &&
        (MENSTRUAL_BREAKOUT_ANSWERS.YES === data.menstruationBreakout ||
          MENSTRUAL_BREAKOUT_ANSWERS.NO === data.menstruationBreakout),
      Component: IsMenstruationRegularSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isBirthControlTaken),
      when: (data) => data.gender === GENDER_ANSWERS.FEMALE,
      Component: isBirthControlSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.birthControlMeds),
      when: (data) =>
        data.gender === GENDER_ANSWERS.FEMALE && data.isBirthControlTaken,
      Component: BirthControlMedsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isPcos),
      when: (data) => data.gender === GENDER_ANSWERS.FEMALE,
      Component: isPCOSSurveyQuestion,
    },
    {
      isFilled: (data) =>
        !isEmpty(data.previousAcneProductsTaken) &&
        data.previousAcneProductsTaken.length > 0,
      Component: PreviousAcneProductsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.currentNonPrescriptions),
      when: (data) =>
        !isEmpty(data.previousAcneProductsTaken) &&
        data.previousAcneProductsTaken.includes(
          PREVIOUS_ACNE_PRODUCTS_ANSWERS.OTC_CREAMS
        ),
      Component: CurrentNonPrescriptionsSurveyQuestion,
    },
    {
      isFilled: (data) =>
        !isEmpty(data.prescriptionCreams) && data.prescriptionCreams.length > 0,
      when: (data) =>
        !isEmpty(data.previousAcneProductsTaken) &&
        data.previousAcneProductsTaken.includes(
          PREVIOUS_ACNE_PRODUCTS_ANSWERS.PRESCRIPTION_CREAMS
        ),
      Component: PrescriptionCreamsSurveyQuestion,
    },
    {
      isFilled: (data) =>
        !isEmpty(data.prescriptionPills) && data.prescriptionPills.length > 0,
      when: (data) =>
        !isEmpty(data.previousAcneProductsTaken) &&
        data.previousAcneProductsTaken.includes(
          PREVIOUS_ACNE_PRODUCTS_ANSWERS.PRESCRIPTION_PILLS
        ),
      Component: PrescriptionPillsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isAnyPrescriptionsInUse),
      when: (data) =>
        !isEmpty(data.previousAcneProductsTaken) &&
        (data.previousAcneProductsTaken.includes(
          PREVIOUS_ACNE_PRODUCTS_ANSWERS.PRESCRIPTION_CREAMS
        ) ||
          data.previousAcneProductsTaken.includes(
            PREVIOUS_ACNE_PRODUCTS_ANSWERS.PRESCRIPTION_PILLS
          )),
      Component: isAnyPrescriptionsInUseSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.currentPrescriptions),
      when: (data) => data.isAnyPrescriptionsInUse,
      Component: CurrentPrescriptionsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.interestingPrescriptions),
      Component: InterestingMedicationsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isAnyMedicalConditionPillsInUse),
      Component: isMedicalConditionPillsInUseSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.medications),
      when: (data) => data.isAnyMedicalConditionPillsInUse,
      Component: MedicationsSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.isAnyPrescriptionAllergies),
      Component: isAnyPrescriptionAllergiesSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.prescriptionAllergies),
      when: (data) => data.isAnyPrescriptionAllergies,
      Component: PrescriptionAllergiesSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.stressLevel),
      Component: StressLevelSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.sleepAmount),
      Component: SleepAmountSurveyQuestion,
    },
    {
      isFilled: (data) => !isEmpty(data.dairyConsumption),
      Component: DairyConsumptionSurveyQuestion,
      onComplete: async (data) => {
        const { height, weight, gender } = data;
        const skinSurvey: MedicalBackgroundItem[] = Object.entries(data)
          .filter(
            ([key, value]) => value !== null || excludedQuestions.includes(key)
          )
          .map(([key, value]) => ({
            id: key,
            ...surveyValueToDB(value),
          }));
        const response: any = await submitMedicalBackground({
          patientId: activePatientId,
          payload: {
            height,
            weight,
            sex: gender,
            skinSurvey,
          },
        });

        if (response.error || response.data.errors) {
          showError({
            title: "Something went wrong...",
            description:
              "Unable to submit your medical background. Please, try again later",
          });
          throw new Error("Unable to submit medical background.");
        }
      },
    },
    {
      isFilled: () => false,
      Component: SkinImagesSurveyQuestion,
    },
  ];

  const generateSurveyQuestions = (subQuestions: any[] | undefined) => {
    const questions =
      subQuestions &&
      subQuestions.map((question: { value: string | number }) => ({
        isFilled: (data: { [x: string]: any }) =>
          !isEmpty(data[question.value]) || isMedicalBackground,
        Component: SurveyQuestionTemplete,
        question,
        isChildAccount,
        when: (data) =>
          !question.parentQuestion ||
          (data[question?.parentQuestion] &&
            (data[question?.parentQuestion] === question.parentAnswer ||
              (question?.isArrayOfAnswer &&
                data[question?.parentQuestion].includes(
                  question.parentAnswer
                )))) ||
          (question.parentTextAnwser &&
            data[question.parentQuestion] !== question.parentTextAnwser),
      }));
    let payload = {};
    questions.push(
      {
        isFilled: (data: { height: any }) =>
          !isEmpty(data.height) || isMedicalBackground,
        Component: HeightSurveyQuestion,
      },
      {
        isFilled: (data: { weight: any }) =>
          !isEmpty(data.weight) || isMedicalBackground,
        Component: WeightSurveyQuestion,
        onComplete: async (
          data: { [s: string]: unknown } | ArrayLike<unknown>
        ) => {
          const { height, weight, gender } = data;
          const skinSurvey: MedicalBackgroundItem[] = Object.entries(data)
            .filter(
              ([key, value]) =>
                value !== null && !excludedQuestions.includes(key)
            )
            .map(([key, value]) => {
              const questionType = questions.find(
                (q) => q?.question?.value === key
              )?.question?.questionType;
              return {
                id: key,
                questionType,
                ...surveyValueToDB(value),
              };
            });
          payload = {
            height,
            weight,
            sex: gender,
            skinSurvey,
          };
          localStorage.setItem("payload", JSON.stringify(payload));
        },
      },
      {
        isFilled: () => false,
        Component: SkinImagesSurveyQuestion,
      }
    );
    return questions;
  };

  const skinIssuesOptions = [
    {
      label: "Skin Issues",
      value: "skinIssueType",
      options: skinIssues.map((issue) => ({
        label: issue.label,
        value: issue.value,
        surveyQuestions: generateSurveyQuestions(issue.subQuestions),
      })),
    },
  ];

  function findSkinTypeByName(typeName) {
    return skinIssues.filter((issue) => issue?.value === typeName);
  }

  function getSurveyDataFromLocalStorage(key) {
    const data = JSON.parse(window.localStorage.getItem(key));
    return data || {};
  }

  const existingData = getSurveyDataFromLocalStorage(localStorageKey);

  function setSurveyDataInLocalStorage(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  const [surveyQuestionsBySkinIssue, setSurveyQuestionsBySkinIssue] = useState(
    generateSurveyQuestions(findSkinTypeByName("acne")[0]?.subQuestions)
  );

  function skipSkinIssuesType(value) {
    const foundIssue = skinIssuesOptions[0].options.find(
      (issue) => issue.value === value
    );
    const surveyQuestions = foundIssue ? foundIssue.surveyQuestions : [];
    setSurveyQuestionsBySkinIssue(surveyQuestions);
    setState(STATES.SURVEY);
  }

  const generateImagesFilename = (images: ImageBlob[]) =>
    images.map((image) => {
      const extension = `${image.type.split("/").pop()}`;
      const filename = `${activePatientId}.${generateHash(10)}.${extension}`;
      return filename;
    });

  function existingCacheData(value) {
    if (!existingData || existingData.skinIssueType !== value) {
      const surveyData = { id: activePatientId, skinIssueType: value };
      setSurveyDataInLocalStorage(localStorageKey, surveyData);
    }
  }

  useEffect(() => {
    if (!existingData.skinIssueType) {
      existingCacheData("acne");
    }
  }, [surveyQuestionsBySkinIssue]);

  function updateSurveyQuestions(value: {
    surveyQuestions: React.SetStateAction<never[]>;
  }) {
    existingCacheData(value.value);
    setSurveyQuestionsBySkinIssue(value?.surveyQuestions);
  }

  function resetLocalStorage() {
    if (existingData) {
      const { id, skinIssueType } = existingData;
      const newData = { id, skinIssueType };
      setSurveyDataInLocalStorage(localStorageKey, newData);
    }
    setState(STATES.SURVEY);
  }

  function render() {
    switch (state) {
      case STATES.INIT:
        return (
          <div className="survey-page">
            <MedicalBackgroundImage className="survey-page__image" />
            <p className="survey-page__title">
              Let's fill in your medical background
            </p>
            <p className="survey-page__text paragraph-font--color">
              Don't worry - we'll make this quick!
            </p>
            <Button
              text="Okay, Let's go"
              onClick={() => {
                if (isFromAccutaneSource || isMedicalBackground) {
                  setState(STATES.SURVEY);
                } else if (existingData && existingData.skinIssueType) {
                  skipSkinIssuesType(existingData.skinIssueType);
                } else {
                  setState(STATES.SURVEY_TYPE);
                }
              }}
            />
          </div>
        );

      case STATES.SURVEY_TYPE:
        return (
          <div className="survey-question survey-question--small">
            <p className="survey-question__title">
              Which skin issue would you like to address?
            </p>
            <p className="survey-question__descr paragraph-font--color" />
            <p className="edit-patient-details-popup__input-title">
              skin Issue
            </p>
            <StyledSelect
              id="skinIssue"
              name="skinIssue"
              options={skinIssuesOptions}
              defaultValue={findSkinTypeByName(
                existingData?.skinIssueType || "acne"
              )}
              onChange={(value: any) => value && updateSurveyQuestions(value)}
              hideCrossIcon
            />
            <Button
              text="continue"
              onClick={() => {
                resetLocalStorage();
              }}
              className="survey-question__button"
              disabled={isEmpty(surveyQuestionsBySkinIssue)}
            />
          </div>
        );
      case STATES.SURVEY:
        return (
          <CustomSurvey
            questions={surveyQuestionsBySkinIssue}
            data={{
              id: activePatientId,
            }}
            onComplete={async (_data) => {
              try {
                setState(STATES.COMPLETE);
                return true;
              } catch {
                showError({
                  title: "Something went wrong",
                  description:
                    "Could not submit your survey for some reason. Please, try again later.",
                });
                return false;
              }
            }}
            localStorage={`skin-survey-data:${activePatientId}`}
            setState={setState}
            isFromAccutaneSource={isFromAccutaneSource !== null}
            isMedicalBackground={isMedicalBackground}
            miminumPage={0}
          />
        );
      case STATES.COMPLETE:
        return (
          <div className="survey-page">
            <MedicalBackgroundImage className="survey-page__image" />
            <p className="survey-page__title">
              All done! You are ready for your visit.
            </p>
            <p className="survey-page__text paragraph-font--color">
              Please check your email for a calendar invitation and the meeting
              details of your initial consultation.
            </p>
            <Button text="Great!" onClick={() => history.push("/")} />
          </div>
        );
      default:
        return null;
    }
  }

  return render();
}
