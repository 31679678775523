import { MODE } from "../constants";

export default function sendGAEvent(...args: unknown[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (MODE === "PROD" && window.ga) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ga(...args);
  }
}
