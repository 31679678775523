import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Medicine } from "../../../../../types/Entities/Medicine";
import { AuthenticationService } from "../../../../cognito";

export const get = async (medicineId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<Medicine>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/medicine`,
      {
        params: {
          id: medicineId,
        },
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get medicine data");
    throw error;
  }
};
