import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { ENV_CONFIG } from "../../config";
import { logError, logInfo } from "../../shared/logger";
import { SessionPayload } from "../../types/Payloads/SessionPayload";
import {
  ChangePasswordProps,
  ForgotPasswordProps,
  NewPasswordProps,
  ResetPasswordProps,
  SignInProps,
  SignUpPatientPayload,
  SignUpProps,
} from "./types";

const poolData = {
  UserPoolId: ENV_CONFIG.USER_POOL_ID,
  ClientId: ENV_CONFIG.CLIENT_ID,
};

const cognitoService = new CognitoUserPool(poolData);

const resetPassword = async ({
  email,
  verificationCode,
  password,
}: ResetPasswordProps) =>
  await new Promise<void>((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: cognitoService,
    });

    user.confirmPassword(verificationCode, password, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });

const setNewPassword = async ({
  email,
  userAttr,
  password,
}: NewPasswordProps) => {
  const user = new CognitoUser({
    Username: email,
    Pool: cognitoService,
  });
  // await new Promise((resolve, reject) => {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   user.getSession((err: any, session: any) => {
  //     console.log(err, session);
  //     if (!err) {
  //       resolve(session);
  //     } else {
  //       reject(err);
  //     }
  //   });
  // });
  await new Promise<void>((resolve, reject) => {
    user.completeNewPasswordChallenge(password, userAttr, {
      onSuccess: () => resolve(),
      onFailure: reject,
    });
  });
};

const currentLoggedInUser = async () => {
  const user = cognitoService.getCurrentUser();
  if (!user) {
    logError("Cannot change password: User is not logged in");
    return null;
  }
  return user;
};

const changePassword = async ({
  oldPassword,
  newPassword,
}: ChangePasswordProps) => {
  const user = cognitoService.getCurrentUser();
  if (!user) {
    logError("Cannot change password: User is not logged in");
    return;
  }

  try {
    await new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      user.getSession((err: any, session: any) => {
        if (!err) {
          resolve(session);
        } else {
          reject(err);
        }
      });
    });

    await new Promise((resolve, reject) => {
      user.changePassword(oldPassword, newPassword, (err) => {
        if (err) {
          reject(new Error(err.message));
        }

        resolve(null);
      });
    });
  } catch (err) {
    logError("Change password failed", err);
    throw err;
  }
};

const forgotPassword = async ({ email }: ForgotPasswordProps) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: cognitoService,
    });

    user.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    });
  });

const signIn = async ({
  email,
  password,
  onNewPasswordChallenge,
}: SignInProps) => {
  const result = await new Promise<SessionPayload>((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: cognitoService,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        resolve(data.getIdToken().decodePayload() as SessionPayload);
      },
      onFailure: reject,
      newPasswordRequired: (userAttrs) =>
        onNewPasswordChallenge(userAttrs, user),
    });
  });
  return result;
};

const checkSession = async () =>
  await new Promise<SessionPayload>((resolve, reject) => {
    const user = cognitoService.getCurrentUser();

    if (user) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      user.getSession((err: any, session: any) => {
        if (!err) {
          resolve(session.idToken.payload);
        } else {
          reject();
        }
      });
    } else {
      reject();
    }
  });

const signOut = () => {
  const user = cognitoService.getCurrentUser();

  if (!user) {
    return;
  }

  user.signOut();
};

const getAccessToken = async () => {
  const user = cognitoService.getCurrentUser();

  return await new Promise<string>((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user?.getSession((err: any, session: any) => {
      if (err) {
        reject(err);
      }

      resolve(session.accessToken.jwtToken);
    });
  });
};

const signUp = async (
  { email, password }: SignUpProps,
  data: SignUpPatientPayload
) =>
  await new Promise<Error | null>((resolve) => {
    cognitoService.signUp(
      email,
      password,
      [],
      [],
      (error) => {
        if (error) {
          resolve(error);
        } else resolve(null);
      },
      {
        patientData: JSON.stringify(data),
      }
    );
  });

const confirmRegistration = async (email: string, code: string) => {
  await new Promise((resolve) => {
    const user = new CognitoUser({
      Username: email,
      Pool: cognitoService,
    });

    user.confirmRegistration(code, false, (err, data) => {
      resolve(data);
    });
  });
};

const resendVerificationCode = async (email: string) => {
  await new Promise((resolve) => {
    const user = new CognitoUser({
      Username: email,
      Pool: cognitoService,
    });

    user.resendConfirmationCode((error) => {
      if (error) logError("Error during confirmation code resending", error);
      resolve(null);
    });
  });
};

export const AuthenticationService = {
  changePassword,
  resetPassword,
  currentLoggedInUser,
  forgotPassword,
  signIn,
  checkSession,
  signOut,
  getAccessToken,
  signUp,
  confirmRegistration,
  resendVerificationCode,
  setNewPassword,
};
