import axios from "axios";
import { logError } from "../../../../../shared/logger";
import { ENV_CONFIG } from "../../../../../config";
import { ProductPayload } from "../../../../../types/Payment/Product";
import { ShippingInfo } from "../../../../../types/Entities/Patient";

interface Props {
  patientId: string;
  purchasedProductsPriceIds: ProductPayload[];
  customerId: string;
  paymentMethod: string;
  isPaymentMethodExists: boolean;
  shippingInfo?: ShippingInfo;
  email: string;
}

export const processCheckout = async ({
  patientId,
  purchasedProductsPriceIds,
  customerId,
  paymentMethod,
  email,
  isPaymentMethodExists,
  shippingInfo,
}: Props) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/payments/processCheckout`,
      {
        patientId,
        purchasedProductsPriceIds,
        customerId,
        paymentMethod,
        email,
        shippingInfo,
        isPaymentMethodExists,
      }
    );

    return result;
  } catch (error) {
    logError("Error occured. Cannot create invoice", error);
    throw error;
  }
};
