import React, { useState } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";

export const usePdfToJpgConverter = () => {
  const [imageData, setImageData] = useState("");

  const convertPdfToJpg = async (pdfUrl: any) => {
    try {
      GlobalWorkerOptions.workerSrc =
        "//unpkg.com/pdfjs-dist/build/pdf.worker.min.js";
      const pdfDocumentInstance = await getDocument(pdfUrl).promise;
      const { numPages } = pdfDocumentInstance;
      const pages = await Promise.all(
        Array.from({ length: numPages }, (_, i) =>
          pdfDocumentInstance.getPage(i + 1)
        )
      );
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (!context) throw new Error("Unable to get context");
      const scale = 2;
      canvas.width = pages[0].view[2] * scale;
      canvas.height = pages[0].view[3] * scale;

      await Promise.all(
        pages.map(async (page, i) => {
          const viewport = page.getViewport({ scale });
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          await page.render({ canvasContext: context, viewport }).promise;
          setImageData(canvas.toDataURL("image/jpeg"));
        })
      );
    } catch (error) {
      console.error("Error converting PDF to JPG:", error);
    }
  };

  return { convertPdfToJpg, imageData };
};
