import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { act } from "react-dom/test-utils";
import { getPatientStore } from "../../../../features/patientView";
import {
  BasicPopupTemplate,
  BasicPopupTemplateContext,
} from "../../Common/PopupLayer/basic";
import "./style.scss";
import { getProvidersInfoState } from "../../../../features/providers";
import { Button } from "../../Common/Button";
import { PopupLayerProps } from "../../../../types/Base/PopupLayer";
import { TreatmentPlanItemsList } from "../TreatmentPlanItemsList";
import { TreatmentPlanProviderComment } from "../TreatmentPlanProviderComment";
import { ReactComponent as MedicalBackgroundImage } from "../../../../Assets/NewIcons/medical-background.svg";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { getSessionState } from "../../../../features/session";
import {
  useGetFollowUpsByPatientIdQuery,
  useMarkTreatmentPlanAsReadMutation,
} from "../../../../features/api/follow-ups";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { Skeleton } from "../../Common/Skeleton";
import { USER_ROLES } from "../../../../types/Main";
import { FollowUp } from "../../../../types/FollowUp";
import { getFullType } from "../../../../utils/get-full-type";

function MyTreatmentPlanPopup() {
  const history = useHistory();
  const { onClose } = useContext(BasicPopupTemplateContext);
  const { activePatientId } = useSelector(getSessionState);
  const [markAsRead] = useMarkTreatmentPlanAsReadMutation();
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const lastTreatmentPlan =
    followUpsQuery.data?.[0].treatmentPlan ||
    followUpsQuery.data?.[1].treatmentPlan;
  const employeeQuery = useGetEmployeeQuery(
    lastTreatmentPlan?.submittedBy as string,
    {
      skip: !lastTreatmentPlan,
    }
  );

  useEffect(() => {
    (async () => {
      if (
        followUpsQuery.data &&
        lastTreatmentPlan &&
        !lastTreatmentPlan?.isReadByPatient
      ) {
        const followUp = followUpsQuery.data.find(
          ({ treatmentPlan }) => treatmentPlan === lastTreatmentPlan
        ) as FollowUp;
        await markAsRead(followUp.followUpId);
      }
    })();
  }, [followUpsQuery]);

  function renderContent() {
    // if (!treatmentPlanData || !medications || !provider) {
    //   return (
    //     <div className="my-treatment-plan-popup__no-treatment">
    //       <MedicalBackgroundImage className="my-treatment-plan-popup__no-treatment-icon" />
    //       <p className="my-treatment-plan-popup__no-treatment-title">
    //         You don't have a treatment plan yet
    //       </p>
    //       <p className="my-treatment-plan-popup__no-treatment-descr">
    //         {activePatient?.appointments
    //           ? "Please, wait for a treatment plan from your provider"
    //           : "Make an initial visit in order to start your acne-free journey with Honeydew"}
    //       </p>
    //       {!activePatient?.appointments ? (
    //         <Button
    //           text="Let's go"
    //           onClick={() => {
    //             history.push("/submit-appointment");
    //             onClose();
    //           }}
    //         />
    //       ) : null}
    //     </div>
    //   );
    // }

    return (
      <div className="my-treatment-plan-popup">
        <p className="my-treatment-plan-popup__updated-text paragraph-font paragraph-font--color">
          {followUpsQuery.data && employeeQuery.data && lastTreatmentPlan ? (
            <>
              {" "}
              Your treatment plan was updated on{" "}
              {getMomentDate(lastTreatmentPlan.timestamp).format(
                "MMMM MM, YYYY"
              )}{" "}
              by {getFullType(employeeQuery.data.role)}{" "}
              {employeeQuery.data.firstName} {employeeQuery.data.lastName}
              {employeeQuery.data.role === USER_ROLES.PROVIDER
                ? `, ${employeeQuery.data.title}`
                : ""}
              .
            </>
          ) : (
            <Skeleton fullWidth />
          )}
        </p>
        <div className="my-treatment-plan-popup__comment">
          {lastTreatmentPlan && employeeQuery.data ? (
            <TreatmentPlanProviderComment
              employee={employeeQuery.data}
              treatmentPlan={lastTreatmentPlan}
            />
          ) : (
            <Skeleton />
          )}
        </div>
        <div className="my-treatment-plan-popup__list">
          {lastTreatmentPlan ? (
            <TreatmentPlanItemsList
              medications={lastTreatmentPlan.medications}
            />
          ) : (
            <Skeleton fullHeight fullWidth count={3} />
          )}
        </div>
        <div className="my-treatment-plan-popup__actions">
          <Button
            size="small"
            view="secondary"
            text="close"
            onClick={onClose}
            className="my-treatment-plan-popup__close-button"
          />
          <Button
            size="small"
            text="view history"
            onClick={() => {
              onClose();
              history.push("/profile");
            }}
            className="my-treatment-plan-popup__view-history"
          />
        </div>
      </div>
    );
  }

  return renderContent();
}

export function MyTreatmentPlanPopupGenerator() {
  return function render({ onClose }: PopupLayerProps) {
    return (
      <BasicPopupTemplate title="My Treatment Plan" onClose={onClose}>
        <MyTreatmentPlanPopup />
      </BasicPopupTemplate>
    );
  };
}
