import moment from "moment-timezone";

moment.tz.add(["America/New_York|EST EDT|50 40|0101|1Lz50 1zb0 Op0"]);

export function getDatePretty(_date: Date | string | number) {
  return (
    moment(_date)
      // .add(-1, "hour")
      .tz("America/New_York")
      .format("h:mma on dddd, MMMM Do")
  );
}

export function getChatDatePretty(_date: Date | string | number) {
  return (
    moment(_date)
      // .add(-1, "hour")
      .tz("America/New_York")
      .format("h:mm A, MMM Do")
  );
}

export function getFollowUpDatePretty(_date: Date | string | number) {
  return (
    moment(_date)
      // .add(-1, "hour")
      .tz("America/New_York")
      .format("ddd MMM Do, YYYY")
  );
}

export function getMyProgressDatePretty(_date: Date | string | number) {
  return (
    moment(_date)
      // .add(-1, "hour")
      .tz("America/New_York")
      .format("MMM D, YYYY")
  );
}

export function getMomentDate(_date: Date | string | number) {
  return (
    moment(_date)
      // .add(-1, "hour")
      .tz("America/New_York")
  );
}

export function getFormattedMomentDate(_date: Date | string | number) {
  return moment(_date).tz("America/New_York").format("ddd MMM DD, YYYY");
}

export function formatRelativeTimeShort(momentDate: moment.Moment) {
  const duration = moment().diff(momentDate, "seconds");

  if (duration < 60) {
    return `${duration} sec`;
  }
  if (duration < 3600) {
    return `${Math.floor(duration / 60)} min`;
  }
  if (duration < 86400) {
    return `${Math.floor(duration / 3600)} hr`;
  }
  return momentDate.fromNow();
}

export function getDateLongPretty(_date: Date | string | number) {
  return (
    moment(_date)
      // .add(-1, "hour")
      .tz("America/New_York")
      .format("MMMM Do YYYY, hh:mm A")
  );
}

export function getLocalDateLongPretty(_date: Date | string | number) {
  return moment.utc(_date).local().format("MMMM Do YYYY, hh:mm A");
}

export function getLocalDateNonMilitary(_date: Date | string | number) {
  return moment.utc(_date).local().format("MM/DD/YYYY h:mma");
}

export function getLocalDateNormal(_date: Date | string | number) {
  return moment.utc(_date).local().format("MM/DD/YYYY");
}

function isDaylightSavingTime(
  date: moment.Moment,
  zoneInfo: moment.MomentZone
): boolean {
  const standardOffset = zoneInfo.offsets[0];
  const currentOffset = date.utcOffset();
  return currentOffset !== standardOffset;
}

function getTimezoneAbbreviation(
  timezone: string,
  date: moment.Moment
): string {
  const zoneInfo = moment.tz.zone(timezone);
  if (!zoneInfo) return "";

  const isDst = isDaylightSavingTime(date, zoneInfo);
  const abbreviation = isDst
    ? zoneInfo.abbr(date.valueOf()).replace("D", "")
    : zoneInfo.abbr(date.valueOf());

  return abbreviation;
}

export function getGivenTimezoneDate(
  _date: Date | string | number,
  timezone = "America/New_York"
) {
  const date = moment.tz(_date, timezone);
  const timezoneAbbreviation = getTimezoneAbbreviation(timezone, date);
  return `${date.format("MM/DD/YYYY h:mma")} ${timezoneAbbreviation}`;
}

export function getGivenTimezoneLongPretty(
  _date: Date | string | number,
  timezone: string // Add default value here
) {
  timezone = timezone || "America/New_York";
  const date = moment.tz(_date, timezone);
  const timezoneAbbreviation = getTimezoneAbbreviation(timezone, date);
  return `${date.format("MMMM Do YYYY, hh:mm A")} ${timezoneAbbreviation}`;
}
