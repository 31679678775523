import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

interface FieldStructure {
  field_name: string;
  prefilled_text: string;
}

export const populatePdf = async (
  fields: FieldStructure[],
  documentName: string,
  patientId: string
): Promise<any> => {
  // TODO REPLACE TEMPLATE ID WITH CORRECT LATER
  const templateId = "18e62d7033974667b494a6bb8aa27b834cc7b62a";
  try {
    const result = await axios.post<any>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/populate-blood-slip/${templateId}`,
      {
        fields,
        documentName,
        patientId,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to get info");
    throw error;
  }
};
