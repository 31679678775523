import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientFollowUpVisit } from "../../Pages/Provider/PatientFollowUpVisit";
import { Footer } from "../../NewComponents/Common/Footer";
import { useGetAssignedPatientsQuery } from "../../../features/api/patients";
import PrintPatientInfoPage from "../../NewPages/PrintPatientInfo";
import { getSessionState } from "../../../features/session";
import { RescheduleAppointmentPage } from "../../NewPages/Common/RescheduleAppointmentPage";
import { PatientDashboardPageForEnrollmentCoordinator } from "../../NewPages/EnrollmentCoordinator/PatientDashboard";
import { HoneydewAPI } from "../../../services/honeydew-api";
import {
  getPatientDashboardForEnrollmentCoordinatorFiltersDeclinedVisits,
  getPatientDashboardForEnrollmentCoordinatorFiltersPostEnrollment,
  getPatientDashboardForEnrollmentCoordinatorFiltersUpcomingVisits,
} from "../../NewPages/EnrollmentCoordinator/PatientsPage/filters";

interface Props {
  isAuthorised: boolean;
  setParamsId: (id: any) => void;
}

function PatientDashboardForEnrollmentComponent({
  isAuthorised,
  setParamsId,
}: Props) {
  const params = useParams();
  const paramsId = params.userId;

  useEffect(() => {
    setParamsId(paramsId);
  }, [paramsId]);

  return isAuthorised ? (
    <>
      <PatientDashboardPageForEnrollmentCoordinator />
      <Footer />
    </>
  ) : (
    <Redirect to="/" />
  );
}

export default PatientDashboardForEnrollmentComponent;
