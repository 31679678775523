import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";

export async function countDashboardItems(
  queries: { query: any; label: string }[]
) {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<
      any,
      { data: { [p: string]: { count: number } } }
    >(
      `${ENV_CONFIG.QUERY_DASHBOARD_ITEMS_URL}/count`,
      {
        queries,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error: any) {
    logError("Error occured. Cannot count dashboard items", error);
    throw error;
  }
}
