import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const deleteDocumentInvite = async (
  patientId: string,
  documentGroupId: string
) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/delete-document-invite/${patientId}`,
      {
        documentGroupId,
      }
    );

    return result.status;
  } catch (error) {
    logError("Error received when trying to delete document group invite");
    throw error;
  }
};
