import React, { createContext, useContext, useState, useMemo } from "react";
import { ENV_CONFIG } from "../config";

type FeatureToggleContext = {
  isFeatureEnabled: (feature: string) => boolean;
};

const DEFAULT_VALUE = {
  isFeatureEnabled: () => false,
};

const FeatureToggleContext = createContext<FeatureToggleContext>(DEFAULT_VALUE);

export function FeatureToggleProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [config] = useState(ENV_CONFIG.ENABLED_FEATURES);

  const isFeatureEnabled = (feature: string) => config.includes(feature);

  const contextValue = useMemo(
    () => ({ isFeatureEnabled }),
    [isFeatureEnabled]
  );

  return (
    <FeatureToggleContext.Provider value={contextValue}>
      {children}
    </FeatureToggleContext.Provider>
  );
}

export const useFeatureToggle = () => useContext(FeatureToggleContext);
