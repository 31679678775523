import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";

export const patientDashboardForCareCoordinatorOrder = (
  careCoordinatorId: string
) => [
  {
    filter: {
      bool: {
        must: [
          {
            match: {
              tasks: careCoordinatorId,
            },
          },
          {
            bool: {
              should: [
                {
                  match: {
                    "followUp.isPrescriptionSubmitted": true,
                  },
                },
                {
                  match: {
                    "followUp.isTreatmentPlanSet": false,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    weight: 4,
  },
  {
    filter: {
      bool: {
        must: [
          {
            match: {
              "followUp.isTreatmentPlanSet": true,
            },
          },
          {
            match: {
              "followUp.isPrescriptionSubmitted": false,
            },
          },
        ],
      },
    },
    weight: 2,
  },
  {
    filter: {
      bool: {
        should: [
          {
            match: {
              [`flags.pinnedByCareCoordinator_${careCoordinatorId}`]: true,
            },
          },
          {
            match: {
              "flags.pinnedByCareCoordinator": true,
            },
          },
        ],
      },
    },
    weight: 1,
  },
  {
    filter: {
      match_all: {},
    },
    weight: 1,
  },
];
