import React, { useState, createContext } from "react";
import { ReactComponent as CloseButton } from "../../../../Assets/icons/close-button.svg";
import { logInfo } from "../../../../shared/logger";
import "./style.css";

export const ImageViewPopupContext = createContext({
  showImagePopup: (src: string) => logInfo(src),
});

interface Props {
  children: (JSX.Element | null)[] | JSX.Element | null;
}

export function ImageViewPopup({ children }: Props) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isShown, setShowStatus] = useState(false);
  const [isFullSize, setFullSizeStatus] = useState(false);

  const showImagePopup = (src: string) => {
    if (!src || typeof src !== "string") return;
    setImageSrc(src);
    setShowStatus(true);
    setFullSizeStatus(false);
  };

  const closePopup = () => {
    setShowStatus(false);
  };

  const zoomIn = () => {
    if (isFullSize) return;
    setFullSizeStatus(true);
  };

  const zoomOut = () => {
    if (!isFullSize) return;
    setFullSizeStatus(false);
  };

  return (
    <ImageViewPopupContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        showImagePopup,
      }}
    >
      {isShown ? (
        <div className="image-view-popup">
          <CloseButton
            className="image-view-popup__close-button"
            onClick={closePopup}
          />
          <div
            className={`image-view-popup__image-container${
              isFullSize ? " image-view-popup__image-container--full-size" : ""
            }`}
            onClick={zoomOut}
          >
            <img
              className={`image-view-popup__image image-view-popup__image${
                isFullSize ? "--full-size" : "--contain-size"
              }`}
              src={imageSrc || ""}
              onClick={zoomIn}
            />
          </div>
        </div>
      ) : null}
      {children}
    </ImageViewPopupContext.Provider>
  );
}
