import React, { useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { RadioButtonsList } from "../../../../NewComponents/Common/RadioButtonsList";

export const MENSTRUAL_BREAKOUT_ANSWERS = {
  YES: "Premenstrual/during menstruation",
  NO: "Throughout the month",
  DONT_MENSTRUATE: "I don't menstruate",
};

const list = [
  {
    value: MENSTRUAL_BREAKOUT_ANSWERS.YES,
    label: "Yes",
  },
  {
    value: MENSTRUAL_BREAKOUT_ANSWERS.NO,
    label: "No, I break out throughout the month",
  },
  {
    value: MENSTRUAL_BREAKOUT_ANSWERS.DONT_MENSTRUATE,
    label: "I don't menstruate",
  },
];

function surveyToComponent(data: SurveyData) {
  return data.menstruationBreakout;
}

function componentToSurvey(value: boolean) {
  return {
    menstruationBreakout: value,
  };
}

export function MenstrualBreakoutSurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));

  function submit() {
    if (isEmpty(currentData)) return;

    sendSurveyQuestion("menstruationBreakout");
    onDone(componentToSurvey(currentData));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        Do you break out during/leading up to your menstrual cycle specifically
        ?
      </p>
      <p className="survey-question__descr paragraph-font--color" />
      <RadioButtonsList
        list={list}
        currentValue={currentData ? (currentData as string) : undefined}
        onChange={(value) => setData(value)}
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={!currentData}
      />
    </div>
  );
}
