import React, { useState } from "react";
import Joi from "joi";
import JoiPhoneNumber from "joi-phone-number";
import parsePhoneNumber from "libphonenumber-js";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { TextInput } from "../../../../NewComponents/Common/TextInput";
import { sendSurveyQuestion } from "../../../../../shared/analytics";

interface Data {
  fullName: string;
  email: string;
  phone: string;
}

function surveyToComponent(data: SurveyData): Data {
  return {
    fullName: data.parents?.name || "",
    email: data.parents?.email || "",
    phone: data.parents?.phone || "+1",
  };
}

function componentToSurvey(value: Data) {
  return {
    parents: {
      name: value.fullName.trim(),
      email: value.email.trim(),
      phone: value.phone.trim(),
    },
  };
}

export function ParentsDataSurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));
  const [fullNameInvalid, setFullNameInvalid] = useState("");
  const [emailInvalid, setEmailInvalid] = useState("");
  const [phoneInvalid, setPhoneInvalid] = useState("");

  const { isChild } = data;

  function validateFullName() {
    if (!currentData.fullName.trim()) {
      setFullNameInvalid("Please, enter your full name");
      return false;
    }

    if (currentData.fullName.length > 255) {
      setFullNameInvalid("Full name is too long");
      return false;
    }

    if (currentData.fullName.match(/[^a-zA-Z' -]/)) {
      setFullNameInvalid(
        "You can use only latin letters, space, - and ' symbols"
      );
      return false;
    }

    return true;
  }

  function validateEmail() {
    const { error } = Joi.string()
      .email({ tlds: { allow: false } })
      .validate(currentData.email);
    if (error) {
      setEmailInvalid("Invalid email");
      return false;
    }

    return true;
  }

  function validatePhone() {
    const parsedPhoneNumber = parsePhoneNumber(currentData.phone, {
      defaultCountry: "US",
    });
    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      setPhoneInvalid("Invalid phone number");
      return false;
    }

    return true;
  }

  function submit() {
    const isEmailValid = validateEmail();
    const isFullNameValid = validateFullName();
    const isPhoneValid = validatePhone();
    if (!isEmailValid || !isFullNameValid || !isPhoneValid) return;

    sendSurveyQuestion("parentsData");
    onDone(componentToSurvey(currentData));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        {isChild
          ? "Finally, what is your contact information?"
          : "Since you're under 18, we'll need your parent's contact info."}
      </p>
      <p className="survey-question__descr paragraph-font--color">
        {isChild
          ? "We'll keep you on file as the patient's representative, so we can reach you if needed."
          : "Please enter your parent or guardian's contact information below, and have them be present for the initial consultation to provide consent."}
      </p>
      <div className="survey-question__input">
        <TextInput
          id="full-name"
          name="full-name"
          onChange={(value) => {
            setData({
              ...currentData,
              fullName: value,
            });
            setFullNameInvalid("");
          }}
          required
          value={currentData.fullName || ""}
          placeholder="Full name"
          error={fullNameInvalid}
        />
      </div>
      <div className="survey-question__input">
        <TextInput
          id="email"
          name="email"
          onChange={(value) => {
            setData({
              ...currentData,
              email: value,
            });
            setEmailInvalid("");
          }}
          required
          value={currentData.email || ""}
          placeholder="Email address"
          error={emailInvalid}
        />
      </div>
      <div className="survey-question__input">
        <TextInput
          id="phone"
          name="phone"
          onChange={(value) => {
            setData({
              ...currentData,
              phone: value,
            });
            setPhoneInvalid("");
          }}
          required
          value={currentData.phone || ""}
          placeholder="+1 999 999 9999"
          error={phoneInvalid}
        />
      </div>
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={!Object.values(currentData).every((value) => !!value)}
      />
    </div>
  );
}
