import React from "react";
import { StepIconProps, StepProps, StepsProps } from "./types";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import "./style.scss";

function StepIcon({ stepNumber, isActive, iconType, size }: StepIconProps) {
  const stepIconStyle = {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    justifyContent: "center",
    position: "relative",
    border: isActive ? "none" : "2px solid #60B257",
    background: isActive ? "#60B257" : "#fff",
    boxSizing: "border-box",
    borderRadius: "20px",
    width: size === "default" ? "24px" : "20px",
    height: size === "default" ? "24px" : "20px",
    zIndex: 2,
    color: isActive ? "#fff" : "#403E48",
  } as React.CSSProperties;

  let iconContent;

  if (iconType !== "numbers") {
    iconContent = isActive ? <CheckIcon /> : "";
  } else {
    iconContent = stepNumber;
  }

  return (
    <div
      style={stepIconStyle}
      className={stepNumber === 1 ? `step-icon__${size}` : ""}
    >
      {iconContent}
    </div>
  );
}

export function Step({
  children,
  direction,
  iconType,
  isActive,
  size,
  stepNumber,
  title,
  view,
}: StepProps) {
  const stepClassName = `steps__${direction}__single-step__${view}-${size}`;
  const titleWrapperClassName = `steps__${direction}__single-step__${view}-${size}-title__wrapper`;
  const titleClassName = `steps__${direction}__single-step__${view}-${size}-title__wrapper-content`;
  const wrapperDescriptionClassName = `steps__${direction}__single-step__${view}-${size}-title__wrapper-description`;

  return (
    <div className={stepClassName} key={`${title}__step`}>
      <StepIcon
        stepNumber={stepNumber || 0}
        isActive={isActive || false}
        iconType={iconType || "numbers"}
        size={size || "default"}
      />
      <div className={titleWrapperClassName}>
        <p className={titleClassName}>{title}</p>
        <div className={wrapperDescriptionClassName}>{children}</div>
      </div>
    </div>
  );
}

export function Steps({
  children,
  className,
  direction = "vertical",
  iconType = "numbers",
  size = "default",
  view = "right",
}: StepsProps) {
  return (
    <div className={`steps__${direction} ${className || ""}`}>
      {children.map((value, index) =>
        React.createElement(Step, {
          view,
          iconType,
          direction,
          size,
          stepNumber: index + 1,
          ...value.props,
        })
      )}
    </div>
  );
}
