import { CareCoordinator } from "../Entities/CareCoordinator";
import { Provider } from "../Entities/Provider";

export interface TableBodyPayload<T> {
  isNewMessage: boolean;
  isHighlightedMessage?: boolean;
  entity: T;
  nextFollowUpDate: Date | null;
  rowClass: string | null;
  type: string | null;
  provider?: Provider;
  careCoordinator?: CareCoordinator;
}

export enum Stylings {
  Base = "base",
  Red = "red",
  Blue = "blue",
  Orange = "orange",
}

export interface PatientsTableBodyPayload<T> {
  data: T;
  row: JSX.Element[];
  rowStyle: Stylings;
}
