import React from "react";
import { useSelector } from "react-redux";
import { ChatCard } from "../../../NewComponents/Patient/ChatCard";
import { ContactProviderCard } from "../../../NewComponents/Patient/ContactProviderCard";
import { FollowUpVisitCard } from "../../../NewComponents/Patient/FollowUpVisitCard";
import ImportantIpledgeDates from "../../../NewComponents/Patient/ImportantIpledgeDates";
import { MyTreatmentPlanCard } from "../../../NewComponents/Patient/MyTreatmentPlanCard";
import { OrderTreatmentCard } from "../../../NewComponents/Patient/OrderTreatmentCard";
import { ReferralCard } from "../../../NewComponents/Patient/ReferralCard";
import { TrackProgressCard } from "../../../NewComponents/Patient/TrackProgressCard";
import { WelcomeCard } from "../../../NewComponents/Patient/WelcomeCard";
import "./style.scss";
import { getSessionState } from "../../../../features/session";
import { useGetAccutaneByPatientIdQuery } from "../../../../features/api/accutane";

export function PatientHomePage() {
  const { activePatientId } = useSelector(getSessionState);
  const patientId = activePatientId as string;
  const accutaneQuery = useGetAccutaneByPatientIdQuery(patientId);

  return (
    <div className="patient-home-page">
      <div className="patient-home-page__block patient-home-page__block--welcome">
        <WelcomeCard patientId={patientId} />
      </div>
      <div className="patient-home-page__block patient-home-page__block--referral">
        <ReferralCard />
      </div>
      <div className="patient-home-page__block patient-home-page__block--iPledge">
        {accutaneQuery.data && (
          <div className="patient-home-page__block--iPledge__ipledge">
            <ImportantIpledgeDates accutane={accutaneQuery.data} />
          </div>
        )}
      </div>
      <div className="patient-home-page__block patient-home-page__block--follow-up">
        {/* {accutane && (
          <div className="patient-home-page__block--follow-up__ipledge">
            <ImportantIpledgeDates accutane={accutane} />
          </div>
        )} */}
        <FollowUpVisitCard patientId={patientId} />
      </div>
      <div className="patient-home-page__block patient-home-page__block--contact-provider">
        {accutaneQuery.data ? (
          <ImportantIpledgeDates accutane={accutaneQuery.data} />
        ) : (
          <ContactProviderCard />
        )}
      </div>

      <div className="patient-home-page__block patient-home-page__block--my-treatment">
        <MyTreatmentPlanCard patientId={patientId} />
      </div>
      <div className="patient-home-page__block patient-home-page__block--progress">
        <TrackProgressCard patientId={patientId} />
      </div>
      <div className="patient-home-page__block patient-home-page__block--products">
        <OrderTreatmentCard />
      </div>
      <div className="patient-home-page__block patient-home-page__block--chat">
        <ChatCard />
      </div>
    </div>
  );
}
