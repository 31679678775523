import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import "./style.scss";

interface Props {
  onChange: (value: string) => void;
  value?: string;
  maxRows?: number;
  minRows?: number;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

export function TextArea({
  onChange,
  value,
  maxRows,
  minRows,
  disabled,
  className,
  placeholder,
}: Props) {
  return (
    <TextareaAutosize
      className={`text-area text-input__field ${className}`}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      defaultValue={value}
      maxRows={maxRows || 5}
      minRows={minRows || 1}
      value={value}
    />
  );
}
