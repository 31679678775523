import { IAssigneeOptions } from "./types";
import { USER_ROLES } from "../../../../types/Main";

export const assignTaskModalOptions = [
  {
    label: "all tasks",
    options: [
      {
        label: "Review blood work results",
        value: "bloodWorkResults",
      },
      {
        label: "Review urine pregnancy test",
        value: "urinePregnancyTest",
      },
      {
        label: "Government-Issued ID",
        value: "governmentIssuedId",
      },
    ],
  },
];

const employeeTypeOrder = {
  [USER_ROLES.PROVIDER]: 3,
  [USER_ROLES.CARE_COORDINATOR]: 2,
  [USER_ROLES.ADMINISTRATOR]: 1,
  [USER_ROLES.ENROLLMENT_COORDINATOR]: 0,
};

export const mapStringToType = {
  "Care coordinator": "care-coordinator",
  Provider: "provider",
  "Enrollment coordinator": "enrollment-coordinator",
  Admin: "admin",
};

export const mapTypeToString = {
  [USER_ROLES.CARE_COORDINATOR]: "Care coordinator",
  [USER_ROLES.PROVIDER]: "Provider",
  [USER_ROLES.ENROLLMENT_COORDINATOR]: "Enrollment coordinator",
  [USER_ROLES.ADMINISTRATOR]: "Admin",
};

function sortAssignees(a: IAssigneeOptions, b: IAssigneeOptions) {
  if (employeeTypeOrder[a.type] < employeeTypeOrder[b.type]) return 1;
  if (employeeTypeOrder[a.type] > employeeTypeOrder[b.type]) return -1;
  if (a.firstName < b.firstName) return -1;
  if (a.firstName > b.firstName) return 1;
  return 0;
}

export const assigneeOptions = (assigneeEmployees: IAssigneeOptions[]) => [
  ...(assigneeEmployees.some(({ inCareTeam }) => inCareTeam)
    ? [
        {
          label: "Care team members",
          options: assigneeEmployees
            .filter(({ inCareTeam }) => inCareTeam)
            .sort(sortAssignees)
            .map((assignee) => ({
              label: `${assignee.firstName} ${assignee.lastName} (${
                mapTypeToString[assignee.type]
              })`,
              value: assignee.id,
            })),
        },
      ]
    : []),
  {
    label: "All staff users",
    options: assigneeEmployees
      .filter(({ inCareTeam }) => !inCareTeam)
      .sort(sortAssignees)
      .map((assignee) => ({
        label: `${assignee.firstName} ${assignee.lastName} (${
          mapTypeToString[assignee.type]
        })`,
        value: assignee.id,
      })),
  },
];
