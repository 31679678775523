import React, { useContext, useState } from "react";
import zipState from "zip-state";
import { HONEYDEW_ELIGIBLE_STATES } from "../../../../../data/states";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { PopupLayerContext } from "../../../../NewComponents/Common/PopupLayer";
import { TextInput } from "../../../../NewComponents/Common/TextInput";
import { UnsupportedAreaPopupGenerator } from "../../../../NewComponents/Patient/UnsupportedAreaPopup";

function surveyToComponent(data: SurveyData) {
  return data.zipCode;
}

function componentToSurvey(value: string) {
  return {
    zipCode: value,
  };
}

export function ZipCodeSurveyQuestion({ data, onDone }: SurveyComponentProps) {
  const { showPopup } = useContext(PopupLayerContext);
  const [currentData, setData] = useState(surveyToComponent(data));
  const [zipCodeInvalid, setZipCodeInvalid] = useState("");
  const { isChild } = data;

  function validate() {
    const valid = new RegExp(/^\d{5}$/).test(currentData);
    if (!valid) {
      setZipCodeInvalid("Invalid zip code");
      return false;
    }

    return true;
  }

  function submit() {
    const result = validate();

    if (!result) return;

    const state = zipState(currentData);
    const isEligibleState =
      state && Object.keys(HONEYDEW_ELIGIBLE_STATES).includes(state);

    if (!isEligibleState) {
      showPopup(UnsupportedAreaPopupGenerator(currentData));
      return;
    }

    sendSurveyQuestion("zipCode");
    onDone(componentToSurvey(currentData));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        {isChild ? "What zip code is your child in?" : "What is your zip code?"}
      </p>
      <p className="survey-question__descr paragraph-font--color" />
      <TextInput
        id="zip-code"
        name="zip-code"
        onChange={(value) => {
          setData(value);
          setZipCodeInvalid("");
        }}
        required
        value={currentData || ""}
        placeholder="10001"
        error={zipCodeInvalid}
        className="survey-question__input"
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={!currentData}
      />
    </div>
  );
}
