import { devEnvConfig } from "./dev";
import { testEnvConfig } from "./test";
import { prodEnvConfig } from "./prod";
import { EnvironmentConfig } from "../types/EnvConfig";

export const ENV_CONFIG = ((): EnvironmentConfig => {
  switch (process.env.REACT_APP_ENV) {
    case "DEV":
      return devEnvConfig;
    case "TEST":
      return testEnvConfig;
    case "PROD":
      return prodEnvConfig;
    default:
      throw new Error(`Unknown environment "${process.env.REACT_APP_ENV}"`);
  }
})();
