import React, { FormEvent, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Joi from "joi";
import { useDispatch } from "react-redux";
import { hide as hideErrorNotifcation } from "../../../../features/errorNotification";
import { AuthenticationService } from "../../../../services/cognito";
import { logError } from "../../../../shared/logger";
import { SubmitButton } from "../../../NewComponents/Common/Button";
import { TextInput } from "../../../NewComponents/Common/TextInput";
import "./style.css";
import { VerifyYourEmail } from "../../../NewPages/VerifyYourEmail";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";

export function ForgotPassword() {
  const { showError } = useContext(NotificationLayerContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState("");
  const [submitInProgress, setSubmitState] = useState(false);
  const [success, setSuccessState] = useState(false);

  // eslint-disable-next-line no-nested-ternary
  const submitButtonStatus = !email.length
    ? "disabled"
    : submitInProgress
    ? "loading"
    : undefined;

  function validateEmail() {
    const { error } = Joi.string()
      .email({ tlds: { allow: false } })
      .validate(email);
    if (error) {
      setEmailInvalid("Invalid email");
      return false;
    }

    return true;
  }

  const submit = async (event: FormEvent) => {
    dispatch(hideErrorNotifcation());
    if (!validateEmail()) return;

    setSubmitState(true);
    event.preventDefault();

    try {
      await AuthenticationService.forgotPassword({ email });
      setSuccessState(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      showError({
        title: "Error",
        description: e.message,
      });
      logError("Cannot request password change", e);
      setSubmitState(false);
    }
  };

  return success ? (
    <VerifyYourEmail email={email} onSuccess={() => history.push("/")} />
  ) : (
    <>
      <p className="logInContainer__title">Forgot your password?</p>
      <p className="logInContainer__description">
        Please type your email username to reset it.
      </p>
      <form className="logInContainer__form" onSubmit={submit}>
        <div className="logInContainer__field-block">
          <TextInput
            disabled={submitInProgress}
            type="text"
            id="email"
            name="email"
            placeholder="Enter your email"
            required
            onChange={(value) => {
              setEmail(value);
              setEmailInvalid("");
            }}
            error={emailInvalid}
          />
        </div>
        <SubmitButton text="Continue" status={submitButtonStatus} />
      </form>
    </>
  );
}
