import React, { useState } from "react";
import {
  SurveyComponentProps,
  SurveyData,
  TreatmentAnswer,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { RadioButtonsForm } from "../../../../NewComponents/Common/RadioButtonsForm";
import { TextArea } from "../../../../NewComponents/Common/TextArea";
import { TreatmentPlanItemsList } from "../../../../NewComponents/Patient/TreatmentPlanItemsList";
import { useGetFollowUpsByPatientIdQuery } from "../../../../../features/api/follow-ups";
import { Skeleton } from "../../../../NewComponents/Common/Skeleton";

enum STATES {
  SAME_AS_DIRECTED = "NORMAL",
  MODIFIED = "MODIFIED",
  STOPPED = "STOPPED",
}

function surveyToComponent(data: SurveyData): TreatmentAnswer[] | null {
  return data.treatmentSurvey;
}

function componentToSurvey(value: TreatmentAnswer[]) {
  return {
    treatmentSurvey: value,
  };
}

export function TreatmentsSurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(
    typeof surveyToComponent(data) === "string"
      ? []
      : surveyToComponent(data) || []
  );
  const [step, setStep] = useState(0);
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(data.id);
  const lastTreatmentPlan = followUpsQuery.data?.[0]?.treatmentPlan;

  const list = [
    {
      value: STATES.SAME_AS_DIRECTED,
      label: "I use it exactly as directed",
    },
    {
      value: STATES.MODIFIED,
      label: "I modified the amount or frequency of use",
      extra:
        currentData[step]?.answer === STATES.MODIFIED ? (
          <TextArea
            onChange={(value) => {
              currentData[step].comment = value;
              setData(Array.from(currentData));
            }}
            placeholder="Please explain"
            maxRows={5}
            minRows={2}
            value={currentData[step]?.comment || undefined}
          />
        ) : undefined,
    },
    {
      value: STATES.STOPPED,
      label: "I stopped using this",
      extra:
        currentData[step]?.answer === STATES.STOPPED ? (
          <TextArea
            onChange={(value) => {
              currentData[step].comment = value;
              setData(Array.from(currentData));
            }}
            placeholder="Please explain"
            maxRows={5}
            minRows={2}
            value={currentData[step]?.comment || undefined}
          />
        ) : undefined,
    },
  ];

  function submit() {
    if (isEmpty(currentData)) return;

    onDone(componentToSurvey(currentData));
  }

  function nextPage() {
    if (!lastTreatmentPlan) return;
    if (step < lastTreatmentPlan.medications.length - 1) {
      setStep(step + 1);
    } else {
      submit();
    }
  }

  function isValid() {
    if (!currentData[step]) return false;
    if (currentData[step].answer === STATES.SAME_AS_DIRECTED) return true;
    if (currentData[step].comment) return true;
    return false;
  }

  function previousPage() {
    setStep(step - 1);
  }

  return (
    <div className="survey-question survey-question--medium">
      <p className="survey-question__title">
        Please let your provider know how you've been using your recommended
        treatments
      </p>
      <p className="survey-question__descr paragraph-font--color" />
      {lastTreatmentPlan ? (
        <div className="survey-question__body">
          <div className="survey-question__progress">
            {lastTreatmentPlan.medications.map((el, i) => (
              <div
                className={`survey-question__progress-item ${
                  i <= step ? "selected" : ""
                }`}
              />
            ))}
          </div>
          <TreatmentPlanItemsList
            medications={[lastTreatmentPlan.medications[step]]}
            expand
          />
          <RadioButtonsForm
            list={list}
            onChange={(value) => {
              currentData[step] = {
                answer: value as STATES,
                comment: null,
              };
              setData(Array.from(currentData));
            }}
            currentValue={currentData[step]?.answer}
          />
        </div>
      ) : (
        <Skeleton fullWidth />
      )}

      {lastTreatmentPlan ? (
        <div className="survey-question__actions">
          {step !== 0 ? (
            <Button
              text="back"
              onClick={previousPage}
              view="secondary"
              disabled={step === 0}
              className="survey-question__treatment-button"
            />
          ) : null}
          <Button
            text="continue"
            onClick={nextPage}
            className="survey-question__treatment-button"
            disabled={!isValid()}
          />
        </div>
      ) : (
        <Skeleton />
      )}
    </div>
  );
}
