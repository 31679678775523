import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { AuthenticationService } from "../../../../cognito";

export const remove = async (treatmentPlanId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    await axios.delete(`${ENV_CONFIG.AMAZON_API_DOMAIN}/treatment-plans`, {
      params: {
        id: treatmentPlanId,
      },
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    logError(`Error occured. Cannot delete treatment plan`);
    throw error;
  }
};
