import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";

export const patientDashboardForCareCoordinatorSort = () => [
  {
    filter: {
      bool: {
        must: [
          {
            match: {
              "followUp.isTreatmentPlanSet": true,
            },
          },
          {
            match: {
              "followUp.isPrescriptionSubmitted": false,
            },
          },
        ],
      },
    },
    script_score: {
      script: {
        source: `10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
      },
    },
    weight: 1,
  },
  {
    filter: {
      match_all: {},
    },
    weight: 1,
  },
];
