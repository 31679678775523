import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { PaymentCreationPayload } from "../../../../../types/Payment/PaymentCreationPayload";

export const createSubscription = async ({
  customerId,
  paymentMethodId,
  subscriptionItems,
  metadata,
  promotionCode,
  productItems,
  isPaymentMethodExists,
  shippingInfo,
}: PaymentCreationPayload) => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/payments/create-subscription`,
      {
        customerId,
        paymentMethodId,
        subscriptionItems,
        metadata,
        promotionCode,
        productItems,
        isPaymentMethodExists,
        shippingInfo,
      }
    );
    return result.data;
  } catch (e) {
    logError("Something went wrong during subscription creation", e);
    throw e;
  }
};
