import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Medicine } from "../../../../../types/Entities/Medicine";
import { AuthenticationService } from "../../../../cognito";

export const update = async (medicine: Medicine) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.put<Medicine>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/medicine/update`,
      medicine,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError(`Error occured. Cannot update medicine`);
    throw error;
  }
};
