import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Mention, MentionsInput } from "react-mentions";
import { Patient } from "../../../../types/Entities/Patient";
import { ReactComponent as SendIcon } from "../../../../Assets/NewIcons/send.svg";
import { getSessionState } from "../../../../features/session";
import "./style.scss";
import { PatientNote } from "../../../../types/PatientNote";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import {
  employeesApiSlice,
  useGetEmployeeQuery,
  useGetAllEmployeesQuery,
  useGetAllEmployeesOnTimeOffQuery,
} from "../../../../features/api/employees";
import { Skeleton } from "../Skeleton";
import {
  useAddPatientNoteMutation,
  useGetPatientNotesQuery,
} from "../../../../features/api/patients";
import { NotificationLayerContext } from "../NotificationLayer";
import { getEmployeeFullName } from "../../../../utils/get-employee-full-name";
import { Employee } from "../../../../types/Employee";
import { LoadingBlock } from "../LoadingBlock";
import { Button } from "../Button";
import { assignTask } from "../../../../services/acneaway-api/entities/providers";
import "./mentions.css";
import checkmark from "../../../../Assets/NewIcons/checkmark.svg";
import completedCheckmark from "../../../../Assets/NewIcons/completedCheckmark.svg";
import {
  useGetTasksQuery,
  useUpdateTaskStatusMutation,
} from "../../../../features/api/accutane";
import addNoteIcon from "../../../../Assets/NewIcons/sendNoteIcon.svg";
import { PopupLayerContext } from "../PopupLayer";
import { WarnSubstituteEmployeePopupGenerator } from "../../../Components/Common/WarnSubstituteEmployeePopup";

function getUserAssignedTask(notesTasks, userId) {
  return notesTasks.find(
    (task) =>
      task.assignedTo.id === userId &&
      task.status !== "COMPLETED" &&
      task.status !== "DELETED"
  );
}

function PatientNoteMessage({ note }: { note: PatientNote }) {
  const { userId } = useSelector(getSessionState);
  const employeeQuery = useGetEmployeeQuery(note.creatorId as string, {
    skip: !note.creatorId,
  });
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [updateTaskStatus] = useUpdateTaskStatusMutation();

  // const [notesTasks, setNotesTasks] = useState<any[]>([]);
  const [showCheckmark, setShowCheckmark] = useState(false);

  const notesTasks = useGetTasksQuery({
    patientId: note.patientId,
    documentId: note.noteId,
  });

  const submitTask = async (task: { taskId: any; compositeKey: any }) => {
    const updatedBy = {
      id: note.creatorId || "",
      role: employeeQuery.data?.role || "",
    };
    try {
      const result: any = await updateTaskStatus({
        taskId: task?.id,
        compositeKey: task?.compositeKey,
        updatedBy,
        patientId: note.patientId,
        status: "COMPLETED",
      });

      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to complete task",
        });
      } else {
        showSuccess({
          title: "Success!",
          description: "Successfully completed task",
        });
      }
    } catch (error) {
      console.error("Error completing task:", error);
      showError({
        title: "Something went wrong...",
        description: "Unable to complete task",
      });
    }
  };

  function extractMentionedUsers(text: string) {
    const regex = /@(\w+\s+\w+)/g;
    const matches = text.match(regex);
    return matches ? matches.map((match) => match.slice(1)) : [];
  }

  function renderNoteTextWithMentions(noteText: string) {
    const mentionedUsers = extractMentionedUsers(noteText);
    const parts = noteText.split(/(@\w+\s+\w+)/g);

    return parts.map((part, index) => {
      if (part.startsWith("@")) {
        return <div className="mentioned-user">{part}</div>;
      }
      return <span>{part}</span>;
    });
  }

  return (
    <div className="patient-notes__content__note">
      <p className="patient-notes__content__note__text">
        {renderNoteTextWithMentions(note.text)}
      </p>
      <p className="patient-notes__content__note__info">
        {!employeeQuery.data && !note.creatorName ? (
          <Skeleton fullWidth flex />
        ) : (
          <>
            <span>
              {note.creatorName ||
                getEmployeeFullName(employeeQuery.data as Employee)}{" "}
              on{" "}
            </span>
            <span>
              {moment
                .utc(note.timestamp)
                .local()
                .format("MMMM DD YYYY, hh:mm A")}
            </span>
          </>
        )}
      </p>
      {notesTasks?.data?.length > 0 &&
        getUserAssignedTask(notesTasks?.data, userId) && (
          <span
            className="patient-notes__content__note__info__icon"
            onMouseEnter={() => setShowCheckmark(true)}
            onMouseLeave={() => setShowCheckmark(false)}
            onClick={() =>
              submitTask(getUserAssignedTask(notesTasks?.data, userId))
            }
          >
            <img src={showCheckmark ? completedCheckmark : checkmark} />
          </span>
        )}
    </div>
  );
}

function AddNoteInput() {
  const [noteText, setText] = useState("");
  const [prevNoteText, setPreviousText] = useState("");
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const { userId, userRole, activePatientId } = useSelector(getSessionState);
  const [addPatientNote] = useAddPatientNoteMutation();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [suggestedEmployees, setSuggestedEmployees] = useState<Employee[]>([]);
  const [mentionedEmployees, setMentionedEmployees] = useState<Employee[]>([]);
  const [employeesOnLeave, setEmployeesOnLeave] = useState([]);

  const allEmployeesQuery = useGetAllEmployeesQuery(null);
  const allTimeOffsQuery = useGetAllEmployeesOnTimeOffQuery(null);

  const { showPopup } = useContext(PopupLayerContext);

  useEffect(() => {
    if (allTimeOffsQuery?.data) {
      const result = allTimeOffsQuery?.data.map((item) => ({
        leaveId: item.leaveId,
        employeeId: item.employeeId,
      }));
      setEmployeesOnLeave(result);
    }
  }, [allTimeOffsQuery]);

  useEffect(() => {
    if (allEmployeesQuery.data && Array.isArray(allEmployeesQuery.data)) {
      const data = allEmployeesQuery.data
        .filter((emp) => emp?.isTaggable)
        .map((emp) => ({
          ...emp,
          display: `${emp.firstName} ${emp.lastName}`,
        }));

      setSuggestedEmployees(data);
    }
  }, [allEmployeesQuery.data, mentionedEmployees]);

  if (!activePatientId || !userRole || !userId) return null;

  const handleTextChange = (e: any) => {
    setPreviousText(noteText);
    setText(e.target.value);
  };

  const createTask = async (employee, noteId, onLeaveFlag) => {
    const result: any = await assignTask({
      message: "Review notes section",
      type: "reviewNotesSection",
      createdAt: new Date().toISOString(),
      patientId: activePatientId as string,
      employeeId: employee.id,
      documentId: noteId,
      onLeaveDelayed: onLeaveFlag,
      assignedTo: {
        role: employee.role,
        name: employee.display,
        id: employee.id,
      },
      assignedBy: {
        id: userId,
        role: userRole,
      },
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: `Unable to assign task to ${employee.display}`,
      });
    }
  };

  const assignTaskToStaff = async (mentionedList, noteId) => {
    mentionedList.forEach(async (employee) => {
      const onLeaveFlag = employeesOnLeave.some(
        (item: any) => item.employeeId === employee.id
      );
      console.log("onLeaveFlag", onLeaveFlag);
      await createTask(employee, noteId, onLeaveFlag);
    });
  };

  const submitNewNote = async () => {
    const finalData = mentionedEmployees.filter((emp) =>
      noteText.includes(emp.id)
    );
    const uuidPattern = /\([^)]+\)/g;
    const finalText = noteText.replace(uuidPattern, "");

    const result: any = await addPatientNote({
      patientId: activePatientId as string,
      creatorId: userId as string,
      text: finalText,
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to add a note to a patient",
      });
      return;
    }
    if (finalData.length && result) {
      assignTaskToStaff(finalData, result.data?.noteId);
    }
    setText("");
    showSuccess({
      title: "Success!",
      description: "You've successfully submitted a patient note",
    });
  };

  const renderUserSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused
  ) => `${entry.firstName} ${entry.lastName}`;

  function replaceLastMention(inputString: string, replacement: string) {
    // Find the last occurrence of "@"
    const lastAtIndex = inputString.lastIndexOf("@");

    if (lastAtIndex === -1) {
      // If no "@" is found, return the original string
      return inputString;
    }

    // Find the index of the next space after the "@" (or the end of the string if there's no space)
    const nextSpaceIndex = inputString.indexOf(" ", lastAtIndex);

    // If there is a space after the "@", replace the text from "@" to the space
    if (nextSpaceIndex !== -1) {
      return (
        inputString.slice(0, lastAtIndex) +
        replacement +
        inputString.slice(nextSpaceIndex)
      );
    }
    // If there's no space, replace from "@" to the end of the string
    return inputString.slice(0, lastAtIndex) + replacement;
  }

  const handleSubstituteReplace = (substituteId: string) => {
    console.log("subs id", substituteId);
    const substituteEmployee = allEmployeesQuery?.data.find(
      (emp) => emp.id === substituteId
    );
    if (!substituteEmployee || !substituteEmployee) {
      console.error("One of the employees could not be found.");
      return;
    }

    const checkEmployee = mentionedEmployees.find(
      (emp) => emp.id === substituteId
    );
    if (!checkEmployee) {
      setMentionedEmployees([...mentionedEmployees, substituteEmployee]);
    }

    const newTag = `@${substituteEmployee.firstName} ${substituteEmployee.lastName}(user:${substituteId})`;
    const newNoteText = replaceLastMention(noteText, newTag);
    setText(newNoteText);
  };

  const suggestionAdded = (id, display, startPos, endPos) => {
    const matchedEmployee = employeesOnLeave.find(
      (item) => item.employeeId === id
    );
    if (matchedEmployee) {
      showPopup(
        WarnSubstituteEmployeePopupGenerator({
          patientId: activePatientId,
          employee: matchedEmployee,
          onSubstituteReplace: handleSubstituteReplace,
        })
      );
    } else {
      const addedEmployee = suggestedEmployees.find((emp) => emp.id === id);
      const checkEmployee = mentionedEmployees.find((emp) => emp.id === id);

      if (!checkEmployee) {
        setMentionedEmployees([...mentionedEmployees, addedEmployee]);
      }
    }
  };

  return (
    <div className="patient-notes__new-note">
      <MentionsInput
        style={{
          minHeight: "50px",
          resize: "none !important",
        }}
        placeholder="Enter note"
        value={noteText}
        onChange={handleTextChange}
        className="mentions mentioned-user stop-overflow bg-same"
      >
        <Mention
          trigger="@"
          data={suggestedEmployees}
          renderSuggestion={renderUserSuggestion}
          markup="@__display__(user:__id__)"
          displayTransform={(id, display) => `@${display}`}
          onAdd={suggestionAdded}
        />
      </MentionsInput>
      <img
        src={addNoteIcon}
        alt="Send Note"
        onClick={noteText.trim() ? submitNewNote : null}
        className={`patient-notes__new-note__submit ${
          !noteText.trim() && "disabled"
        }`}
        style={{ cursor: !noteText.trim() ? "not-allowed" : "pointer" }}
      />
    </div>
  );
}

function PatientNotes() {
  const { activePatientId } = useSelector(getSessionState);
  const notesQuery = useGetPatientNotesQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const notesList = notesQuery.data?.map((note) => (
    <PatientNoteMessage
      note={note}
      key={`${note.timestamp}_${note.creatorId}`}
    />
  ));

  return (
    <div className="patient-notes">
      <div className="patient-notes__header">
        <p className="patient-notes__header__title">Notes</p>
      </div>
      <div className="patient-notes__content patient-notes__content__opened">
        {notesList || <LoadingBlock />}
      </div>
      <AddNoteInput />
    </div>
  );
}

export default PatientNotes;
