import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { AuthenticationService } from "../../../../cognito";

export const updateName = async (patientId: string, fullName: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.put(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/update-full-name`,
      { id: patientId, fullName },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError("Error occured. Cannot update patient's name");
    throw error;
  }
};
