import React from "react";
import "./style.scss";
import { getCurrentYear } from "../../../../utils/getCurrentYear";

export function Footer() {
  return (
    <>
      <div className="main-area__footer">
        <p className="paragraph-font paragraph-font--color footer-copyright">
          © {getCurrentYear()} AcneAway, Inc. All rights reserved.
        </p>
      </div>
      <div className="main-area__footer-placeholder" />
    </>
  );
}
