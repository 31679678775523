import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Footer } from "../../NewComponents/Common/Footer";
import PrintPatientInfoPage from "../../NewPages/PrintPatientInfo";

interface Props {
  setMenuVisibility: (isVisible: boolean) => void;
  isAuthorised: boolean;
  setParamsId: (id: any) => void;
}

function PrintPatientInfoComponent({
  setMenuVisibility,
  isAuthorised,
  setParamsId,
}: Props) {
  const params = useParams();
  const paramsId = params.userId;

  useEffect(() => {
    setParamsId(paramsId);
  }, [paramsId]);

  return isAuthorised ? (
    <>
      {/* <PrintPatientInfoPage setMenuVisibility={setMenuVisibility} /> */}
      <Footer />
    </>
  ) : (
    <Redirect to="/" />
  );
}

export default PrintPatientInfoComponent;
