import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Accutane } from "../../../../../types/Entities/Accutane";

interface Response {
  accutane: Accutane;
}

export const setRemNumber = async (
  userId: string,
  remsNumber: string
): Promise<Accutane> => {
  try {
    const result = await axios.post<Response>(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/set-rems-number/${userId}`,
      {
        remsNumber,
      }
    );

    return result.data.accutane;
  } catch (error) {
    logError("Error received when trying to set rem number");
    throw error;
  }
};
