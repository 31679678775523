import * as payment from "./entities/payment";
import * as careCordinators from "./entities/care-coordinators";
import * as providers from "./entities/providers";
import * as medicine from "./entities/medicine";
import * as treatmentPlans from "./entities/treatment-plans";
import * as images from "./entities/images";
import * as patients from "./entities/patients";
import * as chat from "./entities/chat";
import * as enrollmentCoordinators from "./entities/enrollment-coordinators";
import * as accutane from "./entities/accutane";
import * as unsupportedVisitors from "./entities/unsupported-visitors";

export const AcneAwayAPI = {
  payment,
  careCordinators,
  providers,
  medicine,
  treatmentPlans,
  images,
  patients,
  chat,
  enrollmentCoordinators,
  unsupportedVisitors,
  accutane,
};
