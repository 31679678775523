import React from "react";
import "./style.scss";

interface Props {
  list: {
    value: any;
    label: string;
    extra?: JSX.Element | string;
    key?: string;
    disabled?: boolean;
  }[];
  currentValue?: any;
  onChange: (value: any) => void;
  size?: "small" | "big";
  className?: string;
}

function renderElement(
  value: string | number | boolean,
  label: string,
  onClick: (value: string | number | boolean) => void,
  selected?: boolean,
  extra?: JSX.Element | string,
  key?: string,
  disabled?: boolean
) {
  const onClickHandler = () => {
    if (!disabled) {
      onClick(value);
    }
  };

  return (
    <div
      className={`radio-buttons-list__item ${selected ? "selected" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={onClickHandler}
      tabIndex={0}
      key={key}
    >
      <div className="radio-buttons-list__circle" />
      <p className="radio-buttons-list__text">{label}</p>
      {extra && <div>{extra}</div>}
    </div>
  );
}

export function RadioButtonsList({
  list,
  currentValue,
  onChange,
  className,
  size = "big",
}: Props) {
  return (
    <div
      className={`radio-buttons-list radio-buttons-list--${size} ${
        className || ""
      }`}
    >
      {list.map(({ value: _value, label, extra, key, disabled }) =>
        renderElement(
          _value,
          label,
          (newValue) => onChange(newValue),
          _value === currentValue,
          extra,
          key,
          disabled
        )
      )}
    </div>
  );
}
