export function surveyValueToDB(value: any): {
  type: string;
  value: string;
} {
  switch (typeof value) {
    case "string":
      return {
        type: "text",
        value,
      };
    case "number":
      return {
        type: "number",
        value: String(value),
      };
    case "boolean":
      return {
        type: "bool",
        value: String(value),
      };
    case "object":
      if (!(value instanceof Array)) {
        throw new Error(
          "Survey mapping error: received object that is not an array"
        );
      }
      if (!value.every((it) => typeof it === "string")) {
        throw new Error(
          "Survey mapping error: received array contains not only strings"
        );
      }
      return {
        type: "list",
        value: JSON.stringify(value),
      };
    default:
      throw new Error("Survey mapping error: unknown value type received");
  }
}
