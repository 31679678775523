import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AddIcon } from "../../../../Assets/NewIcons/add.svg";
import Table from "../../../Components/Common/Table/Table";
import OutsideClickHandler from "../../../Components/Common/OutsideClickHandler/OutsideClickHandler";
import { ColumnsConfig } from "../../../Components/Common/Table/types";
import "./style.scss";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { AccutaneDocument } from "../../../../services/acneaway-api/entities/accutane/get-documents";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { LoadingBlock } from "../../Common/LoadingBlock";
import { ProviderTask } from "../../../../types/Provider";
import { UploadDocumentsPopupGenerator } from "../../Provider/UploadDocuments";
import DocumentOverviewPopupGenerator from "../../Provider/DocumentOverviewPopup";
import { AssignTaskModalPopupGenerator } from "../../Provider/AssignTaskModalPopup";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { downloadDocument } from "../../../../utils/download-document";
import SendBloodSlipPopup from "../../Common/SendBloodSlipPopup";
import { getProviderById } from "../../../../features/providers";
import {
  useDeleteDocumentMutation,
  useGetDocumentsByPatientIdQuery,
  useGetTasksQuery,
  useResetIpledgeConsentMutation,
} from "../../../../features/api/accutane";
import {
  useGetCareTeamQuery,
  useGetPatientByIdQuery,
} from "../../../../features/api/patients";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { USER_ROLES } from "../../../../types/Main";
import { Provider } from "../../../../types/Employee";
import { ActionPopupGenerator } from "../../Common/ActionPopup";

const ITEMS_PER_PAGE = 8;

const dateFormat = "MM/DD/YY";

function CommentRenderer(
  text: string | number | undefined,
  record: AccutaneDocument
) {
  const hasComment = !!text;
  const { signedBy } = record;

  return (
    <div className="comment-column">
      <p className="comment-column__comment">
        {hasComment ? text : "No comment"}
      </p>
      <p className="comment-column__signed">
        {signedBy?.name
          ? `Signed by ${signedBy.name} on ${getMomentDate(
              signedBy.createdAt
            ).format(dateFormat)}`
          : ""}
      </p>
    </div>
  );
}

function AddAction({ actions }: { actions: any[] }) {
  const [popoverActive, setActive] = useState(false);

  const setPopoverActive = () => {
    setActive(true);
  };

  const setPopoverInactive = () => {
    setActive(false);
  };

  return (
    <OutsideClickHandler onClickOutside={setPopoverInactive}>
      <div className="patient-documents-header__wrapper-extra__actions">
        <AddIcon onClick={setPopoverActive} />
        {popoverActive && (
          <div className="patient-documents-header__wrapper-extra__actions__list">
            {actions.map((it) => (
              <div className="patient-documents-header__wrapper-extra__actions__list__item">
                {it}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

function ActionButton({ children }: { children: JSX.Element[] }) {
  const ref = useRef<any>(null);
  const [actionActivity, setActive] = useState({
    dotsActive: false,
    popupActive: false,
  });

  const handleOnClick = () => {
    setActive({
      popupActive: !actionActivity.popupActive,
      dotsActive: !actionActivity.dotsActive,
    });
  };

  const onClickOutside = () => {
    setActive({ dotsActive: false, popupActive: false });
  };

  const dotClassName = actionActivity.dotsActive
    ? "table-body-row-action-button-dot__active"
    : "table-body-row-action-button-dot";
  const buttonClassName = actionActivity.popupActive
    ? "table-body-row-action-button__active"
    : "table-body-row-action-button";

  return (
    <OutsideClickHandler onClickOutside={onClickOutside}>
      <button
        className={buttonClassName}
        onClick={handleOnClick}
        type="button"
        ref={ref}
      >
        <div className={dotClassName} />
        <div className={dotClassName} />
        <div className={dotClassName} />
        {actionActivity.popupActive && (
          <div className="table-body-row-action-button__popup">{children}</div>
        )}
      </button>
    </OutsideClickHandler>
  );
}

const getTableData = (documentsList: AccutaneDocument[]) =>
  documentsList
    .map((document) => ({
      id: document.id,
      key: document.compositeKey,
      title: document.title,
      assignedBy: document.assignedBy,
      assignedTo: document.assignedTo,
      date: getMomentDate(document.createdAt).format("MM/DD/YY"),
      uploadedBy: document.uploadedBy?.name ?? "N/A",
      comment: document.comment ?? "No comments",
      url: document.url,
      signedBy: document.signedBy,
    }))
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

function DocumentsTable({
  columns,
  documentsList,
  isLoading,
}: {
  columns: any[];
  documentsList: AccutaneDocument[];
  isLoading: boolean;
}) {
  const [currentPage, setPage] = useState(0);

  const onPageChange = (current: number) => setPage(current);

  const pagination = {
    maxPage: Math.ceil(documentsList.length / ITEMS_PER_PAGE),
    currentPage,
    onPageChange,
  };

  const tableData = getTableData(documentsList);
  const dataToRender = tableData.slice(
    currentPage * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );

  return isLoading ? (
    <LoadingBlock />
  ) : (
    <Table
      pagination={pagination}
      data={dataToRender}
      columns={columns}
      className="patient-documents__table"
    />
  );
}

function AdministratorDocuments({
  userId,
  employeeId,
  viewOnly = false,
}: {
  userId: string;
  employeeId: string;
  viewOnly: boolean;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [deleteDocument] = useDeleteDocumentMutation();
  const patientQuery = useGetPatientByIdQuery(userId);
  const careTeamQuery = useGetCareTeamQuery(userId);
  const providerQuery = useGetEmployeeQuery(
    careTeamQuery.data?.find(({ role }) => role === USER_ROLES.PROVIDER)
      ?.employeeId as string,
    {
      skip: !careTeamQuery.data?.find(
        ({ role }) => role === USER_ROLES.PROVIDER
      )?.employeeId,
      refetchOnMountOrArgChange: true,
    }
  );
  const tasksQuery = useGetTasksQuery({
    patientId: userId,
  });
  const documentsQuery = useGetDocumentsByPatientIdQuery(userId);
  const [resetIpledgeConsent] = useResetIpledgeConsentMutation();

  const handleResetIpledgeConsentClick = () => {
    showPopup(
      ActionPopupGenerator({
        title: "Reset patient's iPledge Consent?",
        text: `Are you sure you want to reset iPledge Consent for ${
          patientQuery.data?.fullName || "patient"
        }? Both patient and provider would need to sign it again. This action cannot be undone.`,
        action: async () => {
          const response: any = await resetIpledgeConsent({
            patientId: userId,
          });
          if (response.error) {
            showError({
              title: "Something went wrong...",
              description: `Unable to reset iPledge consent for some reason`,
            });
            throw new Error(`Unable to reset iPledge consent`);
          }

          showSuccess({
            title: "Success!",
            description: `The iPledge consent has ben successfully reset`,
          });
        },
        actionText: "Reset",
      })
    );
  };

  const handleAddClick = () => {
    showPopup(UploadDocumentsPopupGenerator(userId));
  };

  const handleCreateFormClick = () => {
    if (patientQuery.data && providerQuery.data) {
      showPopup(
        SendBloodSlipPopup({
          patient: patientQuery.data,
          provider: providerQuery.data as Provider,
        })
      );
    }
  };

  const onRowClick = (rowData: any) => {
    showPopup(
      DocumentOverviewPopupGenerator(
        rowData?.title ?? "N/A",
        userId,
        rowData.id,
        rowData.url,
        employeeId
      )
    );
  };

  const hasNewTasks = (id: string) =>
    tasksQuery.data?.some(
      (task: ProviderTask) =>
        task.compositeKey.includes(id) &&
        task.status !== "COMPLETED" &&
        task.status !== "DELETED"
    );

  const documentsActionsColumn = (_: any, record: any) => {
    const { title, id, url } = record;

    const onTaskAssign = () => {
      showPopup(
        AssignTaskModalPopupGenerator(
          title,
          patientQuery.data?.fullName ?? "",
          id,
          userId,
          employeeId
        )
      );
    };

    const onDeleteDocument = async () => {
      if (userId) {
        const result: any = await deleteDocument({
          patientId: userId,
          documentId: id,
        });
        if (result.error) {
          showError({
            title: "Something went wrong...",
            description: "Unable to delete document",
          });
          return;
        }
        showSuccess({
          title: "Success",
          description: "Document successfully deleted",
        });
      }
    };

    return (
      <ActionButton>
        <p
          className="table-body-row-action-button__popup-options"
          onClick={downloadDocument(url, title)}
        >
          Download document
        </p>
        <p
          className="table-body-row-action-button__popup-options"
          onClick={onTaskAssign}
        >
          Assign a task
        </p>
        <p
          className="table-body-row-action-button__popup-options"
          onClick={onDeleteDocument}
        >
          Delete document
        </p>
      </ActionButton>
    );
  };

  const columns: ColumnsConfig[] = [
    {
      header: "TITLE",
      dataIndex: "title",
      key: "title",
      onClick: onRowClick,
      render: (text, record) => (
        <div>
          <p>
            {record.title}{" "}
            {hasNewTasks(record.id) && (
              <span className="table-body-row__cell-green-dot" />
            )}
          </p>
          <p>{record.date}</p>
        </div>
      ),
    },
    {
      header: "UPLOADED BY",
      dataIndex: "uploadedBy",
      key: "age",
      onClick: onRowClick,
    },
    {
      header: "COMMENT",
      dataIndex: "comment",
      key: "address",
      width: "496px",
      onClick: onRowClick,
      render: CommentRenderer,
    },
    {
      header: " ",
      dataIndex: "actions",
      key: "actions",
      render: documentsActionsColumn,
    },
  ];

  const actions = [
    <p onClick={handleAddClick}>Upload document</p>,
    <p onClick={handleCreateFormClick}>Create a form</p>,
    <p onClick={handleResetIpledgeConsentClick}>Reset iPledge consent</p>,
  ];

  return (
    <div className="patient-documents">
      <div className="patient-documents-header__wrapper">
        <p className="patient-documents-header__wrapper-title">Documents</p>
        {!viewOnly && (
          <div className="patient-documents-header__wrapper-extra">
            <AddAction actions={actions} />
          </div>
        )}
      </div>
      <DocumentsTable
        documentsList={documentsQuery.data || []}
        columns={columns}
        isLoading={!documentsQuery.data}
      />
    </div>
  );
}

export default AdministratorDocuments;
