export const acneSubQuestions = [
  {
    label:
      "Have there been any changes to your medical history since your last visit?",
    value: "isMedicalHistoryChanges",
    questionType: "acne",
    hasChild: true,
    childId: "isMedicalHistoryChanges",
    radioButtonsForm: true,
    placeholder: "Please elaborate",
    description:
      "(e.g. new medications, medical conditions, surgeries or procedures)",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Please elaborate",
  //   value: "medicalHistoryChanges",
  //   questionType: "acne",
  //   parentQuestion: "isMedicalHistoryChanges",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "medicalHistoryChanges",
  //       label: "Please elaborate",
  //     },
  //   ],
  // },
  {
    label:
      "Please let your provider know how you've been using your recommended treatments",
    value: "treatmentSurvey",
    isRecommendedTreatment: true,
    questionType: "acne",
    options: [
      {
        value: "NORMAL",
        label: "I use it exactly as directed",
      },
      {
        value: "MODIFIED",
        label: "I modified the amount or the frequency of use",
      },
      {
        value: "STOPPED",
        label: "I stopped using this",
      },
    ],
    isConditional: true,
  },
  {
    label:
      "Are you experiencing any dryness, redness, or itching from your creams?",
    value: "isIssuesWithCreams",
    questionType: "acne",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
    isConditional: true,
  },
  {
    label:
      "Please elaborate, and specify which prescriptions are causing irritation if you know.",
    value: "issuesWithCreams",
    questionType: "acne",
    parentQuestion: "isIssuesWithCreams",
    parentAnswer: true,
    textArea: true,
    options: [
      {
        value: "issuesWithCreams",
        label:
          "Please elaborate, and specify which prescriptions are causing irritation if you know.",
      },
    ],
  },
  {
    label:
      "If you are on oral medications, do you have any body rash, stomach irritation, or headaches/light headedness?",
    value: "isOralMedicationInUse",
    questionType: "acne",
    options: [
      {
        value: "Yes",
        label: "Yes",
      },
      {
        value: "no",
        label: "No",
      },
      {
        value: "I am not on oral medication",
        label: "I am not on oral medication",
      },
    ],
    isConditional: true,
  },
  {
    label: "Do you need a refill of any products?",
    value: "isRefillRequired",
    questionType: "acne",
    hasChild: true,
    childId: "isRefillRequired",
    radioButtonsForm: true,
    placeholder: "Which products would you like to refill?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Which products would you like to refill?",
  //   value: "refillProducts",
  //   questionType: "acne",
  //   parentQuestion: "isRefillRequired",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "refillProducts",
  //       label: "Which products would you like to refill?",
  //     },
  //   ],
  // },
  {
    label:
      "Are you taking any of the following medications? Please check all that apply.",
    value: "accutaneContraindications",
    questionType: "acne",
    isCheckboxesList: true,
    checkboxesList: true,
    onlySelectable: true,
    isAccutane: true,
    description:
      "These medications are contraindications and will need to be stopped and cannot be taken during treatment.",
    options: [
      {
        value: "Vitamin A supplements",
        label: "Vitamin A supplements",
      },
      {
        value: "Tetracycline antibiotics",
        label: "Tetracycline antibiotics",
      },
      {
        value: "Progestin only birth control (mini pill)",
        label: "Progestin only birth control (mini pill)",
      },
      {
        value: "Dilantin (phenytoin)",
        label: "Dilantin (phenytoin)",
      },
      {
        value: "Oral steroids (such as Prednisone)",
        label: "Oral steroids (such as Prednisone)",
      },
      {
        value: "St. John's Wort",
        label: "St. John's Wort",
      },
      {
        value: "I am not taking any of these",
        label: "I am not taking any of these",
        onlySelectable: true,
      },
    ],
  },
  {
    label: 'Would you still describe your stress level as "part from survey"?',
    value: "stressLevel",
    questionType: "lifestyle",
    options: [
      {
        value: "Never stressed",
        label: "Never stressed",
      },
      {
        value: "Almost never stressed",
        label: "Almost never stressed",
      },
      {
        value: "Sometimes stressed",
        label: "Sometimes stressed",
      },
      {
        value: "Fairly stressed",
        label: "Fairly stressed",
      },
      {
        value: "Very stressed",
        label: "Very stressed",
      },
    ],
  },
  {
    label: "Are you still sleeping part from survey each night?",
    value: "sleepAmount",
    questionType: "lifestyle",
    options: [
      {
        value: "5 hours or less",
        label: "5 hours or less",
      },
      {
        value: "Between 6-7 hours",
        label: "Between 6-7 hours",
      },
      {
        value: "8+ hours",
        label: "8+ hours",
      },
    ],
  },
  {
    label:
      'Does this still describe your dairy intake accurately: "part from survey"?',
    value: "dairyConsumption",
    questionType: "lifestyle",
    options: [
      {
        value: "Never",
        label: "Never",
      },
      {
        value: "A few times a month",
        label: "A few times a month",
      },
      {
        value: "A few times a week",
        label: "A few times a week",
      },
      {
        value: "A few times a day",
        label: "A few times a day",
      },
    ],
  },
  {
    label: "How do you feel about your progress?",
    value: "progressFeelings",
    questionType: "acne",
    isTextArea: true,
    textArea: true,
    options: [
      {
        value: "progressFeelings",
        label: "How do you feel about your progress?",
      },
    ],
    isConditional: true,
  },
  {
    label: "Do you have any specific questions or comments for your provider?",
    value: "additionalQuestions",
    questionType: "acne",
    textArea: true,
    options: [
      {
        value: "additionalQuestions",
        label:
          "Do you have any specific questions or comments for your provider?",
      },
    ],
  },
  {
    label:
      "Over the past 2 weeks, how often have you been bothered by any of the following problems?",
    value: "phqAnswer1",
    isPhqQuestion: true,
    questionType: "acne",
    isAccutane: true,
    options: [
      {
        value: 0,
        label: "Not at all",
      },
      {
        value: 1,
        label: "Several days",
      },
      {
        value: 2,
        label: "More than half the days",
      },
      {
        value: 3,
        label: "Nearly every day",
      },
    ],
  },
];
