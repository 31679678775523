import React, { useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { Button } from "../../../../NewComponents/Common/Button";
import { TextArea } from "../../../../NewComponents/Common/TextArea";

function surveyToComponent(data: SurveyData) {
  return data.progressFeelings;
}

function componentToSurvey(value: string) {
  return {
    progressFeelings: value,
  };
}

export function ProgressFeelingsSurveyQuestion({
  data,
  onDone,
}: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));

  function submit() {
    if (isEmpty(currentData)) return;

    onDone(componentToSurvey(currentData));
  }
  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        How do you feel about your progress?
      </p>
      <p className="survey-question__descr paragraph-font--color" />
      <TextArea
        onChange={(value) => setData(value)}
        maxRows={5}
        minRows={3}
        value={currentData}
      />
      <Button
        text="continue"
        onClick={submit}
        className="survey-question__button"
        disabled={/^\s*$/.test(currentData) || isEmpty(currentData)}
      />
    </div>
  );
}
