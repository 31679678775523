import React from "react";
import { Step, Steps } from "../../Common/Steps";
import "./style.scss";
import { Accutane } from "../../../../types/Entities/Accutane";
import { getMomentDate } from "../../../../utils/get-date-pretty";

const dateFormat = "MM/DD/YY";

const formatDate = (dateString: string): string => {
  if (dateString === "--" || dateString === "N/A") {
    return "--";
  }
  if (dateString && dateString !== "N/A") {
    return getMomentDate(dateString).format(dateFormat);
  }
  return "N/A";
};

function ImportantIpledgeDates({ accutane }: { accutane: Accutane }) {
  const hasEnrollmentDate = !!accutane?.enrollmentDate;
  const hasLastConfirmationDate =
    !!accutane?.lastConfirmationDate &&
    accutane.lastConfirmationDate !== "--" &&
    accutane.lastConfirmationDate !== "N/A";
  const hasNextConfirmationDate = !!accutane?.nextConfirmationDate;

  return (
    <div className="ipledge-dates-card">
      <p className="subheading-font subheading-font--margin subheading-font--color">
        Important iPledge Dates
      </p>
      <div className="ipledge-dates-card__info">
        <Steps
          direction="vertical"
          view="left"
          iconType="marks"
          className="ipledge-dates-card__info__steps"
          size="small"
        >
          <Step title="Enrolled" isActive={hasEnrollmentDate}>
            <p className="step-description">
              {formatDate(accutane?.enrollmentDate ?? "--")}
            </p>
          </Step>
          <Step title="Last confirmation" isActive={hasLastConfirmationDate}>
            <p className="step-description">
              {formatDate(accutane?.lastConfirmationDate ?? "--")}
            </p>
          </Step>
          <Step title="Next confirmation" isActive={hasNextConfirmationDate}>
            <p className="step-description">
              {formatDate(accutane?.nextConfirmationDate ?? "--")}
            </p>
          </Step>
        </Steps>
      </div>
    </div>
  );
}

export default ImportantIpledgeDates;
