import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export const addDocumentComment = async (
  documentId: string,
  comment: string,
  patientId: string,
  signedBy: { id: string; name: string; role: string; createdAt: string }
): Promise<any> => {
  try {
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/add-document-comment/${documentId}`,
      {
        comment,
        patientId,
        signedBy,
      }
    );

    return result.data.accutaneDocument;
  } catch (error) {
    logError("Error received when trying to add comment");
    throw error;
  }
};
