import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { HelpPopupTemplate } from "../../../NewComponents/Common/PopupLayer/help";
import { Accutane, Gender } from "../../../../types/Entities/Accutane";
import { Button } from "../../../NewComponents/Common/Button";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import {
  setAccutaneInfo,
  setProviderTasks,
} from "../../../../features/providers";
import "./style.scss";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { setLoading } from "../../../../features/patientView";
import { Patient } from "../../../../types/Entities/Patient";
import { Provider } from "../../../../types/Employee";
import { useUpdateTaskStatusMutation } from "../../../../features/api/accutane";

interface PopupProps {
  onClose: () => void;
}

interface RemovePatientTaskPopup extends Accutane {
  taskId: string;
  userId: string;
  deletedBy: string;
  patient?: Patient;
  provider?: Provider;
  inviteId?: string | undefined;
  role?: string;
  compositeKey?: string;
  taskName?: string;
}

function RemoveTaskPopup({
  patientId,
  onClose,
  taskId,
  userId,
  deletedBy,
  compositeKey,
  taskName,
}: {
  patientId: string;
  onClose: () => void;
  taskId: string;
  userId: string;
  deletedBy: string;
  compositeKey: string;
  taskName: string;
}) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [updateTaskStatus] = useUpdateTaskStatusMutation();

  const updatedBy = { id: deletedBy, role: "Administrator" };

  const onSubmit = async () => {
    const result: any = await updateTaskStatus({
      taskId,
      compositeKey,
      updatedBy,
      patientId,
      status: "DELETED",
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to complete task",
      });
      return;
    }
    showSuccess({
      title: "Success!",
      description: "Task removed successfully.",
    });
    onClose();
  };

  console.log("In accutane remove task");

  return (
    <div className="enroll-ipledge-popup">
      <p className="enroll-ipledge-popup__title">Cancel task: {taskName}</p>
      <div className="enroll-ipledge-popup__content">
        <p className="enroll-ipledge-popup__content-text">
          Note: This will remove the task from the assignees dashboard.
          <br />
          <br />
          This action cannot be reversed.
        </p>
      </div>
      <div className="enroll-ipledge-popup__control-buttons">
        <Button text="Cancel" onClick={onClose} size="small" view="secondary" />
        <Button text="Confirm" onClick={onSubmit} size="small" />
      </div>
    </div>
  );
}

function RemoveTaskPopupGenerator(accutane: RemovePatientTaskPopup) {
  return function render({ onClose }: PopupProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <RemoveTaskPopup
          patientId={accutane?.userId}
          taskId={accutane.taskId}
          onClose={onClose}
          userId={accutane.userId}
          deletedBy={accutane.employeeId}
          compositeKey={accutane.compositeKey}
          taskName={accutane?.taskName}
        />
      </HelpPopupTemplate>
    );
  };
}

export default RemoveTaskPopupGenerator;
