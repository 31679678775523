import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { EMPLOYEE_TYPE } from "../../../../../types/Employee";
import { Patient } from "../../../../../types/Entities/Patient";
import { AuthenticationService } from "../../../../cognito";

export const assignEmployee = async (
  patientId: string,
  employeeId: string,
  employeeType: EMPLOYEE_TYPE
) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/assign-employee`,
      {
        patientId,
        employeeId,
        employeeType,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result.data;
  } catch (error) {
    logError("Error occured. Cannot archive patient");
    throw error;
  }
};
