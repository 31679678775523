import React from "react";
import { HelpPopupTemplate } from "../../../NewComponents/Common/PopupLayer/help";
import { Button } from "../../../NewComponents/Common/Button";
import "./style.scss";

interface Props {
  onClose: () => void;
}

interface OnCloseModalPopupProps {
  onClose: () => void;
  onConfirm: any;
  modalText: string;
  modalHeaderText: string;
}

function OnCloseModalPopup({
  onClose,
  onConfirm,
  modalText,
  modalHeaderText,
}: OnCloseModalPopupProps) {
  const onConfirmHandler = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <div className="close-modal">
      <p className="close-modal__modal-header">{modalHeaderText}</p>
      <p className="close-modal__modal-text">{modalText}</p>
      <div className="close-modal__control-buttons">
        <Button text="Stay" onClick={onClose} size="small" />
        <Button text="Close" onClick={onConfirmHandler} size="small" />
      </div>
    </div>
  );
}

function OnCloseModalPopupGenerator(
  onConfirm: any,
  modalText: string,
  modalHeaderText: string
) {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <OnCloseModalPopup
          onClose={onClose}
          onConfirm={onConfirm}
          modalText={modalText}
          modalHeaderText={modalHeaderText}
        />
      </HelpPopupTemplate>
    );
  };
}

export default OnCloseModalPopupGenerator;
