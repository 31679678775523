import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";

export interface SignIpldedgeConsentResponse {
  uniqueLink: {
    data: {
      link: string;
    };
  };
  uniqueParentLink?: {
    data: {
      link: string;
    };
  };
}

export const signIpledgeConsent = async (
  userId: string,
  role: string,
  documentGroupName: string,
  setLoadingText: (text: string) => void
): Promise<SignIpldedgeConsentResponse> => {
  try {
    setLoadingText("Generating documents for you. Please wait.");
    const document = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/document-group-from-templates/${userId}`,
      {
        documentGroupName,
      }
    );
    setLoadingText("Generating unique embedded link for signing. Please wait.");
    const result = await axios.post(
      `${ENV_CONFIG.ACCUTANE_API_PATH}/ipledge-embedded-link/${userId}`,
      {
        documentGroupId: document.data.documentGroupId,
        role,
      }
    );

    return result.data;
  } catch (error) {
    logError("Error received when trying to set rem number");
    throw error;
  }
};
