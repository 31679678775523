import { createSlice } from "@reduxjs/toolkit";
import { CareCoordinator } from "../types/Entities/CareCoordinator";
import { ReduxAction, ReduxStore } from "../types/Stores";
import { CareCoordinatorsStore } from "../types/Stores/CareCoordinatorsStore";
import { SortingPayload } from "../types/Table/SortingPayload";
import { Accutane } from "../types/Entities/Accutane";
import { ProviderTask } from "../types/Provider";

const initialState: CareCoordinatorsStore = {
  loadingStatus: "idle",
  wasInitialized: false,
  list: [],
  sort: null,
  accutane: null,
  tasks: [],
  documentTasks: [],
};

export const careCoordinatorsSlice = createSlice({
  name: "careCoordinators",
  initialState,
  reducers: {
    setCareCoordinators(_state, { payload }: ReduxAction<CareCoordinator[]>) {
      const state = _state;
      state.list = payload;
      state.wasInitialized = true;
    },
    clearCareCoordinatorsStore(_state) {
      const state = _state;
      state.list = [];
      state.wasInitialized = false;
      state.accutane = null;
      state.tasks = [];
      state.documentTasks = [];
    },
    setSorting(_state, { payload }: ReduxAction<SortingPayload | null>) {
      const state = _state;
      state.sort = payload;
    },
    addCareCoordinator(_state, { payload }: ReduxAction<CareCoordinator>) {
      const state = _state;
      state.list.push(payload);
      state.list = Array.from(state.list);
    },
    deleteCareCoordinator(_state, { payload }: ReduxAction<string>) {
      const state = _state;
      const index = state.list.findIndex(({ id }) => id === payload);
      state.list.splice(index, 1);
      state.list = Array.from(state.list);
    },
    updateCareCoordinator(_state, { payload }: ReduxAction<CareCoordinator>) {
      const state = _state;
      const index = state.list.findIndex(({ id }) => id === payload.id);
      state.list[index] = {
        ...payload,
      };
      state.list = Array.from(state.list);
    },
    setCareCoordinatorAccutaneInfo(state, { payload }: ReduxAction<Accutane>) {
      state.accutane = payload;
    },
    setCareCoordinatorTasks(state, { payload }: ReduxAction<ProviderTask[]>) {
      state.tasks = payload;
    },
    setDocumentTasks(state, { payload }: ReduxAction<ProviderTask[]>) {
      state.documentTasks = payload;
    },
    clearCareCoordinatorAccutaneInfo(state) {
      state.accutane = null;
    },
  },
});

export const {
  setCareCoordinators,
  clearCareCoordinatorsStore,
  setSorting,
  addCareCoordinator,
  deleteCareCoordinator,
  updateCareCoordinator,
  setCareCoordinatorAccutaneInfo,
  setCareCoordinatorTasks,
  setDocumentTasks,
  clearCareCoordinatorAccutaneInfo,
} = careCoordinatorsSlice.actions;

export const getCareCoordinatorsInfoState = ({
  careCoordinatorsStore,
}: ReduxStore) => careCoordinatorsStore;

export const getCareCoordinatorsList = ({
  careCoordinatorsStore,
}: ReduxStore) => careCoordinatorsStore?.list ?? [];

export const getCareCoordinatorByCoordinatorId =
  (coordinatorId: string | undefined | null) =>
  ({ careCoordinatorsStore }: ReduxStore) =>
    careCoordinatorsStore.list.find(({ id }) => id === coordinatorId);

export const getAccutaneOfPatient = ({ careCoordinatorsStore }: ReduxStore) =>
  careCoordinatorsStore.accutane;

export const getProviderById =
  (coordinatorId: string | undefined | null) =>
  ({ careCoordinatorsStore }: ReduxStore) =>
    careCoordinatorsStore.list.find(({ id }) => id === coordinatorId);

export const getCareCoordinatorsTasks = ({
  careCoordinatorsStore,
}: ReduxStore) => careCoordinatorsStore.tasks;

export const getDocumentTasks = ({ careCoordinatorsStore }: ReduxStore) =>
  careCoordinatorsStore.documentTasks;

export default careCoordinatorsSlice.reducer;
