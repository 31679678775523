import React from "react";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";

const RADIUS = 1;

interface Props {
  max: number;
  current: number;
  onChange: (value: number) => void;
}

function renderEmphasis() {
  return <div className="patients-table__pagination-emphasis">...</div>;
}

function renderButton(
  number: number,
  onClick: (state: number) => void,
  isSelected?: boolean
) {
  return (
    <div
      className={`patients-table__pagination-button patients-table__pagination-button--page ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => (!isSelected ? onClick(number) : null)}
    >
      {number + 1 < 10 ? `0${number + 1}` : number + 1}
    </div>
  );
}

function renderPages(
  current: number,
  max: number,
  onClick: (state: number) => void
) {
  const items = [];

  let leftEmphasisSet = false;
  let rightEmphasisSet = false;

  for (let i = 0; i < max; i++) {
    if (i < current) {
      if (i < current - RADIUS && i !== 0) {
        if (!leftEmphasisSet) items.push(renderEmphasis());
        leftEmphasisSet = true;
      } else {
        items.push(renderButton(i, onClick));
      }
    } else if (i > current) {
      if (i > current + RADIUS && i !== max - 1) {
        if (!rightEmphasisSet) items.push(renderEmphasis());
        rightEmphasisSet = true;
      } else {
        items.push(renderButton(i, onClick));
      }
    } else {
      items.push(renderButton(current, onClick, true));
    }
  }

  return items;
}

export function PatientsTablePagination({ max, current, onChange }: Props) {
  return (
    <div className="patients-table__pagination">
      <div
        className="patients-table__pagination-button patients-table__pagination-button--prev"
        onClick={() => {
          if (current > 0) onChange(current - 1);
        }}
      >
        <ChevronIcon />
      </div>
      {renderPages(current, max, onChange)}
      <div
        className="patients-table__pagination-button patients-table__pagination-button--next"
        onClick={() => {
          if (current < max - 1) onChange(current + 1);
        }}
      >
        <ChevronIcon />
      </div>
    </div>
  );
}
