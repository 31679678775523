import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { EnrollmentCoordinator } from "../../../../../types/Entities/EnrollmentCoordinator";
import { AuthenticationService } from "../../../../cognito";

export const getAll = async () => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<EnrollmentCoordinator[]>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/enrollment-coordinators/all`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get enrollment coordinators list", error);
    throw error;
  }
};
