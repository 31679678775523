import React from "react";
import { ReactComponent as SunIcon } from "../../../../Assets/NewIcons/sun.svg";
import { ReactComponent as MoonIcon } from "../../../../Assets/NewIcons/moon.svg";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import "./style.scss";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { Skeleton } from "../../Common/Skeleton";
import { Medication } from "../../../../types/Medication";
import { TreatmentPlanMedication } from "../../../../types/FollowUp";

interface Props {
  medications: TreatmentPlanMedication[];
  highlight?: boolean;
  expand?: boolean;
}

const highlghtedMedicine = [
  "benzoylPeroxide",
  "moisturizer",
  "b20e4ba8-e850-4636-aa01-76f24e5223ac",
];

function renderDropdown(tags: string[]) {
  return (
    <div className="treatment-plan-items-list__dropdown">
      {tags.map((tag) => {
        switch (tag) {
          case "morning":
            return <SunIcon />;
          case "evening":
            return <MoonIcon />;
          default:
            return null;
        }
      })}
      <ChevronIcon className="treatment-plan-items-list__chevron" />
    </div>
  );
}

function renderTags(tags: string[]) {
  return tags.map((tag) => {
    switch (tag) {
      case "morning":
        return (
          <div className="treatment-plan-items-list__tag treatment-plan-items-list__tag--morning">
            <SunIcon />
            <p className="treatment-plan-items-list__tag-text">Morning</p>
          </div>
        );
      case "evening":
        return (
          <div className="treatment-plan-items-list__tag treatment-plan-items-list__tag--evening">
            <MoonIcon />
            <p className="treatment-plan-items-list__tag-text">Night</p>
          </div>
        );
      default:
        return null;
    }
  });
}

export function TreatmentPlanItemsList({
  medications,
  highlight,
  expand,
}: Props) {
  const medicationsQuery = useGetAllMedicationsQuery(null);
  return (
    <ul className="treatment-plan-items-list">
      {medications.map(({ medicineKey, specialInstructions, instructions }) => {
        if (!medicationsQuery.data) {
          return (
            <li className="treatment-plan-items-list__list-item">
              <Skeleton fullWidth />
            </li>
          );
        }

        const medicine = medicationsQuery.data.find(
          ({ id }) => id === medicineKey
        );
        if (!medicine) return null;
        const name = `${medicine.name}${
          medicine.strength ? ` ${medicine.strength}` : ""
        }${medicine.size ? `, ${medicine.size}` : ""}`;

        return (
          <li
            className={`treatment-plan-items-list__list-item ${
              highlight && highlghtedMedicine.includes(medicineKey)
                ? "highlighted"
                : ""
            }${expand ? " open" : ""}`}
            onClick={(e) => e.currentTarget.classList.toggle("open")}
          >
            <div className="treatment-plan-items-list__item-image" />
            <div className="treatment-plan-items-list__item-title">
              <p className="treatment-plan-items-list__item-title--main">
                {name}
              </p>
              <div className="treatment-plan-items-list__highlight">
                <CheckIcon />
              </div>
            </div>
            {renderDropdown(instructions)}
            <div className="treatment-plan-items-list__item-descr">
              <p className="paragraph-font">{specialInstructions}</p>
            </div>
            <div className="treatment-plan-items-list__item-tags">
              {renderTags(instructions)}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
