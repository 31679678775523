export const eczemaSubQuestions = [
  {
    label:
      "Have there been any changes to your medical history since your last visit?",
    value: "isMedicalHistoryChanges",
    questionType: "eczema",
    hasChild: true,
    childId: "isMedicalHistoryChanges",
    radioButtonsForm: true,
    placeholder: "Please elaborate",
    description:
      "(e.g. new medications, medical conditions, new allergies,  surgeries or procedures)",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Please elaborate",
  //   value: "medicalHistoryChanges",
  //   questionType: "eczema",
  //   parentQuestion: "isMedicalHistoryChanges",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "medicalHistoryChanges",
  //       label: "Please elaborate",
  //     },
  //   ],
  // },
  {
    label:
      "Please let your provider know how you've been using your recommended treatments",
    value: "treatmentSurvey",
    isRecommendedTreatment: true,
    questionType: "eczema",
    options: [
      {
        value: "NORMAL",
        label: "I use it exactly as directed",
      },
      {
        value: "MODIFIED",
        label: "I modified the amount or the frequency of use",
      },
      {
        value: "STOPPED",
        label: "I stopped using this",
      },
    ],
  },
  {
    label:
      "If you are on oral or injectable medications, do you have any eye dryness or eye irritation, body rash, stomach irritation?",
    value: "sideEffects",
    questionType: "eczema",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
      {
        value: "I am not on oral or injectable medication",
        label: "I am not on oral or injectable medication",
      },
    ],
  },
  {
    label: "Do you need a refill of any products?",
    value: "isRefillRequired",
    questionType: "acne",
    hasChild: true,
    childId: "isRefillRequired",
    radioButtonsForm: true,
    placeholder: "Which products would you like to refill?",
    options: [
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ],
  },
  // {
  //   label: "Which products would you like to refill?",
  //   value: "refillProducts",
  //   questionType: "eczema",
  //   parentQuestion: "isRefillRequired",
  //   parentAnswer: true,
  //   textArea: true,
  //   options: [
  //     {
  //       value: "refillProducts",
  //       label: "Which products would you like to refill?",
  //     },
  //   ],
  // },
  {
    label: "How would you describe the amount of eczema?",
    description:
      '1-10, with 1 being “totally clear” and 10 being “most of my body is covered with eczema"',
    value: "eczemaDuration",
    questionType: "eczema",
    isInputTypeNumber: true,
    maxNumber: 10,
    options: [],
  },
  {
    label: "How would you describe your level of itch",
    description:
      '1-10, with 1 being “not at all” and 10 being “itching and scratching all day and can’t sleep at night because of it"',
    value: "itchDuration",
    questionType: "eczema",
    isInputRatingType: true,
    maxNumber: 10,
    options: [],
  },
  {
    label: 'Would you still describe your stress level as "part from survey"?',
    value: "stressLevel",
    questionType: "lifestyle",
    options: [
      { value: "Never stressed", label: "Never stressed" },
      { value: "Almost never stressed", label: "Almost never stressed" },
      { value: "Sometimes stressed", label: "Sometimes stressed" },
      { value: "Fairly stressed", label: "Fairly stressed" },
      { value: "Very stressed", label: "Very stressed" },
    ],
  },
  {
    label: "In the last month, how would you describe your sleep?",
    value: "sleepAmount",
    questionType: "lifestyle",
    options: [
      { value: "5 hours or less", label: "5 hours or less" },
      { value: "Between 6-7 hours", label: "Between 6-7 hours" },
      { value: "8+ hours", label: "8+ hours" },
    ],
  },
  {
    label: "How do you feel about your progress?",
    value: "progressFeelings",
    questionType: "eczema",
    isTextArea: true,
    textArea: true,
    options: [
      {
        value: "How do you feel about your progress?",
        label: "How do you feel about your progress?",
      },
    ], // open text
  },
  {
    label: "Do you have any specific questions or comments for your provider?",
    value: "additionalQuestions",
    questionType: "eczema",
    textArea: true,
    options: [
      {
        value:
          "Do you have any specific questions or comments for your provider?",
        label:
          "Do you have any specific questions or comments for your provider?",
      },
    ], // open text
  },
];
