import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getPatientStore } from "../../../../features/patientView";
import "./style.scss";
import { getMyProgressDatePretty } from "../../../../utils/get-date-pretty";
import { ProgressPopupTemplate } from "../../Common/PopupLayer/progress";
import { ReactComponent as LockIcon } from "../../../../Assets/NewIcons/lock.svg";
import { ENV_CONFIG } from "../../../../config";
import { getSessionState } from "../../../../features/session";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { Loader } from "../../Common/Loader";
import { hasActiveMembership } from "../../../../utils/has-active-membership";

interface Props {
  onClose: () => void;
}

interface TabProps {
  handleClick: (str: string) => void;
  activeTab: string;
}

function renderTabs({ handleClick, activeTab }: TabProps) {
  return (
    <ul className={`my-progress-popup__tabs ${activeTab}`}>
      <li
        className="my-progress-popup__tabs-item"
        onClick={() => {
          handleClick("left");
        }}
      >
        Left view
      </li>
      <li
        className="my-progress-popup__tabs-item"
        onClick={() => {
          handleClick("front");
        }}
      >
        Front view
      </li>
      <li
        className="my-progress-popup__tabs-item"
        onClick={() => {
          handleClick("right");
        }}
      >
        Right view
      </li>
    </ul>
  );
}

function MyProgressPopup() {
  const { activePatientId } = useSelector(getSessionState);
  const followUpQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const [activeTab, setActiveTab] = useState("left");

  if (!followUpQuery.isSuccess || !subscriptionsQuery.isSuccess) {
    return <Loader />;
  }

  function renderImageList() {
    return (
      <ul className={`my-progress-popup__list ${activeTab}`}>
        {followUpQuery.data?.map(({ timestamp, images }) => (
          <li
            className="my-progress-popup__list-item"
            data-image-date={`${getMyProgressDatePretty(timestamp)}`}
          >
            <div
              className="my-progress-popup__list-item-image-wrapper"
              data-image-direction="Left"
            >
              <img
                className="my-progress-popup__list-item-image"
                alt="Image"
                src={`${ENV_CONFIG.AMAZON_S3_USER_IMAGES_DOMAIN}/${images[0].fileName}`}
              />
            </div>
            <div
              className="my-progress-popup__list-item-image-wrapper"
              data-image-direction="Front"
            >
              <img
                className="my-progress-popup__list-item-image"
                alt="Image"
                src={`${ENV_CONFIG.AMAZON_S3_USER_IMAGES_DOMAIN}/${images[1].fileName}`}
              />
            </div>
            <div
              className="my-progress-popup__list-item-image-wrapper"
              data-image-direction="Right"
            >
              <img
                className="my-progress-popup__list-item-image"
                alt="Image"
                src={`${ENV_CONFIG.AMAZON_S3_USER_IMAGES_DOMAIN}/${images[2].fileName}`}
              />
            </div>
          </li>
        ))}
      </ul>
    );
  }

  if (!hasActiveMembership(subscriptionsQuery.data)) {
    return (
      <div className="my-progress-popup">
        <div className="my-progress-popup__no-membership">
          <LockIcon className="my-progress-popup__no-membership-icon" />
          <p className="my-progress-popup__no-membership-title">
            You don't have subscription
          </p>
          <p className="my-progress-popup__no-membership-descr">
            Buy a subscription to obtain full access to Honeydew features
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="my-progress-popup">
      <p className="my-progress-popup__updated-text paragraph-font paragraph-font--color">
        The photos below are taken from your visit submissions. More photos will
        appear as you complete additional follow ups.
      </p>
      {renderTabs({ handleClick: setActiveTab, activeTab })}
      {renderImageList()}
    </div>
  );
}

export function MyProgressPopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <ProgressPopupTemplate title="View my progress" onClose={onClose}>
        <MyProgressPopup />
      </ProgressPopupTemplate>
    );
  };
}
