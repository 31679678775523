import { PatientDashboardItem } from "../../../../types/Dashboard";
import { Stylings } from "../../../../types/Table/TableBodyPayload";

export function getStyleForUpcomingVisitsDashboard(
  payload: PatientDashboardItem
) {
  switch (true) {
    case payload.appointmentStatus === "PENDING":
      return Stylings.Red;
    default:
      return Stylings.Base;
  }
}

export function getStyleForPostEnrollmentDashboard(
  payload: PatientDashboardItem
) {
  switch (true) {
    case !payload.flags.contacted:
      return Stylings.Blue;
    default:
      return Stylings.Base;
  }
}
