import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { Button } from "../Button";
import { Patient } from "../../../../types/Entities/Patient";
import { MONTH_NAMES } from "../../../../constants";
import { ImageViewPopupContext } from "../../../Components/Common/ImageViewPopup";
import { ReactComponent as SunIcon } from "../../../../Assets/NewIcons/sun.svg";
import { ReactComponent as MoonIcon } from "../../../../Assets/NewIcons/moon.svg";
import { ReactComponent as DoctorIcon } from "../../../../Assets/NewIcons/single-doctor.svg";
import { ReactComponent as CaretIcon } from "../../../../Assets/NewIcons/caret-icon.svg";
import {
  dairyConsumptionBaseQuestion,
  sleepAmountBaseQuestion,
  stressLevelBaseQuestion,
} from "../../../Pages/Patient/StartFollowUpPage/follow-up-questions";
import { generateAdaptiveQnA } from "../../../Components/Common/TreatmentPlanFollowUp";
import { getSessionState } from "../../../../features/session";
import { ReactComponent as GoodIcon } from "../../../../Assets/icons/good-icon.svg";
import { ReactComponent as OkIcon } from "../../../../Assets/icons/OkIcon.svg";
import { ReactComponent as BadIcon } from "../../../../Assets/icons/bad-icon.svg";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import "./style.scss";
import { TreatmentAnswer } from "../../../../types/CustomSurvey";
import { isEmpty } from "../../../../utils/is-empty";
import { ENV_CONFIG } from "../../../../config";
import { followupSkinIssues } from "../../../NewPages/Patient/FollowUpSurvey/follow-up-questions";
import {
  useGetFollowUpsByPatientIdQuery,
  useSubmitPrescriptionMutation,
} from "../../../../features/api/follow-ups";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { Loader } from "../Loader";
import { FollowUpQuestionnaire } from "../../../../types/FollowUp";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { usePdfToJpgConverter } from "../../../../utils/usePdfToJpgConverter";
import { getFullType } from "../../../../utils/get-full-type";
import { getLastFolloUpAnswer } from "../../../../utils/survey/get-last-follow-up-answer";
import { useGetAccutaneByPatientIdQuery } from "../../../../features/api/accutane";
import { InfoBlock } from "../InfoBlock";

interface Props {
  onClose: () => void;
}

interface VisitDetailsPopupProps extends Props {
  followUpId: string;
  preloadedImages?: Record<string, string>;
  followUpDate?: string;
}

function mapFollowUpBoolAnswer(value: string | boolean) {
  switch (value) {
    case "Yes":
    case "true":
    case true:
      return {
        bool: true,
        string: "Yes",
      };
    case "No":
    case "false":
    case false:
      return {
        bool: false,
        string: "No",
      };
    default:
      return null;
  }
}

interface VisitDetailsImageProps {
  imageName: string;
  base64?: string;
}

function VisitDetailsImage({ imageName, base64 }: VisitDetailsImageProps) {
  const { showImagePopup } = useContext(ImageViewPopupContext);
  const src =
    base64 || `${ENV_CONFIG.AMAZON_S3_USER_IMAGES_DOMAIN}/${imageName}`;
  const [imageUrl, setImageUrl] = useState("");
  const { convertPdfToJpg, imageData } = usePdfToJpgConverter();

  const handleImagePreview = () => {
    showImagePopup(imageUrl);
  };

  useEffect(() => {
    if (src) {
      if (src.split(".").pop().toLowerCase() === "pdf") {
        convertPdfToJpg(src);
        setImageUrl(imageData);
      } else setImageUrl(src);
    }
  }, [src, imageData]);

  return (
    <img
      src={imageUrl}
      className="visit-details-popup__content__item__images__item"
      alt="visit-image"
      onClick={handleImagePreview}
    />
  );
}

function WhenToUse({ partOfDay }: { partOfDay: string }) {
  switch (partOfDay) {
    case "morning": {
      return (
        <div className="when-to-use when-to-use__morning">
          <SunIcon />
          <span>Morning</span>
        </div>
      );
    }
    case "evening": {
      return (
        <div className="when-to-use when-to-use__evening">
          <MoonIcon />
          <span>Evening</span>
        </div>
      );
    }
    default: {
      return <div />;
    }
  }
}

function getFeelingIcon(
  feeling: string,
  skinType: any
): { icon: JSX.Element; text: string } {
  switch (feeling) {
    case "Good Day":
      return {
        icon: (
          <GoodIcon className="visit-details-popup__content__item__feeling-icon" />
        ),
        text: `Good day for my ${skinType} in the last 2 weeks`,
      };
    case "Typical Day": {
      return {
        icon: (
          <OkIcon className="visit-details-popup__content__item__feeling-icon" />
        ),
        text: `Typical day for my ${skinType} in the last 2 weeks`,
      };
    }
    case "Bad Day": {
      return {
        icon: (
          <BadIcon className="visit-details-popup__content__item__feeling-icon" />
        ),
        text: `Bad day for my ${skinType} in the last 2 weeks`,
      };
    }
    default: {
      return {
        icon: <div />,
        text: "",
      };
    }
  }
}

function InitialVisitAnswers({
  questionnaire,
  previousQuestionnaire,
  questions,
}: {
  questionnaire?: FollowUpQuestionnaire[];
  previousQuestionnaire?: FollowUpQuestionnaire[];
  questions: any;
}) {
  if (!questionnaire) {
    return <div />;
  }

  const [questionsArray, setQuestionsArray] = useState();

  const map = questionnaire?.reduce((acc, { type, value, id }) => {
    let _value = null;
    switch (type) {
      case "text":
        _value = value;
        break;
      case "number":
        _value = Number(value);
        break;
      case "list":
        _value = JSON.parse(value).join(", ");
        break;
      case "bool":
        _value = value === "true";
        break;
      default:
        break;
    }

    if (_value === null) return acc;
    return {
      ...acc,
      [id]: _value,
    };
  }, {} as { [p: string]: any });

  const checkIfQuestionExists = (questionId: string) =>
    questionnaire.some((item) => item.id === questionId);

  useEffect(() => {
    if (questionnaire && questions) {
      const combinedArray: any = [];
      questionnaire.forEach((questionnaireItem) => {
        const matchingQuestion = questions.find(
          (question: any) => question.value === questionnaireItem.id
        );
        if (
          matchingQuestion?.parentQuestion ||
          matchingQuestion?.value === "treatmentSurvey"
        )
          return;
        if (
          matchingQuestion &&
          (!matchingQuestion.hasChild || matchingQuestion.hasChild !== true)
        ) {
          // Case 1 where question does not have a child
          const combinedObject = {
            question: matchingQuestion.label,
            answer: questionnaireItem.value,
            type: questionnaireItem.type,
          };
          combinedArray.push(combinedObject);
        } else if (matchingQuestion && matchingQuestion.hasChild === true) {
          // Case 2 where question has a child
          const childQuestion = questionnaire.find(
            (child: any) => child.id === matchingQuestion.childId
          );

          if (childQuestion) {
            const combinedObject = {
              question: matchingQuestion.label,
              answer: childQuestion.value,
              type: childQuestion.type,
            };

            combinedArray.push(combinedObject);
          } else {
            const question: any = questionnaire.find(
              (child: any) => child.id === matchingQuestion.value
            );
            const newObject = {
              question: matchingQuestion.label,
              answer: question.value,
              type: question.type,
            };
            combinedArray.push(newObject);
          }
        }
      });
      setQuestionsArray(combinedArray);
    }
  }, [questionnaire, questions]);

  const convertBoolValue = (value: string) => {
    switch (value) {
      case "true":
        return "Yes";
      case "false":
        return "No";
      default:
        return null;
    }
  };
  console.log("questsArray", questionsArray);
  return (
    !isEmpty(questionsArray) &&
    questionsArray.map((obj: any) => {
      let description;
      if (obj.type === "bool") {
        description = convertBoolValue(obj.answer);
      } else if (obj.type === "list" && Array.isArray(JSON.parse(obj.answer))) {
        description = JSON.parse(obj.answer).join(", ");
      } else {
        description = obj.answer;
      }
      return (
        <li className="visit-details-popup__content__item__questionare__list__item">
          <p className="visit-details-popup__content__item__questionare__list__item__title">
            {obj.question}
          </p>
          <p className="visit-details-popup__content__item__questionare__list__item__description">
            {description}
          </p>
        </li>
      );
    })
    // <>
    //   <li className="visit-details-popup__content__item__questionare__list__item">
    //     <p className="visit-details-popup__content__item__questionare__list__item__title">
    //       Have there been any changes to your medical history since your last
    //       visit? (e.g. new medications, medical conditions, surgeries or
    //       procedures)
    //     </p>
    //     <p className="visit-details-popup__content__item__questionare__list__item__description">
    //       {mapFollowUpBoolAnswer(map.isMedicalHistoryChanges)?.bool
    //         ? map.medicalHistoryChanges
    //         : mapFollowUpBoolAnswer(map.isMedicalHistoryChanges)?.string}
    //     </p>
    //   </li>
    //   {!isEmpty(
    //     mapFollowUpBoolAnswer(map.isPrescriptionProductsOnDailyBasis)
    //   ) ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Have you been using all of your prescription products on a daily
    //         basis?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.isPrescriptionProductsOnDailyBasis}
    //       </p>
    //     </li>
    //   ) : null}
    //   {!isEmpty(
    //     mapFollowUpBoolAnswer(map.isPrescriptionProductsOnDailyBasis)
    //   ) &&
    //   !mapFollowUpBoolAnswer(map.isPrescriptionProductsOnDailyBasis)?.bool ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Which products do you avoid using regularly? Is there a specific
    //         reason why?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description" />
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("sideEffects") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         If you are on oral or injectable medications, do you have any eye
    //         dryness or eye irritation, body rash, stomach irritation?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.sideEffects)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("bodyRashEffects") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Do you have any body rash or stomach irritation from the oral
    //         medication?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.bodyRashEffects)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("isIrritationExperience") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Are you experiencing any stomach irritation, headache,
    //         lightheadedness, or body rash from your oral medications?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.isIrritationExperience}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("isDrynessExperience") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Are you experiencing any dryness or itching from your creams?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.isDrynessExperience)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("medicationSideEffects") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Do you have any side effects to your medication (both topical and
    //         systemic medications) that you wish to talk to your provider about?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.medicationSideEffects)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("isIssuesWithCreams") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Are you experiencing any dryness, redness, or itching from your
    //         creams?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.isIssuesWithCreams)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    //   {mapFollowUpBoolAnswer(map.isIssuesWithCreams)?.bool ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Please elaborate, and specify which prescriptions are causing
    //         irritation if you know.
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.issuesWithCreams}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("isOralMedicationInUse") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         If you are on oral medications, do you have any body rash, stomach
    //         irritation, or headaches/light headedness?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.isOralMedicationInUse}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("isRefillRequired") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Do you need a refill of any products?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.isRefillRequired)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    //   {mapFollowUpBoolAnswer(map.isRefillRequired)?.bool ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Which products do you want to refill?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.refillProducts}
    //       </p>
    //     </li>
    //   ) : null}
    //   {!isEmpty(map.accutaneContraindications) ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Are you taking any of these medications that are contraindications
    //         for accutane treatment?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.accutaneContraindications}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("eczemaDuration") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         How would you describe the amount of eczema? 1-10, with 1 being
    //         “totally clear” and 10 being “most of my body is covered with eczema
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.eczemaDuration}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("itchDuration") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         How would you describe your level of itch? 1-10, with 1 being “not
    //         at all” and 10 being “itching and scratching all day and can’t sleep
    //         at night because of it
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.itchDuration}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("psoriasisAmount") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         How would you describe the amount of psoriasis? (1-10; 1 - totally
    //         clear to 10 - most of my body covered with eczema)
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.psoriasisAmount}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("cystAmount") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         On a scale of 1-5, how would you describe the amount of cysts or
    //         boils present? 1 (totally clear) → 5 (armpits or groin still have
    //         active lesions)
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.cystAmount}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("painLevel") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         How would you describe your level of pain or inflammation? 1 (none
    //         at all) → 10 (extremely tender to the touch)
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.painLevel}
    //       </p>
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("stressLevel") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       {generateAdaptiveQnA(
    //         stressLevelBaseQuestion,
    //         map.stressLevel,
    //         previousQuestionnaire?.find(({ id }) => id === "stressLevel")?.value
    //       )}
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("sleepAmount") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       {generateAdaptiveQnA(
    //         sleepAmountBaseQuestion,
    //         map.sleepAmount,
    //         previousQuestionnaire?.find(({ id }) => id === "sleepAmount")?.value
    //       )}
    //     </li>
    //   ) : null}
    //   {checkIfQuestionExists("dairyConsumption") ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       {generateAdaptiveQnA(
    //         dairyConsumptionBaseQuestion,
    //         map.dairyConsumption,
    //         previousQuestionnaire?.find(({ id }) => id === "dairyConsumption")
    //           ?.value
    //       )}
    //     </li>
    //   ) : null}
    //   {map.progressFeelings !== "" ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         How do you feel about your progress?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.progressFeelings}
    //       </p>
    //     </li>
    //   ) : null}
    //   {map.additionalQuestions !== "" ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Do you have any specific questions or comments for your provider?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.additionalQuestions}
    //       </p>
    //     </li>
    //   ) : null}
    //   {!isEmpty(map.phqAnswer1) && !isEmpty(map.phqAnswer2) ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         PHQ-2 Questionnaire result
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {map.phqAnswer1 + map.phqAnswer2}
    //       </p>
    //     </li>
    //   ) : null}
    //   {mapFollowUpBoolAnswer(map.depressionHistory) ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Does anyone in your family have a history of depression?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.depressionHistory)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    //   {mapFollowUpBoolAnswer(map.wasDepressionTreated) ? (
    //     <li className="visit-details-popup__content__item__questionare__list__item">
    //       <p className="visit-details-popup__content__item__questionare__list__item__title">
    //         Have you ever been treated for depression?
    //       </p>
    //       <p className="visit-details-popup__content__item__questionare__list__item__description">
    //         {mapFollowUpBoolAnswer(map.wasDepressionTreated)?.string}
    //       </p>
    //     </li>
    //   ) : null}
    // </>
  );
}

function PrescriptionItem({
  title,
  strength,
  isPrescription,
  content,
  description,
  whenToUse,
  instruction,
}: {
  strength: string | null;
  whenToUse: string[];
  instruction: string | null;
  description: string;
  title: string;
  content: string;
  isPrescription?: boolean;
}) {
  const [isCollapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => setCollapsed(!isCollapsed);

  return (
    <li className="prescriptions-list__item">
      <div className="prescriptions-list__item__title-wrapper">
        <CaretIcon
          onClick={toggleCollapse}
          className={`${
            isCollapsed
              ? "prescriptions-list__item__title-wrapper__collapse-icon--opened"
              : "prescriptions-list__item__title-wrapper__collapse-icon"
          }`}
        />
        <div className="prescriptions-list__item__title-wrapper__title">
          <p className="prescriptions-list__item__title-wrapper__title__text max-width-150">
            {title} {strength}
          </p>
          {/* <p className="prescriptions-list__item__title-wrapper__title__is-prescription"> */}
          {/*  {isPrescription ? "Prescription" : "Non-prescription"} */}
          {/* </p> */}
          <div className="prescriptions-list__item__title-wrapper__title__when-to-use">
            {whenToUse.map((it) =>
              it === "morning" ? <SunIcon /> : <MoonIcon />
            )}
          </div>
        </div>
      </div>
      {isCollapsed && (
        <p className="prescriptions-list__item__instruction">{instruction}</p>
      )}
      <div className="prescriptions-list__item__content">{content}</div>
      {description && (
        <div className="prescriptions-list__item__description">
          {description}
        </div>
      )}
    </li>
  );
}

function InitialVisitPrescriptions({
  prescriptions,
}: {
  prescriptions: Array<{
    strength: string | null;
    whenToUse: string[];
    instruction: string | null;
    description: string;
    title: string;
    content: string;
  }>;
}) {
  return (
    <ul className="prescriptions-list">
      <p className="prescriptions-list__title">Exact daily treatment routine</p>
      {prescriptions.map((it) => (
        <PrescriptionItem {...it} />
      ))}
    </ul>
  );
}

const formatDate = (dateString: string | undefined): string => {
  if (dateString === "--" || dateString === "N/A") {
    return "--";
  }
  if (dateString && dateString !== "N/A") {
    return getMomentDate(dateString).format("MM/DD/YY");
  }
  return "N/A";
};

enum ANSWERS {
  NORMAL = "NORMAL",
  STOPPED = "STOPPED",
  MODIFIED = "MODIFIED",
}

const ANSWER_TO_TEXT = {
  [ANSWERS.NORMAL]: "I use it exactly as directed",
  [ANSWERS.STOPPED]: "I stopped using this",
  [ANSWERS.MODIFIED]: "I modified the amount or the frequency of use",
};

export function VisitDetails({
  onClose,
  followUpId,
  preloadedImages,
  followUpDate,
}: VisitDetailsPopupProps) {
  const history = useHistory();
  const [prescriptionSubmitted, setPrescriptionSubmitted] = useState(false);
  const [patientSkinType, setPatientSkinType] = useState("");
  const [followUpQuestionnaire, setFollowUpQuestionnaire] = useState();
  const { userRole, activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const { data: accutane, isSuccess } = useGetAccutaneByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  const accutaneStatus = accutane?.status;
  const nextConfirmationDate = formatDate(accutane?.nextConfirmationDate);
  const skinSurvey = patientQuery?.currentData?.medicalBackground.skinSurvey;
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const [submitPrescription] = useSubmitPrescriptionMutation();
  const followUp = followUpsQuery.data?.find(
    ({ followUpId: id }) => id === followUpId
  );

  const submittedBy = followUp?.treatmentPlan?.submittedBy;
  const employeeQuery = useGetEmployeeQuery(submittedBy as string, {
    skip: !submittedBy,
  });
  const medicationsQuery = useGetAllMedicationsQuery(null);

  const isProvider = userRole === "providers";
  const isCareCoordinator = userRole === "care-coordinators";
  const isAdmin = userRole === "admins";
  const isPatient = userRole === "patients";

  console.log("userRole", userRole);

  useEffect(() => {
    const medicalBackground: any = patientQuery.currentData?.medicalBackground;
    if (medicalBackground) {
      const skinIssueTypeObject = medicalBackground?.skinSurvey?.find(
        (obj) => obj.id === "skinIssueType"
      );

      setPatientSkinType(
        skinIssueTypeObject ? skinIssueTypeObject.value : "acne"
      );
    }
  }, [patientQuery]);

  useEffect(() => {
    if (patientSkinType) {
      const getFollowUpQuestionnaire = followupSkinIssues.find(
        (obj) => obj.value === patientSkinType
      );

      getFollowUpQuestionnaire?.subQuestions
        ?.filter((question: any) => question.questionType === "lifestyle")
        .forEach((question: any) => {
          const lastFollowUpAnswer = getLastFolloUpAnswer(
            patientQuery?.data,
            followUpsQuery?.data,
            question.value
          );

          question.label = question.label.replace(
            "part from survey",
            lastFollowUpAnswer
          );
        });
      setFollowUpQuestionnaire(getFollowUpQuestionnaire?.subQuestions);
    }
  }, [patientSkinType]);

  function accutaneDuration() {
    const duration = accutane?.confirmationPeriod;
    if (duration?.months === 0 && duration?.days === 0)
      return (
        <>
          <span className="visit-details-popup__content__item__head__title">
            Status:{" "}
          </span>
          <span>Active</span>
        </>
      );
    if (duration && duration.past === false)
      return (
        <InfoBlock
          text={
            <>
              <span className="visit-details-popup__content__item__head__title">
                Status:
              </span>{" "}
              Active (Month {duration.months}, Day {duration.days})
            </>
          }
          view="info"
        />
      );
    if (duration?.months > 0)
      return (
        <InfoBlock
          text={
            <>
              <span className="visit-details-popup__content__item__head__title">
                Status:
              </span>{" "}
              Active (Month {duration.months}, {duration.days} days past
              expected next confirmation)
            </>
          }
          view="warn"
        />
      );
    return (
      <>
        <span className="visit-details-popup__content__item__head__title">
          Status:
        </span>
        <span>Active</span>
      </>
    );
  }

  const showStatusIndicator = () => {
    switch (accutaneStatus) {
      case "renew":
        return accutaneDuration();
      case "stop":
        return (
          <>
            <span className="visit-details-popup__content__item__head__title">
              Status:
            </span>
            <span>Course concluded</span>
          </>
        );
      case "pause":
        return (
          <>
            <span className="ipledge-details__title">Status:</span>
            <span>
              {" "}
              Month {accutane?.confirmationPeriod?.months}, Day{" "}
              {accutane?.confirmationPeriod?.days} (Paused <br /> until{" "}
              {nextConfirmationDate})
            </span>
          </>
        );
      default:
        return accutaneDuration();
    }
  };

  const editTreatmentPlanHandler = () => {
    window.scrollTo(0, 0);
    history.push(`/${activePatientId}/visit`);
    onClose();
  };

  const startVisitHandler = () => {
    window.scrollTo(0, 0);
    history.push(`/${activePatientId}/visit`);
    onClose();
  };

  const markAsRead = async () => {
    if (followUp) {
      await submitPrescription(followUp?.followUpId);
      setPrescriptionSubmitted(true);
    }
  };

  const acneFeelingForLast2Weeks = getFeelingIcon(
    followUp?.questionnaire?.find(({ id }) => id === "acneFeelings")?.value ??
      "",
    patientSkinType
  );

  if (
    !followUpsQuery.isSuccess ||
    (submittedBy && !employeeQuery.isSuccess) ||
    !medicationsQuery.isSuccess ||
    !patientQuery.isSuccess
  ) {
    return <Loader />;
  }

  if (!followUp) {
    return null;
  }

  const isActual = followUpsQuery.data[0].followUpId === followUpId;
  const isInitialVisit = followUpsQuery.data.length === 1;
  const followUpIndex = followUpsQuery.data.findIndex(
    ({ followUpId: id }) => id === followUpId
  );
  const previousFollowUp = followUpsQuery.data[followUpIndex + 1];

  const prescriptionsList =
    followUp.treatmentPlan?.medications.map((medication) => {
      const medicine = medicationsQuery.data.find(
        (it) => medication.medicineKey === it.id
      );

      if (!medicine) {
        return null;
      }

      return {
        title: `${medicine.name} ${medicine?.strength ?? ""} ${
          medicine?.size ?? ""
        }`,
        description: medication.specialInstructions,
        isPrescription: true,
        whenToUse: medication.instructions,
      };
    }) ?? null;

  // todo add isPrescription key later
  const initialVisitPrescriptions: {
    strength: string | null;
    whenToUse: string[];
    instruction: string | null;
    description: string;
    title: string;
    content: string;
  }[] =
    previousFollowUp?.treatmentPlan?.medications.map((it, index) => {
      const medicationFromList = medicationsQuery.data.find(
        (medication) => medication.id === it.medicineKey
      );
      const treatmentSurveyAnswer = followUp.questionnaire?.find(
        ({ id }) => id === "treatmentSurvey"
      );

      let treatmentSurvey;

      try {
        treatmentSurvey = treatmentSurveyAnswer
          ? JSON.parse(treatmentSurveyAnswer.value)
          : null;
      } catch (error) {
        console.error("Error parsing JSON:", error.message);
        // Handle the error accordingly, for example:
        // treatmentSurvey = null;
      }
      const hasTreatmentSurvey: TreatmentAnswer | null =
        treatmentSurvey?.[index] ?? null;

      return {
        title: medicationFromList?.name || "",
        strength: medicationFromList?.strength || "",
        content:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          hasTreatmentSurvey ? ANSWER_TO_TEXT[hasTreatmentSurvey.answer] : "",
        description: hasTreatmentSurvey?.comment ?? "",
        whenToUse: it?.instructions ?? [],
        instruction: it?.specialInstructions ?? "",
      };
    }) ?? [];

  return (
    <div className="visit-details-popup">
      <div className="visit-details-popup__action-buttons">
        {isActual &&
        isCareCoordinator &&
        !followUp.treatmentPlan?.isPrescriptionSubmitted &&
        !prescriptionSubmitted ? (
          <Button
            text="Prescription submitted"
            size="small"
            className="visit-details-popup__action-buttons__button"
            onClick={markAsRead}
          />
        ) : null}
        {(isAdmin || isProvider) && isActual && !followUp.treatmentPlan && (
          <Button
            text="Start visit"
            size="small"
            className="visit-details-popup__action-buttons__button"
            onClick={startVisitHandler}
          />
        )}
        {(isAdmin || isProvider || isCareCoordinator) &&
          isActual &&
          followUp.treatmentPlan && (
            <Button
              text="Edit treatment plan"
              size="small"
              className="visit-details-popup__action-buttons__button"
              onClick={editTreatmentPlanHandler}
            />
          )}
      </div>
      <div className="visit-details-popup__content">
        <div className="visit-details-popup__content__item">
          <div className="visit-details-popup__content__item__head">
            <p className="visit-details-popup__content__item__head__title">
              {getMomentDate(followUpDate).format("MMMM Do, YYYY")}
            </p>
            {followUp && (
              <div className="visit-details-popup__content__item__head__feeling">
                <span className="visit-details-popup__content__item__head__feeling__text">
                  {acneFeelingForLast2Weeks.text}
                </span>
                {acneFeelingForLast2Weeks.icon}
              </div>
            )}
          </div>
          <div className="visit-details-popup__content__item__images">
            {followUp.images?.map(({ fileName }) => (
              <VisitDetailsImage
                imageName={fileName}
                key={fileName}
                base64={preloadedImages?.[fileName]}
              />
            ))}
          </div>
        </div>
        <div className="visit-details-popup__content__item__head" />
        <div className="visit-details-popup__content__item">
          {!isPatient && (
            <div className="visit-details-popup__content__item__head">
              <p className="visit-details-popup__content__item__head__title">
                {isInitialVisit
                  ? "Submitted initial visit"
                  : "Submitted follow up"}
              </p>
              <br />

              {/* <p className="visit-details-popup__content__item__head__description"> */}
              {/*  Patient submitted the follow up on {month} {day}, {year} at {time} */}
              {/* </p> */}
            </div>
          )}
          {followUp.questionnaire && (
            <div className="visit-details-popup__content__item__questionare">
              <ul className="visit-details-popup__content__item__questionare__list">
                <InitialVisitAnswers
                  questionnaire={followUp.questionnaire}
                  questions={followUpQuestionnaire}
                  previousQuestionnaire={
                    previousFollowUp?.questionnaire ||
                    patientQuery.data.medicalBackground.skinSurvey
                  }
                />
              </ul>
              {initialVisitPrescriptions &&
                followUp?.questionnaire?.find(
                  ({ id }) => id === "treatmentSurvey"
                ) && (
                  <InitialVisitPrescriptions
                    prescriptions={initialVisitPrescriptions}
                  />
                )}
            </div>
          )}
        </div>
        {followUp.treatmentPlan?.message && (
          <div className="visit-details-popup__content__item">
            <div className="visit-details-popup__content__item__head">
              <p className="visit-details-popup__content__item__head__title">
                Provider's response
              </p>
              {followUp.treatmentPlan?.nextFollowUpDate && (
                <div className="visit-details-popup__content__item__head__next-visit">
                  <p>Next Follow-up</p>
                  <p className="visit-details-popup__content__item__head__next-visit__date-number">
                    {moment
                      .utc(followUp.treatmentPlan.nextFollowUpDate)
                      .local()
                      .format("DD")}
                  </p>
                  <p className="visit-details-popup__content__item__head__next-visit__date-month">
                    {getMomentDate(
                      new Date(followUp.treatmentPlan.nextFollowUpDate)
                    ).format("MMMM")}
                  </p>
                </div>
              )}
              {/* <p className="visit-details-popup__content__item__head__description"> */}
              {/*  Provider submitted the follow up on{" "} */}
              {/*  {nextFollowUpDateFormatted.month}{" "} */}
              {/*  {nextFollowUpDateFormatted.day},{" "} */}
              {/*  {nextFollowUpDateFormatted.year} at{" "} */}
              {/*  {nextFollowUpDateFormatted.time} */}
              {/* </p> */}
            </div>
            <div className="visit-details-popup__content__item__wrapper">
              <div className="visit-details-popup__content__item__wrapper__providers-response">
                <div className="visit-details-popup__content__item__wrapper__providers-response__provider">
                  <div className="visit-details-popup__content__item__wrapper__providers-response__provider__head">
                    {employeeQuery.data?.image ? (
                      <img
                        className="visit-details-popup__content__item__wrapper__providers-response__provider__head__image"
                        src={employeeQuery.data?.image}
                        alt="provider-photo"
                      />
                    ) : (
                      <DoctorIcon className="visit-details-popup__content__item__wrapper__providers-response__provider__head__image" />
                    )}
                    <div className="visit-details-popup__content__item__wrapper__providers-response__provider__head__about">
                      <p className="visit-details-popup__content__item__wrapper__providers-response__provider__head__about__name">
                        {employeeQuery.data?.firstName}{" "}
                        {employeeQuery.data?.lastName} (
                        {employeeQuery.data?.role &&
                          getFullType(employeeQuery.data?.role)}
                        )
                      </p>
                      {/* <p className="visit-details-popup__content__item__wrapper__providers-response__provider__head__about__date"> */}
                      {/*  {nextFollowUpDateFormatted.month}{" "} */}
                      {/*  {nextFollowUpDateFormatted.day} ,{" "} */}
                      {/*  {nextFollowUpDateFormatted.year}{" "} */}
                      {/*  {nextFollowUpDateFormatted.time} */}
                      {/* </p> */}
                    </div>
                  </div>
                  <p className="visit-details-popup__content__item__wrapper__providers-response__provider__answer">
                    {followUp.treatmentPlan?.message}
                  </p>
                </div>
              </div>
              <div className="visit-details-popup__content__item__wrapper__prescriptions">
                <ul className="visit-details-popup__content__item__wrapper__prescriptions__list">
                  {prescriptionsList &&
                    prescriptionsList.every((item) => !!item) &&
                    prescriptionsList.map((prescription) => (
                      <li className="visit-details-popup__content__item__wrapper__prescriptions__list__item">
                        <p className="visit-details-popup__content__item__wrapper__prescriptions__list__title">
                          <span className="visit-details-popup__content__item__wrapper__prescriptions__list__item__title">
                            {prescription?.title}
                          </span>
                          {/* <span className="visit-details-popup__content__item__wrapper__prescriptions__list__item__is-prescription"> */}
                          {/*  {prescription?.isPrescription */}
                          {/*    ? "Prescription" */}
                          {/*    : "Non-prescription"} */}
                          {/* </span> */}
                        </p>
                        <p className="visit-details-popup__content__item__wrapper__prescriptions__list__item__description">
                          {prescription?.description}
                        </p>
                        <div className="visit-details-popup__content__item__wrapper__prescriptions__list__item__prescription">
                          {prescription?.whenToUse.map((partOfDay) => (
                            <WhenToUse partOfDay={partOfDay} />
                          ))}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function VisitDetailsPopupGenerator(followUpId: string, followUpDate: string) {
  return function render({ onClose }: Props) {
    return (
      <ActionPopupTemplate title="Visit details" onClose={onClose} size="large">
        <VisitDetails
          followUpId={followUpId}
          onClose={onClose}
          followUpDate={followUpDate}
        />
      </ActionPopupTemplate>
    );
  };
}

export default VisitDetailsPopupGenerator;
