import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as PencilIcon } from "../../../../Assets/NewIcons/pencil.svg";
import "./style.scss";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { AssignEmployeePopupGenerator } from "../AssignEmployeePopup";
import { useGetCareTeamQuery } from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { USER_ROLES } from "../../../../types/Main";
import { Skeleton } from "../../Common/Skeleton";
import { LoadingBlock } from "../../Common/LoadingBlock";
import { getFullType } from "../../../../utils/get-full-type";

interface Props {
  editable?: boolean;
}

function CareTeamItem({ employeeId }: { employeeId: string }) {
  const {
    data: employee,
    isSuccess,
    isLoading,
  } = useGetEmployeeQuery(employeeId);
  const fullName = [employee?.firstName, employee?.lastName];
  if (employee?.role === USER_ROLES.PROVIDER) fullName.push(employee.title);

  return (
    <div className="care-team-block__item">
      {!isSuccess || isLoading ? (
        <>
          <Skeleton
            flex
            fullWidth
            fullHeight
            style={{
              height: 40,
              width: 40,
            }}
          />
          <Skeleton flex fullWidth fullHeight />
        </>
      ) : (
        <>
          <img src={employee?.image} className="care-team-block__img" />
          <div className="care-team-block__text">
            <p className="care-team-block__title subheading-font subheading-font--color">
              {fullName.join(" ")}
            </p>
            <p className="care-team-block__type">
              {employee?.role && getFullType(employee?.role)}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export function CareTeamBlock({ editable = false }: Props) {
  const { showPopup } = useContext(PopupLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const careTeamQuery = useGetCareTeamQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  return (
    <div className="care-team-block">
      <div className="care-team-block__heading">
        <p className="care-team-block__heading-title">Providers</p>
        {editable ? (
          <div
            className="care-team-block__action"
            onClick={() =>
              showPopup(
                AssignEmployeePopupGenerator({
                  type: USER_ROLES.PROVIDER,
                  patientId: activePatientId as string,
                })
              )
            }
          >
            <PencilIcon />
          </div>
        ) : null}
      </div>
      <div className="care-team-block__list">
        {careTeamQuery.data ? (
          careTeamQuery.data
            .filter(({ role }) => role === USER_ROLES.PROVIDER)
            .map(({ employeeId }) => <CareTeamItem employeeId={employeeId} />)
        ) : (
          <LoadingBlock />
        )}
      </div>
      <div className="care-team-block__heading">
        <p className="care-team-block__heading-title">Care coordinators</p>
        {editable ? (
          <div
            className="care-team-block__action"
            onClick={() =>
              showPopup(
                AssignEmployeePopupGenerator({
                  type: USER_ROLES.CARE_COORDINATOR,
                  patientId: activePatientId as string,
                })
              )
            }
          >
            <PencilIcon />
          </div>
        ) : null}
      </div>
      <div className="care-team-block__list">
        {careTeamQuery.data ? (
          careTeamQuery.data
            .filter(({ role }) => role === USER_ROLES.CARE_COORDINATOR)
            .map(({ employeeId }) => <CareTeamItem employeeId={employeeId} />)
        ) : (
          <LoadingBlock />
        )}
      </div>
    </div>
  );
}
