import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextInput } from "../../../Common/TextInput";
import "./style.scss";
// import { CheckoutShippingInfo } from "../../../../../types/Checkout";
import { useGetPatientByIdQuery } from "../../../../../features/api/patients";
import { getSessionState } from "../../../../../features/session";
import { Skeleton } from "../../../Common/Skeleton";
import { ShippingInfo } from "../../../../../types/Entities/Patient";

interface Props {
  onSelectChange: (value: boolean) => void;
  onShippingInfoChange: (value: ShippingInfo) => void;
}

export function CheckoutFormShippingInfo({
  onShippingInfoChange,
  onSelectChange,
}: Props) {
  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  function changeShippingInfo(key: keyof ShippingInfo, value: string) {
    setShippingInfo(
      JSON.parse(
        JSON.stringify({
          ...shippingInfo,
          [key]: value,
        })
      )
    );
  }

  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [isCurrent, setIsCurrent] = useState(true);

  useEffect(() => {
    onSelectChange(isCurrent);
  }, [isCurrent]);
  useEffect(() => {
    onShippingInfoChange(shippingInfo);
  }, [shippingInfo]);

  return (
    <div className="checkout-component-radio">
      <div
        className={`checkout-component-radio__item ${
          isCurrent === true ? "selected" : ""
        }`}
        onClick={() => setIsCurrent(true)}
      >
        <div className="checkout-component-radio__heading">
          <div className="checkout-component-radio__circle" />
          <p>
            {patientQuery.isSuccess ? patientQuery.data.fullName : <Skeleton />}
          </p>
          <p className="checkout-shipping-info__address-text paragraph-font--color">
            {patientQuery.isSuccess ? (
              [
                patientQuery.data.shippingInfo?.addressLine1,
                patientQuery.data.shippingInfo?.addressLine2,
                patientQuery.data.shippingInfo?.city,
                patientQuery.data.shippingInfo?.state,
                patientQuery.data.shippingInfo?.zipCode,
              ]
                .filter((it) => it)
                .join(", ")
            ) : (
              <Skeleton />
            )}
          </p>
        </div>
      </div>
      <div
        className={`checkout-component-radio__item ${
          isCurrent === false ? "selected" : ""
        }`}
        onClick={() => setIsCurrent(false)}
      >
        <div className="checkout-component-radio__heading">
          <div className="checkout-component-radio__circle" />
          <p>Add New Shipping Address</p>
        </div>
        <div className="checkout-component-radio__body">
          <div className="checkout-shipping-info">
            <TextInput
              onChange={(value) => changeShippingInfo("addressLine1", value)}
              id="address-line-1"
              name="address line 1"
              className="checkout-shipping-info__field--address-1"
              placeholder="Address 1"
            />
            <TextInput
              onChange={(value) => changeShippingInfo("addressLine2", value)}
              id="address-line-2"
              name="address line 2"
              className="checkout-shipping-info__field--address-2"
              placeholder="Address 2"
            />
            <TextInput
              onChange={(value) => changeShippingInfo("city", value)}
              id="city"
              name="city"
              className="checkout-shipping-info__field--city"
              placeholder="City"
            />
            <TextInput
              onChange={(value) => changeShippingInfo("state", value)}
              id="state"
              name="state"
              className="checkout-shipping-info__field--state"
              placeholder="State"
            />
            <TextInput
              onChange={(value) => changeShippingInfo("zipCode", value)}
              id="zip-code"
              name="zip code"
              className="checkout-shipping-info__field--zip-code"
              placeholder="Zip code"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
