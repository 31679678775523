import React from "react";

interface Props {
  required?: boolean;
  disabled?: boolean;
  value?: string;
  label?: string;
  error?: string;
  id: string;
  name: string;
  className?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export function DateInput({
  required,
  disabled,
  value,
  label,
  error,
  onChange,
  id,
  name,
  placeholder,
  className,
}: Props) {
  function checkValue(str: string, max: number) {
    if (str.charAt(0) !== "0" || str === "00") {
      let num = parseInt(str, 10);
      if (Number.isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0), 10) &&
        num.toString().length === 1
          ? `0${num}`
          : num.toString();
    }
    return str;
  }
  function renderLabel() {
    if (!label) return null;
    return (
      <label
        className="text-input__label label-font label-font--color"
        htmlFor={id}
      >
        {label}
        {required ? " *" : null}
      </label>
    );
  }
  return (
    <div className={`text-input ${className || ""}`}>
      {renderLabel()}
      <input
        className={`text-input__field input-font input-font--color${
          error ? " text-input__field--error" : ""
        }`}
        type="text"
        // DON'T TOUCH
        // MAGIC HAPPENING HERE
        onChange={(e) => {
          let input = e.target.value;
          if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
          const values = input
            .split("/")
            .map((v: string) => v.replace(/\D/g, ""));
          if (values[0]) values[0] = checkValue(values[0], 12);
          if (values[1]) values[1] = checkValue(values[1], 31);
          const output = values.map((v: string, i: number) =>
            v.length === 2 && i < 2 ? `${v} / ` : v
          );
          onChange(output.join("").substr(0, 14));
        }}
        // onBlur={(e) => {
        //   const input = e.target.value;
        //   const values = input.split("/").map((v, i) => v.replace(/\D/g, ""));
        //   let output = "";

        //   if (values.length === 3) {
        //     const year =
        //       values[2].length !== 4
        //         ? parseInt(values[2], 10) + 2000
        //         : parseInt(values[2], 10);
        //     const month = parseInt(values[0], 10) - 1;
        //     const day = parseInt(values[1], 10);
        //     const d = new Date(year, month, day);
        //     if (!Number.isNaN(d)) {
        //       const dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
        //       output = dates
        //         .map((v) => {
        //           const _v = v.toString();
        //           return _v.length === 1 ? `0${v}` : v;
        //         })
        //         .join("/");
        //     }
        //   }
        //   onChange(output);
        // }}
        required={required}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
      />
      {error ? (
        <p className="text-input__error-text error-font error-font--color">
          {error}
        </p>
      ) : null}
    </div>
  );
}
