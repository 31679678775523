const notArchived = {
  bool: {
    must_not: {
      match: {
        archived: true,
      },
    },
  },
};

export function getPatientDashboardForCareCoordinatorFilters(
  careCoordinatorId: string
) {
  return [
    {
      text: "All",
      filter: {
        must: [
          {
            match_all: {},
          },
          {
            match: {
              "careTeam.employeeId": careCoordinatorId,
            },
          },
          {
            match: {
              "subscription.membership": true,
            },
          },
          notArchived,
        ],
      },
    },
    {
      text: "New treatment plans",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": careCoordinatorId,
            },
          },
          {
            match: {
              "followUp.isTreatmentPlanSet": true,
            },
          },
          {
            match: {
              "followUp.isPrescriptionSubmitted": false,
            },
          },
          {
            match: {
              "subscription.membership": true,
            },
          },
          notArchived,
        ],
      },
    },
    {
      text: "Accutane",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": careCoordinatorId,
            },
          },
          {
            match: {
              "subscription.accutane": true,
            },
          },
          {
            match: {
              "subscription.membership": true,
            },
          },
          notArchived,
        ],
      },
    },
    {
      text: "Miscellaneous tasks",
      filter: {
        must: [
          {
            match_all: {},
          },
          {
            match: {
              "careTeam.employeeId": careCoordinatorId,
            },
          },
          {
            match: {
              "subscription.membership": true,
            },
          },
          {
            match: {
              tasks: careCoordinatorId,
            },
          },
          notArchived,
        ],
      },
    },
  ];
}

const noMembership = {
  bool: {
    should: [
      {
        bool: {
          must_not: {
            exists: {
              field: "subscription",
            },
          },
        },
      },
      {
        match: {
          "subscription.membership": false,
        },
      },
    ],
  },
};

export function getPatientDashboardForCareCoordinatorFiltersInactive(
  careCoordinatorId: string
) {
  return [
    {
      text: "All",
      filter: {
        must: [
          {
            match_all: {},
          },
          {
            match: {
              "careTeam.employeeId": careCoordinatorId,
            },
          },
        ],
        filter: {
          ...noMembership,
        },
      },
    },
    {
      text: "Inactive membership",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": careCoordinatorId,
            },
          },
          {
            ...noMembership,
          },
          {
            bool: {
              should: [
                {
                  match: {
                    "followUp.isInitialFollowUp": false,
                  },
                },
                {
                  bool: {
                    must: [
                      {
                        match: {
                          "followUp.isInitialFollowUp": true,
                        },
                      },
                      {
                        match: {
                          "followUp.isTreatmentPlanSet": true,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          notArchived,
        ],
      },
    },
    {
      text: "Archived",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": careCoordinatorId,
            },
          },
          {
            ...noMembership,
          },
          {
            match: {
              archived: true,
            },
          },
        ],
      },
    },
  ];
}
