function capitalizeFirstLetter(data: string | null) {
  if (!data) {
    return "";
  }
  const dataSymbols = data.split("");

  return dataSymbols.reduce((acc, it, index) => {
    const letterToAdd = index === 0 ? it.toUpperCase() : it;

    acc += letterToAdd;

    return acc;
  }, "");
}

export default capitalizeFirstLetter;
