import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientsPageForEnrollmentCoordinator } from "../../NewPages/EnrollmentCoordinator/PatientsPage";
import { Footer } from "../../NewComponents/Common/Footer";
import { getSessionState } from "../../../features/session";
import EnrollmentRescheduleComponent from "./EnrollmentRescheduleComponent";
import {
  getPatientDashboardForEnrollmentCoordinatorFiltersDeclinedVisits,
  getPatientDashboardForEnrollmentCoordinatorFiltersPostEnrollment,
  getPatientDashboardForEnrollmentCoordinatorFiltersUpcomingVisits,
} from "../../NewPages/EnrollmentCoordinator/PatientsPage/filters";
import { HoneydewAPI } from "../../../services/honeydew-api";
import PatientDashboardForEnrollmentComponent from "./PatientDashboardForEnrollmentComponent";

function EnrollmentProtectedRoutes() {
  const [isAuthorised, setIsAuthorised] = useState(true);
  const [paramsId, setParamsId] = useState(null);

  useEffect(() => {
    if (paramsId) {
      const filters1 =
        getPatientDashboardForEnrollmentCoordinatorFiltersUpcomingVisits();
      const filters2 =
        getPatientDashboardForEnrollmentCoordinatorFiltersPostEnrollment();
      const filters3 =
        getPatientDashboardForEnrollmentCoordinatorFiltersDeclinedVisits();
      const currentIndex = localStorage.getItem("currentIndex");
      const currentIndexFormatted = JSON.parse(currentIndex);
      const allFilters = [filters1, filters2, filters3];

      (async () => {
        let isExist = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const filters of allFilters) {
          // eslint-disable-next-line no-await-in-loop
          const { filter } = currentIndexFormatted
            ? filters[currentIndexFormatted.currentSubTab]
            : filters[currentFilter];

          const query = {
            query: {
              from: 0,
              size: 1000,
              query: {
                function_score: {
                  query: {
                    bool: {
                      ...filter,
                    },
                  },
                },
              },
            },
          };
          // eslint-disable-next-line no-await-in-loop
          const result = await HoneydewAPI.dashboard.queryDashboardItems<T>(
            query
          );
          if (result?.hits.some((obj) => obj._id === paramsId)) {
            isExist = true;
            break;
          }
        }

        setIsAuthorised(isExist);
      })();
    }
  }, []);

  return (
    <Switch>
      <Route path="/reschedule/:patientId">
        <div className="main-area">
          <EnrollmentRescheduleComponent
            isAuthorised={isAuthorised}
            setParamsId={setParamsId}
          />
        </div>
      </Route>
      <Route path="/:userId">
        <div className="main-area">
          <PatientDashboardForEnrollmentComponent
            isAuthorised={isAuthorised}
            setParamsId={setParamsId}
          />
        </div>
      </Route>
      <Route path="/">
        <div className="main-area">
          <PatientsPageForEnrollmentCoordinator />
          <Footer />
        </div>
      </Route>
    </Switch>
  );
}

export default EnrollmentProtectedRoutes;
