import React, { useState } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import { ReactComponent as LogoIcon } from "../../../../Assets/NewIcons/logo-icon.svg";
import { ReactComponent as LogoText } from "../../../../Assets/NewIcons/logo-text.svg";
import { ReactComponent as BurgerIcon } from "../../../../Assets/NewIcons/burger.svg";
import { ReactComponent as CrossIcon } from "../../../../Assets/NewIcons/close-cross.svg";
import { getSessionState } from "../../../../features/session";
import { MenuItemsForPatient } from "./user-types/patient";
import { MenuItemsForProvider } from "./user-types/provider";
import { MenuItemsForAdministrator } from "./user-types/administrator";
import { MenuItemsForCareCoordinator } from "./user-types/care-coordinator";
import { getCurrentYear } from "../../../../utils/getCurrentYear";

export function Menu() {
  const { userRole } = useSelector(getSessionState);
  const [expanded, setExpanded] = useState(false);

  function toggleExpand() {
    setExpanded(!expanded);
  }

  function renderMenuItems() {
    switch (userRole) {
      case "patients":
        return <MenuItemsForPatient onClose={() => setExpanded(false)} />;
      case "providers":
        return <MenuItemsForProvider onClose={() => setExpanded(false)} />;
      case "care-coordinators":
      case "enrollment-coordinators":
        return (
          <MenuItemsForCareCoordinator onClose={() => setExpanded(false)} />
        );
      case "admins":
        return <MenuItemsForAdministrator onClose={() => setExpanded(false)} />;
      default:
        return null;
    }
  }

  return (
    <>
      <div className="menu__placeholder-mobile" />
      <div className="menu__placeholder">
        <div className={`menu__over-wrap ${expanded ? "expanded" : ""}`}>
          <div className="menu__chevron" onClick={toggleExpand}>
            <ChevronIcon />
          </div>
          <div className="menu">
            <div className="menu__expand-wrap">
              <div className="menu__heading">
                <div className="menu__logo-wrap">
                  <LogoIcon className="menu__logo-icon" />
                  <LogoText />
                </div>
                <div className="menu__toggle-button" onClick={toggleExpand}>
                  <BurgerIcon className="menu__toggle-button--burger" />
                  <CrossIcon className="menu__toggle-button--cross" />
                </div>
              </div>
              <ul className="menu__list">{renderMenuItems()}</ul>
              <p className="menu__copyright paragraph-font paragraph-font--color">
                © {getCurrentYear()} AcneAway, Inc. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
