import React from "react";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import { ReactComponent as WomenOnMicImage } from "../../../../Assets/NewIcons/women-on-mic.svg";
import "./style.scss";

interface Props {
  onClose: () => void;
}

function HelpPopup() {
  return (
    <div className="help-popup">
      <div className="help-popup__image">
        <WomenOnMicImage />
      </div>
      <div className="help-popup__body">
        <p className="help-popup__title">Have general questions?</p>
        <p className="paragraph-font paragraph-font--color">
          Contact Honeydew's team 8am-10pm EST
        </p>
        <ul className="help-popup__contacts">
          <li className="help-popup__contact-item">
            <p className="help-popup__contact-item-title">call us</p>
            <a
              className="help-popup__contact-item-link"
              href="tel:516-210-5600"
            >
              516-210-5600
            </a>
          </li>
          <li className="help-popup__contact-item">
            <p className="help-popup__contact-item-title">email us</p>
            <a
              className="help-popup__contact-item-link"
              href="mailto:info@honeydewcare.com"
            >
              info@honeydewcare.com
            </a>
          </li>
          <li className="help-popup__contact-item">
            <p className="help-popup__contact-item-title">
              MEMBERSHIP QUESTIONS?
            </p>
            <a
              className="help-popup__contact-item-link"
              href="mailto:membership@honeydewcare.com"
            >
              membership@honeydewcare.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export function HelpPopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <HelpPopup />
      </HelpPopupTemplate>
    );
  };
}
