import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { HelpPopupTemplate } from "../../../NewComponents/Common/PopupLayer/help";
import { Accutane, Gender } from "../../../../types/Entities/Accutane";
import { Button } from "../../../NewComponents/Common/Button";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import {
  setAccutaneInfo,
  setProviderTasks,
} from "../../../../features/providers";
import "./style.scss";
import { TextInput } from "../../../NewComponents/Common/TextInput";
import { Checkbox } from "../../../NewComponents/Common/Checkbox";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { setLoading } from "../../../../features/patientView";
import { Patient } from "../../../../types/Entities/Patient";
import { Provider } from "../../../../types/Employee";
import {
  useEnrollAccutaneMutation,
  useSetRemNumberMutation,
} from "../../../../features/api/accutane";

interface PopupProps {
  onClose: () => void;
}

interface EnrollPatientPopup extends Accutane {
  taskId: string;
  userId: string;
  employeeId: string;
  patient?: Patient;
  provider?: Provider;
  documentGroupId?: string | undefined;
  inviteId?: string | undefined;
  role?: string;
  compositeKey?: string;
}

function EnrollIpledgePopup({
  gender,
  patientId,
  onClose,
  taskId,
  userId,
  employeeId,
}: {
  gender: Gender;
  patientId: string;
  onClose: () => void;
  taskId: string;
  userId: string;
  employeeId: string;
}) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [setRemsNumber] = useSetRemNumberMutation();
  const [enrollAccutane] = useEnrollAccutaneMutation();
  const [enrollForm, setFormField] = useState({
    rems: "",
    isEnrolled: false,
  });
  const confirmDisabled = !enrollForm.rems || !enrollForm.isEnrolled;

  const onSubmit = async () => {
    const remsNumberResult: any = await setRemsNumber({
      patientId,
      remsNumber: enrollForm.rems,
    });
    if (remsNumberResult.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to set REMS number",
      });
      return;
    }

    const enrollAccutaneResult: any = await enrollAccutane({
      patientId,
      taskId,
    });

    if (enrollAccutaneResult.error) {
      showError({
        title: "Error",
        description: "Error occurred when trying to enroll patient to accutane",
      });
      return;
    }
    showSuccess({
      title: "Success!",
      description: "Successfully enrolled patient to accutane",
    });
    onClose();
  };

  const handleRemsChange = (rems: string) => {
    if (Number.isInteger(Number(rems))) {
      setFormField({ ...enrollForm, rems });
    }
  };

  const handleEnrollChange = () =>
    setFormField({ ...enrollForm, isEnrolled: !enrollForm.isEnrolled });

  return (
    <div className="enroll-ipledge-popup">
      <p className="enroll-ipledge-popup__title">Enroll patient</p>
      <div className="enroll-ipledge-popup__content">
        <p className="enroll-ipledge-popup__content-text">
          This patient has subscribed to Isotretinoin Management and is waiting
          to be enrolled. Please enroll the patient on the iPledge website and
          confirm that this task was completed below.
        </p>
        <p className="enroll-ipledge-popup__content-text">REMS number</p>
        <TextInput
          id="remsNumber"
          name="remsNumber"
          value={enrollForm.rems}
          onChange={handleRemsChange}
          placeholder="Enter patient REMS number"
          className="enroll-ipledge-popup__content__rems-number-input"
        />
        <div className="enroll-ipledge-popup__content__enroll-checkbox-wrapper">
          <Checkbox onChange={handleEnrollChange}>
            <span>
              I've enrolled the patient on the{" "}
              <a
                href="https://ipledgeprogram.com/#Main"
                target="_blank"
                rel="noreferrer"
              >
                iPledge website
              </a>
              .
            </span>
          </Checkbox>
        </div>
      </div>
      <div className="enroll-ipledge-popup__control-buttons">
        <Button text="Cancel" onClick={onClose} size="small" view="secondary" />
        <Button
          text="Confirm"
          onClick={onSubmit}
          size="small"
          disabled={confirmDisabled}
        />
      </div>
    </div>
  );
}

function EnrollIpledgePopupGenerator(accutane: EnrollPatientPopup) {
  return function render({ onClose }: PopupProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <EnrollIpledgePopup
          gender={accutane.gender}
          patientId={accutane.patientId}
          taskId={accutane.taskId}
          onClose={onClose}
          userId={accutane.userId}
          employeeId={accutane.employeeId}
        />
      </HelpPopupTemplate>
    );
  };
}

export default EnrollIpledgePopupGenerator;
