import React from "react";
import { ReactComponent as LogoIcon } from "../../../../Assets/NewIcons/full-logo.svg";
import { ReactComponent as CloseIcon } from "../../../../Assets/NewIcons/close-cross.svg";
import "./style.scss";
import { getCurrentYear } from "../../../../utils/getCurrentYear";

interface Props {
  onBack?: () => void;
  children: JSX.Element | JSX.Element[];
}

export function SimplePageLayout({ onBack, children }: Props) {
  return (
    <div className="simple-page-layout">
      <div className="simple-page-layout__header">
        {onBack ? (
          <div className="simple-page-layout__back" onClick={onBack}>
            <CloseIcon />
          </div>
        ) : null}
        <LogoIcon />
      </div>
      <div className="simple-page-layout__header-placeholder" />
      <div className="simple-page-layout__wrap">
        <div className="simple-page-layout__content">{children}</div>
        <div className="simple-page-layout__footer">
          <p className="simple-page-layout__copyright">
            © {getCurrentYear()} AcneAway, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
