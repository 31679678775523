import moment from "moment";
import React, { useContext, useState } from "react";
import Select from "react-select";
import { v4 } from "uuid";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { Button } from "../../Common/Button";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import {
  CustomPopupTemplate,
  CustomPopupTemplateContext,
} from "../../Common/PopupLayer/custom";
import "./style.scss";
import { HoneydewAPI } from "../../../../services/honeydew-api";

const MONTHS_AMOUNT = 12;

interface PopupProps {
  providerId: string;
}

interface Props {
  onClose: () => void;
}

function ProvidersReportPickerPopup({ providerId }: PopupProps) {
  const { showError } = useContext(NotificationLayerContext);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const { onClose } = useContext(CustomPopupTemplateContext);

  async function submit() {
    if (!timestamp) return;

    try {
      const dateTo = new Date(timestamp).setMonth(
        new Date(timestamp).getMonth() + 1
      );

      const link = await HoneydewAPI.providers.generateReport(providerId, {
        dateFrom: timestamp,
        dateTo,
      });
      const { Location } = JSON.parse(link.billingReport);
      window.location.assign(Location);
    } catch {
      showError({
        title: "Something went wrong",
        description:
          "Could not generate provider report. Please, try again later",
      });
    }
  }

  function generateSelectItems() {
    const result = [];
    for (let i = 0; i < MONTHS_AMOUNT; i++) {
      const dateOptionEasternTime = moment
        .utc()
        .subtract(i, "months")
        .tz("America/New_York")
        .startOf("month");

      // Convert to accounting for daylight savings
      const _timestampUTC = dateOptionEasternTime.clone().utc().valueOf(); // in millis

      result.push({
        value: _timestampUTC,
        label: dateOptionEasternTime.add(1, "day").format("MMMM, YYYY"),
      });
    }

    return result;
  }

  return (
    <div className="providers-report-picker">
      <div className="providers-report-picker__message-block">
        <p className="providers-report-picker__title">
          Please select the date to generate a report
        </p>
        {/* <p className="providers-report-picker__descr paragraph-font--color">
          You have {patientsMultiAccGroup.length} patients under the same
          account. Please select the patient you would like to continue with.
        </p> */}
        <Select
          className="providers-report-picker__select"
          options={generateSelectItems()}
          onChange={(data) => setTimestamp(data?.value as number)}
          id={v4()}
        />
      </div>
      <div className="providers-report-picker__actions">
        <Button
          text="cancel"
          view="secondary"
          onClick={onClose}
          className="providers-report-picker__action"
        />
        <Button
          text="generate"
          className="providers-report-picker__action"
          onClick={submit}
          disabled={!timestamp}
        />
      </div>
    </div>
  );
}

export function ProvidersReportPickerPopupGenerator(props: PopupProps) {
  return function render({ onClose }: Props) {
    return (
      <CustomPopupTemplate onClose={onClose}>
        <ProvidersReportPickerPopup {...props} />
      </CustomPopupTemplate>
    );
  };
}
