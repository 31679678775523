import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Provider } from "../../../../../types/Entities/Provider";
import { AuthenticationService } from "../../../../cognito";

export const get = async (providerId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<Provider>(
      `${ENV_CONFIG.ROUTE_53_BASE_URL}/providers/get?id=${providerId}`,
      {
        headers: {
          Authorization: token,
        },
        params: {
          id: token,
        },
      }
    );

    return result.data;
  } catch (error) {
    logError("Error occured. Cannot get provider data");
    throw error;
  }
};
