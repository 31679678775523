import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import "./style.scss";
import { InfoBlock } from "../../Common/InfoBlock";
import { Checkbox } from "../../Common/Checkbox";
import { Button } from "../../Common/Button";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { useConfirmAppointmentMutation } from "../../../../features/api/appointments";
import { useConfirmIpledgeMutation } from "../../../../features/api/accutane";
import { getSessionState } from "../../../../features/session";

interface Props {
  onClose: () => void;
}

interface ConfirmIpledgePopupProps {
  onClose: () => void;
  nextConfirmationDate: string;
  userId: string;
}

function ConfirmIpledgePopup({
  onClose,
  nextConfirmationDate,
  userId,
}: ConfirmIpledgePopupProps) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [confirmIpledge] = useConfirmIpledgeMutation();
  const [confirmed, setConfirmed] = useState(false);
  const { userId: providerId } = useSelector(getSessionState);

  const handleConfirmation = () => setConfirmed(!confirmed);
  const handleCancel = () => {
    onClose();
  };
  const handleSubmit = async () => {
    const result: any = await confirmIpledge({
      patientId: userId,
      employeeId: providerId,
    });

    if (result.error) {
      showError({
        title: "Error",
        description: "Error occurred when trying to confirm iPledge",
      });
    } else {
      showSuccess({
        title: "Success",
        description: "iPledge successfully confirmed",
      });
      onClose();
    }
  };

  // todo remove N/A check for nextConfirmationDate
  return (
    <div className="confirm-ipledge-popup">
      <p className="confirm-ipledge-popup__title">Confirm patient</p>
      <div className="confirm-ipledge-popup__content">
        {nextConfirmationDate && nextConfirmationDate !== "N/A" && (
          <InfoBlock
            view="info"
            text={`The patient is due for iPledge confirmation on ${nextConfirmationDate}.`}
            className="confirm-ipledge-popup__content__info"
          />
        )}
        <p className="confirm-ipledge-popup__content__description">
          You should only click confirm button after you have confirmed a
          patient on the iPledge website.
        </p>
        <div className="confirm-ipledge-popup__content__checkbox-wrapper">
          <Checkbox onChange={handleConfirmation}>
            <span>
              I've already confirmed a patient on the{" "}
              <a
                href="https://ipledgeprogram.com/#Main"
                target="_blank"
                rel="noreferrer"
              >
                iPledge website
              </a>
              .
            </span>
          </Checkbox>
        </div>
      </div>
      <div className="confirm-ipledge-popup__control-buttons">
        <Button
          text="Cancel"
          view="secondary"
          onClick={handleCancel}
          size="small"
        />
        <Button
          text="Confirm"
          onClick={handleSubmit}
          size="small"
          disabled={!confirmed}
        />
      </div>
    </div>
  );
}

function ConfirmIpledgePopupGenerator(
  nextConfirmationDate: string,
  userId: string
) {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <ConfirmIpledgePopup
          nextConfirmationDate={nextConfirmationDate}
          onClose={onClose}
          userId={userId}
        />
      </HelpPopupTemplate>
    );
  };
}

export default ConfirmIpledgePopupGenerator;
