import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { CustomerInfo } from "../../../types/CustomerInfo";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

const customerInfoFieldsQuery = `{
  internalCredit
  paymentMethods {
    id
    billingName
    brand
    cardNumber
  }
}`;

export const getCustomerInfo = async (patientId: string) => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<
      GraphQLResponse<{ getCustomerInfo: CustomerInfo }>
    >(`${ENV_CONFIG.PAYMENT_SERVICE_GRAPHQL_URL}`, {
      headers: {
        Authorization: token,
      },
      params: {
        query: `query { getCustomerInfo(patientId: "${patientId}") ${customerInfoFieldsQuery} }`,
      },
    });

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.getCustomerInfo;
  } catch (error: any) {
    logError("Error occured. Cannot get Payment Configuration", error);
    throw error;
  }
};
