import axios from "axios";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { Patient } from "../../../../../types/Entities/Patient";

interface Props {
  id: string;
  email: string;
  zipCode: string;
  isChild: boolean;
  birthDate: string;
  fullName: string;
  parents?: {
    name: string;
    email: string;
    phone: string;
  };
  referralCode: string | null;
}

export const signUp = async (props: Props) => {
  try {
    const result = await axios.post<Patient>(
      `${ENV_CONFIG.AMAZON_API_DOMAIN}/patients/sign-up`,
      JSON.stringify(props)
    );
    return result.data;
  } catch (error) {
    logError("Error occured. Cannot create a patient in DB");
    throw error;
  }
};
