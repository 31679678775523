import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import { ReactComponent as HappyGuy } from "../../../../Assets/NewIcons/happy-guy.svg";
import { Button } from "../../../NewComponents/Common/Button";
import { PaymentFormSurveyPage } from "../InitiateMembership/pages/payment-form";
import { ProductsSurveyPage } from "./pages/products";

export function BuyProductsPage() {
  const history = useHistory();
  const [isDone, setDone] = useState(false);

  const pages: SurveyPage[] = [
    {
      isFilled: () => false,
      Component: ProductsSurveyPage,
    },
    {
      isFilled: () => false,
      Component: PaymentFormSurveyPage,
    },
  ];

  return isDone ? (
    <div className="initiate-checkout-page__success-page">
      <HappyGuy className="initiate-checkout-page__success-image" />
      <p className="initiate-checkout-page__success-title">
        You have successfully ordered your products.
      </p>
      {/* <p className="initiate-checkout-page__success-description paragraph-font--color">
        After a minute you will obtain full access to our features.{" "}
      </p> */}
      <Button onClick={() => history.push("/")} text="finish" />
    </div>
  ) : (
    <CustomSurvey
      questions={pages}
      data={{
        isSuggestionsDisabled: true,
      }}
      onComplete={() => {
        setDone(true);
        return true;
      }}
      className="initiate-checkout-page__survey"
    />
  );
}
